import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Formik, Field, Form, ErrorMessage } from 'formik'
import { FaEyeSlash } from 'react-icons/fa'
import { FaEye } from 'react-icons/fa'
import * as Yup from 'yup'
import { ErrorNotify, SuccessNotify } from '../../helpers/notification'
import './profile.scss'
import { DashboardLayout } from '../../components/layout/dashboardLayout'
import ErrorFocus from '../../components/ws/ErrorFocus'
import { Grid } from '@material-ui/core'
import { ButtonComponent } from '../../components/form/Button'
import accountSlice from '../../redux/slices/accountSlice'
import { clear_getStaticPages, getStaticPages } from '../../redux/actions/pageInfoByTypeAction'
import { get } from 'lodash'
import UserContext from '../../UserContext'

class CloseAccount extends Component {
  static contextType = UserContext

  componentDidMount() {
    this.props.getStaticPages({ slug: 'close-account-838' })
  }

  componentDidUpdate() {
    if (this.props.staticPages?.success || this.props.staticPages?.failure) {
      this.props.clear_getStaticPages()
    }

    if (this.props.s_closeAccount.success || this.props.s_closeAccount.failure) {
      this.props.d_closeAccount_clear()

      if (this.props.s_closeAccount.success) {
        SuccessNotify('Account closed successfully!')

        setTimeout(() => {
          this.context.logout()
          this.props.history.push('/logout')
        }, 1000)
      }

      if (this.props.s_closeAccount.failure) {
        ErrorNotify(this.props.s_closeAccount.message || 'Cannot close account!')
      }
    }
  }

  state = {
    passwordVisible: false,
  }

  passwordToggle = () => {
    this.setState(prevState => ({
      passwordVisible: !prevState.passwordVisible,
    }))
  }

  render() {
    const { passwordVisible } = this.state
    const { staticPages, d_closeAccount, s_closeAccount } = this.props

    const sideContent = get(staticPages, 'data[0].pageContent', '')

    return (
      <DashboardLayout>
        <div className="change--password">
          <Formik
            initialValues={{
              password: '',
            }}
            validationSchema={Yup.object().shape({
              password: Yup.string().required('Password is required'),
            })}
            onSubmit={values => {
              d_closeAccount({ input: { password: values.password } })
            }}
            render={({ errors, values }) => (
              <>
                <Grid container>
                  <Grid sm={7} className="password--change--confirm--div">
                    <div>
                      <Form>
                        <ErrorFocus />

                        <div className="change--password--main--title--div">
                          <span className="change--password--main--title">Close Account</span>
                        </div>

                        <div className="password--change--fields">
                          <div className="mb-3 width-100 change--password--info change--current--password--info">
                            <label htmlFor="oldPassword" className="">
                              Confirm Your Password
                            </label>
                            <div className="eye--input">
                              <Field
                                name="password"
                                style={{backgroundImage: 'none'}}
                                type={passwordVisible ? 'text' : 'password'}
                                className={'form-control password--spacing' + (errors.password ? ' is-invalid' : '')}
                              />
                              <span className="eye--input-icon" onClick={this.passwordToggle}>
                                {passwordVisible ? <FaEyeSlash /> : <FaEye />}
                              </span>
                            </div>
                            <ErrorMessage name="password" component="div" className="d-flex invalid-feedback" />
                          </div>

                          <div className="width-100 mb-3">
                            <ButtonComponent
                              variant="outline"
                              color="success"
                              className="h-auto btn--padding btn--font justify-content-center login--sign--up--btn"
                              type="submit"
                              disabled={s_closeAccount.loading}
                            >
                              Close Account
                            </ButtonComponent>
                          </div>
                        </div>
                      </Form>
                    </div>
                  </Grid>

                  <Grid sm={5} className="password--change--right--box">
                    <div>
                      <div className="d-flex flex-column">
                        <span
                          className="title-text password--right--div--desc"
                          dangerouslySetInnerHTML={{ __html: sideContent }}
                        />
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </>
            )}
          />
        </div>
      </DashboardLayout>
    )
  }
}

const mapStateToProps = state => ({
  staticPages: state.pageInfoByTypeReducer.staticPages,
  s_closeAccount: state.accountReducer.closeAccount,
})

const mapDispatchToProps = dispatch => ({
  getStaticPages: data => dispatch(getStaticPages(data)),
  clear_getStaticPages: () => dispatch(clear_getStaticPages()),
  d_closeAccount: data => dispatch(accountSlice.actions.closeAccount.main(data)),
  d_closeAccount_clear: data => dispatch(accountSlice.actions.closeAccount.clear(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(CloseAccount)
