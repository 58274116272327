export const USER_REGISTER = 'USER_REGISTER'
export const USER_REGISTER_SUCCESS = 'USER_REGISTER_SUCCESS'
export const USER_REGISTER_FAILURE = 'USER_REGISTER_FAILURE'

//clear register flag
export const CLEAR_USER_REGISTER_FLAG = 'CLEAR_USER_REGISTER_FLAG'

export const USER_LOGIN = 'USER_LOGIN'
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS'
export const USER_LOGIN_FAILURE = 'USER_LOGIN_FAILURE'

export const USER_LOGOUT = 'USER_LOGOUT'
export const USER_LOGOUT_SUCCESS = 'USER_LOGOUT_SUCCESS'
export const USER_LOGOUT_FAILURE = 'USER_LOGOUT_FAILURE'

export const GET_CATEGORIES_WISE_BANNER_SUCCESS = 'GET_CATEGORIES_WISE_BANNER_SUCCESS'
export const GET_CATEGORIES_WISE_BANNER_FAILURE = 'GET_CATEGORIES_WISE_BANNER_FAILURE'
export const GET_CATEGORIES_WISE_BANNER = 'GET_CATEGORIES_WISE_BANNER'

// CLEAR USER FLAGS
export const CLEAR_AUTH_FLAG = 'CLEAR_AUTH_FLAG'
export const CLEAR_EMAIL_FLAGS = 'CLEAR_EMAIL_FLAGS'
export const CLEAR_UPDATE_FLAG = 'CLEAR_UPDATE_FLAG'
export const CLEAR_USER_VERIFY_FLAGS = 'CLEAR_USER_VERIFY_FLAGS'
export const CLEAR_FORGOT_PASSWORD_MAIL_FLAG = 'CLEAR_FORGOT_PASSWORD_FLAG'
export const CLEAR_RESET_PASSWORD_FLAG = 'CLEAR_RESET_PASSWORD_FLAG'

// CLEAR BRANCH FLAGS
export const CLEAR_BRANCH_FLAG = 'CLEAR_BRANCH_FLAG'

// CLEAR SEARCH FLAGS
export const CLEAR_SEARCH_BOAT_FLAG = 'CLEAR_SEARCH_BOAT_FLAG'
export const CLEAR_SEARCHED_RESULTS = 'CLEAR_SEARCHED_RESULTS'

// CLEAR BOATS FLAGS
export const CLEAR_BOAT_ADD_FLAG = 'CLEAR_BOAT_ADD_FLAG'
export const CLEAR_BOAT_UPDATE_FLAG = 'CLEAR_BOAT_UPDATE_FLAG'
export const CLEAR_EXISTING_BOAT = 'CLEAR_EXISTING_BOAT'
export const CLEAR_CITY_WISE_BOATS = 'CLEAR_CITY_WISE_BOATS'
export const CLEAR_CATEGOEY_WISE_BOATS = 'CLEAR_CATEGOEY_WISE_BOATS'
export const CLEAR_DELETE_BOAT_RENT_FLAG = 'CLEAR_DELETE_BOAT_RENT_FLAG'
export const CLEAR_ESTIMATED_MAIL = 'CLEAR_ESTIMATED_MAIL'

// CLEAR BOAT SERVICE FLAGS
export const CLEAR_ADD_BOAT_SERVICE_FLAGS = 'CLEAR_ADD_BOAT_SERVICE_FLAGS'
export const CLEAR_SEARCH_YACHT_SERVICE_FLAG = 'CLEAR_SEARCH_YACHT_SERVICE_FLAG'

// CLEAR MARINA & STORAGE FLAGS
export const CLEAR_SEARCH_MARINA_AND_STORAGE_FLAG = 'CLEAR_SEARCH_MARINA_AND_STORAGE_FLAG'

export const USER_UPDATE = 'USER_UPDATE'
export const USER_UPDATE_SUCCESS = 'USER_UPDATE_SUCCESS'
export const USER_UPDATE_FAILURE = 'USER_UPDATE_FAILURE'

export const GET_USER_STRIPE_DETAILS = 'GET_USER_STRIPE_DETAILS'
export const GET_USER_STRIPE_DETAILS_SUCCESS = 'GET_USER_STRIPE_DETAILS_SUCCESS'
export const GET_USER_STRIPE_DETAILS_FAILURE = 'GET_USER_STRIPE_DETAILS_FAILURE'

export const CLEAR_USER_STRIPE_LINK = 'CLEAR_USER_STRIPE_LINK'

export const UPDATE_USER_STRIPE_PROFILE_COMPLETED = 'UPDATE_USER_STRIPE_PROFILE_COMPLETED'
export const UPDATE_USER_STRIPE_PROFILE_COMPLETED_SUCCESS = 'UPDATE_USER_STRIPE_PROFILE_COMPLETED_SUCCESS'
export const UPDATE_USER_STRIPE_PROFILE_COMPLETED_FAILURE = 'UPDATE_USER_STRIPE_PROFILE_COMPLETED_FAILURE'
export const CLEAR__UPDATE_STRIPE_PROFILE_FAILURE = 'CLEAR__UPDATE_STRIPE_PROFILE_FAILURE'

export const GET_USER_ROLES = 'GET_USER_ROLES'
export const GET_USER_ROLES_SUCCESS = 'GET_USER_ROLES_SUCCESS'
export const GET_USER_ROLES_FAILURE = 'GET_USER_ROLES_FAILURE'

// Register Page Information
export const GET_ALL_PAGE_INFO_BY_TYPE = 'GET_ALL_PAGE_INFO_BY_TYPE'
export const GET_ALL_PAGE_INFO_BY_TYPE_SUCCESS = 'GET_ALL_PAGE_INFO_BY_TYPE_SUCCESS'
export const GET_ALL_PAGE_INFO_BY_TYPE_FAILURE = 'GET_ALL_PAGE_INFO_BY_TYPE_FAILURE'

export const SEND_CONFIRM_MAIL_LINK = 'SEND_CONFIRM_MAIL_LINK'
export const SEND_CONFIRM_MAIL_LINK_SUCCESS = 'SEND_CONFIRM_MAIL_LINK_SUCCESS'
export const SEND_CONFIRM_MAIL_LINK_FAILURE = 'SEND_CONFIRM_MAIL_LINK_FAILURE'

export const USER_EMAIL_VERIFY = 'USER_EMAIL_VERIFY'
export const USER_EMAIL_VERIFY_SUCCESS = 'USER_EMAIL_VERIFY_SUCCESS'
export const USER_EMAIL_VERIFY_FAILURE = 'USER_EMAIL_VERIFY_FAILURE'

export const GET_USER_BY_ID = 'GET_USER_BY_ID'
export const GET_USER_BY_ID_SUCCESS = 'GET_USER_BY_ID_SUCCESS'
export const GET_USER_BY_ID_FAILURE = 'GET_USER_BY_ID_FAILURE'

//get all boat lookups
export const GET_ALL_BOAT_LOOKUPS = 'GET_ALL_BOAT_LOOKUPS'
export const GET_ALL_BOAT_LOOKUPS_SUCCESS = 'GET_ALL_BOAT_LOOKUPS_SUCCESS'
export const GET_ALL_BOAT_LOOKUPS_FAILURE = 'GET_ALL_BOAT_LOOKUPS_FAILURE'

// get all boats by users
export const GET_ALL_BOATS_BY_USER = 'GET_ALL_BOATS_BY_USER'
export const GET_ALL_BOATS_BY_USER_SUCCESS = 'GET_ALL_BOATS_BY_USER_SUCCESS'
export const GET_ALL_BOATS_BY_USER_FAILURE = 'GET_ALL_BOATS_BY_USER_FAILURE'

// get all footer boats
export const GET_ALL_FOOTER_LINK = 'GET_ALL_FOOTER_LINK'
export const GET_ALL_FOOTER_LINK_SUCCESS = 'GET_ALL_FOOTER_LINK_SUCCESS'
export const GET_ALL_FOOTER_LINK_FAILURE = 'GET_ALL_FOOTER_LINK_FAILURE'

//save boat
export const ADD_BOAT = 'ADD_BOAT'
export const ADD_BOAT_SUCCESS = 'ADD_BOAT_SUCCESS'
export const ADD_BOAT_FAILURE = 'ADD_BOAT_FAILURE'

//search boat
export const SEARCH_BOAT = 'SEARCH_BOAT'
export const SEARCH_BOAT_SUCCESS = 'SEARCH_BOAT_SUCCESS'
export const SEARCH_BOAT_FAILURE = 'SEARCH_BOAT_FAILURE'

//add marina and storage
export const ADD_MARINA_AND_STORAGE = 'ADD_MARINA_AND_STORAGE'
export const ADD_MARINA_AND_STORAGE_SUCCESS = 'ADD_MARINA_AND_STORAGE_SUCCESS'
export const ADD_MARINA_AND_STORAGE_FAILURE = 'ADD_MARINA_AND_STORAGE_FAILURE'

//get all marina and storage
export const GET_ALL_MARINA = 'GET_ALL_MARINA'
export const GET_ALL_MARINA_SUCCESS = 'GET_ALL_MARINA_SUCCESS'
export const GET_ALL_MARINA_FAILURE = 'GET_ALL_MARINA_FAILURE'

//get all marina services
export const GET_MORE_SERVICE = 'GET_MORE_SERVICE'
export const GET_MORE_SERVICE_SUCCESS = 'GET_MORE_SERVICE_SUCCESS'
export const GET_MORE_SERVICE_FAILURE = 'GET_MORE_SERVICE_FAILURE'

//clear marina flags
export const CLEAR_MARINA_FLAG = 'CLEAR_MARINA_FLAG'

// CREATE BRANCH
export const ADD_BRANCH = 'ADD_BRANCH'
export const ADD_BRANCH_SUCCESS = 'ADD_BRANCH_SUCCESS'
export const ADD_BRANCH_FAILURE = 'ADD_BRANCH_FAILURE'

// GET_SINGLE BRANCH
export const GET_SINGLE_BRANCH = 'GET_SINGLE_BRANCH'
export const GET_SINGLE_BRANCH_SUCCESS = 'GET_SINGLE_BRANCH_SUCCESS'
export const GET_SINGLE_BRANCH_FAILURE = 'GET_SINGLE_BRANCH_FAILURE'

// UPDATE BRANCH
export const UPDATE_BRANCH = 'UPDATE_BRANCH'
export const UPDATE_BRANCH_SUCCESS = 'UPDATE_BRANCH_SUCCESS'
export const UPDATE_BRANCH_FAILURE = 'UPDATE_BRANCH_FAILURE'

// GET ALL BRANCHES
export const GET_ALL_BRANCHES = 'GET_ALL_BRANCHES'
export const GET_ALL_BRANCHES_SUCCESS = 'GET_ALL_BRANCHES_SUCCESS'
export const GET_ALL_BRANCHES_FAILURE = 'GET_ALL_BRANCHES_FAILURE'

// GET ALL BRANCHES
export const CHANGE_BRANCH_STATUS = 'CHANGE_BRANCH_STATUS'
export const CHANGE_BRANCH_STATUS_SUCCESS = 'CHANGE_BRANCH_STATUS_SUCCESS'
export const CHANGE_BRANCH_STATUS_FAILURE = 'CHANGE_BRANCH_STATUS_FAILURE'

// DELETE BRANCH
export const DELETE_BRANCH = 'DELETE_BRANCH'
export const DELETE_BRANCH_SUCCESS = 'DELETE_BRANCH_SUCCESS'
export const DELETE_BRANCH_FAILURE = 'DELETE_BRANCH_FAILURE'

export const SET_CURRENT_LOCATION = 'SET_CURRENT_LOCATION'

//clear dashboard flag
export const CLEAR_DASHBOARD_FLAG = 'CLEAR_DASHBOARD_FLAG'
// get boat type
export const GET_BOAT_TYPE = 'GET_BOAT_TYPE'
export const GET_BOAT_TYPE_SUCCESS = 'GET_BOAT_TYPE_SUCCESS'
export const GET_BOAT_TYPE_FAILURE = 'GET_BOAT_TYPE_FAILURE'

export const MENU_CHANGE = 'MENU_CHANGE'

// FORGOT PASSWORD
export const FORGOT_PASSWORD_MAIL = 'FORGOT_PASSWORD_MAIL'
export const FORGOT_PASSWORD_MAIL_SUCCESS = 'FORGOT_PASSWORD_MAIL_SUCCESS'
export const FORGOT_PASSWORD_MAIL_FAILURE = 'FORGOT_PASSWORD_MAIL_FAILURE'

// RESET PASSWORD
export const RESET_PASSWORD = 'RESET_PASSWORD'
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS'
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE'

// RECENT SEARCH
export const RECENT_SEARCH = 'RECENT_SEARCH'
export const RECENT_SEARCH_SUCCESS = 'RECENT_SEARCH_SUCCESS'
export const RECENT_SEARCH_FAILURE = 'RECENT_SEARCH_FAILURE'

// MULTI SEARCH
export const MULTI_SEARCH = 'MULTI_SEARCH'
export const MULTI_SEARCH_SUCCESS = 'MULTI_SEARCH_SUCCESS'
export const MULTI_SEARCH_FAILURE = 'MULTI_SEARCH_FAILURE'

//create rent boat
export const CREATE_RENT_BOAT = 'CREATE_RENT_BOAT'
export const CREATE_RENT_BOAT_SUCCESS = 'CREATE_RENT_BOAT_SUCCESS'
export const CREATE_RENT_BOAT_FAILURE = 'CREATE_RENT_BOAT_FAILURE'

//clear boat rent flag
export const CLEAR_BOAT_RENT_FLAG = 'CLEAR_BOAT_RENT_FLAG'
export const CLEAR_BOAT_RENT_DATA = 'CLEAR_BOAT_RENT_DATA'
export const CLEAR_SEARCH_BOAT_RENT_FLAG = 'CLEAR_SEARCH_BOAT_RENT_FLAG'

//clear Rent Boat
export const SEARCH_BOAT_RENT = 'SEARCH_BOAT_RENT'
export const SEARCH_BOAT_RENT_SUCCESS = 'SEARCH_BOAT_RENT_SUCCESS'
export const SEARCH_BOAT_RENT_FAILURE = 'SEARCH_BOAT_RENT_FAILURE'

//get boat rent lookups

export const GET_BOAT_RENT_LOOKUPS = 'GET_BOAT_RENT_LOOKUPS'
export const GET_BOAT_RENT_LOOKUPS_SUCCESS = 'GET_BOAT_RENT_LOOKUPS_SUCCESS'
export const GET_BOAT_RENT_LOOKUPS_FAILURE = 'GET_BOAT_RENT_LOOKUPS_FAILURE'

//get user all boat rents

export const GET_USER_BOAT_RENTS = 'GET_USER_BOAT_RENTS'
export const GET_USER_BOAT_RENTS_SUCCESS = 'GET_USER_BOAT_RENTS_SUCCESS'
export const GET_USER_BOAT_RENTS_FAILURE = 'GET_USER_BOAT_RENTS_FAILURE'
// GET SPECIFIC BOAT BY ID
export const GET_SINGLE_BOAT = 'GET_SINGLE_BOAT'
export const GET_SINGLE_BOAT_SUCCESS = 'GET_SINGLE_BOAT_SUCCESS'
export const GET_SINGLE_BOAT_FAILURE = 'GET_SINGLE_BOAT_FAILURE'
// GET SPECIFIC BOAT BY ID
export const GET_USERS_BY_COUNTRY = 'GET_USERS_BY_COUNTRY'
export const GET_USERS_BY_COUNTRY_SUCCESS = 'GET_USERS_BY_COUNTRY_SUCCESS'
export const GET_USERS_BY_COUNTRY_FAILURE = 'GET_USERS_BY_COUNTRY_FAILURE'

export const GET_CATEGORY_WISE_BOATS = 'GET_CATEGORY_WISE_BOATS'
export const GET_CATEGORY_WISE_BOATS_SUCCESS = 'GET_CATEGORY_WISE_BOATS_SUCCESS'
export const GET_CATEGORY_WISE_BOATS_FAILURE = 'GET_CATEGORY_WISE_BOATS_FAILURE'

// TOGGLE BOAT STATUS (BY COLUMN NAME)
export const TOGGLE_BOAT_STATUS = 'TOGGLE_BOAT_STATUS'
export const TOGGLE_BOAT_STATUS_SUCCESS = 'TOGGLE_BOAT_STATUS_SUCCESS'
export const TOGGLE_BOAT_STATUS_FAILURE = 'TOGGLE_BOAT_STATUS_FAILURE'

// DELETE BOAT
export const DELETE_BOAT = 'DELETE_BOAT'
export const DELETE_BOAT_SUCCESS = 'DELETE_BOAT_SUCCESS'
export const DELETE_BOAT_FAILURE = 'DELETE_BOAT_FAILURE'

// delete boat rent
export const DELETE_BOAT_RENT = 'DELETE_BOAT_RENT'
export const DELETE_BOAT_RENT_SUCCESS = 'DELETE_BOAT_RENT_SUCCESS'
export const DELETE_BOAT_RENT_FAILURE = 'DELETE_BOAT_RENT_FAILURE'
// UPDATE BOAT
export const UPDATE_BOAT = 'UPDATE_BOAT'
export const UPDATE_BOAT_SUCCESS = 'UPDATE_BOAT_SUCCESS'
export const UPDATE_BOAT_FAILURE = 'UPDATE_BOAT_FAILURE'

// GET COUNTRY-WISE BOATS
export const GET_ALL_BOATS_BY_COUNTRY = 'GET_ALL_BOATS_BY_COUNTRY'
export const GET_ALL_BOATS_BY_COUNTRY_SUCCESS = 'GET_ALL_BOATS_BY_COUNTRY_SUCCESS'
export const GET_ALL_BOATS_BY_COUNTRY_FAILURE = 'GET_ALL_BOATS_BY_COUNTRY_FAILURE'

// GET BOAT BY TYPE
export const GET_BOAT_BY_TYPE = 'GET_BOAT_BY_TYPE'
export const GET_BOAT_BY_TYPE_SUCCESS = 'GET_BOAT_BY_TYPE_SUCCESS'
export const GET_BOAT_BY_TYPE_FAILURE = 'GET_BOAT_BY_TYPE_FAILURE'

// GET LATEST BOATS
export const GET_LATEST_BOATS = 'GET_LATEST_BOATS'
export const GET_LATEST_BOATS_SUCCESS = 'GET_LATEST_BOATS_SUCCESS'
export const GET_LATEST_BOATS_FAILURE = 'GET_LATEST_BOATS_FAILURE'

// GET MOST POPULAR BOATS
export const GET_POPULAR_BOATS = 'GET_POPULAR_BOATS'
export const GET_POPULAR_BOATS_SUCCESS = 'GET_POPULAR_BOATS_SUCCESS'
export const GET_POPULAR_BOATS_FAILURE = 'GET_POPULAR_BOATS_FAILURE'

// GET TOP RATED BOATS
export const GET_TOP_RATED_BOATS = 'GET_TOP_RATED_BOATS'
export const GET_TOP_RATED_BOATS_SUCCESS = 'GET_TOP_RATED_BOATS_SUCCESS'
export const GET_TOP_RATED_BOATS_FAILURE = 'GET_TOP_RATED_BOATS_FAILURE'

//create articles
export const CREATE_ARTICLE = 'CREATE_ARTICLE'
export const CREATE_ARTICLE_SUCCESS = 'CREATE_ARTICLE_SUCCESS'
export const CREATE_ARTICLE_FAILURE = 'CREATE_ARTICLE_FAILURE'

export const CHANGE_ARTICLE_STATUS = 'CHANGE_ARTICLE_STATUS'
export const CHANGE_ARTICLE_STATUS_SUCCESS = 'CHANGE_ARTICLE_STATUS_SUCCESS'
export const CHANGE_ARTICLE_STATUS_FAILURE = 'CHANGE_ARTICLE_STATUS_FAILURE'

export const SUBSCRIBE_USER = 'SUBSCRIBE_USER'
export const SUBSCRIBE_USER_SUCCESS = 'SUBSCRIBE_USER_SUCCESS'
export const SUBSCRIBE_USER_FAILURE = 'SUBSCRIBE_USER_FAILURE'
export const CLEAR__SUBSCRIBE_USER = 'CLEAR__SUBSCRIBE_USER'

//clear article flag
export const CLEAR_ARTICLE_FLAG = 'CLEAR_ARTICLE_FLAG'

// get user's all articles
export const GET_USER_ALL_ARTICLES = 'GET_ALL_ARTICLES'
export const GET_USER_ALL_ARTICLES_SUCCESS = 'GET_ALL_ARTICLES_SUCCESS'
export const GET_USER_ALL_ARTICLES_FAILURE = 'GET_ALL_ARTICLES_FAILURE'

//create boat show
export const CREATE_BOAT_SHOW = 'CREATE_BOAT_SHOW'
export const CREATE_BOAT_SHOW_SUCCESS = 'CREATE_BOAT_SHOW_SUCCESS'
export const CREATE_BOAT_SHOW_FAILURE = 'CREATE_BOAT_SHOW_FAILURE'

//get user boat shows
export const GET_USER_BOAT_SHOWS = 'GET_USER_BOAT_SHOWS'
export const GET_USER_BOAT_SHOWS_SUCCESS = 'GET_USER_BOAT_SHOWS_SUCCESS'
export const GET_USER_BOAT_SHOWS_FAILURE = 'GET_USER_BOAT_SHOWS_FAILURE'
export const CLEAR_SEARCH_BOAT_SHOW_FLAG = 'CLEAR_SEARCH_BOAT_SHOW_FLAG'

//clear boat show flag
export const CLEAR_BOAT_SHOW_FLAG = 'CLEAR_BOAT_SHOW_FLAG'

//get all boat shows
export const GET_ALL_BOAT_SHOW = 'GET_ALL_BOAT_SHOW'
export const GET_ALL_BOAT_SHOW_SUCCESS = 'GET_ALL_BOAT_SHOW_SUCCESS'
export const GET_ALL_BOAT_SHOW_FAILURE = 'GET_ALL_BOAT_SHOW_FAILURE'

//search boat show
export const SEARCH_BOAT_SHOW = 'SEARCH_BOAT_SHOW'
export const SEARCH_BOAT_SHOW_SUCCESS = 'SEARCH_BOAT_SHOW_SUCCESS'
export const SEARCH_BOAT_SHOW_FAILURE = 'SEARCH_BOAT_SHOW_FAILURE'

//get rent boat by trip kind
export const GET_RENT_BOAT_BY_TRIP = 'GET_RENT_BOAT_BY_TRIP'
export const GET_RENT_BOAT_BY_TRIP_SUCCESS = 'GET_RENT_BOAT_BY_TRIP_SUCCESS'
export const GET_RENT_BOAT_BY_TRIP_FAILURE = 'GET_RENT_BOAT_BY_TRIP_FAILURE'

//get rent boat most popular
export const GET_RENT_BOAT_MOST_POPULAR_TRIP_KIND = 'GET_RENT_BOAT_MOST_POPULAR_TRIP_KIND'
export const GET_RENT_BOAT_MOST_POPULAR = 'GET_RENT_BOAT_MOST_POPULAR'
export const GET_RENT_BOAT_MOST_POPULAR_SUCCESS = 'GET_RENT_BOAT_MOST_POPULAR_SUCCESS'
export const GET_RENT_BOAT_MOST_POPULAR_FAILURE = 'GET_RENT_BOAT_MOST_POPULAR_FAILURE'

// get marina storage type wise lookup
export const GET_TYPE_WISE_LOOKUP = 'GET_TYPE_WISE_LOOKUP'
export const GET_TYPE_WISE_LOOKUP_SUCCESS = 'GET_TYPE_WISE_LOOKUP_SUCCESS'
export const GET_TYPE_WISE_LOOKUP_FAILURE = 'GET_TYPE_WISE_LOOKUP_FAILURE'

export const GET_ALL_TRIP_TYPES = 'GET_ALL_TRIP_TYPES'
export const GET_ALL_TRIP_TYPES_SUCCESS = 'GET_ALL_TRIP_TYPES_SUCCESS'
export const GET_ALL_TRIP_TYPES_FAILURE = 'GET_ALL_TRIP_TYPES_FAILURE'

// get explore marina
export const GET_EXPLORE_MARINA = 'GET_EXPLORE_MARINA'
export const GET_EXPLORE_MARINA_SUCCESS = 'GET_EXPLORE_MARINA_SUCCESS'
export const GET_EXPLORE_MARINA_FAILURE = 'GET_EXPLORE_MARINA_FAILURE'

// GET ALL GLOBAL MINIMUM PRICE BOATS
export const GET_ALL_GLOBAL_MINIMUM_PRICE_BOATS = 'GET_ALL_GLOBAL_MINIMUM_PRICE_BOATS'
export const GET_ALL_GLOBAL_MINIMUM_PRICE_BOATS_SUCCESS = 'GET_ALL_GLOBAL_MINIMUM_PRICE_BOATS_SUCCESS'
export const GET_ALL_GLOBAL_MINIMUM_PRICE_BOATS_FAILURE = 'GET_ALL_GLOBAL_MINIMUM_PRICE_BOATS_FAILURE'

// GET CITY WISE BOATS
export const GET_CITY_WISE_BOATS = 'GET_CITY_WISE_BOATS'
export const GET_CITY_WISE_BOATS_SUCCESS = 'GET_CITY_WISE_BOATS_SUCCESS'
export const GET_CITY_WISE_BOATS_FAILURE = 'GET_CITY_WISE_BOATS_FAILURE'

// ADD BOAT SERVICE
export const ADD_BOAT_SERVICE = 'ADD_BOAT_SERVICE'
export const ADD_BOAT_SERVICE_SUCCESS = 'ADD_BOAT_SERVICE_SUCCESS'
export const ADD_BOAT_SERVICE_FAILURE = 'ADD_BOAT_SERVICE_FAILURE'

export const GET_USER_MARINA_STORAGE = 'GET_USER_MARINA_STORAGE'
export const GET_USER_MARINA_STORAGE_SUCCESS = 'GET_USER_MARINA_STORAGE_SUCCESS'
export const GET_USER_MARINA_STORAGE_FAILURE = 'GET_USER_MARINA_STORAGE_FAILURE'

// GET RECOMMENDED TRIPS
export const GET_RECOMMENDED_TRIPS = 'GET_RECOMMENDED_TRIPS'
export const GET_RECOMMENDED_TRIPS_SUCCESS = 'GET_RECOMMENDED_TRIPS_SUCCESS'
export const GET_RECOMMENDED_TRIPS_FAILURE = 'GET_RECOMMENDED_TRIPS_FAILURE'

//update boat show
export const UPDATE_BOAT_SHOW = 'UPDATE_BOAT_SHOW'
export const UPDATE_BOAT_SHOW_SUCCESS = 'UPDATE_BOAT_SHOW_SUCCESS'
export const UPDATE_BOAT_SHOW_FAILURE = 'UPDATE_BOAT_SHOW_FAILURE'

//get boatshow by Id
export const GET_SINGLE_BOAT_SHOW = 'GET_SINGLE_BOAT_SHOW'
export const GET_SINGLE_BOAT_SHOW_SUCCESS = 'GET_SINGLE_BOAT_SHOW_SUCCESS'
export const GET_SINGLE_BOAT_SHOW_FAILURE = 'GET_SINGLE_BOAT_SHOW_FAILURE'

//get recently added marina storage
export const GET_RECENTLY_ADDED_MARINA_STORAGE = ' GET_RECENTLY_ADDED_MARINA_STORAGE'
export const GET_RECENTLY_ADDED_MARINA_STORAGE_SUCCESS = ' GET_RECENTLY_ADDED_MARINA_STORAGE_SUCCESS'
export const GET_RECENTLY_ADDED_MARINA_STORAGE_FAILURE = ' GET_RECENTLY_ADDED_MARINA_STORAGE_FAILURE'
//get type wise boat service
export const GET_TYPE_WISE_BOAT_SERVICE = 'GET_TYPE_WISE_BOAT_SERVICE'
export const GET_TYPE_WISE_BOAT_SERVICE_SUCCESS = 'GET_TYPE_WISE_BOAT_SERVICE_SUCCESS'
export const GET_TYPE_WISE_BOAT_SERVICE_FAILURE = 'GET_TYPE_WISE_BOAT_SERVICE_FAILURE'

//get recently added boat services
export const GET_RECENTLY_ADDEDD_SERVICE = 'GET_RECENTLY_ADDEDD_SERVICE'
export const GET_RECENTLY_ADDEDD_SERVICE_SUCCESS = 'GET_RECENTLY_ADDEDD_SERVICE_SUCCESS'
export const GET_RECENTLY_ADDEDD_SERVICE_FAILURE = 'GET_RECENTLY_ADDEDD_SERVICE_FAILURE'

//clear boat service flag
export const CLEAR_SERVICE_FLAG = 'CLEAR_SERVICE_FLAG'

//get user service
export const GET_USER_BOAT_SERVICE = 'GET_USER_BOAT_SERVICE'
export const GET_USER_BOAT_SERVICE_SUCCESS = 'GET_USER_BOAT_SERVICE_SUCCESS'
export const GET_USER_BOAT_SERVICE_FAILURE = 'GET_USER_BOAT_SERVICE_FAILURE'

//get single marina
export const GET_SINGLE_MARINA_STORAGE = 'GET_SINGLE_MARINA_STORAGE'
export const GET_SINGLE_MARINA_STORAGE_SUCCESS = 'GET_SINGLE_MARINA_STORAGE_SUCCESS'
export const GET_SINGLE_MARINA_STORAGE_FAILURE = 'GET_SINGLE_MARINA_STORAGE_FAILURE'

export const EDIT_YACHT_SERVICE = 'EDIT_YACHT_SERVICE'
export const EDIT_YACHT_SERVICE_SUCCESS = 'EDIT_YACHT_SERVICE_SUCCESS'
export const EDIT_YACHT_SERVICE_FAILURE = 'EDIT_YACHT_SERVICE_FAILURE'

export const GET_SERVICE_DETAIL = 'GET_SERVICE_DETAIL'
export const GET_SERVICE_DETAIL_SUCCESS = 'GET_SERVICE_DETAIL_SUCCESS'
export const GET_SERVICE_DETAIL_FAILURE = 'GET_SERVICE_DETAIL_FAILURE'

export const TOGGLE_SERVICE_STATUS = 'TOGGLE_SERVICE_STATUS'
export const TOGGLE_SERVICE_STATUS_SUCCESS = 'TOGGLE_SERVICE_STATUS_SUCCESS'
export const TOGGLE_SERVICE_STATUS_FAILURE = 'TOGGLE_SERVICE_STATUS_FAILURE'

//update Marina and storage
export const UPDATE_MARINA_STORAGE = 'UPDATE_MARINA_STORAGE'
export const UPDATE_MARINA_STORAGE_SUCCESS = 'UPDATE_MARINA_STORAGE_SUCCESS'
export const UPDATE_MARINA_STORAGE_FAILURE = 'UPDATE_MARINA_STORAGE_FAILURE'

export const GET_CATEGORY_WISE_VIDEOS = 'GET_CATEGORY_WISE_VIDEOS'
export const GET_CATEGORY_WISE_VIDEOS_SUCCESS = 'GET_CATEGORY_WISE_VIDEOS_SUCCESS'
export const GET_CATEGORY_WISE_VIDEOS_FAILURE = 'GET_CATEGORY_WISE_VIDEOS_FAILURE'

export const GET_RENT_CATEGORY_WISE_BOATS = 'GET_RENT_CATEGORY_WISE_BOATS'
export const GET_RENT_CATEGORY_WISE_BOATS_SUCCESS = 'GET_RENT_CATEGORY_WISE_BOATS_SUCCESS'
export const GET_RENT_CATEGORY_WISE_BOATS_FAILURE = 'GET_RENT_CATEGORY_WISE_BOATS_FAILURE'

export const CREATE_REVIEW = 'CREATE_REVIEW'
export const CREATE_REVIEW_SUCCESS = 'CREATE_REVIEW_SUCCESS'
export const CREATE_REVIEW_FAILURE = 'CREATE_REVIEW_FAILURE'

export const GET_REVIEW_BY_MODULE_ID = 'GET_REVIEW_BY_MODULE_ID'
export const GET_REVIEW_BY_MODULE_ID_SUCCESS = 'GET_REVIEW_BY_MODULE_ID_SUCCESS'
export const GET_REVIEW_BY_MODULE_ID_FAILURE = 'GET_REVIEW_BY_MODULE_ID_FAILURE'
//clear edit marina flags
export const CLEAR_EDIT_MARINA_FLAG = 'CLEAR_EDIT_MARINA_FLAG'

//delete marina storage
export const DELETE_MARINA_STORAGE = 'DELETE_MARINA_STORAGE'
export const DELETE_MARINA_STORAGE_SUCCESS = 'DELETE_MARINA_STORAGE_SUCCESS'
export const DELETE_MARINA_STORAGE_FAILURE = 'DELETE_MARINA_STORAGE_FAILURE'

//delete boat show
export const DELETE_BOAT_SHOW = 'DELETE_BOAT_SHOW'
export const DELETE_BOAT_SHOW_SUCCESS = 'DELETE_BOAT_SHOW_SUCCESS'
export const DELETE_BOAT_SHOW_FAILURE = 'DELETE_BOAT_SHOW_FAILURE'

//toggle boat show status
export const TOGGLE_BOAT_SHOW_STATUS = 'TOGGLE_BOAT_SHOW_STATUS'
export const TOGGLE_BOAT_SHOW_STATUS_SUCCESS = 'TOGGLE_BOAT_SHOW_STATUS_SUCCESS'
export const TOGGLE_BOAT_SHOW_STATUS_FAILURE = 'TOGGLE_BOAT_SHOW_STATUS_FAILURE'

//clear edit boat show
export const CLEAR_EDIT_BOAT_SHOW = 'CLEAR_EDIT_BOAT_SHOW'

//Rent inner boat
export const GET_RENTS_INNER_BOAT = 'GET_RENTS_INNER_BOAT'
export const GET_RENTS_INNER_BOAT_SUCCESS = 'GET_RENTS_INNER_BOAT_SUCCESS'
export const GET_RENTS_INNER_BOAT_FAILURE = 'GET_RENTS_INNER_BOAT_FAILURE'

//clear edit boat service
export const CLEAR_EDIT_SERVICE = 'CLEAR_EDIT_SERVICE'

//get most viewed marina list
export const GET_MOST_VIEWED_MARINA_STORAGE = 'GET_MOST_VIEWED_MARINA_STORAGE'
export const GET_MOST_VIEWED_MARINA_STORAGE_SUCCESS = 'GET_MOST_VIEWED_MARINA_STORAGE_SUCCESS'
export const GET_MOST_VIEWED_MARINA_STORAGE_FAILURE = 'GET_MOST_VIEWED_MARINA_STORAGE_FAILURE'

//get most viewed boat services
export const GET_MOST_VIEWED_BOAT_SERVICES = 'GET_MOST_VIEWED_BOAT_SERVICES'
export const GET_MOST_VIEWED_BOAT_SERVICES_SUCCESS = 'GET_MOST_VIEWED_BOAT_SERVICES_SUCCESS'
export const GET_MOST_VIEWED_BOAT_SERVICES_FAILURE = 'GET_MOST_VIEWED_BOAT_SERVICES_FAILURE'

export const CREATE_AUCTION_ROOM = 'CREATE_AUCTION_ROOM'
export const CREATE_AUCTION_ROOM_SUCCESS = 'CREATE_AUCTION_ROOM_SUCCESS'
export const CREATE_AUCTION_ROOM_FAILURE = 'CREATE_AUCTION_ROOM_FAILURE'
// FORGOT PASSWORD
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD'
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS'
export const FORGOT_PASSWORD_FAILURE = 'FORGOT_PASSWORD_FAILURE'

// CLEAR PASSWORD FLAG
export const CLEAR_PASSWORD_FLAG = 'CLEAR_PASSWORD_FLAG'

// SEARCH YACHT SERVICE
export const SEARCH_YACHT_SERVICE = 'SEARCH_YACHT_SERVICE'
export const SEARCH_YACHT_SERVICE_SUCCESS = 'SEARCH_YACHT_SERVICE_SUCCESS'
export const SEARCH_YACHT_SERVICE_FAILURE = 'SEARCH_YACHT_SERVICE_FAILURE'

// SEARCH MARINA & STORAGE
export const SEARCH_MARINA_AND_STORAGE = 'SEARCH_MARINA_AND_STORAGE'
export const SEARCH_MARINA_AND_STORAGE_SUCCESS = 'SEARCH_MARINA_AND_STORAGE_SUCCESS'
export const SEARCH_MARINA_AND_STORAGE_FAILURE = 'SEARCH_MARINA_AND_STORAGE_FAILURE'

//clear auction flag
export const CLEAR_AUCTION_FLAG = 'CLEAR_AUCTION_FLAG'

//get user created auction room
export const GET_USER_AUCTION_ROOMS = 'GET_USER_AUCTION_ROOMS'
export const GET_USER_AUCTION_ROOMS_SUCCESS = 'GET_USER_AUCTION_ROOMS_SUCCESS'
export const GET_USER_AUCTION_ROOMS_FAILURE = 'GET_USER_AUCTION_ROOMS_FAILURE'

//get all bid of auction
export const GET_ALL_AUCTION_BID = 'GET_ALL_AUCTION_BID'
export const GET_ALL_AUCTION_BID_SUCCESS = 'GET_ALL_AUCTION_BID_SUCCESS'
export const GET_ALL_AUCTION_BID_FAILURE = 'GET_ALL_AUCTION_BID_FAILURE'

//clear bid data
export const CLEAR_BID_FLAG = 'CLEAR_BID_FLAG'

//get all auction rooms
export const GET_ALL_AUCTION_ROOMS = 'GET_ALL_AUCTION_ROOMS'
export const GET_ALL_AUCTION_ROOMS_SUCCESS = 'GET_ALL_AUCTION_ROOMS_SUCCESS'
export const GET_ALL_AUCTION_ROOMS_FAILURE = 'GET_ALL_AUCTION_ROOMS_FAILURE'

//change user account status

export const CHANGE_USER_ACCOUNT_STATUS = 'CHANGE_USER_ACCOUNT_STATUS'
export const CHANGE_USER_ACCOUNT_STATUS_SUCCESS = 'CHANGE_USER_ACCOUNT_STATUS_SUCCESS'
export const CHANGE_USER_ACCOUNT_STATUS_FAILURE = 'CHANGE_USER_ACCOUNT_STATUS_FAILURE'

//get all advertisement
export const GET_CATEGORY_WISE_ADVERTISEMENTS = 'GET_CATEGORY_WISE_ADVERTISEMENTS'
export const GET_CATEGORY_WISE_ADVERTISEMENTS_SUCCESS = 'GET_CATEGORY_WISE_ADVERTISEMENTS_SUCCESS'
export const GET_CATEGORY_WISE_ADVERTISEMENTS_FAILURE = 'GET_CATEGORY_WISE_ADVERTISEMENTS_FAILURE'

//get all boat rent types

export const GET_ALL_BOAT_RENT_TYPES = 'GET_ALL_BOAT_RENT_TYPES'
export const GET_ALL_BOAT_RENT_TYPES_SUCCESS = 'GET_ALL_BOAT_RENT_TYPES_SUCCESS'
export const GET_ALL_BOAT_RENT_TYPES_FAILURE = 'GET_ALL_BOAT_RENT_TYPES_FAILURE'

// reserve rent booking

export const CHECK_IF_CAN_BOOK_RENT = 'CHECK_IF_CAN_BOOK_RENT'
export const CHECK_IF_CAN_BOOK_RENT__SUCCESS = 'CHECK_IF_CAN_BOOK_RENT__SUCCESS'
export const CHECK_IF_CAN_BOOK_RENT__FAILURE = 'CHECK_IF_CAN_BOOK_RENT__FAILURE'
export const CLEAR__CHECK_IF_CAN_BOOK_RENT__FLAG = 'CLEAR__CHECK_IF_CAN_BOOK_RENT__FLAG'

// create rent booking

export const RENT_BOOKING_PAYMENT = 'RENT_BOOKING_PAYMENT'
export const CREATE_RENT_BOOKING = 'CREATE_RENT_BOOKING'
export const CREATE_RENT_BOOKING_SUCCESS = 'CREATE_RENT_BOOKING_SUCCESS'
export const CREATE_RENT_BOOKING_FAILURE = 'CREATE_RENT_BOOKING_FAILURE'
export const CLEAR_CREATE_RENT_BOOKING = 'CLEAR_CREATE_RENT_BOOKING'

//error

export const ERROR_MESSAGE_SHOW = 'ERROR_MESSAGE_SHOW'
export const CLEAR_ERROR_MESSAGE = 'CLEAR_ERROR_MESSAGE'

export const GET_ALL_RENT_TYPES = 'GET_ALL_RENT_TYPES'
export const GET_ALL_RENT_TYPES_SUCCESS = 'GET_ALL_RENT_TYPES_SUCCESS'
export const GET_ALL_RENT_TYPES_FAILURE = 'GET_ALL_RENT_TYPES_FAILURE'

// GET RENT BOAT TRIP CITIES
export const GET_RENT_BOAT_TRIP_CITIES = 'GET_RENT_BOAT_TRIP_CITIES'
export const GET_RENT_BOAT_TRIP_CITIES_SUCCESS = 'GET_RENT_BOAT_TRIP_CITIES_SUCCESS'
export const GET_RENT_BOAT_TRIP_CITIES_FAILURE = 'GET_RENT_BOAT_TRIP_CITIES_FAILURE'

//get top rated marina storage
export const GET_TOP_RATED_MARINA_STORAGE = 'GET_TOP_RATED_MARINA_STORAGE'
export const GET_TOP_RATED_MARINA_STORAGE_SUCCESS = 'GET_TOP_RATED_MARINA_STORAGE_SUCCESS'
export const GET_TOP_RATED_MARINA_STORAGE_FAILURE = 'GET_TOP_RATED_MARINA_STORAGE_FAILURE'

//get marina storage by service
export const GET_MARINA_STORAGE_All_SERVICES = 'GET_MARINA_STORAGE_All_SERVICES'
export const GET_MARINA_STORAGE_All_SERVICES_SUCCESS = 'GET_MARINA_STORAGE_All_SERVICES_SUCCESS'
export const GET_MARINA_STORAGE_All_SERVICES_FAILURE = 'GET_MARINA_STORAGE_All_SERVICES_FAILURE'

export const GET_ALL_BOAT_SERVICE_TYPES = 'GET_ALL_BOAT_SERVICE_TYPES'
export const GET_ALL_BOAT_SERVICE_TYPES_SUCCESS = 'GET_ALL_BOAT_SERVICE_TYPES_SUCCESS'
export const GET_ALL_BOAT_SERVICE_TYPES_FAILURE = 'GET_ALL_BOAT_SERVICE_TYPES_FAILURE'

//Archive Sales-Engine-Process
export const ARCHIVE_SALES_PROCESS_TYPES = 'ARCHIVE_SALES_PROCESS_TYPES'
export const ARCHIVE_SALES_PROCESS_TYPES_SUCCESS = 'ARCHIVE_SALES_PROCESS_TYPES_SUCCESS'
export const ARCHIVE_SALES_PROCESS_TYPES_FAILURE = 'ARCHIVE_SALES_PROCESS_TYPES_FAILURE'

export const UNARCHIVE_SALES_PROCESS_TYPES = 'UNARCHIVE_SALES_PROCESS_TYPES'
export const UNARCHIVE_SALES_PROCESS_TYPES_SUCCESS = 'UNARCHIVE_SALES_PROCESS_TYPES_SUCCESS'
export const UNARCHIVE_SALES_PROCESS_TYPES_FAILURE = 'UNARCHIVE_SALES_PROCESS_TYPES_FAILURE'

// Sales-Engines

export const GET_SALES_ENGINE_BY_BUYER = 'GET_SALES_ENGINE_BY_BUYER'
export const GET_SALES_ENGINE_BY_BUYER_SUCCESS = 'GET_SALES_ENGINE_BY_BUYER_SUCCESS'
export const GET_SALES_ENGINE_BY_BUYER_FAILURE = 'GET_SALES_ENGINE_BY_BUYER_FAILURE'

export const GET_SALES_ENGINE_BY_SELLER = 'GET_SALES_ENGINE_BY_SELLER'
export const GET_SALES_ENGINE_BY_SELLER_SUCCESS = 'GET_SALES_ENGINE_BY_SELLER_SUCCESS'
export const GET_SALES_ENGINE_BY_SELLER_FAILURE = 'GET_SALES_ENGINE_BY_SELLER_FAILURE'

export const GET_SALES_ENGINE_BY_SURVEYOR = 'GET_SALES_ENGINE_BY_SURVEYOR'
export const GET_SALES_ENGINE_BY_SURVEYOR_SUCCESS = 'GET_SALES_ENGINE_BY_SURVEYOR_SUCCESS'
export const GET_SALES_ENGINE_BY_SURVEYOR_FAILURE = 'GET_SALES_ENGINE_BY_SURVEYOR_FAILURE'

export const GET_SALES_ENGINE_BY_SHIPPER = 'GET_SALES_ENGINE_BY_SHIPPER'
export const GET_SALES_ENGINE_BY_SHIPPER_SUCCESS = 'GET_SALES_ENGINE_BY_SHIPPER_SUCCESS'
export const GET_SALES_ENGINE_BY_SHIPPER_FAILURE = 'GET_SALES_ENGINE_BY_SHIPPER_FAILURE'

export const GET_SINGLE_SALES_ENGINE = 'GET_SINGLE_SALES_ENGINE'
export const GET_SINGLE_SALES_ENGINE_SUCCESS = 'GET_SINGLE_SALES_ENGINE_SUCCESS'
export const GET_SINGLE_SALES_ENGINE_FAILURE = 'GET_SINGLE_SALES_ENGINE_FAILURE'

export const GET_MODULE_WISE_BANNERS = 'GET_MODULE_WISE_BANNERS'
export const GET_MODULE_WISE_BANNERS_SUCCESS = 'GET_MODULE_WISE_BANNERS_SUCCESS'
export const GET_MODULE_WISE_BANNERS_FAILURE = 'GET_MODULE_WISE_BANNERS_FAILURE'

export const CREATE_SALES_ENGINE = 'CREATE_SALES_ENGINE'
export const CREATE_SALES_ENGINE_SUCCESS = 'CREATE_SALES_ENGINE_SUCCESS'
export const CREATE_SALES_ENGINE_FAILURE = 'CREATE_SALES_ENGINE_FAILURE'

export const ASSIGN_SURVEYOR = 'ASSIGN_SURVEYOR'
export const ASSIGN_SURVEYOR_SUCCESS = 'ASSIGN_SURVEYOR_SUCCESS'
export const ASSIGN_SURVEYOR_FAILURE = 'ASSIGN_SURVEYOR_FAILURE'

export const DECLINE_SURVEYOR = 'DECLINE_SURVEYOR'
export const DECLINE_SURVEYOR_SUCCESS = 'DECLINE_SURVEYOR_SUCCESS'
export const DECLINE_SURVEYOR_FAILURE = 'DECLINE_SURVEYOR_FAILURE'

export const PAYMENT_REQUEST = 'PAYMENT_REQUEST'
export const PAYMENT_REQUEST_SUCCESS = 'PAYMENT_REQUEST_SUCCESS'
export const PAYMENT_REQUEST_FAILURE = 'PAYMENT_REQUEST_FAILURE'

export const SALES_ENGINE_ASSIGN_SHIPPER_REQUEST = 'SALES_ENGINE_ASSIGN_SHIPPER_REQUEST'
export const SALES_ENGINE_ASSIGN_SHIPPER_REQUEST_SUCCESS = 'SALES_ENGINE_ASSIGN_SHIPPER_REQUEST_SUCCESS'
export const SALES_ENGINE_ASSIGN_SHIPPER_REQUEST_FAILURE = 'SALES_ENGINE_ASSIGN_SHIPPER_REQUEST_FAILURE'

export const GET_AGREEMENTS_CONTENTS = 'GET_AGREEMENTS_CONTENTS'
export const GET_AGREEMENTS_CONTENTS_SUCCESS = 'GET_AGREEMENTS_CONTENTS_SUCCESS'
export const GET_AGREEMENTS_CONTENTS_FAILURE = 'GET_AGREEMENTS_CONTENTS_FAILURE'

export const CHECK_AGREEMENT = 'CHECK_AGREEMENT'
export const CHECK_AGREEMENT_SUCCESS = 'CHECK_AGREEMENT_SUCCESS'
export const CHECK_AGREEMENT_FAILURE = 'CHECK_AGREEMENT_FAILURE'

export const GET_ALL_REVIEWS = 'GET_ALL_REVIEWS'
export const GET_ALL_REVIEWS_SUCCESS = 'GET_ALL_REVIEWS_SUCCESS'
export const GET_ALL_REVIEWS_FAILURE = 'GET_ALL_REVIEWS_FAILURE'

export const GET_ALL_MEDIA_ARTICLES = 'GET_ALL_MEDIA_ARTICLES'
export const GET_ALL_MEDIA_ARTICLES_SUCCESS = 'GET_ALL_MEDIA_ARTICLES_SUCCESS'
export const GET_ALL_MEDIA_ARTICLES_FAILURE = 'GET_ALL_MEDIA_ARTICLES_FAILURE'

export const GET_ALL_MEDIA_RECORD = 'GET_ALL_MEDIA_RECORD'
export const GET_ALL_MEDIA_RECORD_SUCCESS = 'GET_ALL_MEDIA_RECORD_SUCCESS'
export const GET_ALL_MEDIA_RECORD_FAILURE = 'GET_ALL_MEDIA_RECORD_FAILURE'

// User-Guide
export const GET_ALL_USER_GUIDE = 'GET_ALL_USER_GUIDE'
export const GET_ALL_USER_GUIDE_SUCCESS = 'GET_ALL_USER_GUIDE_SUCCESS'
export const GET_ALL_USER_GUIDE_FAILURE = 'GET_ALL_USER_GUIDE_FAILURE'

// User-Faq
export const GET_ALL_USER_FAQ = 'GET_ALL_USER_FAQ'
export const GET_ALL_USER_FAQ_SUCCESS = 'GET_ALL_USER_FAQ_SUCCESS'
export const GET_ALL_USER_FAQ_FAILURE = 'GET_ALL_USER_FAQ_FAILURE'

// GET CITY WISE RENT BOAT
export const GET_RENT_CITY_WISE_BOATS = 'GET_RENT_CITY_WISE_BOATS'
export const GET_RENT_CITY_WISE_BOATS_SUCCESS = 'GET_RENT_CITY_WISE_BOATS_SUCCESS'
export const GET_RENT_CITY_WISE_BOATS_FAILURE = 'GET_RENT_CITY_WISE_BOATS_FAILURE'

export const GET_DASHBOARD_COUNT = 'GET_DASHBOARD_COUNT'
export const GET_DASHBOARD_COUNT_SUCCESS = 'GET_DASHBOARD_COUNT_SUCCESS'
export const GET_DASHBOARD_COUNT_FAILURE = 'GET_DASHBOARD_COUNT_FAILURE'

export const GET_ALL_PAGE_INFORMATION_BY_TYPE = 'GET_ALL_PAGE_INFORMATION_BY_TYPE'
export const GET_ALL_PAGE_INFORMATION_BY_TYPE_SUCCESS = 'GET_ALL_PAGE_INFORMATION_BY_TYPE_SUCCESS'
export const GET_ALL_PAGE_INFORMATION_BY_TYPE_FAILURE = 'GET_ALL_PAGE_INFORMATION_BY_TYPE_FAILURE'

export const GET_RENT_PAGES = 'GET_RENT_PAGES'
export const GET_RENT_PAGES_SUCCESS = 'GET_RENT_PAGES_SUCCESS'
export const GET_RENT_PAGES_FAILURE = 'GET_RENT_PAGES_FAILURE'

export const GET_AGREEMENT = 'GET_AGREEMENT'
export const GET_AGREEMENT_SUCCESS = 'GET_AGREEMENT_SUCCESS'
export const GET_AGREEMENT_FAILURE = 'GET_AGREEMENT_FAILURE'
export const CLEAR__GET_AGREEMENT = 'CLEAR__GET_AGREEMENT'

export const GET_STATIC_PAGES = 'GET_STATIC_PAGES'
export const GET_STATIC_PAGES_SUCCESS = 'GET_STATIC_PAGES_SUCCESS'
export const GET_STATIC_PAGES_FAILURE = 'GET_STATIC_PAGES_FAILURE'
export const CLEAR__GET_STATIC_PAGES = 'CLEAR__GET_STATIC_PAGES'

export const GET_SALES_ENGINE_PAGES = 'GET_SALES_ENGINE_PAGES'
export const GET_SALES_ENGINE_PAGES_SUCCESS = 'GET_SALES_ENGINE_PAGES_SUCCESS'
export const GET_SALES_ENGINE_PAGES_FAILURE = 'GET_SALES_ENGINE_PAGES_FAILURE'

export const CLEAR_RENT_PAGES_FLAG = 'CLEAR_RENT_PAGES_FLAG'

export const UPDATE_RENT_BOAT = 'UPDATE_RENT_BOAT'
export const UPDATE_RENT_BOAT_SUCCESS = 'UPDATE_RENT_BOAT_SUCCESS'
export const UPDATE_RENT_BOAT_FAILURE = 'UPDATE_RENT_BOAT_FAILURE'

//clear pagetype flag
export const CLEAR_PAGE_INFO_BY_TYPE_FLAG = 'CLEAR_PAGE_INFO_BY_TYPE_FLAG'
export const CLEAR_MODEL_SIZE_FLAG = 'CLEAR_MODEL_SIZE_FLAG'

// CREATE AUCTION BID
export const CREATE_AUCTION_BID = 'CREATE_AUCTION_BID'
export const CREATE_AUCTION_BID_SUCCESS = 'CREATE_AUCTION_BID_SUCCESS'
export const CREATE_AUCTION_BID_FAILURE = 'CREATE_AUCTION_BID_FAILURE'

export const CLEAR_FLAG_AUCTION_BID = 'CLEAR_FLAG_AUCTION_BID'
export const CLEAR_REVIEW_FLAG = 'CLEAR_REVIEW_FLAG'

//accept surveyor sales engine request
export const SALES_ENGINE_SURVEYOR_ACCEPT_BUYER = 'SALES_ENGINE_SURVEYOR_ACCEPT_BUYER'
export const SALES_ENGINE_SURVEYOR_ACCEPT_BUYER_SUCCESS = 'SALES_ENGINE_SURVEYOR_ACCEPT_BUYER_SUCCESS'
export const SALES_ENGINE_SURVEYOR_ACCEPT_BUYER_FAILURE = 'SALES_ENGINE_SURVEYOR_ACCEPT_BUYER_FAILURE'

// decline surveyor sales engine request
export const SALES_ENGINE_SURVEYOR_DECLINE_BUYER = 'SALES_ENGINE_SURVEYOR_DECLINE_BUYER'
export const SALES_ENGINE_SURVEYOR_DECLINE_BUYER_SUCCESS = 'SALES_ENGINE_SURVEYOR_DECLINE_BUYER_SUCCESS'
export const SALES_ENGINE_SURVEYOR_DECLINE_BUYER_FAILURE = 'SALES_ENGINE_SURVEYOR_DECLINE_BUYER_FAILURE'

//create surveyor report
export const CREATE_SURVEYOR_REPORT = 'CREATE_SURVEYOR_REPORT'
export const CREATE_SURVEYOR_REPORT_SUCCESS = 'CREATE_SURVEYOR_REPORT_SUCCESS'
export const CREATE_SURVEYOR_REPORT_FAILURE = 'CREATE_SURVEYOR_REPORT_FAILURE'

//get surveyor report
export const GET_SURVEYOR_REPORT = 'GET_SURVEYOR_REPORT'
export const GET_SURVEYOR_REPORT_SUCCESS = 'GET_SURVEYOR_REPORT_SUCCESS'
export const GET_SURVEYOR_REPORT_FAILURE = 'GET_SURVEYOR_REPORT_FAILURE'

export const SHIPPER_ACCEPT_SHIPMENT_REQUEST = 'SHIPPER_ACCEPT_SHIPMENT_REQUEST'
export const SHIPPER_ACCEPT_SHIPMENT_REQUEST_SUCCESS = 'SHIPPER_ACCEPT_SHIPMENT_REQUEST_SUCCESS'
export const SHIPPER_ACCEPT_SHIPMENT_REQUEST_FAILURE = 'SHIPPER_ACCEPT_SHIPMENT_REQUEST_FAILURE'

export const SHIPPER_DECLINE_SHIPMENT_REQUEST = 'SHIPPER_DECLINE_SHIPMENT_REQUEST'
export const SHIPPER_DECLINE_SHIPMENT_REQUEST_SUCCESS = 'SHIPPER_DECLINE_SHIPMENT_REQUEST_SUCCESS'
export const SHIPPER_DECLINE_SHIPMENT_REQUEST_FAILURE = 'SHIPPER_DECLINE_SHIPMENT_REQUEST_FAILURE'

export const BOAT_BY_CITIES = 'BOAT_BY_CITIES'
export const BOAT_BY_CITIES_SUCCESS = 'BOAT_BY_CITIES_SUCCESS'
export const BOAT_BY_CITIES_FAILURE = 'BOAT_BY_CITIES_FAILURE'
export const BOAT_BY_CITIES_CLEAR = 'BOAT_BY_CITIES_CLEAR'

export const GET_EXPERIENCES = 'GET_EXPERIENCES'
export const GET_EXPERIENCES_SUCCESS = 'GET_EXPERIENCES_SUCCESS'
export const GET_EXPERIENCES_FAILURE = 'GET_EXPERIENCES_FAILURE'

export const ADD_SHIPMENT_PROPOSAL = 'ADD_SHIPMENT_PROPOSAL'
export const ADD_SHIPMENT_PROPOSAL_SUCCESS = 'ADD_SHIPMENT_PROPOSAL_SUCCESS'
export const ADD_SHIPMENT_PROPOSAL_FAILURE = 'ADD_SHIPMENT_PROPOSAL_FAILURE'

export const GET_SINGLE_SHIPMENT_PROPOSAL = 'GET_SINGLE_SHIPMENT_PROPOSAL'
export const GET_SINGLE_SHIPMENT_PROPOSAL_SUCCESS = 'GET_SINGLE_SHIPMENT_PROPOSAL_SUCCESS'
export const GET_SINGLE_SHIPMENT_PROPOSAL_FAILURE = 'GET_SINGLE_SHIPMENT_PROPOSAL_FAILURE'

export const UPDATE_SHIPMENT_PROPOSAL = 'UPDATE_SHIPMENT_PROPOSAL'
export const UPDATE_SHIPMENT_PROPOSAL_SUCCESS = 'UPDATE_SHIPMENT_PROPOSAL_SUCCESS'
export const UPDATE_SHIPMENT_PROPOSAL_FAILURE = 'UPDATE_SHIPMENT_PROPOSAL_FAILURE'

export const GET_ALL_SHIPMENT_PROPOSAL = 'GET_ALL_SHIPMENT_PROPOSAL'
export const GET_ALL_SHIPMENT_PROPOSAL_SUCCESS = 'GET_ALL_SHIPMENT_PROPOSAL_SUCCESS'
export const GET_ALL_SHIPMENT_PROPOSAL_FAILURE = 'GET_ALL_SHIPMENT_PROPOSAL_FAILURE'

export const GET_USER_LOCATION = 'GET_USER_LOCATION'
export const GET_USER_LOCATION_SUCCESS = 'GET_USER_LOCATION_SUCCESS'
export const GET_USER_LOCATION_FAILURE = 'GET_USER_LOCATION_FAILURE'

export const GET_SITE_CONFIGURATIONS = 'GET_SITE_CONFIGURATIONS'
export const GET_SITE_CONFIGURATIONS_SUCCESS = 'GET_SITE_CONFIGURATIONS_SUCCESS'
export const GET_SITE_CONFIGURATIONS_FAILURE = 'GET_SITE_CONFIGURATIONS_FAILURE'

export const ADD_NEGOTIABLE_PRICE = 'ADD_NEGOTIABLE_PRICE'
export const ADD_NEGOTIABLE_PRICE_SUCCESS = 'ADD_NEGOTIABLE_PRICE_SUCCESS'
export const ADD_NEGOTIABLE_PRICE_FAILURE = 'ADD_NEGOTIABLE_PRICE_FAILURE'

export const GET_RENT_TRIP_CITY_WISE = 'GET_RENT_TRIP_CITY_WISE'
export const GET_RENT_TRIP_CITY_WISE_SUCCESS = 'GET_RENT_TRIP_CITY_WISE_SUCCESS'
export const GET_RENT_TRIP_CITY_WISE_FAILURE = 'GET_RENT_TRIP_CITY_WISE_FAILURE'

export const GET_MARINA_BY_TYPE = 'GET_MARINA_BY_TYPE'
export const GET_MARINA_BY_TYPE_SUCCESS = 'GET_MARINA_BY_TYPE_SUCCESS'
export const GET_MARINA_BY_TYPE_FAILURE = 'GET_MARINA_BY_TYPE_FAILURE'

export const CLEAR_REPORT_FLAG = 'CLEAR_REPORT_FLAG'
export const CLEAR_AGENT_FLAG = 'CLEAR_AGENT_FLAG'
export const CLEAR_PAYMENT_FLAG = 'CLEAR_PAYMENT_FLAG'
export const CLEAR_SALES_ENGINES = 'CLEAR_SALES_ENGINES'
export const CLEAR_CREATE_SALES_ENGINE_FLAG = 'CLEAR_CREATE_SALES_ENGINE_FLAG'
export const CLEAR_SALES_ENGINE_MY_BOAT_FLAG = 'CLEAR_SALES_ENGINE_MY_BOAT_FLAG'
export const CLEAR_SALES_ENGINE_PAYMENT_FLAG = 'CLEAR_SALES_ENGINE_PAYMENT_FLAG'
export const CLEAR_SALES_ENGINE_SHIPMENT_FLAG = 'CLEAR_SALES_ENGINE_SHIPMENT_FLAG'
export const CLEAR_GET_SINGLE_SALES_ENGINE_FLAG = 'CLEAR_GET_SINGLE_SALES_ENGINE_FLAG'
export const CLEAR_SALES_ENGINE_SURVEYOR_DASHBOARD_FLAG = 'CLEAR_SALES_ENGINE_SURVEYOR_DASHBOARD_FLAG'
export const CLEAR_SALES_ENGINE_SHIPPER_DASHBOARD_FLAG = 'CLEAR_SALES_ENGINE_SHIPPER_DASHBOARD_FLAG'
export const CLEAR_SALES_ENGINE_DASHBOARD_FLAG = 'CLEAR_SALES_ENGINE_DASHBOARD_FLAG'

export const ADD_SURVEY_OPTION = 'ADD_SURVEY_OPTION'
export const ADD_SURVEY_OPTION_SUCCESS = 'ADD_SURVEY_OPTION_SUCCESS'
export const ADD_SURVEY_OPTION_FAILURE = 'ADD_SURVEY_OPTION_FAILURE'

export const SKIP_SHIPMENT = 'SKIP_SHIPMENT'
export const SKIP_SHIPMENT_SUCCESS = 'SKIP_SHIPMENT_SUCCESS'
export const SKIP_SHIPMENT_FAILURE = 'SKIP_SHIPMENT_FAILURE'

//request agent
export const REQUEST_AGENT = 'REQUEST_AGENT'
export const REQUEST_AGENT_SUCCESS = 'REQUEST_AGENT_SUCCESS'
export const REQUEST_AGENT_FAILURE = 'REQUEST_AGENT_FAILURE'

//assign agents
export const ASSIGN_AGENT = 'ASSIGN_AGENT'
export const ASSIGN_AGENT_SUCCESS = 'ASSIGN_AGENT_SUCCESS'
export const ASSIGN_AGENT_FAILURE = 'ASSIGN_AGENT_FAILURE'

//clear sales engine agents flag

export const GET_BOAT_SEARCH_MINIMUM_VALUE = 'GET_BOAT_SEARCH_MINIMUM_VALUE'
export const GET_BOAT_SEARCH_MINIMUM_VALUE_SUCCESS = 'GET_BOAT_SEARCH_MINIMUM_VALUE_SUCCESS'
export const GET_BOAT_SEARCH_MINIMUM_VALUE_FAILURE = 'GET_BOAT_SEARCH_MINIMUM_VALUE_FAILURE'

//get sales engine by agent
export const GET_SALES_ENGINE_BY_AGENT = 'GET_SALES_ENGINE_BY_AGENT'
export const GET_SALES_ENGINE_BY_AGENT_SUCCESS = 'GET_SALES_ENGINE_BY_AGENT_SUCCESS'
export const GET_SALES_ENGINE_BY_AGENT_FAILURE = 'GET_SALES_ENGINE_BY_AGENT_FAILURE'

export const GET_DOCUMENT_LINKS = 'GET_DOCUMENT_LINKS'
export const GET_DOCUMENT_LINKS_SUCCESS = 'GET_DOCUMENT_LINKS_SUCCESS'
export const GET_DOCUMENT_LINKS_FAILURE = 'GET_DOCUMENT_LINKS_FAILURE'

export const ADD_BOAT_SHIPMENT_LOCATION = 'ADD_BOAT_SHIPMENT_LOCATION'
export const ADD_BOAT_SHIPMENT_LOCATION_SUCCESS = 'ADD_BOAT_SHIPMENT_LOCATION_SUCCESS'
export const ADD_BOAT_SHIPMENT_LOCATION_FAILURE = 'ADD_BOAT_SHIPMENT_LOCATION_FAILURE'

export const GET_COST_ESTIMATE = 'GET_COST_ESTIMATE'
export const GET_COST_ESTIMATE_SUCCESS = 'GET_COST_ESTIMATE_SUCCESS'
export const GET_COST_ESTIMATE_FAILURE = 'GET_COST_ESTIMATE_FAILURE'

//start shipment
export const START_SHIPMENT = 'START_SHIPMENT'
export const START_SHIPMENT_SUCCESS = 'START_SHIPMENT_SUCCESS'
export const START_SHIPMENT_FAILURE = 'START_SHIPMENT_FAILURE'

//complete shipment
export const COMPLETE_SHIPMENT = 'COMPLETE_SHIPMENT'
export const COMPLETE_SHIPMENT_SUCCESS = 'COMPLETE_SHIPMENT_SUCCESS'
export const COMPLETE_SHIPMENT_FAILURE = 'COMPLETE_SHIPMENT_FAILURE'

//add shipper final document
export const ADD_SHIPPER_DOCUMENTS = 'ADD_SHIPPER_DOCUMENTS'
export const ADD_SHIPPER_DOCUMENTS_SUCCESS = 'ADD_SHIPPER_DOCUMENTS_SUCCESS'
export const ADD_SHIPPER_DOCUMENTS_FAILURE = 'ADD_SHIPPER_DOCUMENTS_FAILURE'

//clear sales engine shipper document flag
export const CLEAR_SHIPPER_DOCUMENT_FLAG = 'CLEAR_SHIPPER_DOCUMENT_FLAG'

export const GET_RENT_BOAT_TRIP_DETAILS = 'GET_RENT_BOAT_TRIP_DETAILS'
export const GET_RENT_BOAT_TRIP_DETAILS__SUCCESS = 'GET_RENT_BOAT_TRIP_DETAILS__SUCCESS'
export const GET_RENT_BOAT_TRIP_DETAILS__FAILURE = 'GET_RENT_BOAT_TRIP_DETAILS__FAILURE'
export const CLEAR__GET_RENT_BOAT_TRIP_DETAILS = 'CLEAR__GET_RENT_BOAT_TRIP_DETAILS'

//update rent boat trip details
export const UPDATE_RENT_BOAT_TRIP_DETAILS = 'UPDATE_RENT_BOAT_TRIP_DETAILS'
export const UPDATE_RENT_BOAT_TRIP_DETAILS__SUCCESS = 'UPDATE_RENT_BOAT_TRIP_DETAILS__SUCCESS'
export const UPDATE_RENT_BOAT_TRIP_DETAILS__FAILURE = 'UPDATE_RENT_BOAT_TRIP_DETAILS__FAILURE'
export const CLEAR__UPDATE_RENT_BOAT_TRIP_DETAILS = 'CLEAR__UPDATE_RENT_BOAT_TRIP_DETAILS'

//clear update  trip type
export const CLEAR_UPDATE_TRIP_FLAG = 'CLEAR_UPDATE_TRIP_FLAG'

//book rent boat
export const BOAT_RENT_BOOKING = 'BOAT_RENT_BOOKING'
export const BOAT_RENT_BOOKING_SUCCESS = 'BOAT_RENT_BOOKING_SUCCESS'
export const BOAT_RENT_BOOKING_FAILURE = 'BOAT_RENT_BOOKING_FAILURE'

export const MODULE_PAGE_VISIT = 'MODULE_PAGE_VISIT'
export const MODULE_PAGE_VISIT_SUCCESS = 'MODULE_PAGE_VISIT_SUCCESS'
export const MODULE_PAGE_VISIT_FAILURE = 'MODULE_PAGE_VISIT_FAILURE'

export const CLEAR_MODULE_PAGE_VISIT = 'CLEAR_MODULE_PAGE_VISIT'

export const GET_USER_PAYMENT_INFO = 'GET_USER_PAYMENT_INFO'
export const GET_USER_PAYMENT_INFO_SUCCESS = 'GET_USER_PAYMENT_INFO_SUCCESS'
export const GET_USER_PAYMENT_INFO_FAILURE = 'GET_USER_PAYMENT_INFO_FAILURE'

export const ADD_USER_PAYMENT_INFO = 'ADD_USER_PAYMENT_INFO'
export const ADD_USER_PAYMENT_INFO_SUCCESS = 'ADD_USER_PAYMENT_INFO_SUCCESS'
export const ADD_USER_PAYMENT_INFO_FAILURE = 'ADD_USER_PAYMENT_INFO_FAILURE'

export const CLEAR_USER_PAYMENT_INFO_FLAG = 'CLEAR_USER_PAYMENT_INFO_FLAG'

export const NOTIFY_ME = 'NOTIFY_ME'
export const NOTIFY_ME_SUCCESS = 'NOTIFY_ME_SUCCESS'
export const NOTIFY_ME_FAILURE = 'NOTIFY_ME_FAILURE'

export const CLEAR_NOTIFY_ME_FLAG = 'CLEAR_NOTIFY_ME_FLAG'

//get boat cost estimation
export const GET_BOAT_COST_ESTIMATION = 'GET_BOAT_COST_ESTIMATION'
export const GET_BOAT_COST_ESTIMATION_SUCCESS = 'GET_BOAT_COST_ESTIMATION_SUCCESS'
export const GET_BOAT_COST_ESTIMATION_FAILURE = 'GET_BOAT_COST_ESTIMATION_FAILURE'

//mail boat cost estimation
export const MAIL_BOAT_COST_ESTIMATION = 'MAIL_BOAT_COST_ESTIMATION'
export const MAIL_BOAT_COST_ESTIMATION_SUCCESS = 'MAIL_BOAT_COST_ESTIMATION_SUCCESS'
export const MAIL_BOAT_COST_ESTIMATION_FAILURE = 'MAIL_BOAT_COST_ESTIMATION_FAILURE'

export const ADD_LIKE = 'ADD_LIKE'
export const ADD_LIKE_SUCCESS = 'ADD_LIKE_SUCCESS'
export const ADD_LIKE_FAILURE = 'ADD_LIKE_FAILURE'
export const CLEAR__ADD_LIKE = 'CLEAR__ADD_LIKE'

export const CONVERT_LIKES_FROM_SESSION_TO_USER = 'CONVERT_LIKES_FROM_SESSION_TO_USER'
export const CONVERT_LIKES_FROM_SESSION_TO_USER_SUCCESS = 'CONVERT_LIKES_FROM_SESSION_TO_USER_SUCCESS'
export const CONVERT_LIKES_FROM_SESSION_TO_USER_FAILURE = 'CONVERT_LIKES_FROM_SESSION_TO_USER_FAILURE'

//get all user bookings
export const GET_USER_BOOKINGS = 'GET_USER_BOOKINGS'
export const GET_USER_BOOKINGS_SUCCESS = 'GET_USER_BOOKINGS_SUCCESS'
export const GET_USER_BOOKINGS_FAILURE = 'GET_USER_BOOKINGS_FAILURE'

//clear booking flag
export const CLEAR_BOOKING_FLAG = 'CLEAR_BOOKING_FLAG'

export const GET_CURRENCY_RATES = 'GET_CURRENCY_RATES'
export const GET_CURRENCY_RATES_SUCCESS = 'GET_CURRENCY_RATES_SUCCESS'
export const GET_CURRENCY_RATES_FAILURE = 'GET_CURRENCY_RATES_FAILURE'

export const SET_CURRENT_CURRENCY = 'SET_CURRENT_CURRENCY'

// Wishlist
export const CREATE_WISHLIST = 'CREATE_WISHLIST'
export const CREATE_WISHLIST_SUCCESS = 'CREATE_WISHLIST_SUCCESS'
export const CREATE_WISHLIST_FAILURE = 'CREATE_WISHLIST_FAILURE'

export const REMOVE_WISHLIST = 'REMOVE_WISHLIST'
export const REMOVE_WISHLIST_SUCCESS = 'REMOVE_WISHLIST_SUCCESS'
export const REMOVE_WISHLIST_FAILURE = 'REMOVE_WISHLIST_FAILURE'

export const GET_WISHLISTS = 'GET_WISHLISTS'
export const GET_WISHLISTS_SUCCESS = 'GET_WISHLISTS_SUCCESS'
export const GET_WISHLISTS_FAILURE = 'GET_WISHLISTS_FAILURE'

export const CONVERT_WISHLISTS_FROM_SESSION_TO_USER = 'CONVERT_WISHLISTS_FROM_SESSION_TO_USER'
export const CONVERT_WISHLISTS_FROM_SESSION_TO_USER_SUCCESS = 'CONVERT_WISHLISTS_FROM_SESSION_TO_USER_SUCCESS'
export const CONVERT_WISHLISTS_FROM_SESSION_TO_USER_FAILURE = 'CONVERT_WISHLISTS_FROM_SESSION_TO_USER_FAILURE'
export const CLEAR_CONVERT_WISHLISTS_FROM_SESSION_TO_USER_FLAG = 'CLEAR_CONVERT_WISHLISTS_FROM_SESSION_TO_USER_FLAG'

export const CLEAR_WISHLIST_FLAG = 'CLEAR_WISHLIST_FLAG'

//get single article
export const GET_SINGLE_ARTICLE = 'GET_SINGLE_ARTICLE'
export const GET_SINGLE_ARTICLE_SUCCESS = 'GET_SINGLE_ARTICLE_SUCCESS'
export const GET_SINGLE_ARTICLE_FAILURE = 'GET_SINGLE_ARTICLE_FAILURE'

//update article
export const UPDATE_ARTICLE = 'UPDATE_ARTICLE'
export const UPDATE_ARTICLE_SUCCESS = 'UPDATE_ARTICLE_SUCCESS'
export const UPDATE_ARTICLE_FAILURE = 'UPDATE_ARTICLE_FAILURE'

//delete article
export const DELETE_ARTICLE = 'DELETE_ARTICLE'
export const DELETE_ARTICLE_SUCCESS = 'DELETE_ARTICLE_SUCCESS'
export const DELETE_ARTICLE_FAILURE = 'DELETE_ARTICLE_FAILURE'

//clear service types flag
export const CLEAR_SERVICE_TYPES_FLAG = 'CLEAR_SERVICE_TYPES_FLAG'

//add feedback
export const ADD_FEEDBACK = 'ADD_FEEDBACK'
export const ADD_FEEDBACK_SUCCESS = 'ADD_FEEDBACK_SUCCESS'
export const ADD_FEEDBACK_FAILURE = 'ADD_FEEDBACK_FAILURE'

//clear feedback
export const CLEAR_FEEDBACK_FLAG = 'CLEAR_FEEDBACK_FLAG'

//get users
export const GET_USER_LIST = 'GET_USER_LIST'
export const GET_USER_LIST_SUCCESS = 'GET_USER_LIST_SUCCESS'
export const GET_USER_LIST_FAILURE = 'GET_USER_LIST_FAILURE'

//share with adamsea User
export const SHARE_DETAILS_WITH_USERS = 'SHARE_DETAILS_WITH_USERS'
export const SHARE_DETAILS_WITH_USERS_SUCCESS = 'SHARE_DETAILS_WITH_USERS_SUCCESS'
export const SHARE_DETAILS_WITH_USERS_FAILURE = 'SHARE_DETAILS_WITH_USERS_FAILURE'

//Update current user email
export const UPDATE_EMAIL_SUCCESS = 'UPDATE_EMAIL_SUCCESS'

//change boat rent status
export const CHANGE_RENT_BOAT_STATUS = 'CHANGE_RENT_BOAT_STATUS'
export const CHANGE_RENT_BOAT_STATUS_SUCCESS = 'CHANGE_RENT_BOAT_STATUS_SUCCESS'
export const CHANGE_RENT_BOAT_STATUS_FAILURE = 'CHANGE_RENT_BOAT_STATUS_FAILURE'

//clear boat rent status flag
export const CLEAR_BOAT_RENT_STATUS_FLAG = 'CLEAR_BOAT_RENT_STATUS_FLAG'

//clear share flag
export const CLEAR_SHARE_FLAG = 'CLEAR_SHARE_FLAG'

//get auction room by bid user
export const GET_AUCTION_ROOM_BY_BID_USER = 'GET_AUCTION_ROOM_BY_BID_USER'
export const GET_AUCTION_ROOM_BY_BID_USER_SUCCESS = 'GET_AUCTION_ROOM_BY_BID_USER_SUCCESS'
export const GET_AUCTION_ROOM_BY_BID_USER_FAILURE = 'GET_AUCTION_ROOM_BY_BID_USER_FAILURE'

//get all dealers
export const GET_ALL_DEALERS = 'GET_ALL_DEALERS'
export const GET_ALL_DEALERS_SUCCESS = 'GET_ALL_DEALERS_SUCCESS'
export const GET_ALL_DEALERS_FAILURE = 'GET_ALL_DEALERS_FAILURE'

//request for agreement Id
export const ADD_REQUEST_FOR_AGREEMENT_ID = 'ADD_REQUEST_FOR_AGREEMENT_ID'
export const ADD_REQUEST_FOR_AGREEMENT_ID_SUCCESS = 'ADD_REQUEST_FOR_AGREEMENT_ID_SUCCESS'
export const ADD_REQUEST_FOR_AGREEMENT_ID_FAILURE = 'ADD_REQUEST_FOR_AGREEMENT_ID_FAILURE'

//clear agreement id request flag
export const CLEAR_AGREEMENT_ID_REQUEST_FLAG = 'CLEAR_AGREEMENT_ID_REQUEST_FLAG'

//get user by agreementId
export const GET_USER_BY_AGREEMENT_ID = 'GET_USER_BY_AGREEMENT_ID'
export const GET_USER_BY_AGREEMENT_ID_SUCCESS = 'GET_USER_BY_AGREEMENT_ID_SUCCESS'
export const GET_USER_BY_AGREEMENT_ID_FAILURE = 'GET_USER_BY_AGREEMENT_ID_FAILURE'

//remove agreement Id data
export const REMOVE_AGREEMENT_ID_DATA = 'REMOVE_AGREEMENT_ID_DATA'

//update user agreement data
export const UPDATE_USER_DETAILS_BY_AGREEMENT_ID = 'UPDATE_USER_DETAILS_BY_AGREEMENT_ID'
export const UPDATE_USER_DETAILS_BY_AGREEMENT_ID_SUCCESS = 'UPDATE_USER_DETAILS_BY_AGREEMENT_ID_SUCCESS'
export const UPDATE_USER_DETAILS_BY_AGREEMENT_ID_FAILURE = 'UPDATE_USER_DETAILS_BY_AGREEMENT_ID_FAILURE'

export const CLEAR_REMOVE_AGREEMENT_ID_FLAG = 'CLEAR_REMOVE_AGREEMENT_ID_FLAG'
export const CLEAR_AGREEMENT_CREATE_MESSAGE = 'CLEAR_AGREEMENT_CREATE_MESSAGE'

//Global Search
export const GLOBAL_SEARCH = 'GLOBAL_SEARCH'
export const GLOBAL_SEARCH_SUCCESS = 'GLOBAL_SEARCH_SUCCESS'
export const GLOBAL_SEARCH_FAILURE = 'GLOBAL_SEARCH_FAILURE'

export const CLEAR_GLOBAL_SEARCH = 'CLEAR_GLOBAL_SEARCH'

// assign agreement id
export const ASSIGN_AGREEMENT_ID = 'ASSIGN_AGREEMENT_ID'
export const ASSIGN_AGREEMENT_ID_SUCCESS = 'ASSIGN_AGREEMENT_ID_SUCCESS'
export const ASSIGN_AGREEMENT_ID_FAILURE = 'ASSIGN_AGREEMENT_ID_FAILURE'

// Flag Report
export const ADD_FLAG_REPORT = 'ADD_FLAG_REPORT'
export const ADD_FLAG_REPORT_SUCCESS = 'ADD_FLAG_REPORT_SUCCESS'
export const ADD_FLAG_REPORT_FAILURE = 'ADD_FLAG_REPORT_FAILURE'

export const CLEAR_FLAG_REPORT = 'CLEAR_FLAG_REPORT'

//change marina status
export const CHANGE_MARINA_STATUS = 'CHANGE_MARINA_STATUS'
export const CHANGE_MARINA_STATUS_SUCCESS = 'CHANGE_MARINA_STATUS_SUCCESS'
export const CHANGE_MARINA_STATUS_FAILURE = 'CHANGE_MARINA_STATUS_FAILURE'

export const GET_AUCTION_BY_BOAT_ID = 'GET_AUCTION_BY_BOAT_ID'
export const GET_AUCTION_BY_BOAT_ID_SUCCESS = 'GET_AUCTION_BY_BOAT_ID_SUCCESS'
export const GET_AUCTION_BY_BOAT_ID_FAILURE = 'GET_AUCTION_BY_BOAT_ID_FAILURE'

// PAYMENT
export const GET_PAYMENT_METHODS = 'GET_PAYMENT_METHODS'
export const GET_PAYMENT_METHODS_SUCCESS = 'GET_PAYMENT_METHODS_SUCCESS'
export const GET_PAYMENT_METHODS_FAILURE = 'GET_PAYMENT_METHODS_FAILURE'

export const CREATE_PAYMENT_METHOD = 'CREATE_PAYMENT_METHOD'
export const CREATE_PAYMENT_METHOD_SUCCESS = 'CREATE_PAYMENT_METHOD_SUCCESS'
export const CREATE_PAYMENT_METHOD_FAILURE = 'CREATE_PAYMENT_METHOD_FAILURE'

export const DELETE_PAYMENT_METHOD = 'DELETE_PAYMENT_METHOD'
export const DELETE_PAYMENT_METHOD_SUCCESS = 'DELETE_PAYMENT_METHOD_SUCCESS'
export const DELETE_PAYMENT_METHOD_FAILURE = 'DELETE_PAYMENT_METHOD_FAILURE'

export const CREATE_PAYMENT = 'CREATE_PAYMENT'
export const CREATE_PAYMENT_SUCCESS = 'CREATE_PAYMENT_SUCCESS'
export const CREATE_PAYMENT_FAILURE = 'CREATE_PAYMENT_FAILURE'

// ADMIN LOGIN AS USER
export const ADMIN_LOGIN_AS_USER = 'ADMIN_LOGIN_AS_USER'
export const ADMIN_LOGIN_AS_USER_SUCCESS = 'ADMIN_LOGIN_AS_USER_SUCCESS'
export const ADMIN_LOGIN_AS_USER_FAILURE = 'ADMIN_LOGIN_AS_USER_FAILURE'

export const CLEAR_ADMIN_LOGIN_FLAG = 'CLEAR_ADMIN_LOGIN_FLAG'

// get module pdf
export const GET_MODULE_PDF = 'GET_MODULE_PDF'
export const GET_MODULE_PDF_SUCCESS = 'GET_MODULE_PDF_SUCCESS'
export const GET_MODULE_PDF_FAILURE = 'GET_MODULE_PDF_FAILURE'

//clear pdf flag
export const CLEAR_PDF_FLAG = 'CLEAR_PDF_FLAG'

//clear shipper flag
export const CLEAR_SHIPPER_REQUEST_FLAG = 'CLEAR_SHIPPER_REQUEST_FLAG'

// GET DOCUMENT URL
export const GET_DOCUMENT_URL = 'GET_DOCUMENT_URL'
export const GET_DOCUMENT_URL_SUCCESS = 'GET_DOCUMENT_URL_SUCCESS'
export const GET_DOCUMENT_URL_FAILURE = 'GET_DOCUMENT_URL_FAILURE'

export const CLEAR_DOCUMENT_URL = 'CLEAR_DOCUMENT_URL'

export const EMAIL_VERIFICATION_CHECK = 'EMAIL_VERIFICATION_CHECK'
export const CLOSE_EMAIL_VERIFICATION_MODAL = 'CLOSE_EMAIL_VERIFICATION_MODAL'
export const IS_VISIT_FIRST_TIME = 'IS_VISIT_FIRST_TIME'
export const CLEAR_IS_VISIT_FIRST_TIME = 'CLEAR_IS_VISIT_FIRST_TIME'

export const NOTIFICATION_MESSAGE = 'NOTIFICATION_MESSAGE'
export const INFO_NOTIFICATION_MESSAGE = 'INFO_NOTIFICATION_MESSAGE'
export const ERROR_NOTIFICATION_MESSAGE = 'ERROR_NOTIFICATION_MESSAGE'
export const CLEAR_NOTIFICATION_MESSAGE = 'CLEAR_NOTIFICATION_MESSAGE'

//discard sales engine agent process
export const DISCARD_AGENT = 'DISCARD_AGENT'
export const DISCARD_AGENT_SUCCESS = 'DISCARD_AGENT_SUCCESS'
export const DISCARD_AGENT_FAILURE = 'DISCARD_AGENT_FAILURE'

//clear discard flag
export const CLEAR_DISCARD_AGENT_FLAG = 'CLEAR_DISCARD_AGENT_FLAG'

// assign surveyor clear flag
export const ASSIGN_SURVEYOR_CLEAR_FLAG = 'ASSIGN_SURVEYOR_CLEAR_FLAG'

// get item by user
export const GET_ITEMS_BY_USER = 'GET_ITEMS_BY_USER'
export const GET_ITEMS_BY_USER_SUCCESS = 'GET_ITEMS_BY_USER_SUCCESS'
export const GET_ITEMS_BY_USER_FAILURE = 'GET_ITEMS_BY_USER_FAILURE'

//set unauthorized access true
export const SET_DASHBOARD_UNAUTHORIZED_ACCESS = 'SET_DASHBOARD_UNAUTHORIZED_ACCESS'

//clear flags for dashboard authorization
export const CLEAR_DASHBOARD_UNAUTHORIZED_ACCESS_FLAG = 'CLEAR_DASHBOARD_UNAUTHORIZED_ACCESS_FLAG'

//Get Boat Converted Cost Estimation
export const GET_BOAT_CONVERTED_COST_ESTIMATION = 'GET_BOAT_CONVERTED_COST_ESTIMATION'
export const GET_BOAT_CONVERTED_COST_ESTIMATION_SUCCESS = 'GET_BOAT_CONVERTED_COST_ESTIMATION_SUCCESS'
export const GET_BOAT_CONVERTED_COST_ESTIMATION_FAILURE = 'GET_BOAT_CONVERTED_COST_ESTIMATION_FAILURE'

//get sales engine Agents
export const GET_SALES_ENGINE_AGENTS = 'GET_SALES_ENGINE_AGENTS'
export const GET_SALES_ENGINE_AGENTS_SUCCESS = 'GET_SALES_ENGINE_AGENTS_SUCCESS'
export const GET_SALES_ENGINE_AGENTS_FAILURE = 'GET_SALES_ENGINE_AGENTS_FAILURE'

//dealers requests manufacturer
export const DEALERS_REQUEST_MANUFACTURER = 'DEALERS_REQUEST_MANUFACTURER'
export const DEALERS_REQUEST_MANUFACTURER_SUCCESS = 'DEALERS_REQUEST_MANUFACTURER_SUCCESS'
export const DEALERS_REQUEST_MANUFACTURER_FAILURE = 'DEALERS_REQUEST_MANUFACTURER_FAILURE'

export const DEALERS_REQUEST_MULTIPLE_MANUFACTURER = 'DEALERS_REQUEST_MULTIPLE_MANUFACTURER'
export const DEALERS_REQUEST_MULTIPLE_MANUFACTURER_SUCCESS = 'DEALERS_REQUEST_MULTIPLE_MANUFACTURER_SUCCESS'
export const DEALERS_REQUEST_MULTIPLE_MANUFACTURER_FAILURE = 'DEALERS_REQUEST_MULTIPLE_MANUFACTURER_FAILURE'

export const CLEAR_DEALERS_REQUEST_FLAG = 'CLEAR_DEALERS_REQUEST_FLAG'

//set dealers
export const SET_DEALER_IN_MANUFACTURER = 'SET_DEALER_IN_MANUFACTURER'

//set dealers
export const SET_MANUFACTURER = 'SET_MANUFACTURER'

//get dealers request
export const GET_DEALERS_REQUESTS = 'GET_DEALERS_REQUESTS'

//accept or reject dealer request
export const ACCEPT_OR_REJECT_DEALER_REQUEST = 'ACCEPT_OR_REJECT_DEALER_REQUEST'
export const ACCEPT_OR_REJECT_DEALER_REQUEST_SUCCESS = 'ACCEPT_OR_REJECT_DEALER_REQUEST_SUCCESS'
export const ACCEPT_OR_REJECT_DEALER_REQUEST_FAILURE = 'ACCEPT_OR_REJECT_DEALER_REQUEST_FAILURE'

// search manufacturer
export const SEARCH_MANUFACTURER = 'SEARCH_MANUFACTURER'
export const SEARCH_MANUFACTURER_SUCCESS = 'SEARCH_MANUFACTURER_SUCCESS'
export const SEARCH_MANUFACTURER_FAILURE = 'SEARCH_MANUFACTURER_FAILURE'

//clear manufacturer flag
export const CLEAR_MANUFACTURER_FLAG = 'CLEAR_MANUFACTURER_FLAG'

//dealers sent requests
export const DEALERS_SENT_REQUESTS = 'DEALERS_SENT_REQUESTS'
export const DEALERS_SENT_REQUESTS_SUCCESS = 'DEALERS_SENT_REQUESTS_SUCCESS'
export const DEALERS_SENT_REQUESTS_FAILURE = 'DEALERS_SENT_REQUESTS_FAILURE'

//get all payment methods
export const GET_ALL_PAYMENT_METHODS = 'GET_ALL_PAYMENT_METHODS'
export const GET_ALL_PAYMENT_METHODS_SUCCESS = 'GET_ALL_PAYMENT_METHODS_SUCCESS'
export const GET_ALL_PAYMENT_METHODS_FAILURE = 'GET_ALL_PAYMENT_METHODS_FAILURE'

//get user flag report for module
export const GET_USER_REPORT_FOR_MODULE = 'GET_USER_REPORT_FOR_MODULE'
export const GET_USER_REPORT_FOR_MODULE_SUCCESS = 'GET_USER_REPORT_FOR_MODULE_SUCCESS'
export const GET_USER_REPORT_FOR_MODULE_FAILURE = 'GET_USER_REPORT_FOR_MODULE_FAILURE'

//get all jobs
export const GET_ALL_JOBS = 'GET_ALL_JOBS'
export const GET_ALL_JOBS_SUCCESS = 'GET_ALL_JOBS_SUCCESS'
export const GET_ALL_JOBS_FAILURE = 'GET_ALL_JOBS_FAILURE'

//clear jobs flag
export const CLEAR_JOBS_FLAG = 'CLEAR_JOBS_FLAG'

//apply for job
export const APPLY_FOR_JOB = 'APPLY_FOR_JOB'
export const APPLY_FOR_JOB_SUCCESS = 'APPLY_FOR_JOB_SUCCESS'
export const APPLY_FOR_JOB_FAILURE = 'APPLY_FOR_JOB_FAILURE'

//get password content
export const GET_MANAGE_PASSWORD_CONTENT = 'GET_MANAGE_PASSWORD_CONTENT'
export const GET_MANAGE_PASSWORD_CONTENT_SUCCESS = 'GET_MANAGE_PASSWORD_CONTENT_SUCCESS'
export const GET_MANAGE_PASSWORD_CONTENT_FAILURE = 'GET_MANAGE_PASSWORD_CONTENT_FAILURE'

//get all downloads data
export const GET_ALL_DOWNLOADS_DATA = 'GET_ALL_DOWNLOADS_DATA'
export const GET_ALL_DOWNLOADS_DATA_SUCCESS = 'GET_ALL_DOWNLOADS_DATA_SUCCESS'
export const GET_ALL_DOWNLOADS_DATA_FAILURE = 'GET_ALL_DOWNLOADS_DATA_FAILURE'

//search jobs
export const SEARCH_JOBS = 'SEARCH_JOBS'
export const SEARCH_JOBS_SUCCESS = 'SEARCH_JOBS_SUCCESS'
export const SEARCH_JOBS_FAILURE = 'SEARCH_JOBS_FAILURE'

//get career content
export const GET_JOBS_CONTENT = 'GET_JOBS_CONTENT'
export const GET_JOBS_CONTENT_SUCCESS = 'GET_JOBS_CONTENT_SUCCESS'
export const GET_JOBS_CONTENT_FAILURE = 'GET_JOBS_CONTENT_FAILURE'

//search jobs
export const GET_SIGLE_JOB = 'GET_SIGLE_JOB'
export const GET_SIGLE_JOB_SUCCESS = 'GET_SIGLE_JOB_SUCCESS'
export const GET_SIGLE_JOB_FAILURE = 'GET_SIGLE_JOB_FAILURE'

//get country list
export const GET_COUNTRY_LIST = 'GET_COUNTRY_LIST'
export const GET_COUNTRY_LIST_SUCCESS = 'GET_COUNTRY_LIST_SUCCESS'
export const GET_COUNTRY_LIST_FAILURE = 'GET_COUNTRY_LIST_FAILURE'

//stop sales engine process
export const STOP_SALES_ENGINE_PROCESS = 'STOP_SALES_ENGINE_PROCESS'
export const STOP_SALES_ENGINE_PROCESS_SUCCESS = 'STOP_SALES_ENGINE_PROCESS_SUCCESS'
export const STOP_SALES_ENGINE_PROCESS_FAILURE = 'STOP_SALES_ENGINE_PROCESS_FAILURE'

// print payment receipt
export const PRINT_PAYMENT_RECEIPT = 'PRINT_PAYMENT_RECEIPT'
export const PRINT_PAYMENT_RECEIPT_SUCCESS = 'PRINT_PAYMENT_RECEIPT_SUCCESS'
export const PRINT_PAYMENT_RECEIPT_FAILURE = 'PRINT_PAYMENT_RECEIPT_FAILURE'

// get all sales engine static content
export const GET_SALES_ENGINE_STATIC_CONTENT = 'GET_SALES_ENGINE_STATIC_CONTENT'
export const GET_SALES_ENGINE_STATIC_CONTENT_SUCCESS = 'GET_SALES_ENGINE_STATIC_CONTENT_SUCCESS'
export const GET_SALES_ENGINE_STATIC_CONTENT_FAILURE = 'GET_SALES_ENGINE_STATIC_CONTENT_FAILURE'

// get survey document
export const GET_SURVEY_DOCUMENT_DATA = 'GET_SURVEY_DOCUMENT_DATA'
export const GET_SURVEY_DOCUMENT_DATA_SUCCESS = 'GET_SURVEY_DOCUMENT_DATA_SUCCESS'
export const GET_SURVEY_DOCUMENT_DATA_FAILURE = 'GET_SURVEY_DOCUMENT_DATA_FAILURE'

//get sales engine by boat
export const GET_SALES_ENGINE_BY_BOAT = 'GET_SALES_ENGINE_BY_BOAT'
export const GET_SALES_ENGINE_BY_BOAT_SUCCESS = 'GET_SALES_ENGINE_BY_BOAT_SUCCESS'
export const GET_SALES_ENGINE_BY_BOAT_FAILURE = 'GET_SALES_ENGINE_BY_BOAT_FAILURE'

// buyer review surveyor report
export const BUYER_REVIEW_SURVEY_REPORT = 'BUYER_REVIEW_SURVEY_REPORT'
export const BUYER_REVIEW_SURVEY_REPORT_SUCCESS = 'BUYER_REVIEW_SURVEY_REPORT_SUCCESS'
export const BUYER_REVIEW_SURVEY_REPORT_FAILURE = 'BUYER_REVIEW_SURVEY_REPORT_FAILURE'

///clear review report flag
export const CLEAR_REVIEW_REPORT_FLAG = 'CLEAR_REVIEW_REPORT_FLAG'

//map
export const ON_MAP_ITEM_HOVER = 'ON_MAP_ITEM_HOVER'

///update surveyor report
export const UPDATE_SURVEYOR_REPORT = 'UPDATE_SURVEYOR_REPORT'
export const UPDATE_SURVEYOR_REPORT_SUCCESS = 'UPDATE_SURVEYOR_REPORT_SUCCESS'
export const UPDATE_SURVEYOR_REPORT_FAILURE = 'UPDATE_SURVEYOR_REPORT_FAILURE'

///sales engine stepper change
export const SALES_ENGINE_STEP_CHANGE = 'SALES_ENGINE_STEP_CHANGE'
export const SALES_ENGINE_STEP_CHANGE_SUCCESS = 'SALES_ENGINE_STEP_CHANGE_SUCCESS'
export const SALES_ENGINE_STEP_CHANGE_FAILURE = 'SALES_ENGINE_STEP_CHANGE_FAILURE'

//dealers requests
export const GET_DEALERS_SALES_ENGINE = 'GET_DEALERS_SALES_ENGINE'
export const GET_DEALERS_SALES_ENGINE_SUCCESS = 'GET_DEALERS_SALES_ENGINE_SUCCESS'
export const GET_DEALERS_SALES_ENGINE_FAILURE = 'GET_DEALERS_SALES_ENGINE_FAILURE'

//get shipper documents
export const GET_SHIPPER_DOCUMENTS = 'GET_SHIPPER_DOCUMENTS'
export const GET_SHIPPER_DOCUMENTS_SUCCESS = 'GET_SHIPPER_DOCUMENTS_SUCCESS'
export const GET_SHIPPER_DOCUMENTS_FAILURE = 'GET_SHIPPER_DOCUMENTS_FAILURE'

export const CLEAR_SHIPPER_DOCUMENTS_FLAG = 'CLEAR_SHIPPER_DOCUMENTS_FLAG'

//get auction salesEngine
export const GET_AUCTION_SALES_ENGINE = 'GET_AUCTION_SALES_ENGINE'
export const GET_AUCTION_SALES_ENGINE_SUCCESS = 'GET_AUCTION_SALES_ENGINE_SUCCESS'
export const GET_AUCTION_SALES_ENGINE_FAILURE = 'GET_AUCTION_SALES_ENGINE_FAILURE'
export const CLEAR_AUCTION_SALES_ENGINE = 'CLEAR_AUCTION_SALES_ENGINE'

//update stock quantity
export const UPDATE_STOCK_QUANTITY = 'UPDATE_STOCK_QUANTITY'
export const UPDATE_STOCK_QUANTITY_SUCCESS = 'UPDATE_STOCK_QUANTITY_SUCCESS'
export const UPDATE_STOCK_QUANTITY_FAILURE = 'UPDATE_STOCK_QUANTITY_FAILURE'

export const CHECK_FOR_QUANTITY_QUERY = 'CHECK_FOR_QUANTITY_QUERY'
export const CHECK_FOR_QUANTITY_QUERY_SUCCESS = 'CHECK_FOR_QUANTITY_QUERY_SUCCESS'
export const CHECK_FOR_QUANTITY_QUERY_FAILURE = 'CHECK_FOR_QUANTITY_QUERY_FAILURE'
export const CLEAR_CHECK_FOR_QUANTITY_QUERY = 'CLEAR_CHECK_FOR_QUANTITY_QUERY'

export const ADD_DEPOSIT_FOR_BID = 'ADD_DEPOSIT_FOR_BID'
export const ADD_DEPOSIT_FOR_BID_SUCCESS = 'ADD_DEPOSIT_FOR_BID_SUCCESS'
export const ADD_DEPOSIT_FOR_BID_FAILURE = 'ADD_DEPOSIT_FOR_BID_FAILURE'
export const CLEAR_ADD_DEPOSIT_FOR_BID = 'CLEAR_ADD_DEPOSIT_FOR_BID'

//update shipment document
export const UPDATE_SHIPPER_DOCUMENTS = 'UPDATE_SHIPPER_DOCUMENTS'
export const UPDATE_SHIPPER_DOCUMENTS_SUCCESS = 'UPDATE_SHIPPER_DOCUMENTS_SUCCESS'
export const UPDATE_SHIPPER_DOCUMENTS_FAILURE = 'UPDATE_SHIPPER_DOCUMENTS_FAILURE'

// index search
export const INDEX_SEARCH = 'INDEX_SEARCH'
export const INDEX_SEARCH_SUCCESS = 'INDEX_SEARCH_SUCCESS'
export const INDEX_SEARCH_FAILURE = 'INDEX_SEARCH_FAILURE'
export const CLEAR_INDEX_SEARCH = 'CLEAR_INDEX_SEARCH'

//get transaction by user
export const GET_TRANSACTION_BY_USER = 'GET_TRANSACTION_BY_USER'
export const GET_TRANSACTION_BY_USER_SUCCESS = 'GET_TRANSACTION_BY_USER_SUCCESS'
export const GET_TRANSACTION_BY_USER_FAILURE = 'GET_TRANSACTION_BY_USER_FAILURE'

//get transaction by user payout
export const GET_TRANSACTION_BY_USER_PAYOUTS = 'GET_TRANSACTION_BY_USER_PAYOUTS'
export const GET_TRANSACTION_BY_USER_PAYOUTS_SUCCESS = 'GET_TRANSACTION_BY_USER_PAYOUTS_SUCCESS'
export const GET_TRANSACTION_BY_USER_PAYOUTS_FAILURE = 'GET_TRANSACTION_BY_USER_PAYOUTS_FAILURE'

//sales engine dashboard visit
export const SALES_ENGINE_DASHBOARD_VISIT = 'SALES_ENGINE_DASHBOARD_VISIT'
export const SALES_ENGINE_DASHBOARD_VISIT_SUCCESS = 'SALES_ENGINE_DASHBOARD_VISIT_SUCCESS'
export const SALES_ENGINE_DASHBOARD_VISIT_FAILURE = 'SALES_ENGINE_DASHBOARD_VISIT_FAILURE'

// fetch all channels
export const FETCH_ALL_CHANNELS = 'FETCH_ALL_CHANNELS'
export const FETCH_ALL_CHANNELS_SUCCESS = 'FETCH_ALL_CHANNELS_SUCCESS'
export const FETCH_ALL_CHANNELS_FAILURE = 'FETCH_ALL_CHANNELS_FAILURE'

export const ADD_REMOVE_FRIEND = 'ADD_REMOVE_FRIEND'
export const ADD_REMOVE_FRIEND_SUCCESS = 'ADD_REMOVE_FRIEND_SUCCESS'
export const ADD_REMOVE_FRIEND_FAILURE = 'ADD_REMOVE_FRIEND_FAILURE'

// Contact US Suggestion
export const CREATE_CONTACT_US_SUGGESTION = 'CREATE_CONTACT_US_SUGGESTION'
export const CREATE_CONTACT_US_SUGGESTION_SUCCESS = 'CREATE_CONTACT_US_SUGGESTION_SUCCESS'
export const CREATE_CONTACT_US_SUGGESTION_FAILURE = 'CREATE_CONTACT_US_SUGGESTION_FAILURE'

// Contact US Service
export const CREATE_CONTACT_US_SERVICE = 'CREATE_CONTACT_US_SERVICE'
export const CREATE_CONTACT_US_SERVICE_SUCCESS = 'CREATE_CONTACT_US_SERVICE_SUCCESS'
export const CREATE_CONTACT_US_SERVICE_FAILURE = 'CREATE_CONTACT_US_SERVICE_FAILURE'

// Contact US Complaint
export const CREATE_CONTACT_US_COMPLAINT = 'CREATE_CONTACT_US_COMPLAINT'
export const CREATE_CONTACT_US_COMPLAINT_SUCCESS = 'CREATE_CONTACT_US_COMPLAINT_SUCCESS'
export const CREATE_CONTACT_US_COMPLAINT_FAILURE = 'CREATE_CONTACT_US_COMPLAINT_FAILURE'

export const GET_RENT_BOOKINGS_BY_CUSTOMER = 'GET_RENT_BOOKINGS_BY_CUSTOMER'
export const GET_RENT_BOOKINGS_BY_CUSTOMER_SUCCESS = 'GET_RENT_BOOKINGS_BY_CUSTOMER_SUCCESS'
export const GET_RENT_BOOKINGS_BY_CUSTOMER_FAILURE = 'GET_RENT_BOOKINGS_BY_CUSTOMER_FAILURE'
export const CLEAR__GET_RENT_BOOKINGS_BY_CUSTOMER__FLAG = 'CLEAR__GET_RENT_BOOKINGS_BY_CUSTOMER__FLAG'

// rent ticked

export const GET_RENT_TICKET = 'GET_RENT_TICKET'
export const GET_RENT_TICKET_SUCCESS = 'GET_RENT_TICKET__SUCCESS'
export const GET_RENT_TICKET_FAILURE = 'GET_RENT_TICKET__FAILURE'
export const CLEAR_GET_RENT_TICKET_FLAGS = 'CLEAR_GET_RENT_TICKET_FLAGS'

// get country details
export const GET_COUNTRY_DETAILS = 'GET_COUNTRY_DETAILS'
export const GET_COUNTRY_DETAILS_SUCCESS = 'GET_COUNTRY_DETAILS__SUCCESS'
export const GET_COUNTRY_DETAILS_FAILURE = 'GET_COUNTRY_DETAILS__FAILURE'

// cancel rent booking
export const CANCEL_RENT_BOOKING = 'CANCEL_RENT_BOOKING'
export const CANCEL_RENT_BOOKING_SUCCESS = 'CANCEL_RENT_BOOKING_SUCCESS'
export const CANCEL_RENT_BOOKING_FAILURE = 'CANCEL_RENT_BOOKING_FAILURE'
export const CLEAR__CANCEL_RENT_BOOKING__FLAG = 'CLEAR__CANCEL_RENT_BOOKING__FLAG'

// get rent payouts
export const GET_RENT_PAYOUTS = 'GET_RENT_PAYOUTS'
export const GET_RENT_PAYOUTS_SUCCESS = 'GET_RENT_PAYOUTS_SUCCESS'
export const GET_RENT_PAYOUTS_FAILURE = 'GET_RENT_PAYOUTS_FAILURE'
export const CLEAR__GET_RENT_PAYOUTS__FLAG = 'CLEAR__GET_RENT_PAYOUTS__FLAG'

export const GET_CHAT_USERS='GET_CHAT_USERS'
export const GET_CHAT_USERS_SUCCESS='GET_CHAT_USERS_SUCCESS'
export const GET_CHAT_USERS_FAILURE='GET_CHAT_USERS_FAILURE'

// USER chat list
export const CLEAR_USER_SEARCH = 'CLEAR_USER_SEARCH'
export const GET_USERS_LIST = 'GET_USERS_LIST'
export const GET_USERS_LIST_SUCCESS = 'GET_USERS_LIST_SUCCESS'
export const GET_USERS_LIST_FAILURE = 'GET_USERS_LIST_FAILURE'

// USER Friend List
export const GET_FRIEND_USERS_LIST = 'GET_FRIEND_USERS_LIST'
export const GET_FRIEND_USERS_LIST_SUCCESS = 'GET_FRIEND_USERS_LIST_SUCCESS'
export const SET_FRIEND_LIST="SET_FRIEND_LIST"
export const SET_CHAT_LIST='SET_CHAT_LIST'
export const GET_FRIEND_USERS_LIST_FAILURE = 'GET_FRIEND_USERS_LIST_FAILURE'
// Chat list
export const GET_CHAT_LIST = 'GET_CHAT_LIST'
export const GET_CHAT_LIST_SUCCESS = 'GET_CHAT_LIST_SUCCESS'
export const GET_CHAT_LIST_FAILURE = 'GET_CHAT_LIST_FAILURE'

// ME
export const GET_CHAT_ME = 'GET_CHAT_ME'
export const GET_CHAT_ME_SUCCESS = 'GET_CHAT_ME_SUCCESS'
export const GET_CHAT_ME_FAILURE = 'GET_CHAT_ME_FAILURE'

export const SET_SELECT_USER_AFTER_ADDORREMOVE='SET_SELECT_USER_AFTER_ADDORREMOVE'

// Initial Chat Flag
export const INITIAL_CHAT_ID = 'INITIAL_CHAT_ID'

// Create Chat by ID
export const CREATE_CHAT_BY_ID = 'CREATE_CHAT_BY_ID'
export const SUCCESS_CREATED_CHAT_BY_ID = 'SUCCESS_CREATED_CHAT_BY_ID'
export const CREATE_CHAT_BY_ID_FAILURE = 'CREATE_CHAT_BY_ID_FAILURE'
export const SET_FIRSTTIMECREATECHATIDNULL = 'SET_FIRSTTIMECREATECHATIDNULL'

// Create Message by chat ID
export const CREATE_MESSAGE_BY_CHAT_ID = 'CREATE_MESSAGE_BY_CHAT_ID'
export const CREATE_MESSAGE_BY_CHAT_ID_SUCCESS = 'CREATE_MESSAGE_BY_CHAT_ID_SUCCESS'
export const CREATE_MESSAGE_BY_CHAT_ID_FAILURE = 'CREATE_MESSAGE_BY_CHAT_ID_FAILURE'

// Add Remove group chat
export const ADD_OR_REMOVE_USERS_FROM_GROUP = 'ADD_OR_REMOVE_USERS_FROM_GROUP'
export const ADD_OR_REMOVE_USERS_FROM_GROUP_SUCCESS = 'ADD_OR_REMOVE_USERS_FROM_GROUP_SUCCESS'
export const ADD_OR_REMOVE_USERS_FROM_GROUP_FAILURE = 'ADD_OR_REMOVE_USERS_FROM_GROUP_FAILURE'

// Update chat
export const UPDATE_CHAT='UPDATE_CHAT'
export const UPDATE_CHAT_FAILURE='UPDATE_CHAT_FAILURE'
export const UPDATE_CHAT_SUCCESS='UPDATE_CHAT_SUCCESS'

// Get Message by chat ID
export const CLEAR_CHAT_THREAD = 'CLEAR_CHAT_THREAD'
export const CLEAR_CHAT_THREAD_SUCCESS = 'CLEAR_CHAT_THREAD_SUCCESS'
export const CLEAR_CHAT_THREAD_FAILURE='CLEAR_CHAT_THREAD_FAILURE'

export const START_MESSAGE_BY_CHAT_ID = 'START_MESSAGE_BY_CHAT_ID'
export const GET_MESSAGE_BY_CHAT_ID = 'GET_MESSAGE_BY_CHAT_ID'
export const ADD_MESSAGE_BY_CHAT_ID = 'ADD_MESSAGE_BY_CHAT_ID'
export const UPDATE_MESSAGE_ID='UPDATE_MESSAGE_ID'
export const GET_MESSAGE_BY_CHAT_ID_SUCCESS = 'GET_MESSAGE_BY_CHAT_ID_SUCCESS'
export const GET_MESSAGE_BY_CHAT_ID_FAILURE = 'GET_MESSAGE_BY_CHAT_ID_FAILURE'

// Get Message using Subscription by chat ID
export const GET_NEW_MESSAGE_BY_CHAT_ID = 'GET_NEW_MESSAGE_BY_CHAT_ID'
export const GET_NEW_MESSAGE_BY_CHAT_ID_SUCCESS = 'GET_NEW_MESSAGE_BY_CHAT_ID_SUCCESS'
export const GET_NEW_MESSAGE_BY_CHAT_ID_ME_SUCCESS = 'GET_NEW_MESSAGE_BY_CHAT_ID_ME_SUCCESS'
export const GET_NEW_MESSAGE_BY_CHAT_ID_FAILURE = 'GET_NEW_MESSAGE_BY_CHAT_ID_FAILURE'

// Select left side user
export const SET_SELECT_USER = 'SET_SELECT_USER'
export const SET_MESSAGE = 'SET_MESSAGE'
export const SET_TEXT_AREA_HEIGHT = 'SET_TEXT_AREA_HEIGHT'

export const SET_LASTACTIVE_TIME="SET_LASTACTIVE_TIME"

// Read Message by chat ID
export const READ_MESSAGE_BY_CHAT_ID = 'READ_MESSAGE_BY_CHAT_ID'
export const READ_MESSAGE_BY_CHAT_ID_SUCCESS = 'READ_MESSAGE_BY_CHAT_ID_SUCCESS'
export const READ_MESSAGE_BY_CHAT_ID_FAILURE = 'READ_MESSAGE_BY_CHAT_ID_FAILURE'

// Set user list on socket
export const SET_USER='SET_USER'

// Clear Message by chat ID
export const DELETE_SINGLE_USER_CHAT = 'DELETE_SINGLE_USER_CHAT'
export const DELETE_SINGLE_USER_CHAT_SUCCESS = 'DELETE_SINGLE_USER_CHAT_SUCCESS'
export const DELETE_SINGLE_USER_CHAT_FAILURE = 'DELETE_SINGLE_USER_CHAT_FAILURE'

// Clear Message by chat ID
export const DELETE_SINGLE_MESSAGE_CHAT = 'DELETE_SINGLE_MESSAGE_CHAT_CHAT'
export const DELETE_SINGLE_MESSAGE_CHAT_SUCCESS = 'DELETE_SINGLE_MESSAGE_CHAT_SUCCESS'
export const DELETE_SINGLE_MESSAGE_CHAT_FAILURE = 'DELETE_SINGLE_MESSAGE_CHAT_FAILURE'

// Block User By ID
export const BLOCK_USER = 'BLOCK_USER'
export const BLOCK_USER_SUCCESS = 'BLOCK_USER_SUCCESS'
export const BLOCK_USER_FAILURE = 'BLOCK_USER_FAILURE'

// Temporary
export const CHAT_IMAGES_UPLOADED = 'CHAT_IMAGES_UPLOADED'
export const CHAT_VIDEO_UPLOADED_FAILD = 'CHAT_VIDEO_UPLOADED_FAILD'
export const CLEAR_CHAT_WHILE_LOG_OUT = 'CLEAR_CHAT_WHILE_LOG_OUT'
export const CLEAR_CHAT_CREATED_CHAT = 'CLEAR_CHAT_CREATED_CHAT'
export const SET_ONLINE_USER = 'SET_ONLINE_USER'
export const SET_GROUP_CHAT_FLAG = 'SET_GROUP_CHAT_FLAG'
export const SET_SELECTED_GROUP_IDS = 'SET_SELECTED_GROUP_IDS'
export const SET_GROUP_NAME_SCREEN = 'SET_GROUP_NAME_SCREEN'
export const SET_USER_LIST_PAGE = 'SET_USER_LIST_PAGE'
export const EDIT_GROUP_SCREEN = 'EDIT_GROUP_SCREEN'
export const BACK_TO_CHAT_LIST = 'BACK_TO_CHAT_LIST'
export const CHAT_TOGGLE = 'CHAT_TOGGLE'
export const SET_MODAL_VIEW = 'SET_MODAL_VIEW'
export const SET_FIRST_TIME_CHAT = 'SET_FIRST_TIME_CHAT'
export const SET_TOGGLE_HEADER_MANAGE = 'SET_TOGGLE_HEADER_MANAGE'

export const IS_TEXT_FALSE='IS_TEXT_FALSE'

//salesengine payment details
export const SALES_ENGINE_PAYMENT_DETAILS = 'SALES_ENGINE_PAYMENT_DETAILS'
export const SALES_ENGINE_PAYMENT_DETAILS_SUCCESS = 'SALES_ENGINE_PAYMENT_DETAILS_SUCCESS'
export const SALES_ENGINE_PAYMENT_DETAILS_FAILURE = 'SALES_ENGINE_PAYMENT_DETAILS_FAILURE'

export const GET_COMMISSIONS = 'GET_COMMISSIONS'
export const GET_COMMISSIONS_SUCCESS = 'GET_COMMISSIONS_SUCCESS'
export const GET_COMMISSIONS_FAILURE = 'GET_COMMISSIONS_FAILURE'
export const GET_COMMISSIONS__CLEAR = 'GET_COMMISSIONS__CLEAR'

// SEO Pages
export const GET_SEO_PAGES = 'GET_SEO_PAGES'
export const GET_SEO_PAGES_SUCCESS = 'GET_SEO_PAGES_SUCCESS'
export const GET_SEO_PAGES_FAILURE = 'GET_SEO_PAGES_FAILURE'
export const GET_SEO_PAGES_CLEAR = 'GET_SEO_PAGES_CLEAR'

export const SET_ONLINE_USERS = 'SET_ONLINE_USERS'

export const SET_READ_MESSAGE='SET_READ_MESSAGE'
export const SET_UNREAD_COUNT="SET_UNREAD_COUNT"

export const SET_DELIVERED_MESSAGE='SET_DELIVERED_MESSAGE'

// add service tag
export const ADD_SERVICE_TAG = 'ADD_SERVICE_TAG'
export const ADD_SERVICE_TAG_SUCCESS = 'ADD_SERVICE_TAG_SUCCESS'
export const ADD_SERVICE_TAG_FAILURE = 'ADD_SERVICE_TAG_FAILURE'
export const ADD_SERVICE_TAG__CLEAR = 'ADD_SERVICE_TAG__CLEAR'

// get service tags
export const GET_SERVICE_TAGS = 'GET_SERVICE_TAGS'
export const GET_SERVICE_TAGS_SUCCESS = 'GET_SERVICE_TAGS_SUCCESS'
export const GET_SERVICE_TAGS_FAILURE = 'GET_SERVICE_TAGS_FAILURE'
export const GET_SERVICE_TAGS__CLEAR = 'GET_SERVICE_TAGS__CLEAR'

// choose for estimated cost
export const CHOOSE_FOR_ESTIMATED_COST = 'CHOOSE_FOR_ESTIMATED_COST'
export const CHOOSE_FOR_ESTIMATED_COST_SUCCESS = 'CHOOSE_FOR_ESTIMATED_COST_SUCCESS'
export const CHOOSE_FOR_ESTIMATED_COST_FAILURE = 'CHOOSE_FOR_ESTIMATED_COST_FAILURE'
export const CHOOSE_FOR_ESTIMATED_COST__CLEAR = 'CHOOSE_FOR_ESTIMATED_COST__CLEAR'

// choose for estimated cost
export const IS_CHOSEN_FOR_ESTIMATED_COST = 'IS_CHOSEN_FOR_ESTIMATED_COST'
export const IS_CHOSEN_FOR_ESTIMATED_COST_SUCCESS = 'IS_CHOSEN_FOR_ESTIMATED_COST_SUCCESS'
export const IS_CHOSEN_FOR_ESTIMATED_COST_FAILURE = 'IS_CHOSEN_FOR_ESTIMATED_COST_FAILURE'
export const IS_CHOSEN_FOR_ESTIMATED_COST__CLEAR = 'IS_CHOSEN_FOR_ESTIMATED_COST__CLEAR'

// get service profile for estimated cost
export const GET_SERVICE_PROFILE_FOR_ESTIMATED_COST = 'GET_SERVICE_PROFILE_FOR_ESTIMATED_COST'
export const GET_SERVICE_PROFILE_FOR_ESTIMATED_COST_SUCCESS = 'GET_SERVICE_PROFILE_FOR_ESTIMATED_COST_SUCCESS'
export const GET_SERVICE_PROFILE_FOR_ESTIMATED_COST_FAILURE = 'GET_SERVICE_PROFILE_FOR_ESTIMATED_COST_FAILURE'
export const GET_SERVICE_PROFILE_FOR_ESTIMATED_COST__CLEAR = 'GET_SERVICE_PROFILE_FOR_ESTIMATED_COST__CLEAR'

export const TOGGLE_COOKIE_POLICY_MODAL = 'TOGGLE_COOKIE_POLICY_MODAL'
export const SET_COOKIE_POLICY_STATUS = 'SET_COOKIE_POLICY_STATUS'

export const LOGIN_WITH_PAYPAL = 'LOGIN_WITH_PAYPAL';
export const LOGIN_WITH_PAYPAL_SUCCESS = 'LOGIN_WITH_PAYPAL_SUCCESS';
export const LOGIN_WITH_PAYPAL_FAILURE = 'LOGIN_WITH_PAYPAL_FAILURE';
export const LOGIN_WITH_PAYPAL_CLEAR = 'LOGIN_WITH_PAYPAL_CLEAR';