import { useTopLoader } from '../../contexts/top-loader-ctx'
import compStyles from '../../styles/components.module.scss'
import styles from './top-loader.module.scss'

export default function TopLoader({ zIndex }) {
  const topLoader = useTopLoader()

  if (!topLoader.isActive) return null

  return (
    <div className={compStyles.overlay} style={{ zIndex }}>
      <div className={compStyles.container}>
        <div className={styles.topLoader}>
          <span className={compStyles.spinner} /> <span>Getting you to the AdamSea Community...</span>
        </div>
      </div>
    </div>
  )
}
