import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import { withRouter } from 'react-router'
import { toast } from 'react-toastify'
import { ButtonComponent } from '../../../components/form/Button'
import { CurrencyContextConsumer } from '../../../CurrencyContext'
import { viewBoatHandler } from '../../../helpers/boatHelper'
import { getConvertedPrice } from '../../../helpers/currencyConverterHelper'
import { nameFormatter, TitleCase } from '../../../helpers/string'
import { PDFExport, savePDF } from '@progress/kendo-react-pdf'
import { connect } from 'react-redux'

const PayoutCardList = props => {
  const { data, siteConfigurations} = props
  const exportBoatReceipt = useRef(null)
  const boatDomElem = useRef(null)
  console.log(data, 'data')
  const [printing, setPrinting] = useState(false)
  const [expnaded, setExpanded] = useState(false)

  useEffect(() => {
    printing &&
    savePDF(boatDomElem.current, {fileName: data?.salesEngineId?.boat?.adId?.toUpperCase()}, () => setPrinting(false))
},[printing])
  return (
    <div className="card mb-3 manage-payment-card">
                  <div
        style={{
          position: "absolute",
          left: "-1000px",
          top: 0,
          zIndex:-1,
          opacity:0
        }}
      >
            {
        data && 
        <PDFExport scale={0.6} ref={exportBoatReceipt}>
        <div ref={boatDomElem} className='d-flex justify-content-center bg-light p-5 border-rounded'>
          <div style={{width:'609px'}} className='flex flex-column bg-white p-2 justify-content-center'>
            <img width={140} loading='lazy' src={siteConfigurations?.siteLogo?.url} alt="AdamSea" className="site-logo pt-4"/>
            <p className='my-4'>BOAT PAYMENT</p>
            <p className='mt-4'>AdamSea Inc. has initiated payout of {getConvertedPrice(data?.amount)}</p>
            <div className='d-flex mb-2 mt-4'>
              <div className='d-flex flex-column'>
                <p className='fontSize12'>STARTED</p><p className='text-dark'>{moment(data?.updatedAt).format('ddd, MMM DD YYYY')}</p>
              </div>
              <div className='d-flex flex-column ml-3'>
                <p className='fontSize12'>STRIPE ID</p><p className='text-dark'>{data?.paymentId}</p>
              </div>

            </div>
            <b className='py-3 fontSize12'>SUMMARY</b>
            <div className='p-2 mt-3 border-rounded' style={{backgroundColor:'#f8f9fa'}}>
              <div className='d-flex justify-content-between'>
                <p>Amount Paid to {data?.receiverId?.firstName + ' ' + data?.receiverId?.lastName}</p>
                <p>{getConvertedPrice(data?.amount)}</p>
              </div>
              <hr className='mt-0'/>
              <div className='text-right'>
              <p>Total Paid out</p><p className='m-0 text-dark'>{getConvertedPrice(data?.amount)}</p>
            </div>
            </div>
            <p>Your are receiving this receipt because you conducted as transaction with AdamSea Inc.</p>
            <p className='mt-2'>	If you have any questions, visit our support site at <a href='https://adamoceans.com/help' style={{textDecoration:'none', color: '#625afa '}}>https://adamoceans.com/help</a></p>
          </div>
        </div>
        </PDFExport>
      }
      </div>
      <div className="card-body m-0">
        <div className="d-flex justify-content-between align-items-center">
          <div className="">
            <ul className="d-flex justify-content-center align-items-center table-head-title">
              <li className="-mr-5">
                <div className="arrow-down" onClick={() => setExpanded(!expnaded)}>
                  {!expnaded ? '+' : '-'}
                </div>
              </li>
              <li className="-mr-5" style={{ width: '150px' }}>
                <div className="payment-card-content">
                  <p className="m-0">Ad ID</p>
                  <div>{data?.salesEngineId?.boat?.adId}</div>
                </div>
              </li>
              <li className="-mr-5" style={{ width: '80px' }}>
                <div className="payment-card-content">
                  <p className="m-0">Date</p>
                  <div>{moment(data?.updatedAt).format('ll')}</div>
                </div>
              </li>
              <li className="-mr-5" style={{ width: '250px' }}>
                <div className="payment-card-content">
                  <p className="m-0">Stripe Transaction Id</p>
                  <div>{data.paymentId}</div>
                </div>
              </li>
            </ul>
          </div>
          <div className="payment--detail--btn-2">
            <ButtonComponent
              className="btn mr-2 payment--view--boat--btn"
              onClick={() => data?.salesEngineId?.boat && viewBoatHandler(data.salesEngineId.boat)}
            >
              View Boat
            </ButtonComponent>
            <ButtonComponent
              className="btn payment--view--salesengine--btn"
              onClick={() => {
                props.history &&
                  data?.salesEngineId?._id &&
                  props.history.push(`/sales-engine-process/${data.salesEngineId._id}`)
              }}
            >
              View Sales Engine
            </ButtonComponent>
          </div>
        </div>
        {expnaded && (
          <div className="mt-3">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>BUYER</th>
                  <th>Payout Amount</th>
                  <th>Status</th>
                  <th>ACTION</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{nameFormatter([data?.salesEngineId.buyer?.firstName, data?.salesEngineId.buyer?.lastName])}</td>
                  <td>
                    <CurrencyContextConsumer>
                      {({ currentCurrency }) => <span>{getConvertedPrice(data.amount / 100, currentCurrency)} </span>}
                    </CurrencyContextConsumer>
                  </td>
                  <td>
                    <div className="boatShow-action-button justify-content-start">
                      <span
                        className={`${data.status === 'succeeded' ? 'bg-green-color' : 'bg-red-color'} payment--status--detail`}
                      >
                        {TitleCase(data.status)}
                      </span>
                    </div>
                  </td>
                  <td>
                    <ButtonComponent
                      className={`btn mr-2 payment--view--boat--btn`}
                      onClick={() => {
                        setPrinting(true)
                        // if (data.receipt) {
                        //   window && window.open(`${data.receipt}`, '_blank')
                        // } else {
                        //   toast.error('Receipt not found!', { autoClose: 5000 })
                        // }
                      }}
                    >
                      Download Receipt
                    </ButtonComponent>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  siteConfigurations: state.loginReducer.siteConfigurations
})
export default connect(mapStateToProps)(withRouter(PayoutCardList))
