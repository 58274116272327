import { TopLoaderProvider } from './top-loader-ctx'
import { AuthProvider } from './auth-ctx'
import { VisibleProvider } from './visible-ctx'
import { MutedProvider } from './muted-ctx'
import { LoginModalProvider } from './login-modal-ctx'
import { ChatModalProvider } from './chat-modal-ctx'
import { SMDbProvider } from './smdb-ctx'
import { CreatePostModalProvider } from './create-post-modal-ctx'

export default function CtxProvider({ children }) {
  return (
    <TopLoaderProvider>
      <AuthProvider>
        <VisibleProvider>
          <MutedProvider>
            <LoginModalProvider>
              <ChatModalProvider>
                <SMDbProvider>
                  <CreatePostModalProvider>{children}</CreatePostModalProvider>
                </SMDbProvider>
              </ChatModalProvider>
            </LoginModalProvider>
          </MutedProvider>
        </VisibleProvider>
      </AuthProvider>
    </TopLoaderProvider>
  )
}
