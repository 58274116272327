import { Layout } from '../../components'
import ImageBanner from '../../components/mainBanner/ImageBanner'
import { Col, Image, Row } from 'react-bootstrap'
import classNames from 'classnames'
import data from './data'
import _styles from '../../styles/info-screens.module.scss'
import styles from './styles.module.scss'
import { Link } from 'react-router-dom'

const ShipperInfo = ({}) => {
  return (
    <Layout noFooterMargin>
      <div className={_styles.secPadding}>
        <div className="container100">
          {/* <Row>
          </Row> */}

         <Row>
            <Col xs={12}>
              <h1 className="r-h1 mb-0">Boat Shipper Service</h1>
            </Col>
            <Col md={6}>
              <h2 className={_styles.fz_h} style={{ lineHeight: 1.5 }}>
                <div style={{ color: '#018BBC' }}>As a shipper partnered with AdamSea,</div> you'll benefit from our extensive
                network of boat buyers and sellers from around the world.{' '}
              </h2>
              <p className={classNames(_styles.fz_p, 'mt-30')}>
                With our innovative bidding system, you can submit competitive shipping quotes and showcase your expertise in
                handling boat transportation. Buyers will have the option to compare and choose the best shipping option for
                their specific needs, ensuring a smooth and reliable delivery process. AdamSea offers advanced tracking and
                communication tools that allow you to stay in close contact with boat sellers and buyers throughout the shipping
                journey. You can provide real-time updates, address any concerns or inquiries, and ensure a high level of
                customer satisfaction.
              </p>
            </Col>

            <Col md={6} className="text-right">
              <Image src={require('../../assets/images/info-screens/shipper-pg--intro-side.png')} alt="shippers intro" fluid />
            </Col>
          </Row>
        </div>
      </div>

      <div className={_styles.secPadding}>
        <div className="container100">
          <h2 className={_styles.fz_h}>Why Choose AdamSea as a shipper partner </h2>
          <Row className="mt-30">
            {data.map(col => (
              <Col md={6} lg={4}>
                <div className={styles.card}>
                  <h3 className={_styles.fz_h}>{col.title}</h3>
                  <p className={classNames(_styles.fz_p, 'mt-30')}>{col.text}</p>
                </div>
              </Col>
            ))}
          </Row>
        </div>
      </div>

      <div className={classNames(_styles.secPadding)}>
        <div className="container100">
          <h2 className={_styles.fz_h}>How it works</h2>
          <p className={_styles.fz_p + ' mt-20'}>
            <Link to="/register/yacht-shipper" className="btn btn-primary rounded-full">Sign up and generate your agreement ID</Link>
          </p>

          <ol className={"mt-30 " + _styles.fz_ul_lg}>
            <li>Receive a request from the buyer for the boat that sold in AdamSea marketplace.</li>
            <li>Submit a proposal and wait for payment.</li>
            <li>
              <div>When AdamSea receives the payment, the shipper can start the work.</div>
              <div className="mt-20">
                <Image src={require('../../assets/images/info-screens/shipper-how-1.png')} alt="Receive a request from the buyer for the boat that sold" fluid />
              </div>
            </li>
            <li>
              <div>Upload shipment documents</div>
              <div className="mt-20">
                <Image src={require('../../assets/images/info-screens/shipper-how-2.png')} alt="Upload shipment documents" fluid />
              </div>
            </li>
            <li>Get paid by AdamSea when you submit the shipping documents</li>
          </ol>
        </div>
      </div>

      {/* <div className={classNames(_styles.waveCont, _styles.waveCont_white)}> */}
        <div className={classNames(_styles.secPadding, _styles.bgCircle)}>
          <div className="container100 text-center">
            <p className={_styles.fz_h}>
              Our platform provides a seamless and efficient process for shipping companies to connect with boat owners and
              facilitate the transportation of boats to their new destinations.
            </p>
            {/* <div className="mt-50">
              <button type="button" className={styles.partnerBtn}>
                Be a Partner &rarr;
              </button>
            </div> */}
          </div>
        </div>
      {/* </div> */}
    </Layout>
  )
}

export default ShipperInfo
