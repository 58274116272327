import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Container } from 'react-bootstrap'
import { Grid } from '@material-ui/core'
import {
  salesEngineAssignShipper,
  paymentRequest,
  skipShipment,
  addBoatShipmentLocation,
  getSingleSalesEngine,
  getAllShipmentProposal,
  getPaymentReceipt,
  getDocumentUrl,
  getShipperDocument,
  getAuctionByBoatId,
} from '../../../redux/actions'
import PaymentOption from '../../../components/salesEngine/paymentOption'
import GoogleMap from '../../../components/map/map'
import { ShipperCarousel, paymentTypes } from '../../../util/enums/enums'
import { PaymentInformation, SellerInformation, UserInformationCard } from '../../../components'
import './SalesEngineStepper.scss'
import { getAddress, getLatLngFromGeometric } from '../../../helpers/jsxHelper'
import { SuccessNotify } from '../../../helpers/notification'
import { CommonModal } from '../../../components/modal/commonModal'
import { ShipperRequestList } from '../../../components/salesEngine/ShipperRequestList'
import { CarouselWrapper } from '../../../components/carouselComponent/carouselWrapper'
import { CommonSalesEnginTitle } from '../../../components/salesEngine/CommonSalesEnginTitle'
import { AgentInfoCard } from '../../../components/salesEngine/AgentInfoCard'
import { ButtonComponent } from '../../../components/form/Button'
import { getAllPageInfoByType } from '../../../redux/actions/pageInfoByTypeAction'
import GoogleMarker from '../../../components/map/marker'
import { getShipmentMarker, getSingleBoatMarker } from '../../../helpers/boatHelper'
import { salesEngineStatus } from '../SalesEngineHelper'
import BoatInformation from '../../../components/salesEngine/BoatInformation'
import { getWithStripeFee } from '../../../util/utilFunctions'
import { PaymentDetail } from '../../../components/salesEngine/PaymentDetail'

class Shipment extends Component {
  state = {
    selectShipmentLocation: false,
    isUpdate: false,
    isOpen: false,
    shipmentLocationData: null,
    checkUser: false,
    shipperDocumentUploaded: false,
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const {
      shipmentLocationAddedSuccess,
      assignShipperSuccess,
      salesEngine,
      skipShipmentSuccess,
      shipperDocument,
      isSeller,
      isBuyer,
      getDocumentSuccess,
    } = nextProps

    if (assignShipperSuccess) {
      getSingleSalesEngine({ id: salesEngine.id })
    }

    if (skipShipmentSuccess && salesEngine?.isShipperSkip) {
      return {
        isOpen: false,
      }
    }

    if (getDocumentSuccess) {
      const shipperDocumentId = shipperDocument?.id
      const shipperDocumentUploaded = isSeller && shipperDocumentId
      const checkUser = isSeller && !shipperDocumentId
      return {
        checkUser,
        shipperDocumentUploaded,
      }
    }

    return null
  }

  componentDidUpdate(prevProps) {
    if (this.props.shipmentLocationAddedSuccess && this.props.shipmentLocationAddedCount !== prevProps.shipmentLocationAddedCount) {
      SuccessNotify('Shipment Location Added Successfully.')
    }

    if (this.props.skipShipmentSuccess && this.props.salesEngine?.isShipperSkip && this.props.skipShipmentCount !== prevProps.skipShipmentCount) {
      SuccessNotify('Shipment Process Skipped.')
    }
  }

  componentDidMount() {
    const { salesEngine, getAllShipmentProposal, getShipperDocument, getAuctionByBoatId } = this.props
    getAllShipmentProposal(salesEngine.id)

    if (salesEngine?.buySellProcess.includes(salesEngineStatus.shipperPaymentCompleted)) {
      getShipperDocument({ id: salesEngine.id })
    }

    if (salesEngine.shipmentLocation?.geometricLocation) {
      const { geometricLocation } = salesEngine.shipmentLocation
      const { type, __typename, ...newGeometricLocation } = geometricLocation
      const latLng = getLatLngFromGeometric(newGeometricLocation)
      this.setState({ isUpdate: true, selectShipmentLocation: true, latLng })
    }

    if (!salesEngine.isShipperSkip) {
      this.setState({ selectShipmentLocation: true })
    }

    salesEngine.boat?.isAuctionRoomCreated && getAuctionByBoatId({ id: salesEngine.boat?.id })
  }

  locationModalHandler = () => {
    const { salesEngine, skipShipment } = this.props

    if (salesEngine?.isShipperSkip) {
      skipShipment({ id: salesEngine.id, isShipperSkip: false })
    }

    this.setState(preState => ({ selectShipmentLocation: true }))
  }

  confirmationModal = () => {
    this.setState(preState => ({ isOpen: !preState.isOpen, selectShipmentLocation: false }))
  }

  removeShipper = ({ id }) => {
    const { salesEngineAssignShipper } = this.props
    salesEngineAssignShipper({ shipperId: '', id, removeShipper: true })
  }

  fetchMapInfo = result => {
    const {
      salesEngine: { id },
    } = this.props
    const input = {
      address: result.address1,
      city: result.city,
      country: result.country,
      postalCode: result.zip,
      state: result.state,
      geometricLocation: result.geometricLocation,
    }
    const shipmentLocationData = { id, input }
    this.setState({ shipmentLocationData })
  }

  render() {
    const {
      selectShipmentLocation,
      isUpdate,
      latLng,
      isOpen,
      shipmentLocationData,
      checkUser,
      shipperDocumentUploaded,
      shipperDocumentId,
    } = this.state
    const {
      salesEngine,
      shipmentProposals,
      isBuyer,
      isSeller,
      paymentRequest,
      skipShipment,
      currentUser,
      history,
      salesEngineAssignShipper,
      getAllPageInfoByType,
      getPaymentReceipt,
      getDocumentUrl,
      shipperDocument,
      addBoatShipmentLocation,
      isAgent,
      paymentProcessing,
      boatAuctionRoom,
    } = this.props

    const shipperPaymentData =
      shipmentProposals?.length > 0 && shipmentProposals.find(item => item.shipper?.id === salesEngine?.shipper?.id)

    const {
      commonAddress: { geometricLocation = null, city, country },
    } = getAddress(salesEngine.boat.address)

    const amountWithFee = getWithStripeFee(salesEngine?.paymentInformation?.shipperSubtotalPrice)

    return (
      <Container
        fluid
        className={`${
          !selectShipmentLocation || ((!isBuyer || isSeller) && !shipperDocument?.id) ? 'h-100' : ''
        } pl-0 pr-0 salesEngine--shipper--container`}
      >
        <Grid container className={!selectShipmentLocation || ((!isBuyer || isSeller) && !shipperDocument?.id) ? 'h-100' : ''}>
          {!salesEngine.shipperPayment && !salesEngine.shipper && isBuyer ? (
            // need to confirm
            // !salesEngine.isShipperSkip &&
            <Grid item xs={12}>
              <div className="shipment--pick--question">How would you like to receive your Boat ?</div>
              <div className="shipment--pick--boat--label">Please select one from below</div>
              <div className="shipment--pick--boat--btn">
                {isBuyer && (
                  // !salesEngine.isShipperSkip &&
                  <button
                    type="button"
                    className={`${
                      salesEngine.isShipperSkip && 'shipment--blue--border'
                    } button btn w-auto btn-outline-primary inspection-payment-skip-btn`}
                    onClick={this.confirmationModal}
                    disabled={salesEngine.selectedShipper}
                  >
                    {'Pickup your Boat'}
                  </button>
                )}
                {/* {!salesEngine.isShipperSkip && */}
                {/* <> */}
                <div className="or">OR</div>
                {!isSeller && (
                  <button
                    className="button btn w-auto btn-outline-primary inspection-payment-skip-btn"
                    type="button"
                    onClick={this.locationModalHandler}
                  >
                    {'Ship my Boat'}
                  </button>
                )}
                {/* </> */}
                {/* } */}
              </div>
            </Grid>
          ) : (
            (!isBuyer || isSeller) &&
            !shipperDocument?.id && (
              <div className="h-100 w-100 d-flex justify-content-center align-items-center">
                <span className="buyer--proceeding--shipment">Buyer is proceeding with shipment process</span>
              </div>
            )
          )}

          {salesEngine.isShipperSkip && !selectShipmentLocation && (
            <Grid item xs={12}>
              <div className="congratulation--content--msg">
                <div className="congratulation--max--width">
                  <div className="congratulation--massage">Congratulation..! Your process has been Successfully Done.</div>
                </div>

                <Grid container className="shipment--pickup--info--section">
                  <Grid item xs={4} className="shipment--pickup--left--section">
                    <div className="address--shipment--note">Please collect your boat from the below address.</div>
                    <div className="address--shipment--final p-0">
                      {salesEngine.boat &&
                        salesEngine.boat.address &&
                        salesEngine.boat.address.length > 0 &&
                        salesEngine.boat.address[0].address1}
                    </div>
                  </Grid>
                  {salesEngine.boat && (
                    <Grid item xs={8} className="shipment--pick--map">
                      <GoogleMarker
                        isRedirectToMap
                        geometricLocation={geometricLocation}
                        isInnerPage={false}
                        className=""
                        markers={getSingleBoatMarker(salesEngine.boat, false, true)}
                        height={30}
                        width={'100%'}
                      />
                    </Grid>
                  )}
                </Grid>
              </div>
            </Grid>
          )}

          {isBuyer &&
            this.props.payments?.length > 0 &&
            this.props.payments.map(item => (
              <PaymentDetail salesEngine={salesEngine} isShipperPaymentDetail paymentItem={item} />
            ))}

          {salesEngine.shipper && (shipperDocumentUploaded || isBuyer) && (
            <Grid item xs={12} className="shipper-user--information xxx">
              <UserInformationCard
                isShipper
                shipperPaymentData={shipperPaymentData}
                className={'assign-single-list'}
                userInfo={salesEngine.shipper}
                isBuyer={isBuyer}
                salesEngine={salesEngine}
                payment={this.props.payments && this.props.payments[0]}
                history={history}
                handleChange={this.removeShipper}
                proposalDocument={shipperPaymentData.proposalDocument}
                getDocumentUrl={getDocumentUrl}
                enableReview={shipperDocument?.id && true}
                isAgent={isAgent}
                shipperDocumentUploaded={shipperDocumentUploaded}
              />
            </Grid>
          )}
          {shipperDocument?.id && salesEngine?.agent && (isSeller || isBuyer) && (
            <Grid item xs={12} className="shipment--after--agent--section">
              <AgentInfoCard
                agent={salesEngine?.agent}
                className={'assign-single-list'}
                isSingle={false}
                history={history}
                salesEngine={salesEngine}
                isShipper
                enableReview
                afterDocumentReview
                isAgent={isAgent}
                isBuyer
                shipperDocumentUploaded={shipperDocumentUploaded}
              />
            </Grid>
          )}

          {isBuyer && !salesEngine.shipper && !salesEngine.isShipperSkip && selectShipmentLocation && (
            <>
              <Grid item xs={12} className="shipment--Map--position">
                <GoogleMap
                  shipmentMap
                  isRedirectToMap
                  geometricLocation={geometricLocation}
                  shipmentAddressLabel="Add Address"
                  className="googleMap-position"
                  latLng={latLng}
                  fetch={result => this.fetchMapInfo(result)}
                  height={'100%'}
                  width={100}
                  placeHolder="Shipment Location"
                  columnName={'address'}
                  isUpdate={isUpdate}
                  isFullGrid
                  isRegister
                  zoomControl={false}
                  crossCountryAllowed
                />
                <Grid container>
                  <Grid item xs={4}></Grid>
                  <Grid item xs={8}>
                    <div className="shipper--send--location--div">
                      <ButtonComponent onClick={() => addBoatShipmentLocation(shipmentLocationData)}>
                        {isUpdate ? 'Update Address' : 'Share your address with the Shippers for pricing'}
                      </ButtonComponent>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}

          {(isBuyer || shipperDocumentUploaded) && salesEngine.shipper && (
            <Grid item xs={12} className="">
              <PaymentOption
                paymentRequest={paymentRequest}
                paymentTo={paymentTypes.shipperPayment}
                price={shipperPaymentData?.price}
                currentUser={currentUser}
                salesEngine={salesEngine}
                paymentDone={salesEngine.shipperPayment}
                disable={salesEngine.shipperPayment || salesEngine.shipper}
                getAllPageInfoByType={getAllPageInfoByType}
                getPaymentReceipt={getPaymentReceipt}
                totalPayment={amountWithFee}
                shipperDocument={shipperDocument}
                getDocumentUrl={getDocumentUrl}
                paymentProcessing={paymentProcessing}
                isBuyer={isBuyer}
                isSeller={isSeller}
                auctionId={
                  boatAuctionRoom && (boatAuctionRoom?.id !== null || boatAuctionRoom?.id !== undefined)
                    ? boatAuctionRoom?.id
                    : null
                }
                isFromAuction={
                  boatAuctionRoom && (boatAuctionRoom?.depositAmount === null || boatAuctionRoom?.depositAmount === undefined)
                    ? false
                    : true
                }
              />
            </Grid>
          )}
          {salesEngine?.shipperPayment && (checkUser || isBuyer) && <PaymentInformation />}
        </Grid>

        {salesEngine.shipper && (checkUser || isBuyer) && (
          <Grid container spacing={3}>
            {salesEngine.boat && <BoatInformation salesEngine={salesEngine} />}

            <SellerInformation salesEngine={salesEngine} isSeller={isSeller} history={history} isBuyer={isBuyer} />
          </Grid>
        )}

        {isBuyer && !salesEngine.shipper && !salesEngine.shipperPayment && selectShipmentLocation && (
          <Grid container spacing={3}>
            <Grid item xs={12} className="pb-0">
              <CommonSalesEnginTitle title="Select Shippers" className="select--shippers--title" />
            </Grid>
            <Grid item xs={12}>
              {shipmentProposals && shipmentProposals.length > 0 ? (
                <CarouselWrapper items={ShipperCarousel} top={30}>
                  {shipmentProposals.map(item => {
                    return (
                      item.shipper && (
                        <ShipperRequestList
                          history={history}
                          price={item.price}
                          distance={item.distance}
                          proposalDocument={item.proposalDocument}
                          userInfo={item.shipper}
                          className="surveyor-list-nearest--class--name"
                          salesEngine={salesEngine}
                          shipperAcceptRequest={salesEngineAssignShipper}
                          getDocumentUrl={getDocumentUrl}
                        />
                      )
                    )
                  })}
                </CarouselWrapper>
              ) : (
                <div className="no-shipper-alert">
                  {' '}
                  <span className="alert alert-info-user">No shipper has sent a proposal yet. Please wait until they do.</span>
                </div>
              )}
            </Grid>
          </Grid>
        )}

        <CommonModal
          className="shipment--own--pick--main--modal"
          open={isOpen}
          close={this.confirmationModal}
          hideCloseIcon={true}
          title="Are you sure you would like to pick up the boat by yourself?"
        >
          <div className="shipment--own--pick--button">
            <ButtonComponent
              className=" h-auto as--success--outline btn--padding btn--font mr-3"
              onClick={() => skipShipment({ id: salesEngine.id, isShipperSkip: true })}
            >
              Yes
            </ButtonComponent>

            <ButtonComponent className=" h-auto as--cancel--outline btn--padding btn--font" onClick={this.confirmationModal}>
              No
            </ButtonComponent>
          </div>
        </CommonModal>
      </Container>
    )
  }
}
const mapStateToProps = state => ({
  shipmentProposals: state.salesEngineReducer.shipmentProposals,
  shipmentLocationAddedSuccess: state.salesEngineReducer.shipmentLocationAddedSuccess,
  assignShipperSuccess: state.salesEngineReducer.assignShipperSuccess,
  skipShipmentSuccess: state.salesEngineReducer?.skipShipmentSuccess,
  currentUser: state.loginReducer && state.loginReducer.currentUser,
  shipperDocument: state?.salesEngineReducer?.shipperDocument,
  paymentProcessing: state.salesEngineReducer?.paymentProcessing,
  getDocumentSuccess: state.salesEngineReducer?.getDocumentSuccess,
  boatAuctionRoom: state.boatReducer.boatAuctionRoom,
  payments: state.salesEngineReducer?.payments,
  shipmentLocationAddedCount: state.salesEngineReducer?.shipmentLocationAddedCount,
  skipShipmentCount: state.salesEngineReducer?.skipShipmentCount
})

const mapDispatchToProps = dispatch => ({
  salesEngineAssignShipper: data => dispatch(salesEngineAssignShipper(data)),
  paymentRequest: data => dispatch(paymentRequest(data)),
  skipShipment: data => dispatch(skipShipment(data)),
  addBoatShipmentLocation: data => dispatch(addBoatShipmentLocation(data)),
  getSingleSalesEngine: data => dispatch(getSingleSalesEngine(data)),
  getAllShipmentProposal: data => dispatch(getAllShipmentProposal(data)),
  getAllPageInfoByType: data => dispatch(getAllPageInfoByType(data)),
  getPaymentReceipt: data => dispatch(getPaymentReceipt(data)),
  getDocumentUrl: data => dispatch(getDocumentUrl(data)),
  getShipperDocument: data => dispatch(getShipperDocument(data)),
  getAuctionByBoatId: data => dispatch(getAuctionByBoatId(data)),
})
export default connect(mapStateToProps, mapDispatchToProps)(Shipment)
