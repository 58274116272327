import OutsideClickHandler from 'react-outside-click-handler'
import AdamseaChat from '../../../chat/adamseaChat'
import { useChatModal } from '../../contexts/chat-modal-ctx'
import compStyles from '../../styles/components.module.scss'
import styles from './chat-modal.module.scss'

export default function ChatModal({ zIndex }) {
  const chatModal = useChatModal()

  if (!chatModal.isOpen) return null

  return (
    <div className={compStyles.overlay} style={{ zIndex }}>
      <OutsideClickHandler display="contents" onOutsideClick={chatModal.hide}>
        <AdamseaChat height={100} className={styles.chat} />
      </OutsideClickHandler>
    </div>
  )
}
