import React, { useState, useEffect, useContext } from 'react'
import moment from 'moment';

import classNames from 'classnames';


import './chat.scss'

//components
import { Field } from '../../components'

//helpers
import { lowerHypenCase, nameFormatter } from '../../helpers/string'
import { getFirstCharacter } from '../../helpers/jsxHelper'
import { useDispatch, useSelector } from 'react-redux'
import {
  getChatListsByCurrentUser,
  getUserListsByCurrentUser,
  getChatMe,
  clearCratedChat,
  blockUserByID,
  editGroupScreen,
  setIsFirstTimeChat,
  backToChatList,
  clearUserSearch,
  chatToggle,
  clearChatThreadIfNotChatId,
  clearChatById,
  setUnreadCount,
  setUserList,
  setFriendList,
  getFriendUsersByCurrentUser,
  setChatList,
} from '../../redux/actions/chatAction'
import {
  getChatsList,
  getChatUsersList,
  getFriendList,
  getIsAddGroupName,
  getIsBlockDisabled,
  getIsChatChoiceGroup,
  getIsChatCreated,
  getIsCreatedChatMessage,
  getIsFirstTime,
  getIsFriendView,
  getIsGroupChatCreateScreen,
  getSelectUser,
  getUserListSearch,
} from '../../redux/selector/chat'
import { LeftSideUserList } from '../../components/chats/leftSideUserlist'
import { ActiveThreadChat } from '../../components/chats/chatThread'
import UserContext from '../../UserContext'
import { CurrentUser } from './currentUser'
import { SendMessageChat } from './sendMessage'
import { deleteChatByID } from '../../redux/actions/chatAction'
import { chatType } from '../../util/chat'
import { ChatContext } from '../../contexts/ChatContext'
import OutsideClickHandler from 'react-outside-click-handler'
import { rolesEnum } from '../../util/enums/enums'
import { CommonTooltip } from '../CommonTooltip'
// import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { get } from 'lodash'
import { getAllManageSiteConfiguration } from '../../graphql/userSchema'
import { graphqlClient } from '../../helpers/graphqlClient'
import { getImgUrl } from '../../util/utilFunctions'
import { socket } from '../../socket'
import { socketEvent } from '../../util/enums/socketEnums'

import { v4 as uuid } from 'uuid'
import QRCode from 'react-qr-code';
import { envConfig } from '../../config';

const ChatWrapper = ({ height }) => {
  const { currentUser } = useContext(ChatContext)
  const { currentUser: loginUser, history } = useContext(UserContext)
  const dispatch = useDispatch()
  const [isAttachmentOpen, setIsAttachmentOpen] = useState(false)
  //Search Query
  const [searchPeopleOrGroup, setSearchPeopleOrGroup] = useState('')

  // Current Active Thread/Channel
  let threadLoader = false

  /**
   *  Selector Chat Start
   */
  const selectUser = useSelector(getSelectUser)
  const [userId, setUserId] = useState('')
  const [freezed, setFreezed] = useState(false)
  const isCreatedChatMessage = useSelector(getIsCreatedChatMessage)
  const friendList = useSelector(getFriendList)
  const isGroup = useSelector(getIsGroupChatCreateScreen)
  // const isEditGroupScreen = useSelector(getIsEditGroupScreen)

  const isAddGroupName = useSelector(getIsAddGroupName)
  const isBlockDisabled = useSelector(getIsBlockDisabled)
  const usersList = useSelector(getChatUsersList)
  const isFirstTime = useSelector(getIsFirstTime)
  const isFriendView = useSelector(getIsFriendView)
  const chatsList = useSelector(getChatsList)

  const [logo, setLogo] = useState('')

  useEffect(() => {
    loadConfigs()
  }, [])

  useEffect(() => {
    if (!currentUser?.id) return
    if (!usersList?.length && isFirstTime) {
      dispatch(getChatListsByCurrentUser({ limit: 1000, searchTerm: '' }))
    }
  }, [currentUser, isFirstTime])

  useEffect(() => {
      if (isFriendView) {
          setSearchPeopleOrGroup('')
      }
  }, [isFriendView])

  useEffect(() => {
    if (isFirstTime) return
    if (searchPeopleOrGroup) {
      isCreatedChatMessage && dispatch(clearCratedChat())
      let newList=[]
      if(isFriendView){
        newList= friendList.filter((friend)=>{
          return (
            friend.firstName.toLocaleLowerCase().includes(searchPeopleOrGroup.trimStart().toLocaleLowerCase())||friend.lastName.trimStart().toLocaleLowerCase().includes(searchPeopleOrGroup.toLocaleLowerCase()))
          }
          )
          if(newList?.length>0){
            dispatch(setFriendList(newList))
          }
          else{
            dispatch(setFriendList([]))
          }
      }
      else{
        const getUserData = setTimeout(() => {
              dispatch(getUserListsByCurrentUser({ limit: 1000, searchTerm: searchPeopleOrGroup }))
        }, 500)
        return () => clearTimeout(getUserData)
      }
    } else {
      if (!currentUser?.id) return
      dispatch(clearUserSearch())
      if (isFriendView){ dispatch(getFriendUsersByCurrentUser())}
      if (chatsList.length) return
      const getChatData = setTimeout(() => {
        dispatch(getChatListsByCurrentUser({ limit: 1000, searchTerm: '' }))
      }, 500)
      return () => clearTimeout(getChatData)
    }
  }, [searchPeopleOrGroup])

  useEffect(() => {
    const id = selectUser?.appUserId?selectUser?.appUserId:selectUser?.users?.filter(user => user.id !== currentUser?.id)[0]?.appUserId
    setUserId(id)

    if (selectUser?.chatType === chatType.normalchat) {
      const freezed = selectUser?.freezed ?? selectUser?.users?.filter(user => user.id !== currentUser?.id)[0]?.freezed
      setFreezed(!!freezed)
    }
  }, [selectUser])


  const loadConfigs = async () => {
    try {
      const res = await graphqlClient.query({
        query: getAllManageSiteConfiguration,
      })

      if (get(res, 'data.getSiteConfigurations.adamseaConnectLogo', '')) {
        setLogo(getImgUrl(res.data.getSiteConfigurations.adamseaConnectLogo) || '')
      }
    } catch (err) {
      console.log(err)
    }
  }

  const toggleAttachmentHandler = () => setIsAttachmentOpen(isAttachmentOpen => !isAttachmentOpen)

  const openLeftFriendList = () => {
    document.getElementById('left--chat--main--div-mobile').classList.remove('Chat-opend')
    document.getElementById('chat-left-space-mobile-handel').classList.remove('Chat-opend')

    const child2 = document.getElementById('chat-right-user-profile')
    child2.classList.add('Chat-closed')
    document.getElementById('chat-rigt-div-display-mobile').classList.add('Chat-closed')
  }

  return (
    <>
      <div className="chat chat-new chat-of-members">
        <div className="chat-main">
          <div
            className={classNames('chat-section', {
              'chat-section--isGroup': isAddGroupName,
            })}
          >
            <div className="chat-header">
              <CurrentUser />
              {![isGroup].includes(true) && (
                <>
                  <div onClick={openLeftFriendList} className="open-left-friend-list">
                    <ArrowBackIcon className="back-arow-show" />
                  </div>
                  <div id="chat-right-user-profile" className="chat-right-user">
                    <>
                      {selectUser?.id && (
                        <div className="chat-right-user-title sss">
                          <div className="chat-user-info">
                            <div
                              className="chat-user-image"
                              onClick={() => {
                                if (selectUser.chatType !== chatType.groupChat && !freezed) {
                                  history.push(`/user-profile/${userId}/${lowerHypenCase(selectUser.name)}`)
                                  socket.emit(socketEvent.LeaveChannel,selectUser?.chatId)
                                  dispatch(chatToggle(false))
                                }
                              }}
                            >
                              {freezed ? (
                                <img src={require('../../assets/images/user_9136017.svg')} alt='user' />
                              ) : selectUser?.imageUrl ? (
                                <img loading="lazy" src={selectUser.imageUrl ?? selectUser.profileImage} alt="Profile" />
                              ) : !selectUser?.chatId && selectUser?.profileImage ? (
                                <img loading="lazy" src={selectUser.profileImage} alt="Profile" />
                              ) : (
                                getFirstCharacter(selectUser?.name)
                              )}
                              {selectUser.chatType === chatType.normalchat && (
                                <div className="chat--online">
                                  <div
                                    className={classNames('online-div-user', {
                                      disconnect: !selectUser?.isOnline,
                                    })}
                                  ></div>
                                </div>
                              )}
                            </div>
                            <div className="chat-user-desc">
                              <div className="chat-user-desc-info">
                                <span className="chat-user-name">{freezed ? 'Deleted User' : nameFormatter([selectUser.name])}</span>
                              </div>
                              <div className="chat-user-role-city">
                                {selectUser.chatType === chatType.normalchat ? (
                                  <span className="chat--user--role">{selectUser?.metaData?.userType?.replaceAll("-"," ")}</span>
                                ) : (
                                  <>
                                    <span className="chat--user--role chat-group-name" >
                                      {selectUser?.users?.map(obj => obj.freezed ? 'Deleted User' : obj.name)?.join(', ')}
                                    </span>
                                    <div className="chat-group-name--dropdown">
                                      <div className="chat-group-name--dropdown-list">
                                        {selectUser?.users?.map(i => (
                                          <span onClick={() => {
                                            if (i.freezed) return
                                            history.push(`/user-profile/${i.appUserId}/${lowerHypenCase(i.name)}`)
                                            socket.emit(socketEvent.LeaveChannel,selectUser?.chatId)
                                            dispatch(chatToggle(false))
                                          }} className="cursor-pointer chat-group-name--dropdown-list--user-wrp">
                                          <img src={i.freezed ? require('../../assets/images/user_9136017.svg') : (i.profileImage || require('../../assets/images/chat/emoji.svg'))} alt={i.name} className="chat-group-name--dropdown-list--user-pic" /> {i.freezed ? 'Deleted User' : i.name}</span>
                                        ))}
                                      </div>
                                    </div>
                                  </>
                                )}

                                {!selectUser?.isOnline &&
                                  selectUser?.lastActiveTimeList?.length > 0 &&
                                  selectUser?.chatType === chatType.normalchat && (
                                    <span className="chat-user-active-time">{`Active ${moment(
                                      selectUser?.lastActiveTimeList[0]?.lastActive
                                    ).fromNow()}`}</span>
                                  )}
                                {!selectUser?.isOnline &&
                                  !selectUser?.lastActiveTimeList?.length > 0 &&
                                  !selectUser?.isOnline &&
                                  selectUser?.chatType === chatType.normalchat && (
                                    <span className="chat-user-active-time">Last seen days ago</span>
                                  )}

                                {/* <span className="chat--user--city">{cityCountryNameFormatter(selectUser?.userMeta?.city, selectUser?.userMeta?.country)}</span> */}
                              </div>
                            </div>
                          </div>

                          <div className="chat-right-user-nav chat-dropdown-all-messages cursor-pointer">
                            <OutsideClickHandler display="contents" onOutsideClick={() => setIsAttachmentOpen(false)}>
                              <img
                                loading="lazy"
                                src={require('../../assets/images/chat/nav.svg')}
                                alt="nav"
                                onClick={toggleAttachmentHandler}
                              />
                              {isAttachmentOpen && (
                                <div className="chat--opened--dropdown chat-opened-attachment-dropdown">
                                  <ul className="chat--opened--dropdown--ul">
                                    {selectUser.chatType === chatType.normalchat && (
                                      <li
                                        onClick={() => {
                                          !isBlockDisabled &&
                                            dispatch(
                                              blockUserByID({
                                                blockedUserId: selectUser.users.filter(i => i.id !== currentUser.id)[0].id,
                                                action: selectUser.isBlocked ? 'Unblock' : 'Block',
                                              })
                                            )
                                          setIsAttachmentOpen(false)
                                        }}
                                      >
                                        {selectUser.isBlocked ? 'Unblock' : 'Block'}
                                        <span className="block--user--name">
                                          {/* ({nameFormatter([activeThreadMember?.firstName, activeThreadMember?.lastName])}) */}
                                        </span>
                                      </li>
                                    )}
                                    {selectUser?.chatId && (
                                      <>
                                        <li
                                          onClick={() => {
                                            // dispatch(deleteChatByID({ chatIds: [selectUser.chatId], isClear: true }))
                                            dispatch(clearChatById({ chatId: selectUser.chatId }))
                                            // dispatch(clearChatThreadIfNotChatId())
                                            setIsAttachmentOpen(false)
                                          }}
                                        >
                                          Clear Chat
                                        </li>
                                        {selectUser.chatId && (
                                          <li
                                            onClick={() => {
                                              // dispatch(deleteChatByID({ chatIds: [selectUser.chatId], isClear: false }))
                                              socket.emit(socketEvent.LeaveChannel,selectUser?.chatId)
                                              dispatch(deleteChatByID({ chatIds: [selectUser.chatId] }))
                                              // dispatch(clearChatThreadIfNotChatId())
                                              setIsAttachmentOpen(false)
                                            }}
                                          >
                                            Delete User
                                          </li>
                                        )}
                                        {selectUser.chatType === chatType.groupChat &&
                                          currentUser?.id === selectUser?.createdBy?.id && (
                                            <li
                                              onClick={() => {
                                                dispatch(editGroupScreen(true))
                                                setIsAttachmentOpen(false)
                                              }}
                                            >
                                              Edit Group
                                            </li>
                                          )}
                                      </>
                                    )}
                                  </ul>
                                </div>
                              )}
                            </OutsideClickHandler>
                          </div>
                        </div>
                      )}
                    </>
                  </div>{' '}
                </>
              )}
            </div>
            <div className="chat-main-chat">
              <div id="left--chat--main--div-mobile" className="chat-left-side">
                {!isGroup && (
                  <div id="chat-left-user-info-chat" className="chat--search--div--spacing">
                    <div className="chat--left--search--div">
                      <div className="chat--search--img">
                        <img loading="lazy" src={require('../../assets/images/chat/search.svg')} alt="Search" />
                      </div>
                      <div className="chat--input--div chat--search--text">
                        <Field
                          name="leftSearchInput"
                          type="text"
                          placeholder="Search by name or group"
                          value={searchPeopleOrGroup}
                          onChangeText={e => setSearchPeopleOrGroup(e.target.value)}
                          styles={{ paddingLeft: 15 }}
                        />
                      </div>
                    </div>
                  </div>
                )}
                <div
                  className={`${
                    threadLoader
                      ? 'h-100 d-flex justify-content-center align-items-center'
                      : 'h-100 listing-height-chat-messages'
                  }`}
                >
                  {!isAddGroupName && <LeftSideUserList height={height} />}
                </div>
              </div>
              {selectUser?.name && !isGroup ? (
                <div id="chat-rigt-div-display-mobile" className="chat--right--div">
                  <ActiveThreadChat />
                  <SendMessageChat setSearchPeopleOrGroup={setSearchPeopleOrGroup} />
                </div>
              ) : (
                <div className="chat-no-active">
                  <div className="chat-no-active--logo">
                    <img loading="lazy" src={logo} alt='logo' />
                  </div>
                  <div className="chat-app-store" style={{ padding: 12, borderRadius: 8, background: '#dff0f2' }}>
                    <div className="chat-app-store-icon text-center">
                      <QRCode value={envConfig.BASE_URL.replace(/\/?$/, '/') + 'getapp'} />
                      <p className="font-14">Download the App</p>
                    </div>

                    <div className="chat-app-store-icon">
                      <img loading="lazy" src={require('../../components/footer/appStore.png')} alt='store'/>
                    </div>

                    <div className="chat-app-store-icon">
                      <img loading="lazy" src={require('../../components/footer/googlePlay.png')} alt='store' />
                    </div>
                  </div>
                  <img loading="lazy" src={require('../ws/Images/no-active.png')} alt='no active'/>

                  <div style={{ marginTop: 30, padding: '16px 24px', borderRadius: 8, background: '#dff0f2' }}>
                    <span className="chat-no-active--text">Stay connected with your contacts even while away from your desk.</span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default ChatWrapper
