import {
  ADD_BOAT_SERVICE_SUCCESS,
  ADD_BOAT_SERVICE_FAILURE,
  CLEAR_ADD_BOAT_SERVICE_FLAGS,
  GET_TYPE_WISE_BOAT_SERVICE,
  GET_TYPE_WISE_BOAT_SERVICE_SUCCESS,
  GET_TYPE_WISE_BOAT_SERVICE_FAILURE,
  GET_RECENTLY_ADDEDD_SERVICE,
  GET_RECENTLY_ADDEDD_SERVICE_SUCCESS,
  GET_RECENTLY_ADDEDD_SERVICE_FAILURE,
  CLEAR_SERVICE_FLAG,
  GET_USER_BOAT_SERVICE,
  GET_USER_BOAT_SERVICE_SUCCESS,
  GET_USER_BOAT_SERVICE_FAILURE,
  CLEAR_EDIT_SERVICE,
  GET_MOST_VIEWED_BOAT_SERVICES,
  GET_MOST_VIEWED_BOAT_SERVICES_SUCCESS,
  GET_MOST_VIEWED_BOAT_SERVICES_FAILURE,
  SEARCH_YACHT_SERVICE,
  SEARCH_YACHT_SERVICE_SUCCESS,
  SEARCH_YACHT_SERVICE_FAILURE,
  CLEAR_SEARCH_YACHT_SERVICE_FLAG,
  GET_ALL_BOAT_SERVICE_TYPES,
  GET_ALL_BOAT_SERVICE_TYPES_SUCCESS,
  GET_ALL_BOAT_SERVICE_TYPES_FAILURE,
  EDIT_YACHT_SERVICE,
  EDIT_YACHT_SERVICE_SUCCESS,
  EDIT_YACHT_SERVICE_FAILURE,
  CLEAR_SERVICE_TYPES_FLAG,
  ADD_LIKE,
  ADD_LIKE_SUCCESS,
  ADD_LIKE_FAILURE,
  GET_SERVICE_DETAIL_SUCCESS,
  TOGGLE_SERVICE_STATUS_SUCCESS,
  GET_SERVICE_DETAIL_FAILURE,
  GET_SERVICE_DETAIL,
  ADD_SERVICE_TAG,
  ADD_SERVICE_TAG_SUCCESS,
  ADD_SERVICE_TAG_FAILURE,
  ADD_SERVICE_TAG__CLEAR,
  GET_SERVICE_TAGS,
  GET_SERVICE_TAGS_SUCCESS,
  GET_SERVICE_TAGS_FAILURE,
  GET_SERVICE_TAGS__CLEAR,
  CHOOSE_FOR_ESTIMATED_COST,
  CHOOSE_FOR_ESTIMATED_COST_SUCCESS,
  CHOOSE_FOR_ESTIMATED_COST_FAILURE,
  CHOOSE_FOR_ESTIMATED_COST__CLEAR,
  GET_SERVICE_PROFILE_FOR_ESTIMATED_COST,
  GET_SERVICE_PROFILE_FOR_ESTIMATED_COST_SUCCESS,
  GET_SERVICE_PROFILE_FOR_ESTIMATED_COST_FAILURE,
  GET_SERVICE_PROFILE_FOR_ESTIMATED_COST__CLEAR,
  IS_CHOSEN_FOR_ESTIMATED_COST,
  IS_CHOSEN_FOR_ESTIMATED_COST_SUCCESS,
  IS_CHOSEN_FOR_ESTIMATED_COST_FAILURE,
  IS_CHOSEN_FOR_ESTIMATED_COST__CLEAR,
} from '../actionTypes'
import { renderSelectOptions, filterLikesReducerArray } from '../../helpers/string'
import { toast } from 'react-toastify'

const InitialState = {
  editYachtService: {},

  isAddSuccess: false,
  isAddError: false,
  providedServices: [],
  getSuccess: false,
  getError: false,
  typeWiseServices: [],
  totalTypeWiseServices: 0,

  recentlyAddedService: [],
  totalRecentlyAddedService: null,

  success: false,
  error: false,
  getOneSuccess: false,
  getOneError: false,
  userService: [],
  isLoading: false,
  singleSuccess: false,
  singleError: false,

  searchedYachtServices: [],
  totalSearchedYachtServices: null,
  isYachtSearched: false,

  isLoading: false,
  serviceDetailForDashboard: {},
}

export const boatServiceReducer = (state = InitialState, action) => {
  const { editYachtService } = state

  switch (action.type) {
    case ADD_BOAT_SERVICE_SUCCESS:
      return {
        ...state,
        isAddSuccess: true,
      }

    case ADD_BOAT_SERVICE_FAILURE:
      return {
        ...state,
        isAddError: true,
      }

    case CLEAR_ADD_BOAT_SERVICE_FLAGS:
      return {
        ...state,
        isAddSuccess: false,
        isAddError: false,
      }

    case GET_TYPE_WISE_BOAT_SERVICE:
      return {
        ...state,
        getSuccess: false,
        getError: false,
        totalTypeWiseServices: 0,
        isPageLoader: true,
      }

    case GET_TYPE_WISE_BOAT_SERVICE_SUCCESS:
      return {
        ...state,
        getSuccess: true,
        getError: false,
        typeWiseServices: action.payload.items,
        totalTypeWiseServices: action.payload.total,
        isPageLoader: false,
      }
    case GET_TYPE_WISE_BOAT_SERVICE_FAILURE:
      return {
        ...state,
        getSuccess: false,
        getError: true,
        isPageLoader: false,
      }
    case GET_RECENTLY_ADDEDD_SERVICE:
      return {
        ...state,
        success: false,
        error: false,
        isPageLoader: true,
      }
    case GET_RECENTLY_ADDEDD_SERVICE_SUCCESS:
      return {
        ...state,
        success: true,
        error: false,
        recentlyAddedService: action.payload.data.getAllYachtServicesByCountry.items,
        totalRecentlyAddedService: action.payload.data.getAllYachtServicesByCountry.total,
        isPageLoader: false,
      }
    case GET_RECENTLY_ADDEDD_SERVICE_FAILURE:
      return {
        ...state,
        success: false,
        error: true,
        isPageLoader: false,
      }
    case CLEAR_SERVICE_FLAG:
      return {
        ...state,
        isAddSuccess: false,
        isAddError: false,
        getSuccess: false,
        getError: false,
        success: false,
        error: false,
        getOneSuccess: false,
        getOneError: false,
      }
    case GET_USER_BOAT_SERVICE:
      return {
        ...state,
        getOneSuccess: false,
        isLoading: true,
        getOneError: false,
      }
    case GET_USER_BOAT_SERVICE_SUCCESS:
      const data = action.payload.data.yachtServiceByUser[0]
      const service = data ? { ...data, serviceProvide: renderSelectOptions(data.serviceProvide, 'name', 'id') } : {}

      return {
        ...state,
        getOneSuccess: true,
        getOneError: false,
        isLoading: false,
        userService: service,
      }
    case GET_USER_BOAT_SERVICE_FAILURE:
      return {
        ...state,
        getOneSuccess: false,
        isLoading: false,
        getOneError: true,
      }
    case GET_MOST_VIEWED_BOAT_SERVICES:
      return {
        ...state,
        geMostViewedSuccess: false,
        isLoading: true,
        geMostViewedError: false,
      }
    case GET_MOST_VIEWED_BOAT_SERVICES_SUCCESS:
      return {
        ...state,
        geMostViewedSuccess: true,
        geMostViewedError: false,
        isLoading: false,
        mostViewedBoatServices: action.payload.data.mostViewedYachtServices.items,
        total: action.payload.data.mostViewedYachtServices.total,
      }
    case GET_MOST_VIEWED_BOAT_SERVICES_FAILURE:
      return {
        ...state,
        geMostViewedSuccess: false,
        isLoading: false,
        geMostViewedError: true,
      }
    case CLEAR_EDIT_SERVICE:
      return {
        ...state,
        userService: {},
      }

    case SEARCH_YACHT_SERVICE:
      return {
        ...state,
        isPageLoader: true,
        isYachtSearched: false,
        // searchedYachtServices: [],
      }

    case SEARCH_YACHT_SERVICE_SUCCESS:
      return {
        ...state,
        isYachtSearched: true,
        searchedYachtServices: action.payload.items,
        totalSearchedYachtServices: action.payload.total,
        isPageLoader: false,
      }

    case SEARCH_YACHT_SERVICE_FAILURE:
      return {
        ...state,
        isYachtSearched: false,
        isPageLoader: false,
      }

    case CLEAR_SEARCH_YACHT_SERVICE_FLAG:
      return {
        ...state,
        isYachtSearched: false,
        singleSuccess: false,
        singleError: false,
      }

    case GET_ALL_BOAT_SERVICE_TYPES: {
      return {
        ...state,
        providedServices: [],
      }
    }

    case GET_ALL_BOAT_SERVICE_TYPES_SUCCESS: {
      return {
        ...state,
        getTypesSuccess: true,
        providedServices: action.payload.items,
        // @kinari disscuss for below code.
        // providedServices: !action.isAdmin ? action.payload.items : state.providedServices,
        providedAdminServices: action.isAdmin ? action.payload.items : state.providedAdminServices,
      }
    }

    case GET_ALL_BOAT_SERVICE_TYPES_FAILURE: {
      return {
        ...state,
        providedServices: [],
      }
    }

    case CLEAR_SERVICE_TYPES_FLAG:
      return {
        ...state,
        getTypesSuccess: false,
      }

    case EDIT_YACHT_SERVICE: {
      return {
        ...state,
        isLoading: true,
        singleSuccess: false,
        singleError: false,
        editYachtService: {},
      }
    }

    case EDIT_YACHT_SERVICE_SUCCESS: {
      return {
        ...state,
        singleSuccess: false,
        isLoading: false,
        singleError: true,
        editYachtService: action.payload,
      }
    }

    case EDIT_YACHT_SERVICE_FAILURE: {
      return {
        ...state,
        singleSuccess: false,
        isLoading: false,
        singleError: true,
        editYachtService: {},
      }
    }

    case ADD_LIKE:
      return {
        ...state,
      }

    case ADD_LIKE_SUCCESS:
      return {
        ...state,
        editYachtService: {
          ...editYachtService,
          likes: filterLikesReducerArray(editYachtService.likes, action.payload, action.moduleType),
        },
      }

    case ADD_LIKE_FAILURE:
      return {
        ...state,
      }

    case GET_SERVICE_DETAIL:
      return {
        ...state,
        isPageLoader: true
      }

    case GET_SERVICE_DETAIL_SUCCESS:
      return {
        ...state,
        serviceDetailForDashboard: action.payload,
        isPageLoader: false
      }

    case GET_SERVICE_DETAIL_FAILURE:
      return {
        ...state,
        isPageLoader: false
      }

    case TOGGLE_SERVICE_STATUS_SUCCESS:
      return {
        ...state,
        serviceDetailForDashboard: {
          ...state.serviceDetailForDashboard,
          status: !state.serviceDetailForDashboard.status,
        },
      }

    case ADD_SERVICE_TAG: {
      return {
        ...state,
        addServiceTag_loading: true,
      }
    }

    case ADD_SERVICE_TAG_SUCCESS: {
      return {
        ...state,
        addServiceTag_success: true,
      }
    }

    case ADD_SERVICE_TAG_FAILURE: {
      return {
        ...state,
        addServiceTag_failure: true,
      }
    }

    case ADD_SERVICE_TAG__CLEAR: {
      return {
        ...state,
        addServiceTag_loading: false,
        addServiceTag_success: false,
        addServiceTag_failure: false,
      }
    }

    case GET_SERVICE_TAGS: {
      return {
        ...state,
        getServiceTags_loading: true,
      }
    }

    case GET_SERVICE_TAGS_SUCCESS: {
      return {
        ...state,
        getServiceTags_success: true,
        getServiceTags_data: action.payload,
      }
    }

    case GET_SERVICE_TAGS_FAILURE: {
      return {
        ...state,
        getServiceTags_failure: true,
      }
    }

    case GET_SERVICE_TAGS__CLEAR: {
      return {
        ...state,
        getServiceTags_loading: false,
        getServiceTags_success: false,
        getServiceTags_failure: false,
      }
    }

    case CHOOSE_FOR_ESTIMATED_COST: {
      return {
        ...state,
        chooseForEstimatedCost_loading: true,
      }
    }

    case CHOOSE_FOR_ESTIMATED_COST_SUCCESS: {
      toast.success(`Successfully chosen for estimated cost`)

      return {
        ...state,
        chooseForEstimatedCost_success: true,
      }
    }

    case CHOOSE_FOR_ESTIMATED_COST_FAILURE: {
      return {
        ...state,
        chooseForEstimatedCost_failure: true,
      }
    }

    case CHOOSE_FOR_ESTIMATED_COST__CLEAR: {
      return {
        ...state,
        chooseForEstimatedCost_loading: false,
        chooseForEstimatedCost_success: false,
        chooseForEstimatedCost_failure: false,
      }
    }

    case IS_CHOSEN_FOR_ESTIMATED_COST: {
      return {
        ...state,
        isChosenForEstimatedCost_data: false,
        isChosenForEstimatedCost_loading: true,
      }
    }

    case IS_CHOSEN_FOR_ESTIMATED_COST_SUCCESS: {
      return {
        ...state,
        isChosenForEstimatedCost_success: true,
        isChosenForEstimatedCost_data: action.payload,
      }
    }

    case IS_CHOSEN_FOR_ESTIMATED_COST_FAILURE: {
      return {
        ...state,
        isChosenForEstimatedCost_failure: true,
      }
    }

    case IS_CHOSEN_FOR_ESTIMATED_COST__CLEAR: {
      return {
        ...state,
        isChosenForEstimatedCost_loading: false,
        isChosenForEstimatedCost_success: false,
        isChosenForEstimatedCost_failure: false,
      }
    }

    case GET_SERVICE_PROFILE_FOR_ESTIMATED_COST: {
      return {
        ...state,
        getServiceProfileForEstimatedCost_loading: true,
      }
    }

    case GET_SERVICE_PROFILE_FOR_ESTIMATED_COST_SUCCESS: {
      return {
        ...state,
        getServiceProfileForEstimatedCost_success: true,
        getServiceProfileForEstimatedCost_data: action.payload,
      }
    }

    case GET_SERVICE_PROFILE_FOR_ESTIMATED_COST_FAILURE: {
      return {
        ...state,
        getServiceProfileForEstimatedCost_failure: true,
      }
    }

    case GET_SERVICE_PROFILE_FOR_ESTIMATED_COST__CLEAR: {
      return {
        ...state,
        getServiceProfileForEstimatedCost_loading: false,
        getServiceProfileForEstimatedCost_success: false,
        getServiceProfileForEstimatedCost_failure: false,
      }
    }

    default:
      return state
  }
}
