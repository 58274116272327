import { gql } from 'apollo-boost'
import {
  AdminUser,
  LookUp,
  UserPaymentInformation,
  RelatedItem,
  Role,
  DocumentVerification,
  AddressType,
  RatingReview,
  ImageType,
  SiteConfigurationsType,
  BoatCardResponse,
  YachtCardResponse,
  MarinaCardResponse,
  YachtListing,
  SuccessResponse,
  BoatShowListing,
  Media,
  PaymentMethodTypes,
  TransactionDetail,
  BoatRent,
} from './schemaTypes/schemaTypes'
import { Branch } from './branchSchema'

export const LoginUserResponse = `{
      id
      firstName
      lastName
      shortDescription
      email
      mobileNumber
      address ${AddressType}
      title
      language
      token
      isVerified
      documentVerification ${DocumentVerification}
      companyName
      commercialLicence ${ImageType}
      governmentId ${ImageType}
      reviews ${RatingReview}
      provider ${LookUp}
      companyLogo ${ImageType}
      role ${Role}
      companyWebsite
      accountActivated
      image ${ImageType}
      createdAt
    }`

export const registerUser = gql`
  mutation createUser($input: UserCreateInput!) {
    createUser(user: $input) {
      user ${LoginUserResponse}
      agreement {
        id
        country
        firstName
        lastName
        companyWebsite
        mobileNumber
        companyName
        email
        role ${Role}
        agreementId
      }
    }
  }
`

export const loginUser = gql`
  mutation loginUser($input: LoginType!) {
    loginUser(input: $input) ${LoginUserResponse}
  }
`

export const getStripeProfileLinkSchema = gql`
  query genrateStripeProfileLink {
    genrateStripeProfileLink {
      message
      data
    }
  }
`

export const activateUser = gql`
  mutation activateUser($token: String!, $activationType: Float!) {
    activateUser(token: $token, activationType: $activationType)
      ${AdminUser}
  }
`

export const getAutoLoginDetail = gql`
  query getAutoLoginDetail($autoLoginToken: String!) {
    getAutoLoginDetail(autoLoginToken: $autoLoginToken)
      ${LoginUserResponse}
  }
`

export const getUser = gql`
  query getUser($id: String!) {
    getUser(id: $id) {
      id
      firstName
      lastName
      shortDescription
      email
      mobileNumber
      address ${AddressType}
      title
      language
      token
      isProfilecompleted
      is_stripe_profile_completed
      paypalId
      isVerified
      dealerRequests {
        dealer {
          companyName
          companyLogo ${ImageType}
          image ${ImageType}
          id
          firstName
          lastName
          address ${AddressType}
          email
          mobileNumber
          isVerified
        }
        accepted
        status
      }
      documentVerification ${DocumentVerification}
      companyName
      commercialLicence ${ImageType}
      governmentId ${ImageType}
      reviews ${RatingReview}
      provider ${LookUp}
      companyLogo ${ImageType}
      role ${Role}
      companyWebsite
      agreementId
      accountActivated
      image ${ImageType}
      profession ${LookUp}
      paymentMethodTypes ${PaymentMethodTypes}
      pricePerFt
      subscribers {
        id
      }
      friends {
        id
      }
      createdAt
    }
  }
`

export const updateUser = gql`
  mutation updateProfile($input: ProfileInput!) {
    updateProfile(user: $input) {
      id
      firstName
      lastName
      shortDescription
      email
      mobileNumber
      address ${AddressType}
      title
      companyWebsite
      companyLogo ${ImageType}
      language
      companyName
      companyName
      companyLogo ${ImageType}
      companyWebsite
      accountActivated
      commercialLicence ${ImageType}
      governmentId ${ImageType}
      provider ${LookUp}
      role ${Role}
      token
      isVerified
      documentVerification ${DocumentVerification}
      image ${ImageType}
      profession ${LookUp}
      paymentMethodTypes ${PaymentMethodTypes}
      pricePerFt
      createdAt
    }
  }
`

export const updateStripeProfileCompletedSchema = gql`
  query updateStripeProfileCompleted($user_id: String!) {
    updateStripeProfileCompleted(user_id: $user_id) {
      message
      data
    }
  }
`

export const getAllRoles = gql`
  {
    getAllRoles {
      id
      role
      type
      aliasName
    }
  }
`

export const getAllPageInformationByType = gql`
  query getAllPageInformationByType($input: PageGetInput!) {
    getAllPageInformationByType(input: $input) {
      id
      uiType
      module
      header
      content
    }
  }
`

export const forgotPassword = gql`
  mutation forgotPassword($input: ForgotPasswordInput!) {
    forgotPassword(input: $input) {
      message
      statusCode
      id
    }
  }
`

export const forgotPasswordMail = gql`
  mutation forgotPasswordMail($email: String!) {
    forgotPasswordMail(email: $email) {
      message
      statusCode
      id
    }
  }
`
export const changePassword = gql`
  mutation forgotPassword($oldPassword: String!, $password: String!) {
    forgotPassword(oldPassword: $oldPassword, password: $password) {
      id
    }
  }
`
export const resetPasswordQuery = gql`
  mutation resetPassword($oldPassword: String!, $password: String!) {
    resetPassword(oldPassword: $oldPassword, password: $password) {
      id
    }
  }
`

export const changeUserAccountStatus = gql`
  mutation changeUserStatus($value: Boolean!, $column: String!, $id: String!) {
    changeUserStatus(value: $value, column: $column, id: $id) {
      message
      statusCode
    }
  }
`

export const getUserLocation = gql`
  query getUserLocation {
    getUserLocation
  }
`

export const getDocumentUrl = gql`
  query getDocumentUrl($mediaId: String!) {
    getDocumentUrl(mediaId: $mediaId)
  }
`

export const getUserPaymentInformation = gql`
  {
    getUserPaymentInformation ${UserPaymentInformation}
  }
`

export const addUpdatePaymentInformation = gql`
  mutation addUpdatePaymentInformation($input: UserPaymentInformationInput!) {
    addUpdatePaymentInformation(input: $input)
      ${UserPaymentInformation}
  }
`

export const getTransactionDetailsByUserSchema = gql`
  query getTransactionDetailsByUser($limit: Int, $page: Int, $type: String, $text: String) {
    getTransactionDetailsByUser(limit: $limit, page: $page, type: $type, text: $text)
  }
`

export const createAgreementIdRequest = gql`
  mutation createAgreementIdRequest($input: PendingUserInput!) {
    createAgreementIdRequest(input: $input) {
      id
      country
      firstName
      lastName
      companyWebsite
      mobileNumber
      companyName
      email
      agreementId
    }
  }
`

export const getUserByAgreementId = gql`
query getAgreementDetails($agreementId: String!) {
  getAgreementDetails(agreementId: $agreementId) {
    id
    country
    firstName
    lastName
    companyWebsite
    mobileNumber
    companyName
    email
    role ${Role}
    agreementId
  }
}
`

export const updateUserDetailsByAgreementId = gql`
mutation updateAgreementData($input: PendingUserInput!, $agreementId: String!) {
  updateAgreementData(input: $input, agreementId: $agreementId) {
    id
    country
    firstName
    lastName
    companyWebsite
    mobileNumber
    companyName
    email
    role ${Role}
    agreementId
  }
}
`

export const assignAgreementId = gql`
  mutation agreementIdAssigned($agreementId: String!) {
    agreementIdAssigned(agreementId: $agreementId) {
      id
      country
    }
  }
`

export const getItemsByUserQuery = gql`
  query getItemsByUserId($userId: String!,$page: Int, $limit: Int, $latLng: CoordinateInput) {
    getItemsByUserId(userId: $userId,page:$page,limit:$limit, latLng: $latLng)
    {
      boats ${BoatCardResponse}
      yachts ${YachtListing}
      marinas ${MarinaCardResponse}
      boatShows ${BoatShowListing}
      surveyorBranches ${Branch}
      boatRents ${BoatRent}
      total
      totalBoatShows
    }
  }
`
export const getAllManageSiteConfiguration = gql`
  query getSiteConfigurations {
    getSiteConfigurations{
    boatShowBanner ${ImageType}
    boatMediaBanner ${ImageType}
    boatShowLogo ${ImageType}
    buyAndSellBanner ${ImageType}
    marinaAndStorageLogo ${ImageType}
    marinaBanner ${ImageType}
    rentBanner ${ImageType}
    rentLogo ${ImageType}
    serviceMaintenanceBanner ${ImageType}
    siteFavicon ${ImageType}
    siteLogo ${ImageType}
    siteWelcomeBanner ${ImageType}
    yachtServiceLogo ${ImageType}
    buyAndSellLogo ${ImageType}
    adamseaConnectLogo ${ImageType}
    adamseaShareLogo ${ImageType}
    boatMediaLogo ${ImageType}
    supportNumber
    supportEmail
    copyRightText
    linkedInLink
    googlePlusLink
    instaLink
    facebookLink
    twitterLink
    pinterestLink
    youtubeLink
    address
    siteUrl
    }
  }`

export const getManagePasswordContent = gql`
  {
    getManagePasswordContent {
      id
      title
      content
      buttonText
      otherDescription
      icon ${Media}
    }
  }
  `

export const subscribeUserSchema = gql`
  mutation subscribe($id: String!) {
    subscribe(id: $id) {
      id
      data
    }
  }
`

export const addRemoveFriendSchema = gql`
  mutation addRemoveFriend($friendId: String!) {
    addRemoveFriend(friendId: $friendId) {
      id
      data
    }
  }
`

export const fetchAllChannelSchema = gql`
  query fetchAllChannel($channelType: CHANNEL_TYPE, $searchQuery: String) {
    fetchAllChannel(channelType: $channelType, searchQuery: $searchQuery)
  }
`

export const loginWithPaypalSchema = gql`
  mutation loginWithPaypal($code: String!) {
    loginWithPaypal(code: $code)
  }
`