import gql from 'graphql-tag'
import { RatingReview } from './schemaTypes/schemaTypes'

export const createReview = gql`
  mutation createReview($input: ReviewInput!, $inProfile: Boolean! = false) {
    createReview(input: $input, inProfile: $inProfile) {
      id
      moduleId {
        id
        firstName
        lastName
      }
      rating
      reviews
    }
  }
`

export const getReviewsByModuleId = gql`
  query getReviewsByModuleId($moduleId: String, $userModuleId: String, $subModuleId: String) {
    getReviewsByModuleId(moduleId: $moduleId, userModuleId: $userModuleId, subModuleId: $subModuleId) ${RatingReview}
  }
`

export const addFeedBack = gql`
  mutation createFeedBack($input: FeeBackInput!) {
    createFeedBack(input: $input) {
      id
      isAdamSeaMember
      feedBackAboutSite
      feedBackTopic
      feedSubTopic
      description
    }
  }
`
