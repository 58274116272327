import { Route, Switch } from 'react-router-dom'
import { Routes } from '../lib/consts'
import CtxProvider from '../contexts/provider'
import Layout from '../components/layout'
import Feeds from './feeds'

export default function SMRoutes() {
  return (
    <CtxProvider>
      <Layout>
        <Switch>
          <Route path={Routes.Home} exact component={Feeds} />
        </Switch>
      </Layout>
    </CtxProvider>
  )
}
