import React, { useState } from 'react'
import { FormControlLabel, Radio, RadioGroup, Button } from '@material-ui/core'
import { Formik, Form, ErrorMessage } from 'formik'
import { Link } from 'react-router-dom'
import * as Yup from 'yup'

import SearchComplete from '../map/SearchComplete'
import '../../containers/marinaStorage/marinaStorage.scss'
import CommonBanner from '../mainBanner/commonBanner'
import { NotifyModal } from '../modal/notifyModal'
import { notifyMeEnums } from '../../util/enums/enums'
import { renderSelectOptions, requireMessage } from '../../helpers/string'
import SingleSelect from '../helper/singleSelect'

export const StyledRadio = props => {
  return (
    <Radio
      disableRipple
      color="default"
      checkedIcon={<span className="icon-test  checked-icon" />}
      icon={<span className="icon-test" />}
      {...props}
    />
  )
}

export const MarinaStorageSearchCard = ({ types, services, search, marinaBanner, notifyInput, notifyMe }) => {
  const setPlaceHandler = ({ country, city }, setFieldValue) => {
    setFieldValue('country', country)
    setFieldValue('city', city)
  }

  const [openDialog, setOpenDialog] = useState(false)

  const modalHandler = e => {
    e && e.preventDefault()
    setOpenDialog(!openDialog)
  }

  const notifyMeHandler = (formValues, values) => {
    notifyMe({
      ...formValues,
      ...values,
      type: notifyMeEnums.MARINA,
      serviceProvide: values.serviceProvide ? values.serviceProvide.value : '',
    })

    modalHandler()
  }

  return (
    <Formik
      initialValues={{
        country: '',
        city: '',
        serviceProvide: '',
        provider: types?.length && types[0].id,
      }}
      validationSchema={Yup.object().shape({
        country: Yup.string().required(requireMessage('Location')),
      })}
      enableReinitialize
      onSubmit={values => {
        const providerAlias = types?.length && types.find(item => item.id === values.provider)
        search({
          ...values,
          providerAlias: providerAlias ? providerAlias?.alias : '',
          serviceProviderAlias: values.serviceProvide ? values.serviceProvide.label : '',
          serviceProvide: values.serviceProvide ? values.serviceProvide.value : '',
        })
      }}
      render={({ touched, errors, values, setFieldValue, handleSubmit }) => (
        <Form className="bg-transparent-form marina-home-search-card" onSubmit={e => e.preventDefault()}>
          <div className="bg-blue marina-storage-bg">
            <CommonBanner data={marinaBanner} />
            <div className="w-100 h-100 marina-storage-card-position">
              <div style={{ backgroundColor: 'transparent', border: '0', padding: '14px' }} className="card">
                <RadioGroup
                  name="provider"
                  className="storage-radio-group"
                  style={{ display: 'block' }}
                  value={values.provider}
                  onChange={e => {
                    setFieldValue('provider', e.target.value)
                  }}
                >
                  <ErrorMessage component="div" name={'provider'} className="error-message" />

                  <div className="search w-100">
                    <div style={{ position: 'relative' }}>
                      <SearchComplete
                        style={{ backgroundColor: '#0073', backdropFilter: 'blur(4px)', color: 'white' }}
                        requiredFieldLabel={
                          errors?.country && touched?.country ? (
                            <ErrorMessage component="div" name="country" className="color-white" />
                          ) : (
                            <span style={{ color: 'white' }}>Search country or city</span>
                          )
                        }
                        className="form-control font-14 marina--filter--location border--radius--20 box--shadow--1"
                        name="country"
                        getPlaceName={place => setPlaceHandler(place, setFieldValue)}
                      />
                      <i
                        style={{
                          position: 'absolute',
                          top: '50%',
                          right: 10,
                          transform: 'translateY(-50%)',
                          color: 'white',
                          fontSize: 13,
                        }}
                        className="adam-search-2"
                      ></i>
                    </div>
                  </div>

                  <div className="search w-100 mt-3 mb-0">
                    <SingleSelect
                      styles={{
                        control: (base, state) => ({
                          ...base,
                          borderRadius: '20px',
                          backgroundColor: '#061e9c19',
                          backdropFilter: 'blur(2px)',
                          boxShadow: '#63636333 0px 2px 8px 0px',
                          color: 'white',
                        }),
                        menuList: base => ({
                          ...base,
                          '::-webkit-scrollbar': {
                            width: '9px',
                            height: '0px',
                          },
                          '::-webkit-scrollbar-track': {},
                          '::-webkit-scrollbar-thumb': {
                            background: '#2276d6',
                            borderRadius: '15px',
                          },
                        }),
                      }}
                      name="serviceProvide"
                      placeholder="Service"
                      selectedOption={values.serviceProvide}
                      options={renderSelectOptions(services, 'name', 'id')}
                      onChange={value => {
                        setFieldValue('serviceProvide', value)
                      }}
                    />

                    <ErrorMessage component="div" name="serviceProvide" className="error-message"></ErrorMessage>
                  </div>

                  <div className="notify mt-3 mb-0">
                    <span></span>
                    {/* <Link href="#" className="marina-notify btn-notify-link" onClick={modalHandler}>
                      <u>Notify Me</u>
                    </Link> */}
                    {/* <NotifyModal
                      open={openDialog}
                      value={notifyInput}
                      closeModal={modalHandler}
                      onSubmit={formValues => notifyMeHandler(formValues, values)}
                    /> */}

                    <Button
                      style={{ borderRadius: '20px' }}
                      className="search-button-width box--shadow--1"
                      variant="contained"
                      onClick={handleSubmit}
                    >
                      Search Now
                    </Button>
                  </div>
                </RadioGroup>
              </div>
            </div>
          </div>
        </Form>
      )}
    />
  )
}
