import React, { useState } from 'react'
import Carousel, { Modal, ModalGateway } from 'react-images'
import SaveAltIcon from '@material-ui/icons/SaveAlt'

import { CommonInformationCard } from './CommonInformationCard'
import { CommonSalesEnginTitle } from './CommonSalesEnginTitle'
import { nameFormatter } from '../../helpers/string'
import { formattedDate } from '../../helpers/dateTimeHelper'
import '../../assets/css/component/salesEngine/surveyorReport.scss'
import { redirectRouteHandler } from '../../helpers/routeHelper'
import { CommonModal } from '../modal/commonModal'
import { downloadSurveyImagesZipFile } from '../../util/utilFunctions'
import { Button } from 'react-bootstrap'

export const SurveyorSubmittedReport = props => {
  const { surveyorReport, salesEngine, getDocumentUrl } = props
  const [show, setShow] = useState(false)

  const SurveyorModalHandler = () => setShow(!show)
  return (
    <div className="surveyor--submitted--report--main">
      <CommonSalesEnginTitle
        isReport
        title="Report"
        className="surveyor--submitted--title"
        otherInformation={`<span class="surveyor--submitted--date">
                        Submitted by ${
                          salesEngine?.surveyor?.user &&
                          nameFormatter([salesEngine.surveyor.user.firstName, salesEngine.surveyor.user.lastName])
                        } 
                        on ${salesEngine?.surveySubmitted && formattedDate(salesEngine.surveySubmitted)}
                    </span>`}
      />
      <CommonInformationCard className="surveyor--submitted--report">
        <div className="surveyor--report--50 surveyor--report--last">
          {surveyorReport?.comment && (
            // <span className="download--document video--download--document">Surveyor Comment: {surveyorReport?.comment}</span>
            <>
              <Button
                variant="outline"
                type="submit"
                color="secondary"
                className="btn btn-outline-primary next-sales-engine-btn mr-3 surveyor--comment--btn"
                onClick={SurveyorModalHandler}
              >
                {'Surveyor Comment'}
              </Button>
              <CommonModal
                className="surveyor--comment--modal"
                open={show}
                close={SurveyorModalHandler}
                title="Comment From Surveyor"
              >
                <p className="download--document video--download--document">{surveyorReport?.comment}</p>
              </CommonModal>
            </>
          )}
          {surveyorReport?.images?.length > 0 && (
            <span
              className="download--document image--download--document"
              onClick={() => {
                downloadSurveyImagesZipFile(surveyorReport?.images)
              }}
              download
              target="_blank"
            >
              Report Images ({surveyorReport?.images?.length})
              <span className="download--document--icon">
                <SaveAltIcon />
              </span>
            </span>
          )}
          <span
            className="download--document video--download--document"
            onClick={() => redirectRouteHandler(surveyorReport?.video?.url)}
            download
            target="_blank"
          >
            Report Video{' '}
            <span className="download--document--icon">
              <SaveAltIcon />
            </span>
          </span>
          <span
            className="download--document verification--download--document"
            onClick={() => getDocumentUrl(surveyorReport?.boatVerifications?.id)}
            download
          >
            Boat Verification Document{' '}
            <span className="download--document--icon">
              <SaveAltIcon />
            </span>
          </span>
          <span
            className="download--document survey--download--document"
            onClick={() => getDocumentUrl(surveyorReport?.report?.id)}
            download
          >
            Survey Report{' '}
            <span className="download--document--icon">
              <SaveAltIcon />
            </span>
          </span>
          {surveyorReport?.otherDocument && (
            <span
              className="download--document survey--download--document"
              onClick={() => getDocumentUrl(surveyorReport?.otherDocument?.id)}
              download
            >
              Other Documents{' '}
              <span className="download--document--icon">
                <SaveAltIcon />
              </span>
            </span>
          )}
        </div>
      </CommonInformationCard>
    </div>
  )
}
