import { createContext, useContext, useState } from 'react'

const MutedCtx = createContext()
export default MutedCtx

export const MutedProvider = ({ children }) => {
  const [muted, setMuted] = useState(true)
  return <MutedCtx.Provider value={[muted, setMuted]}>{children}</MutedCtx.Provider>
}

export const useMuted = () => useContext(MutedCtx)
