import React from 'react'
export const CommonSalesEnginTitle = React.memo(({ title, otherInformation, className, isReport }) => {
  const payment = title === 'Boat Payment'

  return (
    // need for title logic part
    // <div className={`${className} common--sales-engin--title ${payment && "payment--with--oval"}`}>
    // <div className={`${className} common--sales-engin--title payment--with--oval`}>
    <div className={`${className} common--sales-engin--title ${title && !isReport && `payment--with--oval`}`}>
      {title}{' '}
      {otherInformation && <span className="title-with-other-info" dangerouslySetInnerHTML={{ __html: otherInformation }} />}
    </div>
  )
})
CommonSalesEnginTitle.defaultProps = {
  className: '',
}
