import React, { useEffect, useState } from 'react'
import '../footer/footer.scss'
import { Grid } from '@material-ui/core'
import Container from '@material-ui/core/Container'
import { ErrorMessage, Form, Formik } from 'formik'
import * as Yup from 'yup'
import { connect } from 'react-redux'
import { Layout } from '../layout/layout'
import SingleSelect from '../helper/singleSelect'
import { renderSelectOptions, requireMessage, trimmedValue } from '../../helpers/string'
import { stringValidation } from '../../helpers/yupHelper'
import { getAllCountries } from '../../redux/actions/jobsAction'
import { ButtonComponent } from '../form/Button'
import { Field } from '../ws/Field'
import { getTypeWiseLookup } from '../../redux/actions'
import { lookupTypes } from '../../util/enums/enums'
import { createSuggestion } from '../../redux/actions/userFaqAction'
import { SuccessNotify } from '../../helpers/notification'

const ContactUsSuggestions = props => {
  const [initValues, setInitValues] = useState({
    country: '',
    fullName: '',
    email: '',
    mobileNumber: '',
    topic: '',
    suggestion: '',
  })
  const { countries, suggestionTypes, history, createSuggestion, currentUser } = props

  useEffect(() => {
    const { getAllCountries, getTypeWiseLookup, currentUser } = props
    Object.keys(currentUser).length > 0 &&
      setInitValues({
        ...initValues,
        fullName: `${currentUser?.firstName} ${currentUser?.lastName}`,
        email: currentUser?.email,
        mobileNumber: currentUser?.mobileNumber,
        country: currentUser?.address && currentUser?.address.length > 0 ? { label: currentUser?.address[0]?.country } : '',
        topic:
          suggestionTypes && suggestionTypes.length > 0
            ? { label: suggestionTypes[0].alias, value: suggestionTypes[0].id }
            : '',
      })
    getAllCountries({ page: 1, limit: 400 })
    getTypeWiseLookup(lookupTypes.SUGGESTION_TOPIC_AREA)
  }, [currentUser])

  return (
    <>
      <Layout>
        <div className="contactFooter-bg contact-us-top-space-header">
          <div className="footer--container--content--section">
            {/* <div className="inner"> */}
            <Container maxWidth="lg" className="contact--container--section">
              <h1 className="r-h1 mt-50 mb-0" style={{ textTransform: 'none' }}>Suggest your opinion</h1>
              <p className="mb-4 pt-4">
                Help us help you better, use this form to send us your suggestions. We'd love to hear from you.
              </p>
              <p style={{ color: 'red' }} className="mb-1">
                *REQUIRED FIELD
              </p>
              <hr className="mt-0" />

              <Container style={{ padding: 0 }}>
                <Formik
                  initialValues={{ ...initValues }}
                  onSubmit={(values, { resetForm }) => {
                    let data = {
                      country: values.country.label,
                      fullName: values.fullName,
                      email: values.email,
                      mobileNumber: values.mobileNumber,
                      topic: values.topic.label,
                      description: values.suggestion,
                    }
                    createSuggestion(data)
                    setTimeout(() => SuccessNotify('Form successfully submitted'), 100)
                    resetForm()
                    history.goBack()
                  }}
                  validationSchema={Yup.object().shape({
                    country: Yup.string().required(requireMessage('Country')),
                    fullName: stringValidation.required(requireMessage('Full Name')),
                    email: Yup.string().required(requireMessage('Email')),
                    suggestion: Yup.string().required(requireMessage('Suggestion')),
                  })}
                  render={({ setFieldValue, values, handleSubmit, errors }) => (
                    <Form className="form-left-side-space">
                      {/* <ErrorFocus /> */}
                      <Grid container className="formvalue-width">
                        <Grid item sm={12} className="bottom-space-contactus-page">
                          <div className="boatShow-field grid-flex-method align-items-center">
                            <label className="footer-form-label">
                              <span className="required">*</span> Country
                            </label>
                            <div className="footer-form-select-width">
                              <SingleSelect
                                selectedOption={values.country}
                                options={renderSelectOptions(countries, 'name', 'id')}
                                placeholder={'Select County'}
                                onChange={select => {
                                  setFieldValue('country', select)
                                }}
                                styles={{
                                  singleValue: (provided, state) => {
                                    const fontSize = '14px'
                                    return { ...provided, fontSize, color: '#495057' }
                                  },
                                  placeholder: (provided, state) => {
                                    const fontSize = '14px'
                                    return { ...provided, fontSize, color: '#495057' }
                                  },
                                }}
                              />
                              <ErrorMessage component="div" name="country" className="error-message" />
                            </div>
                          </div>
                        </Grid>

                        <Grid item sm={12} className=" grid-flex-method  bottom-space-contactus-page align-items-center">
                          <label className="footer-form-label">
                            <span className="required">*</span> Full Name
                          </label>
                          <div style={{ flex: '1' }} className="input-field-space contactus-page-form">
                            <Field
                              name="fullName"
                              value={values.fullName}
                              type="text"
                              className="form-control"
                              onChangeText={e => setFieldValue('fullName', e.target.value)}
                              required
                            />
                            <ErrorMessage
                              className="invalid-feedback ant-typography-danger d-block"
                              name={'fullName'}
                              component="span"
                            />
                          </div>
                        </Grid>

                        <Grid item sm={12} className="grid-flex-method  bottom-space-contactus-page align-items-center">
                          <label className="footer-form-label">
                            <span className="required">*</span> Email Address
                          </label>
                          <div style={{ flex: '1' }} className="input-field-space contactus-page-form">
                            <Field
                              name="email"
                              value={values.email}
                              type="text"
                              className="form-control "
                              onChangeText={e => setFieldValue('email', e.target.value)}
                              required
                            />
                            <ErrorMessage
                              className="invalid-feedback ant-typography-danger d-block"
                              name={'email'}
                              component="span"
                            />
                          </div>
                        </Grid>

                        <Grid item sm={12} className="grid-flex-method  bottom-space-contactus-page align-items-center">
                          <label className="footer-form-label">
                            Mobile Number
                          </label>
                          <div style={{ flex: '1' }} className="input-field-space contactus-page-form">
                            <Field
                              name="mobileNumber"
                              value={values.mobileNumber}
                              type="text"
                              className="form-control "
                              onChangeText={e => setFieldValue('mobileNumber', e.target.value)}
                              required
                            />
                            <ErrorMessage
                              className="invalid-feedback ant-typography-danger d-block"
                              name={'mobileNumber'}
                              component="span"
                            />
                          </div>
                        </Grid>

                        <Grid item sm={12} className="grid-flex-method-topic  bottom-space-contactus-page">
                          <div className="boatShow-field grid-flex-method  align-items-center">
                            <label className="footer-form-label">Topic Area</label>
                            <div className="footer-form-select-width">
                              <SingleSelect
                                selectedOption={values.topic}
                                options={renderSelectOptions(suggestionTypes, 'alias', 'id')}
                                placeholder={'Select Topic'}
                                onChange={select => {
                                  setFieldValue('topic', select)
                                }}
                                styles={{
                                  singleValue: (provided, state) => {
                                    const fontSize = '14px'
                                    return { ...provided, fontSize, color: '#495057' }
                                  },
                                  placeholder: (provided, state) => {
                                    const fontSize = '14px'
                                    return { ...provided, fontSize, color: '#495057' }
                                  },
                                }}
                              />
                              <ErrorMessage component="div" name="topic" className="error-message" />
                            </div>
                          </div>
                        </Grid>

                        <Grid item sm={12} className="grid-flex-method bottom-space-contactus-page ">
                          <label className="footer-form-label">
                            <span className="required">*</span> Your Suggestion
                          </label>
                          <div style={{ flex: '1' }} className="contactus-page-form-suggestion input-field-space">
                            <Field
                              id={'suggestion'}
                              name={'suggestion'}
                              className="contactus-page-form "
                              type="textarea"
                              rows={4}
                              value={values.suggestion}
                              onChangeText={e => {
                                setFieldValue('suggestion', e.target.value)
                              }}
                              onBlur={e => setFieldValue('suggestion', trimmedValue(e.target.value))}
                              required
                            />
                            <ErrorMessage
                              className="invalid-feedback ant-typography-danger d-block"
                              name={'suggestion'}
                              component="span"
                            />
                          </div>
                        </Grid>

                        <Grid item sm={12} className=" bottom-space-contactus-page apply-btn-mt">
                          <div className="d-flex" style={{ float: 'right' }}>
                            <ButtonComponent
                              className=" h-auto  submit-btn-contactus btn--padding btn--font btn-outline-info"
                              onClick={handleSubmit}
                            >
                              {'Submit'}
                            </ButtonComponent>
                            <ButtonComponent
                              className=" h-auto as--success--outline btn--padding btn--font"
                              onClick={() => history.goBack()}
                            >
                              {'Cancel'}
                            </ButtonComponent>
                          </div>
                        </Grid>
                      </Grid>
                    </Form>
                  )}
                  enableReinitialize={true}
                />
              </Container>

              <div className="mt-2">
                <p>
                  AdamSea filters emails for security reasons.By clicking on " Submit ", you consent to this action in
                  accordance with our Terms of Use & Privacy Policy.
                </p>
              </div>
            </Container>
          </div>
        </div>
      </Layout>
    </>
  )
}

const mapStateToProps = state => ({
  countries: state.jobsReducer?.countries,
  suggestionTypes: state.loginReducer?.suggestionTypes,
  currentUser: state.loginReducer?.currentUser,
})

const mapDispatchToProps = dispatch => ({
  getAllCountries: data => dispatch(getAllCountries(data)),
  getTypeWiseLookup: data => dispatch(getTypeWiseLookup(data)),
  createSuggestion: data => dispatch(createSuggestion(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ContactUsSuggestions)
