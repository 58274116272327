import { createContext, useContext, useState } from 'react'

const LoginModalCtx = createContext()
export default LoginModalCtx

export const LoginModalProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false)

  const show = () => setIsOpen(true)
  const hide = () => setIsOpen(false)

  return (
    <LoginModalCtx.Provider
      value={{
        isOpen,
        show,
        hide,
      }}
    >
      {children}
    </LoginModalCtx.Provider>
  )
}

export const useLoginModal = () => useContext(LoginModalCtx)
