import { Link } from 'react-router-dom'
import cn from 'classnames'
import * as Icons from '../../assets/icons'
import { Routes } from '../../lib/consts'
import { useAuth } from '../../contexts/auth-ctx'
import { useLoginModal } from '../../contexts/login-modal-ctx'
import { useChatModal } from '../../contexts/chat-modal-ctx'
import { useCreatePostModal } from '../../contexts/create-post-modal-ctx'
import styles from './sidebar.module.scss'

export default function Sidebar({ className }) {
  const auth = useAuth()
  const loginModal = useLoginModal()
  const chatModal = useChatModal()
  const createPostModal = useCreatePostModal()

  return (
    <aside className={cn(styles.sidebar, className)}>
      <div className={styles.logoWrp}>
        <img src={require('../../assets/logo.jpeg')} alt="SM Logo" className={styles.logo} />
      </div>

      <nav className={styles.nav}>
        <ul className={styles.navList}>
          <li className={styles.navItem}>
            <Link to={Routes.Home} className={styles.navLink}>
              <span className={styles.navLinkIconWrp}>
                <Icons.Home className={styles.navLinkIcon} />
              </span>{' '}
              Home
            </Link>
          </li>

          <li className={styles.navItem}>
            <Link to={Routes.Search} className={styles.navLink}>
              <span className={styles.navLinkIconWrp}>
                <Icons.Search className={styles.navLinkIcon} />
              </span>{' '}
              Search
            </Link>
          </li>

          <li className={styles.navItem}>
            {auth.isLoggedIn ? (
              <Link to={Routes.Saved} className={styles.navLink}>
                <span className={styles.navLinkIconWrp}>
                  <Icons.Heart className={styles.navLinkIcon} />
                </span>{' '}
                Saved
              </Link>
            ) : (
              <button className={styles.navLink} onClick={loginModal.show}>
                <span className={styles.navLinkIconWrp}>
                  <Icons.Heart className={styles.navLinkIcon} />
                </span>{' '}
                Saved
              </button>
            )}
          </li>

          <li className={styles.navItem}>
            <button className={styles.navLink} onClick={auth.isLoggedIn ? chatModal.show : loginModal.show}>
              <span className={styles.navLinkIconWrp}>
                <Icons.Message className={styles.navLinkIcon} />
              </span>{' '}
              Messages
            </button>
          </li>

          <li className={styles.navItem}>
            <Link to={Routes.Notifications} className={styles.navLink}>
              <span className={styles.navLinkIconWrp}>
                <Icons.Notification className={styles.navLinkIcon} />
              </span>{' '}
              Notifications
            </Link>
          </li>

          <li className={styles.navItem}>
            <Link to={Routes.Market} className={styles.navLink}>
              <span className={styles.navLinkIconWrp}>
                <Icons.Shop className={styles.navLinkIcon} />
              </span>{' '}
              <strong style={{ fontWeight: 700 }}>AdamSea</strong>
            </Link>
          </li>

          <li className={styles.navItem}>
            <button className={styles.navLink} onClick={auth.isLoggedIn ? createPostModal.show : loginModal.show}>
              <span className={styles.navLinkIconWrp}>
                <Icons.Plus className={styles.navLinkIcon} />
              </span>{' '}
              Create Post
            </button>
          </li>

          {!auth.isLoggedIn ? (
            <li className={styles.navItem}>
              <button className={styles.navLink} onClick={loginModal.show}>
                <span className={styles.navLinkIconWrp}>
                  <Icons.User className={styles.navLinkIcon} />
                </span>{' '}
                Login
              </button>
            </li>
          ) : (
            <li className={styles.navItem}>
              <Link to={Routes.User(auth.user._id)} className={styles.navLink}>
                <span className={styles.navLinkIconWrp}>
                  <img
                    src={auth.user.image?.url || require('../../assets/avatar-sample.png')}
                    alt={auth.user.firstName}
                    className={styles.navLinkAvatar}
                  />
                </span>{' '}
                Profile
              </Link>
            </li>
          )}
        </ul>
      </nav>
    </aside>
  )
}
