import React, { useEffect, useState } from 'react'
import { Container, Grid } from '@material-ui/core'
import { connect } from 'react-redux'
import { Tab, Tabs } from 'react-bootstrap'

import {
  Layout,
  searchArticleData,
  searchCommonData,
  searchHeaderData,
  searchNewData,
  searchUsedData,
  searchUser,
  Loader,
  PaginationBar,
} from '../../components'
import SearchComponent from '../../components/search/searchComponent'
import SearchUserCard from '../../components/search/searchUserCard'
import { globalSearch } from '../../redux/actions'
import { nameFormatter } from '../../helpers/string'
import {
  viewArticles,
  viewBoatHandler,
  viewMarinaDetails,
  viewRentBoatHandler,
  viewServiceBoatHandler,
} from '../../helpers/boatHelper'
import { defaultImage, noResultFound, pagination, searchModuleType, searchTab } from '../../util/enums/enums'
import { redirectToUserProfile } from '../../helpers/jsxHelper'

import './globalSearch.scss'
import { getSingleBoatApi } from '../../redux/sagas/boatSaga'
import { getSingleMarinaApi } from '../../redux/sagas/marinaAndStorageSaga'
import { editYachtServiceApi } from '../../redux/sagas/boatServiceSaga'
import { getUserByIdApi } from '../../redux/sagas/loginSaga'

const GlobalSearch = props => {
  const {
    recentSearchResults,
    globalSearchData,
    location,
    globalSearch,
    history,
    searchSuccess,
    searchTermData,
    totalBoatsData,
    totalNewBoatsData,
    totalArticleData,
    totalBoatShowsData,
    totalMarinaStorageData,
    totalUsedBoatsData,
    totalUsersData,
    totalYachtServiceData,
    totalRentBoatData,
  } = props

  const [isResult, setIsResult] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [tabName, setTabName] = useState(searchTab.All)

  // useEffect(() => {
  //   if (searchSuccess) {
  //     let pageConst = ''
  //     const urlParams = new URLSearchParams(location.search)

  //     if (urlParams.has('page')) {
  //       const pageIsThere = urlParams.get('page')
  //       pageConst = `&page=${pageIsThere}`
  //     }
  //     history.push(`/search?searchTerm=${searchTermData}${pageConst}`)
  //   }
  // }, [searchSuccess])

  useEffect(() => {
    if (location && location.search) {
      const urlParams = new URLSearchParams(location.search)

      if (urlParams.has('searchTerm')) {
        const searchTerm = urlParams.get('searchTerm')
        setSearchTerm(searchTerm)
        globalSearch({ query: searchTerm, page: pagination.PAGE_COUNT, limit: pagination.GLOBAL_SEARCH_LIMIT })
      }
    }
  }, [])

  useEffect(() => {
    if (globalSearchData) {
      const { boats, marinaStorage, rentBoats, yachtService, boatShows } = globalSearchData

      if (
        (boats && boats.length) ||
        (marinaStorage && marinaStorage.length) ||
        (rentBoats && rentBoats.length) ||
        (yachtService && yachtService.length) ||
        (boatShows && boatShows.length) ||
        (rentBoats && rentBoats.length)
      ) {
        setIsResult(true)
      } else if (recentSearchResults) {
        setIsResult(false)
      }
    }
  }, [globalSearchData, recentSearchResults])

  const renderUserSection = () => {
    return (
      <>
        {globalSearchData?.users?.items?.length > 0 && (
          <>
            <div className="search--main--title search--article">
              <p className="search--main--title--name">Users</p>
            </div>
            <div className="d-flex flex-wrap">
              {globalSearchData?.users?.items.map((item, index) => {
                if (index < 10) {
                  return <SearchUserCard src={item?.image} name={nameFormatter([item?.name])} userId={item.id} />
                }
              })}
            </div>
            {globalSearchData?.users?.items.length > 10 && (
              <div
                class="see-all"
                onClick={() => {
                  setTabName(searchTab.Users)
                }}
              >
                See All
              </div>
            )}
          </>
        )}
      </>
    )
  }

  const renderArticleSection = () => {
    return (
      <>
        {globalSearchData?.articles?.items?.length > 0 && (
          <div className="search--article">
            <div className="search--main--title">
              <p className="search--main--title--name">Articles</p>
            </div>
            <div className="search--article--sec">
              {globalSearchData?.articles?.items.map((item, index) => {
                if (index < 10) {
                  return (
                    <div className="search--article--sec--data">
                      <SearchComponent
                        src={item?.image}
                        name={item?.name}
                        module={searchModuleType.ARTICLES}
                        searchDataDescription={item?.description}
                        userName={item?.user?.user_name}
                        onClick={() => viewArticles(item.id, item.name)}
                      />
                    </div>
                  )
                }
              })}
            </div>
            {globalSearchData?.articles?.items.length > 10 && (
              <div
                class="see-all"
                onClick={() => {
                  setTabName(searchTab.Articles)
                }}
              >
                See All
              </div>
            )}
          </div>
        )}
      </>
    )
  }

  const renderNoResultSection = () => {
    return (
      <>
        {tabName !== searchTab.All && (
          <div className="global--search">
            <div className="global--search--total--result">
              <p className="global--search--total--result--info">{noResultFound}</p>
            </div>
          </div>
        )}
      </>
    )
  }
  //count total number of documents
  const totalSearchDataCount = () => {
    return (
      globalSearchData?.articles?.total +
      globalSearchData?.boatShows?.total +
      globalSearchData?.boats?.total +
      globalSearchData?.marinaStorage.total +
      globalSearchData?.newBoats.total +
      globalSearchData?.usedBoats.total +
      globalSearchData?.users.total +
      globalSearchData?.yachtService.total +
      globalSearchData?.rentBoats.total
    )
  }
  const renderCommonBoatSection = () => {
    return (
      <>
        {globalSearchData?.articles?.total ||
        globalSearchData?.boatShows?.total ||
        globalSearchData?.boats?.total ||
        globalSearchData?.marinaStorage.total ||
        globalSearchData?.newBoats.total ||
        globalSearchData?.usedBoats.total ||
        globalSearchData?.users.total ||
        globalSearchData?.yachtService.total ||
        globalSearchData?.rentBoats.total > 0 ? (
          <div className="global--search">
            <div className="global--search--total--result">
              <p className="global--search--total--result--info">About ({totalSearchDataCount()}) Search results</p>
            </div>
          </div>
        ) : (
          <div className="global--search">
            <div className="global--search--total--result">
              <p className="global--search--total--result--info">{noResultFound}</p>
            </div>
          </div>
        )}
        {globalSearchData?.boats?.items.length > 0 &&
          globalSearchData?.boats?.items.map(item => {
            return (
              <SearchComponent
                src={item?.image}
                name={item?.name}
                subtitle={item?.subtitle}
                marketType={item?.boatStatus}
                module={searchModuleType.BOATS}
                searchDataDescription={item?.description}
                role={item?.role}
                userId={item?.id}
                boatName={item?.name}
                history={history}
                topUserFirstName={item?.user_name}
                onClick={() => {
                  getSingleBoatApi({ id: item.id, basic: true })
                    .then(res => {
                      viewBoatHandler(res.data.editBoat)
                    })
                    .catch(err => {})
                }}
                userOnClick={() => {
                  getUserByIdApi(item.user_id)
                    .then(res => {
                      redirectToUserProfile(res.data.getUser, history, 'blank')
                    })
                    .catch(err => {})
                }}
              />
            )
          })}
      </>
    )
  }
  const renderUsedBoatSection = () => {
    return (
      <>
        {totalUsedBoatsData && tabName !== searchTab.All ? (
          <div className="global--search">
            <div className="global--search--total--result">
              <p className="global--search--total--result--info">About ({totalUsedBoatsData}) Used Boats results</p>
            </div>
          </div>
        ) : (
          tabName !== searchTab.All && renderNoResultSection()
        )}
        {totalUsedBoatsData > 0 && (
          <div className="global--search--data global--search--new--data">
            {globalSearchData?.usedBoats?.items.map(item => {
              return (
                <SearchComponent
                  src={item?.image}
                  marketType={item?.boatStatus}
                  name={item?.name}
                  subtitle={item?.subtitle}
                  topUserFirstName={item?.user_name}
                  searchDataDescription={item?.description}
                  onClick={() => {
                    getSingleBoatApi({ id: item.id, basic: true })
                      .then(res => {
                        viewBoatHandler(res.data.editBoat)
                      })
                      .catch(err => {})
                  }}
                  userOnClick={() => {
                    getUserByIdApi(item.user_id)
                      .then(res => {
                        redirectToUserProfile(res.data.getUser, history, 'blank')
                      })
                      .catch(err => {})
                  }}
                />
              )
            })}
          </div>
        )}
      </>
    )
  }
  const renderNewBoatSection = () => {
    return (
      <>
        {totalNewBoatsData && tabName !== searchTab.All ? (
          <div className="global--search">
            <div className="global--search--total--result">
              <p className="global--search--total--result--info">About ({totalNewBoatsData}) New Boats results</p>
            </div>
          </div>
        ) : (
          tabName !== searchTab.All && renderNoResultSection()
        )}
        {totalNewBoatsData > 0 && (
          <div className="global--search--data global--search--new--data">
            {globalSearchData?.newBoats?.items.map(item => {
              return (
                <SearchComponent
                  src={item?.image}
                  name={item?.name}
                  subtitle={item?.subtitle}
                  marketType={item?.boatStatus}
                  searchDataDescription={item?.description}
                  topUserFirstName={item?.user_name}
                  onClick={() => {
                    getSingleBoatApi({ id: item.id, basic: true })
                      .then(res => {
                        viewBoatHandler(res.data.editBoat)
                      })
                      .catch(err => {})
                  }}
                  userOnClick={() => {
                    getUserByIdApi(item.user_id)
                      .then(res => {
                        redirectToUserProfile(res.data.getUser, history, 'blank')
                      })
                      .catch(err => {})
                  }}
                />
              )
            })}
          </div>
        )}
      </>
    )
  }
  const renderMarinaBoatSection = () => {
    return (
      <>
        {totalMarinaStorageData && tabName !== searchTab.All ? (
          <div className="global--search">
            <div className="global--search--total--result">
              <p className="global--search--total--result--info">About ({totalMarinaStorageData}) Marina and Storage results</p>
            </div>
          </div>
        ) : (
          tabName !== searchTab.All && renderNoResultSection()
        )}
        {totalMarinaStorageData > 0 && (
          <div className="global--search--data global--search--new--data">
            {globalSearchData?.marinaStorage?.items.map(item => {
              return (
                <SearchComponent
                  src={item?.image}
                  name={item?.name}
                  marketType={item?.provider}
                  topUserFirstName={item?.user_name}
                  searchDataDescription={item?.description}
                  onClick={() => {
                    getSingleMarinaApi(item.id, true)
                      .then(res => {
                        viewMarinaDetails(res.data.editMarina)
                      })
                      .catch(err => {})
                  }}
                  userOnClick={() => {
                    getUserByIdApi(item.user_id)
                      .then(res => {
                        redirectToUserProfile(res.data.getUser, history, 'blank')
                      })
                      .catch(err => {})
                  }}
                />
              )
            })}
          </div>
        )}
      </>
    )
  }
  const renderRentBoatSection = () => {
    return (
      <>
        {totalRentBoatData && tabName !== searchTab.All ? (
          <div className="global--search">
            <div className="global--search--total--result">
              <p className="global--search--total--result--info">About ({totalRentBoatData}) Rent results</p>
            </div>
          </div>
        ) : (
          tabName !== searchTab.All && renderNoResultSection()
        )}
        {totalRentBoatData > 0 && (
          <div className="global--search--data global--search--new--data">
            {globalSearchData?.rentBoats?.items.map(item => {
              return (
                <SearchComponent
                  src={item?.image}
                  name={item?.model}
                  subtitle={item?.subtitle}
                  marketType={'Rent'}
                  topUserFirstName={item?.user_name}
                  searchDataDescription={item?.description}
                  onClick={() => viewRentBoatHandler(item)}
                  userOnClick={() => {
                    getUserByIdApi(item.user_id)
                      .then(res => {
                        redirectToUserProfile(res.data.getUser, history, 'blank')
                      })
                      .catch(err => {})
                  }}
                />
              )
            })}
          </div>
        )}
      </>
    )
  }
  const renderBoatShowSection = () => {
    return (
      <>
        {totalBoatShowsData && tabName !== searchTab.All ? (
          <div className="global--search">
            <div className="global--search--total--result">
              <p className="global--search--total--result--info">About ({totalBoatShowsData}) Boat Shows results</p>
            </div>
          </div>
        ) : (
          tabName !== searchTab.All && renderNoResultSection()
        )}
        {totalBoatShowsData > 0 && (
          <div className="global--search--data global--search--new--data">
            {globalSearchData?.boatShows?.items.map(item => {
              return (
                <SearchComponent
                  src={item?.image}
                  name={item?.name}
                  marketType={'Boat Show'}
                  topUserFirstName={item?.user_name}
                  searchDataDescription={item?.description}
                  userOnClick={() => {
                    getUserByIdApi(item.user_id)
                      .then(res => {
                        redirectToUserProfile(res.data.getUser, history, 'blank')
                      })
                      .catch(err => {})
                  }}
                />
              )
            })}
          </div>
        )}
      </>
    )
  }
  const renderServiceBoatSection = () => {
    return (
      <>
        {totalYachtServiceData && tabName !== searchTab.All ? (
          <div className="global--search">
            <div className="global--search--total--result">
              <p className="global--search--total--result--info">About ({totalYachtServiceData}) Yatch Service results</p>
            </div>
          </div>
        ) : (
          tabName !== searchTab.All && renderNoResultSection()
        )}
        {totalYachtServiceData > 0 && (
          <div className="global--search--data global--search--new--data">
            {globalSearchData?.yachtService?.items.map(item => {
              return (
                <SearchComponent
                  src={item?.image}
                  name={item?.name}
                  marketType={'Service'}
                  searchDataDescription={item?.description}
                  topUserFirstName={item?.user_name}
                  onClick={() => {
                    editYachtServiceApi(item.id, true)
                      .then(res => {
                        viewServiceBoatHandler(res.data.EditYachtService)
                      })
                      .catch(err => {})
                  }}
                  userOnClick={() => {
                    getUserByIdApi(item.user_id)
                      .then(res => {
                        redirectToUserProfile(res.data.getUser, history, 'blank')
                      })
                      .catch(err => {})
                  }}
                />
              )
            })}
          </div>
        )}
      </>
    )
  }
  const renderBoatArticleSection = () => {
    return (
      <>
        {totalArticleData && tabName !== searchTab.All ? (
          <div className="global--search">
            <div className="global--search--total--result">
              <p className="global--search--total--result--info">About ({totalArticleData}) Aticles results</p>
            </div>
          </div>
        ) : (
          tabName !== searchTab.All && renderNoResultSection()
        )}
        {totalArticleData > 0 && (
          <div className="global--search--data global--search--new--data">
            {globalSearchData?.articles?.items.map(item => {
              return (
                <SearchComponent
                  src={item?.image}
                  name={item?.name}
                  topUserFirstName={item?.user_name}
                  searchDataDescription={item?.description}
                  onClick={() => viewArticles(item.id, item.name)}
                  userOnClick={() => {
                    getUserByIdApi(item.user_id)
                      .then(res => {
                        redirectToUserProfile(res.data.getUser, history, 'blank')
                      })
                      .catch(err => {})
                  }}
                />
              )
            })}
          </div>
        )}
      </>
    )
  }

  const renderBoatUserSection = () => {
    return (
      <>
        {totalUsersData && tabName !== searchTab.All ? (
          <div className="global--search">
            <div className="global--search--total--result">
              <p className="global--search--total--result--info">About ({totalUsersData}) Users results</p>
            </div>
          </div>
        ) : (
          tabName !== searchTab.All && renderNoResultSection()
        )}
        {totalUsersData > 0 && (
          <div className="global--search--data global--search--new--data global--search--user--boat--section">
            {globalSearchData?.users?.items.map(item => {
              const user = item
              return (
                <SearchComponent
                  src={item?.image || defaultImage}
                  topUserFirstName={item?.name}
                  searchDataDescription={item?.description}
                  userRole={item?.role}
                  onClick={() => {
                    getUserByIdApi(item.id)
                      .then(res => {
                        redirectToUserProfile(res.data.getUser, history, 'blank')
                      })
                      .catch(err => {})
                  }}
                  userOnClick={() => {
                    getUserByIdApi(item.id)
                      .then(res => {
                        redirectToUserProfile(res.data.getUser, history, 'blank')
                      })
                      .catch(err => {})
                  }}
                />
              )
            })}
          </div>
        )}
      </>
    )
  }

  const renderPagination = totalRecords => {
    return (
      <div className="global--search--pagination">
        <PaginationBar action={globalSearch} value={{ query: searchTermData }} totalRecords={totalRecords} />
      </div>
    )
  }

  return (
    <Layout className="global--search--layout">
      {globalSearchData?.boats ? (
        <div className="global--search--main--container global--search--main--section">
          <Tabs
            onSelect={e => setTabName(e)}
            defaultActiveKey={searchTab.All}
            id="uncontrolled-tab-example"
            className="global--search--tabs--section d-flex"
            activeKey={tabName}
          >
            <Tab
              eventKey={searchTab.All}
              title={
                <div className="global--search--header">
                  <div className="global--search--main--container">
                    <div className="d-flex justify-content-between global--search--header--section align-items-center">
                      <div className="d-flex align-items-center">
                        <div className="d-flex align-items-center global--search--header--section--div">
                          <div className="global--search--header--section--icon">
                            <img
                              src={require('../../assets/images/search/all-search.png')}
                              className="global--search--header--section--icon--img"
                              lat='Search'
                            />
                          </div>
                          <div className="global--search--header--section--text">
                            <p className="global--search--header--section--text--info mb-0">{searchTab.All}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              }
            >
              <div className="global--search">
                <Grid container>
                  <Grid item md={8} style={{ minWidth: 0 }}>
                    <div className="global--search--data">
                      {renderCommonBoatSection()}
                      {renderNewBoatSection()}
                      {renderUsedBoatSection()}
                      {renderRentBoatSection()}
                      {renderServiceBoatSection()}
                      {renderMarinaBoatSection()}
                      {renderBoatShowSection()}
                    </div>
                  </Grid>

                  <Grid item md={4} style={{ minWidth: 0 }}>
                    {renderUserSection()}
                    {renderArticleSection()}
                  </Grid>
                  {totalBoatsData > pagination.GLOBAL_SEARCH_LIMIT && totalBoatsData > 0 && renderPagination(totalBoatsData)}
                </Grid>
              </div>
            </Tab>
            <Tab
              eventKey={searchTab.New}
              title={
                <div className="global--search--header">
                  <div className="global--search--main--container">
                    <div className="d-flex justify-content-between global--search--header--section align-items-center">
                      <div className="d-flex align-items-center">
                        <div className="d-flex align-items-center global--search--header--section--div">
                          <div className="global--search--header--section--icon">
                            <img
                              src={require('../../assets/images/search/new-search.png')}
                              className="global--search--header--section--icon--img"
                              alt='Search'
                            />
                          </div>
                          <div className="global--search--header--section--text">
                            <p className="global--search--header--section--text--info mb-0">{searchTab.New}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              }
            >
              <div className="global--search">
                <Grid container className="width-100">
                  <Grid item sm={12}>
                    <div className="global--search--data">{renderNewBoatSection()}</div>
                  </Grid>

                  {/* <Grid item sm={4}>
                    {renderUserSection()}
                    {renderArticleSection()}
                  </Grid> */}
                  {totalNewBoatsData > pagination.GLOBAL_SEARCH_LIMIT &&
                    totalNewBoatsData > 0 &&
                    renderPagination(totalNewBoatsData)}
                </Grid>
              </div>
            </Tab>
            <Tab
              eventKey={searchTab.Used}
              title={
                <div className="global--search--header">
                  <div className="global--search--main--container">
                    <div className="d-flex justify-content-between global--search--header--section align-items-center">
                      <div className="d-flex align-items-center">
                        <div className="d-flex align-items-center global--search--header--section--div">
                          <div className="global--search--header--section--icon">
                            <img
                              src={require('../../assets/images/search/used-search.png')}
                              className="global--search--header--section--icon--img"
                              alt='Used'
                            />
                          </div>
                          <div className="global--search--header--section--text">
                            <p className="global--search--header--section--text--info mb-0">{searchTab.Used}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              }
            >
              <div className="global--search">
                <Grid container className="width-100">
                  <Grid item sm={12}>
                    <div className="global--search--data">{renderUsedBoatSection()}</div>
                  </Grid>

                  {/* <Grid item sm={4}>
                    {renderUserSection()}
                    {renderArticleSection()}
                  </Grid> */}
                  {totalUsedBoatsData > pagination.GLOBAL_SEARCH_LIMIT &&
                    totalUsedBoatsData > 0 &&
                    renderPagination(totalUsedBoatsData)}
                </Grid>
              </div>
            </Tab>
            <Tab
              eventKey={searchTab.CharterAndRent}
              title={
                <div className="global--search--header">
                  <div className="global--search--main--container">
                    <div className="d-flex justify-content-between global--search--header--section align-items-center">
                      <div className="d-flex align-items-center">
                        <div className="d-flex align-items-center global--search--header--section--div">
                          <div className="global--search--header--section--icon">
                            <img
                              src={require('../../assets/images/search/rent-search.png')}
                              className="global--search--header--section--icon--img"
                              alt='Rent'
                            />
                          </div>
                          <div className="global--search--header--section--text">
                            <p className="global--search--header--section--text--info mb-0">{searchTab.CharterAndRent}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              }
            >
              <div className="global--search">
                <Grid container className="width-100">
                  <Grid item sm={12}>
                    <div className="global--search--data">{renderRentBoatSection()}</div>
                  </Grid>

                  {/* <Grid item sm={4}>
                    {renderUserSection()}
                    {renderArticleSection()}
                  </Grid> */}
                  {totalBoatsData > pagination.GLOBAL_SEARCH_LIMIT && totalBoatsData > 0 && renderPagination(totalBoatsData)}
                </Grid>
              </div>
            </Tab>
            <Tab
              eventKey={searchTab.YachtService}
              title={
                <div className="global--search--header">
                  <div className="global--search--main--container">
                    <div className="d-flex justify-content-between global--search--header--section align-items-center">
                      <div className="d-flex align-items-center">
                        <div className="d-flex align-items-center global--search--header--section--div">
                          <div className="global--search--header--section--icon">
                            <img
                              src={require('../../assets/images/search/service-search.png')}
                              className="global--search--header--section--icon--img"
                              alt='Service'
                            />
                          </div>
                          <div className="global--search--header--section--text">
                            <p className="global--search--header--section--text--info mb-0">{searchTab.YachtService}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              }
            >
              <div className="global--search">
                <Grid container className="width-100">
                  <Grid item sm={12}>
                    <div className="global--search--data">{renderServiceBoatSection()}</div>
                  </Grid>

                  {/* <Grid item sm={4}>
                    {renderUserSection()}
                    {renderArticleSection()}
                  </Grid> */}
                  {totalYachtServiceData > pagination.GLOBAL_SEARCH_LIMIT &&
                    totalYachtServiceData > 0 &&
                    renderPagination(totalYachtServiceData)}
                </Grid>
              </div>
            </Tab>
            <Tab
              eventKey={searchTab.MarinaAndStorage}
              title={
                <div className="global--search--header">
                  <div className="global--search--main--container">
                    <div className="d-flex justify-content-between global--search--header--section align-items-center">
                      <div className="d-flex align-items-center">
                        <div className="d-flex align-items-center global--search--header--section--div">
                          <div className="global--search--header--section--icon">
                            <img
                              src={require('../../assets/images/search/marina-search.png')}
                              className="global--search--header--section--icon--img"
                              alt='Marina'
                            />
                          </div>
                          <div className="global--search--header--section--text">
                            <p className="global--search--header--section--text--info mb-0">{searchTab.MarinaAndStorage}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              }
            >
              <div className="global--search">
                <Grid container className="width-100">
                  <Grid item sm={12}>
                    <div className="global--search--data">{renderMarinaBoatSection()}</div>
                  </Grid>

                  {/* <Grid item sm={4}>
                    {renderUserSection()}
                    {renderArticleSection()}
                  </Grid> */}
                  {totalMarinaStorageData > pagination.GLOBAL_SEARCH_LIMIT &&
                    totalMarinaStorageData > 0 &&
                    renderPagination(totalMarinaStorageData)}
                </Grid>
              </div>
            </Tab>
            <Tab
              eventKey={searchTab.BoatShow}
              title={
                <div className="global--search--header">
                  <div className="global--search--main--container">
                    <div className="d-flex justify-content-between global--search--header--section align-items-center">
                      <div className="d-flex align-items-center">
                        <div className="d-flex align-items-center global--search--header--section--div">
                          <div className="global--search--header--section--icon">
                            <img
                              src={require('../../assets/images/search/boat-show.svg')}
                              className="global--search--header--section--icon--img"
                              alt='Boat show'
                            />
                          </div>
                          <div className="global--search--header--section--text">
                            <p className="global--search--header--section--text--info mb-0">{searchTab.BoatShow}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              }
            >
              <div className="global--search">
                <Grid container className="width-100">
                  <Grid item sm={12}>
                    <div className="global--search--data">{renderBoatShowSection()}</div>
                  </Grid>

                  {/* <Grid item sm={4}>
                    {renderUserSection()}
                    {renderArticleSection()}
                  </Grid> */}
                  {totalBoatShowsData > pagination.GLOBAL_SEARCH_LIMIT &&
                    totalBoatShowsData > 0 &&
                    renderPagination(totalBoatShowsData)}
                </Grid>
              </div>
            </Tab>
            <Tab
              eventKey={searchTab.Articles}
              title={
                <div className="global--search--header">
                  <div className="global--search--main--container">
                    <div className="d-flex justify-content-between global--search--header--section align-items-center">
                      <div className="d-flex align-items-center">
                        <div className="d-flex align-items-center global--search--header--section--div">
                          <div className="global--search--header--section--icon">
                            <img
                              src={require('../../assets/images/search/articles-search.png')}
                              className="global--search--header--section--icon--img"
                              alt='Articles'
                            />
                          </div>
                          <div className="global--search--header--section--text">
                            <p className="global--search--header--section--text--info mb-0">{searchTab.Articles}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              }
            >
              <div className="global--search">
                <Grid container className="width-100">
                  <Grid item sm={12}>
                    <div className="global--search--data">{renderBoatArticleSection()}</div>
                  </Grid>

                  {/* <Grid item sm={4}>
                    {renderUserSection()}
                  </Grid> */}
                  {totalArticleData > pagination.GLOBAL_SEARCH_LIMIT &&
                    totalArticleData > 0 &&
                    renderPagination(totalArticleData)}
                </Grid>
              </div>
            </Tab>
            <Tab
              eventKey={searchTab.Users}
              title={
                <div className="global--search--header">
                  <div className="global--search--main--container">
                    <div className="d-flex justify-content-between global--search--header--section align-items-center">
                      <div className="d-flex align-items-center">
                        <div className="d-flex align-items-center global--search--header--section--div">
                          <div className="global--search--header--section--icon">
                            <img
                              src={require('../../assets/images/search/user-search.png')}
                              className="global--search--header--section--icon--img"
                              alt='User search'
                            />
                          </div>
                          <div className="global--search--header--section--text">
                            <p className="global--search--header--section--text--info mb-0">{searchTab.Users}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              }
            >
              <div className="global--search">
                <Grid container className="width-100">
                  <Grid item sm={12}>
                    <div className="global--search--data">{renderBoatUserSection()}</div>
                  </Grid>

                  {/* <Grid item sm={4}>
                    {renderArticleSection()}
                  </Grid> */}
                  {totalUsersData > pagination.GLOBAL_SEARCH_LIMIT && totalUsersData > 0 && renderPagination(totalUsersData)}
                </Grid>
              </div>
            </Tab>
          </Tabs>

          <div className="global--search--back--section">
            <div className="global--search--back--div">
              <span onClick={() => history?.goBack()}>{searchTab.Back}</span>
            </div>
          </div>
        </div>
      ) : (
        <Loader isPageLoader />
      )}
    </Layout>
  )
}

const mapStateToProps = state => ({
  globalSearchData: state.dashboardReducer.globalSearchData,
  searchSuccess: state.dashboardReducer.searchSuccess,
  searchTermData: state.dashboardReducer.searchTerm,
  recentSearchResults: state.boatReducer.recentSearchResults,
  totalBoatsData: state.dashboardReducer.totalBoatsData,
  totalNewBoatsData: state.dashboardReducer.totalNewBoatsData,
  totalArticleData: state.dashboardReducer.totalArticleData,
  totalBoatShowsData: state.dashboardReducer.totalBoatShowsData,
  totalMarinaStorageData: state.dashboardReducer.totalMarinaStorageData,
  totalRentBoatData: state.dashboardReducer.totalRentBoatData,
  totalUsedBoatsData: state.dashboardReducer.totalUsedBoatsData,
  totalUsersData: state.dashboardReducer.totalUsersData,
  totalYachtServiceData: state.dashboardReducer.totalYachtServiceData,
})

const mapDispatchToProps = dispatch => ({
  globalSearch: data => dispatch(globalSearch(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(GlobalSearch)
