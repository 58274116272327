import { get } from 'lodash'
import { gql } from 'apollo-boost'
import { graphqlClient } from '../../../helpers/graphqlClient'
import { subscribeUserSchema } from '../../../graphql/userSchema'
import { AddressType } from '../../../graphql/schemaTypes/schemaTypes'

export const getUser = async userId => {
  try {
    const res = await graphqlClient.query({
      fetchPolicy: 'no-cache',
      query: gql`
        query sm_getUser($userId: String!) {
          sm_getUser(userId: $userId) {
            _id
            isFollowing
            image {
              _id
              url
            }
            followersCount
            followingsCount
            postsCount
            adsCount
            adsCountLabel
            reviewsCount
            blogsCount
            boatShowsCount
          }
        }
      `,
      variables: { userId },
    })
    return res.data.sm_getUser
  } catch (er) {
    console.log(er)
    throw 'Someting went wrong'
  }
}

export const createPost = async data => {
  try {
    await graphqlClient.mutate({
      fetchPolicy: 'no-cache',
      mutation: gql`
        mutation sm_createPost($data: CreatePostInput!) {
          sm_createPost(data: $data)
        }
      `,
      variables: { data },
    })
  } catch (er) {
    console.log(er)
    throw 'Someting went wrong'
  }
}

export const getPost = async postId => {
  try {
    const res = await graphqlClient.query({
      fetchPolicy: 'no-cache',
      query: gql`
        query sm_getPost($postId: String!) {
          sm_getPost(postId: $postId) {
            _id
          }
        }
      `,
      variables: { postId },
    })
    return res.data.sm_getPost
  } catch (er) {
    console.log(er)
    throw 'Someting went wrong'
  }
}

export const getPosts = async (pagination, userId = null) => {
  try {
    const res = await graphqlClient.query({
      fetchPolicy: 'no-cache',
      query: gql`
        query sm_getPosts($userId: String, $pagination: PaginationInput!) {
          sm_getPosts(userId: $userId, pagination: $pagination) {
            page
            token
            items {
              _id
              content
              createdAt
              media {
                _id
                type
                url
                largeUrl
              }
              user {
                _id
                firstName
                lastName
                isVerified
                isFollowing
                createdAt
                image {
                  _id
                  url
                }
                address ${AddressType}
                followersCount
                followingsCount
                postsCount
                adsCount
                adsCountLabel
                reviewsCount
                blogsCount
                boatShowsCount
              }
              likesCount
              liked
              topLikes {
                _id
                user {
                  _id
                  firstName
                  lastName
                  image {
                    _id
                    url
                  }
                }
              }
              commentsCount
              topComments {
                _id
                content
                createdAt
                user {
                  _id
                  firstName
                  lastName
                  image {
                    _id
                    url
                  }
                }
                likesCount
                liked
              }
              saved
            }
          }
        }
      `,
      variables: { pagination, userId },
    })
    return res.data.sm_getPosts
  } catch (er) {
    console.log(er)
    throw 'Someting went wrong'
  }
}

export const followUser = async (userId, authUserId) => {
  try {
    const res = await graphqlClient.mutate({
      fetchPolicy: 'no-cache',
      mutation: subscribeUserSchema,
      variables: { id: userId },
    })
    return res.data.subscribe.data.some(sid => sid === authUserId)
  } catch (er) {
    console.log(er)
    throw 'Someting went wrong'
  }
}

export const likePost = async postId => {
  try {
    const res = await graphqlClient.mutate({
      fetchPolicy: 'no-cache',
      mutation: gql`
        mutation sm_likePost($postId: String!) {
          sm_likePost(postId: $postId) {
            likesCount
            liked
            topLikes {
              _id
              user {
                _id
                firstName
                lastName
                image {
                  _id
                  url
                }
              }
            }
          }
        }
      `,
      variables: { postId },
    })
    return res.data?.sm_likePost
  } catch (er) {
    console.log(er)
    throw 'Someting went wrong'
  }
}

export const getLikes = async (postId, pagination) => {
  try {
    const res = await graphqlClient.query({
      fetchPolicy: 'no-cache',
      query: gql`
        query sm_getPostLikes($postId: String!, $pagination: PaginationInput!) {
          sm_getPostLikes(postId: $postId, pagination: $pagination) {
            items {
              _id
              user {
                _id
                firstName
                lastName
                image {
                  smallUrl
                }
                isVerified
              }
            }
            page
          }
        }
      `,
      variables: { postId, pagination },
    })
    return res.data.sm_getPostLikes
  } catch (er) {
    console.log(er)
    throw 'Someting went wrong'
  }
}

export const commentPost = async (postId, content, parentCommentId = null) => {
  try {
    const res = await graphqlClient.mutate({
      fetchPolicy: 'no-cache',
      mutation: gql`
        mutation sm_commentPost($postId: String!, $content: String!, $parentCommentId: String) {
          sm_commentPost(postId: $postId, content: $content, parentCommentId: $parentCommentId) {
            commentsCount
            topComments {
              _id
              user {
                firstName
                image {
                  id
                  url
                }
                isVerified
              }
              content
              parentComment {
                _id
              }
              createdAt
              likesCount
              liked
              repliesCount
            }
          }
        }
      `,
      variables: { postId, content, parentCommentId },
    })
    return res.data?.sm_commentPost
  } catch (er) {
    console.log(er)
    throw 'Someting went wrong'
  }
}

export const likePostComment = async commentId => {
  try {
    const res = await graphqlClient.mutate({
      fetchPolicy: 'no-cache',
      mutation: gql`
        mutation sm_likePostComment($commentId: String!) {
          sm_likePostComment(commentId: $commentId) {
            likesCount
            liked
          }
        }
      `,
      variables: { commentId },
    })
    return res.data?.sm_likePostComment
  } catch (er) {
    console.log(er)
    throw 'Someting went wrong'
  }
}

export const getComments = async (postId, pagination) => {
  try {
    const res = await graphqlClient.query({
      fetchPolicy: 'no-cache',
      query: gql`
        query sm_getPostComments($postId: String!, $pagination: PaginationInput!) {
          sm_getPostComments(postId: $postId, pagination: $pagination) {
            items {
              _id
              content
              user {
                _id
                firstName
                image {
                  _id
                  smallUrl
                }
                isVerified
              }
              createdAt
              repliesCount
              liked
              likesCount
            }
            page
          }
        }
      `,
      variables: { postId, pagination },
    })
    return res.data.sm_getPostComments
  } catch (er) {
    console.log(er)
    throw 'Someting went wrong'
  }
}

export const getCommentReplies = async (commentId, { pagination }) => {
  try {
    const res = await graphqlClient.query({
      fetchPolicy: 'no-cache',
      query: gql`
        query sm_getPostCommentReplies($commentId: String!, $pagination: PaginationInput!) {
          sm_getPostCommentReplies(commentId: $commentId, pagination: $pagination) {
            _id
            user {
              firstName
              image {
                id
                url
              }
              isVerified
            }
            content
            createdAt
            repliesCount
            liked
            likesCount
          }
        }
      `,
      variables: { commentId, pagination },
    })
    return res.data?.sm_getPostCommentReplies
  } catch (er) {
    console.log(er)
    throw 'Someting went wrong'
  }
}

export const savePost = async postId => {
  try {
    const res = await graphqlClient.mutate({
      fetchPolicy: 'no-cache',
      mutation: gql`
        mutation sm_savePost($postId: String!) {
          sm_savePost(postId: $postId) {
            saved
          }
        }
      `,
      variables: { postId },
    })
    return res.data?.sm_savePost
  } catch (er) {
    console.log(er)
    throw 'Someting went wrong'
  }
}

export const deletePost = async postId => {
  try {
    await graphqlClient.mutate({
      fetchPolicy: 'no-cache',
      mutation: gql`
        mutation sm_deletePost($postId: String!) {
          sm_deletePost(postId: $postId)
        }
      `,
      variables: { postId },
    })
  } catch (er) {
    console.log(er)
    throw 'Someting went wrong'
  }
}

export const whoToFollow = async ({ page, limit } = {}) => {
  try {
    const res = await graphqlClient.query({
      query: gql`
        query whoToFollow($page: Float, $limit: Float) {
          whoToFollow(page: $page, limit: $limit) {
            user {
              _id
              firstName
              image {
                id
                url
              }
              subscribers {
                _id
              }
            }
            followersCount
            postsCount
          }
        }
      `,
      variables: { page, limit },
    })

    return res.data?.whoToFollow
  } catch (er) {
    console.log(er)
    throw get(er, 'networkError.result.errors[0].message', "Couldn't load recommendations")
  }
}

export const matchInterest = async ({ page, limit } = {}) => {
  try {
    const res = await graphqlClient.query({
      query: gql`
        query matchInterest($page: Float, $limit: Float) {
          matchInterest(page: $page, limit: $limit) {
            _id
            adId
            price
            yearBuilt
            lengthInFt
            address ${AddressType}
            boatType {
              _id
              name
            }
            boatStatus {
              _id
              alias
            }
            manufacturedBy {
              _id
              alias
            }
            seller {
              _id
              companyName
              role {
                _id
                aliasName
              }
            }
          }
        }
      `,
      variables: { page, limit },
    })

    return res.data?.matchInterest
  } catch (er) {
    console.log(er)
    throw get(er, 'networkError.result.errors[0].message', "Couldn't load recommendations")
  }
}
