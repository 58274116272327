import React, { useEffect, useRef, useState } from 'react'
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import classNames from 'classnames';
import { v4 as uuid } from 'uuid'
import OutsideClickHandler from 'react-outside-click-handler';
import PauseIcon from '@material-ui/icons/Pause';
import StopIcon from '@material-ui/icons/Delete';
import { uploadAudio, uploadChatImages } from '../../helpers/s3FileUpload';
import { chatType, convertRimeFromSecond, getThumbnailForVideo } from '../../util/chat';
import { useDispatch, useSelector } from 'react-redux'
import { addMessageByChatId, chatUploadImages, chatVideoUploadFail, createChatById, createMessageByChatId, isTextFalse, setDeliveredMessage, setFirsttimechatidNull, setMessagePayload, setReadMessage, setTextareaHeight, setUnreadCount, setUserList, startMessageByChatId } from '../../redux/actions/chatAction'
import { getIsCreatedChatMessage, getIsGroupChatCreateScreen, getIsTextMessage, getMessagePayload, getSelectUser, getFirstTimeCreateChatId, getTextareaHeight, getChatsList, chatSelect } from '../../redux/selector/chat'
import { Picker } from 'emoji-mart'
//scss
import 'emoji-mart/css/emoji-mart.css'
import { trimmedValue } from '../../helpers/string';
import { ChatContext } from '../../contexts/ChatContext';
import { useContext } from 'react';
import { Loader } from '../loader/loader';
import ChatInput from './chatInput';
import { generateVideoThumbnail } from '../../helpers/jsxHelper';
import loadable from '@loadable/component';
import {  MessageTypeEnum } from '../../util/enums/enums';
import {  socketEvent } from '../../util/enums/socketEnums';
import { socket } from '../../socket';
const AudioAnalyser = loadable(() => import("react-audio-analyser"));

export const SendMessageChat = ({ setSearchPeopleOrGroup }) => {
    const textAreaRef = useRef()
    const {  currentUser} = useContext(ChatContext);
    const [imageCount,setImageCount] = useState(0);
    const [videoCount,setVideoCount] = useState(0);
    const[isSend,setIsSend]=useState(false);
    const[audioBolb,setAudioBolb]=useState(null);
    
    const [status,setstatus] = useState('')
    const dispatch = useDispatch()
    const [enableEmojiTool, setEnableEmojiTool] = useState(false)
    const [stopAudioVideo, setStopAudioVideo] = useState(true)
    const [videoState,setVideoState] = useState("")
    const[firstTimeImage,setFirstTimeImage]=useState();
    const[firstTimeLocalImage,setFirstTimeLocalImage]=useState();
    const[videoLocal,setVideoLocal]=useState("")
    const [thumbnail,setThubnail] = useState("")
    const [isMediaOpen, setIsMediaOpen] = useState(false)
    const [audioVideo, setAudioVideo] = useState('')
    const [isDocState,setIsDoc]=useState(false);
    const [isVideoState,setIsVideo]=useState(false);
    const [isVideoSend, setIsVideoSend] = useState(false)
    const [uploadedImages, setUploadedImages] = useState([])
    const [uploadVideo,setUploadVideo]=useState([])
    const toggleEmoji = () => setEnableEmojiTool(enableEmojiTool => !enableEmojiTool)
    const selectUser = useSelector(getSelectUser)
    const chatsList = useSelector(getChatsList)
    const messagePayload = useSelector(getMessagePayload)
    const firstTimeCreateChatId = useSelector(getFirstTimeCreateChatId)
    const isCreatedChatMessage = useSelector(getIsCreatedChatMessage)
    const isGroup = useSelector(getIsGroupChatCreateScreen)
    const isTextMessage = useSelector(getIsTextMessage)
    const textareaHeight = useSelector(getTextareaHeight)
    const toggleMediaHandler = () => setIsMediaOpen(isMediaOpen => !isMediaOpen)
    const audioProps = {
        audioType:"audio/mp3",
        status,
        timeslice: 1000,
        startCallback: (e) => {
        },
        pauseCallback: (e) => {
            setAudioBolb(e)
        },
        stopCallback: (e) => {
            setAudioBolb(e)
            setIsSend(true);
        },
        errorCallback: (err) => {
            }
    }

    const addEmoji = e => {
        let emoji = e.native
        if (!emoji) {
            const sym = e.unified.split('-')
            const codesArray = sym.map(el => '0x' + el)
            emoji = String.fromCodePoint(...codesArray)
        }
        dispatch(setMessagePayload(messagePayload + emoji))
    }
    /**
     * If only Image upload
     *
     */
    const videoThumbnailHandle =(images)=>{
        if(images.length > 1){
          return  images.find(Boolean)
        }
        return ''
    }
    /**
     * Check video uploaded or not
     *
     */
    const checkVideoUpload =(images)=>{
     if(images.length === 1)  {
        return images?.includes('.png')
     }
     return false
    }
    /**
     * If only video upload
     *
     */
    const videoUploadHandle =(images)=>{
     if(images.length === 1){
        return images
     }
     return images.filter((i,index)=> index !== 0)
    }
    /**
   * Send message
   */
    const sendMessage = async(createImageUuid = null, images = [], localImages = [], isVideo = false, isDocs = '',thumbnail) => {
        if(isVideo && checkVideoUpload(images)) {
           return dispatch(chatVideoUploadFail())
        }
        dispatch(setTextareaHeight(78))
        const message = trimmedValue(messagePayload)
        if ((Object.keys(selectUser)?.length) && (message || audioVideo || images?.length) && !selectUser?.chatId && !isGroup && !firstTimeCreateChatId) {
            dispatch(createChatById({
                 members: [selectUser?.id] ,
                 imageUrl: selectUser?.imageUrl?selectUser?.imageUrl :selectUser.profileImage?selectUser.profileImage:"",
                metaData:selectUser?.metaData,
                description:"",
                channelUrl:uuid(),
                chatType: chatType.normalchat}))
        } 
        else {
            if (createImageUuid && images?.length) {
                if(!selectUser.isBlocked){
                    dispatch(addMessageByChatId({
                        chatId: selectUser?.chatId ?? firstTimeCreateChatId,
                        messageBody: '',
                        id:uuid(),
                        mediaUrl: isDocs?localImages[0]:isVideo?videoLocal:localImages ,
                        sender:{id:currentUser.id},
                        messageType: isVideo ? (isDocs ? MessageTypeEnum.DOCUMENT : MessageTypeEnum.VIDEO ) : MessageTypeEnum.IMAGE,
                        metaData: {
                            messageId: createImageUuid,
                            chatId: selectUser?.chatId ?? firstTimeCreateChatId,
                            ...(isVideo&&{videoThumbnail:""}),
                            ...(isVideo&&{isVideo:true}),
                            ...(isDocs&&{docName:isDocs}),
                        }
                    }))
                }
                socket.emit(socketEvent.Message,JSON.stringify({
                    chatId: selectUser?.chatId ?? firstTimeCreateChatId,
                    messageBody: '',
                    mediaUrl: isDocs?images[0]:images ,
                    messageType: isVideo ? (isDocs ? MessageTypeEnum.DOCUMENT : MessageTypeEnum.VIDEO ) : MessageTypeEnum.IMAGE,
                    metaData: {
                        messageId: createImageUuid,
                        chatId: selectUser?.chatId ?? firstTimeCreateChatId,
                        ...(isVideo&&{videoThumbnail:""}),
                        ...(isVideo&&{isVideo:true}),
                        ...(isDocs&&{docName:isDocs}),
                    }
                }))
            } else {
                if((message || audioVideo)){
                    if(!selectUser.isBlocked && !audioVideo){
                        dispatch(addMessageByChatId({
                            chatId: selectUser?.chatId ?? firstTimeCreateChatId,
                            messageBody: messagePayload,
                            mediaUrl: audioVideo,
                            messageType:audioVideo?MessageTypeEnum.AUDIO:MessageTypeEnum.TEXT,
                            id:uuid(),
                            sender:{id:currentUser.id},
                            metaData: {
                                chatId: selectUser?.chatId ?? firstTimeCreateChatId,
                                messageId: Boolean(audioVideo) ? audioVideo : uuid(),
                            }
                        }))
                    }
                    socket.emit(socketEvent.Message,JSON.stringify({
                      chatId: selectUser?.chatId ?? firstTimeCreateChatId,
                      messageBody: messagePayload,
                      mediaUrl: audioVideo,
                      messageType:audioVideo?MessageTypeEnum.AUDIO:MessageTypeEnum.TEXT,
                      metaData: {
                          chatId: selectUser?.chatId ?? firstTimeCreateChatId,
                          messageId: Boolean(audioVideo) ? audioVideo : uuid(),
                      }
                      })
                     )
                  dispatch(setUserList({
                    chatId: selectUser?.chatId ?? firstTimeCreateChatId,
                    messageBody: messagePayload,
                    mediaUrl: audioVideo,
                    messageType:audioVideo?MessageTypeEnum.AUDIO:MessageTypeEnum.TEXT,
                    metaData: {
                        chatId: selectUser?.chatId ?? firstTimeCreateChatId,
                        messageId: Boolean(audioVideo) ? audioVideo : uuid(),
                    }
                }))
                }

            }
            dispatch(setMessagePayload(''))
            setAudioVideo('')
        }
        // }
    }
    /**
   *
   * Image Multiple
   */
    const uploadMultipleImages = async (event) => {
        dispatch(startMessageByChatId())
        dispatch(chatUploadImages())
        setIsMediaOpen(false)
        const files = (Array.from(event.target.files))
        setUploadedImages(files)

    };
    /**
     *
     * Video Multiple
     */
    const uploadMultipleVideo = async (videos, isDocs = '') => {
       
        dispatch(chatUploadImages())
        setIsMediaOpen(false)
        if(isDocs){
           const createImageUuid = uuid()
        const res = await uploadChatImages(videos, uuid(), 'video')
                const videosOrDocs = res.map((i) => i.name)
                const localImages = isDocs ? [] : res.map((i) => i.url)
                setIsDoc(isDocs)
                setFirstTimeImage(videosOrDocs)
                setIsVideo(true)
                setFirstTimeLocalImage(localImages)
                
                sendMessage(createImageUuid, videosOrDocs, localImages, true, isDocs,res[0])
        }else{
        setUploadVideo(videos)
        }
    };
    const handleFileChange = async (event) => {
        setIsVideoSend(true)
        const thumbnail = await generateVideoThumbnail(event.target.files[0]);

        const dataThumb = await fetch(thumbnail)
            .then(res => res.blob())
            .then(blob => {
               return new File([blob], `${uuid()}-videoThumbnail`,{ type: "image/png" })
              })

        const allVideo = [dataThumb].concat(Array.from(event.target.files))
        await uploadMultipleVideo(allVideo)
    };
    const handleFileDocsChange = async (event) => {
        setIsVideoSend(true)
        const allVideo = (Array.from(event.target.files))
        const [singleFile] = allVideo
        uploadMultipleVideo(allVideo, singleFile?.name)
    };
    /**
   * Audio start
   */
    const addAudioElement = async (blob) => {
        
        if (blob) {
            
            const fileName = new File([blob], "audio");
            dispatch(chatUploadImages())
            const res = await uploadAudio(fileName, uuid(), 'audio', blob)
            setAudioVideo(res.name)
            setAudioBolb(null)
            setIsSend(false)
        }
    };

    useEffect(()=>{
        
        if(isSend){
            addAudioElement(audioBolb)
            setstatus(null)
        }
    },[isSend])
        
    useEffect(async()=>{
        if(uploadedImages.length){
            if((uploadedImages.length-1)>=imageCount ){
            const res = await uploadChatImages(Array.from([uploadedImages[imageCount]]), uuid(), 'image')
            if(res.length){
                const createImageUuid = uuid()
                setFirstTimeImage(res[0].name);
                setFirstTimeLocalImage(res[0].url);
                sendMessage(createImageUuid, res[0].name??[],res[0].url)
                    setImageCount(prev=>prev+1)
                }
                }else{
                    dispatch(isTextFalse())
                    setImageCount(0)
                    setUploadedImages([])
                }
        }
    },[imageCount,uploadedImages])

    useEffect(async()=>{
        if(uploadVideo.length){
            const res = await uploadChatImages(Array.from([uploadVideo[videoCount]]), uuid(), videoCount?'video':'image')
            if(res.length){
                
                if((uploadVideo.length-1)>videoCount ){
                    setVideoCount(prev=>prev+1)
                    if(videoCount===0){
                        setThubnail(res[0].name)    
                    }
                }
        else{
            setVideoLocal(res[0].url)
            setVideoState(res[0].name)
        }
    }
}
},[videoCount,uploadVideo])


useEffect(()=>{
    if(videoState&& thumbnail){
        const createImageUuid = uuid()
        setIsVideo(true);
        sendMessage(createImageUuid, videoState, [""], true, false,thumbnail)
        }
    },[videoState,thumbnail])


    useEffect(() => {
        if (textAreaRef.current) {
          textAreaRef.current.style.height = "30px";
          const scrollHeight = textAreaRef.current.scrollHeight;
    
          textAreaRef.current.style.height = scrollHeight + "px";
        }
      }, [textAreaRef.current, messagePayload]);

    useEffect(() => {
        if (!audioVideo) return
        sendMessage()
    }, [audioVideo])
    useEffect(() => {
        const breakLine = messagePayload.split('\n')
        if (!breakLine.length || !messagePayload) {
            dispatch(setTextareaHeight(78))
        }
        if (textareaHeight >= 140) return
        if (breakLine.length > 2) {
            breakLine.length === 3 ? dispatch(setTextareaHeight(30 + textareaHeight)) : dispatch(setTextareaHeight(30 * (breakLine.length)))
        } else {
            dispatch(setTextareaHeight(78))
        }
    }, [messagePayload])
    
    
    useEffect(() => {
        if (firstTimeCreateChatId) {
            socket.emit(socketEvent.JoinChannel,firstTimeCreateChatId)
            const message = trimmedValue(messagePayload)
            if (audioVideo || messagePayload ) {
                if((message || audioVideo)){
                        dispatch(addMessageByChatId({
                            chatId: selectUser?.chatId ?? firstTimeCreateChatId,
                            messageBody: messagePayload,
                            mediaUrl: audioVideo,
                            messageType:audioVideo?MessageTypeEnum.AUDIO:MessageTypeEnum.TEXT,
                            id:uuid(),
                            sender:{id:currentUser.id},
                            metaData: {
                                chatId: selectUser?.chatId ?? firstTimeCreateChatId,
                                messageId: Boolean(audioVideo) ? audioVideo : uuid(),
                            }
                        }))
                    setTimeout(()=>{
                        socket.emit(
                            socketEvent.Message,JSON.stringify({
                                chatId: selectUser?.chatId ?? firstTimeCreateChatId,
                                messageBody: messagePayload,
                                mediaUrl: audioVideo,
                                messageType:audioVideo?MessageTypeEnum.AUDIO:MessageTypeEnum.TEXT,
                                metaData: {
                                    chatId: selectUser?.chatId ?? firstTimeCreateChatId,
                                    messageId: Boolean(audioVideo) ? audioVideo : uuid(),
                                }
                            })
                            )
                        },500)
                  dispatch(setUserList({
                    chatId: selectUser?.chatId ?? firstTimeCreateChatId,
                    messageBody: messagePayload,
                    mediaUrl: audioVideo,
                    messageType:audioVideo?MessageTypeEnum.AUDIO:MessageTypeEnum.TEXT,
                    metaData: {
                        chatId: selectUser?.chatId ?? firstTimeCreateChatId,
                        messageId: Boolean(audioVideo) ? audioVideo : uuid(),
                    }
                }))
                }
            }else  {
                if ( firstTimeImage?.length || videoState?.length) {  
                const createImageUuid = uuid()
                if(!selectUser.isBlocked){
                    dispatch(addMessageByChatId({
                        chatId: selectUser?.chatId ?? firstTimeCreateChatId,
                        messageBody: '',
                        id:uuid(),
                        mediaUrl: isDocState?firstTimeLocalImage[0]:isVideoState?videoLocal:firstTimeLocalImage ,
                        sender:{id:currentUser.id},
                        messageType: isVideoState ? (isDocState ? MessageTypeEnum.DOCUMENT : MessageTypeEnum.VIDEO ) : MessageTypeEnum.IMAGE,
                        metaData: {
                            messageId: createImageUuid,
                            chatId: selectUser?.chatId ?? firstTimeCreateChatId,
                            ...(isVideoState&&{videoThumbnail:""}),
                            ...(isVideoState&&{isVideo:true}),
                            ...(isDocState&&{docName:isDocState}),
                        }
                    }))
                }
                setTimeout(()=>{
                    socket.emit(socketEvent.Message,JSON.stringify({
                        chatId: selectUser?.chatId ?? firstTimeCreateChatId,
                        messageBody: '',
                        mediaUrl: isDocState?firstTimeImage[0]:isVideoState?videoState:firstTimeImage ,
                        messageType: isVideoState ? (isDocState ? MessageTypeEnum.DOCUMENT : MessageTypeEnum.VIDEO ) : MessageTypeEnum.IMAGE,
                        metaData: {
                            messageId: createImageUuid,
                            chatId: selectUser?.chatId ?? firstTimeCreateChatId,
                            ...(isVideoState&&{videoThumbnail:""}),
                            ...(isVideoState&&{isVideo:true}),
                            ...(isDocState&&{docName:isDocState}),
                        }
                    }))
                },[500])

                dispatch(setUserList({
                    chatId: selectUser?.chatId ?? firstTimeCreateChatId,
                    messageBody: messagePayload,
                    mediaUrl: isDocState?firstTimeImage[0]:firstTimeImage,
                    messageType:isVideoState ? (isDocState ? MessageTypeEnum.DOCUMENT : MessageTypeEnum.VIDEO ) : MessageTypeEnum.IMAGE,
                    metaData: {
                        chatId: selectUser?.chatId ?? firstTimeCreateChatId,
                        messageId: Boolean(audioVideo) ? audioVideo : uuid(),
                    }
                }))
                setIsVideo(false);
                setIsDoc(false);
            
            }} 
            setUploadedImages([])
            setAudioVideo('')

            dispatch(setMessagePayload(''))
            setAudioVideo('')
            setIsVideoSend(false)
            setSearchPeopleOrGroup('')
        }
    }, [firstTimeCreateChatId])
    useEffect(() => {
        if (isCreatedChatMessage) {
            setSearchPeopleOrGroup('')
            setUploadedImages([])
        }
    }, [isCreatedChatMessage])

    return (
        <React.Fragment>
            {isTextMessage && <div className='chat-text-loader'> <Loader isSmall /></div>}
            <div className="chat--message--sent--section" >
                <OutsideClickHandler display="contents" onOutsideClick={() => setIsMediaOpen(false)}>
                    <div className="chat--add--icon chat-dropdown-all-messages cursor-pointer" onClick={toggleMediaHandler}>
                        <i class="fa fa-plus" aria-hidden="true"></i>
                        {isMediaOpen && (
                            <ul className="chat-media--control">
                                <li className='chat-media--videos'>
                                    <ChatInput
                                        handleFileChange={uploadMultipleImages}
                                        accept=".jpg,.jpeg, .gif, .png, .gif,.jfif"
                                        className="chat-media-img--btn"
                                    />
                                </li>
                                <li className='chat-media--videos'>
                                    <ChatInput
                                        handleFileChange={handleFileChange}
                                        accept="video/*"
                                        multiple={false}
                                    />
                                </li>
                                <li className='chat-media--videos'>
                                    <ChatInput
                                        handleFileChange={handleFileDocsChange}
                                        accept=".doc,.docx,.xml,.pdf,.xlsx,.csv,.xls"
                                        className="chat-media-doc--btn"
                                        multiple={false}
                                    />
                                </li>
                            </ul>
                        )}
                    </div>
                </OutsideClickHandler>
                {!status &&
                    <>
                        <div className={classNames("d-flex chat--write--message--div", {
                            'chat--input--div-auto': textareaHeight > 180
                        })}>
                            <textarea
                                className="chat--input--div"
                                type="text"
                                value={messagePayload}
                                ref={textAreaRef}
                                onChange={e => dispatch(setMessagePayload(e.target.value))}
                                onKeyUp={e => {
                                    if (e.keyCode === 13 && !e.shiftKey) {
                                        sendMessage(null, [], [], false)
                                    }
                                }}
                                disabled={!(Object.keys(selectUser)?.length)}
                            />
                            <div>
                                <div
                                    className="chat--send--message--icon cursor-pointer"
                                    disabled={!trimmedValue(messagePayload)}
                                    onClick={sendMessage}
                                >
                                    <img loading="lazy" src={require('../../assets/images/chat/message-send.svg')} alt="Message Send" />
                                </div>
                            </div>
                        </div>
                        <div className="chat--emoji--icon cursor-pointer">
                            <img loading="lazy" src={require('../../assets/images/chat/emoji.svg')} onClick={toggleEmoji} alt="Emoji" />
                            <OutsideClickHandler display="contents" onOutsideClick={() => setEnableEmojiTool(false)}>
                                {enableEmojiTool && (
                                    <Picker
                                        onSelect={addEmoji}
                                        style={{
                                            position: 'absolute',
                                            bottom: '78px',
                                            right: 0,
                                        }}
                                        title=""
                                    />
                                )}
                            </OutsideClickHandler>
                        </div>
                    </>
                }
                <div className={classNames('chat--speaker--icon cursor-pointer', {
                    'chat--speaker--auto': status,
                })}
                >

                    <div className='mic-control--wrapper'>
                    <AudioAnalyser {...audioProps}>
                        {(!status) && <div className="  cursor-pointer">
                            <img loading="lazy" src={require('../../assets/images/chat/mike.svg')} onClick={()=>{
                                setstatus('recording')
                            }} alt="" />
                        </div>}
                        {status && <div className='mic-control'>
                            <div className='mic-control--stop cursor-pointer' onClick={() => {
                                setstatus(null)  
                                setIsSend(false)
                                setAudioBolb(null)
                            }}><StopIcon /></div>
                            <div className=' mic-control--start-push'>
                                <div className='mic-control--push cursor-pointer' onClick={()=>{
                                    status === 'paused' ? setstatus('recording') :  setstatus('paused')
                                }}>
                                    {status !== 'paused'  ? <PauseIcon /> : <PlayArrowIcon />}
                                </div>
                                <span className='mic-control--text'>{status === 'paused' ? 'Paused' : 'recording...'}</span>
                            </div>
                            <div
                                className="mic-control--icon cursor-pointer"
                            >
                                <img loading="lazy" src={require('../../assets/images/chat/message-send.svg')} alt="Message Send" onClick={() => {
                                    setstatus('inactive')
                                }} />
                            </div>
                        </div>}
                        </AudioAnalyser>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
