import gql from 'graphql-tag'

const User = `{
  _id
  firstName
  lastName
  companyName
  image {
    _id
    smallUrl
  }
  role {
    _id
    role
    aliasName
  }
}`

const Boat = `{
  _id
  adId
  yearBuilt
  manufacturedBy {
    _id
    alias
  }
  engineHp
  boatType {
    _id
    name
  }
  seller ${User}
}`

const BoatRent = `{
  _id
  model
  tripType {
    _id
    alias
  }
}`

const Marina = `{
  _id
  name
  user ${User}
  provider {
    _id
    alias
  }
  serviceProvide {
    _id
    name
  }
}`

const Yacht = `{
  _id
  serviceProvide {
    _id
    name
  }
  user ${User}
}`

const Article = `{
  _id
  title
}`

const Booking = `{
  _id
  boat ${BoatRent}
}`

const AuctionBid = `{
  _id
  auctionRoom {
    _id
    boat ${Boat}
  }
}`

export const read = {
  name: 'readNotifications',
  query: gql`
    query readNotifications($input: NotificationInput) {
      readNotifications(input: $input) {
        items {
          _id
          moduleType
          category
          type
          data
          isRead
          createdAt
          updatedAt
          sender ${User}
          receiver ${User}
          module {
            SalesEngine {
              _id
              buyer ${User}
              seller ${User}
              boat ${Boat}
              agent ${User}
              surveyor {
                _id
                user ${User}
              }
            }
            Boat ${Boat}
            BoatRent ${BoatRent}
            AuctionBid ${AuctionBid}
            Article ${Article}
            RentBookingType ${Booking}
            Yacht ${Yacht}
            Marina ${Marina}
            BoatShow {
              _id
              title
            }
            Users ${User}
          }
        }
        total
        unread
      }
    }
  `,
}

export const markAsRead = {
  name: 'markNotificationsAsRead',
  query: gql`
    mutation markNotificationsAsRead($input: NotificationInput) {
      markNotificationsAsRead(input: $input)
    }
  `,
}

export const remove = {
  name: 'removeNotifications',
  query: gql`
    mutation removeNotifications($input: NotificationInput) {
      removeNotifications(input: $input)
    }
  `,
}
