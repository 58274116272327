import React, { useState, useEffect, memo } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import { Grid } from '@material-ui/core'
import { Formik, Form, ErrorMessage } from 'formik'
import { FaEye, FaEyeSlash } from 'react-icons/fa'
import MailOutlineIcon from '@material-ui/icons/MailOutline'
import * as Yup from 'yup'

import { convertWishlistsFromSessionToUser, clearErrorMessageShow, clearAuthorizationFlag, login } from '../../redux/actions'
import { CommonModal } from './commonModal'
import ErrorFocus from '../ws/ErrorFocus'
import { requireMessage, trimmedValue } from '../../helpers/string'
import { Field } from '../ws/Field'
import Captcha from '../helper/captcha'
import ErrorComponent from '../error/errorComponent'
import { ButtonComponent } from '../form/Button'

import './loginModal.scss'

const LoginModal = props => {
  const {
    open,
    close,
    isAuthorized,
    isError,
    errorMessage,
    login,
    clearAuthFlag,
    clearErrorMessageShow,
    convertWishlistsFromSessionToUser,
  } = props

  const [loginInput] = useState({
    username: '',
    password: '',
  })

  const [showPassword, setShowPassword] = useState(false)
  const [isVerifyCaptch, setIsVerifyCaptch] = useState(false)
  const [recaptchaRef, setRecaptchaRef] = useState(null)

  const toggleShowPasswordHandler = () => setShowPassword(!showPassword)

  useEffect(() => {
    open && clearAuthFlag()
    open && clearErrorMessageShow()
  }, [clearAuthFlag, clearErrorMessageShow, open])

  useEffect(() => {
    if (isAuthorized && open) {
      clearAuthFlag()
      convertWishlistsFromSessionToUser()
      close()
    }
  }, [isAuthorized, clearAuthFlag, convertWishlistsFromSessionToUser, open, close])

  return (
    <CommonModal open={open} close={close} title="AdamSea Login" className="login--common--modal">
      <Formik
        initialValues={loginInput}
        validationSchema={Yup.object().shape({
          username: Yup.string().email('Email is invalid.').required(requireMessage('Email')),
          password: Yup.string().required(requireMessage('Password')),
        })}
        onSubmit={async values => {
          const token = await recaptchaRef.current.execute()
          if (token) {
            setIsVerifyCaptch(false)
            clearErrorMessageShow()
            login({ username: values.username, password: values.password })
          } else {
            setIsVerifyCaptch(true)
          }
        }}
        render={({ status, values, setFieldValue, handleSubmit }) => (
          <div>
            <div className="form-content d-flex flex-column">
              <Form className="mt-3 mb-3">
                <ErrorFocus />

                <div className="position-relative login-field mb-3">
                  <Field
                    placeholder="Email Address"
                    type="text"
                    name="username"
                    id="username"
                    value={values.username}
                    onChangeText={e => setFieldValue('username', trimmedValue(e.target.value))}
                    required
                  />
                  <span className="form-side-icon password-login-icon login-form-side-icon">
                    <MailOutlineIcon />
                  </span>
                  <ErrorMessage name="username" component="div" className="invalid--feedback--error" />
                </div>

                <div className="position-relative login-field">
                  <Field
                    placeholder="Password"
                    type={showPassword ? 'text' : 'password'}
                    name="password"
                    id="password"
                    onChangeText={e => setFieldValue('password', e.target.value)}
                    required
                  />
                  <span className="form-side-icon password-login-icon login-form-side-icon" onClick={toggleShowPasswordHandler}>
                    {showPassword ? <FaEye /> : <FaEyeSlash />}
                  </span>
                  <ErrorMessage name="password" component="div" className="invalid--feedback--error" />
                </div>

                <Grid container className="mt-10 mb-10">
                  <Grid item xs>
                    <div className="clearfix"></div>
                  </Grid>
                  <Link to="/send-reset-password-link" className="float-right forgetTextStyle font-14">
                    {'Forgot password?'}
                  </Link>
                </Grid>

                <div className="position-relative login-field error-captcha vidnohid">
                  <Captcha getRefValue={value => setRecaptchaRef(value)} />
                </div>

                <ButtonComponent
                  className="h-auto as--success--outline btn--padding btn--font mb-3 w-100 justify-content-center"
                  onClick={handleSubmit}
                >
                  Log in
                </ButtonComponent>
                <span className='font-12 d-flex justify-content-center text-center'>
                  Don't have an account?
                  <Link to="/register" className="forgetTextStyle ml-1 font-14">
                    {'Sign up'}
                  </Link>
                </span>
                {isVerifyCaptch && <div className="alert alert-danger">Invalid captach</div>}
                {isError && <ErrorComponent errors={errorMessage} />}
              </Form>
            </div>
          </div>
        )}
      />
    </CommonModal>
  )
}

LoginModal.defaultProps = {
  value: null,
}

const mapStateToProps = state => ({
  isError: state.loginReducer.isError,
  errorMessage: state.errorReducer.errorMessage,
  isAuthorized: state.loginReducer.isAuthorized,
})

const mapDispatchToProps = dispatch => ({
  login: data => dispatch(login(data)),
  clearAuthFlag: () => dispatch(clearAuthorizationFlag()),
  clearErrorMessageShow: () => dispatch(clearErrorMessageShow()),
  convertWishlistsFromSessionToUser: () => dispatch(convertWishlistsFromSessionToUser()),
})

export default connect(mapStateToProps, mapDispatchToProps)(memo(LoginModal))
