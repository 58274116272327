import { useLoginModal } from '../../contexts/login-modal-ctx'
import OrigLoginModal from '../../../../components/modal/loginModal'
import compStyles from '../../styles/components.module.scss'

export default function LoginModal({ zIndex }) {
  const loginModal = useLoginModal()

  if (!loginModal.isOpen) return null

  return (
    <div className={compStyles.overlay} style={{ zIndex }}>
      <OrigLoginModal open close={loginModal.hide} />
    </div>
  )
}
