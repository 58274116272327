import { connect } from 'react-redux'
import React, { Component } from 'react'
import { FaRegBell } from "react-icons/fa";


import * as Yup from 'yup'
import { Formik, Form, ErrorMessage } from 'formik'
import CancelIcon from '@material-ui/icons/Cancel'

import { Layout, Loader, Field } from '../../components'
import { getSingleArticle } from '../../redux/actions/articlesAction'
import '../../assets/css/component/article-inner.scss'
import { monthYearDateFormat, formattedDateArticle } from '../../helpers/dateTimeHelper'
import {
  redirectToUserProfile,
  cityCountryNameFormatter,
  getFirstCharacter,
  verificationRedirection,
} from '../../helpers/jsxHelper'

import UserContext from '../../UserContext'
import {
  defaultProfileIcon,
  likeModuleEnum,
  sharePopupMessage,
  pagination,
  wishlistModuleEnum,
  boatVerifyMessage,
  skeletonType,
} from '../../util/enums/enums'
import { displayDefaultNumericValue, formatCash, nameFormatter } from '../../helpers/string'
import { ButtonComponent } from '../../components/form/Button'
import { pageVisit, addLikes, subscribeToUser, clearModulePageVisit, clear_subscribeToUser } from '../../redux/actions'
import { getLocalStorageItem } from '../../helpers/storageHelper'
import LoginModal from '../../components/modal/loginModal'

import { CommonModal } from '../../components/modal/commonModal'
import { popUpMessage } from '../../helpers/confirmationPopup'
import { CheckBox } from '@material-ui/icons'
import { getUserList } from '../../redux/actions/shareAction'
import { LikeComponent } from '../../components/like/LikeComponent'
import { ArticleShareIcon } from '../../components/article/ArticleShareIcon'
import { SkeletonLoader } from '../../components/loader/SkeletonLoader'
import { Grid } from '@material-ui/core'
import { Spinner } from 'react-bootstrap'
import { notificationEnum } from '../../util/enums/notificationEnum'
import { getId, getImgUrl, getOnlineUsers } from '../../util/utilFunctions'
import classNames from 'classnames'
import { SET_ONLINE_USERS } from '../../redux/actionTypes'
import VerificationModal from '../verificationModal'
import { Box } from '@material-ui/core'
import { FaAngleLeft } from 'react-icons/fa'

class ArticleInner extends Component {
  state = {
    id: '',
    isOpenModel: false,
    selectedEmail: '',
    isOpen: false,
    email: '',
    selectedUsers: [],
    addedEmails: [],
    selectedUserDetailList: [],
    maxUserLimitReached: false,
    maxUserLimitReachedForAddedUser: false,
    loader: true,
    loginModal: false,
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    const { match } = nextProps
    const { params } = match
    const { shareUsers } = nextProps
    const { loader } = prevState

    let ret = {};

    if (params && params.hasOwnProperty('id') && params.id) {
      ret = {
        ...ret,
        id: params.id,
      }
    }
    if (shareUsers?.items?.length && loader) {
      ret = {
        ...ret,
        loader: false,
      }
    }

    return ret;
  }

  componentDidUpdate(prevProps) {
    const { match, modulePageVisitSuccess, modulePageVisitError, getSingleArticle, clearModulePageVisit } = this.props

    const { params } = match

    if (modulePageVisitSuccess || modulePageVisitError) {
      getSingleArticle({ id: params.id })
      clearModulePageVisit()
    }

    if (getId(this.props.article?.user) !== getId(prevProps.article?.user)) {
      getOnlineUsers([getId(this.props.article?.user)], this.props.setOnlineUsers);
    }

    if (this.props.subscribeError) {
      this.props.clear_subscribeToUser()
    }
  }

  componentDidMount() {
    const { id } = this.state
    const { pageVisit, adminLoginDetected } = this.props

    !adminLoginDetected && id && pageVisit({ module: id, type: likeModuleEnum.ARTICLE, sessionId: getLocalStorageItem('sessionId') })

    if (getId(this.props.article?.user)) {
      getOnlineUsers([getId(this.props.article?.user)], this.props.setOnlineUsers);
    }
  }

  createMarkup = text => {
    return { __html: text }
  }

  addRemoveUser = (user, setFieldValue) => {
    const { selectedUsers, maxUserLimitReached, maxUserLimitReachedForAddedUser, selectedUserDetailList } = this.state
    maxUserLimitReachedForAddedUser && this.setState({ maxUserLimitReachedForAddedUser: false })
    if (selectedUsers.includes(user.id)) {
      const index = selectedUsers.indexOf(user.id)
      const detailIndex = selectedUserDetailList.indexOf(user)

      selectedUsers.splice(index, 1)
      selectedUserDetailList.splice(detailIndex, 1)
      this.setState({ selectedUsers, selectedUserDetailList })
      maxUserLimitReached && this.setState({ maxUserLimitReached: false })
    } else {
      if (selectedUsers.length <= 4) {
        selectedUsers.push(user.id)
        selectedUserDetailList.push(user)
        setFieldValue('email', `${user.firstName}${' '}${user.lastName}`)
        this.setState({ selectedUsers, selectedUserDetailList })
      } else {
        this.setState({ maxUserLimitReached: true })
      }
    }
  }
  addEmail = ({ email }, resetForm) => {
    const { addedEmails, maxUserLimitReachedForAddedUser, maxUserLimitReached } = this.state
    maxUserLimitReached && this.setState({ maxUserLimitReached: false })
    if (addedEmails.length <= 4) {
      maxUserLimitReachedForAddedUser && this.setState({ maxUserLimitReachedForAddedUser: false })
      if (email) {
        addedEmails.push(email)
        this.setState({ addedEmails })
        resetForm()
      }
    } else {
      this.setState({ maxUserLimitReachedForAddedUser: true })
      const interval = setInterval(() => {
        this.setState({ maxUserLimitReachedForAddedUser: false })
        clearInterval(interval)
      }, 2000)
    }
  }

  removeEmail = i => {
    const { addedEmails, maxUserLimitReachedForAddedUser, maxUserLimitReached } = this.state
    maxUserLimitReached && this.setState({ maxUserLimitReached: false })
    addedEmails.splice(i, 1)
    this.setState({ addedEmails })
    if (addedEmails.length <= 4) {
      maxUserLimitReachedForAddedUser && this.setState({ maxUserLimitReachedForAddedUser: false })
    }
  }

  closeModalHandler = () => {
    this.setState({ isOpen: false })
  }

  handlerLoginModal = () => {
    this.setState(prevState => ({
      isOpenModel: !prevState.isOpenModel,
    }))
  }

  modalHandler = () => {
    const { isOpen } = this.state
    const { isInner, singleHandleClick } = this.props
    isInner && singleHandleClick()
    const userId = getLocalStorageItem('userId')

    !userId && this.handlerLoginModal()

    userId && !isOpen && this.getSearchedUserList()
    userId && this.setState(prevState => ({ isOpen: !prevState.isOpen }))
  }

  getSearchedUserList = (searchTerm = '') => {
    clearTimeout(this.timer)
    this.timer = setTimeout(() => {
      const { getUserList, currentUser } = this.props
      currentUser &&
        currentUser.id &&
        getUserList({ searchTerm, page: pagination.PAGE_COUNT, limit: pagination.PAGE_RECORD_LIMIT })
    }, 1000)
  }

  submitShareWithUsersHandler = data => {
    const { shareDetailsWithUsers } = this.context
    const { article } = this.props

    if (data.selectedUsers.length > 0 || data.addedEmails.length > 0) {
      shareDetailsWithUsers({
        module: wishlistModuleEnum.ARTICLE,
        moduleId: article?.id,
        receiverIds: data.selectedUsers,
        manualIds: data.addedEmails,
      })
      this.setState({ selectedEmail: '' })
      this.setState({ selectedUsers: [], addedEmails: [] })
      this.modalHandler()
    } else {
      popUpMessage('Please select atleast one user or add new email to share with', 'Alert')
    }
  }
  static contextType = UserContext

  subscribeHandler = () => {
    const userId = getLocalStorageItem('userId')
    const { subscribeToUser, article } = this.props

    if (!userId) {
      this.handlerLoginModal()
    } else if (article?.user?.id) {
      subscribeToUser({
        id: article.user.id,
      })
    }
  }

  redirectHandler = () => {
    const { history } = this.props
    verificationRedirection('/', history)
  }

  render() {
    const { history } = this.context
    const { article, isLoading, shareIsLoading, isModulePageLoading, shareUsers, loading, addLikes, selectedUserDetailList, subscribeLoader, onlineUsers } =
      this.props
    const {
      isOpenModel,
      isOpen,
      email,
      selectedEmail,
      selectedUsers,
      addedEmails,
      maxUserLimitReached,
      maxUserLimitReachedForAddedUser,
      loader,
      id,
    } = this.state
    const { title, user, createdAt, userTotalArticles, files, description, likes, pageVisits, articleApproved } = article
    const userId = getLocalStorageItem('userId')

    const isSubscribe = article?.user?.subscribers?.map(user => (typeof user === 'object' ? user?.id : user)).includes(userId)
    //redirect to article user

    return (
      <Layout>
        {isLoading || isModulePageLoading ? (
          <Loader isPageLoader />
        ) : (
          <div className="single--article-main adam-dash-manage-art-inner w-100 ">
            <LoginModal open={isOpenModel} close={this.handlerLoginModal} />

            {article.id &&
              !articleApproved &&
              popUpMessage(
                notificationEnum.ARTICLE_UNDER_VERIFICATION.message,
                'Alert',
                true,
                null,
                null,
                this.redirectHandler
              )}

            <div className="single--article-container">
              <div className="back-to-article" onClick={() => redirectToUserProfile(user, history)}>
                <FaAngleLeft size={'1em'} /> {user?.firstName || 'user'}'s Profile
              </div>
              <div className="single--article-content-main">
                <Grid container>
                  <Grid item xs={12}>
                    <div className="single--article-content">
                      <h1 className="single--article-title article--inner--container">{String(title).split(' ').map(word => (word.charAt(0)?.toUpperCase() || '') + word.substring(1, word.length)).join(' ')}</h1>

                      <div className="posted--by-article article--inner--container">
                        <span className="by--user color-black">{nameFormatter([user?.firstName, user?.lastName])}</span>
                        <span className="seperator">|</span>
                        <span className="by--user color-black">{formattedDateArticle(createdAt)}</span>
                      </div>

                      <div className="mobile-image article-image article--inner--container w-100 ">
                        <img
                          src={files?.length > 0 ? getImgUrl(files[0]) : ''}
                          alt={nameFormatter([user?.firstName, user?.lastName])}
                        />
                      </div>

                      <div className="d-flex justify-content-between align-items-start w-100 maindiv article-page-hide-mobile-view pb-5">
                        <div style={{ flex: 1 }}>
                          <div className="article-image article--inner--container w-100 ">
                            <img
                              src={files?.length > 0 ? getImgUrl(files[0]) : ''}
                              alt={nameFormatter([user?.firstName, user?.lastName])}
                            />
                          </div>
                          <div className="article-like-view">
                            <div className='d-flex mobile-view-article'>
                            <div className="artical-icon font-16">
                              <span className="count d-flex align-items-center"><i className="fas fa-book-reader mr-3"></i> {formatCash(pageVisits?.length)}</span>
                            </div>
                            <LikeComponent
                              likes={likes}
                              moduleId={id}
                              likeModule={likeModuleEnum.ARTICLE}
                              addLikes={addLikes}
                              isArticle
                            />
                            </div>
                            {articleApproved && (
                              <div className="count artical-social-share article--inner--container">
                                {/* <div className="artical-social-label">Social Share</div> */}
                                <ArticleShareIcon onClick={this.modalHandler} />
                              </div>
                            )}
                          </div>
                        </div>

                        {user && user.id && (
                          <div className="user-information">
                            <div className="user--logo-with-profile">
                              <div className="profile--picture-logo">
                                <div
                                  className="user-profile-online-section cursor-pointer"
                                  onClick={() => redirectToUserProfile(user, history)}
                                >
                                  <img
                                    className="rounded-circle cursor-pointer article--user-profile"
                                    src={user?.image?.url || defaultProfileIcon}
                                    alt="profile icon"
                                  />
                                  <div className="user-online">
                                    <div className={classNames("online-div-user", {
                                      'disconnect': !onlineUsers[getId(user)],
                                    })}></div>
                                  </div>
                                </div>
                              </div>
                              <div className='d-flex justify-content-around w-100'>
                            <div className="user--card-info">
                              <div className="author-info text-center">
                                <h5 className="user--name font-weight-bold color-black" onClick={() => redirectToUserProfile(user, history)}>
                                  {user?.firstName}
                                </h5>
                                <p className="article--city">
                                  {cityCountryNameFormatter(
                                    user?.address?.length > 0 && user.address[0].city,
                                    user.address[0].country
                                  )}
                                </p>
                                {userId !== article?.user?.id && (
                          <div className="text-center mt-10">
                            <div className="email-subscribe-form justify-content-center">
                              <button disabled={subscribeLoader} id='as--subscribe--yt' onClick={this.subscribeHandler}>
                                {
                                // subscribeLoader ? (
                                //   <Spinner animation="border" size="sm" />
                                // ) : (
                                <>
                                  {isSubscribe && <FaRegBell style={{width: '14px', marginRight: '4px'}} color='white' />}
                                  {isSubscribe ? 'FOLLOWED' : 'FOLLOW'}
                                </>

                                // )
                                }
                              </button>
                            </div>
                          </div>
                        )}
                                {/* <span className="text-transform-capitalize inner-user-profile-info-text">
                                  {user?.role?.role.toLowerCase()}
                                </span> */}
                              </div>
                            </div>
                            {/* <div className='d-flex flex-column p-3'>
                            <div className='d-flex flex-column'>
                            <strong>{monthYearDateFormat(user?.createdAt)}</strong>
                            <span className='inner-user-profile-info-text'>On AdamSea</span>
                          </div>
                            </div> */}
                            </div>
                              {/* {user?.companyLogo?.url && (
                                <div className="company-logo">
                                  <img
                                    className="logo-profile"
                                    src={user?.companyLogo?.url || defaultProfileIcon}
                                    alt="profile icon"
                                  />
                                </div>
                              )} */}
                            </div>

                            <div className="col-12 pl-3 pr-0 inner-user-profile-info d-flex justify-content-around py-3">
                                              <div className='d-flex flex-column text-center'>
                                                      <strong>{userTotalArticles}</strong>
                                                      <span className='inner-user-profile-info-text'>Articles</span>
                                                    </div>
                                                    <div className='d-flex flex-column text-center align-items-center'>
                                                      <strong className='d-flex'>{displayDefaultNumericValue(article?.user?.subscribers?.length)}</strong>
                                                      <span className='inner-user-profile-info-text'>Followers</span>
                                                    </div>
                                              </div>
                          </div>
                        )}
                      </div>

                      <div className="d-flex justify-content-between w-100 maindiv article-page-show-mobile-view-first">
                        <div className="desktop-image article-image article--inner--container w-100 ">
                          <img
                            src={files?.length > 0 ? files[0].largeUrl : ''}
                            alt={nameFormatter([user?.firstName, user?.lastName])}
                          />
                        </div>
                      </div>

                      <div className="d-flex justify-content-between w-100 maindiv article-page-show-mobile-view">
                        {user && user.id && (
                          <div className="user-information">
                            <div className="user--logo-with-profile">
                              <div className="profile--picture-logo">
                                <div
                                  className="user-profile-online-section cursor-pointer"
                                  onClick={() => redirectToUserProfile(user, history)}
                                >
                                  <img
                                    className="rounded-circle cursor-pointer article--user-profile"
                                    src={user?.image?.url || defaultProfileIcon}
                                    alt="profile icon"
                                  />
                                  <div className="user-online">
                                    <div className={classNames("online-div-user", {
                                      'disconnect': !onlineUsers[getId(user)],
                                    })}></div>
                                  </div>
                                </div>
                              </div>
                              <div className='d-flex justify-content-around w-100'>
                                <div className="user--card-info">
                                  <div className="author-info text-center">
                                    <h5 className="user--name font-weight-bold color-black" onClick={() => redirectToUserProfile(user, history)}>
                                      {user?.firstName}
                                    </h5>
                                    <p className="article--city">
                                      {cityCountryNameFormatter(
                                        user?.address?.length > 0 && user.address[0].city,
                                        user.address[0].country
                                      )}
                                    </p>
                                    {userId !== article?.user?.id && (
                                      <div className="text-center mt-10">
                                        <div className="email-subscribe-form justify-content-center">
                                          <button disabled={subscribeLoader} id='as--subscribe--yt' onClick={this.subscribeHandler}>
                                            {
                                              <>
                                                {isSubscribe && <FaRegBell style={{ width: '14px', marginRight: '4px' }} color='white' />}
                                                {isSubscribe ? 'FOLLOWED' : 'FOLLOW'}
                                              </>
                                            }
                                          </button>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                  <div className="col-12 pl-3 pr-0 inner-user-profile-info d-flex justify-content-around py-3">
                                    <div className='d-flex flex-column text-center'>
                                      <strong className='user--name font-weight-bold color-black'>{userTotalArticles}</strong>
                                      <span className='inner-user-profile-info-text'>Articles</span>
                                    </div>
                                    <div className='d-flex flex-column text-center align-items-center'>
                                      <strong className='d-flex user--name font-weight-bold color-black'>{displayDefaultNumericValue(article?.user?.subscribers?.length)}</strong>
                                      <span className='inner-user-profile-info-text'>Followers</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>

                      <div className="articalparagraph">
                        <div dangerouslySetInnerHTML={this.createMarkup(description)}></div>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </div>
          </div>
        )}

        {/* share */}
        {/*[NOTE] : Need to make common component */}
        {isOpen && (
          <CommonModal
            className="adamsea--share--content noheadermt"
            open={isOpen}
            close={() => {
              this.closeModalHandler()
            }}
            title={
              <div className="d-flex justify-content-between align-items-center flex-wrap">
                <img loading="lazy" src={getImgUrl(this.props.siteConfigurations?.adamseaShareLogo)} alt='adamsea logo' height={20} />
                <span className="model-after-title-text">Share this listing with your contacts.</span>
              </div>
            }
            overflowAuto={false}
          >
            <Formik
              initialValues={{ email }}
              validationSchema={Yup.object().shape({
                email: Yup.string().email('Only email allowed').required('Email is required'),
              })}
              onSubmit={(values, { resetForm }) => {
                this.addEmail(values, resetForm)
                this.getSearchedUserList()
              }}
              render={({ setFieldValue, values, errors, touched, handleSubmit }) => (
                <Form>
                  <div
                    className={`d-flex justify-content-between content-share-popup-rent-page ${
                      errors.email && touched.email ? 'mb-0' : 'mb--error'
                    }`}
                  >
                    <div className="with--error-model content-popup-rent-inner-div text-left mr-1 position-relative w-100">
                      <input
                        className="form-control h-auto adam--share--popup--mail--div font-12"
                        name="email"
                        value={values.email}
                        autocomplete="off"
                        placeholder="Search or add new email from here"
                        onChange={e => {
                          this.setState({ selectedEmail: '' })
                          setFieldValue('email', e.target.value)
                          this.getSearchedUserList(e.target.value)
                        }}
                      />

                      <ButtonComponent
                        className=" h-auto as--success--outline adam--share--modal--popup--add--btn btn--padding btn--font mr-imp-1"
                        onClick={handleSubmit}
                      >
                        Add Email
                      </ButtonComponent>

                      <ButtonComponent
                        className=" share-now-mobile h-auto as--success--outline btn--padding btn--font adam--share--modal--popup--btn btn--padding btn--font"
                        onClick={() => {
                          this.submitShareWithUsersHandler({ selectedUsers, addedEmails })
                        }}
                      >
                        <i className="adam-share mr-2 font-weight-bold "></i>
                        Share
                      </ButtonComponent>
                    </div>
                  </div>
                  {errors.email && touched.email && (
                    <ErrorMessage className="error-message mb--error" name="email" component="div" />
                  )}
                  {maxUserLimitReached && (
                    <div ref={el => el?.scrollIntoView({ behavior: 'smooth', block: 'end' })} className="alert alert-warning">
                      Maximum limit reached for AdamSea Users
                    </div>
                  )}
                  {maxUserLimitReachedForAddedUser && (
                    <div ref={el => el?.scrollIntoView({ behavior: 'smooth', block: 'end' })} className="alert alert-warning">
                      Maximum limit reached.
                    </div>
                  )}

                  {addedEmails &&
                    addedEmails.length > 0 &&
                    addedEmails.map((item, i) => {
                      return (
                        <div>
                          {item} <CancelIcon onClick={() => this.removeEmail(i)} />
                        </div>
                      )
                    })}

                  <div className="adamsea--users--share">
                    {selectedUsers?.length > 0 &&
                      selectedUserDetailList?.length > 0 &&
                      selectedUserDetailList.map((user, index) => {
                        return (
                          <div
                            className={`cursor-pointer  ${
                              selectedUsers.includes(user.id) && 'selected-adamsea--user'
                            } share-adamsea-user`}
                            onClick={() => this.addRemoveUser(user, setFieldValue)}
                          >
                            <CheckBox
                              className="opacity-none cursor-pointer"
                              id={user.id}
                              checked={selectedUsers.includes(user.id)}
                              onClick={() => {
                                this.addRemoveUser(user, setFieldValue)
                              }}
                            ></CheckBox>
                            <label for={user.id} className="d-flex mt-1 cursor-pointer">
                              <div className="d-flex align--flex--start share--adamse--logo">
                                {user.image ? (
                                  <img
                                    src={user.image?.url}
                                    className="view-service-img-salesman share--adamse--user--img"
                                    alt="Profile"
                                    onClick={() => redirectToUserProfile(user, history)}
                                  />
                                ) : (
                                  getFirstCharacter(user.firstName)
                                )}
                                <div className="share--name-with-email">
                                  <span className="share--adamse--user--name font--black-bold">
                                    {user.firstName} {user.lastName}
                                  </span>
                                  <span className="share--adamse--user--name">
                                    {user?.role?.role === 'SERVICE & MAINTENANCE' ? 'BOAT SERVICE' : user?.role?.role}
                                  </span>
                                  <span className="share--adamse--user--email">
                                    Working {user.companyName ? ` in ${user.companyName}` : `${user.profession?.alias}`}
                                  </span>
                                </div>
                              </div>
                            </label>
                          </div>
                        )
                      })}
                    {shareIsLoading ? (
                      <SkeletonLoader type={skeletonType.userInfo} itemsLength={10} count={6} />
                    ) : (
                      <>
                        {shareUsers?.items?.length ? (
                          shareUsers.items.map((user, index) => {
                            return (
                              !selectedUserDetailList?.find(item => item.id === user.id) && (
                                <div
                                key={index}
                                  className={`cursor-pointer  ${
                                    selectedUsers.includes(user.id) && 'selected-adamsea--user'
                                  } share-adamsea-user`}
                                  onClick={() => this.addRemoveUser(user, setFieldValue)}
                                >
                                  <CheckBox
                                    className="opacity-none cursor-pointer"
                                    id={user.id}
                                    checked={selectedUsers.includes(user.id)}
                                    onClick={() => {
                                      this.addRemoveUser(user, setFieldValue)
                                    }}
                                  ></CheckBox>
                                  <label for={user.id} className="d-flex mt-1 cursor-pointer">
                                    <div className="d-flex align--flex--start share--adamse--logo">
                                      {user.image ? (
                                        <img
                                          src={user.image?.url}
                                          className="view-service-img-salesman share--adamse--user--img"
                                          alt="Profile"
                                          onClick={() => redirectToUserProfile(user, history)}
                                        />
                                      ) : (
                                        getFirstCharacter(user.firstName)
                                      )}
                                      <div className="share--name-with-email">
                                        <span className="share--adamse--user--name font--black-bold">
                                          {user.firstName} {user.lastName}
                                        </span>
                                        <span className="share--adamse--user--name">
                                          {user?.role?.role === 'SERVICE & MAINTENANCE' ? 'BOAT SERVICE' : user?.role?.role}
                                        </span>
                                        <span className="share--adamse--user--email">
                                          Working {user.companyName ? ` in ${user.companyName}` : `${user.profession?.alias}`}
                                        </span>
                                      </div>
                                    </div>
                                  </label>
                                </div>
                              )
                            )
                          })
                        )
                        :
                          <>
                            { shareUsers?.total=== 0 &&
                              <Box className='no--result--found--text'>
                                <img src={require('../../assets/images/search/no_data_found.jpeg')} alt='No data'/>
                                <p>{sharePopupMessage.noUsersFound}</p>
                              </Box>
                            }
                          </>
                        }
                      </>
                    )}
                  </div>
                </Form>
              )}
            />
          </CommonModal>
        )}
        {/* share */}

        <VerificationModal open={this.props.subscribeError} content="before you can subscribe to any user" />
      </Layout>
    )
  }
}

const mapStateToProps = state => ({
  shareUsers: state.shareReducer && state.shareReducer.shareUsers,
  loading: state.shareReducer && state.shareReducer.isLoading,
  article: state.articleReducer && state.articleReducer.article,
  isLoading: state.articleReducer.isLoading,
  shareIsLoading: state.shareReducer.isLoading,
  isModulePageLoading: state.dashboardReducer.isModulePageLoading,
  subscribeLoader: state.articleReducer.subscribeLoader,
  subscribeError: state.articleReducer.subscribeError,
  currentUser: state.loginReducer.currentUser || {},
  adminLoginDetected: state.loginReducer?.adminLoginDetected,
  modulePageVisitSuccess: state.dashboardReducer?.modulePageVisitSuccess,
  modulePageVisitError: state.dashboardReducer?.modulePageVisitError,
  onlineUsers: state.chatReducer.onlineUsers,
  siteConfigurations: state.loginReducer.siteConfigurations,
})

const mapDispatchToProps = dispatch => ({
  getSingleArticle: data => dispatch(getSingleArticle(data)),
  clearModulePageVisit: data => dispatch(clearModulePageVisit(data)),
  pageVisit: data => dispatch(pageVisit(data)),
  addLikes: data => dispatch(addLikes(data)),
  getUserList: data => dispatch(getUserList(data)),
  subscribeToUser: data => dispatch(subscribeToUser(data)),
  setOnlineUsers: users => dispatch({ type: SET_ONLINE_USERS, payload: users }),
  clear_subscribeToUser: () => dispatch(clear_subscribeToUser()),
})

export default connect(mapStateToProps, mapDispatchToProps)(ArticleInner)
