import { Trip } from '../../components'

export const LookUp = `{
    id
    alias
    lookUpName
    icon {
        id
        type
        url
        smallUrl
        largeUrl
    }
  }`

export const Media = `{
    id
    url
    largeUrl
    name
}`

export const PaymentMethodTypes = `{
    id
    name
    icon ${Media}
  }`

export const roleWithoutType = `{
    id
    role
    aliasName
}`

export const ImageType = `{
    id
    url
    largeUrl
}`

export const TypeCrud = `{
    id
    name
    icon ${Media}
    # createdAt
    # updatedAt
}`

export const ServiceProvide = `{
    id
    name
    icon ${Media}
    thumbnailIcon ${Media}
}`

export const Role = `{
    id
    role
    type
    aliasName
}`

export const SuccessResponse = `{
    id
    message
    statusCode
}`

export const GeometricLocation = `{
    type
    coordinates
}`

export const Like = `{
    id
    user {
        id
    }
    sessionId
    moduleId
    likeFlag
    likeModule
}`

export const PageVisit = `{
    id
    module
    sessionId
    type
}`

export const DocumentVerification = `{
    emailVerified
    mobileVerified
    commercialLicenceVerified
    commercialLicenceRejected
    governmentIdVerified
    governmentIdRejected
}`

export const AddressType = `{
    type
    address1
    address2
    street
    city
    state
    country
    zip
    geometricLocation ${GeometricLocation}
}`

export const AdminUser = `{
    address ${AddressType}
    like
    title
    firstName
    lastName
    shortDescription
    companyWebsite
    mobileNumber
    language
    companyName
    token
    id
    dealerRequests {
        dealer {
          id
          isVerified
          firstName
          lastName
          address ${AddressType}
          email
          mobileNumber
          companyName
          companyLogo ${ImageType}
          image ${ImageType}
        }
        accepted
        status
      }
    email
    isVerified
    accountActivated
    documentVerification ${DocumentVerification}
    companyLogo ${ImageType}
    provider ${LookUp}
    profession ${LookUp}

    role ${Role}
    image ${ImageType}
    governmentId ${ImageType}
    commercialLicence ${ImageType}
    reviews{
        reviews{
            rating
            reviews
            createdAt
        }
        ratings{
            averageRating
            count
        }
    }


}`

export const SalesEngineUser = `{
    address ${AddressType}
    like
    title
    firstName
    lastName
    shortDescription
    companyWebsite
    mobileNumber
    language
    companyName
    token
    id
    email
    isVerified
    accountActivated
    documentVerification ${DocumentVerification}
    companyLogo ${ImageType}
    provider ${LookUp}
    profession ${LookUp}

    role ${Role}
    image ${ImageType}
    reviews{
        reviews{
            rating
            reviews
            createdAt
        }
        ratings{
            averageRating
            count
        }
    }
}`

export const RatingReview = `{
    averageRating {
        _id
        averageRating
        count
    }
    ratings {
        _id
        averageRating
        count
    }
    reviews {
        id
        moduleId {
            _id
        }
        subModuleId
        reviews
        rating
        reviewStatus
        reviewWithTypes {
            type
            rating
        }
        user {
            _id
            id
            email
            firstName
            lastName
            image ${ImageType}
            createdAt
            address ${AddressType}
            role{
                aliasName
                id
                role
            }
        }
        createdAt
    }
}`

export const SellerUser = `{
    createdAt
    address ${AddressType}
    like
    title
    firstName
    lastName
    shortDescription
    companyWebsite
    mobileNumber
    language
    companyName
    companyLogo ${ImageType}
    token
    id
    email
    language

    isVerified
    accountActivated
    documentVerification ${DocumentVerification}

    provider ${LookUp}

    role ${Role}
    image ${ImageType}
    governmentId ${ImageType}
    paymentMethodTypes ${PaymentMethodTypes}
    commercialLicence ${ImageType}
    reviews{
        reviews{
            user ${AdminUser}
            rating
            reviews
            createdAt
        }
        ratings{
            averageRating
            count
        }
    }
}`

export const Boat = `{
    id
    adId
    address ${AddressType}
    adStatus
    featureStatus
    bestDealStatus
    mustBuyStatus
    status
    boatName
    listedBy
    price
    yearBuilt
    description
    manufacturedBy ${LookUp}
    trailer
    engineManufacturer
    engineHp
    images ${ImageType}
    layout ${ImageType}
    video
    isTaxEnabled
    tax
    reviews ${RatingReview}
    boatType ${TypeCrud}
    boatStatus ${LookUp}
    boatParking ${LookUp}
    seller ${AdminUser}
    rating
    createdAt
    lengthInFt
    isSalesEngineCreated
    isAuctionRoomCreated
    auctionRoom {
        status
    }
    auctionApproved
    auctionDeclined
    winnerAssigned
    auctionRoomWinner {
        id
        firstName
        email
    }
}`

export const BoatCardResponse = `{
    id
    adId
    adStatus
    address ${AddressType}
    boatName
    price
    yearBuilt
    description
    kind ${LookUp}
    kayakType ${LookUp}
    manufacturedBy ${LookUp}
    engineHp
    images ${Media}
    boatType ${TypeCrud}
    boatStatus ${LookUp}
    seller {
        id
        role ${Role}
        companyName
    }
    rating
    lengthInFt
    isSalesEngineCreated
    createdAt
}`

export const RecentServicesInner = `{
    address ${AddressType}

    image ${ImageType}
    id
    role ${Role}
    like
    createdAt
    title
    provider ${LookUp}
    firstName
    lastName
    companyWebsite
    mobileNumber
    paymentMethodTypes ${PaymentMethodTypes}
    companyLogo ${ImageType}
    companyName
    isVerified
    reviews ${RatingReview}
}`

export const ListingYachtUser = `{
    address ${AddressType}

    image ${ImageType}
    id
    role ${Role}
    like
    createdAt
    title
    provider ${LookUp}
    firstName
    lastName
    companyWebsite
    mobileNumber
    companyLogo ${ImageType}
    companyName
}`

export const YachtListing = `{
    address ${AddressType}
    id
    service
    teamCommitment
    rating
    images ${ImageType}
    ratedReviews ${RatingReview}
    serviceProvide ${TypeCrud}
    user ${ListingYachtUser}
    createdAt
    pageVisits ${PageVisit}
  }`

export const Yacht = `{

    address ${AddressType}


    id
    service
    featuresAndAdvantages
    teamCommitment
    qualityOfService
    youtubeUrl
    adId

    status
    adStatus

    pageVisits ${PageVisit}
    likes ${Like}
    rating

    images ${ImageType}
    ratedReviews ${RatingReview}

    serviceProvide ${ServiceProvide}
    taggedServiceProvide ${ServiceProvide}

    user ${RecentServicesInner}

    createdAt
  }`

export const Marina = `{

    address ${AddressType}

    _id
    noneMembersRate
    membersRate


    id
    serviceDescription
    membershipBenefits
    memberEvents
    promotionRunning
    exclusiveFacilities
    membersReview
    newsEvents
    berth
    name
    facilities
    storage
    adId

    rating
    pageVisits ${PageVisit}

    accountStatus
    adStatus

    images ${ImageType}

    reviews ${RatingReview}

    provider ${LookUp}
    serviceProvide ${TypeCrud}

    user ${AdminUser}

    createdAt

  }`

export const TripDetailsType = `{
    _id
    id
    type
    startDate
    startTime
    endDate
    endTime
    price
    total
    available
    expired
}`

export const RentBookingType = `{
    id
    boat {
        id
        adId
        model
        trip {
            id
            alias
        }
        tripType {
            id
            alias
        }
        tripDetails {
            id
            type
            startDate
            startTime
            endDate
            endTime
            price
            total
            available
        }
        user {
            id
            firstName
            lastName
            role {
                id
                role
                aliasName
            }
        }
    }
    customer {
        id
        firstName
        lastName
    }
    slot
    units
    status
    bookedAt
    isQRScanned
    isCancelled
    isDeleted
    reviews ${RatingReview}
}`

export const RentType = `{
    id
    alias
    tripId {
        id
        alias
    }
}`

export const BoatRent = `{
    id
    adId
    address ${AddressType}
    adStatus
    available
    boatRentStatus
    total
    captainName
    images ${ImageType}
    boatLayout ${ImageType}
    checkLocationFlag
    trip ${RentType}
    tripType ${RentType}
    endDate
    endTime
    engine
    tripDuration
    boatLength
    bedroomAndCabins
    yearOfManufacturer
    maximumGuest
    manufacturer
    model
    bathrooms
    seatsOnBoat
    deckAndEntertainment {
        id
        alias
    }
    whatToBring
    price
    descriptionOfOurTrip
    policy
    deposit {
        id
        alias
    }
    costOfDeposit
    createdAt
    youtubeLinkVideo
    tripDetails ${TripDetailsType}
    rating
    bookings {
        id
        ticketId
        slot
        units
        status
        bookedAt
        isCancelled
        customer {
            _id
            id
            firstName
            lastName
            companyName
        }
        payment {
            _id
            paymentIntentId
        }
    }
    user {
        id
        firstName
        lastName
        companyName
        role {
        id
        role
        }
    }
}`

export const BoatShow = `{
    address ${AddressType}

    adId

    id
    title
    showDescription
    boatshowImages ${Media}

    user {
        id
        firstName
        lastName
        email
    }

    createdAt
    startDate
    endDate
    meta_tags
}`

export const BoatShowListing = `{
    address ${AddressType}

    adId

    id
    title
    showDescription
    boatshowImages ${Media}

    createdAt
    startDate
    endDate

}`

export const RoleStatus = `{
    beforeStatus
    currentStatus
}`

export const SalesEngineStatusTracking = `{
    buyer ${RoleStatus}
    seller ${RoleStatus}
    surveyor ${RoleStatus}
    shipper ${RoleStatus}
}`

export const Branch = `{
    id
    address ${AddressType}

    branchName
    contactName
    mobileNumber
    emailAddress
    position ${LookUp}
    image ${Media}

    branchStatus
    branchVerificationStatus

    user ${AdminUser}
    pricePerFt
    createdAt
}`

export const SalesEngine = `{
    id
    boat ${Boat}

    buyer ${AdminUser}
    seller ${AdminUser}
    agent ${AdminUser}
    surveyor ${Branch}
    shipper ${AdminUser}

    isSurveyorSkip
    surveyorAccepted
    surveyorPayment
    surveyorReport
    buyerAgreement
    sellerAgreement
    isShipperSkip
    selectedShipper
    shipperPayment
    auditorAccepted
    accountantAccepted

    buySellProcess

    requestedSurveyor
    surveyDocument ${ImageType}
    approvedSurveyors

    shipmentLocation {
        address
        city
        country
        geometricLocation ${GeometricLocation}
        placeName
        postalCode
        route
        state
    }
    shipperRequested {
        price
        proposalDocument ${ImageType}
        shipper ${AdminUser}
    }
    documentUploadedShippers

    createdAt
    surveyorRequestAccept
    surveySubmitted

    negotiatedBoatPrice
    agreementDocument {
        url
    }
    agreement {
        buyerId
        sellerId
    }
    status ${SalesEngineStatusTracking}
}`

export const Surveyor = `{
    address ${AddressType}
    id
    contactName
    emailAddress
    position ${LookUp}
    distance
    pricePerFt
    branchName
    branchStatus
    branchVerificationStatus

    mobileNumber
    isMainBranch
    image ${Media}

    user ${SalesEngineUser}
    createdAt
}`

export const CreateSalesEngine = `{
    id
    isSold
    stockQuantity
    distance
    boatPayment
    salesEngineStatus
    shipperAddedDocument {
        id
    }
    buyerPaymentDueTime
    surveyorPaymentDueTime
    isPaymentDue
    paymentInformation {
        unitBoatPrice
        subTotalPrice
        totalPrice
        serviceFees
        paymentTransferFee
        surveyorSubtotalPrice
        surveyorTotalPrice
        shipperSubtotalPrice
        shipperTotalPrice
        surveyorPaymentTransferFee
        shipperPaymentTransferFee
    }
    qrCode
    shipmentPaymentDate
    isAuctionSalesEngine
    boat {
        id
        adId
        address ${AddressType}
        stockQuantity
        boatName
        listedBy
        price
        yearBuilt
        description
        manufacturedBy ${LookUp}
        trailer
        engineManufacturer
        engineHp
        images ${ImageType}
        layout ${ImageType}
        isTaxEnabled
        ownershipAttachment ${Media}
        tax
        reviews ${RatingReview}
        boatType {
            name
        }
        kayakType ${LookUp}
        kind ${LookUp}
        boatStatus ${LookUp}
        boatParking ${LookUp}
        seller ${SalesEngineUser}
        isAuctionRoomCreated
        rating
        createdAt
        lengthInFt
        distance
        auctionRoom {
            status
        }
        auctionApproved
        auctionDeclined
        winnerAssigned
        auctionRoomWinner {
            id
            firstName
            email
        }
        minPurchaseQuantity
    }
    surveyorExceedUploadLimit
    buyer ${SalesEngineUser}
    seller ${SalesEngineUser}
    agent ${SalesEngineUser}
    shipper ${SalesEngineUser}
    surveyor ${Surveyor}

    isSurveyorSkip
    surveyorAccepted
    surveyorPayment
    surveyorReport
    buyerAgreement
    sellerAgreement
    isShipperSkip
    selectedShipper
    shipperPayment
    auditorAccepted
    accountantAccepted
    agentRequested
    surveyorReviewAdded

    isViewedByAgent
    isViewedBySurveyor
    isViewedByShipper

    buyerReviewSurveyReport

    buySellProcess

    agentRequestDate
    requestedSurveyor
    surveyDocument {
        id
    }
    approvedSurveyors
    surveyorRequestDetails
    shipmentLocation {
        address
        city
        country
        geometricLocation ${GeometricLocation}
        placeName
        postalCode
        route
        state
        date
    }
    shipperRequested {
        price
        proposalDocument ${ImageType}
        shipper ${SalesEngineUser}
    }
    documentUploadedShippers

    createdAt
    surveyorRequestAccept
    surveySubmitted
    boatPayment
    negotiatedBoatPrice
    agreementDocument ${Media}
    agreement {
        buyerId
        sellerId
    }
    status ${SalesEngineStatusTracking}
    salesEngineInProcessWithPayment
    surveyorPaymentDueTime
    stepperInfo {
        totalSteps
        activeStep {
            step
            name
            isActivated
        }
        previousStep {
            step
            name
            isActivated
        }
        nextStep {
            step
            name
            isActivated
        }
        salesEngineSteps {
            step
            name
            isActivated
        }
    }
}`

export const creditPayment = `{
    cardHolderName
    payWith
    expirationMonth
    expirationYear
    cardNumber
    securityCode
    type
    user {
      firstName
      lastName
    }
}`

export const SalesEngineAgreement = `{
    id
    title
    content
    createdAt
}`

export const SalesEngineAssignShipper = `{
    id
    shipper{
        address ${AddressType}
        firstName
        lastName
        mobileNumber
    }
    selectedShipper
    status{
        buyer{
            beforeStatus
            currentStatus
        }
        seller{
            beforeStatus
            currentStatus
        }
        surveyor{
            beforeStatus
            currentStatus
        }
        shipper{
            beforeStatus
            currentStatus
        }
    }
}`

export const SurveyorReport = `{
    id
    report
    boatVerifications
    otherDocument
    video
    images ${ImageType}
    salesEngineId ${SalesEngine}
    surveyorId ${AdminUser}
}`

export const UserPaymentInformation = `{
    id
    holderName
    bankName
    branchName
    branchAddress
    fullAddress
    accountNumber
    transitCode
    institutionNumber
    swiftCode
}`

export const TransactionDetail = `{
    id
    senderId {
        id
        firstName
        lastName
    }
    amount
    is_release_amount_paid
    paymentId
    receiverId {
        id
        firstName
        lastName
        role {
            aliasName
            role
        }
    }
    #paymentId
    transactionType
    externalId
    externalModuleType
    salesEngineId {
        id
        buyer {
            firstName
            lastName
        }
        seller {
            firstName
            lastName
        }
        boat {
            id
            yearBuilt
            boatType {
                id
                name
            }
            manufacturedBy ${LookUp}
            engineHp
            seller {
                id
                companyName
                role {
                    aliasName
                }
            }
        }
    }
    status
    createdAt
    updatedAt
    meta
}`

export const WishlistResponse = `{
    id
    user
    moduleId {
        id
    }
    moduleType
    totalCounts
    sessionId
    createdAt
}`

export const RelatedItem = `{
    id
    showDescription
    boatName
    manufacturedBy
    description
    video
    address ${AddressType}
    yearBuilt
    rating
    price
    lengthInFt
    images ${ImageType}
    engineHp
    boatStatus ${LookUp}
    boatType ${TypeCrud}
    user ${AdminUser}
    seller ${AdminUser}
    startTime
    endTime
    maximumGuest
    tripDuration
    boatLength
    tripType ${RentType}
    total
    serviceProvide ${TypeCrud}
    membersReview
    provider ${LookUp}
    descriptionOfOurTrip
    facilities

    boatshowImages ${Media}
    title
    startDate
    endDate
    showDescription

    service
    # serviceDescription
}`

export const yachtWishlists = `{
    id
    user
    moduleType
    sessionId
    moduleId {
        id
        service
        adStatus
        address ${AddressType}
        rating
        images ${ImageType}
        service
        serviceProvide ${TypeCrud}
        user {
            image ${ImageType}
            firstName
            lastName
            companyName
            companyLogo ${ImageType}
        }
    }
}`

export const boatWishlists = ` {
    id
    user
    moduleType
    sessionId
    moduleId {
      id
      description
      adStatus
      address ${AddressType}
      manufacturedBy ${LookUp}
      seller {
          role{
            aliasName
            role
          }
          companyName
      }
      boatType{
          name
          icon ${Media}
      }
      price
      lengthInFt
      yearBuilt
      images ${ImageType}
      rating
    }
  }
`
export const marinaWishlists = `{
    id
    user
    moduleType
    sessionId
    moduleId {
      id
      serviceDescription
      address ${AddressType}
      images ${ImageType}
      facilities
      rating
      provider ${LookUp}
      serviceProvide ${TypeCrud}
      adStatus
      name
      user{
        image ${ImageType}
        firstName
        lastName
        companyName
        companyLogo ${ImageType}
      }
    }
  }
`

export const rentWishlists = `{
    id
    user
    moduleType
    sessionId
    moduleId {
      id
      descriptionOfOurTrip
      address ${AddressType}
      rating
      images ${ImageType}
      price
      model
      tripType {
        id
        alias
        icon ${ImageType}
      }
      trip {
        id
        alias
      }
      tripDetails ${TripDetailsType}
    }
}`

export const boatShowWishlists = `{
    id
    user
    moduleType
    sessionId
    moduleId {
        id
        title
        address ${AddressType}
        boatshowImages ${Media}
        startDate
        endDate
        showDescription
        adStatus
        user  {
            firstName
            lastName
            id
        }
    }
  }
`

export const BrokerType = `
{
        id
    createdAt
    companyName
    firstName
    user {
        id
        firstName
        lastName
    }
    lastName
    contactNumber
    email
    position
    companyLogo
}
`
export const SurveyorUserForSalesEngine = `{
    address ${AddressType}
    id
    contactName
    emailAddress
    position
    distance
    pricePerFt

    branchStatus
    branchVerificationStatus

    mobileNumber

    user {
        id
        image ${ImageType}
        firstName
        lastName
        email
        mobileNumber
        role{
            id
            aliasName
            role
            type
        }
        image ${ImageType}
    }
    # createdAt
}`

export const MarinaCardResponse = `{
    id
    address ${AddressType}
    provider ${LookUp}
    serviceProvide ${TypeCrud}
    images ${ImageType}
    adId
    rating
    facilities
    adStatus
    status
    name
    user {
        id
        companyName
    }
  }`

export const ManageBoatShow = `{
    address ${AddressType}
    adId
    id
    title
    createdAt
    startDate
    endDate
    adStatus
    status
}`

export const dealerUserSchema = `{
    id
    firstName
    image ${ImageType}
    companyLogo ${ImageType}
    lastName
    address ${AddressType}
    email
    mobileNumber
    isVerified
    companyName
    dealerRequests {
        accepted
        status
        dealer {
            id
            isVerified
        }
    }
}`
