import { useCallback, useState } from 'react'
import { enqueueSnackbar } from 'notistack'
import { savePost as qSavePost } from '../../lib/queries'
import { useAuth } from '../../contexts/auth-ctx'
import { useLoginModal } from '../../contexts/login-modal-ctx'
import { useSMDb } from '../../contexts/smdb-ctx'

export default function SaveBtn({ postId, ...props }) {
  const auth = useAuth()
  const loginModal = useLoginModal()
  const [smdbGet, smdbSet] = useSMDb()

  const [saveLoading, setSaveLoading] = useState(false)

  const savePost = useCallback(async () => {
    setSaveLoading(true)

    try {
      const res = await qSavePost(postId)
      smdbSet(postId, 'saved', res.saved)
    } catch (er) {
      enqueueSnackbar(er)
    }

    setSaveLoading(false)
  }, [postId])

  return (
    <button
      {...props}
      onClick={async e => {
        props.onClick?.(e)
        auth.isLoggedIn ? savePost(postId) : loginModal.show()
      }}
      disabled={saveLoading ?? props.disabled}
    >
      {props.children || (smdbGet(postId, 'saved') ? 'Saved' : 'Save')}
    </button>
  )
}
