import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Row, Col } from 'react-bootstrap'

import { FaEye } from 'react-icons/fa'
import {
  logout,
  sendConfirmationLink,
  clearForgotPassword,
  getDashBoardCount,
  clearAuthorizationFlag,
  getAllWishlists,
  onMenuItemClick,
  emailVerificationCheck,
  closeEmailVerificationModal,
  isVisitFirstTimeForEmail,
  clearDashboardUnauthorizedAccessFlag,
  getServiceDetail,
  toggleYachtServiceStatus,
} from '../../redux/actions'

import {
  dashboardTabs,
  userRoles,
  salesEngineAccessibleTypes,
  activationType,
  auctionRoomAccessibleRoles,
  requiredProfileUpdate,
  urlsWithoutPermissions,
  myStoreIdAccessibleRoles,
} from '../../util/enums/enums'
import { SuccessNotify, InfoNotify } from '../../helpers/notification'
import { DashboardLayout } from '../../components/layout/dashboardLayout'
import './dashboard.scss'
import '../../styles/dashboardResponsive.scss'
import { getUserProfileCount } from '../../helpers/jsxHelper'
import EmailVerificationDialog from '../../components/dialog/EmailVerificationDialog'
import ConfirmationEmail from '../../components/modal/confirmationEmail'
import { getLocalInfo, getLocalStorageItem } from '../../helpers/storageHelper'
import { Loader, Field } from '../../components'
import { Switch } from '@material-ui/core'
import { viewServiceBoatHandler } from '../../helpers/boatHelper'
import { ButtonComponent } from '../../components/form/Button'
import { get } from 'lodash'
import { CommonTooltip } from '../../components/CommonTooltip'
import { getId } from '../../util/utilFunctions'

class Dashboard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      openDialog: false,
      serviceStatusFlage: true,
    }
  }

  componentDidMount() {
    const { getServiceDetail, currentUser, getDashBoardCount, clearAuthFlag, getAllWishlists } = this.props

    clearAuthFlag()
    getAllWishlists()
    getDashBoardCount()

    if (get(currentUser, 'role.aliasName', '') === userRoles.SERVICE_AND_MAINTENANCE) {
      getServiceDetail()
    }

    const g = this.props.location.state?.showFirstTimeGuide

    if (g) {
      setTimeout(() => {
        document.getElementById(`showFirstTimeGuide-content-${g}`)?.scrollIntoView({ block: 'center' })
      }, 200);
    }
  }

  componentDidUpdate(prevProps) {
    const { clearDashboardUnauthorizedAccessFlag } = prevProps
    const { unauthorizedAccess, unauthorizedAccessMessage, currentUser } = this.props

    if (unauthorizedAccess) {
      clearDashboardUnauthorizedAccessFlag()
      SuccessNotify(unauthorizedAccessMessage)
    }

    if (
      get(this.props, 'currentUser.role.aliasName', '') !== get(prevProps, 'currentUser.role.aliasName', '') &&
      get(this.props, 'currentUser.role.aliasName', '') === userRoles.SERVICE_AND_MAINTENANCE
    ) {
      this.props.getServiceDetail()
    }

    if (get(currentUser, 'role.aliasName', '') && typeof this.state.firstTimeGuide4Enabled === "undefined") {
      this.setState({
        firstTimeGuide3Enabled: currentUser.role.aliasName !== userRoles.MEMBER,
        firstTimeGuide4Enabled: [
          userRoles.BOAT_OWNER,
          userRoles.BROKER_AND_DEALER,
          userRoles.BOAT_MANUFACTURER,
          userRoles.MARINA_AND_STORAGE,
          userRoles.RENT_AND_CHARTER
        ].includes(currentUser.role.aliasName)
      })
    }
  }

  checkCount = value => {
    return typeof +value === 'number' && !isNaN(+value) ? value : <i class="fas fa-ellipsis-h" style={{ color: 'rgba(0, 0, 0, 0.2)' }}></i>
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    const { forgotPasswordSuccess, clearForgotPassword, dashboardCount, currentUser } = nextProps
    const { serviceStatusFlage } = prevState
    const { role } = currentUser

    if (forgotPasswordSuccess) {
      clearForgotPassword()
      SuccessNotify('Password Changed Successfully')
    }
    if (dashboardCount?.serviceStatus && serviceStatusFlage && role?.role === 'SERVICE & MAINTENANCE') {
      InfoNotify('Waiting for approval')
      return {
        serviceStatusFlage: false,
      }
    }
    return null
  }

  redirectionHandler = url => {
    const {
      boats,
      branch,
      buyItNow,
      articles,
      boatRents,
      services,
      marinaAndStorage,
      salesEngine,
      salesEngineArchive,
      auctionRooms,
      boatShows,
      auctionRoomBidList,
      brokers,
      logOut,
      changePassword,
      userProfile,
      myDeals,
    } = dashboardTabs
    const { currentUser, history, dashBoardSideBarData, onMenuItemClick } = this.props
    const { documentVerification } = currentUser

    const urls = [
      boats.url,
      branch.url,
      articles.url,
      buyItNow.url,
      boatRents.url,
      services.url,
      marinaAndStorage.url,
      salesEngine.url,
      salesEngineArchive.url,
      auctionRooms.url,
      boatShows.url,
      auctionRoomBidList.url,
      myDeals.url,
      //  brokers.url
    ]

    const newData = dashBoardSideBarData.map((data, index) => {
      if (data.url === url) {
        data.selected = true
        return data
      } else {
        data.selected = false
        return data
      }
    })

    onMenuItemClick(newData)

    if (currentUser.isProfilecompleted || urlsWithoutPermissions.includes(url)) {
      history.push(`/${url}`)
    } else {
      SuccessNotify(requiredProfileUpdate)
    }
  }

  renderMyRequestCard = roleType => {
    const { myRequests } = dashboardTabs

    const { salesEngineMyRequestCount: myRequestsCount } = this.props.dashboardCount

    return (
      myStoreIdAccessibleRoles.includes(roleType) && (
        <Col sm={4} className="cursor-pointer mb-4 dashboard-box-div-spacing">
          <div className="dashboard-box-div">
            <div
              className="d-flex align-items-center dashboard-right-div-content p-4"
              onClick={() => this.redirectionHandler(myRequests.url)}
            >
              <div className="dashboard-div-icon mr-4 d-flex">
                <img loading="lazy" src={require('./image/My Request.svg')} alt="Loading..." />
              </div>
              <div className="d-flex flex-column dashboard-right-div-data">
                <h5 className="dashboard-count">{this.checkCount(myRequestsCount)}</h5>
                <span className="font-weight-400 title-text dashboard-market-title">
                  {myRequests.title}
                </span>
              </div>
            </div>
          </div>
        </Col>
      )
    )
  }

  renderRoleWiseCards = roleType => {
    const { boats, buyItNow, branch, brokers, boatRents, services, marinaAndStorage, manufacturerRequest, myDeals } =
      dashboardTabs

    const {
      MEMBER,
      BOAT_OWNER,
      BROKER_AND_DEALER,
      BOAT_MANUFACTURER,
      YACHT_SHIPPER,
      SURVEYOR,
      RENT_AND_CHARTER,
      SERVICE_AND_MAINTENANCE,
      MARINA_AND_STORAGE,
      AGENT,
    } = userRoles
    const {
      surveyorBranches: surveyorBranchesCount,
      auctionRoom: auctionCount,
      brokerCount,
      boatShow: boatshowCount,
      salesEngineBuyItNowCount,
      salesEngineArchiveCount,
      manageHomeAdvertisement: advertisementsCount,
      boat: boatsCount,
      surveyor: surveyorCount,
      boatRent: boatRentsCount,
      marina: marinaCount,
      salesEngine: salesEngineCount,
      salesEngineMyRequestCount: myRequestsCount,
      salesEngineAgentCount,
      manufacturerCounts,
      dealersDeals,
    } = this.props.dashboardCount
    const { serviceDetailForDashboard, toggleYachtServiceStatus } = this.props

    switch (roleType) {
      // case MEMBER:
      //   return (
      //     <>
      //       <Col sm={4} className="cursor-pointer mb-4 dashboard-box-div-spacing">
      //         <div className="dashboard-box-div">
      //           <div className="d-flex align-items-center dashboard-right-div-content p-4"
      //             onClick={() => this.redirectionHandler(buyItNow.url)}>
      //             <div className="dashboard-div-icon mr-4 d-flex">
      //               <img loading="lazy" src={require('./image/buy.png')} alt="Loading..." />
      //             </div>
      //             <div className="d-flex flex-column dashboard-right-div-data">
      //               <h5 className="dashboard-count">{this.checkCount(salesEngineBuyItNowCount)}</h5>
      //               <span className="font-weight-400 title-text dashboard-market-title">
      //                 {buyItNow.title}
      //               </span>
      //             </div>
      //           </div>
      //         </div>
      //       </Col>
      //     </>
      //   );

      case BOAT_OWNER:
        return (
          <>
            <Col sm={4} className="cursor-pointer mb-4 dashboard-box-div-spacing">
              <div className="dashboard-box-div">
                <CommonTooltip
                  className="common-tooltip-guide"
                  key={this.props.history.location.state?.showFirstTimeGuide}
                  isOpen={this.props.history.location.state?.showFirstTimeGuide === 4}
                  clickable
                  closeOnScroll={false}
                  closeOnResize={false}
                  content={(
                    <div id="showFirstTimeGuide-content-4">
                      <div style={{ textAlign: 'center' }}>Add and manage your listing from here.</div>
                      <div style={{ textAlign: 'center' }}>
                        <button
                          className="btn common-tooltip-button"
                          onClick={() => {
                            this.props.history.replace({ ...this.props.history.location, state: { showFirstTimeGuide: 5 } })
                            setTimeout(() => {
                              document.getElementById(`showFirstTimeGuide-content-5`)?.scrollIntoView({ block: 'center' })
                            }, 200);
                          }}
                        >Got it</button>
                      </div>
                    </div>
                  )}
                  component={ctProps => (
                    <div
                      data-tooltip-id={ctProps.id}
                      data-tooltip-place="top-start"
                      className="d-flex align-items-center dashboard-right-div-content p-4"
                      onClick={() => this.redirectionHandler(boats.url)}
                    >
                      <div className="dashboard-div-icon mr-4 d-flex">
                        <img loading="lazy" src={require('./image/Manage Boat.svg')} alt="Loading..." />
                      </div>
                      <div className="d-flex flex-column dashboard-right-div-data">
                        <h5 className="dashboard-count">{this.checkCount(boatsCount)}</h5>
                        <span className="font-weight-400 title-text dashboard-market-title">{boats.title}</span>
                      </div>
                    </div>
                  )}
                />
              </div>
            </Col>
          </>
        )

      case BROKER_AND_DEALER:
        return (
          <>
            <Col sm={4} className="cursor-pointer mb-4 dashboard-box-div-spacing">
              <div className="dashboard-box-div">
                <CommonTooltip
                  className="common-tooltip-guide"
                  key={this.props.history.location.state?.showFirstTimeGuide}
                  isOpen={this.props.history.location.state?.showFirstTimeGuide === 4}
                  clickable
                  closeOnScroll={false}
                  closeOnResize={false}
                  content={(
                    <div id="showFirstTimeGuide-content-4">
                      <div style={{ textAlign: 'center' }}>Add and manage your listing from here.</div>
                      <div style={{ textAlign: 'center' }}>
                        <button
                          className="btn common-tooltip-button"
                          onClick={() => {
                            this.props.history.replace({ ...this.props.history.location, state: { showFirstTimeGuide: 5 } })
                            setTimeout(() => {
                              document.getElementById(`showFirstTimeGuide-content-5`)?.scrollIntoView({ block: 'center' })
                            }, 200);
                          }}
                        >Got it</button>
                      </div>
                    </div>
                  )}
                  component={ctProps => (
                    <div
                      data-tooltip-id={ctProps.id}
                      data-tooltip-place="top-start"
                      className="d-flex align-items-center dashboard-right-div-content p-4"
                      onClick={() => this.redirectionHandler(boats.url)}
                    >
                      <div className="dashboard-div-icon mr-4 d-flex">
                        <img loading="lazy" src={require('./image/Manage Boat.svg')} alt="Loading..." />
                      </div>
                      <div className="d-flex flex-column dashboard-right-div-data">
                        <h5 className="dashboard-count">{this.checkCount(boatsCount)}</h5>
                        <span className="font-weight-400 title-text dashboard-market-title">{boats.title}</span>
                      </div>
                    </div>
                  )}
                />
              </div>
            </Col>
            <Col sm={4} className="cursor-pointer mb-4 dashboard-box-div-spacing">
              <div className="dashboard-box-div">
                <div
                  className="d-flex align-items-center dashboard-right-div-content p-4"
                  onClick={() => this.redirectionHandler(manufacturerRequest.url)}
                >
                  <div className="dashboard-div-icon mr-4 d-flex">
                    <img loading="lazy" src={require('./image/Request Manufact.svg')} alt="Loading..." />
                  </div>
                  <div className="d-flex flex-column dashboard-right-div-data">
                    <h5 className="dashboard-count">{this.checkCount(manufacturerCounts)}</h5>
                    <span className="font-weight-400 title-text dashboard-market-title">
                      {manufacturerRequest.title}
                    </span>
                  </div>
                </div>
              </div>
            </Col>
            <Col sm={4} className="cursor-pointer mb-4 dashboard-box-div-spacing">
              <div className="dashboard-box-div">
                <div
                  className="d-flex align-items-center dashboard-right-div-content p-4"
                  onClick={() => this.redirectionHandler(myDeals.url)}
                >
                  <div className="dashboard-div-icon mr-4 d-flex">
                    <img loading="lazy" src={require('./image/My Deals.svg')} alt="Loading..." />
                  </div>
                  <div className="d-flex flex-column dashboard-right-div-data">
                    <h5 className="dashboard-count">{this.checkCount(dealersDeals)}</h5>
                    <span className="font-weight-400 title-text dashboard-market-title">
                      {myDeals.title}
                    </span>
                  </div>
                </div>
              </div>
            </Col>
          </>
        )

      case BOAT_MANUFACTURER:
        return (
          <>
            <Col sm={4} className="cursor-pointer mb-4 dashboard-box-div-spacing">
              <div className="dashboard-box-div">
                <CommonTooltip
                  className="common-tooltip-guide"
                  key={this.props.history.location.state?.showFirstTimeGuide}
                  isOpen={this.props.history.location.state?.showFirstTimeGuide === 4}
                  clickable
                  closeOnScroll={false}
                  closeOnResize={false}
                  content={(
                    <div id="showFirstTimeGuide-content-4">
                      <div style={{ textAlign: 'center' }}>Add and manage your listing from here.</div>
                      <div style={{ textAlign: 'center' }}>
                        <button
                          className="btn common-tooltip-button"
                          onClick={() => {
                            this.props.history.replace({ ...this.props.history.location, state: { showFirstTimeGuide: 5 } })
                            setTimeout(() => {
                              document.getElementById(`showFirstTimeGuide-content-5`)?.scrollIntoView({ block: 'center' })
                            }, 200);
                          }}
                        >Got it</button>
                      </div>
                    </div>
                  )}
                  component={ctProps => (
                    <div
                      data-tooltip-id={ctProps.id}
                      data-tooltip-place="top-start"
                      className="d-flex align-items-center dashboard-right-div-content p-4"
                      onClick={() => this.redirectionHandler(boats.url)}
                    >
                      <div className="dashboard-div-icon mr-4 d-flex">
                        <img loading="lazy" src={require('./image/Manage Boat.svg')} alt="Loading..." />
                      </div>
                      <div className="d-flex flex-column dashboard-right-div-data">
                        <h5 className="dashboard-count">{this.checkCount(boatsCount)}</h5>
                        <span className="font-weight-400 title-text dashboard-market-title">{boats.title}</span>
                      </div>
                    </div>
                  )}
                />
              </div>
            </Col>

            <Col sm={4} className="cursor-pointer mb-4 dashboard-box-div-spacing">
              <div className="dashboard-box-div">
                <div
                  className="d-flex align-items-center dashboard-right-div-content p-4"
                  onClick={() => this.redirectionHandler(brokers.url)}
                >
                  <div className="dashboard-div-icon mr-4 d-flex">
                    <img loading="lazy" src={require('./image/Manage Boat.svg')} alt="Loading..." />
                  </div>
                  <div className="d-flex flex-column dashboard-right-div-data">
                    <h5 className="dashboard-count">{this.checkCount(brokerCount)}</h5>
                    <span className="font-weight-400 title-text dashboard-market-title">
                      {brokers.title}
                    </span>
                  </div>
                </div>
              </div>
            </Col>
          </>
        )

      case SURVEYOR:
        return (
          <>
            <Col sm={4} className="cursor-pointer mb-4 dashboard-box-div-spacing">
              <div className="dashboard-box-div">
                <div
                  className="d-flex align-items-center dashboard-right-div-content p-4"
                  onClick={() => this.redirectionHandler(branch.url)}
                >
                  <div className="dashboard-div-icon mr-4 d-flex">
                    <img loading="lazy" src={require('./image/Manage Boat.svg')} alt="Loading..." />
                  </div>
                  <div className="d-flex flex-column dashboard-right-div-data">
                    <h5 className="dashboard-count">{this.checkCount(surveyorBranchesCount)}</h5>
                    <span className="font-weight-400 title-text dashboard-market-title">
                      {branch.title}
                    </span>
                  </div>
                </div>
              </div>
            </Col>
          </>
        )

      case RENT_AND_CHARTER:
        return (
          <>
            <Col sm={4} className="cursor-pointer mb-4 dashboard-box-div-spacing">
              <div className="dashboard-box-div">
                <CommonTooltip
                  className="common-tooltip-guide"
                  key={this.props.history.location.state?.showFirstTimeGuide}
                  isOpen={this.props.history.location.state?.showFirstTimeGuide === 4}
                  clickable
                  closeOnScroll={false}
                  closeOnResize={false}
                  content={(
                    <div id="showFirstTimeGuide-content-4">
                      <div style={{ textAlign: 'center' }}>Add and manage your listing from here.</div>
                      <div style={{ textAlign: 'center' }}>
                        <button
                          className="btn common-tooltip-button"
                          onClick={() => {
                            this.props.history.replace({ ...this.props.history.location, state: { showFirstTimeGuide: 5 } })
                            setTimeout(() => {
                              document.getElementById(`showFirstTimeGuide-content-5`)?.scrollIntoView({ block: 'center' })
                            }, 200);
                          }}
                        >Got it</button>
                      </div>
                    </div>
                  )}
                  component={ctProps => (
                    <div
                      data-tooltip-id={ctProps.id}
                      data-tooltip-place="top-start"
                      className="d-flex align-items-center dashboard-right-div-content p-4"
                      onClick={() => this.redirectionHandler(boatRents.url)}
                    >
                      <div className="dashboard-div-icon mr-4 d-flex">
                        <img loading="lazy" src={require('./image/Manage Boat.svg')} alt="Loading..." />
                      </div>
                      <div className="d-flex flex-column dashboard-right-div-data">
                        <h5 className="dashboard-count">{this.checkCount(boatRentsCount)}</h5>
                        <span className="font-weight-400 title-text dashboard-market-title">
                          {boatRents.title}
                        </span>
                      </div>
                    </div>
                  )}
                />
              </div>
            </Col>
          </>
        )

      case SERVICE_AND_MAINTENANCE:
        return (
          <>
            <Col sm={4} className="mb-4 dashboard-box-div-spacing service--right-sm-0">
              <div className="dashboard-box-div">
                <CommonTooltip
                  className="common-tooltip-guide"
                  key={this.props.history.location.state?.showFirstTimeGuide}
                  isOpen={this.props.history.location.state?.showFirstTimeGuide === 4}
                  clickable
                  closeOnScroll={false}
                  closeOnResize={false}
                  content={(
                    <div id="showFirstTimeGuide-content-4">
                      <div style={{ textAlign: 'center' }}>Add and manage your listing from here.</div>
                      <div style={{ textAlign: 'center' }}>
                        <button
                          className="btn common-tooltip-button"
                          onClick={() => {
                            this.props.history.replace({ ...this.props.history.location, state: { showFirstTimeGuide: 5 } })
                            setTimeout(() => {
                              document.getElementById(`showFirstTimeGuide-content-5`)?.scrollIntoView({ block: 'center' })
                            }, 200);
                          }}
                        >Got it</button>
                      </div>
                    </div>
                  )}
                  component={ctProps => (
                    <div data-tooltip-id={ctProps.id} data-tooltip-place="top-start" className="d-flex align-items-center  dashboard-right-div-content position-relative">
                      {/* <div className="dashboard-div-icon mr-4 d-flex">
                        <img loading="lazy" src={require('./image/Manage Boat.svg')} alt="Loading..." />
                      </div> */}
                      <div className="d-flex align-center flex-row service--right-dash-div dashboard-right-div-data justify-content-center">
                        {/* <h5 className="dashboard-count">1</h5> */}
                        {/* <span className="font-weight-400 title-text dashboard-market-title">
                          {services.title}
                        </span> */}

                        <div className="d-flex justify-content-center align-items-center" style={{ flexDirection: 'column' }}>
                          <button className="btn rounded-full btn-black service-btn-lg" onClick={() => this.redirectionHandler(services.url)}>
                            {serviceDetailForDashboard?.id ? 'Update business page' : 'Create a business page'}
                          </button>

                          <button
                            className="mt-10 btn rounded-full btn-o-black"
                            color="view"
                            onClick={() => viewServiceBoatHandler(serviceDetailForDashboard)}
                          >
                            {'Preview my page'}
                          </button>

                          <div className="dashboard-service-switch-btn">
                            <Field
                              type="switch"
                              switchEnabledText="The ad is online"
                              switchDisabledText="The ad is hidden"
                              checked={serviceDetailForDashboard?.status}
                              value={serviceDetailForDashboard?.status}
                              onChange={() =>
                                toggleYachtServiceStatus({
                                  id: serviceDetailForDashboard?.id,
                                  column: 'status',
                                  value: !serviceDetailForDashboard?.status,
                                })
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                />
              </div>
            </Col>
          </>
        )

      case MARINA_AND_STORAGE:
        return (
          <>
            <Col sm={4} className="cursor-pointer mb-4 dashboard-box-div-spacing">
              <div className="dashboard-box-div">
                <CommonTooltip
                  className="common-tooltip-guide"
                  key={this.props.history.location.state?.showFirstTimeGuide}
                  isOpen={this.props.history.location.state?.showFirstTimeGuide === 4}
                  clickable
                  closeOnScroll={false}
                  closeOnResize={false}
                  content={(
                    <div id="showFirstTimeGuide-content-4">
                      <div style={{ textAlign: 'center' }}>Add and manage your listing from here.</div>
                      <div style={{ textAlign: 'center' }}>
                        <button
                          className="btn common-tooltip-button"
                          onClick={() => {
                            this.props.history.replace({ ...this.props.history.location, state: { showFirstTimeGuide: 5 } })
                            setTimeout(() => {
                              document.getElementById(`showFirstTimeGuide-content-5`)?.scrollIntoView({ block: 'center' })
                            }, 200);
                          }}
                        >Got it</button>
                      </div>
                    </div>
                  )}
                  component={ctProps => (
                    <div
                      data-tooltip-id={ctProps.id}
                      data-tooltip-place="top-start"
                      className="d-flex align-items-center dashboard-right-div-content p-4"
                      onClick={() => this.redirectionHandler(marinaAndStorage.url)}
                    >
                      <div className="dashboard-div-icon mr-4 d-flex">
                        <img loading="lazy" src={require('./image/Manage Boat.svg')} alt="Loading..." />
                      </div>
                      <div className="d-flex flex-column dashboard-right-div-data">
                        <h5 className="dashboard-count">{this.checkCount(marinaCount)}</h5>
                        <span className="font-weight-400 title-text dashboard-market-title">
                          {marinaAndStorage.title}
                        </span>
                      </div>
                    </div>
                  )}
                />
              </div>
            </Col>
          </>
        )

      default:
        break
    }
  }

  renderAllCards = () => {
    const { logout, currentUser, dashboardCount, totalWishlists } = this.props
    const {
      article: articlesCount,
      auctionRoom: auctionCount,
      boatShow: boatshowCount,
      salesEngineBuyItNowCount,
      salesEngineArchiveCount,
      manageHomeAdvertisement: advertisementsCount,
      boat: boatsCount,
      broker: brokersCount,
      surveyor: surveyorCount,
      boatRent: boatRentsCount,
      marina: marinaCount,
      salesEngineCount,
      auctionBidList: bidCount,
      rentBookingType,
    } = dashboardCount
    const { role } = currentUser
    const {
      userProfile,
      salesEngine,
      salesEngineArchive,
      auctionRooms,
      contactLeads,
      wishlists,
      articles,
      boatShows,
      agreementsAndFiles,
      changePassword,
      boatBookings,
      auctionRoomBidList,
      logOut,
    } = dashboardTabs

    const {
      MEMBER,
      BOAT_OWNER,
      BROKER_AND_DEALER,
      BOAT_MANUFACTURER,
      YACHT_SHIPPER,
      SURVEYOR,
      RENT_AND_CHARTER,
      SERVICE_AND_MAINTENANCE,
      MARINA_AND_STORAGE,
      AGENT,
    } = userRoles
    const agreementsAndFilesAccessibleRoles = [BOAT_MANUFACTURER, YACHT_SHIPPER, SURVEYOR]

    return (
      <div>
        <Row>
          <Col sm={4} className="cursor-pointer mb-4 dashboard-box-div-spacing">
            <div className="dashboard-box-div">
              <CommonTooltip
                className="common-tooltip-guide"
                key={Math.random()}
                isOpen={this.props.history.location.state?.showFirstTimeGuide === 1}
                clickable
                closeOnScroll={false}
                closeOnResize={false}
                content={(
                  <div id="showFirstTimeGuide-content-1">
                    <div style={{ textAlign: 'center' }}>Create your AdamSea profile to get started.</div>
                    <div style={{ textAlign: 'center' }}>
                      <button
                        className="btn common-tooltip-button"
                        onClick={() => {
                          this.props.history.replace({ ...this.props.history.location, state: { showFirstTimeGuide: 2 } })
                          setTimeout(() => {
                            document.getElementById(`showFirstTimeGuide-content-2`)?.scrollIntoView({ block: 'center' })
                          }, 200);
                        }}
                      >Got it</button>
                    </div>
                  </div>
                )}
                component={ctProps => (
                  <div
                    data-tooltip-id={ctProps.id}
                    data-tooltip-place="top-start"
                    className="d-flex align-items-center dashboard-right-div-content p-4"
                    onClick={() => this.redirectionHandler(userProfile.url)}
                  >
                    <div className="dashboard-div-icon mr-4 d-flex">
                      <img loading="lazy" src={require('./image/User profile.svg')} alt="Loading..." />
                    </div>
                    <div className="d-flex flex-column dashboard-right-div-data">
                      <h5 className="dashboard-count">{getUserProfileCount(currentUser)}%</h5>
                      <span className="font-weight-400 title-text dashboard-market-title">
                        {userProfile.title}
                      </span>
                    </div>
                  </div>
                )}
              />
            </div>
          </Col>

          {role && this.renderRoleWiseCards(role.aliasName)}
          {role && this.renderMyRequestCard(role.aliasName)}

          <>
            <Col sm={4} className="cursor-pointer mb-4 dashboard-box-div-spacing">
              <div className="dashboard-box-div">
                <div
                  className="d-flex align-items-center dashboard-right-div-content p-4"
                  onClick={() => this.redirectionHandler(salesEngine.url)}
                >
                  <div className="dashboard-div-icon mr-4 d-flex">
                    <img loading="lazy" src={require('./image/My Store (2).svg')} alt="Loading..." />
                  </div>
                  <div className="d-flex flex-column dashboard-right-div-data">
                    <h5 className="dashboard-count">{this.checkCount(dashboardCount && dashboardCount.salesEngine)}</h5>
                    <span className="font-weight-400 title-text dashboard-market-title">
                      {salesEngine.title}
                    </span>
                  </div>
                </div>
              </div>
            </Col>

            <Col sm={4} className="cursor-pointer mb-4 dashboard-box-div-spacing">
              <div className="dashboard-box-div">
                <div
                  className="d-flex align-items-center dashboard-right-div-content p-4"
                  onClick={() => this.redirectionHandler(salesEngineArchive.url)}
                >
                  <div className="dashboard-div-icon mr-4 d-flex">
                    <img loading="lazy" src={require('./image/My store archive.svg')} alt="Loading..." />
                  </div>
                  <div className="d-flex flex-column dashboard-right-div-data">
                    <h5 className="dashboard-count">{this.checkCount(salesEngineArchiveCount)}</h5>
                    <span className="font-weight-400 title-text dashboard-market-title">
                      {salesEngineArchive.title}
                    </span>
                  </div>
                </div>
              </div>
            </Col>
          </>

          {auctionRoomAccessibleRoles.includes(role && role.aliasName) && (
            <Col sm={4} className="cursor-pointer mb-4 dashboard-box-div-spacing">
              <div className="dashboard-box-div">
                <div
                  className="d-flex align-items-center dashboard-right-div-content p-4"
                  onClick={() => this.redirectionHandler(auctionRooms.url)}
                >
                  <div className="dashboard-div-icon mr-4 d-flex">
                    <img loading="lazy" src={require('./image/Auction Rooms.svg')} alt="Loading..." />
                  </div>
                  <div className="d-flex flex-column dashboard-right-div-data">
                    <h5 className="dashboard-count">{this.checkCount(auctionCount)}</h5>
                    <span className="font-weight-400 title-text dashboard-market-title">
                      {auctionRooms.title}
                    </span>
                  </div>
                </div>
              </div>
            </Col>
          )}

          {/* <Col sm={4} className="cursor-pointer mb-4 dashboard-box-div-spacing">
            <div className="dashboard-box-div">
              <div className="d-flex align-items-center dashboard-right-div-content p-4" onClick={() => this.redirectionHandler(wishlists.url)}>
                <div className="dashboard-div-icon mr-4 d-flex">
                  <img loading="lazy" src={require('./image/whishlist.png')} alt="Loading..." />
                </div>
                <div className="d-flex flex-column dashboard-right-div-data">
                  <h5 className="dashboard-count">{totalWishlists}</h5>
                  <span className="font-weight-400 title-text dashboard-market-title">
                    {wishlists.title}
                  </span>
                </div>
              </div>
            </div>
          </Col> */}

          <Col sm={4} className="cursor-pointer mb-4 dashboard-box-div-spacing">
            <div className="dashboard-box-div">
              <div
                className="d-flex align-items-center dashboard-right-div-content p-4"
                onClick={() => this.redirectionHandler(auctionRoomBidList.url)}
              >
                <div className="dashboard-div-icon mr-4 d-flex">
                  <img loading="lazy" src={require('./image/Manage Auction.svg')} alt="Loading..." />
                </div>
                <div className="d-flex flex-column dashboard-right-div-data">
                  <h5 className="dashboard-count">{this.checkCount(bidCount) || 0}</h5>
                  <span className="font-weight-400 title-text dashboard-market-title">
                    {auctionRoomBidList.title}
                  </span>
                </div>
              </div>
            </div>
          </Col>

          <Col sm={4} className="cursor-pointer mb-4 dashboard-box-div-spacing">
            <div className="dashboard-box-div">
              <div
                className="d-flex align-items-center dashboard-right-div-content p-4"
                onClick={() => this.redirectionHandler(boatBookings.url)}
              >
                <div className="dashboard-div-icon mr-4 d-flex">
                  <img loading="lazy" src={require('./image/My Bookings.svg')} alt="Loading..." />
                </div>
                <div className="d-flex flex-column dashboard-right-div-data">
                  <h5 className="dashboard-count">{this.checkCount(rentBookingType)}</h5>
                  <span className="font-weight-400 title-text dashboard-market-title">
                    {boatBookings.title}
                  </span>
                </div>
              </div>
            </div>
          </Col>

          <Col sm={4} className="cursor-pointer mb-4 dashboard-box-div-spacing">
            <div className="dashboard-box-div">
              <CommonTooltip
                className="common-tooltip-guide"
                key={this.props.history.location.state?.showFirstTimeGuide}
                isOpen={this.props.history.location.state?.showFirstTimeGuide === 2}
                clickable
                closeOnScroll={false}
                closeOnResize={false}
                content={(
                  <div id="showFirstTimeGuide-content-2">
                    <div style={{ textAlign: 'center' }}>Write your first article.</div>
                    <div style={{ textAlign: 'center' }}>
                      <button
                        className="btn common-tooltip-button"
                        onClick={() => {
                          this.props.history.replace({ ...this.props.history.location, state: { showFirstTimeGuide: this.state.firstTimeGuide3Enabled ? 3 : (this.state.firstTimeGuide4Enabled ? 4 : 5) } })
                          setTimeout(() => {
                            document.getElementById(`showFirstTimeGuide-content-${this.state.firstTimeGuide3Enabled ? 3 : (this.state.firstTimeGuide4Enabled ? 4 : 5)}`)?.scrollIntoView({ block: 'center' })
                          }, 200);
                        }}
                      >Got it</button>
                    </div>
                  </div>
                )}
                component={ctProps => (
                  <div
                    data-tooltip-id={ctProps.id}
                    data-tooltip-place="top-start"
                    className="d-flex align-items-center dashboard-right-div-content p-4"
                    onClick={() => this.redirectionHandler(articles.url)}
                  >
                    <div className="dashboard-div-icon mr-4 d-flex">
                      <img loading="lazy" src={require('./image/Manage Articles.svg')} alt="Loading..." />
                    </div>
                    <div className="d-flex flex-column dashboard-right-div-data">
                      <h5 className="dashboard-count">{this.checkCount(articlesCount)}</h5>
                      <span className="font-weight-400 title-text dashboard-market-title">
                        {articles.title}
                      </span>
                    </div>
                  </div>
                )}
              />
            </div>
          </Col>

          {/* <Col sm={4} className="cursor-pointer mb-4 dashboard-box-div-spacing">
            <div className="dashboard-box-div">
              <div className="d-flex align-items-center dashboard-right-div-content p-4">
                <div className="dashboard-div-icon mr-4 d-flex">
                  <img loading="lazy" src={require('./image/advertisement.png')} alt="Loading..." />
                </div>
                <div className="d-flex flex-column dashboard-right-div-data">
                  <h5 className="dashboard-count">{this.checkCount(advertisementsCount)}</h5>
                  <span className="font-weight-400 title-text dashboard-market-title">
                    {advertisements.title}
                  </span>
                </div>
              </div>
            </div>
          </Col> */}

          {/* {role?.aliasName !== MEMBER && ( */}
            <Col sm={4} className="cursor-pointer mb-4 dashboard-box-div-spacing">
              <div className="dashboard-box-div">
                <CommonTooltip
                  className="common-tooltip-guide"
                  key={this.props.history.location.state?.showFirstTimeGuide}
                  isOpen={this.props.history.location.state?.showFirstTimeGuide === 3}
                  clickable
                  closeOnScroll={false}
                  closeOnResize={false}
                  content={(
                    <div id="showFirstTimeGuide-content-3">
                      <div style={{ textAlign: 'center' }}>Add your first boat show or event.</div>
                      <div style={{ textAlign: 'center' }}>
                        <button
                          className="btn common-tooltip-button"
                          onClick={() => {
                            this.props.history.replace({ ...this.props.history.location, state: { showFirstTimeGuide: this.state.firstTimeGuide4Enabled ? 4 : 5 } })
                            setTimeout(() => {
                              document.getElementById(`showFirstTimeGuide-content-${this.state.firstTimeGuide4Enabled ? 4 : 5}`)?.scrollIntoView({ block: 'center' })
                            }, 200);
                          }}
                        >Got it</button>
                      </div>
                    </div>
                  )}
                  component={ctProps => (
                    <div
                      data-tooltip-id={ctProps.id}
                      data-tooltip-place="top-start"
                      className="d-flex align-items-center dashboard-right-div-content p-4"
                      onClick={() => this.redirectionHandler(boatShows.url)}
                    >
                      <div className="dashboard-div-icon mr-4 d-flex">
                        <img loading="lazy" src={require('./image/Boat show.svg')} alt="Loading..." />
                      </div>
                      <div className="d-flex flex-column dashboard-right-div-data">
                        <h5 className="dashboard-count">{this.checkCount(boatshowCount)}</h5>
                        <span className="font-weight-400 title-text dashboard-market-title">
                          {boatShows.title}
                        </span>
                      </div>
                    </div>
                  )}
                />
              </div>
            </Col>
          {/* )} */}

          {/* LATER NEEDED THIS CHANGE */}

          <Col sm={4} className="cursor-pointer mb-4 dashboard-box-div-spacing">
            <div className="dashboard-box-div">
              <div
                className="d-flex align-items-center dashboard-right-div-content p-4"
                onClick={() => this.redirectionHandler(changePassword.url)}
              >
                <div className="dashboard-div-icon mr-4 d-flex">
                  <img loading="lazy" src={require('./image/Change Password.svg')} alt="Loading..." />
                </div>
                <div className="d-flex flex-column dashboard-right-div-data">
                  <h5 className="dashboard-count"></h5>
                  <span className="font-weight-400 title-text dashboard-market-title">
                    {changePassword.title}
                  </span>
                </div>
              </div>
            </div>
          </Col>

          {/* <Col sm={4} className="cursor-pointer mb-4 dashboard-box-div-spacing">
            <div className="dashboard-box-div">
              <div
                className="d-flex align-items-center dashboard-right-div-content p-4"
                onClick={() => this.redirectionHandler(logOut.url)}
              >
                <div className="dashboard-div-icon mr-4 d-flex">
                  <img loading="lazy" src={require('./image/logout.png')} alt="Loading..." />
                </div>
                <div className="d-flex flex-column dashboard-right-div-data">
                  <h5 className="dashboard-count"></h5>
                  <span className="font-weight-400 title-text dashboard-market-title">{logOut.title}</span>
                </div>
              </div>
            </div>
          </Col> */}
        </Row>
      </div>
    )
  }

  render() {
    const { currentUser, dashboardCount, isPageLoader } = this.props
    const { agreementId } = currentUser

    return (
      <DashboardLayout>
        {currentUser && !currentUser.id ? (
          <Loader isPageLoader />
        ) : (
          <>
            <div className="width-100">
              <div className="px-3 dashboard-right-div">
                <div className="pl-5 pb-5 dashboard-right-div-padding">
                  {dashboardCount && this.renderAllCards()}
                </div>
              </div>
            </div>
            {/* Do not remove below component
            ask @miraj before

            <TableComponent/>
          */}

          {isPageLoader && <Loader isPageLoader />}
          </>
        )}
      </DashboardLayout>
    )
  }
}

const mapStateToProps = state => ({
  isAuthenticated: state.loginReducer.isAuthenticated,
  currentUser: state.loginReducer.currentUser,
  forgotPasswordSuccess: state.loginReducer.forgotPasswordSuccess,
  dashboardCount: state.dashboardReducer.dashboardCount,
  dashBoardSideBarData: state.dashboardReducer.dashBoardSideBarData,
  totalWishlists: state.dashboardReducer.totalWishlists,
  unauthorizedAccess: state.dashboardReducer.unauthorizedAccess,
  unauthorizedAccessMessage: state.dashboardReducer.unauthorizedAccessMessage,
  serviceDetailForDashboard: state.boatServiceReducer.serviceDetailForDashboard,
  isPageLoader: state.boatServiceReducer.isPageLoader
})

const mapDispatchToProps = dispatch => ({
  clearForgotPassword: () => dispatch(clearForgotPassword()),
  clearAuthFlag: () => dispatch(clearAuthorizationFlag()),
  getDashBoardCount: data => dispatch(getDashBoardCount(data)),
  getAllWishlists: data => dispatch(getAllWishlists(data)),
  onMenuItemClick: data => dispatch(onMenuItemClick(data)),
  clearDashboardUnauthorizedAccessFlag: () => dispatch(clearDashboardUnauthorizedAccessFlag()),
  getServiceDetail: () => dispatch(getServiceDetail()),
  toggleYachtServiceStatus: data => dispatch(toggleYachtServiceStatus(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)
