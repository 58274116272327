import React from 'react'
import { connect } from 'react-redux'
import { InputBase } from '@material-ui/core'
import { Layout } from '../../components/layout/layout'
import { Tab, Row, Col, Nav } from 'react-bootstrap'
import { IoIosArrowForward } from 'react-icons/io'
import { getALlDownloadsData } from '../../redux/actions/downloadsAction'
import searchFilter from '../../helpers/filterUtil'
import { getCategoriesWiseBanners, getDocumentUrl } from '../../redux/actions'
import CommonBanner from '../../components/mainBanner/commonBanner'
import { ButtonComponent } from '../../components/form/Button'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import { getId } from '../../util/utilFunctions'
import { get } from 'lodash'
import classNames from 'classnames'

class DownLoad extends React.Component {
  state = {
    searchValue: '',
    filterData: [],
    filterDataMap: {},
  }

  createMarkup = data => {
    return { __html: data }
  }

  componentDidMount() {
    const { getALlDownloadsData, getCategoriesWiseBanners } = this.props

    const faqInput = {
      type: 'downloaded',
      fieldName: 'downloadBanner',
    }
    getCategoriesWiseBanners(faqInput)
    getALlDownloadsData()
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.downloads !== this.props.downloads) {
      this.setState({
        filterData: this.props.downloads,
        filterDataMap: this.props.downloads.reduce((obj, itm) => {
          obj[getId(itm)] = itm
          return obj
        }, {}),
      })
    }

    const searchParams = new URLSearchParams(this.props.location.search)
    const activeId = searchParams.get('id')

    if (!activeId && this.state.filterData?.length) {
      const id = getId(this.state.filterData[0])
      this.props.history.push({ pathname: '/downloads', search: `?id=${id}` })
    }
  }

  searchHandler() {
    const { downloads } = this.props
    const { searchValue } = this.state
    let getSearchData = []
    if (downloads.length) {
      getSearchData = searchFilter(downloads, searchValue, 'title')
    } else {
      getSearchData = downloads || []
    }
    this.setState({
      filterData: getSearchData,
      filterDataMap: getSearchData.reduce((obj, itm) => {
        obj[getId(itm)] = itm
        return obj
      }, {}),
    })
  }

  render() {
    const { downloadBanner, getDocumentUrl } = this.props
    const { filterData, filterDataMap, searchValue } = this.state

    const searchParams = new URLSearchParams(this.props.location.search)
    const activeId = searchParams.get('id')
    const activeData = get(filterDataMap, `${activeId}`)

    return (
      <Layout className="downloads-layout">
        <div className="user-faq-banner-div user-guide-faq-banner-section position-relative">
          <CommonBanner data={downloadBanner} />
          <h1
            className="mb-4 font-22 font-bold"
            style={{ position: 'absolute', top: '50%', zIndex: 111, left: 50, color: 'white' }}
          >
            Help Center
          </h1>
        </div>
        <div className="container100 pt-4 pb-5 user-faq-main-container">
          <div className="mb-4 d-flex justify-content-center align-items-center">
            <div className="_tabs-des">
              <Link to="/user-guide" className="_tab-des">
                User Guide
              </Link>
              <Link to="/faq" className="_tab-des">
                FAQs
              </Link>
              <Link to="/downloads" className="_tab-des _tab-des-active">
                Downloads
              </Link>
            </div>

            <div className="ml-4">
              <div className="position-relative">
                <div className="main-home-search faq-main-search mt-0">
                  <div className="btn w-auto faq-banner-search-btn">
                    <i className="adam-search1 color-black"></i>
                  </div>
                  <InputBase
                    placeholder="Search by keyword"
                    inputProps={{ 'aria-label': 'search google maps' }}
                    className="search-input faq-search-input"
                    value={searchValue}
                    onChange={e => {
                      this.setState({ searchValue: e.target.value }, () => {
                        this.searchHandler(this.state.searchValue)
                      })
                    }}
                  />
                </div>
                {!filterData.length && searchValue && (
                  <div className="no--search--div">
                    <span>no result for {searchValue}</span>
                  </div>
                )}
              </div>
            </div>
          </div>

          <Tab.Container id="left-tabs-example" defaultActiveKey="0">
            <Row className="mt-4 faq-main-row user-faq-divtop-space">
              <Col sm={3} className="faq-left-div user-faq-download-questioning">
                <div className="user-guide-nav-left-div user-download-question-line user-guide-scrollbar pr-3">
                  {filterData &&
                    filterData.map((value, index) => (
                      <Link
                        to={{ pathname: '/downloads', search: `?id=${getId(value)}` }}
                        key={getId(value)}
                        className="userFaq-ques-item user-guide-nav-div d-flex align-items-center justify-content-between"
                      >
                        <span
                          className={classNames('userFaq-ques-item-ques font-17', { linkActive: getId(value) === activeId })}
                        >
                          {value.title}
                        </span>
                        <div>
                          <IoIosArrowForward className="col-gray user-guide-nav-icon" />
                        </div>
                      </Link>
                    ))}
                </div>
              </Col>
              {activeData && (
                <Col sm={9} className="faq-right-div user-faq-download-help">
                  <div>
                    <div className="faq-right-side-answer-div">
                      <h1 className="faq--title">
                        {activeData?.title}{' '}
                        <div className="faq--title-downloads">
                          <ButtonComponent className="btn-black rounded-full" onClick={() => getDocumentUrl(activeData?.downloadUrl?.id)}>
                            Download
                          </ButtonComponent>
                        </div>
                      </h1>
                      <div dangerouslySetInnerHTML={this.createMarkup(activeData?.description)} />
                    </div>
                  </div>
                </Col>
              )}
            </Row>
          </Tab.Container>
        </div>
      </Layout>
    )
  }
}

const mapStateToProps = state => ({
  downloadBanner: state.bannerReducer && state.bannerReducer['downloadBanner'],
  downloads: state.downloadsReducer?.downloads,
})

const mapDispatchToProps = dispatch => ({
  getCategoriesWiseBanners: data => dispatch(getCategoriesWiseBanners(data)),
  getALlDownloadsData: data => dispatch(getALlDownloadsData(data)),
  getDocumentUrl: data => dispatch(getDocumentUrl(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(DownLoad)
