import React from 'react'
import { Layout } from '../../components/layout/layout'
import { Tab, Row, Col, Nav, Container } from 'react-bootstrap'

import './userFaq.scss'
import { InputBase, Grid } from '@material-ui/core'
import { IoIosArrowForward } from 'react-icons/io'
import { pagination } from '../../util/enums/enums'
import { getAllUserFaq } from '../../redux/actions/userFaqAction'
import searchFilter from '../../helpers/filterUtil'
import { connect } from 'react-redux'
import { getCategoriesWiseBanners } from '../../redux/actions'
import CommonBanner from '../../components/mainBanner/commonBanner'
import { getId } from '../../util/utilFunctions'
import { get } from 'lodash'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import classNames from 'classnames'

class UserFaq extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      searchValue: '',
      filterData: [],
      filterDataMap: {},
    }
  }

  createMarkup = data => {
    return { __html: data }
  }

  componentDidMount() {
    const { getAllUserFaq, getCategoriesWiseBanners } = this.props
    const data = {
      page: pagination.PAGE_COUNT,
      limit: pagination.PAGE_RECORD_LIMIT,
    }
    const faqInput = {
      type: 'faq',
      fieldName: 'faqBanner',
    }
    getCategoriesWiseBanners(faqInput)
    getAllUserFaq(data)
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.userfaqs !== this.props.userfaqs) {
      this.setState({
        filterData: this.props.userfaqs,
        filterDataMap: this.props.userfaqs.reduce((obj, itm) => {
          obj[getId(itm)] = itm
          return obj
        }, {}),
      })
    }

    const searchParams = new URLSearchParams(this.props.location.search)
    const activeId = searchParams.get('id')

    if (!activeId && this.state.filterData?.length) {
      const id = getId(this.state.filterData[0])
      this.props.history.push({ pathname: '/faq', search: `?id=${id}` })
    }
  }

  searchHandler() {
    const { userfaqs } = this.props
    const { searchValue } = this.state
    let temp = []
    if (userfaqs.length) {
      temp = searchFilter(userfaqs, searchValue, 'question')
    } else {
      temp = userfaqs || []
    }
    this.setState({
      filterData: temp,
      filterDataMap: temp.reduce((obj, itm) => {
        obj[getId(itm)] = itm
        return obj
      }, {}),
    })
  }

  render() {
    const { history, faqBanners } = this.props
    const { filterData, filterDataMap, searchValue } = this.state

    const searchParams = new URLSearchParams(this.props.location.search)
    const activeId = searchParams.get('id')
    const activeData = get(filterDataMap, `${activeId}`)

    return (
      <Layout>
        <div className="user-faq-banner-div user-guide-faq-banner-section adam-faq-banner-sec position-relative">
          <CommonBanner data={faqBanners} />
          <h1
            className="mb-4 font-22 font-bold"
            style={{ position: 'absolute', top: '50%', zIndex: 111, left: 50, color: 'white' }}
          >
            Help Center
          </h1>
        </div>
        <div className="container100 pt-4 pb-5 user-faq-main-container adam-faq-main-sec">
          <div className="mb-4 d-flex justify-content-center align-items-center">
            <div className="_tabs-des">
              <Link to="/user-guide" className="_tab-des">
                User Guide
              </Link>
              <Link to="/faq" className="_tab-des _tab-des-active">
                FAQs
              </Link>
              <Link to="/downloads" className="_tab-des">
                Downloads
              </Link>
            </div>

            <div className="ml-4">
              <div className="position-relative">
                <div className="main-home-search faq-main-search mt-0">
                  <div className="btn w-auto faq-banner-search-btn">
                    <i className="adam-search1 color-black"></i>
                  </div>
                  <InputBase
                    placeholder="Search by keyword"
                    inputProps={{ 'aria-label': 'search google maps' }}
                    className="search-input faq-search-input"
                    value={searchValue}
                    onChange={e => {
                      this.setState({ searchValue: e.target.value }, () => {
                        this.searchHandler(this.state.searchValue)
                      })
                    }}
                  />
                </div>
                {!filterData.length && searchValue && (
                  <div className="no--search--div">
                    <span>no result for {searchValue}</span>
                  </div>
                )}
              </div>
            </div>
          </div>

          <Tab.Container id="left-tabs-example" defaultActiveKey="0">
            <Row className="mt-4 faq-main-row">
              <Col sm={3} className="faq-left-div faq-questioning-part">
                <div className="user-guide-nav-left-div user-guide-scrollbar pr-3">
                  {filterData &&
                    filterData.map((value, index) => (
                      <Link
                        to={{ pathname: '/faq', search: `?id=${getId(value)}` }}
                        key={getId(value)}
                        className="userFaq-ques-item user-guide-nav-div d-flex align-items-center justify-content-between"
                      >
                        <span
                          className={classNames('userFaq-ques-item-ques font-17', { linkActive: getId(value) === activeId })}
                        >
                          {value.question}
                        </span>
                        <span>
                          <IoIosArrowForward className="col-gray user-guide-nav-icon" />
                        </span>
                      </Link>
                    ))}
                </div>
              </Col>
              {activeData && (
                <Col sm={9} className="faq-right-div">
                  <div>
                    <div className="faq-right-side-answer-div">
                      <div className="faq-description" dangerouslySetInnerHTML={this.createMarkup(activeData?.description)} />
                    </div>
                  </div>
                </Col>
              )}
            </Row>
          </Tab.Container>
        </div>
        {/* <hr className="mt-5 faq-divider" />
        <Container className="mt-5 frequent-questions-container adam-faq-quest-sec">
          <span className="font-22 font-weight-500 frequent-questions-title">Most Frequent Questions</span>
          <Grid container spacing={3} className="mt-2 frequent-questions-main-div">
            <Grid item sm={4} className="d-flex flex-column">
              <span className="col-gray pl-0 pr-0 f-15 mb-2 mt-1 frequent-questions">How To Open Account As Single Seller</span>
              <span className="col-gray pl-0 pr-0 f-15 mb-2 mt-1 frequent-questions">Sign Into Your Account</span>
              <span className="col-gray pl-0 pr-0 f-15 mb-2 mt-1 frequent-questions">How To Post Ads as Single Seller</span>
            </Grid>
            <Grid item sm={4} className="d-flex flex-column">
              <span className="col-gray pl-0 pr-0 f-15 mb-2 mt-1 frequent-questions">How To Open Account As Yatch Seller</span>
              <span className="col-gray pl-0 pr-0 f-15 mb-2 mt-1 frequent-questions">How To Update My Profile</span>
              <span className="col-gray pl-0 pr-0 f-15 mb-2 mt-1 frequent-questions">How To Reset My Password</span>
            </Grid>
            <Grid item sm={4} className="d-flex flex-column">
              <span className="col-gray pl-0 pr-0 f-15 mb-2 mt-1 frequent-questions">How To Open Account As Broker Seller</span>
              <span className="col-gray pl-0 pr-0 f-15 mb-2 mt-1 frequent-questions">How To Post Ads as Broker Seller</span>
              <span className="col-gray pl-0 pr-0 f-15 mb-2 mt-1 frequent-questions">How To Post Ads as Broker Seller</span>
            </Grid>
          </Grid>
        </Container> */}
      </Layout>
    )
  }
}

const mapStateToProps = state => ({
  faqBanners: state.bannerReducer && state.bannerReducer['faqBanner'],
  userfaqs: state.userFaqReducer && state.userFaqReducer.userfaqs,
})

const mapDispatchToProps = dispatch => ({
  getCategoriesWiseBanners: data => dispatch(getCategoriesWiseBanners(data)),
  getAllUserFaq: data => dispatch(getAllUserFaq(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(UserFaq)
