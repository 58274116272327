import { useCallback, useState } from 'react'
import { Link } from 'react-router-dom'
import cn from 'classnames'
import moment from 'moment'
import OutsideClickHandler from 'react-outside-click-handler'
import { enqueueSnackbar } from 'notistack'
import { getId } from '../../../../util/utilFunctions'
import { getAddress } from '../../../../helpers/jsxHelper'
import * as Icons from '../../assets/icons'
import { Routes } from '../../lib/consts'
import { commentPost as qCommentPost } from '../../lib/queries'
import { useAuth } from '../../contexts/auth-ctx'
import { useSMDb } from '../../contexts/smdb-ctx'
import { useChatModal } from '../../contexts/chat-modal-ctx'
import FollowBtn from './follow-btn'
import SaveBtn from './save-btn'
import LikeBtn from './like-btn'
import ReadMore from '../common/read-more'
import Media from './media'
import compStyles from '../../styles/components.module.scss'
import styles from './post.module.scss'
import EmojiBtn from '../common/emoji-btn'

export default function Post({ data }) {
  const auth = useAuth()
  const [smdbGet, , smdbMerge] = useSMDb()
  const chatModal = useChatModal()

  const postId = data._id
  const userAddr = getAddress(data.user.address)?.commonAddress

  const [isPostActionsDdOpen, setIsPostActionsDdOpen] = useState(false)
  const [userPreviewOpen, setUserPreviewOpen] = useState(false)
  const [userPreviewTimer, setUserPreviewTimer] = useState(null)
  const [parentComment, setParentComment] = useState(null)
  const [comment, setComment] = useState('')
  const [commentLoading, setCommentLoading] = useState(false)

  const commentPost = async ({ postId, content, parentCommentId }) => {
    if (!content) return

    setComment('')
    setParentComment(null)
    setCommentLoading(true)

    try {
      const res = await qCommentPost({ postId, content, parentCommentId })
      smdbMerge(postId, res)
    } catch (er) {
      enqueueSnackbar(er)
    }

    setCommentLoading(false)
  }

  const topLikesNOthers = (() => {
    const arr = [...data.topLikes]
    const restCount = data.likesCount - 4

    if (restCount > 0) {
      arr.push(restCount === 1 ? '1 other' : restCount + ' others')
    }

    return arr
  })()

  return (
    <div className={styles.post}>
      <div className={styles.postHead}>
        <div className={styles.authorDets}>
          <div
            className={styles.authorAvatarNamePreviewWrp}
            onMouseOver={() => {
              if (auth.user._id === data.user._id) return
              clearTimeout(userPreviewTimer)
              const timer = setTimeout(() => setUserPreviewOpen(true), 300)
              setUserPreviewTimer(timer)
            }}
            onMouseOut={e => {
              if (e.currentTarget.contains(e.relatedTarget)) return
              clearTimeout(userPreviewTimer)
              const timer = setTimeout(() => setUserPreviewOpen(false), 100)
              setUserPreviewTimer(timer)
            }}
          >
            <Link to={Routes.User(data.user._id)} className={styles.authorAvatarNameWrp}>
              <span className={styles.authorAvatarWrp}>
                <img
                  src={data.user.image?.url || require('../../assets/avatar-sample.png')}
                  alt={data.user.firstName}
                  className={styles.authorAvatar}
                />
              </span>
              <span className={styles.authorName}>{data.user.firstName}</span>
            </Link>

            {userPreviewOpen && (
              <div
                className={styles.authorPreviewWrp}
                onMouseOver={() => {
                  clearTimeout(userPreviewTimer)
                  setUserPreviewOpen(true)
                }}
                onMouseOut={e => {
                  if (!e.currentTarget.contains(e.relatedTarget)) {
                    clearTimeout(userPreviewTimer)
                    const timer = setTimeout(() => setUserPreviewOpen(false), 100)
                    setUserPreviewTimer(timer)
                  }
                }}
              >
                <div className={styles.authorPreview}>
                  <div className={styles.head}>
                    <div className={styles.primDets}>
                      <Link to={Routes.User(data.user._id)} className={styles.avatarWrp}>
                        <img
                          src={data.user.image?.url || require('../../assets/avatar-sample.png')}
                          alt={data.user.firstName}
                          className={styles.avatar}
                        />
                        <span className={styles.statusDot} />
                      </Link>

                      <div className={styles.detsWrp}>
                        {data.user.isVerified && (
                          <div className={styles.verifLabel}>
                            <Icons.Shield className={styles.icon} /> Verified
                          </div>
                        )}

                        <div className={styles.authorName}>{data.user.firstName}</div>

                        {userAddr?.city && userAddr.country && (
                          <div className={styles.authorAddr}>
                            {userAddr.city}, {userAddr.country}
                          </div>
                        )}

                        <div className={styles.actionBtns}>
                          <button
                            className={cn(styles.actionBtn, styles.actionChatBtn)}
                            onClick={() => chatModal.createChat(data.user)}
                          >
                            <Icons.Chat className={styles.chatIcon} />
                          </button>

                          <FollowBtn userId={data.user._id} className={cn(styles.actionBtn, styles.actionFollowBtn)} />
                        </div>
                      </div>
                    </div>

                    <div className={styles.countsWrp}>
                      <div className={styles.countWrp}>
                        <div className={styles.count}>{data.user.followingsCount}</div>
                        <div className={styles.countLabel}>Followings</div>
                      </div>
                      <div className={styles.countWrp}>
                        <div className={styles.count}>{data.user.followersCount}</div>
                        <div className={styles.countLabel}>Followers</div>
                      </div>
                      <div className={styles.countWrp}>
                        <div className={styles.count}>{data.user.postsCount}</div>
                        <div className={styles.countLabel}>Posts</div>
                      </div>
                    </div>
                  </div>

                  <div className={styles.body}>
                    <div className={styles.countsWrp}>
                      <div className={styles.countItemWrp}>
                        <div className={styles.countWrp}>
                          <Icons.Boat className={styles.countIcon} />
                          <span className={styles.count}>{data.user.adsCount}</span>
                        </div>
                        <div className={styles.countLabel}>{data.user.adsCountLabel}</div>
                      </div>

                      <div className={styles.countItemWrp}>
                        <div className={styles.countWrp}>
                          <Icons.Review className={styles.countIcon} />
                          <span className={styles.count}>{data.user.reviewsCount}</span>
                        </div>
                        <div className={styles.countLabel}>Reviews</div>
                      </div>

                      <div className={styles.countItemWrp}>
                        <div className={styles.countWrp}>
                          <Icons.Article className={styles.countIcon} />
                          <span className={styles.count}>{data.user.blogsCount}</span>
                        </div>
                        <div className={styles.countLabel}>Articles</div>
                      </div>

                      <div className={styles.countItemWrp}>
                        <div className={styles.countWrp}>
                          <Icons.BoatShow className={styles.countIcon} />
                          <span className={styles.count}>{data.user.boatShowsCount}</span>
                        </div>
                        <div className={styles.countLabel}>Boat Shows</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>

          {!!data.user.isVerified && (
            <div className={styles.authorVerifIconWrp}>
              <Icons.ShieldSolid className={styles.authorVerifIcon} />
            </div>
          )}

          <div className={cn(compStyles.dot, styles.dot)} />
          <div className={styles.postTime}>{moment(data.createdAt).fromNow()}</div>
        </div>

        <div className={styles.postActionsWrp}>
          <FollowBtn userId={data.user._id} className={styles.followBtn} />

          <div className={styles.postActionsDdWrp}>
            <OutsideClickHandler onOutsideClick={() => setIsPostActionsDdOpen(false)}>
              <button className={styles.postActionsDdTogBtn} onClick={() => setIsPostActionsDdOpen(open => !open)}>
                <Icons.MenuVDots className={styles.postActionsDdTogBtnIcon} />
              </button>

              {isPostActionsDdOpen && (
                <div className={styles.postActionsDd}>
                  <ul className={styles.postActionsDdList}>
                    <li className={styles.postActionsDdListItem}>
                      <SaveBtn postId={postId} className={styles.postActionsDdListItemBtn} />
                    </li>
                  </ul>
                </div>
              )}
            </OutsideClickHandler>
          </div>
        </div>
      </div>

      <div className={styles.postBody}>
        <div className={styles.postContent}>
          <ReadMore text={data.content} maxLength={100} className={styles.readmore} />
        </div>

        {data.media?.length > 0 && <Media post={data} />}
      </div>

      <div className={styles.postFoot}>
        {data.topLikes?.length > 0 && (
          <div className={styles.postTopLikesWrp}>
            <button className={styles.postTopLikesWrpBtn}>
              <span className={styles.postTopLikeAuthorAvatarsWrp}>
                {data.topLikes.map(l => (
                  <span className={styles.postTopLikeAuthorAvatarWrp}>
                    <img
                      src={l.user.image?.url || require('../../assets/avatar-sample.png')}
                      alt={l.user.firstName}
                      className={styles.postTopLikeAuthorAvatar}
                    />
                  </span>
                ))}
              </span>

              <span className={styles.postTopLikesAuthorNames}>
                {topLikesNOthers.map((l, i) => (
                  <>
                    {i > 0 && i === topLikesNOthers.length - 1 && ' and '}
                    <b>{typeof l === 'string' ? l : l.user.firstName}</b>
                    {i < topLikesNOthers.length - 2 && ', '}
                  </>
                ))}{' '}
                liked this post
              </span>
            </button>
          </div>
        )}

        <div className={styles.postPrimActionsWrp}>
          <div className={styles.postPrimActionBtnWrp}>
            <LikeBtn
              postId={postId}
              className={cn(styles.postPrimActionBtn, smdbGet(postId, 'liked') && styles.__state_postLikeBtnActive)}
            />
          </div>

          <div className={styles.postPrimActionBtnWrp}>
            <button className={styles.postPrimActionBtn}>
              <Icons.Comment /> {data.commentsCount}
            </button>
          </div>

          <div className={styles.postPrimActionBtnWrp}>
            <button className={styles.postPrimActionBtn}>
              <Icons.Repeat />
            </button>
          </div>

          <div className={styles.postPrimActionBtnWrp}>
            <button className={styles.postPrimActionBtn}>
              <Icons.Share />
            </button>
          </div>
        </div>

        <div className={styles.postAddCommentWrp}>
          <div className={styles.postAddCommentInpWrp}>
            <textarea
              className={styles.postAddCommentInp}
              placeholder="Comment here..."
              value={comment}
              onChange={e => setComment(e.target.value)}
              disabled={commentLoading}
            />
          </div>
          <div className={styles.postAddCommentActBtnsWrp}>
            <EmojiBtn className={styles.postAddCommentEmojiBtn} addEmoji={emoji => setComment(c => c + emoji)} />
            <button
              className={styles.postAddCommentSubmitBtn}
              onClick={() => {
                commentPost({
                  postId: data._id,
                  content: comment,
                  parentCommentId: getId(parentComment),
                })
              }}
              disabled={commentLoading}
            >
              Comment
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
