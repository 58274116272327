import { useEffect, useRef, useState } from 'react'
import { enqueueSnackbar } from 'notistack'
import { getPosts as qGetPosts } from '../lib/queries'
import { useSMDb } from '../contexts/smdb-ctx'
import Posts from '../components/common/posts'
import styles from './feeds.module.scss'

export default function Feeds() {
  const [, , smdbMerge] = useSMDb()

  const [postsLoading, setPostsLoading] = useState(false)
  const [posts, setPosts] = useState([])

  const paginationRef = useRef({})

  useEffect(() => {
    setPosts([])
    getPosts({})
  }, [])

  useEffect(() => {
    for (const post of posts) {
      const { likesCount, liked, topLikes, saved } = post
      smdbMerge(post._id, { likesCount, liked, topLikes, saved })

      const { isFollowing } = post.user
      smdbMerge(post.user._id, { isFollowing })
    }
  }, [posts])

  const getPosts = async pagination => {
    setPostsLoading(true)

    try {
      const res = await qGetPosts(pagination)
      setPosts(res.items)
      paginationRef.current = { page: res.page, token: res.token }
    } catch (err) {
      enqueueSnackbar(err)
    }

    setPostsLoading(false)
  }

  return (
    <div className={styles.feedsCont}>
      <div className={styles.feedsSec}>
        <div className={styles.feedsWrp}>
          <Posts items={posts} loading={postsLoading} />
        </div>
      </div>

      <div className={styles.rightSec}></div>
    </div>
  )
}
