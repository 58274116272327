import React, { Component, Fragment } from 'react'
import { Grid, Box, FormControlLabel, Switch } from '@material-ui/core'
import { connect } from 'react-redux'

import { BoatCard, Loader } from '../../components/'
import GoogleMarker from '../../components/map/marker'
import {
  viewBoatHandler,
  getBoatMarkers,
  viewRentBoatHandler,
  getBoatShowMarker,
  getRentShowMarkers,
  getBoatServiceMarkers,
  viewMarinaDetails,
  viewServiceBoatHandler,
  getMarinaStorageMarker,
} from '../../helpers/boatHelper'
import UserContext from '../../UserContext'
import { resultMessage, dimension, skeletonType, noDataResult } from '../../util/enums/enums'
import { getRatio, getHeightRatio } from '../../helpers/ratio'
import ShowBoatCards from './showBoatCards'
import { BoatShowCard } from '../boat/boatShowCard'
import { SectionHeadingShowStyle, MapShowStyle } from '../styleComponent/styleComponent'
import { BoatServiceMapCard } from '../boat/boatServiceMapCard'
import { BoatGrid } from '../../components/gridComponents/boatGrid'
import { RentSharedTrips } from './rentSharedTrips'
import { RecommendedMarinaStorages } from './recommendedMarinaStorages'
import BoatServicesAdded from './boatAddedServices'
import { RentBoatMapCard } from '../boat/rentBoatMapCard'
import { MarinaStorageMapCard } from '../marinaStorage/marinaStorageMapCard'
import '../../components/boat/boatCard.scss'
import { pagination } from '../../util/enums/enums'
import { Layout } from '../layout/layout'
import { SkeletonLoader } from '../loader/SkeletonLoader'
import { getPagesValue } from '../../helpers/jsxHelper'
import { PaginationBar } from '../pagination/PaginationBar'
import { getLocalStorageItem } from '../../helpers/storageHelper'
import { onMapItemCardHover } from '../../redux/actions'
import { objectToQuerystring, urlParamsToObject } from '../../helpers/routeHelper'
import { get } from 'lodash'
import { getId } from '../../util/utilFunctions'

class BoatListingsWithMap extends Component {
  static contextType = UserContext

  state = {
    width: 380,
    height: 280,
    top: 94,
    mapShowTop: 169,
    isShowMapStyle: false,
    defaultSlice: 5,
    latLng: null,
    isShowMassage: false,
    page: 1,
    boats: [],
    boatsLoadedPage: 0,
  }

  componentDidMount() {
    const element = document.querySelector('main')
    element && element.classList.add('layout--show-all-list')
    this.resize()
    const width = getRatio(dimension, 'showAllGrid', '.section-grid')
    const height = getHeightRatio(dimension, 'showAllGrid', '.section-grid')
    width && height && this.setState({ width, height })
    window.addEventListener('resize', this.resize)
    window.addEventListener('scroll', this.onPageScroll)

    //Replaced getDerivedStateFromProps action with componentDidMount
    const { action, value } = this.props

    value &&
      action({
        ...value,
        page: this.state.page,
        limit: pagination.PAGE_RECORD_LIMIT,
      })
  }

  componentDidUpdate(prevProps, prevState) {
    const { isPageLoader } = this.props
    const { isShowMassage } = this.state

    if (!this.state?.isShowMapStyle) {
      if (getLocalStorageItem('userId') && !this.interval) {
        this.headerSwitch()
      } else if (!this.interval1) {
        this.headerSwitchWithoutUser()
      }
    }

    if (!isPageLoader && !this.props.boats?.length && !isShowMassage) {
      setTimeout(() => {
        this.setState({ isShowMassage: true })
      }, 200)
    }

    if (!isPageLoader && JSON.stringify(this.state.boats?.map(getId)) !== JSON.stringify(this.props.boats?.map(getId))) {
      if (this.state.page === 1) {
        this.setState({
          boats: this.props.boats,
          boatsLoadedPage: 1,
        })
      } else {
        if(!isPageLoader
          && this.state.boatsLoadedPage < this.state.page
          && prevProps. boats !== this.props.boats
          // prevState.page !== this.state.page ||
          // JSON.stringify(this.state.latLng) !== JSON.stringify(prevState.latLng)
          ){
          this.setState({
            boats: [...this.state.boats, ...this.props.boats],
            boatsLoadedPage: this.state.boatsLoadedPage + 1,
          })
      }
    }}

    if (
      JSON.stringify(prevProps.value) !== JSON.stringify(this.props.value) ||
      prevState?.page !== this.state.page ||
      JSON.stringify(this.state.latLng) !== JSON.stringify(prevState.latLng)
    ) {
      this.props.action({
        ...this.props.value,
        page: this.state.page,
        limit: pagination.PAGE_RECORD_LIMIT,
        latLng: this.state.latLng,
      })
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onPageScroll)
  }

  onPageScroll = () => {
    if (
      this.state.boats?.length &&
      this.state.page < this.props.boatsTypeCount / pagination.PAGE_RECORD_LIMIT &&
      !this.props.isPageLoader
    ) {
      const el = document.querySelector('.page-scroll-div')

      if (el.getBoundingClientRect().bottom <= window.innerHeight + 150) {
        this.setState({ page: this.state.page + 1 })
      }
    }
  }

  headerSwitch = () => {
    this.interval = setInterval(() => {
      const className = document.querySelector('.header-responsive')
      className && this.stop()
    }, 2000)
  }

  stop() {
    const topSelector = document.querySelector('.header-responsive')
    const top = topSelector && topSelector.offsetHeight - 1
    top && this.setState({ top, isShowMapStyle: true })
    clearInterval(this.interval)
  }

  resize = () => {
    const topSelector = document.querySelector('.header-responsive')
    const top = topSelector && topSelector.offsetHeight - 1
    top && this.setState({ top })
  }

  headerSwitchWithoutUser = () => {
    this.interval1 = setInterval(() => {
      const className = document.querySelector('.login-signup-header-link')
      className && this.stopWithoutUser()
    }, 1000)
  }

  stopWithoutUser() {
    const topSelector = document.querySelector('.header-responsive')
    const top = topSelector && topSelector.offsetHeight - 1
    top && this.setState({ top, isShowMapStyle: true })
    clearInterval(this.interval1)
  }

  mapShowTopHandler = ({ showMap }) => {
    const { top } = this.state

    const mapTop = document.querySelector('.section-heading-show')
    const mapShowTop = mapTop.offsetHeight + top
    mapShowTop && this.setState({ mapShowTop })
    this.toggleMapHandler({ showMap })
  }

  onBoatHover = (boat = null) => {
    this.setState({ highlightBoatId: boat?.id })
    // const { onMapItemCardHover } = this.props
    // onMapItemCardHover(boat?.id)
  }

  fetchResultOnMapChange = latLng => {
    const { action, value } = this.props
    const { history } = this.context

    const urlParams = new URLSearchParams(history.location.search)
    const { page, ...values } = urlParamsToObject(urlParams)

    history.replace({
      ...history.location,
      search: objectToQuerystring(values),
    })

    if (
      action &&
      JSON.stringify(latLng) !==
        JSON.stringify({
          northEast: [-142.18657315000002, 81.09218014500806],
          southWest: [-24.764698150000015, -23.570106422885377],
        })
    ) {
      this.setState({ latLng, page: 1 })
      setTimeout(() => {
        window.scrollTo(0, 0)
      }, 500)
    }
  }

  toggleMapHandler = ({ showMap }) => {
    const { action, value } = this.props
    this.setState({ page: 1 })
    this.context.history.replace({ ...this.context.history.location, state: { showMap: !showMap } })
  }

  render() {
    const {
      message,
      isMarinaStorage,
      isRent,
      isNotHome,
      isBoatShow,
      boatsType,
      boatsTypeCount,
      isBoatService,
      shareWithUsers,
      isPageLoader,
      action,
      value,
      showAllTitle,
      mapGridClassName,
      content,
    } = this.props
    const { top, isShowMassage, mapShowTop, isShowMapStyle, highlightBoatId, defaultSlice, latLng, boats } = this.state
    const showMap = get(this, 'context.history.location.state.showMap', false)

    return (
      <>
        <Layout className={showMap ? 'map-show-100' : ''}>
          <div className={`${showMap ? 'is-show-map' : ''} w-100`}>
            <Grid
              className={isRent ? 'show-all-secondary section-grid' : 'show-all-primary section-grid'}
              container
              justify="center"
            >
              <Grid item xs={12} className={mapGridClassName}>
                {isShowMapStyle && !!this.state.boats?.length && (
                  <SectionHeadingShowStyle className={showMap ? 'show--map--grid' : 'show--no--map--grid'} top={top}>
                    <Box
                      className="section-heading-show section-show-all-img-inherit"
                      display="flex"
                      fontSize={24}
                      fontWeight={500}
                      letterSpacing={0}
                    >
                      <h1 className="r-h1 mb-0" style={{ textTransform: 'capitalize' }}>
                        {showAllTitle || boatsType}
                      </h1>

                      <FormControlLabel
                        className={isMarinaStorage ? `show-marina-map-switch large-font` : `show-map-switch large-font`}
                        control={
                          <Switch
                            checked={showMap}
                            onChange={e => this.mapShowTopHandler({ e, showMap })}
                            value="showMap"
                            color="primary"
                          />
                        }
                        style={{ fontSize: '16px' }}
                        label={showMap ? 'Show In List' : 'Show Map'}
                      />
                    </Box>
                  </SectionHeadingShowStyle>
                )}

                {!showMap && (
                  <>
                    <div className="page-scroll-div">
                      <Grid
                        className="without-show-map boats-for-sale-months show-all-marina-space-top home-layout-page  boat-show-with-map"
                        container
                        justify="center"
                      >
                        <Grid className="home-page" container spacing={2}>
                          {content && !!this.state.boats?.length && <div className="mb-30">
                            <p>{content}</p>
                          </div>}

                          {/* {isNotHome && !isMarinaStorage && !isRent && !isBoatService && ( */}
                          {isNotHome && !isMarinaStorage && !isRent && !isBoatService && !isBoatShow && (

                            <Fragment>
                              {boats && boats.length > 0 && (
                                <BoatGrid
                                  xs={12}
                                  sm={3}
                                  isTopLabel
                                  route
                                  showType="best-deal-boats"
                                  itemsLength={boats.length}
                                  boatGrid={boats}
                                  className="best--recommended--boats--grid"
                                  showAllText="best recommended boats"
                                />
                              )}
                            </Fragment>
                          )}
                          {isRent && (
                            <Grid className="rent-show-all-grid toped-space-rent-all-grid rent-layout" container spacing={2}>
                              {boats && boats.length > 0 && (
                                <RentSharedTrips
                                  itemsLength={boats.length}
                                  dimensionKey="privateTrip"
                                  className="grid--rent--private--trip"
                                  isShowAllPage
                                  rentSharedTrips={boats}
                                />
                              )}
                            </Grid>
                          )}

                          {isMarinaStorage && (
                            <div className="marina-storage-layout show-marina-storage-layout w-100">
                              <Grid className="most-popular-main cursor-pointer marina-ads-sections" container spacing={2}>
                                {boats && boats.length > 0 && (
                                  <RecommendedMarinaStorages
                                    data={boats}
                                    dimensionKey="marinaMostPopular"
                                    isShowLink={false}
                                    itemsLength={20}
                                  />
                                )}
                              </Grid>
                            </div>
                          )}
                          {isBoatShow && <ShowBoatCards isSearch showBoatCards={boats} />}

                          {isBoatService && (
                            <Grid container spacing={2} className="boat--service--with--map--grid">
                              {boats && boats.length > 0 && (
                                <BoatServicesAdded
                                  boatAddedServices={boats}
                                  iconColor="iconColor-boatInner"
                                  itemLength={boats.length}
                                />
                              )}
                            </Grid>
                          )}
                        </Grid>
                      </Grid>
                    </div>
                    {!this.state.boats?.length
                    && !isPageLoader && <Box className='no--result--found--text'>
                        <img src={require('../../assets/images/search/no_data_found.jpeg')} alt='No data'/>
                        <p>{noDataResult}</p>
                      </Box>}
                    {isPageLoader && <SkeletonLoader type={skeletonType.grid} itemsLength={8} />}
                  </>
                )}

                {showMap && (
                  <div className="grid--w-100--map page-scroll-div">
                    <Grid container className="mt-70 mt--78 glo-mob-map-img-part">
                      <Grid item className="left-card rent-img-part pl-3 pr-3" xs={6}>
                        {isNotHome &&
                          !isMarinaStorage &&
                          !isRent &&
                          !isBoatService &&
                          !isBoatShow &&
                          boats.map((boat, i) => (
                            <BoatCard
                              index={i}
                              boat={boat}
                              key={boat.id}
                              onClick={() => viewBoatHandler(boat)}
                              shareWithUsers={data => shareWithUsers({ data, id: boat.id })}
                              onBoatHover={this.onBoatHover}
                              sliceTo={defaultSlice}
                            />
                          ))}

                        {isRent &&
                          boats.map((boat, i) => (
                            <RentBoatMapCard
                              index={i}
                              boat={boat}
                              key={boat.id}
                              onClick={() => viewRentBoatHandler(boat)}
                              onBoatHover={this.onBoatHover}
                              sliceTo={defaultSlice}
                            />
                          ))}
                        {isMarinaStorage &&
                          boats &&
                          boats.map((value, index) => {
                            return (
                              <Grid key={value.id} item xs={12}>
                                <MarinaStorageMapCard
                                  marinaStorage={value}
                                  xs={12}
                                  index={index}
                                  viewMarina={() => viewMarinaDetails(value)}
                                  textColor="iconColor-marina"
                                  onBoatHover={this.onBoatHover}
                                  sliceTo={defaultSlice}
                                />
                              </Grid>
                            )
                          })}
                          {console.log(boats)}
                        {isBoatShow &&
                          boats &&
                          boats.map((boat, index) => {
                            return (
                              <BoatShowCard
                                index={index}
                                boat={boat}
                                key={boat.id}
                                shareServiceWithUsers={data => shareWithUsers({ data, id: boat.id })}
                                onBoatHover={this.onBoatHover}
                                sliceTo={defaultSlice}
                              />
                            )
                          })}
                        {isBoatService &&
                          boats &&
                          boats.map((boat, index) => {
                            return (
                              <Grid key={boat.id} item xs={12}>
                                <BoatServiceMapCard
                                  index={index}
                                  value={boat}
                                  key={boat.id}
                                  onClick={() => viewServiceBoatHandler(boat)}
                                  shareServiceWithUsers={data => shareWithUsers({ data, id: boat.id })}
                                  onBoatHover={this.onBoatHover}
                                  sliceTo={defaultSlice}
                                />
                              </Grid>
                            )
                          })}

                        {boats && !boats.length && !isPageLoader && (
                          <div className="h-100 d-flex flex-column justify-content-center align-items-center">
                            <Box
                              className="section-heading mb-15 mt-0 d-flex justify-content-center align-items-center"
                              fontSize={24}
                              fontWeight={500}
                              letterSpacing={0.5}
                            >
                              {!isPageLoader && isShowMassage && 'No Results'}
                            </Box>

                            {!isPageLoader && isShowMassage && <span>{message ? message : resultMessage.default}</span>}
                          </div>
                        )}
                      </Grid>

                      <Grid className="map-card-z-index rent-map-part" item xs={6}>
                        <div className="sticky-parent">
                          <MapShowStyle
                            top={mapShowTop + 30}
                            heightPx={mapShowTop + 60}
                            className="show-map map-div-form google-marker-div"
                          >
                            {
                              <GoogleMarker
                                width={'100%'}
                                markers={
                                  isBoatService
                                    ? getBoatServiceMarkers(boats)
                                    : isBoatShow
                                    ? getBoatShowMarker(boats)
                                    : isMarinaStorage
                                    ? getMarinaStorageMarker(boats)
                                    : isRent
                                    ? getRentShowMarkers(boats)
                                    : getBoatMarkers(boats)
                                }
                                highlightBoatId={highlightBoatId}
                                fetchResultOnMapChange={this.fetchResultOnMapChange}
                                isMultiple
                                zoomControlPos="RIGHT_BOTTOM"
                              ></GoogleMarker>
                            }
                          </MapShowStyle>
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                )}
              </Grid>
            </Grid>
          </div>

          {/* {boatsTypeCount > pagination.PAGE_RECORD_LIMIT && (
            <div className="boat-pagination home--show--all--pagination">
              <PaginationBar action={action} value={{ ...value, latLng }} totalRecords={boatsTypeCount} />
            </div>
          )} */}
        </Layout>

        {isPageLoader && showMap && !this.state.boats?.length && <Loader isPageLoader />}
      </>
    )
  }
}
const mapStateToProps = state => ({
  currentUser: state.loginReducer.currentUser,
  highlightBoatId: state.mapReducer.highlightBoatId,
})

const mapDispatchToProps = dispatch => ({
  onMapItemCardHover: data => dispatch(onMapItemCardHover(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(BoatListingsWithMap)

BoatListingsWithMap.defaultProps = {
  isRent: false,
  isNotHome: true,
  boatsType: 'Items',
  boatsTypeCount: 0,
  isShowAuction: true,
  boats: [],
  value: {},
}
