import { useCallback, useEffect, useRef, useState } from 'react'
import { Slide } from 'react-slideshow-image'
import cn from 'classnames'
import * as Icons from '../../assets/icons'
import { PostMediaType } from '../../lib/enums'
import { useVisible } from '../../contexts/visible-ctx'
import { useMuted } from '../../contexts/muted-ctx'
import styles from './media.module.scss'

export default function Media({ post }) {
  const postId = post._id
  const postMedia = post.media

  const [visibleId, setVisibleId, setNotVisibleId] = useVisible()
  const [muted, setMuted] = useMuted()

  const [currentSlide, setCurrentSlide] = useState(0)
  const [fullscreen, setFullscreen] = useState(false)

  const isVisibleRef = useRef(visibleId === postId)

  /** @type {React.MutableRefObject<HTMLDivElement>} */
  const mediaRef = useRef()

  /** @type {React.MutableRefObject<import('react-slideshow-image').SlideshowRef>} */
  const sliderRef = useRef()

  /** @type {React.MutableRefObject<number>} */
  const sliderTimerRef = useRef()

  /** @type {React.MutableRefObject<HTMLDivElement>} */
  const progressBarRef = useRef()

  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting && entry.intersectionRatio === 1) {
            setVisibleId(postId)
          } else {
            setNotVisibleId(postId)
          }
        })
      },
      { threshold: 1 }
    )

    if (mediaRef.current) {
      observer.observe(mediaRef.current)
    }

    return () => {
      if (mediaRef.current) {
        observer.unobserve(mediaRef.current)
      }
    }
  }, [postId, setVisibleId, setNotVisibleId])

  useEffect(() => {
    isVisibleRef.current = visibleId === postId

    if (isVisibleRef.current) {
      sliderRef.current.goTo(0)
    } else {
      progressBarRef.current.style.transitionDuration = '0s'
      progressBarRef.current.style.width = 0

      const videos = mediaRef.current.querySelectorAll('video')

      videos.forEach(video => {
        if (video) {
          video.pause()
          video.currentTime = 0
        }
      })
    }
  }, [visibleId, postId])

  const onSlideChange = useCallback(
    async (prev, next) => {
      clearTimeout(sliderTimerRef.current)

      const prevSlide = postMedia[prev]

      if (prevSlide.type === PostMediaType.Video) {
        /** @type {HTMLVideoElement} */
        const video = document.getElementById(`post-${postId}-video-${prev}`)

        if (video) {
          video.pause()
        }
      }

      progressBarRef.current.style.transitionDuration = '0s'
      progressBarRef.current.style.width = 0

      setCurrentSlide(next)

      const currentSlide = postMedia[next]

      if (currentSlide.type === PostMediaType.Video) {
        /** @type {HTMLVideoElement} */
        const video = document.getElementById(`post-${postId}-video-${next}`)

        if (video) {
          await video.play()

          progressBarRef.current.style.transitionDuration = `${video.duration}s`
          progressBarRef.current.style.width = 'calc(100% - 40px)'

          video.onended = () => {
            if (isVisibleRef.current) {
              if (next + 1 >= postMedia.length) {
                sliderRef.current.goTo(0)
              } else {
                sliderRef.current.goNext()
              }
            }
          }
        }
      } else {
        setTimeout(() => {
          progressBarRef.current.style.transitionDuration = '3s'
          progressBarRef.current.style.width = 'calc(100% - 40px)'
        }, 0)

        sliderTimerRef.current = setTimeout(() => {
          if (isVisibleRef.current) {
            if (next + 1 >= postMedia.length) {
              sliderRef.current.goTo(0)
            } else {
              sliderRef.current.goNext()
            }
          }
        }, 3000)
      }
    },
    [postMedia]
  )

  return (
    <div ref={mediaRef} className={styles.postMediasWrp}>
      <Slide
        ref={sliderRef}
        slidesToShow={1}
        slidesToScroll={1}
        arrows={false}
        infinite={false}
        autoplay={false}
        transitionDuration={300}
        canSwipe={postMedia.length > 1}
        onChange={onSlideChange}
      >
        {postMedia.map((m, i) => (
          <div className={styles.postMediaWrp} style={{ maxHeight: fullscreen ? '100vh' : '90vh' }}>
            {m.type === PostMediaType.Image ? (
              <img src={m.largeUrl} alt={post.user.firstName} className={styles.postMedia} />
            ) : (
              <video src={m.url} muted={muted} id={`post-${postId}-video-${i}`} className={styles.postMedia} />
            )}
          </div>
        ))}
      </Slide>

      <div className={styles.mediaActBtns}>
        {postMedia.length > 1 && (
          <div className={styles.mediaActBtnGrp}>
            <button
              className={cn(styles.mediaActBtn, styles.mediaActIconBtn)}
              onClick={() => {
                if (currentSlide == 0) {
                  sliderRef.current.goTo(postMedia.length - 1)
                } else {
                  sliderRef.current.goBack()
                }
              }}
            >
              <Icons.ArrowLeft className={styles.mediaActBtnIcon} />
            </button>

            <button className={cn(styles.mediaActBtn, styles.mediaActBtnSlidesCount, styles.mediaActBtnStatic)}>
              {currentSlide + 1} / {postMedia.length}
            </button>

            <button
              className={cn(styles.mediaActBtn, styles.mediaActIconBtn)}
              onClick={() => {
                if (currentSlide + 1 >= postMedia.length) {
                  sliderRef.current.goTo(0)
                } else {
                  sliderRef.current.goNext()
                }
              }}
            >
              <Icons.ArrowRight className={styles.mediaActBtnIcon} />
            </button>
          </div>
        )}

        <button className={cn(styles.mediaActBtn, styles.mediaActIconBtn)} onClick={() => setMuted(muted => !muted)}>
          {muted ? (
            <Icons.VolumeMute className={styles.mediaActBtnIcon} />
          ) : (
            <Icons.Volume className={styles.mediaActBtnIcon} />
          )}
        </button>

        <button
          className={cn(styles.mediaActBtn, styles.mediaActIconBtn)}
          onClick={() => {
            const element = mediaRef.current

            if (
              !document.fullscreenElement &&
              !document.mozFullScreenElement &&
              !document.webkitFullscreenElement &&
              !document.msFullscreenElement
            ) {
              if (element.requestFullscreen) {
                element.requestFullscreen()
              } else if (element.mozRequestFullScreen) {
                element.mozRequestFullScreen()
              } else if (element.webkitRequestFullscreen) {
                element.webkitRequestFullscreen()
              } else if (element.msRequestFullscreen) {
                element.msRequestFullscreen()
              }

              setFullscreen(true)
            } else {
              if (document.exitFullscreen) {
                document.exitFullscreen()
              } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen()
              } else if (document.webkitExitFullscreen) {
                document.webkitExitFullscreen()
              } else if (document.msExitFullscreen) {
                document.msExitFullscreen()
              }

              setFullscreen(false)
            }
          }}
        >
          <Icons.Expand className={styles.mediaActBtnIcon} />
        </button>
      </div>

      <div ref={progressBarRef} className={styles.progressBar} />
    </div>
  )
}
