import React, { Component } from 'react'
import { v4 as uuid } from 'uuid'
import { connect } from 'react-redux'

import { Grid } from '@material-ui/core'
import { Link } from 'react-router-dom'
import { Formik, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import { FaAngleDown, FaAngleLeft, FaArrowLeft, FaCaretDown, FaEyeSlash } from 'react-icons/fa'
import { FaEye } from 'react-icons/fa'
import { IoIosArrowDown, IoIosArrowDropleft, IoMdArrowDropleft, IoMdPerson } from 'react-icons/io'
import MailOutlineIcon from '@material-ui/icons/MailOutline'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'

import ErrorIcon from '@material-ui/icons/Error'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'

import {
  register,
  clearAuthorizationFlag,
  getUserRoles,
  setCurrentLocation,
  getAllPageInformationByType,
  getUserLocation,
  getCategoriesWiseBanners,
  clearErrorMessageShow,
  convertWishlistsFromSessionToUser,
  requestAgreementId,
  getUserByAgreementId,
  clearAgreementIdData,
  clearAgreementIdDataFlag,
  updateUserDetailByAgreementId,
  assignAgreement,
  getCountryDetails,
} from '../../redux/actions'
import { Loader } from '../../components'
import { Notification, Field } from '../../components'
import {
  userRoles,
  providerOptions,
  latLng,
  AddressInputTypeEnum,
  passwordStrengthEnum,
  footerLinks,
} from '../../util/enums/enums'
import TermAndPolicy from '../../components/termAndPolicy'
import {
  getAgreementRole,
  checkAgreementId,
  validatePassword,
  passwordStrength,
  cityCountryNameFormatter,
} from '../../helpers/jsxHelper'
import { requireMessage, validationMessages, nameFormatter, trimmedValue } from '../../helpers/string'
import ErrorComponent from '../../components/error/errorComponent'
import GoogleMap from '../../components/map/map'
import styles from './register.module.scss'
import '../../styles/common.scss'
import './register.scss'
import '../../styles/registerResponsive.scss'
import { LogInSignupBanner } from '../../components/styleComponent/styleComponent'
import {
  getAllPageInfoByType,
  clearPageInfoByTypeFlag,
  getStaticPages,
  clear_getStaticPages,
} from '../../redux/actions/pageInfoByTypeAction'
import Captcha from '../../components/helper/captcha'
import ErrorFocus from '../../components/ws/ErrorFocus'
import { CommonModal } from '../../components/modal/commonModal'
import PasswordValidation from '../../components/passwordValidations/passwordValidation'
import { ButtonComponent } from '../../components/form/Button'
import { isValidMobileNumber } from '../../helpers/validationHelper'
import { stringValidation } from '../../helpers/yupHelper'
import { getImgUrl } from '../../util/utilFunctions'
import Select from 'react-select'
import { isEmpty } from 'lodash'
import ZohoChatWidget from '../../components/zoho/ZohoChatWidget'
import { CommonTooltip } from '../../components/CommonTooltip'
import { Tooltip } from 'react-tooltip'
import classNames from 'classnames'
import { ArrowLeft } from '@mui/icons-material'

class Register extends Component {
  constructor(props) {
    super(props)
    this.state = {
      newUser: {
        country: '',
        // role: "",
        agreementId: '',
        companyName: '',
        firstName: '',
        lastName: '',
        email: '',
        mobileNumber: '',
        password: '',
        address: '',
        roleAlias: '',
        agree: false,
        // hoveredOption : null,
      },

      type: 'password',
      wording: <FaEye />,

      termsModal: false,
      privacyModal: false,
      errorMessages: {},

      isCheckedAgreementId: false,
      openMapDialog: false,

      isDisplayHint: false,

      addressInput: [],
      formSubmitted: false,

      isUserFound: false,
      isWrongAgreementId: true,
      agreementIdMisMatch: false,
      agreementRoleType: '',
      wrongAgreementId: false,
      isVerifyCaptch: false,
      recaptchaRef: null,
      isDisabled: false,
      isRole: true,
      isMenuOpen: false,

      showGuide: true,
      accTypeDdOpen: !props.isInline,

      isCountryBlocked: false,
    }
    this.selectRef = React.createRef()
  }

  handleMenuOpen = () => {
    this.setState({ showGuide: false, isMenuOpen: true })
  }

  handleMenuClose = () => {
    this.setState({ isMenuOpen: false })
  }

  handleMouseEnter = () => {
    if (!this.state.isMenuOpen) {
      this.handleMenuOpen()
    }
  }

  handleMouseLeave = () => {
    if (this.state.isMenuOpen) {
      this.handleMenuClose()
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const {
      isAuthorized,
      history,
      clearAuthFlag,
      location,
      match,
      currentLocation,
      convertWishlistsFromSessionToUser,
      getByAgreementIdSuccess,
      agreementUser,
      removeData,
      clearAgreementIdDataFlag,
      userVerified,
      clearErrorMessageShow,
      registerSuccess,
      isAgreementUser,
      getAllPageInformationByType,
      getByAgreementIdError,
      roles: allRoles,
      isInline,
      registerType,
    } = nextProps
    const { newUser, isRole, roles } = prevState
    // console.log(nextProps)
    // console.log(prevState)

    if (getByAgreementIdError) {
      newUser.firstName = ''
      newUser.lastName = ''
      newUser.email = ''
      newUser.companyName = ''
      newUser.mobileNumber = ''
      return {
        newUser,
        isUserFound: false,
      }
    }
    if (registerSuccess && isAgreementUser) {
      history.push('/login')
    }
    if (userVerified && agreementUser.role?.id !== newUser.role) {
      return {
        agreementIdMisMatch: true,
      }
    }
    if (isRole && newUser.roleType) {
      getAllPageInformationByType({
        input: { module: newUser.roleAlias },
      })
      return {
        isRole: false,
      }
    }
    if (getByAgreementIdSuccess) {
      clearErrorMessageShow()

      if (agreementUser.role.id === newUser.role) {
        newUser.country = agreementUser.country
        newUser.firstName = agreementUser.firstName
        newUser.lastName = agreementUser.lastName
        newUser.email = agreementUser.email
        newUser.companyName = agreementUser.companyName
        newUser.agreementId = agreementUser.agreementId
        newUser.mobileNumber = agreementUser.mobileNumber
        return {
          newUser,
          isUserFound: true,
          isWrongAgreementId: false,
          isDisabled: true,
        }
      }
    }

    if (removeData) {
      clearAgreementIdDataFlag()
      newUser.firstName = ''
      newUser.lastName = ''
      newUser.email = ''
      newUser.companyName = ''
      newUser.agreementId = ''
      newUser.mobileNumber = ''
      return {
        newUser,
        isUserFound: false,
      }
    }
    if (!newUser.country && currentLocation) {
      newUser.country = currentLocation
      return {
        newUser,
      }
    }
    if (isAuthorized) {
      history.push({ pathname: '/dashboard', state: { showFirstTimeGuide: 1 } })
      clearAuthFlag()
      convertWishlistsFromSessionToUser()
      setTimeout(() => {
        window.scrollTo(0, 0)
      }, 1000);
    } else if ((match?.params?.type || isInline) && !isEmpty(roles)) {
      const role = roles.find(item => item.aliasName === (match?.params?.type || registerType))
      const inAllRole = allRoles.find(item => item.aliasName === (match?.params?.type || registerType) && item.type !== 'administration')

      if (role) {
        newUser.role = role.id
        newUser.roleType = role.role
        newUser.roleAlias = role.aliasName
        newUser.country = currentLocation

        return { newUser, isCountryBlocked: false }
      } else if (inAllRole) {
        return { isCountryBlocked: inAllRole.role }
      } else {
        nextProps.history.push('/')
      }
    } else if (roles && roles.length) {
      // const role = roles.find(item => item.aliasName === userRoles.MEMBER)
      // if (role) {
      //   newUser.role = role.id
      //   newUser.roleType = role.role
      //   newUser.roleAlias = role.aliasName
      //   newUser.country = currentLocation
      //   return { newUser }
      // }
    }

    return null
  }

  createMarkup = data => {
    return { __html: data }
  }

  componentDidUpdate(prevProps, prevState) {
    if (isEmpty(this.props.countryDetails) && this.state.newUser.country && !this.state.countryDetailsHit) {
      this.props.getCountryDetails(this.state.newUser.country)
      this.setState({ countryDetailsHit: true })
    }

    if (!isEmpty(this.props.roles) && !isEmpty(this.props.countryDetails) && !this.state.rolesHit) {
      this.setState({
        rolesHit: true,
        roles: this.props.roles.filter(role => {
          if (role.type === 'administration') {
            return false
          }

          const rolesValidMap = {
            [userRoles.MEMBER]: 'mamberStatus',
            [userRoles.AGENT]: 'agentStatus',
            [userRoles.BOAT_OWNER]: 'boatOwerStatus',
            [userRoles.BOAT_MANUFACTURER]: 'boatManufacturerStatus',
            [userRoles.SERVICE_AND_MAINTENANCE]: 'serviceStatus',
            [userRoles.BROKER_AND_DEALER]: 'brokerAndDealerStatus',
            [userRoles.MARINA_AND_STORAGE]: 'marinaStorageStatus',
            [userRoles.RENT_AND_CHARTER]: 'rentStatus',
            [userRoles.YACHT_SHIPPER]: 'ShipperStatus',
            [userRoles.SURVEYOR]: 'surveyorStatus',
          }

          return !this.props.countryDetails[rolesValidMap[role.aliasName]]
        }),
      })
    }

    if (this.props.registerFailure && this.state.formSubmitted) {
      this.setState({
        formSubmitted: false,
      })
    }
  }

  componentWillUnmount() {
    const { clearErrorMessageShow, clearAgreementIdData, clearAgreementIdDataFlag } = this.props
    clearErrorMessageShow()
    clearAgreementIdData()
    clearAgreementIdDataFlag()

    if (this.props.staticPages?.success || this.props.staticPages?.failure) {
      this.props.clear_getStaticPages()
    }
  }

  async componentDidMount() {
    const { getUserLocation, clearErrorMessageShow } = this.props
    const { newUser } = this.state
    const { country } = newUser

    this.props.getStaticPages({ slug: [footerLinks.Terms_Of_Use, footerLinks.Privacy_Policy] })

    clearErrorMessageShow()
    if (!country) {
      // getGeoLocation(this.fetchCountry, true);
      getUserLocation()
    }

    const { getUserRoles, getCategoriesWiseBanners } = this.props
    await getUserRoles()
    const singUpInput = {
      type: 'signUp',
      fieldName: 'signUpBanner',
    }
    await getCategoriesWiseBanners(singUpInput)
    // await getAllPageInformationByType( {input :{}});
  }

  // fetchCountry = response => {
  //   const { country } = response;
  //   const { newUser } = this.state;
  //   const { setCurrentLocation } = this.props;

  //   newUser.country = country;
  //   this.setState({ newUser });
  //   setCurrentLocation(country);
  // };

  termsHandler = () => {
    this.setState(prevState => ({
      termsModal: !prevState.termsModal,
    }))
  }

  privacyPolicyHandler = () => {
    this.setState(prevState => ({
      privacyModal: !prevState.privacyModal,
    }))
  }

  changeState = () => {
    const { type } = this.state

    const newType = type === 'password' ? 'text' : 'password'
    const newWord = type === 'password' ? <FaEyeSlash /> : <FaEye />

    this.setState({ type: newType, wording: newWord })
  }

  checkUserRolesForMobile = newUser => {
    const selectedUserRoles = [userRoles.BOAT_MANUFACTURER, userRoles.SURVEYOR, userRoles.YACHT_SHIPPER, userRoles.MEMBER]
    return selectedUserRoles.includes(newUser.roleAlias)
  }

  checkUserRolesForCompanyLogo = newUser => {
    const selectedUserRoles = [userRoles.BOAT_MANUFACTURER, userRoles.SURVEYOR, userRoles.YACHT_SHIPPER]

    return selectedUserRoles.includes(newUser.roleAlias)
  }

  checkUserRolesOfficeLocation = newUser => {
    const selectedUserRoles = [userRoles.SURVEYOR]

    return selectedUserRoles.includes(newUser.roleAlias)
  }

  checkUserRolesForPreference = newUser => {
    const selectedUserRoles = [userRoles.RENT_AND_CHARTER]

    return selectedUserRoles.includes(newUser.roleAlias)
  }

  checkUserRolesForAgreementId = newUser => {
    const selectedUserRoles = [userRoles.BOAT_MANUFACTURER, userRoles.YACHT_SHIPPER, userRoles.SURVEYOR]

    return selectedUserRoles.includes(newUser.roleAlias)
  }

  checkUserRolesForCompanyName = newUser => {
    const selectedUserRoles = [
      userRoles.BROKER_AND_DEALER,
      userRoles.BOAT_MANUFACTURER,
      userRoles.SERVICE_AND_MAINTENANCE,
      userRoles.MARINA_AND_STORAGE,
      userRoles.YACHT_SHIPPER,
      userRoles.SURVEYOR,
    ]

    return selectedUserRoles.includes(newUser.roleAlias)
  }

  agreementIdCheckHandler = setFieldValue => {
    const { clearAgreementIdData } = this.props
    const { isCheckedAgreementId } = this.state

    if (isCheckedAgreementId) {
      setFieldValue('agreementId', '')
      clearAgreementIdData(0)
    }
    this.setState(prevState => ({
      isCheckedAgreementId: !prevState.isCheckedAgreementId,
    }))
  }

  fetchMapInfo = (result, setValue) => {
    const { latitude, longitude, ...resultInput } = result

    setValue('address', result.address1)

    this.setState({ latitude, longitude, addressInput: [{ ...resultInput, type: AddressInputTypeEnum.OFFICE_ADDRESS }] })
  }

  mapHandler = () => {
    this.setState(preState => ({ openMapDialog: !preState.openMapDialog }))
  }

  validate = (e, setValue, email = '') => {
    const { errorMessages } = this.state
    const password = e.target.value
    const errors = validatePassword(password, email)

    this.setState({
      errorMessages: errors,
    })

    const pwdStrength = passwordStrength(password, setValue)

    this.setState({
      passwordStrength: pwdStrength,
      isDisplayHint: true,
    })
  }

  getRoleWiseValidations = (newUser, verification) => {
    const afterAgreementValidations = verification
      ? {
          address: Yup.string().required(requireMessage('Address')),
        }
      : {}

    return newUser.roleAlias === userRoles.MEMBER
      ? this.memberValidations()
      : newUser.roleAlias === userRoles.BROKER_AND_DEALER ||
        newUser.roleAlias === userRoles.SERVICE_AND_MAINTENANCE ||
        newUser.roleAlias === userRoles.MARINA_AND_STORAGE
      ? this.brokerValidations()
      : newUser.roleAlias === userRoles.BOAT_MANUFACTURER || newUser.roleAlias === userRoles.YACHT_SHIPPER
      ? { ...this.manufacturerValidations() }
      : newUser.roleAlias === userRoles.SURVEYOR
      ? { ...this.surveyorValidations(), ...afterAgreementValidations }
      : newUser.roleAlias === userRoles.RENT_AND_CHARTER
      ? this.rentAndCharterValidation()
      : {}
  }

  rentAndCharterValidation = () => {
    return {
      provider: Yup.string().required('Please select provider.'),
    }
  }

  memberValidations = () => {
    return {
      mobileNumber: Yup.string()
        .test('match', validationMessages.validMobileNumber, val => isValidMobileNumber(val))
        .required(requireMessage('Mobile Number'))
        .ensure(),
    }
  }

  brokerValidations = () => {
    return {
      companyName: stringValidation.required(requireMessage('Company Name')),
    }
  }

  manufacturerValidations = () => {
    return {
      companyName: stringValidation.required(requireMessage('Company Name')),
      mobileNumber: Yup.string()
        .test('match', validationMessages.validMobileNumber, val => isValidMobileNumber(val))
        .required(requireMessage('Mobile Number'))
        .ensure(),
    }
  }

  surveyorValidations = () => {
    const rules = this.manufacturerValidations()
    return rules
  }

  agreementValidations = () => {
    return {
      agreementId: stringValidation.required(requireMessage('Agreement Id')),
    }
  }

  getUser = agreementId => {
    const { getUserByAgreementId } = this.props
    getUserByAgreementId({ agreementId })
  }

  getUserDetail = values => {
    const userData = {
      agreementId: values.agreementId,
      data: {
        firstName: values.firstName,
        lastName: values.lastName,
        companyWebsite: values.companyWebsite,
        mobileNumber: values.mobileNumber,
        companyName: values.companyName,
        email: values.email,
        // role: values.role
      },
    }
    return userData
  }

  render() {
    const {
      newUser,
      passwordStrength,
      errorMessages,
      isCheckedAgreementId,
      type,
      wording,
      termsModal,
      privacyModal,
      openMapDialog,
      isDisplayHint,
      latitude,
      longitude,
      formSubmitted,
      addressInput,
      recaptchaRef,
      isVerifyCaptch,
      isDisabled,
    } = this.state
    const {
      register,
      assignAgreement,
      isError,
      errorMessage,
      infoList,
      signUpBanners,
      clearErrorMessageShow,
      infoData,
      loader,
      userVerified,
      clearAgreementIdData,
      agreementUser,
    } = this.props
    const agreement = this.checkUserRolesForAgreementId(newUser)
    const agreementValidations = agreement && isCheckedAgreementId ? this.agreementValidations() : {}
    const agreementRole = newUser && getAgreementRole(newUser.roleAlias)
    const agreementRoleValidation =
      agreementRole && !userVerified
        ? {}
        : {
            password: Yup.string().required(requireMessage('Password')),
          }
    const userVerification = agreementRole
      ? userVerified && agreementUser && agreementUser.role && agreementUser.role.id === newUser.role
      : true
    const roleValidations = this.getRoleWiseValidations(newUser, userVerification)

    const officeAddressDisplay = addressInput?.find(item => item.type === AddressInputTypeEnum.OFFICE_ADDRESS)

    const banner = signUpBanners?.length ? signUpBanners.find(b => b.role === newUser?.roleAlias)?.thumbnail[0] : null

    const roleTooltips = {
      [userRoles.AGENT]: `Manage each boat sales transaction by connecting with buyers, sellers, shippers, and facilitating the sales process.`,
      [userRoles.BOAT_MANUFACTURER]: `Open your online shop to be a supplier of water toys or any type of boats. Listing is FREE, we manage  shipment and payout for each transaction.`,
      [userRoles.BOAT_OWNER]: `List your boat for free and sell it quickly online; we'll manage the shipment and payout through our secure payment system.`,
      [userRoles.BROKER_AND_DEALER]: `List all your new and used boats for free, sell them online, and we will handle the shipment and payout for each transaction.`,
      [userRoles.MARINA_AND_STORAGE]: `List your marina and storage with us. Keep adding all your events and boat shows on your page. Connect with boat owners and related users.`,
      [userRoles.MEMBER]: `Buy a boat or rent one for a trip, stay connected with other users, list articles, and post updates. Join the marine community.`,
      [userRoles.RENT_AND_CHARTER]: `Own a kayak or larger boats for rent? List them all for free. We will manage your bookings and ensure payouts for each trip.`,
      [userRoles.SERVICE_AND_MAINTENANCE]: `List your business online, add your services on one page, expand your audience, and stay connected with your customers on AdamSea.`,
      [userRoles.SURVEYOR]: `Increase your boat survey income by surveying more boats near you, get paid for each job with AdamSea's advance payment system.`,
      [userRoles.YACHT_SHIPPER]: `List your business online, add your services on one page, expand your audience, and stay connected with your customers on AdamSea.`,
    }

    return (
      <>
        <ZohoChatWidget />
        <Notification />
        <Grid container className={classNames("flex-1 overflow-hidden p-3 login-responsive regi-responsive register-responsive", !this.props.isInline && 'h100-vh')}>
          {!this.props.isInline && <Grid item className="custom-content-column img-holder register-background-img no-bg-blue" sm={6}>
            <LogInSignupBanner
              img={banner && encodeURI(getImgUrl(banner))}
              className="h-100 d-flex flex-column"
              style={{ borderRadius: 48 }}
            >
              <div className="flex-1 d-flex flex-column" style={{ backgroundColor: '#0007', borderRadius: 48 }}>
                <div className="website-logo reg-website-logo">
                  <Link to="/">
                    <div className="logo">
                      <img
                        loading="lazy"
                        className="logo-size"
                        src={require('../../assets/images/login/logo-white.png')}
                        alt="logo"
                      />
                    </div>
                  </Link>
                  <h2 className="reg-user-role">
                    {newUser?.roleAlias === userRoles.SERVICE_AND_MAINTENANCE ? 'BOAT SERVICE'
                      : newUser?.roleAlias === userRoles.YACHT_SHIPPER ? 'BOAT SHIPPER'
                      : newUser?.roleType}
                  </h2>
                </div>
                <Grid item sm={12} className="flex-1">
                  <div className="d-flex justify-content-center align-items-start flex-column registerMain-div">
                    <div className="d-flex justify-content-center flex-column">
                      <h3 className="text-white registration-free-text"> Register for free</h3>
                      <div className="d-flex align-self-start flex-column registrationFree-div">
                        <span className="text-white registrationJoin-font website-join-text">
                          Join us today to start selling and
                        </span>
                        <span className="text-left text-white registrationWorldWide-text website-join-text">
                          buying boats online.
                        </span>
                      </div>
                    </div>

                    <div className="d-flex float-left flex-column registration--div">
                      {infoList && infoList.length
                        ? infoList.map((data, index) => (
                            <div key={uuid()} className="d-flex justify-content-start align-items-center registration-infoDiv">
                              <span>
                                <span dangerouslySetInnerHTML={{ __html: data.header }} />
                              </span>
                            </div>
                          ))
                        : null}
                    </div>

                    <TermAndPolicy className="register-terms-policy" />
                  </div>
                </Grid>
              </div>
            </LogInSignupBanner>
          </Grid>}

          <Grid item sm={this.props.isInline ? 12 : 6} className="overflow-auto custom-form-column h-100">
            <Formik
              initialValues={{ ...newUser }}
              enableReinitialize
              validationSchema={Yup.object().shape({
                firstName: stringValidation.required(requireMessage('First Name')),
                lastName: stringValidation.required(requireMessage('Last Name')),
                email: Yup.string().email('Email is invalid.').required(requireMessage('Email')),
                agree: Yup.mixed().oneOf([true], 'Please accept terms and conditions.'),
                ...roleValidations,
                ...agreementValidations,
                ...agreementRoleValidation,
              })}
              onSubmit={async values => {
                // console.log(values)

                const token = await recaptchaRef.current.execute()
                if (token) {
                  this.setState({ formSubmitted: true })
                  this.setState({ isVerifyCaptch: false })
                  clearErrorMessageShow()
                  const { errorMessages, passwordStrength } = this.state
                  const { agree, roleType, roleAlias, country, mobileNumber, role, ...newValues } = values
                  if (
                    !errorMessages.symbolOrNumberCount &&
                    !errorMessages.passwordLength &&
                    (passwordStrength !== passwordStrengthEnum.POOR || passwordStrength !== passwordStrengthEnum.WEAK)
                  ) {
                    addressInput.push({ type: AddressInputTypeEnum.COMMON_ADDRESS, country: newUser.country })
                    userVerified && assignAgreement({ agreementId: values.agreementId })
                    clearAgreementIdData()
                    register({
                      ...newValues,
                      mobileNumber: isValidMobileNumber(mobileNumber, false),
                      // role: newUser.role,
                      userType: newUser.roleAlias,
                      address: addressInput,
                    })
                  }
                } else {
                  this.setState({ isVerifyCaptch: true })
                }
              }}
              render={({ errors, touched, setFieldValue, values, handleSubmit }) => (
                <div className="form-holder register-form-holder h-100">
                  {loader ? (
                    <Loader isSmall isPageLoader />
                  ) : (
                    <div className={classNames("form-content form-text-fix d-flex flex-column h-100", this.props.isInline && 'p-3')}>
                      <div className="reg-form-logo">
                        <Link to="/">
                          <img src={require('../../assets/images/logo/home.png')} alt="AdamSea Logo" height={24} />
                        </Link>
                      </div>

                      <div className="form-items">
                        {userVerified && agreementUser && newUser && agreementUser?.role?.id !== newUser?.id && (
                          <div className="agreement--id--for--error">
                            <p
                              className={`${
                                newUser.roleType !== agreementUser.role?.role ? 'alert-danger' : 'alert-success'
                              } mb-0`}
                            >
                              {newUser.roleType !== agreementUser.role?.role ? <ErrorIcon /> : <CheckCircleOutlineIcon />} This
                              Agreement ID is for {agreementUser.role.role}
                            </p>
                          </div>
                        )}

                        {!this.props.isInline && <div className={styles.accTypeField}>
                          <button
                            className={styles.accTypeFieldBtn}
                            onClick={() => this.setState(prev => ({ accTypeDdOpen: !prev.accTypeDdOpen }))}
                          >
                            <label className={styles.accTypeFieldBtnLabel}>
                              {newUser?.roleType
                                ? 'Register as ' +
                                  (newUser?.roleAlias === userRoles.SERVICE_AND_MAINTENANCE ? 'Boat Service'
                                    : newUser?.roleAlias === userRoles.YACHT_SHIPPER ? 'Boat Shipper'
                                    : newUser?.roleType)
                                : 'Select user to register'}
                            </label>
                            <span className={styles.accTypeFieldIcon}>
                              <FaAngleDown />
                            </span>
                          </button>

                          {!!this.state.roles?.length && this.state.accTypeDdOpen && (
                            <div className={styles.accTypeFieldDd} onMouseLeave={() => this.setState({ accTypeDdOpen: false })}>
                              <div className={styles.accTypeFieldDdItems}>
                                {this.state.roles.map(role => (
                                  <div
                                    className={classNames(
                                      styles.accTypeFieldDdItem,
                                      newUser?.roleAlias === role.aliasName && styles.accTypeFieldDdItemActive
                                    )}
                                  >
                                    <button
                                      className={styles.accTypeFieldDdItemBtn}
                                      data-tooltip-id={`regAccTypeTooltip_${role.aliasName}`}
                                      data-tooltip-content={roleTooltips[role.aliasName]}
                                      onClick={() => {
                                        this.props.history.push(`/register/${role.aliasName}`)
                                        this.setState({ accTypeDdOpen: false })
                                      }}
                                    >
                                      {String(
                                        role.aliasName === userRoles.SERVICE_AND_MAINTENANCE ? 'Boat Service'
                                          : role.aliasName === userRoles.YACHT_SHIPPER ? 'Boat Shipper' 
                                          : role.role
                                      ).toLowerCase()}
                                    </button>

                                    <Tooltip
                                      id={`regAccTypeTooltip_${role.aliasName}`}
                                      className={styles.regAccTypeTooltip}
                                      place="bottom"
                                    />
                                  </div>
                                ))}
                              </div>
                            </div>
                          )}
                        </div>}

                        {/* <p style={{ position: 'relative', zIndex: 999999999 }}>
                          {!!this.state.roles?.length && (
                            <div onMouseEnter={this.handleMouseEnter} onMouseLeave={this.handleMouseLeave} ref={this.selectRef}>
                              <Select
                                value={{
                                  label:
                                    (newUser?.roleAlias === 'service-and-maintenance' ? 'BOAT SERVICE' : newUser?.roleType) ||
                                    'Choose account type',
                                  value: newUser?.roleAlias,
                                }}
                                key={this.state.roles?.length}
                                options={this.state.roles.map(role => ({
                                  label: (
                                    <div>
                                      <div
                                        style={{
                                          color: '#000',
                                          fontWeight: 'bold',
                                          fontSize: '1.1em',
                                        }}
                                      >
                                        {role.aliasName === 'service-and-maintenance' ? 'BOAT SERVICE' : role.role}{' '}
                                      </div>
                                      <div
                                        style={{
                                          marginTop: '0.5em',
                                          fontWeight: 'normal',
                                          fontSize: 16,
                                          lineHeight: 'normal',
                                        }}
                                      >
                                        {roleTooltips[role.aliasName]}
                                      </div>
                                    </div>
                                  ),
                                  value: role.aliasName,
                                }))}
                                onChange={selected => {
                                  this.props.history.push({
                                    pathname: `/register/${selected?.value}`,
                                  })
                                }}
                                menuIsOpen={this.state.isMenuOpen}
                                onMenuOpen={this.handleMenuOpen}
                                onMenuClose={this.handleMenuClose}
                                autoFocus={false}
                                ref={this.selectRef}
                                styles={{
                                  control: baseStyles => ({
                                    ...baseStyles,
                                    borderRadius: 50,
                                  }),
                                  valueContainer: baseStyles => ({
                                    ...baseStyles,
                                    paddingLeft: 15,
                                  }),
                                  indicatorSeparator: baseStyles => ({
                                    ...baseStyles,
                                    display: 'none',
                                  }),
                                  menu: baseStyles => ({
                                    ...baseStyles,
                                    overflow: 'hidden',
                                    borderRadius: 15,
                                    marginTop: '0rem',
                                  }),
                                }}
                              />
                            </div>
                          )}
                        </p> */}
                        {/* <CommonTooltip
                          className="common-tooltip-guide"
                          place="bottom"
                          key={Math.random()}
                          isOpen={this.state.showGuide}
                          clickable
                          closeOnScroll={false}
                          closeOnResize={false}
                          content={
                            <>
                              <div style={{ textAlign: 'center' }}>Select the user you want to register.</div>
                              <div style={{ textAlign: 'center' }}>
                                <button
                                  className="btn common-tooltip-button"
                                  onClick={() => this.setState({ showGuide: false })}
                                >
                                  Got it
                                </button>
                              </div>
                            </>
                          }
                          component={ctProps => <div data-tooltip-id={ctProps.id} data-tooltip-place="bottom"></div>}
                        /> */}
                        {newUser?.role && !this.state.accTypeDdOpen && (
                          <Form>
                            <ErrorFocus />
                            <Grid container spacing={2} className="register-input-field">
                              {this.checkUserRolesForAgreementId(newUser) && (
                                <div className={'agreement-checkbox-shadow' + (isCheckedAgreementId ? ' active' : '')}>
                                  <Grid item xs={12}>
                                    <div className="d-flex align-center agreement-align-center">
                                      <label for="agreement" className="agreement-label mb-0">
                                        Do you have an agreement ID?{' '}
                                      </label>
                                      <input
                                        id="agreement"
                                        className="mb-0"
                                        type="checkbox"
                                        value={isCheckedAgreementId}
                                        onChange={() => this.agreementIdCheckHandler(setFieldValue)}
                                        checked={isCheckedAgreementId}
                                      />
                                    </div>
                                    {!isCheckedAgreementId && (
                                      <div className="agreement-label mt-3">If not, fill out the form to generate one.</div>
                                    )}
                                  </Grid>
                                  {isCheckedAgreementId && (
                                    <Grid item xs={12}>
                                      <div className={`${isCheckedAgreementId ? 'agreement--input' : ''} position-relative`}>
                                        <input
                                          name="agreementId"
                                          id="agreementId"
                                          type="text"
                                          value={values.agreementId}
                                          className="form-control font-16"
                                          placeholder="Agreement ID"
                                          // disabled={userVerified}
                                          onChange={e => {
                                            const value = trimmedValue(e.target.value)

                                            const agreementIdValidate = checkAgreementId(value)
                                            if (agreementIdValidate) {
                                              this.getUser(value)
                                            } else {
                                              clearAgreementIdData()
                                            }
                                            setFieldValue('agreementId', value)
                                          }}
                                          required
                                        ></input>
                                        <span className="form-side-icon reg-side-icon">{/* <MdEmail /> */}</span>
                                        <ErrorMessage component="div" name="agreementId" className="error-message" />
                                      </div>
                                    </Grid>
                                  )}
                                </div>
                              )}
                              {this.checkUserRolesForPreference(newUser) && (
                                <Grid item xs={12}>
                                  <div className="reg-radios">
                                    {providerOptions.map(p => (
                                      <label className={`reg-radio ${values.provider === p.id ? 'reg-radio-checked' : ''}`}>
                                        {p.value}

                                        <input
                                          type="radio"
                                          id={`provider-${p.id}`}
                                          name="provider"
                                          checked={values.provider === p.id}
                                          onChange={e => e.target.checked && setFieldValue('provider', p.id)}
                                        />
                                      </label>
                                    ))}
                                  </div>

                                  {errors.provider && <div className="invalid--feedback--error">{errors.provider}</div>}
                                </Grid>
                              )}

                              {this.checkUserRolesForCompanyName(newUser) && (
                                <Grid item xs={12}>
                                  <div className="position-relative">
                                    <input
                                      name="companyName"
                                      id="companyName"
                                      type="text"
                                      value={values.companyName}
                                      className="form-control font-16 rounded-full"
                                      placeholder="Company Name"
                                      onChange={e =>
                                        e.target.value?.length <= 20 && setFieldValue('companyName', e.target.value)
                                      }
                                      onBlur={e => setFieldValue('companyName', trimmedValue(e.target.value))}
                                      required
                                    ></input>
                                    <span className="form-side-icon reg-side-icon">{/* <MdEmail /> */}</span>
                                    <ErrorMessage component="div" name="companyName" className="error-message" />
                                  </div>
                                </Grid>
                              )}
                              <Grid item xs={12} sm={6}>
                                <div className="position-relative">
                                  <input
                                    id="firstName"
                                    name="firstName"
                                    type="text"
                                    value={values.firstName}
                                    className="form-control font-16 rounded-full"
                                    placeholder="First Name"
                                    onChange={e => setFieldValue('firstName', e.target.value)}
                                    onBlur={e => setFieldValue('firstName', trimmedValue(e.target.value))}
                                    required
                                  ></input>
                                  {/* <span className="form-side-icon reg-side-icon icon-top-space">
                                  <IoMdPerson />
                                </span> */}
                                  <ErrorMessage component="div" name="firstName" className="error-message" />
                                </div>
                              </Grid>

                              <Grid item xs={12} sm={6}>
                                <div className="position-relative">
                                  <input
                                    id="lastName"
                                    name="lastName"
                                    value={values.lastName}
                                    type="text"
                                    className="form-control font-16 rounded-full"
                                    placeholder="Last Name"
                                    onChange={e => setFieldValue('lastName', e.target.value)}
                                    onBlur={e => setFieldValue('lastName', trimmedValue(e.target.value))}
                                    required
                                  ></input>
                                  {/* <span className="form-side-icon reg-side-icon icon-top-space">
                                  <IoMdPerson />
                                </span> */}
                                  <ErrorMessage component="div" name="lastName" className="error-message" />
                                </div>
                              </Grid>

                              <Grid item xs={12}>
                                <div className="position-relative">
                                  <div className="mail-icon ">
                                    <input
                                      autocomplete="random"
                                      name="email"
                                      id="email"
                                      type="text"
                                      value={values.email}
                                      disabled={isDisabled}
                                      className="form-control font-16 rounded-full"
                                      placeholder="Email Address"
                                      onChange={e => setFieldValue('email', trimmedValue(e.target.value))}
                                      required
                                    />
                                    {/* <span className="form-side-icon reg-side-icon icon-email">
                                    <MailOutlineIcon />
                                  </span> */}
                                  </div>
                                  <ErrorMessage component="div" name="email" className="error-message" />
                                </div>
                              </Grid>

                              {this.checkUserRolesForMobile(newUser) && (
                                <Grid item xs={12} className="phone-number">
                                  <Field
                                    className="mobile-placeholder rounded-full"
                                    type="mobile-number"
                                    placeholder="Mobile Number"
                                    id="mobileNumber"
                                    disabled={isDisabled}
                                    name="mobileNumber"
                                    value={values.mobileNumber}
                                    onChangeText={value => setFieldValue('mobileNumber', value)}
                                    noFormControl
                                  />
                                  <ErrorMessage component="div" name="mobileNumber" className="error-message" />
                                  <Dialog>
                                    <DialogContent className="google--map--position">
                                      <GoogleMap
                                        className="googleMap-position"
                                        isFullGrid
                                        latLng={
                                          values.address
                                            ? {
                                                lat: latitude,
                                                lng: longitude,
                                              }
                                            : latLng
                                        }
                                        fetch={result => this.fetchMapInfo(result, setFieldValue)}
                                        height={30}
                                        width={100}
                                        placeHolder="find your office location"
                                        columnName={'address'}
                                        isError={errors.address}
                                        onClose={this.mapHandler}
                                        isCloseBtn
                                        isRegister
                                        isUpdate={values.address ? true : false}
                                      ></GoogleMap>
                                    </DialogContent>
                                  </Dialog>
                                </Grid>
                              )}

                              {this.checkUserRolesOfficeLocation(newUser) &&
                                userVerified &&
                                agreementUser &&
                                agreementUser.role.id === newUser.role && (
                                  <Grid item xs={12} className="register-map-main-div">
                                    <button type="button" onClick={this.mapHandler}>
                                      Add Your Office Location
                                    </button>

                                    {values.address && (
                                      <div className="register-map-msg-display">
                                        {cityCountryNameFormatter(officeAddressDisplay?.city, officeAddressDisplay?.country)}
                                      </div>
                                    )}
                                    <ErrorMessage component="div" name="address" className="error-message" />
                                    <Dialog
                                      open={openMapDialog}
                                      onClose={this.mapHandler}
                                      classes={{ container: 'model-width register-map-custom-width' }}
                                      aria-labelledby="responsive-dialog-title "
                                    >
                                      <DialogContent className="overflow-hidden register--map--content--dialog">
                                        <GoogleMap
                                          className="googleMap-position"
                                          isFullGrid
                                          latLng={
                                            values.address
                                              ? {
                                                  lat: latitude,
                                                  lng: longitude,
                                                }
                                              : latLng
                                          }
                                          fetch={result => this.fetchMapInfo(result, setFieldValue)}
                                          height={30}
                                          width={100}
                                          placeHolder="find your office location"
                                          columnName={'address'}
                                          isError={errors.address && touched.address}
                                          onClose={this.mapHandler}
                                          isCloseBtn
                                          isRegister
                                          isUpdate={values.address}
                                        ></GoogleMap>
                                        <div className="map--submit-cancel-register">
                                          <ButtonComponent className="map--submit-btn" onClick={this.mapHandler}>
                                            Submit
                                          </ButtonComponent>
                                          <ButtonComponent color="cancel" className="map--cancel-btn" onClick={this.mapHandler}>
                                            Cancel
                                          </ButtonComponent>
                                        </div>
                                      </DialogContent>
                                    </Dialog>
                                  </Grid>
                                )}

                              <Grid item xs={12}>
                                {((agreementRole && userVerification) || !agreementRole) && (
                                  <div className="position-relative register-field">
                                    <div className="position-relative">
                                      <input
                                        autocomplete="random"
                                        id="password"
                                        name="password"
                                        type={type}
                                        className={'form-control font-16 password--spacing rounded-full'}
                                        placeholder="Password"
                                        onChange={e => {
                                          setFieldValue('password', e.target.value)
                                          this.validate(e, setFieldValue, values.email)
                                        }}
                                        required
                                      ></input>
                                      <span
                                        className="form-side-icon password-show-icon icon-top-space reg-side-icon"
                                        onClick={this.changeState}
                                      >
                                        {wording}
                                      </span>
                                    </div>
                                    <ErrorMessage component="div" name="password" className="invalid--feedback--error" />
                                  </div>
                                )}
                                {((agreementRole && userVerification) || !agreementRole) && isDisplayHint && (
                                  <PasswordValidation errorMessages={errorMessages} passwordStrength={passwordStrength} />
                                )}
                              </Grid>

                              <Grid item xs={12}>
                                <Captcha getRefValue={value => this.setState({ recaptchaRef: value })} />
                              </Grid>
                            </Grid>

                            <div className="clearfix mt-3 agreement-mb">
                              <div className="custom-control custom-checkbox float-left mb-none register-agree-section d-flex flex-column">
                                <input
                                  type="checkbox"
                                  className="custom-control-input custom--control--checkbox"
                                  id="agree"
                                  onClick={() => setFieldValue('agree', !values.agree)}
                                  checked={values.agree}
                                />
                                <label
                                  className="custom-control-label font-14 register-custom-control-label mb-0 d-flex cursor-pointer register-agree-text"
                                  for="remember me"
                                >
                                  <span className="term-span-register color-black">
                                    <span onClick={() => setFieldValue('agree', !values.agree)} className="Normal-Font">
                                      I Agree to AdamSea's
                                    </span>
                                    <div
                                      onClick={this.termsHandler}
                                      className="ml-1 Normal-Font darkBlue mr-1 underline--on--hover"
                                    >
                                      {'Terms'}
                                    </div>
                                    <span className="Normal-Font" onClick={() => setFieldValue('agree', !values.agree)}>
                                      and
                                    </span>
                                    <div
                                      onClick={this.privacyPolicyHandler}
                                      className="ml-1 Normal-Font darkBlue underline--on--hover"
                                    >
                                      {'Privacy Policy'}
                                    </div>
                                  </span>
                                </label>
                              </div>

                              {errors && errors.agree && touched.agree && (
                                <div className="invalid--feedback--error d-block w-100 float-left">{errors.agree}</div>
                              )}
                            </div>

                            <ButtonComponent
                              variant="outline"
                              color="success"
                              className="h-auto btn--padding btn--font w-100 justify-content-center login--sign--up--btn rounded-full"
                              disabled={formSubmitted}
                              loader={formSubmitted}
                              onClick={handleSubmit}
                            >
                              Sign up
                            </ButtonComponent>
                            {isVerifyCaptch && <div className="alert alert-danger">Invalid captach</div>}
                            <CommonModal
                              open={termsModal}
                              title={this.props.staticPages?.data?.find(p => p.titleSlug === footerLinks.Terms_Of_Use)?.title}
                              className="modal--bg--transparent term--policy"
                              close={this.termsHandler}
                            >
                              <div
                                dangerouslySetInnerHTML={this.createMarkup(
                                  this.props.staticPages?.data?.find(p => p.titleSlug === footerLinks.Terms_Of_Use)?.pageContent
                                )}
                              />
                            </CommonModal>
                            <CommonModal
                              open={privacyModal}
                              title={this.props.staticPages?.data?.find(p => p.titleSlug === footerLinks.Privacy_Policy)?.title}
                              className="modal--bg--transparent term--policy"
                              close={this.privacyPolicyHandler}
                            >
                              <div
                                dangerouslySetInnerHTML={this.createMarkup(
                                  this.props.staticPages?.data?.find(p => p.titleSlug === footerLinks.Privacy_Policy)
                                    ?.pageContent
                                )}
                              />
                            </CommonModal>
                          </Form>
                        )}
                        {/* {console.log(isError)} */}
                        {/* {console.log(errorMessage)} */}
                        {isError && errorMessage && errorMessage.length ? <ErrorComponent errors={errorMessage} /> : <> </>}

                        <div className="page-links">
                          <p className="login-link-ragister-page Normal-Font">
                            Already have an AdamSea account?
                            <Link to="/login" className="font-weight-500">
                              {' Log in'}
                            </Link>
                          </p>
                        </div>

                        {/* <div className="other-links register-website-link ">
                          <span className="Normal-Font">
                            Ensure that whenever you sign in to AdamSea, the Web address in your browser starts with
                          </span>
                          <a target="_blank" href="https://adamsea.com/" className="darkBlue link Normal-Font">
                            www.adamsea.com
                          </a>
                        </div> */}
                      </div>

                      <div className="reg-form-back-btn">
                        <Link to="/">
                          <FaAngleLeft /> Back to market
                        </Link>
                      </div>

                      {this.state.isCountryBlocked && (
                        <div style={{ marginTop: 'auto', padding: '16px 24px', borderRadius: 8, border: '1px solid #0002' }}>
                          <div className="d-flex">
                            <div style={{ flexShrink: 0 }}>
                              <img src={require('../../assets/images/megaphone_9551884.svg')} alt="bell" height={28} style={{ height: 28 }} />
                            </div>
                            <div style={{ margin: 0, marginLeft: 20, fontSize: 16 }}>
                              We're sorry, registration for {this.state.isCountryBlocked} users is not available in {this.props.currentLocation} at this time.
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              )}
            />
          </Grid>
        </Grid>
      </>
    )
  }
}

const mapStateToProps = state => ({
  isError: state.loginReducer.isError,
  isAuthorized: state.loginReducer.isAuthorized,
  errorMessage: state.errorReducer.errorMessage,
  roles: state.loginReducer.roles,
  currentLocation: state.loginReducer.currentLocation,
  infoList: state.loginReducer?.infoList,
  agreementUser: state.loginReducer?.agreementUser,
  userVerified: state.loginReducer?.userVerified,
  removeData: state.loginReducer?.removeData,
  requestAgreementIdSuccess: state.loginReducer?.requestAgreementIdSuccess,
  loader: state.loginReducer?.isLoading,
  getByAgreementIdSuccess: state.loginReducer?.getByAgreementIdSuccess,
  getByAgreementIdError: state.loginReducer?.getByAgreementIdError,
  signUpBanners: state.bannerReducer && state.bannerReducer['signUpBanner'],
  infoData: state.pageInfoByTypeReducer?.pageInfoByType,
  isAgreementUser: state.loginReducer?.isAgreementUser,
  registerSuccess: state.loginReducer?.registerSuccess,
  registerFailure: state.loginReducer?.registerFailure,
  staticPages: state.pageInfoByTypeReducer.staticPages,
  countryDetails: state.dashboardReducer.countryDetails,
})

const mapDispatchToProps = dispatch => ({
  register: data => dispatch(register(data)),
  clearAuthFlag: () => dispatch(clearAuthorizationFlag()),
  getUserRoles: () => dispatch(getUserRoles()),
  setCurrentLocation: data => dispatch(setCurrentLocation(data)),
  getUserLocation: data => dispatch(getUserLocation(data)),
  getAllPageInformationByType: data => dispatch(getAllPageInformationByType(data)),
  getCategoriesWiseBanners: data => dispatch(getCategoriesWiseBanners(data)),
  clearErrorMessageShow: () => dispatch(clearErrorMessageShow()),
  getAllPageInfoByType: data => dispatch(getAllPageInfoByType(data)),
  clearPageInfoByTypeFlag: data => dispatch(clearPageInfoByTypeFlag()),
  convertWishlistsFromSessionToUser: () => dispatch(convertWishlistsFromSessionToUser()),
  requestAgreementId: data => dispatch(requestAgreementId(data)),
  getUserByAgreementId: data => dispatch(getUserByAgreementId(data)),
  clearAgreementIdData: () => dispatch(clearAgreementIdData()),
  clearAgreementIdDataFlag: () => dispatch(clearAgreementIdDataFlag()),
  updateUserDetailByAgreementId: data => dispatch(updateUserDetailByAgreementId(data)),
  assignAgreement: data => dispatch(assignAgreement(data)),
  getStaticPages: data => dispatch(getStaticPages(data)),
  clear_getStaticPages: () => dispatch(clear_getStaticPages()),
  getCountryDetails: data => dispatch(getCountryDetails(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Register)
