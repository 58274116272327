import cn from 'classnames'
import { SnackbarProvider } from 'notistack'
import TopLoader from '../overlays/top-loader'
import LoginModal from '../overlays/login-modal'
import ChatModal from '../overlays/chat-modal'
import CreatePostModal from '../overlays/create-post-modal'
import Sidebar from './sidebar'
import compStyles from '../../styles/components.module.scss'
import styles from './layout.module.scss'

export default function Layout({ children }) {
  return (
    <>
      <div className="__as_sm">
        <div className={cn(compStyles.container, styles.container)}>
          <Sidebar className={styles.sidebar} />
          <main className={styles.main}>{children}</main>
        </div>
      </div>

      <>
        <LoginModal zIndex={1001} />
        <ChatModal zIndex={1001} />
        <CreatePostModal zIndex={1001} />
        <TopLoader zIndex={1002} />
        <SnackbarProvider />
      </>
    </>
  )
}
