import React, { Component } from 'react'
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline'

import { Layout, marinaInnerTrip, marinaInnerReview, Loader } from '../../components'
import { Grid, Link, Box } from '@material-ui/core'
import { connect } from 'react-redux'
import RentInnerReviews from '../../components/gridComponents/InnerReviews'
import { RentSharedTrips } from '../../components/gridComponents/rentSharedTrips'
import Rating from 'react-rating'
import StarIcon from '@material-ui/icons/Star'
import StarBorderIcon from '@material-ui/icons/StarBorder'
import Truncate from 'react-truncate'
import Carousel, { Modal, ModalGateway } from 'react-images'

import InnerReviews from '../../components/gridComponents/InnerReviews'
import './marinaStorageInner.scss'
import './marinaStorageInnerResponsive.scss'
import { getSingleMarina, getMarinaByType, pageVisit, addLikes } from '../../redux/actions'
import { getSingleMarinaMarker } from '../../helpers/boatHelper'
import GoogleMarker from '../../components/map/marker'
import { Player, BigPlayButton } from 'video-react'
import { displayDefaultReview, snakeCase, nameFormatter, getLikeFlag, thousandFormatter } from '../../helpers/string'
import {
  icons,
  showAllMarina,
  likeModuleEnum,
  defaultProfileIcon,
  wishlistModuleEnum,
  shareModule,
  textCustomerReview,
  iconImages,
  marinaIconImages,
  skeletonType,
  defaultImage,
  marketVerifications,
  marketTypes,
} from '../../util/enums/enums'
import {
  FirstBannerStyle,
  SecondBannerStyle,
  ThirdBannerStyle,
  HomPageVideoStyle,
} from '../../components/styleComponent/styleComponent'
import { addFeedback, getReviewByModuleId } from '../../redux/actions/ReviewAction'

import { prepareGalleryData, percentageFormate, getId, copyCodeToClipboard, getImgUrl } from '../../util/utilFunctions'
import { RecommendedMarinaStorages } from '../../components/gridComponents/recommendedMarinaStorages'
import ReadMore from '../../components/helper/truncate'
import {
  cityCountryNameFormatter,
  getAvgRating,
  getAddress,
  checkModuleVerification,
  verificationRedirection,
} from '../../helpers/jsxHelper'
import { ShareAndWishlist } from '../../components/share/ShareAndWishlist'
import { shareDetailsWithUsers } from '../../redux/actions/shareAction'
import { CommonTooltip } from '../../components/CommonTooltip'
import RatingComponent from '../../components/rating/Rating'
import ReviewRating from '../../components/reviewRating/ReviewRating'
import { LikeComponent } from '../../components/like/LikeComponent'
import SmallCard from '../../components/boat/smallCard'
import { UserCardWithFeedback } from '../../components/user/UserCardWithFeedback'
import { getModulePdf } from '../../redux/actions/pdfAction'
import { SkeletonLoader } from '../../components/loader/SkeletonLoader'
import { popUpMessage } from '../../helpers/confirmationPopup'
import { Video } from '../../components/popUp/video'
import { getUserReportByModule } from '../../redux/actions/reportAction'
import { getLocalStorageItem } from '../../helpers/storageHelper'
import { ButtonComponent } from '../../components/form/Button'
import InnerRatingModal from '../../components/reviewRating/InnerRatingModal'
import { get } from 'lodash'
import { FaClipboard, FaRegClipboard } from 'react-icons/fa'
import CopyAdId from '../../components/CopyAdId'
import LoginModal from '../../components/modal/loginModal'
import { FeedBackForm } from '../../components/feedback/FeedBackForm'
import UserCardMarina from '../../components/user/UserCardMarina'

class MarinaStorageInner extends Component {
  constructor(props) {
    super(props)
    this.state = {
      marinaId: '',
      isGallery: false,
      relatedVideoPopUp: false,
      visible: false,
      imageIndex: null,
      underVerification: false,
      verificationStatus: {},
      newThumbnail: '',
    }
  }

  openLightbox = index => {
    this.setState({ isGallery: true, imageIndex: index })
  }
  closeLightbox = () => {
    this.setState({ isGallery: false, imageIndex: null })
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { isReview, getReviewByModuleId, match, singleError, errorMessage } = nextProps
    const { params } = match && match
    const { marinaId } = prevState

    let newState = {}

    if (singleError) {
      const underVerification = checkModuleVerification(marketTypes.MARINA_AND_STORAGE, errorMessage)

      if (underVerification) {
        newState = {
          ...newState,
          underVerification: true,
          verificationStatus: underVerification,
        }
      }
    }

    if (isReview) {
      marinaId && getReviewByModuleId({ userModuleId: marinaId })
    }

    if (params && params.id) {
      newState = {
        ...newState,
        marinaId: params.id,
      }
    }

    return newState
  }

  async componentDidMount() {
    const { marinaId } = this.state
    const { getSingleMarina, getReviewByModuleId, pageVisit, getMarinaByType, adminLoginDetected } = this.props

    if (marinaId) {
      !adminLoginDetected &&
        pageVisit({ module: marinaId, type: likeModuleEnum.MARINA, sessionId: getLocalStorageItem('sessionId') })
      getSingleMarina(marinaId)
      getReviewByModuleId({ userModuleId: marinaId })
      getMarinaByType({ id: marinaId })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { editMarina, isReview } = this.props
    const { newThumbnail } = prevState

    if (editMarina && editMarina.youtubeLinkVideo !== '' && newThumbnail === '') {
      this.createThumbnail(editMarina.youtubeLinkVideo)
    }

    if (isReview) {
      document.querySelector('.rating-reviews-cont')?.scrollIntoView({ block: 'center' })
    }
  }

  createThumbnail = video => {
    if (video !== undefined) {
      var regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/
      var result = video?.match(regExp)
      if (result === null || result === undefined) {
        this.setState({ newThumbnail: defaultImage })
      } else {
        var youtube_video_id = result.pop()
        if (youtube_video_id?.length == 11) {
          var video_thumbnail = `//img.youtube.com/vi/${youtube_video_id}/0.jpg`
          this.setState({ newThumbnail: video_thumbnail })
        }
      }
    }
  }

  toggleMenu = () => {
    this.setState(prevState => ({ visible: !prevState.visible }))
  }
  relatedVideoHandler = () => {
    this.setState(prevState => ({
      relatedVideoPopUp: !prevState.relatedVideoPopUp,
    }))
  }

  renderBuyNowSection = () => {
    const reviews = this.props.editMarina?.reviews || {}

    return (
      <div className="d-flex  marina-flex-start-venue align-items-center h-100 pl-0 pr-0">
        <div className="d-flex flex-column h-100 justify-content-evenly">
          {reviews && reviews.reviews && (
            <div className="font-12 newRatingBlack">
              <div>
                <div>{reviews.reviews.length}</div>
                <div>Reviews</div>
              </div>
              <div style={{ marginLeft: '1em', paddingLeft: '1em', borderLeft: '1px solid #000' }}>
                <div>
                  <StarIcon style={{ width: '1em', height: '1em', fontSize: '1.25em' }} />{' '}
                  {percentageFormate(getAvgRating(reviews.reviews))}
                </div>
                <div>Rating</div>
              </div>
            </div>

            // <div className="d-flex flex-column">
            //   <div className="d-flex align-items-center marina-flex-start-venue ">
            //     <div className="d-flex mr-2 mb-1 margin-bottom-rating">
            //       <RatingComponent
            //         className="rating-clr rating-small-size"
            //         rating={percentageFormate(getAvgRating(reviews.reviews))}
            //       />
            //     </div>
            //     <h6 className="m-0 font-14 font-weight-400 dark-silver marina--inner--header--rating--section">
            //       {percentageFormate(getAvgRating(reviews.reviews))}
            //     </h6>
            //   </div>

            //   <span className="font13 font-weight-400 gray-light marina--inner--header--review d-flex justify-content-end marina--inner--header--rating--section">
            //     {reviews.reviews.length} {textCustomerReview}
            //   </span>
            // </div>
          )}
        </div>
      </div>
    )
  }

  redirectHandler = () => {
    const { history } = this.props
    verificationRedirection('/marina-storage', history)
  }

  render() {
    const {
      boat,
      history,
      marinaByType,
      editMarina,
      isLoading,
      currentUser,
      addFeedback,
      addLikes,
      getPdfSuccess,
      getModulePdf,
      pdf,
      loading,
      getUserReportByModule,
      reviews,
    } = this.props

    const {
      commonAddress: { city, country, geometricLocation = null },
    } = getAddress(editMarina && editMarina.address)
    const { isGallery, imageIndex, relatedVideoPopUp, underVerification, verificationStatus, marinaId, newThumbnail } =
      this.state

    // const reviews = (editMarina && editMarina.reviews) || {}

    return (
      <Layout className="marina-inner-layout not--scroll-auto">
        {underVerification && popUpMessage(verificationStatus?.message, 'Alert', true, null, null, this.redirectHandler)}
        {(isLoading && editMarina && !editMarina.hasOwnProperty('id')) || loading ? (
          <Grid container className="container-fluid">
            <SkeletonLoader type={skeletonType.innerPage} />
          </Grid>
        ) : (
          <>
            <Grid container className="container-fluid clr-fluid top-space-mob-view-marina sticky-on-top inner-sticky-on-top">
              <div className="m-auto h-100 w-85vw marina-inner-space">
                <Grid item className="h-70 d-flex pr-0 pl-0 marina--inner-header--div " sm={6}>
                  <div className="width-100 align-self-center">
                    <div className="d-flex align-items-center flex-wrap marina--inner--header--boat--name marina--inner--header--boat--name--mobile" style={{ gap: 8 }}>
                      <h1 className="font-22 marina--ad--title color-black mr-3 mb-0">{editMarina.name}</h1>
                      <h6 className="mb-0 inner-header-badge marina-inner-header-badge dark-silver color-black marina--inner--header--badge">
                        {editMarina && editMarina.provider && editMarina.provider.alias}
                      </h6>
                    </div>

                    {/* <div className="d-flex align-items-center">
                      <img
                        src={require('../../assets/images/boatInner/location.png')}
                        alt="Location"
                        className="header--location--img"
                      />
                      <p className="font-14 mb-0">{cityCountryNameFormatter(city, country)}</p>
                    </div> */}
                  </div>
                </Grid>


                <Grid item className="h-70 marina--inner-header--div marina--inner-header--div--mobile" sm={6}>
                  {editMarina && this.renderBuyNowSection()}
                </Grid>
              </div>
            </Grid>

            <div className="row width-100 ml-0">
              <Grid container className="w-85vw m-auto position-relative marina--inner--banner">
                <div className="position-absolute inner-banner-top-social-div inner--banner--top--div"></div>

                <Grid item className="w-100">
                  <div className="position-relative">
                    <div className="marina-gallery">
                      {editMarina?.images?.slice(0, 5).map((img, i) => (
                        <div
                          className="marina-gallery-item"
                          style={{ backgroundImage: `url(${getImgUrl(img)})` }}
                          onClick={() => this.openLightbox(i)}
                        />
                      ))}
                    </div>

                    <div className="inner--action--btns">
                      {editMarina && editMarina.id && (
                        <ShareAndWishlist
                          moduleId={editMarina.id}
                          moduleType={wishlistModuleEnum.MARINA}
                          ownerId={editMarina?.user?.id}
                          getPdfSuccess={getPdfSuccess}
                          pdf={pdf}
                          getModulePdf={getModulePdf}
                          isInner
                          getUserReportByModule={getUserReportByModule}
                          noTopSpace
                          hideFlag
                          hidePDF
                        />
                      )}
                    </div>
                  </div>

                  {editMarina && (
                    <div className="banner-b-btns-wrp" style={{ marginTop: 15 }}>
                      <LikeComponent
                        likes={editMarina.likes}
                        moduleId={editMarina.id}
                        likeModule={likeModuleEnum.MARINA}
                        addLikes={addLikes}
                        noAbsolute
                      />
                      <div className="banner-b-btn">{thousandFormatter(editMarina.pageVisits?.length || 0)} VIEWS</div>
                      <CommonTooltip
                        component={ctProps => (
                          <div data-tooltip-id={ctProps.id} data-tooltip-content="Photos" className="banner-b-btn">
                            <i className="fas fa-images"></i>
                            {editMarina.images?.length}
                          </div>
                        )}
                      />
                    </div>
                  )}
                </Grid>

                {/* <Grid item sm={6} className="marina-inner-full-width-mob">
                  <Grid container className="h-100">
                    <Grid item sm={12} className="first-banner-height hover--effect-inner-image">
                      <FirstBannerStyle
                        onClick={() => this.openLightbox(0)}
                        img={
                          editMarina && editMarina.images && editMarina.images.length > 0
                            ? encodeURI(getImgUrl(editMarina.images[0]))
                            : require('../../assets/images/marinaStorageInner/Light-Grey-Background.jpg')
                        }
                        className="col hidden border-solid-gray pr-0 pl-0 h-100"
                      >
                        <div className="hover--effect"></div>
                      </FirstBannerStyle>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item sm={6} className="marina-inner-full-width-mob">
                  <Grid container className="h-100">
                    <Grid item xs={12}>
                      <Grid container className="h-100">
                        <Grid
                          item
                          sm={6}
                          className="hidden border-solid-gray h-100 marina-banner-img-padding hover--effect-inner-image"
                        >
                          <SecondBannerStyle
                            onClick={() => this.openLightbox(1)}
                            img={
                              editMarina && editMarina.images && editMarina.images.length > 1
                                ? encodeURI(getImgUrl(editMarina.images[1]))
                                : require('../../assets/images/marinaStorageInner/Light-Grey-Background.jpg')
                            }
                            className="col hidden border-solid-gray pr-0 pl-0 h-100"
                          >
                            <div className="hover--effect"></div>
                          </SecondBannerStyle>
                        </Grid>
                        <Grid
                          item
                          sm={6}
                          className="hidden border-solid-gray h-100 marina-banner-img-padding hover--effect-inner-image"
                        >
                          <ThirdBannerStyle
                            onClick={() => this.openLightbox(2)}
                            img={
                              editMarina && editMarina.images && editMarina.images.length > 2
                                ? encodeURI(getImgUrl(editMarina.images[2]))
                                : require('../../assets/images/marinaStorageInner/Light-Grey-Background.jpg')
                            }
                            className="col hidden border-solid-gray pr-0 pl-0 h-100"
                          >
                            <div className="hover--effect"></div>
                          </ThirdBannerStyle>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container className="h-100">
                        <Grid
                          item
                          sm={6}
                          className="hidden border-solid-gray h-100 marina-banner-img-padding hover--effect-inner-image"
                        >
                          <SecondBannerStyle
                            onClick={() => this.openLightbox(3)}
                            img={
                              editMarina && editMarina.images && editMarina.images.length > 3
                                ? encodeURI(getImgUrl(editMarina.images[3]))
                                : require('../../assets/images/marinaStorageInner/Light-Grey-Background.jpg')
                            }
                            className="col hidden border-solid-gray pr-0 pl-0 h-100"
                          >
                            <div className="hover--effect"></div>
                          </SecondBannerStyle>
                        </Grid>
                        <Grid
                          item
                          sm={6}
                          className="hidden border-solid-gray h-100 marina-banner-img-padding hover--effect-inner-image"
                        >
                          <ThirdBannerStyle
                            style={{ borderTopRightRadius: '0px' }}
                            onClick={() => this.openLightbox(4)}
                            img={
                              editMarina && editMarina.images && editMarina.images.length > 4
                                ? encodeURI(getImgUrl(editMarina.images[4]))
                                : require('../../assets/images/marinaStorageInner/Light-Grey-Background.jpg')
                            }
                            className="col hidden border-solid-gray pr-0 pl-0 h-100"
                          >
                            <div className="hover--effect"></div>
                          </ThirdBannerStyle>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid> */}
              </Grid>
              <div className="w-85vw m-auto">
                <Grid container className="p-sec">
                  <Grid item sm={9} className="marina-grid-full-view">
                    <Grid container>
                      <Grid item sm={6} className="">
                        <div className="marina--inner--desc--spacing marina--inner--service--desc--spacing">
                          <div className="d-flex align-items-center inner--boat--systems-title marina--inner--title">
                            <span className="inner--main--title"> Description of Our Service</span>
                          </div>
                          <p className="text-justify a-inner-fix marina--inner--service--desc color-black marina--inner--read--more">
                            <ReadMore className="text-justify ">{editMarina && editMarina.facilities}</ReadMore>
                          </p>
                        </div>

                        <Grid container className="boat-amenities">
                          <Grid item md={12}>
                            <div className="d-flex align-items-center inner--boat--systems-title marina--inner--service--provide--title">
                              <div className="inner--title--icons">
                                <img
                                  src={require('../../assets/images/boatInner/Services We Provide-M.svg')}
                                  alt="Accessories"
                                  className="d-flex h-100"
                                />
                              </div>
                              <span className="inner--main--title">Services We Provide</span>
                            </div>

                            {editMarina && editMarina.serviceProvide && editMarina.serviceProvide.length > 0 && (
                              <div className="col-md-12 pt-2 inner--boat--amenities--div pl-0">
                                <Grid container>
                                  <div className="d-flex flex-wrap">
                                    {editMarina &&
                                      editMarina.serviceProvide &&
                                      editMarina.serviceProvide.length &&
                                      editMarina.serviceProvide.map((item, index) => {
                                        return (
                                          <div className="mb-4 res-m-0 mt-0 ml-0 marina--inner--boat--facilities">
                                            <SmallCard
                                              index={index}
                                              elseRenderImage={item.thumbnailIcon?.url}
                                              title={item.name}
                                              height="25px"
                                            />
                                          </div>
                                        )
                                      })}
                                  </div>
                                </Grid>
                              </div>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid item sm={6} className="marina--inner--desc--spacing">
                        <div className="marina--inner--membership--spacing">
                          <div className="d-flex align-items-center inner--boat--systems-title marina--inner--title">
                            <div className="inner--title--icons">
                              <img
                                loading="lazy"
                                src={require('../../assets/images/boatInner/Membership benefit-Mar.svg')}
                                className="d-flex h-100"
                                alt="Amenties"
                              />
                            </div>
                            <span className="inner--main--title">Membership benefit</span>
                          </div>
                          <p className="text-justify a-inner-fix marina--inner--service--desc marina--inner--read--more color-black">
                            <ReadMore className="text-justify ">{editMarina && editMarina.membershipBenefits}</ReadMore>
                          </p>
                        </div>

                        <Grid item sm={12}>
                          <Grid item sm={12} className="pb-0 boat--inner--payment--div marina--inner--payment--div">
                            <div className="payment-grid row mt-4">
                              <div className="col-12">
                                <div className="d-flex align-items-center">
                                  <div className="inner--title--icons">
                                    <img
                                      src={require('../../assets/images/boatInner/Payment-M.svg')}
                                      className="d-flex h-100"
                                      alt="Payment"
                                    />
                                  </div>
                                  <span className="inner--main--title">Payment</span>
                                </div>

                                <p className="marina--inner--payment--subtitle color-black Membership benefit">
                                  {`${editMarina?.user?.companyName}`} provides offline payment options.
                                </p>
                              </div>
                              {editMarina?.user?.paymentMethodTypes?.length > 0 &&
                                editMarina.user.paymentMethodTypes.map(item => (
                                  <div className="col-6 p-0">
                                    <div className="inner-payment-div">
                                      <div className="inner--payment--div">
                                        <div className="d-flex justify-content-center align-items-center">
                                          <img
                                            src={item.icon?.url}
                                            className="inner-payment-cash-icon inner--payment--method"
                                            alt={item.name}
                                          />
                                        </div>
                                      </div>
                                      <span className="text-center title-description gray-dark marina--inner--payment--method">
                                        {item.name}
                                      </span>
                                    </div>
                                  </div>
                                ))}
                            </div>

                            {/* <div className="d-flex payment-grid mt-4 marina--inner--payment--grid marina--inner--payment--options">
                                <div className="col-6 p-0">
                                  <div className="inner-payment-div">
                                    <div className="inner--payment--div">
                                      <div className="d-flex justify-content-center align-items-center">
                                        <img
                                          src={require("../../assets/images/boatInner/credit-card.png")}
                                          className="inner-payment-cash-icon inner--payment--method"
                                          alt=""
                                        />
                                      </div>
                                    </div>
                                    <span className="text-center title-description gray-dark marina--inner--payment--method">
                                      Credit Card Payment
                               </span>
                                  </div>
                                </div>
                                <div className="col-6 p-0">
                                  <div className="inner-payment-div">
                                    <div className="inner--payment--div">
                                      <div className="d-flex justify-content-center align-items-center">
                                        <img
                                          src={require("../../assets/images/boatInner/cash-on-arrival.png")}
                                          className="inner-payment-cash-icon inner--payment--method"
                                          alt=""
                                        />
                                      </div>
                                    </div>
                                    <span className="text-center title-description gray-dark marina--inner--payment--method">
                                      Cash on Arrival
                               </span>
                                  </div>
                                </div>
                              </div>
                              <div className="d-flex payment-grid mt-4">
                                <div className="col-6 p-0">
                                  <div className="inner-payment-div">
                                    <div className="inner--payment--div">
                                      <div className="d-flex justify-content-center align-items-center">
                                        <img
                                          src={require("../../assets/images/marinaStorageInner/delivery-payment.png")}
                                          className="inner-payment-cash-icon inner--payment--method"
                                          alt=""
                                        />
                                      </div>
                                    </div>
                                    <span className="text-center title-description gray-dark marina--inner--payment--method">
                                      Payment after Delivery
                               </span>
                                  </div>
                                </div>
                                <div className="col-6 p-0">
                                  <div className="inner-payment-div">
                                    <div className="inner--payment--div">
                                      <div className="d-flex justify-content-center align-items-center">
                                        <img
                                          src={require("../../assets/images/marinaStorageInner/down-payment.png")}
                                          className="inner-payment-cash-icon inner--payment--method"
                                          alt=""
                                        />
                                      </div>
                                    </div>
                                    <span className="text-center title-description gray-dark marina--inner--payment--method">
                                      50% Down Payment
                               </span>
                                  </div>
                                </div>
                              </div> */}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item sm={3} className="marina-grid-full-view">
                    {editMarina && editMarina.user && (
                      <UserCardMarina
                        seller={editMarina.user}
                        boat={{
                          rating_count: get(editMarina, 'user.reviews.reviews.length', 0),
                          rating: getAvgRating(get(editMarina, 'user.reviews.reviews', [])) || 0,
                        }}
                      />
                    )}
                  </Grid>
                </Grid>
                <hr />
                <Grid container className="p-sec marina--inner--desc--div--spacing">
                  <Grid item sm={7} className="video-marina--marina marina-grid-full-view">
                    <div className="d-flex align-items-center inner--boat--systems-title marina--inner--title marina--inner--facility--location--title">
                      <div className="inner--title--icons">
                        <img
                          loading="lazy"
                          src={require('../../assets/images/boatInner/Facility Location-M.svg')}
                          className="d-flex h-100"
                          alt="Location"
                        />
                      </div>
                      <span className="inner--main--title"> Facility Location</span>&nbsp;
                      <span className="title-city-name">{cityCountryNameFormatter(city, country)}</span>
                    </div>

                    <div className="d-flex marina--inner--map">
                      <div className="d-flex boat-inner-map-div marina--inner--map--div">
                        {editMarina?.address?.length && (
                          <div className="inner-map">
                            <GoogleMarker
                              width={45}
                              isInnerPage={false}
                              markers={getSingleMarinaMarker(editMarina)}
                              geometricLocation={geometricLocation}
                              isRedirectToMap
                            />
                          </div>
                        )}
                      </div>

                      <div className="marina--inner--member--info marina--inner--info--desc">
                        <div className="marina--inner--facility--option">
                          <h6 className="title-sm title-description mb-2 margin-bottom-rating gray-dark inner--info--title">
                            MEMBERS RATE
                          </h6>
                          <p className="sub-title-sm dark-silver marina--inner--info--div--desc">
                            {editMarina && editMarina.membersRate}
                          </p>
                        </div>
                        <div className="marina--inner--facility--option">
                          <h6 className="title-sm title-description mb-2 margin-bottom-rating gray-dark inner--info--title">
                            NON MEMBERS RATES
                          </h6>
                          <p className="sub-title-sm dark-silver marina--inner--info--div--desc">
                            {editMarina && editMarina.noneMembersRate}
                          </p>
                        </div>
                      </div>
                    </div>
                  </Grid>

                  {editMarina?.youtubeLinkVideo && (
                    <Grid item sm={4} className="marina-grid-full-view">
                      <div className="align-items-end justify-content-between">
                        <div className="d-flex align-items-center inner--boat--systems-title marina--inner--title marina--inner--facility--location--title">
                          <div className="inner--title--icons">
                            <img
                              src={require('../../assets/images/boatInner/related-videos.png')}
                              alt="Related videos"
                              className="d-flex h-100"
                            />
                          </div>
                          <span className="inner--main--title"> Related Videos</span>
                        </div>
                        <div className="video--container video--container-marina--inner vidnohid">
                          {!relatedVideoPopUp ? (
                            <HomPageVideoStyle
                              img={newThumbnail}
                              className="marina-inner-related-video"
                              onClick={this.relatedVideoHandler}
                            >
                              <PlayCircleOutlineIcon className="playVideo-icon w-auto cursor-pointer" />
                            </HomPageVideoStyle>
                          ) : (
                            <Video
                              videoUrl={editMarina?.youtubeLinkVideo}
                              thumbnail={newThumbnail}
                              isOpen
                              closeVideo={this.relatedVideoHandler}
                              isInline
                            />
                          )}
                        </div>
                      </div>
                    </Grid>
                  )}
                </Grid>
                <hr />
                <Grid container className="p-sec marina--inner--special--facility--section spacing-content-marina">
                  <Grid item sm={6} className="full-width-mob-view full-width-mob-view-left-side">
                    <div className="">
                      <div className="marina--inner--facilities--spacing">
                        <span className="marina--inner--facilities">Our Storage</span>
                      </div>

                      <p className="text-justify full-width-mob-view a-inner-fix  margin-bottom-rating marina--inner--service--desc marina--inner--read--more">
                        <ReadMore className="text-justify">{editMarina && editMarina.storage}</ReadMore>
                      </p>
                    </div>

                    <div className="mt-5 marina--inner--facilities--desc">
                      <div className="marina--inner--facilities--spacing">
                        <span className="marina--inner--facilities">Our Birth</span>
                      </div>

                      <p className="text-justify a-inner-fix  margin-bottom-rating marina--inner--service--desc marina--inner--read--more">
                        <ReadMore className="text-justify ">{editMarina && editMarina.berth}</ReadMore>
                      </p>
                    </div>
                  </Grid>

                  <Grid item sm={6} className="full-width-mob-view full-width-mob-view-right-side">
                    <div className="">
                      <div className="marina--inner--facilities--spacing">
                        <span className="marina--inner--facilities">Running Promotion</span>
                      </div>

                      <p className="text-justify full-width-mob-view a-inner-fix  margin-bottom-rating marina--inner--service--desc marina--inner--read--more">
                        <ReadMore className="text-justify ">{editMarina && editMarina.promotionRunning}</ReadMore>
                      </p>
                    </div>
                    <div className="mt-5 marina--inner--facilities--desc">
                      <div className="marina--inner--facilities--spacing">
                        <span className="marina--inner--facilities">Special About Our Facilities</span>
                      </div>

                      <p className="text-justify full-width-mob-view a-inner-fix   margin-bottom-rating marina--inner--service--desc marina--inner--read--more">
                        <ReadMore className="text-justify ">{editMarina && editMarina.facilities}</ReadMore>
                      </p>
                    </div>
                  </Grid>
                </Grid>
              </div>
              <div className="w-85vw m-auto mt-50 rating-section p-sec">
                <>
                  {/* start */}

                  {(() => {
                    const innerRatingModal = currentUser &&
                      editMarina &&
                      editMarina.user &&
                      getId(currentUser) !== getId(editMarina.user) &&
                      currentUser.accountActivated &&
                      currentUser.isProfilecompleted &&
                      currentUser.isVerified && (
                        <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-end' }}>
                          {(() => {
                            const initVals = reviews?.reviews?.find(r => getId(currentUser) === getId(r.user))

                            return (
                              <InnerRatingModal
                                key={reviews}
                                module={editMarina.user}
                                userModule={editMarina}
                                moduleType={shareModule.MARINA}
                                initVals={initVals}
                                AddReviewButton={({ onClick }) => (
                                  <ButtonComponent className="success" color="orange" onClick={onClick}>
                                    {initVals ? 'Edit' : 'Add'} Review
                                  </ButtonComponent>
                                )}
                              />
                            )
                          })()}
                        </div>
                      )

                    return reviews && reviews.reviews ? (
                      <ReviewRating
                        reviews={reviews}
                        currentUser={currentUser}
                        boat={editMarina}
                        noTopMargin
                        innerRatingModal={innerRatingModal}
                      />
                    ) : (
                      innerRatingModal
                    )
                  })()}

                  {/* end */}
                  <Grid item sm={12}>
                    {/* Need this in future */}
                    {marinaByType && marinaByType.length > 0 && (
                      <div className="container-fluid marina-storage-layout p-sec pl-0 pr-0">
                        <Grid item xs={12} className="mb-5 section-grid section-heading mt-0">
                          <div className="d-flex align-items-center inner--boat--systems-title marina--inner--title">
                            <span className="inner--main--title"> Recommended Marina & Storage in {country} </span>
                          </div>
                          <RecommendedMarinaStorages
                            data={marinaByType}
                            itemsLength={8}
                            dimensionKey="marinaMostPopular"
                            // Need to change url
                            url={`/marina-and-storage/similar/${marinaId}`}
                          />
                        </Grid>
                      </div>
                    )}
                  </Grid>

                  {editMarina?.user && (
                    <div className="inner--add--feedback w-100 d-flex flex-column align-items-end">
                      {editMarina?.user?.id !== currentUser.id && (
                        <div
                          className={`text-right f-14 mb-0 cursor-pointer add-feedback-mt add--feedback color hover-scale-1_1`}
                          style={{ padding: 15 }}
                          onClick={() => {
                            currentUser?.id
                              ? this.setState(prev => ({ feedbackModal: !prev.feedbackModal }))
                              : this.setState(prev => ({ loginModal: !prev.loginModal }))
                          }}
                        >
                          <img
                            loading="lazy"
                            src={require(`../../assets/images/marinaStorageInner/marinainner-like.png`)}
                            className="inner-feedback-img mr-2"
                            alt="Feedback"
                          />
                          <span className="add-feedback-text add--feedback--text">Send us Feedback</span>
                        </div>
                      )}

                      <LoginModal
                        open={this.state.loginModal}
                        close={() => this.setState(prev => ({ loginModal: !prev.loginModal }))}
                      />
                      <FeedBackForm
                        open={this.state.feedbackModal}
                        closeModal={() => this.setState(prev => ({ feedbackModal: !prev.feedbackModal }))}
                        submitValues={addFeedback}
                      />

                      <div className="font-weight-500 inner--adid--section">
                        <span className="inner-adid font-weight-400 color-black">
                          <CopyAdId adId={editMarina && editMarina.adId} />
                        </span>

                        {editMarina && editMarina.id && (
                          <ShareAndWishlist
                            moduleId={editMarina.id}
                            moduleType={wishlistModuleEnum.MARINA}
                            ownerId={editMarina?.user?.id}
                            getPdfSuccess={getPdfSuccess}
                            pdf={pdf}
                            getModulePdf={getModulePdf}
                            isInner
                            getUserReportByModule={getUserReportByModule}
                            noTopSpace
                            hideShare
                            hideSave
                          />
                        )}
                      </div>
                    </div>
                  )}
                </>
              </div>
            </div>
          </>
        )}
        {editMarina && editMarina.images && editMarina.images.length > 0 && isGallery && (
          <ModalGateway>
            <Modal onClose={this.closeLightbox}>
              <Carousel views={prepareGalleryData(editMarina.images, imageIndex)} />
            </Modal>
          </ModalGateway>
        )}
      </Layout>
    )
  }
}

const mapStateToProps = state => ({
  editMarina: state.marinaAndStorageReducer && state.marinaAndStorageReducer.editMarina,
  isLoading: state.marinaAndStorageReducer && state.marinaAndStorageReducer.isLoading,
  currentUser: state.loginReducer && state.loginReducer.currentUser,
  isReview: state.reviewReducer && state.reviewReducer.isReview,
  reviews: state.reviewReducer && state.reviewReducer.reviews,
  marinaByType: state.marinaAndStorageReducer && state.marinaAndStorageReducer.marinaByType,
  getPdfSuccess: state.pdfReducer && state.pdfReducer.getPdfSuccess,
  // loading: state.pdfReducer && state.pdfReducer.isLoading,
  pdf: state.pdfReducer && state.pdfReducer.pdf,
  errorMessage: state.errorReducer?.errorMessage,
  singleError: state.marinaAndStorageReducer?.singleError,
  adminLoginDetected: state.loginReducer?.adminLoginDetected,
})

const mapDispatchToProps = dispatch => ({
  getSingleMarina: data => dispatch(getSingleMarina(data)),
  pageVisit: data => dispatch(pageVisit(data)),
  getMarinaByType: data => dispatch(getMarinaByType(data)),
  addLikes: data => dispatch(addLikes(data)),
  addFeedback: data => dispatch(addFeedback(data)),
  shareDetailsWithUsers: data => dispatch(shareDetailsWithUsers(data)),
  getReviewByModuleId: data => dispatch(getReviewByModuleId(data)),
  getModulePdf: data => dispatch(getModulePdf(data)),
  getUserReportByModule: data => dispatch(getUserReportByModule(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(MarinaStorageInner)
