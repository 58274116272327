import React, { Component, Fragment } from 'react'
import * as Yup from 'yup'
import { connect } from 'react-redux'
import { Formik, ErrorMessage } from 'formik'
import { Button, Grid, Box, FormControl, FormLabel, FormControlLabel, RadioGroup, Radio, CardContent } from '@material-ui/core'
import MenuItem from '@material-ui/core/MenuItem'
import Form from 'react-bootstrap/Form'
import Card from 'react-bootstrap/Card'
// import './profile.scss'
import { Loader, Field } from '../../../components'
import {
  getAllBoatLookUps,
  saveBoat,
  getBoatById,
  updateBoat,
  getBoatTypeStart,
  clearErrorMessageShow,
  clearAddBoatFlag,
  clearUpdateBoatFlag,
  getDocumentUrl,
} from '../../../redux/actions'
import GoogleMap from '../../../components/map/map'
import {
  getListOfYears,
  radioEnum,
  helpTextEnum,
  AddressInputTypeEnum,
  userRoles,
  MEDIA_NAME,
  boatAttachIdAccessibleRoles,
  boatType,
  boatMetaType,
  isYachtType,
} from '../../../util/enums/enums'

// style
import '../../../styles/common.scss'
import {
  renderSelectOptions,
  getIds,
  requireMessage,
  requiredWords,
  validateWords,
  positiveNumberHelper,
  requiredNumber,
  trimmedValue,
  maximumWordCount,
} from '../../../helpers/string'
import './AddBoat.scss'
import { DashboardLayout } from '../../../components/layout/dashboardLayout'
import { Row, Col } from 'react-bootstrap'
import { cancelHandler } from '../../../helpers/routeHelper'
import { dateStringFormate, getImgUrl } from '../../../util/utilFunctions'
import ErrorComponent from '../../../components/error/errorComponent'
import MultiSelect from '../../../components/helper/multiSelect'
import ErrorFocus from '../../../components/ws/ErrorFocus'
import { popUpMessage } from '../../../helpers/confirmationPopup'
import { getLocalInfo, getLocalStorageItem } from '../../../helpers/storageHelper'
import SingleSelect from '../../../components/helper/singleSelect'
import { getLatLngFromGeometric, getSingleSelectValue, getDropdownValue } from '../../../helpers/jsxHelper'
import { CommonModal } from '../../../components/modal/commonModal'
import { disableFutureDates } from '../../../helpers/dateTimeHelper'
import { ButtonComponent } from '../../../components/form/Button'
import { HelpIconComponent } from '../../../components/helper/helpIconComponent'
import { getAddMedia } from '../../../helpers/s3FileUpload'
import { getSubmitValues } from '../../../helpers/boatSubmitHelper'
import { stringValidation, addressObjValidation, urlValidation, numberValidation } from '../../../helpers/yupHelper'
import { maximumDigits } from '../../../util/enums/enums'
import TagsInput from './TagsInput'
import {
  clear_getAgreement,
  clear_getStaticPages,
  getAgreement,
  getStaticPages,
} from '../../../redux/actions/pageInfoByTypeAction'
import { get } from 'lodash'
import AppDlBtnCard from '../../../components/appDlBtnCard'

class AddBoat extends Component {
  constructor(props) {
    super(props)
    this.state = {
      boatId: '',
      isUpdate: false,
      mediaUpdated: false,
      selectedStatusUpdated: false,
      boatTypeAlias: '',
      selectedKind: '',
      boatOwnership: {
        complianceLabelNumber: '',
        hullSerialNumber: '',
        vehicleIdentificationNumber: '',
        licenceNo: '',
        ownershipAttachment: [],
        boatOwnershipId: [],
      },
      boatInfo: {
        stockAvailable: true,
        listedBy: '',
        boatType: '',
        boatStatus: '',
        boatName: '',
        boatParking: null,
        trailer: '',
        yearBuilt: '',
        manufacturedBy: null,
        hullMaterial: '',
        hullColor: '',
        usedHours: '',
        kind: null,
        inStock: null,
        stockQuantity: '',
        minPurchaseQuantity: '',
        weightCapacity: '',
        meta_tags: null,
      },
      boatEngine: {
        noOfEngines: '',
        seats: null,
        modelYear: '',
        fuelType: null,
        fuelCapacity: '',
        holdingCapacity: '',
        freshWater: '',
        engineManufacturer: '',
        engineModel: null,
        engineHp: '',
        engineDrives: null,
        engineStroke: null,
        kayakType: null,
      },
      mechanicalSystem: {
        waterMarker: '',
        stabilizerSystem: '',
        bowThruster: '',
        oilWaterSeparator: '',
        steeringSystem: '',
        fireBilgePump: '',
      },
      electricalSystem: {
        output: '',
        batteriesCount: '',
        generators: '',
        batteryType: '',
        emergencyGenerator: '',
      },
      dimensions: {
        decks: '',
        displacement: '',
        heightInFt: '',
        lengthInFt: '',
        widthInFt: '',
        weightInKg: '',
        numberOfHeads: '',
        crewCabinCount: '',
        crewBerthCount: '',
        crewHeadsCount: '',
        beam: '',
        draft: '',
        mainSaloon: '',
        mainSaloonConvertible: '',
      },
      amenities: [],
      accessories: [],
      navigationEquipments: [],
      Commercial: {
        usage: '',
        boatReview: '',
        accidentHistory: '',
        internationalWarranty: '',
        internationalWarrantyValidity: '',
        internationalWarrantyTerms: '',
        repairHistory: '',
        lastMaintenance: '',
        price: '',
        isTaxEnabled: '',
        tax: '',
        description: '',
        accidentDescribe: '',
      },
      otherInformation: {
        seaTrial: false,
        images: [],
        layout: [],
        video: '',
        addMedia: {
          ownershipAttachment: [],
          boatOwnershipId: [],
          images: [],
          layout: [],
        },
        removeMedia: [],
      },
      latLng: {},

      agree: false,
      termsModal: false,
      error: false,
      focused: false,
      isBoatTypes: true,
      isButtonLoader: false,
      selectedStatus: '',
      userRole: '',
    }
  }

  async componentDidMount() {
    const { boatId } = this.state

    const { getBoatLookUps, getBoatById, clearErrorMessageShow, clearAddBoatFlag, clearUpdateBoatFlag, getStaticPages } =
      this.props
    await getBoatLookUps()

    clearAddBoatFlag()
    clearUpdateBoatFlag()
    clearErrorMessageShow()
    getStaticPages({ slug: 'as-chat-956' })

    if (boatId) {
      await getBoatById({ id: boatId })
    }
  }

  componentDidUpdate(props) {
    const { isBoatTypes } = this.state
    const { staticPages, getBoatTypeStart, currentUser, clear_getStaticPages } = this.props
    if (isBoatTypes && currentUser?.id) {
      getBoatTypeStart({ roleType: currentUser?.role?.aliasName, isAllTypes: true })
      this.setState({ isBoatTypes: false })
    }
    if (staticPages?.success || staticPages?.failure) {
      clear_getStaticPages()
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const {
      history,
      isBoatCreated,
      isBoatUpdated,
      isBoatCreateAndUpdateError,
      currentUser,
      getBoatTypeStart,
      getBoatSuccess,
      clearUpdateBoatFlag,
      boat,
      getAgreement,
      clear_getAgreement,
      agreement,
    } = nextProps
    const { location } = history

    let newState = { ...prevState }

    if (currentUser?.role?.aliasName && currentUser?.role?.aliasName !== prevState.userRole) {
      getAgreement({ role: currentUser?.role?.aliasName })

      newState = {
        ...newState,
        userRole: currentUser?.role?.aliasName,
      }
    }

    if (agreement?.success || agreement?.failure) {
      clear_getAgreement()
    }

    if (getBoatSuccess) {
      clearUpdateBoatFlag()
      newState = {
        ...newState,
        selectedKind: boat && boat.kind && boat.kind.alias,
        boatTypeAlias: boat && boat.boatType && boat.boatType.name,
      }
    }

    if (isBoatCreated || isBoatUpdated) {
      history.push({
        pathname: '/manage-boats',
        search: `page=` + (nextProps.location.state?.manageBoats_page || 1),
      })
      newState = {
        ...newState,
        isButtonLoader: false,
      }
    }

    if (isBoatCreateAndUpdateError) {
      newState = { ...newState, isButtonLoader: false }
    }

    if (location && location.search) {
      const urlParams = new URLSearchParams(location.search)

      if (urlParams.has('id')) {
        const id = urlParams.get('id')
        newState = {
          ...newState,
          boatId: id,
          isUpdate: true,
        }
      }
    }

    return newState
  }

  menuItems(values) {
    if (values) {
      return values.map(name => (
        <MenuItem key={name.lookUp.id} value={name.lookUp.id}>
          {name.lookUp.alias}
        </MenuItem>
      ))
    }
  }

  getYearListing = () => {
    const listOfYears = getListOfYears()

    return listOfYears.map(item => {
      return {
        label: item,
        value: item,
      }
    })
  }

  onClickAgree = () => {
    this.setState(prevState => ({
      agree: !prevState.agree,
    }))
  }

  termsHandler = () => {
    this.setState(prevState => ({
      termsModal: !prevState.termsModal,
    }))
  }

  prepareBoatValue = () => {
    const { boat, currentUser, boatLookUps } = this.props
    const { otherInformation, mediaUpdated, selectedStatusUpdated } = this.state

    if (boat.hasOwnProperty('id')) {
      if (!mediaUpdated) {
        const { address } = boat

        const [oldAddress] = address

        this.setState({
          latLng: getLatLngFromGeometric(oldAddress.geometricLocation),
          mediaUpdated: true,
        })
      }

      if (!selectedStatusUpdated) {
        const roleWiseBoatStatus =
          currentUser?.role?.aliasName === userRoles.BOAT_MANUFACTURER
            ? boatLookUps?.manufacturerBoatStatus
            : boatLookUps?.boatStatus
        const selectedStatus = roleWiseBoatStatus?.find(item => item.lookUp.id === boat.boatStatus.id)
        if (selectedStatus) {
          this.setState({ selectedStatus: selectedStatus?.lookUp?.alias, selectedStatusUpdated: true })
        }
      }

      const {
        boatType,
        hullMaterial,
        boatStatus,
        boatParking,
        mainSaloon,
        mainSaloonConvertible,
        fuelType,
        engineDrives,
        engineStroke,
        accessories,
        amenities,
        navigationEquipments,
        modelYear,
        yearBuilt,
        engineModel,
        decks,
        hullColor,
        noOfEngines,
        seats,
        manufacturedBy,
        isTaxEnabled,
        internationalWarranty,
        internationalWarrantyValidity,
        internationalWarrantyTerms,
        kind,
        inStock,
        kayakType,
        seaTrial,
        numberOfHeads,
        crewCabinCount,
        crewBerthCount,
        crewHeadsCount,
        freshWater,
        fuelCapacity,
        holdingCapacity,
        meta_tags,
      } = boat

      // delete extra field from boat object
      const {
        seller,
        adStatus,
        featureStatus,
        bestDealStatus,
        mustBuyStatus,
        status,
        reviews,
        auctionRoom,
        pageVisits,
        likes,
        __typename,
        isAuctionRoomCreated,
        winnerAssigned,
        isSalesEngineCreated,
        mechanicalSystemLength,
        electricalSystemLength,
        auctionApproved,
        auctionDeclined,
        auctionRoomWinner,
        costEstimationPdf,
        totalSurveyors,
        totalShippers,
        salesEngine,
        ...newBoatValues
      } = boat

      return {
        ...newBoatValues,
        isTaxEnabled: isTaxEnabled ? 'Yes' : 'No',
        internationalWarranty: internationalWarranty ? 'Yes' : 'No',
        internationalWarrantyValidity: internationalWarrantyValidity || '',
        internationalWarrantyTerms: internationalWarrantyTerms || '',

        // radio-button
        boatStatus: boatStatus && boatStatus.id,
        boatParking: boatParking && boatParking.id,
        mainSaloon: mainSaloon && mainSaloon.id,
        kind: kind && kind.id,
        mainSaloonConvertible: mainSaloonConvertible && mainSaloonConvertible.id,

        accessories: renderSelectOptions(accessories, 'alias', 'id'),
        amenities: renderSelectOptions(amenities, 'alias', 'id'),
        navigationEquipments: renderSelectOptions(navigationEquipments, 'alias', 'id'),

        // single select drop-down
        seaTrial: seaTrial ? seaTrial : false,
        modelYear: { label: modelYear, value: modelYear },
        yearBuilt: { label: yearBuilt, value: yearBuilt },
        // engineModel: { label: engineModel, value: engineModel },
        noOfEngines: { label: noOfEngines, value: noOfEngines },
        seats: seats && { label: seats, value: seats },
        decks: { label: decks, value: decks },
        hullColor: { label: hullColor.alias, value: hullColor.id },
        manufacturedBy: manufacturedBy && { label: manufacturedBy.alias, value: manufacturedBy.id },
        boatType: { label: boatType.name, value: boatType.id },
        hullMaterial: { label: hullMaterial.alias, value: hullMaterial.id },
        kayakType: kayakType && { label: kayakType.alias, value: kayakType.id },
        fuelType: fuelType && { label: fuelType.alias, value: fuelType.id },
        engineDrives: engineDrives && { label: engineDrives.alias, value: engineDrives.id },
        engineStroke: engineStroke && { label: engineStroke.alias, value: engineStroke.id },
        addMedia: otherInformation.addMedia,
        removeMedia: [],
        numberOfHeads: numberOfHeads || '',
        crewCabinCount: crewCabinCount || '',
        crewBerthCount: crewBerthCount || '',
        crewHeadsCount: crewHeadsCount || '',
        freshWater: freshWater || '',
        fuelCapacity: fuelCapacity || '',
        holdingCapacity: holdingCapacity || '',
        meta_tags: meta_tags || null,
      }
    }
  }

  setValidationSchema = (value, setValue) => {
    this.setState({ boatTypeAlias: value.label, selectedKind: '' })
    this.defaultStatus(setValue, value)
  }

  getDefaultKindValue = setFieldValue => {
    const { boatLookUps } = this.props
    const { boatKind } = boatLookUps
    const canoeKind = boatKind?.length && boatKind.find(item => item.lookUp.alias === boatType.canoe)
    const defaultKindValue = canoeKind?.lookUp?.id
    setFieldValue('kind', defaultKindValue)
    return defaultKindValue
  }

  defaultStatus = (setValue, boatTypeField) => {
    const isKayakOrCanoe = [boatType.canoe, boatType.kayak].includes(boatTypeField.label)
    const { currentUser, boatLookUps } = this.props
    const isManufacturer = currentUser.role ? currentUser.role.aliasName === userRoles.BOAT_MANUFACTURER : ''
    const isBrokerDealer = currentUser.role ? currentUser.role.aliasName === userRoles.BROKER_AND_DEALER : ''
    if (isManufacturer) {
      const defaultSelectedStatus = boatLookUps
        ? boatLookUps.manufacturerBoatStatus?.find(item => item.lookUp.alias === boatType.newBuild)
        : null
      const defaultStatusValue = defaultSelectedStatus?.lookUp?.id
      setValue('boatStatus', defaultStatusValue)
      return defaultStatusValue
    } else if (isBrokerDealer && isKayakOrCanoe) {
      const defaultSelectedStatus = boatLookUps
        ? boatLookUps.boatStatus?.find(item => item.lookUp.alias === boatType.brandNew)
        : null
      setValue('boatStatus', defaultSelectedStatus?.lookUp?.id)

      if (defaultSelectedStatus) {
        this.setState({ selectedStatus: defaultSelectedStatus?.lookUp?.alias })
      }
    } else {
      return ''
    }
  }

  getSelectedKayakType = selectedType => {
    const { boatLookUps } = this.props
    if (selectedType) {
      const { kayakStatus } = boatLookUps
      const selectedKind = kayakStatus.find(item => item.lookUp.id === selectedType)
      if (selectedKind) {
        return selectedKind?.lookUp?.alias
      }
    }
  }

  render() {
    const {
      boatOwnership,
      boatInfo,
      boatEngine,
      mechanicalSystem,
      electricalSystem,
      dimensions,
      otherInformation,
      latLng,
      Commercial,
      amenities,
      accessories,
      navigationEquipments,
      boatId,
      isUpdate,
      agree,
      termsModal,
      boatTypeAlias,
      selectedKind,
      isButtonLoader,
      selectedStatus,
    } = this.state
    const {
      boatLookUps,
      saveBoat,
      boat,
      updateBoat,
      adminBoatTypes,
      history,
      isBoatCreateAndUpdateError,
      errorMessage,
      clearErrorMessageShow,
      clearUpdateBoatFlag,
      clearAddBoatFlag,
      currentUser,
      getDocumentUrl,
      agreement: agreementContent,
      siteConfigurations,
      staticPages,
    } = this.props

    let {
      boatStatus,
      hullMaterial,
      boatParking,
      fuelType,
      engineDrives,
      engineStroke,
      mainSaloon,
      mainSaloonConvertible,
      navigationEq,
      amenitiesItems,
      accessoriesItems,
      manufacturerBoatStatus,
      boatBrands,
      hullColor,
      boatKind,
      kayakType,
      kayakStatus,
    } = boatLookUps || {}

    const isNotBrokerOrManufacturer =
      currentUser?.role?.aliasName === userRoles?.BROKER_AND_DEALER ||
      currentUser?.role?.aliasName === userRoles?.BOAT_MANUFACTURER

    const roleWiseBoatStatus =
      currentUser.role && currentUser.role.aliasName === 'boat-manufacturer' ? manufacturerBoatStatus : boatStatus

    amenitiesItems = renderSelectOptions(amenitiesItems, 'alias', 'id', true)
    accessoriesItems = renderSelectOptions(accessoriesItems, 'alias', 'id', true)
    navigationEq = renderSelectOptions(navigationEq, 'alias', 'id', true)

    const numberOptions = getDropdownValue(15)

    function priceWithComma(price) {
      return price.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')
    }
    const radioOptions = [
      {
        value: 'Yes',
        label: 'Yes',
      },
      {
        value: 'No',
        label: 'No',
      },
    ]
    const sellerRole = getLocalInfo('role')

    const initValue =
      isUpdate && boatId
        ? boat.hasOwnProperty('id') && this.prepareBoatValue()
        : {
            ...boatOwnership,
            ...boatInfo,
            ...boatEngine,
            ...mechanicalSystem,
            ...electricalSystem,
            ...dimensions,
            ...otherInformation,
            ...Commercial,
            address: [],
            amenities,
            accessories,
            navigationEquipments,
            listedBy: getLocalStorageItem('role') && getLocalInfo('role').role,
            // inStock: sellerRole.aliasName !== userRoles.BOAT_OWNER ? radioEnum.YES : null
          }

    const stockRules = {
      // inStock: Yup.string().required(
      //   requireMessage("In Stoke")
      // ).ensure(),
      stockQuantity: numberValidation('Stock Quantity').when('boatType', {
        is: v => !isYachtType(v?.label),
        then: Yup.number().required(requireMessage('Stock Quantity')).max(maximumDigits.five, requiredNumber('Stock Quantity')),
      }),
      minPurchaseQuantity: Yup.number()
        .positive(positiveNumberHelper('Minimum Purchase Quantity'))
        .when('boatType', {
          is: v => !isYachtType(v?.label),
          then: Yup.number()
            .required(requireMessage('Minimum Purchase Quantity'))
            .max(Yup.ref('stockQuantity'), 'Cannot exceed Stock Quantity.'),
        }),
    }
    const isKayakOrCanoe = boatTypeAlias === boatType.kayak || boatTypeAlias === boatType.canoe

    const kayakOrCanoeValidation =
      isKayakOrCanoe && sellerRole.aliasName === userRoles.BROKER_AND_DEALER
        ? {}
        : {
            boatStatus: Yup.string().required('Please Select Boat status.'),
          }
    const brokerAndDealerRule =
      sellerRole.aliasName === userRoles.BROKER_AND_DEALER && selectedStatus === boatType.brandNew ? stockRules : {}

    const manufacturerValidations =
      sellerRole.aliasName === userRoles.BOAT_MANUFACTURER
        ? {
            ...stockRules,
            internationalWarranty: Yup.string().required(requireMessage('International Warranty')),
            internationalWarrantyValidity: Yup.number()
              .when('internationalWarranty', {
                is: radioEnum.YES,
                then: Yup.number()
                  .required(requireMessage('International Warranty Validity'))
                  .positive(positiveNumberHelper('International Warranty Validity')),
              }),
            internationalWarrantyTerms: Yup.string().max(120, 'International Warranty Terms cannot exceed 120 characters'),
          }
        : isKayakOrCanoe
        ? {
            manufacturedBy: Yup.string().required(requireMessage('Manufactured By')).ensure(),
          }
        : {
            manufacturedBy: Yup.string().required(requireMessage('Manufactured By')).ensure(),
            hullSerialNumber: stringValidation.required(requireMessage('Hull Serial Number')),
            vehicleIdentificationNumber: stringValidation.required(requireMessage('Vehicles Identification No.')),
            ...(currentUser?.role?.aliasName === 'broker-and-dealer'
              ? {}
              : {
                  ownershipAttachment: Yup.array()
                    .required('Please add at least 1 certificate!')
                    .min(1, 'Please add at least 1 certificate!'),
                }),
          }

    const jetSkiAndKayakValidation = {
      seats: Yup.string().required('Please Select Seats.').ensure(),
    }
    const accidentHistoryValidation = {
      accidentHistory: Yup.string().required(requireMessage('Accident History')),
    }
    const hoursUsedValidation =
      sellerRole.aliasName === userRoles.BOAT_OWNER
        ? {
            usedHours: numberValidation('Used Hours').required(requireMessage('Used Hours')),
          }
        : {}

    const kayakValidations =
      boatTypeAlias === boatType.canoe
        ? {
            ...jetSkiAndKayakValidation,
            weightCapacity: Yup.string().required(requireMessage('Weight Capacity')),
          }
        : boatTypeAlias === boatType.kayak
        ? {
            ...jetSkiAndKayakValidation,
            weightCapacity: Yup.string().required(requireMessage('Weight Capacity')),
            kayakType: Yup.string()
              .when('kind', {
                is: val => {
                  const boatType = adminBoatTypes.find(item => item.lookUp?.id === val)
                  return boatType ? (val ? boatType.lookUp.alias === boatType.kayak : false) : false
                },
                then: Yup.string().required(requireMessage('Kayak Type')),
              })
              .ensure(),
          }
        : boatTypeAlias === boatType.jetSki
        ? {
            ...jetSkiAndKayakValidation,
            engineHp: numberValidation('Engine HP').required(requireMessage('Engine HP')),
            fuelType: Yup.string().required('Please Select Fuel Type.').ensure(),
            engineModel: stringValidation.ensure(),
            modelYear: Yup.string().required('Please select Model Year.'),
            engineManufacturer: stringValidation.required(requireMessage('Engine Manufacturer')),
            ...accidentHistoryValidation,
            usage: stringValidation.when('boatType', {
              is: v => !isYachtType(v?.label),
              then: Yup.string().required(requireMessage('Usage')).ensure(),
            }),
          }
        : {
            boatParking: Yup.string().required('Please Select Boat parking.').ensure(),
            noOfEngines: Yup.string().required(requireMessage('Number Of Engines')),
            modelYear: Yup.string().required('Please select Model Year.'),
            engineModel: stringValidation
              // .required(requireMessage("Engine Model"))
              .ensure(),
            engineManufacturer: stringValidation.required(requireMessage('Engine Manufacturer')),
            engineHp: numberValidation('Engine HP').required(requireMessage('Engine HP')),
            engineDrives: Yup.string().required('Please Select Engine Drives.').ensure(),
            fuelType: Yup.string().required('Please Select Fuel Type.').ensure(),
            engineStroke: Yup.string().required('Please Select Engine Stroke.').ensure(),
            stabilizerSystem: stringValidation.required(requireMessage('Stabilizer System')).ensure(),
            steeringSystem: stringValidation.required(requireMessage('Steering System')).ensure(),
            batteriesCount: numberValidation('No. of Batteries').required(requireMessage('No. of Batteries')),
            generators: stringValidation,
            batteryType: stringValidation.required(requireMessage('Battery Type')),
            mainSaloon: Yup.string().required(requireMessage('Main Saloon')),
            mainSaloonConvertible: Yup.string().required(requireMessage('Main Saloon Convertible')),
            ...accidentHistoryValidation,
            decks: Yup.string().required(requireMessage('Decks')),
            beam: numberValidation('Beam').max(maximumDigits.five, requiredNumber('Beam')).required(requireMessage('Beam')),
            draft: numberValidation('Draft').max(maximumDigits.five, requiredNumber('Draft')).required(requireMessage('Draft')),

            amenities: Yup.array().min(2, requireMessage('Minimum 2 Amenities')).ensure(),
            accessories: Yup.array().min(2, requireMessage('Minimum 2 Accessories')).ensure(),
            navigationEquipments: Yup.array().min(2, requireMessage('Minimum 2 Navigation Equipments')).ensure(),

            isTaxEnabled: Yup.string().when('boatType', {
              is: v => String(v?.label).toLowerCase() !== 'super yacht' && String(v?.label).toLowerCase() !== 'yacht',
              then: Yup.string().required(requireMessage('Tax(VAT)')),
            }),
            usage: stringValidation.when('boatType', {
              is: v => String(v?.label).toLowerCase() !== 'super yacht' && String(v?.label).toLowerCase() !== 'yacht',
              then: Yup.string().required(requireMessage('Usage')).ensure(),
            }),
            numberOfHeads: numberValidation('Number Of Heads').max(maximumDigits.five, requiredNumber('Number Of Heads')),
            crewCabinCount: numberValidation('No. Of Crew Cabins').max(
              maximumDigits.five,
              requiredNumber('No. Of Crew Cabins')
            ),
            crewBerthCount: numberValidation('No. Of Crew Berths').max(
              maximumDigits.five,
              requiredNumber('No. Of Crew Berths')
            ),
            crewHeadsCount: numberValidation('No. Of Crew Heads').max(maximumDigits.five, requiredNumber('No. Of Crew Heads')),
          }

    return (
      <Fragment>
        <DashboardLayout className="add--boat--layout">
          <div className="pl-3 pr-3">
            {isUpdate && !boat.hasOwnProperty('id') ? (
              <Loader isPageLoader />
            ) : (
              <div>
                <Formik
                  initialValues={{ ...initValue }}
                  onSubmit={values => {
                    if (!agree) {
                      popUpMessage('Please agree terms & conditions.', 'Terms & Agreement')
                      this.setState({ isButtonLoader: false })
                      return
                    }
                    this.setState({ isButtonLoader: true })
                    const { images, layout, boatOwnershipId, ownershipAttachment, stockAvailable, ...inputValues } = values

                    const input = {
                      ...inputValues,
                      amenities: getIds(values.amenities),
                      stockQuantity: parseInt(values.stockQuantity) >= 0 ? parseInt(values.stockQuantity) : null,
                      minPurchaseQuantity:
                        parseInt(values.minPurchaseQuantity) >= 0 ? parseInt(values.minPurchaseQuantity) : null,
                      isTaxEnabled: values.isTaxEnabled === 'Yes' ? true : false,
                      internationalWarranty: values.internationalWarranty === radioEnum.YES,
                      internationalWarrantyValidity: +values.internationalWarrantyValidity || 0,
                      internationalWarrantyTerms: values.internationalWarrantyTerms || '',
                      accessories: getIds(values.accessories),
                      navigationEquipments: getIds(values.navigationEquipments),
                      modelYear: values.modelYear.value,
                      yearBuilt: values.yearBuilt.value,
                      hullColor: values.hullColor.value,
                      manufacturedBy: values.manufacturedBy && values.manufacturedBy.value,
                      beam: values.beam && parseInt(values.beam),
                      // engineModel: values.engineModel && parseInt(values.engineModel.value),
                      draft: values.draft && parseInt(values.draft),
                      weightCapacity: parseInt(values.weightCapacity),
                      boatType: values.boatType.value,
                      decks: values.decks.value,
                      noOfEngines: values.noOfEngines && values.noOfEngines.value,
                      seats: values.seats && parseInt(values.seats.value),
                      hullMaterial: values.hullMaterial.value,
                      fuelType: values.fuelType && values.fuelType.value,
                      engineDrives: values.engineDrives && values.engineDrives.value,
                      engineStroke: values.engineStroke && values.engineStroke.value,
                      price: parseInt(values.price),
                      usedHours: values.usedHours,
                      heightInFt: parseInt(values.heightInFt),
                      widthInFt: parseInt(values.widthInFt),
                      lengthInFt: parseInt(values.lengthInFt),
                      weightInKg: parseInt(values.weightInKg),
                      numberOfHeads: parseInt(values.numberOfHeads),
                      crewCabinCount: parseInt(values.crewCabinCount),
                      crewBerthCount: parseInt(values.crewBerthCount),
                      crewHeadsCount: parseInt(values.crewHeadsCount),
                      listedBy: currentUser && currentUser.role ? currentUser.role.role : values.listedBy,
                      addMedia: getAddMedia(values.addMedia),
                      kayakType: values.kayakType && values.kayakType.value,
                      holdingCapacity: parseInt(values.holdingCapacity),
                      freshWater: parseInt(values.freshWater),
                    }
                    if (isUpdate) {
                      const submitValues = getSubmitValues(input, values.boatType, selectedKind, true)
                      updateBoat(submitValues)
                      clearErrorMessageShow()
                    } else {
                      const submitValues = getSubmitValues(input, values.boatType, selectedKind)
                      saveBoat(submitValues)
                      clearErrorMessageShow()
                    }
                  }}
                  validationSchema={Yup.object().shape({
                    address: Yup.array(
                      Yup.object({
                        city: Yup.string(),
                      })
                    )
                      .compact(v => !v.city || !v.country)
                      .required(requireMessage('Address'))
                      .min(1, requireMessage('Address'))
                      .ensure(),
                    boatType: Yup.object().required('Please Select Boat Type.'),
                    ...manufacturerValidations,
                    // manufacturedBy: Yup.string().required('Manufactured By is required..'),
                    hullColor: Yup.string().required(requireMessage('Hull Color')),
                    boatName: stringValidation.required(requireMessage('Boat Model')),
                    yearBuilt: Yup.string().required('Please Select Boat Build Year.'),
                    hullMaterial: Yup.string().required(requireMessage('Hull Material')),
                    trailer: Yup.string().required('Select yes if you have trailer.'),
                    heightInFt: Yup.number()
                      .max(maximumDigits.five, requiredNumber('Height'))
                      .positive(positiveNumberHelper('Height'))
                      .required(requireMessage('Height')),
                    lengthInFt: Yup.number()
                      .max(maximumDigits.five, requiredNumber('Length'))
                      .positive(positiveNumberHelper('Length'))
                      .required(requireMessage('Length')),
                    widthInFt: Yup.number()
                      .max(maximumDigits.five, requiredNumber('Width'))
                      .positive(positiveNumberHelper('Width'))
                      .required(requireMessage('Width')),
                    weightInKg: numberValidation('Weight').required(requireMessage('Weight')),
                    price: Yup.number()
                      .positive(positiveNumberHelper('Price'))
                      .when('boatType', {
                        is: v => String(v?.label).toLowerCase() !== 'super yacht' && String(v?.label).toLowerCase() !== 'yacht',
                        then: Yup.number()
                          .required(requireMessage('Price In USD'))
                          .max(250000, 'price must be less than or equal to 250,000 $'),
                      }),
                    images: Yup.array().min(5, requireMessage('Minimum 5 photos upload')),
                    layout: Yup.array().min(1, requireMessage('Minimum 1 layout photo')),
                    video: urlValidation,
                    boatReview: stringValidation.required(requireMessage('Boat Review')),
                    description: stringValidation
                      .required(requireMessage('Boat Description'))
                      .ensure()
                      .test('match', requiredWords('Boat description'), val => validateWords(val)),
                    accidentDescribe: stringValidation.when('accidentHistory', {
                      is: 'Yes',
                      then: stringValidation.required(requiredWords('Damage Description', 50)).ensure(),
                      otherwise: stringValidation.ensure(),
                    }),
                    repairHistory: stringValidation.ensure(),
                    displacement: stringValidation.ensure(),
                    complianceLabelNumber: stringValidation.ensure(),
                    licenceNo: stringValidation.ensure(),
                    fuelCapacity: numberValidation('Fuel Capacity Tank (liter)'),
                    freshWater: numberValidation('Fresh Water Tank (liter)'),
                    holdingCapacity: numberValidation('Holding Capacity Tank (gallons)'),
                    waterMarker: stringValidation.ensure(),
                    bowThruster: stringValidation.ensure(),
                    oilWaterSeparator: stringValidation.ensure(),
                    fireBilgePump: stringValidation.ensure(),
                    output: stringValidation.ensure(),
                    emergencyGenerator: stringValidation.ensure(),
                    ...kayakValidations,
                    ...brokerAndDealerRule,
                    ...kayakOrCanoeValidation,
                    ...hoursUsedValidation,
                  })}
                  render={({ errors, status, touched, values, setFieldValue, handleSubmit }) => (
                    <Form>
                      {/* kept console for debug add form issue in live */}
                      {/* {console.log(errors)} */}
                      {/* {console.log(values)} */}
                      <ErrorFocus />
                      <containerFluid>
                        <div className="pl-2 pt-3 pb-3 map-div map-title-bg">
                          <h3 className="add-form-sec-title">
                            {!isUpdate ? `Add Boat` : `Edit Boat`}{' '}
                            {values?.adId && <span className="font-weight-400 font-14">Ad ID: {`${values.adId}`}</span>}
                          </h3>
                        </div>
                        <Row className="add-form-grid">
                          <Col md={6}>
                            <div>
                              <GoogleMap
                                className="googleMap-position"
                                latLng={latLng}
                                fetch={result => {
                                  console.log(result)
                                  if (result?.address1) {
                                    setFieldValue('address', [
                                      {
                                        ...result,
                                        type:
                                          boatTypeAlias === boatType.jetSki
                                            ? AddressInputTypeEnum.JET_SKI_ADDRESS
                                            : AddressInputTypeEnum.COMMON_ADDRESS,
                                      },
                                    ])
                                  } else {
                                    setFieldValue('address', [])
                                  }
                                }}
                                height={35}
                                width={100}
                                placeHolder={`${
                                  boatTypeAlias === boatType.jetSki ? 'Jet Ski Location Or Storage' : 'Boat Location'
                                }`}
                                columnName={'address'}
                                isError={errors.address}
                                isUpdate={isUpdate}
                                isFullGrid
                                crossCountryAllowed={currentUser.role?.aliasName === userRoles.BOAT_MANUFACTURER}
                              />
                            </div>
                          </Col>

                          <Col md={6}>
                            <Card>
                              <CardContent className="dashboard-forms-field">
                                <Grid container spacing={2}>
                                  <Grid xs={12} item>
                                    <h3 className="add-form-sec-title">
                                      Boat Information
                                    </h3>
                                  </Grid>
                                  <Grid item sm={6}>
                                    <SingleSelect
                                      label="Boat Type"
                                      name="boatType"
                                      placeholder=""
                                      selectedOption={values.boatType}
                                      options={renderSelectOptions(adminBoatTypes, 'name', 'id')}
                                      onChange={value => {
                                        setFieldValue('kind', null)
                                        this.setValidationSchema(value, setFieldValue)
                                        setFieldValue('boatType', value)
                                      }}
                                      required
                                    />
                                    <ErrorMessage component="div" name="boatType" className="error-message err-msg-boatType" />
                                  </Grid>
                                  {sellerRole.aliasName !== userRoles.BOAT_MANUFACTURER && (
                                    <Grid item sm={6}>
                                      <SingleSelect
                                        label="Manufactured By"
                                        name="manufacturedBy"
                                        selectedOption={values.manufacturedBy}
                                        options={renderSelectOptions(boatBrands, 'alias', 'id', true)}
                                        onChange={value => setFieldValue('manufacturedBy', value)}
                                        required
                                      />
                                      <ErrorMessage
                                        component="div"
                                        name="manufacturedBy"
                                        className="error-message err-msg-manufacturedBy"
                                      />
                                    </Grid>
                                  )}
                                  <Grid item sm={6}>
                                    <Field
                                      label="Hour Used"
                                      name="usedHours"
                                      type="number"
                                      required={!isNotBrokerOrManufacturer}
                                      value={values.usedHours}
                                      className="form-control"
                                      onChangeText={e => setFieldValue('usedHours', e.target.value)}
                                    />
                                    <ErrorMessage
                                      component="div"
                                      name="usedHours"
                                      className="error-message err-msg-usedHours"
                                    />
                                  </Grid>
                                  <Grid item sm={6}>
                                    <SingleSelect
                                      label="Hull Color"
                                      name="hullColor"
                                      placeholder=""
                                      selectedOption={values.hullColor}
                                      options={renderSelectOptions(hullColor, 'alias', 'id', true)}
                                      onChange={value => setFieldValue('hullColor', value)}
                                      required
                                    />
                                    <ErrorMessage
                                      component="div"
                                      name="hullColor"
                                      className="error-message err-msg-hullColor"
                                    />
                                  </Grid>
                                  <Grid item sm={6}>
                                    <Field
                                      label={boatTypeAlias === boatType.jetSki ? 'Brand Name' : 'Boat Model'}
                                      name="boatName"
                                      type="text"
                                      required
                                      value={values.boatName}
                                      className="form-control"
                                      onChangeText={e => setFieldValue('boatName', e.target.value)}
                                      onBlur={e => setFieldValue('boatName', trimmedValue(e.target.value))}
                                    />
                                    <ErrorMessage component="div" name="boatName" className="error-message err-msg-boatName" />
                                  </Grid>
                                  {!isKayakOrCanoe && boatTypeAlias !== boatType.jetSki && (
                                    <Grid item sm={6}>
                                      <Field
                                        name="listedBy"
                                        label="Listed By"
                                        type="text"
                                        value={values.listedBy}
                                        className="form-control"
                                        onChangeText={e => setFieldValue('listedBy', e.target.value)}
                                        disabled
                                      />
                                      <ErrorMessage
                                        component="div"
                                        name="listedBy"
                                        className="error-message err-msg-listedBy"
                                      />
                                    </Grid>
                                  )}
                                  <Grid item sm={6}>
                                    <SingleSelect
                                      label="Year Built"
                                      name="yearBuilt"
                                      placeholder=""
                                      selectedOption={values.yearBuilt}
                                      options={this.getYearListing()}
                                      onChange={value => setFieldValue('yearBuilt', value)}
                                      required
                                    />
                                    <ErrorMessage
                                      component="div"
                                      name="yearBuilt"
                                      className="error-message err-msg-yearBuilt"
                                    />
                                  </Grid>

                                  <Grid item sm={6}>
                                    <SingleSelect
                                      label="Hull Material"
                                      name="hullMaterial"
                                      placeholder=""
                                      selectedOption={values.hullMaterial}
                                      options={renderSelectOptions(hullMaterial, 'alias', 'id', true)}
                                      onChange={value => setFieldValue('hullMaterial', value)}
                                      required
                                    />
                                    <ErrorMessage
                                      component="div"
                                      name="hullMaterial"
                                      className="error-message err-msg-hullMaterial"
                                    />
                                  </Grid>

                                  {((!isKayakOrCanoe && sellerRole.aliasName === userRoles.BROKER_AND_DEALER) ||
                                    sellerRole.aliasName !== userRoles.BROKER_AND_DEALER) && (
                                    <Grid item sm={6}>
                                      <Field
                                        label={`${boatTypeAlias === boatType.jetSki ? 'Jet Ski' : 'Boat'} Status`}
                                        name="boatStatus"
                                        bold
                                        type="form-radio"
                                        value={values.boatStatus}
                                        className="form-control"
                                        options={roleWiseBoatStatus}
                                        onChangeText={e => {
                                          const selectedStatus = roleWiseBoatStatus?.find(
                                            item => item.lookUp.id === e.target.value
                                          )
                                          if (selectedStatus) {
                                            this.setState({ selectedStatus: selectedStatus?.lookUp?.alias })
                                          }
                                          setFieldValue('boatStatus', e.target.value)
                                        }}
                                      />
                                      <ErrorMessage
                                        component="div"
                                        name="boatStatus"
                                        className="error-message err-msg-boatStatus"
                                      />
                                    </Grid>
                                  )}
                                  {/* {(boatTypeAlias === boatType.kayak) && (
                                        <Grid item sm={3}>
                                          <Field
                                            label="Kind"
                                            name="kind"
                                            type="form-radio"
                                            bold
                                            value={values.kind ? values.kind : this.getDefaultKindValue(setFieldValue)}
                                            className="form-control"
                                            options={(selectedKind === boatMetaType || selectedKind === boatType.kayakType) ? [...boatKind, ...kayakStatus] : boatKind}
                                            onChangeText={(e) => {
                                              const kind = [...boatKind, ...kayakStatus].find(
                                                (item) =>
                                                  item.lookUp.id === e.target.value
                                              );
                                              if (kind?.lookUp) {
                                                this.setState({
                                                  selectedKind: kind.lookUp.alias,
                                                });
                                              }
                                              setFieldValue("kind", e.target.value);
                                            }}
                                          />
                                          <ErrorMessage
                                            component="div"
                                            name="kind"
                                            className="error-message err-m                                            classNamesg-"
                                          />
                                        </Grid>
                                      )} */}
                                  {boatTypeAlias === boatType.kayak && (
                                    <Grid item sm={6}>
                                      <SingleSelect
                                        label="Kayak Type"
                                        name="kayakType"
                                        placeholder=""
                                        selectedOption={values.kayakType}
                                        options={renderSelectOptions(kayakType, 'alias', 'id', true)}
                                        onChange={value => setFieldValue('kayakType', value)}
                                        required
                                      />
                                      <ErrorMessage
                                        component="div"
                                        name="kayakType"
                                        className="error-message err-msg-kayakType"
                                      />
                                    </Grid>
                                  )}

                                  {/* {sellerRole.aliasName !== userRoles.BOAT_OWNER && (
                                        <Grid item sm={6}>
                                          <Field
                                            label="In Stock"
                                            name="inStock"
                                            type="form-radio"
                                            bold
                                            value={values.inStock}
                                            className="form-control"
                                            isNotLookup
                                            options={radioOptions}
                                            onChangeText={(e) => {
                                              if (e.target.value === radioEnum.NO) {
                                                setFieldValue("stockQuantity", 0)
                                                setFieldValue("stockAvailable", false)
                                              } else {
                                                setFieldValue("stockQuantity", 0)
                                                setFieldValue("stockAvailable", true)
                                              }
                                              setFieldValue(
                                                "inStock",
                                                e.target.value
                                              )
                                            }
                                            }
                                          />
                                          <ErrorMessage
                                            component="div"
                                            name="inStock"
                                            className="error-message err-m                                            classNamesg-"
                                          />
                                        </Grid>
                                      )} */}
                                  {((sellerRole.aliasName === userRoles.BROKER_AND_DEALER &&
                                    selectedStatus === boatType.brandNew) ||
                                    sellerRole.aliasName === userRoles.BOAT_MANUFACTURER) && (
                                    <>
                                      <Grid item sm={6}>
                                        <Field
                                          label="Stock Quantity"
                                          required={!isYachtType(values.boatType?.label)}
                                          name="stockQuantity"
                                          type="number"
                                          value={values.stockQuantity}
                                          className="form-control"
                                          onChangeText={e => setFieldValue('stockQuantity', parseInt(e.target.value))}
                                        />
                                        <ErrorMessage
                                          component="div"
                                          name="stockQuantity"
                                          className="error-message err-msg-stockQuantity"
                                        />
                                      </Grid>

                                      <Grid item sm={6}>
                                        <Field
                                          label="Minimum Purchase Quantity"
                                          required={
                                            String(values.boatType?.label).toLowerCase() !== 'super yacht' &&
                                            String(values.boatType?.label).toLowerCase() !== 'yacht'
                                          }
                                          name="minPurchaseQuantity"
                                          type="number"
                                          value={values.minPurchaseQuantity}
                                          className="form-control"
                                          onChangeText={e => setFieldValue('minPurchaseQuantity', e.target.value)}
                                        />
                                        <ErrorMessage
                                          component="div"
                                          name="minPurchaseQuantity"
                                          className="error-message err-msg-minPurchaseQuantity"
                                        />
                                      </Grid>
                                    </>
                                  )}

                                  {sellerRole.aliasName === userRoles.BOAT_MANUFACTURER && (
                                    <>
                                      <Grid item sm={12}>
                                        <Field
                                          label="Are you providing an international warranty on this product?"
                                          name="internationalWarranty"
                                          type="form-radio"
                                          bold
                                          required
                                          value={values.internationalWarranty}
                                          className="form-control"
                                          isNotLookup
                                          options={radioOptions}
                                          onChangeText={e => setFieldValue('internationalWarranty', e.target.value)}
                                        />
                                        <ErrorMessage
                                          component="div"
                                          name="internationalWarranty"
                                          className="error-message err-msg-internationalWarranty"
                                        />
                                      </Grid>

                                      {values.internationalWarranty === radioEnum.YES && (
                                        <>
                                          <Grid item sm={12}>
                                            <Field
                                              label="How many months the warranty is covering?"
                                              required
                                              name="internationalWarrantyValidity"
                                              type="number"
                                              value={values.internationalWarrantyValidity}
                                              className="form-control"
                                              onChangeText={e => setFieldValue('internationalWarrantyValidity', e.target.value)}
                                            />
                                            <ErrorMessage
                                              component="div"
                                              name="internationalWarrantyValidity"
                                              className="error-message err-msg-internationalWarrantyValidity"
                                            />
                                          </Grid>

                                          <Grid item sm={12}>
                                            <Field
                                              label="Warranty Terms"
                                              name="internationalWarrantyTerms"
                                              value={values.internationalWarrantyTerms}
                                              type="textarea"
                                              className="form-control"
                                              onChangeText={e => {
                                                setFieldValue('internationalWarrantyTerms', e.target.value)
                                              }}
                                              onBlur={e => setFieldValue('internationalWarrantyTerms', trimmedValue(e.target.value))}
                                            />
                                            <ErrorMessage component="div" name="internationalWarrantyTerms" className="error-message err-msg-internationalWarrantyTerms" />
                                          </Grid>
                                        </>
                                      )}
                                    </>
                                  )}

                                  {isKayakOrCanoe && (
                                    <Grid item sm={6}>
                                      <Field
                                        label="Weight Capacity Kg"
                                        required
                                        name="weightCapacity"
                                        type="number"
                                        value={values.weightCapacity}
                                        className="form-control"
                                        onChangeText={e => {
                                          setFieldValue('weightCapacity', e.target.value)
                                        }}
                                      />
                                      <ErrorMessage
                                        component="div"
                                        name="weightCapacity"
                                        className="error-message err-msg-weightCapacity"
                                      />
                                    </Grid>
                                  )}

                                  {(isKayakOrCanoe || boatTypeAlias === boatType.jetSki) && (
                                    <Grid item sm={6}>
                                      <SingleSelect
                                        label="Seats"
                                        name="seats"
                                        selectedOption={values.seats}
                                        options={renderSelectOptions(numberOptions, 'value', 'id', false)}
                                        onChange={value => setFieldValue('seats', value)}
                                        required
                                      />
                                      <ErrorMessage component="div" name="seats" className="error-message err-msg-seats" />
                                    </Grid>
                                  )}

                                  {!isKayakOrCanoe && boatTypeAlias !== boatType.jetSki && (
                                    <Grid item sm={5}>
                                      <Field
                                        label="The Boat Parking"
                                        name="boatParking"
                                        type="form-radio"
                                        bold
                                        value={values.boatParking}
                                        className="form-control"
                                        options={boatParking}
                                        onChangeText={e => setFieldValue('boatParking', e.target.value)}
                                      />
                                      <ErrorMessage
                                        component="div"
                                        name="boatParking"
                                        className="error-message err-msg-boatParking"
                                      />
                                    </Grid>
                                  )}

                                  <Grid item sm={3}>
                                    <Field
                                      label="Trailer"
                                      name="trailer"
                                      type="form-radio"
                                      bold
                                      value={values.trailer}
                                      className="form-control"
                                      isNotLookup
                                      options={radioOptions}
                                      onChangeText={e => setFieldValue('trailer', e.target.value)}
                                    />
                                    <ErrorMessage component="div" name="trailer" className="error-message err-msg-trailer" />
                                  </Grid>
                                </Grid>
                              </CardContent>
                            </Card>
                          </Col>
                          {!isKayakOrCanoe && sellerRole.aliasName !== userRoles.BOAT_MANUFACTURER && (
                            <Col md={6}>
                              <Card>
                                <CardContent>
                                  <Grid container className="boat--owner-ship-section">
                                    <Grid xs={12} item className="pb-1">
                                      <h3 className="add-form-sec-title">
                                        Boat Ownership
                                      </h3>
                                    </Grid>

                                    {!isKayakOrCanoe && (
                                      <Grid item sm={12}>
                                        <Field
                                          label="Compliance Label Number (If Available)"
                                          name="complianceLabelNumber"
                                          type="text"
                                          value={values.complianceLabelNumber}
                                          className="form-control"
                                          onChangeText={e => setFieldValue('complianceLabelNumber', e.target.value)}
                                          onBlur={e => setFieldValue('complianceLabelNumber', trimmedValue(e.target.value))}
                                        />
                                        <ErrorMessage
                                          component="div"
                                          name="complianceLabelNumber"
                                          className="error-message err-msg-complianceLabelNumber"
                                        />
                                      </Grid>
                                    )}
                                    {!isKayakOrCanoe && (
                                      <Grid item sm={12}>
                                        <Field
                                          label=" Pleasure Craft License Number Of
                                                Registration No. (If Already Licensed)"
                                          name="licenceNo"
                                          type="text"
                                          value={values.licenceNo}
                                          className="form-control"
                                          onChangeText={e => setFieldValue('licenceNo', e.target.value)}
                                          onBlur={e => setFieldValue('licenceNo', trimmedValue(e.target.value))}
                                        />
                                        <ErrorMessage
                                          component="div"
                                          name="licenceNo"
                                          className="error-message err-msg-licenceNo"
                                        />
                                      </Grid>
                                    )}
                                    {!isKayakOrCanoe && (
                                      <Grid item sm={12}>
                                        <Field
                                          name="hullSerialNumber"
                                          helpText={helpTextEnum.boat.HIN}
                                          type="text"
                                          required
                                          label="Hull Serial Number (HIN)"
                                          value={values.hullSerialNumber}
                                          className="form-control"
                                          onChangeText={e => setFieldValue('hullSerialNumber', e.target.value)}
                                          onBlur={e => setFieldValue('hullSerialNumber', trimmedValue(e.target.value))}
                                        />
                                        <ErrorMessage
                                          component="div"
                                          name="hullSerialNumber"
                                          className="error-message err-msg-hullSerialNumber"
                                        />
                                      </Grid>
                                    )}
                                    {!isKayakOrCanoe && (
                                      <Grid item sm={12}>
                                        <Field
                                          name="vehicleIdentificationNumber"
                                          type="text"
                                          label="Vehicles Identification No. (VIN)"
                                          required
                                          value={values.vehicleIdentificationNumber}
                                          className="form-control"
                                          onChangeText={e => setFieldValue('vehicleIdentificationNumber', e.target.value)}
                                          onBlur={e =>
                                            setFieldValue('vehicleIdentificationNumber', trimmedValue(e.target.value))
                                          }
                                        />
                                        <ErrorMessage
                                          component="div"
                                          name="vehicleIdentificationNumber"
                                          className="error-message err-msg-vehicleIdentificationNumber"
                                        />
                                      </Grid>
                                    )}

                                    {!isKayakOrCanoe && (
                                      <Grid item sm={12} className="overflow-hidden">
                                        <div className="d-flex flex-wrap justify-content-center mt-3">
                                          <Field
                                            label="Attach Boat Ownership Certificate"
                                            buttonText="Upload Document"
                                            required={currentUser?.role?.aliasName !== 'broker-and-dealer'}
                                            helpText={helpTextEnum.boat.ownershipAttachment}
                                            name="ownershipAttachment"
                                            type="single-document"
                                            value={values.ownershipAttachment}
                                            className="form-control"
                                            onChangeText={setFieldValue}
                                            deleteIds={values.removeMedia}
                                            addMedia={values.addMedia}
                                            mediaName={MEDIA_NAME.BOATOWNERSHIPATTACHMENT}
                                            getDocumentUrl={getDocumentUrl}
                                          />
                                          <ErrorMessage
                                            component="div"
                                            name="ownershipAttachment"
                                            className="error-message err-msg-ownershipAttachment"
                                          />
                                        </div>
                                      </Grid>
                                    )}

                                    {!isKayakOrCanoe && boatAttachIdAccessibleRoles.includes(currentUser?.role?.aliasName) && (
                                      <Grid item sm={12}>
                                        <div className="d-flex flex-wrap justify-content-center mt-3">
                                          <Field
                                            buttonText="Upload Document"
                                            helpText={helpTextEnum.boat.boatOwnershipId}
                                            name="boatOwnershipId"
                                            label="Boat Owner ID"
                                            type="single-document"
                                            value={values.boatOwnershipId}
                                            className="form-control"
                                            onChangeText={setFieldValue}
                                            deleteIds={values.removeMedia}
                                            addMedia={values.addMedia}
                                            mediaName={MEDIA_NAME.BOATOWNERSHIPID}
                                            getDocumentUrl={getDocumentUrl}
                                          />
                                        </div>
                                      </Grid>
                                    )}
                                  </Grid>
                                </CardContent>
                              </Card>
                            </Col>
                          )}
                          <Col md={6}>
                            <Card>
                              <CardContent className="dashboard-forms-field">
                                <Grid container spacing={2}>
                                  <Grid xs={12} item>
                                    <h3 className="add-form-sec-title">
                                      {boatTypeAlias === boatType.jetSki ? 'Mechanical System' : 'Boat Engine'}
                                    </h3>
                                  </Grid>
                                  {!isKayakOrCanoe && boatTypeAlias !== boatType.jetSki && (
                                    <Grid item sm={6}>
                                      <SingleSelect
                                        label="Number Of Engines"
                                        name="noOfEngines"
                                        placeholder=""
                                        selectedOption={values.noOfEngines}
                                        options={renderSelectOptions(numberOptions, 'value', 'id', false)}
                                        onChange={value => setFieldValue('noOfEngines', value)}
                                        required
                                      />
                                      <ErrorMessage
                                        component="div"
                                        name="noOfEngines"
                                        className="error-message err-msg-noOfEngines"
                                      />
                                    </Grid>
                                  )}

                                  {isKayakOrCanoe && (
                                    <Grid item sm={6}>
                                      <Field
                                        label={'Engine ManuFacturer'}
                                        name="engineManufacturer"
                                        type="text"
                                        value={values.engineManufacturer}
                                        className="form-control"
                                        onChangeText={e => setFieldValue('engineManufacturer', e.target.value)}
                                        onBlur={e => setFieldValue('engineManufacturer', trimmedValue(e.target.value))}
                                      />
                                      <ErrorMessage
                                        component="div"
                                        name="engineManufacturer"
                                        className="error-message err-msg-engineManufacturer"
                                      />
                                    </Grid>
                                  )}

                                  {!isKayakOrCanoe && (
                                    <Grid item sm={6}>
                                      <Field
                                        label="Engine ManuFacturer"
                                        name="engineManufacturer"
                                        type="text"
                                        value={values.engineManufacturer}
                                        required
                                        className="form-control"
                                        onChangeText={e => setFieldValue('engineManufacturer', e.target.value)}
                                        onBlur={e => setFieldValue('engineManufacturer', trimmedValue(e.target.value))}
                                      />
                                      <ErrorMessage
                                        component="div"
                                        name="engineManufacturer"
                                        className="error-message err-msg-engineManufacturer"
                                      />
                                    </Grid>
                                  )}

                                  <Grid item sm={6}>
                                    <SingleSelect
                                      label={'Year Model'}
                                      name="modelYear"
                                      placeholder=""
                                      selectedOption={values.modelYear}
                                      options={this.getYearListing()}
                                      onChange={value => setFieldValue('modelYear', value)}
                                      required={!isKayakOrCanoe}
                                    />
                                    <ErrorMessage
                                      component="div"
                                      name="modelYear"
                                      className="error-message err-msg-modelYear"
                                    />
                                  </Grid>
                                  {!isKayakOrCanoe && (
                                    <Grid item sm={6}>
                                      <Field
                                        label="Fuel Capacity Tank (liter)"
                                        name="fuelCapacity"
                                        type="number"
                                        value={values.fuelCapacity}
                                        className="form-control"
                                        onChangeText={e => setFieldValue('fuelCapacity', e.target.value)}
                                      />
                                      <ErrorMessage
                                        component="div"
                                        name="fuelCapacity"
                                        className="error-message err-msg-fuelCapacity"
                                      />
                                    </Grid>
                                  )}
                                  <Grid item sm={6}>
                                    <Field
                                      label={'Engine Model'}
                                      name="engineModel"
                                      type="text"
                                      value={values.engineModel}
                                      className="form-control"
                                      onChangeText={e => setFieldValue('engineModel', e.target.value)}
                                      onBlur={e => setFieldValue('engineModel', trimmedValue(e.target.value))}
                                    />
                                    <ErrorMessage
                                      component="div"
                                      name="engineModel"
                                      className="error-message err-msg-engineModel"
                                    />
                                  </Grid>
                                  {!isKayakOrCanoe && (
                                    <Grid item sm={6}>
                                      <Field
                                        label={boatTypeAlias === boatType.jetSki ? 'Power HP' : 'Engine HP'}
                                        required
                                        name="engineHp"
                                        type="number"
                                        value={values.engineHp}
                                        className="form-control"
                                        onChangeText={e => setFieldValue('engineHp', e.target.value)}
                                      />
                                      <ErrorMessage
                                        component="div"
                                        name="engineHp"
                                        className="error-message err-msg-engineHp"
                                      />
                                    </Grid>
                                  )}

                                  {!isKayakOrCanoe && boatTypeAlias !== boatType.jetSki && (
                                    <Grid item sm={6}>
                                      <Field
                                        label="Fresh Water Tank (liter)"
                                        name="freshWater"
                                        type="number"
                                        value={values.freshWater}
                                        className="form-control"
                                        onChangeText={e => setFieldValue('freshWater', e.target.value)}
                                      />
                                      <ErrorMessage
                                        component="div"
                                        name="freshWater"
                                        className="error-message err-msg-freshWater"
                                      />
                                    </Grid>
                                  )}
                                  {!isKayakOrCanoe && boatTypeAlias !== boatType.jetSki && (
                                    <Grid item sm={6}>
                                      <Field
                                        label="Holding Capacity Tank (gallons)"
                                        name="holdingCapacity"
                                        type="number"
                                        value={values.holdingCapacity}
                                        className="form-control"
                                        onChangeText={e => setFieldValue('holdingCapacity', e.target.value)}
                                      />
                                      <ErrorMessage
                                        component="div"
                                        name="holdingCapacity"
                                        className="error-message err-msg-holdingCapacity"
                                      />
                                    </Grid>
                                  )}
                                  {!isKayakOrCanoe && boatTypeAlias !== boatType.jetSki && (
                                    <Grid item sm={4}>
                                      <SingleSelect
                                        label="Engine Drives"
                                        name="engineDrives"
                                        placeholder=""
                                        selectedOption={values.engineDrives}
                                        options={renderSelectOptions(engineDrives, 'alias', 'id', true)}
                                        onChange={value => setFieldValue('engineDrives', value)}
                                        required
                                      />
                                      <ErrorMessage
                                        component="div"
                                        name="engineDrives"
                                        className="error-message err-msg-engineDrives"
                                      />
                                    </Grid>
                                  )}

                                  {!isKayakOrCanoe && (
                                    <Grid item sm={4}>
                                      <SingleSelect
                                        label="Fuel Type"
                                        name="fuelType"
                                        placeholder=""
                                        selectedOption={values.fuelType}
                                        options={renderSelectOptions(fuelType, 'alias', 'id', true)}
                                        onChange={value => setFieldValue('fuelType', value)}
                                        required
                                      />
                                      <ErrorMessage
                                        component="div"
                                        name="fuelType"
                                        className="error-message err-msg-fuelType"
                                      />
                                    </Grid>
                                  )}

                                  {!isKayakOrCanoe && boatTypeAlias !== boatType.jetSki && (
                                    <Grid item sm={4}>
                                      <SingleSelect
                                        label="Engine Stroke"
                                        name="engineStroke"
                                        placeholder=""
                                        selectedOption={values.engineStroke}
                                        options={renderSelectOptions(engineStroke, 'alias', 'id', true)}
                                        onChange={value => setFieldValue('engineStroke', value)}
                                        required
                                      />
                                      <ErrorMessage
                                        component="div"
                                        name="engineStroke"
                                        className="error-message err-msg-engineStroke"
                                      />
                                    </Grid>
                                  )}
                                </Grid>
                              </CardContent>
                            </Card>
                          </Col>

                          {!isKayakOrCanoe && boatTypeAlias !== boatType.jetSki && (
                            <>
                              <Col md={6}>
                                <Card>
                                  <CardContent className="dashboard-forms-field">
                                    <Grid container spacing={2}>
                                      <Grid xs={12} item>
                                        <h3 className="add-form-sec-title">
                                          Mechanical System
                                        </h3>
                                      </Grid>

                                      {!isKayakOrCanoe && (
                                        <Grid item sm={6}>
                                          <Field
                                            label="Water Marker"
                                            name="waterMarker"
                                            value={values.waterMarker}
                                            type="text"
                                            className="form-control"
                                            onChangeText={e => setFieldValue('waterMarker', e.target.value)}
                                            onBlur={e => setFieldValue('waterMarker', trimmedValue(e.target.value))}
                                          />
                                          <ErrorMessage
                                            component="div"
                                            name="waterMarker"
                                            className="error-message err-msg-waterMarker"
                                          />
                                        </Grid>
                                      )}

                                      {boatTypeAlias !== boatType && (
                                        <Grid item sm={6}>
                                          <Field
                                            label="Stabilizer System"
                                            name="stabilizerSystem"
                                            value={values.stabilizerSystem}
                                            required
                                            type="text"
                                            className="form-control"
                                            onChangeText={e => setFieldValue('stabilizerSystem', e.target.value)}
                                            onBlur={e => setFieldValue('stabilizerSystem', trimmedValue(e.target.value))}
                                          />
                                          <ErrorMessage
                                            component="div"
                                            name="stabilizerSystem"
                                            className="error-message err-msg-stabilizerSystem"
                                          />
                                        </Grid>
                                      )}

                                      {!isKayakOrCanoe && (
                                        <Grid item sm={6}>
                                          <Field
                                            label="Bow Thruster"
                                            name="bowThruster"
                                            value={values.bowThruster}
                                            type="text"
                                            className="form-control"
                                            onChangeText={e => setFieldValue('bowThruster', e.target.value)}
                                            onBlur={e => setFieldValue('bowThruster', trimmedValue(e.target.value))}
                                          />
                                          <ErrorMessage
                                            component="div"
                                            name="bowThruster"
                                            className="error-message err-msg-bowThruster"
                                          />
                                        </Grid>
                                      )}

                                      {!isKayakOrCanoe && (
                                        <Grid item sm={6}>
                                          <Field
                                            label="Oil/Water Separator"
                                            name="oilWaterSeparator"
                                            value={values.oilWaterSeparator}
                                            type="text"
                                            className="form-control"
                                            onChangeText={e => setFieldValue('oilWaterSeparator', e.target.value)}
                                            onBlur={e => setFieldValue('oilWaterSeparator', trimmedValue(e.target.value))}
                                          />
                                          <ErrorMessage
                                            component="div"
                                            name="oilWaterSeparator"
                                            className="error-message err-msg-oilWaterSeparator"
                                          />
                                        </Grid>
                                      )}

                                      {!isKayakOrCanoe && (
                                        <Grid item sm={6}>
                                          <Field
                                            label="Steering System"
                                            name="steeringSystem"
                                            value={values.steeringSystem}
                                            required
                                            type="text"
                                            className="form-control"
                                            onChangeText={e => setFieldValue('steeringSystem', e.target.value)}
                                            onBlur={e => setFieldValue('steeringSystem', trimmedValue(e.target.value))}
                                          />
                                          <ErrorMessage
                                            component="div"
                                            name="steeringSystem"
                                            className="error-message err-msg-steeringSystem"
                                          />
                                        </Grid>
                                      )}

                                      {!isKayakOrCanoe && (
                                        <Grid item sm={6}>
                                          <Field
                                            label="Fire/Bilge Pump"
                                            name="fireBilgePump"
                                            value={values.fireBilgePump}
                                            type="text"
                                            className="form-control"
                                            onChangeText={e => setFieldValue('fireBilgePump', e.target.value)}
                                            onBlur={e => setFieldValue('fireBilgePump', trimmedValue(e.target.value))}
                                          />
                                          <ErrorMessage
                                            component="div"
                                            name="fireBilgePump"
                                            className="error-message err-msg-fireBilgePump"
                                          />
                                        </Grid>
                                      )}
                                    </Grid>
                                  </CardContent>
                                </Card>
                              </Col>
                              <Col md={6}>
                                <Card>
                                  <CardContent className="dashboard-forms-field">
                                    <Grid container spacing={2}>
                                      <Grid xs={12} item>
                                        <h3 className="add-form-sec-title">
                                          Electrical System
                                        </h3>
                                      </Grid>

                                      {!isKayakOrCanoe && (
                                        <Grid item sm={6}>
                                          <Field
                                            label="Output"
                                            name="output"
                                            value={values.output}
                                            type="text"
                                            className="form-control"
                                            onChangeText={e => setFieldValue('output', e.target.value)}
                                            onBlur={e => setFieldValue('output', trimmedValue(e.target.value))}
                                          />
                                          <ErrorMessage
                                            component="div"
                                            name="output"
                                            className="error-message err-msg-output"
                                          />
                                        </Grid>
                                      )}

                                      {!isKayakOrCanoe && (
                                        <Grid item sm={6}>
                                          <Field
                                            label="No. Of Batteries"
                                            name="batteriesCount"
                                            value={values.batteriesCount}
                                            required
                                            type="number"
                                            className="form-control"
                                            onChangeText={e => setFieldValue('batteriesCount', e.target.value)}
                                          />
                                          <ErrorMessage
                                            component="div"
                                            name="batteriesCount"
                                            className="error-message err-msg-batteriesCount"
                                          />
                                        </Grid>
                                      )}

                                      {!isKayakOrCanoe && (
                                        <Grid item sm={6}>
                                          <Field
                                            label="Generators"
                                            name="generators"
                                            value={values.generators}
                                            type="text"
                                            className="form-control"
                                            onChangeText={e => setFieldValue('generators', e.target.value)}
                                            onBlur={e => setFieldValue('generators', trimmedValue(e.target.value))}
                                          />
                                          <ErrorMessage
                                            component="div"
                                            name="generators"
                                            className="error-message err-msg-generators"
                                          />
                                        </Grid>
                                      )}

                                      {!isKayakOrCanoe && (
                                        <Grid item sm={6}>
                                          <Field
                                            label="Type Of Batteries"
                                            name="batteryType"
                                            value={values.batteryType}
                                            required
                                            type="text"
                                            className="form-control"
                                            onChangeText={e => setFieldValue('batteryType', e.target.value)}
                                            onBlur={e => setFieldValue('batteryType', trimmedValue(e.target.value))}
                                          />
                                          <ErrorMessage
                                            component="div"
                                            name="batteryType"
                                            className="error-message err-msg-batteryType"
                                          />
                                        </Grid>
                                      )}

                                      {!isKayakOrCanoe && (
                                        <Grid item sm={6}>
                                          <Field
                                            label="Emergency Generator"
                                            name="emergencyGenerator"
                                            value={values.emergencyGenerator}
                                            type="text"
                                            className="form-control"
                                            onChangeText={e => setFieldValue('emergencyGenerator', e.target.value)}
                                            onBlur={e => setFieldValue('emergencyGenerator', trimmedValue(e.target.value))}
                                          />
                                          <ErrorMessage
                                            component="div"
                                            name="emergencyGenerator"
                                            className="error-message err-msg-emergencyGenerator"
                                          />
                                        </Grid>
                                      )}
                                    </Grid>
                                  </CardContent>
                                </Card>
                              </Col>
                            </>
                          )}

                          <Col md={6}>
                            <Card>
                              <CardContent className="dashboard-forms-field pb-3">
                                <Grid container spacing={2}>
                                  <Grid xs={12} item>
                                    <h3 className="add-form-sec-title">
                                      Dimensions
                                    </h3>
                                  </Grid>

                                  {!isKayakOrCanoe && boatTypeAlias !== boatType.jetSki && (
                                    <Grid item sm={6} className="pt-1 pb-1">
                                      <SingleSelect
                                        label="Decks"
                                        name="decks"
                                        placeholder=""
                                        selectedOption={values.decks}
                                        options={renderSelectOptions(numberOptions, 'value', 'id', false)}
                                        onChange={value => setFieldValue('decks', value)}
                                        required
                                      />
                                      <ErrorMessage component="div" name="decks" className="error-message err-msg-decks" />
                                    </Grid>
                                  )}

                                  {!isKayakOrCanoe && boatTypeAlias !== boatType.jetSki && (
                                    <Grid item sm={6} className="pt-1 pb-1">
                                      <Field
                                        name="displacement"
                                        label="Displacement"
                                        value={values.displacement}
                                        type="text"
                                        className="form-control"
                                        onChangeText={e => setFieldValue('displacement', e.target.value)}
                                        onBlur={e => setFieldValue('displacement', trimmedValue(e.target.value))}
                                      />
                                      <ErrorMessage
                                        component="div"
                                        name="displacement"
                                        className="error-message err-msg-displacement"
                                      />
                                    </Grid>
                                  )}

                                  <Grid item sm={6} className="pt-1 pb-1">
                                    <Field
                                      name="heightInFt"
                                      value={values.heightInFt}
                                      type="number"
                                      label="Height ft"
                                      required
                                      className="form-control"
                                      onChangeText={e => {
                                        setFieldValue('heightInFt', e.target.value)
                                      }}
                                    />
                                    <ErrorMessage
                                      component="div"
                                      name="heightInFt"
                                      className="error-message err-msg-heightInFt"
                                    />
                                  </Grid>

                                  <Grid item sm={6} className="pt-1 pb-1">
                                    <Field
                                      name="lengthInFt"
                                      value={values.lengthInFt}
                                      type="number"
                                      label="Length ft"
                                      required
                                      className="form-control"
                                      onChangeText={e => {
                                        setFieldValue('lengthInFt', e.target.value)
                                      }}
                                    />
                                    <ErrorMessage
                                      component="div"
                                      name="lengthInFt"
                                      className="error-message err-msg-lengthInFt"
                                    />
                                  </Grid>

                                  <Grid item sm={6} className="pt-1 pb-1">
                                    <Field
                                      name="widthInFt"
                                      value={values.widthInFt}
                                      type="number"
                                      required
                                      label="Width ft"
                                      className="form-control"
                                      onChangeText={e => {
                                        setFieldValue('widthInFt', e.target.value)
                                      }}
                                    />
                                    <ErrorMessage
                                      component="div"
                                      name="widthInFt"
                                      className="error-message err-msg-widthInFt"
                                    />
                                  </Grid>

                                  {!isKayakOrCanoe && boatTypeAlias !== boatType.jetSki && (
                                    <Grid item sm={6} className="pt-1 pb-1">
                                      <Field
                                        name="numberOfHeads"
                                        value={values.numberOfHeads}
                                        type="number"
                                        label="Number Of Heads"
                                        className="form-control"
                                        onChangeText={e => setFieldValue('numberOfHeads', parseInt(e.target.value) || '')}
                                      />
                                      <ErrorMessage
                                        component="div"
                                        name="numberOfHeads"
                                        className="error-message err-msg-numberOfHeads"
                                      />
                                    </Grid>
                                  )}

                                  <Grid item sm={6} className="pt-1 pb-1">
                                    <Field
                                      name="weightInKg"
                                      value={values.weightInKg}
                                      type="number"
                                      required
                                      label="Weight kg"
                                      className="form-control"
                                      onChangeText={e => {
                                        setFieldValue('weightInKg', e.target.value)
                                      }}
                                    />
                                    <ErrorMessage
                                      component="div"
                                      name="weightInKg"
                                      className="error-message err-msg-weightInKg"
                                    />
                                  </Grid>

                                  {!isKayakOrCanoe && boatTypeAlias !== boatType.jetSki && (
                                    <Grid item sm={6} className="pt-1 pb-1">
                                      <Field
                                        name="crewCabinCount"
                                        value={values.crewCabinCount}
                                        type="number"
                                        label="No. Of Crew Cabins"
                                        className="form-control"
                                        onChangeText={e => setFieldValue('crewCabinCount', parseInt(e.target.value) || '')}
                                      />
                                      <ErrorMessage
                                        component="div"
                                        name="crewCabinCount"
                                        className="error-message err-msg-crewCabinCount"
                                      />
                                    </Grid>
                                  )}

                                  {!isKayakOrCanoe && boatTypeAlias !== boatType.jetSki && (
                                    <Grid item sm={6} className="pt-1 pb-1">
                                      <Field
                                        name="beam"
                                        value={values.beam}
                                        required
                                        type="number"
                                        className="form-control"
                                        label="Beam ft"
                                        onChangeText={e => {
                                          setFieldValue('beam', e.target.value)
                                        }}
                                      />
                                      <ErrorMessage component="div" name="beam" className="error-message err-msg-beam" />
                                    </Grid>
                                  )}

                                  {!isKayakOrCanoe && boatTypeAlias !== boatType.jetSki && (
                                    <Grid item sm={6} className="pt-1 pb-1">
                                      <Field
                                        name="crewBerthCount"
                                        value={values.crewBerthCount}
                                        type="number"
                                        label="No. Of Crew Berths"
                                        className="form-control"
                                        onChangeText={e => setFieldValue('crewBerthCount', parseInt(e.target.value) || '')}
                                      />
                                      <ErrorMessage
                                        component="div"
                                        name="crewBerthCount"
                                        className="error-message err-msg-crewBerthCount"
                                      />
                                    </Grid>
                                  )}

                                  {!isKayakOrCanoe && boatTypeAlias !== boatType.jetSki && (
                                    <Grid item sm={6} className="pt-1 pb-1">
                                      <Field
                                        label="Draft ft"
                                        name="draft"
                                        required
                                        value={values.draft}
                                        type="number"
                                        className="form-control"
                                        onChangeText={e => {
                                          setFieldValue('draft', e.target.value)
                                        }}
                                      />
                                      <ErrorMessage component="div" name="draft" className="error-message err-msg-draft" />
                                    </Grid>
                                  )}

                                  {!isKayakOrCanoe && boatTypeAlias !== boatType.jetSki && (
                                    <Grid item sm={6} className="pt-1 pb-1">
                                      <Field
                                        label="No. Of Crew Heads"
                                        name="crewHeadsCount"
                                        value={values.crewHeadsCount}
                                        type="number"
                                        className="form-control"
                                        onChangeText={e => setFieldValue('crewHeadsCount', parseInt(e.target.value) || '')}
                                      />
                                      <ErrorMessage
                                        component="div"
                                        name="crewHeadsCount"
                                        className="error-message err-msg-crewHeadsCount"
                                      />
                                    </Grid>
                                  )}
                                  {!isKayakOrCanoe && boatTypeAlias !== boatType.jetSki && (
                                    <Grid item sm={6} className="pt-1 pb-1">
                                      <Field
                                        label="Main Saloon"
                                        name="mainSaloon"
                                        type="form-radio"
                                        bold
                                        required
                                        value={values.mainSaloon}
                                        className="form-control"
                                        options={mainSaloon}
                                        onChangeText={e => {
                                          setFieldValue('mainSaloon', e.target.value)
                                        }}
                                      />
                                      <ErrorMessage
                                        component="div"
                                        name="mainSaloon"
                                        className="error-message err-msg-mainSaloon"
                                      />
                                    </Grid>
                                  )}

                                  {!isKayakOrCanoe && boatTypeAlias !== boatType.jetSki && (
                                    <Grid item sm={6} className="main--saloon--convertible">
                                      <Field
                                        label="Main Saloon Convertible"
                                        name="mainSaloonConvertible"
                                        type="form-radio"
                                        bold
                                        required
                                        value={values.mainSaloonConvertible}
                                        className="form-control"
                                        options={mainSaloonConvertible}
                                        onChangeText={e => {
                                          setFieldValue('mainSaloonConvertible', e.target.value)
                                        }}
                                      />
                                      <ErrorMessage
                                        component="div"
                                        name="mainSaloonConvertible"
                                        className="error-message err-msg-mainSaloonConvertible"
                                      />
                                    </Grid>
                                  )}
                                </Grid>
                              </CardContent>
                            </Card>
                          </Col>
                          {!isKayakOrCanoe && boatTypeAlias !== boatType.jetSki && (
                            <Col md={6}>
                              <Card>
                                <CardContent className="dashboard-forms-field">
                                  <Grid item sm={12}>
                                    <MultiSelect
                                      helpText={helpTextEnum.multiSelect}
                                      label="Amenities"
                                      name="amenities"
                                      selectedOption={values.amenities}
                                      onChangeValue={item => setFieldValue('amenities', [...item])}
                                      options={amenitiesItems}
                                      required
                                    />
                                    <ErrorMessage
                                      component="div"
                                      name="amenities"
                                      className="error-message err-msg-amenities"
                                    />
                                  </Grid>
                                </CardContent>
                              </Card>

                              <Card>
                                <CardContent className="dashboard-forms-field">
                                  <Grid item sm={12}>
                                    <MultiSelect
                                      label="Accessories"
                                      name="accessories"
                                      helpText={helpTextEnum.multiSelect}
                                      selectedOption={values.accessories}
                                      onChangeValue={item => setFieldValue('accessories', [...item])}
                                      options={accessoriesItems}
                                      required
                                    />

                                    <ErrorMessage
                                      component="div"
                                      name="accessories"
                                      className="error-message err-msg-accessories"
                                    />
                                  </Grid>
                                </CardContent>
                              </Card>

                              <Card>
                                <CardContent className="dashboard-forms-field">
                                  <Grid item sm={12}>
                                    <MultiSelect
                                      helpText={helpTextEnum.multiSelect}
                                      label="Navigation Equipment"
                                      name="navigationEquipments"
                                      selectedOption={values.navigationEquipments}
                                      onChangeValue={item => setFieldValue('navigationEquipments', [...item])}
                                      options={navigationEq}
                                      required
                                    />

                                    <ErrorMessage
                                      component="div"
                                      name="navigationEquipments"
                                      className="error-message err-msg-navigationEquipments"
                                    />
                                  </Grid>
                                </CardContent>
                              </Card>
                            </Col>
                          )}

                          <Col md={6}>
                            <Card>
                              <CardContent className="dashboard-forms-field">
                                <Grid container spacing={2}>
                                  <Grid xs={12} item>
                                    <h3 className="add-form-sec-title">
                                      Commercial
                                    </h3>
                                  </Grid>

                                  <Grid item sm={6}>
                                    <Field
                                      label="Prices US$"
                                      helpText={helpTextEnum.priceInput}
                                      name="price"
                                      required={!isYachtType(values.boatType?.label)}
                                      value={values.price}
                                      type="text"
                                      bold
                                      className="form-control"
                                      onChangeText={e => {
                                        // console.log(e);
                                        // console.log(values);
                                        setFieldValue('price', e.target.value)
                                      }}
                                    />
                                    <ErrorMessage component="div" name="price" className="error-message err-msg-price" />
                                  </Grid>

                                  {!isKayakOrCanoe && boatTypeAlias !== boatType.jetSki && (
                                    <Grid item sm={6}>
                                      <Field
                                        label="Do you have Tax (VAT) on the Boat "
                                        name="isTaxEnabled"
                                        type="form-radio"
                                        bold
                                        radioNotRequired={isYachtType(values.boatType?.label)}
                                        value={values.isTaxEnabled}
                                        helpText={helpTextEnum.taxMessage}
                                        className="form-control"
                                        isNotLookup
                                        options={radioOptions}
                                        onChangeText={e => setFieldValue('isTaxEnabled', e.target.value)}
                                      />
                                      <ErrorMessage
                                        component="div"
                                        name="isTaxEnabled"
                                        className="error-message err-msg-isTaxEnabled"
                                      />
                                    </Grid>
                                  )}
                                  <Grid item sm={6}>
                                    <Field
                                      label="The Boat Was Used For"
                                      name="usage"
                                      required={!isKayakOrCanoe && !isYachtType(values.boatType?.label)}
                                      value={values.usage}
                                      type="textarea"
                                      className="form-control"
                                      onChangeText={e => {
                                        setFieldValue('usage', e.target.value)
                                      }}
                                      onBlur={e => setFieldValue('usage', trimmedValue(e.target.value))}
                                      // onKeyDown={maximumWordCount}
                                    />
                                    <ErrorMessage component="div" name="usage" className="error-message err-msg-usage" />
                                  </Grid>

                                  <Grid item sm={6}>
                                    <Field
                                      label="Why To Buy This Boat"
                                      name="boatReview"
                                      // helpText={helpTextEnum.boat.boatReview}
                                      required
                                      value={values.boatReview}
                                      type="textarea"
                                      className="form-control"
                                      onChangeText={e => {
                                        // console.log(e);
                                        // console.log(values);
                                        setFieldValue('boatReview', e.target.value)
                                      }}
                                      onBlur={e => setFieldValue('boatReview', trimmedValue(e.target.value))}
                                      // onKeyDown={maximumWordCount}
                                    />
                                    <ErrorMessage
                                      component="div"
                                      name="boatReview"
                                      className="error-message err-msg-boatReview"
                                    />
                                  </Grid>
                                  {!isKayakOrCanoe && (
                                    <Grid item sm={6}>
                                      <Field
                                        label="Describe The Damage If Any"
                                        name="accidentDescribe"
                                        value={values.accidentDescribe}
                                        type="textarea"
                                        className="form-control"
                                        required={values.accidentHistory === 'Yes' ? true : false}
                                        onChangeText={e => setFieldValue('accidentDescribe', e.target.value)}
                                        onBlur={e => setFieldValue('accidentDescribe', trimmedValue(e.target.value))}
                                        // onKeyDown={maximumWordCount}
                                      />
                                      <ErrorMessage
                                        component="div"
                                        name="accidentDescribe"
                                        className="error-message err-msg-accidentDescribe"
                                      />
                                    </Grid>
                                  )}
                                  <Grid item sm={6}>
                                    <Field
                                      label="Boat Description"
                                      name="description"
                                      value={values.description}
                                      type="textarea"
                                      className="form-control"
                                      required
                                      onChangeText={e => setFieldValue('description', e.target.value)}
                                      onBlur={e => setFieldValue('description', trimmedValue(e.target.value))}
                                      // onKeyDown={maximumWordCount}
                                    />
                                    <ErrorMessage
                                      component="div"
                                      name="description"
                                      className="error-message err-msg-description"
                                    />
                                  </Grid>
                                  {!isKayakOrCanoe && (
                                    <>
                                      <Grid item sm={6}>
                                        <label>Last Time Maintenance Was</label>
                                        <div className="last--time--maintenance">
                                          <Field
                                            type="select-date-time"
                                            name="lastMaintenance"
                                            isUpdate
                                            isTimeFormatFalse
                                            isValidDate={disableFutureDates}
                                            value={values.lastMaintenance}
                                            onChange={value => {
                                              setFieldValue('lastMaintenance', dateStringFormate(value))
                                            }}
                                          />
                                        </div>
                                        <ErrorMessage
                                          component="div"
                                          name="lastMaintenance"
                                          className="error-message err-msg-lastMaintenance"
                                        />
                                      </Grid>
                                      <Grid item sm={6}>
                                        <Field
                                          label="Any Accident History"
                                          name="accidentHistory"
                                          type="form-radio"
                                          bold
                                          required
                                          value={values.accidentHistory}
                                          className="form-control"
                                          isNotLookup
                                          options={radioOptions}
                                          onChangeText={e => setFieldValue('accidentHistory', e.target.value)}
                                        />
                                        <ErrorMessage
                                          component="div"
                                          name="accidentHistory"
                                          className="error-message err-msg-accidentHistory"
                                        />
                                      </Grid>
                                      <Grid item sm={6}>
                                        <FormControl className="w-100" component="fieldset">
                                          <Field
                                            label="Repair History If Any"
                                            name="repairHistory"
                                            value={values.repairHistory}
                                            type="text"
                                            className="form-control"
                                            onChangeText={e => setFieldValue('repairHistory', e.target.value)}
                                            onBlur={e => setFieldValue('repairHistory', trimmedValue(e.target.value))}
                                          />
                                          <ErrorMessage
                                            component="div"
                                            name="repairHistory"
                                            className="error-message err-msg-repairHistory"
                                          />
                                        </FormControl>
                                      </Grid>
                                    </>
                                  )}
                                </Grid>
                              </CardContent>
                            </Card>
                          </Col>
                          <Col md={6}>
                            <Card>
                              <CardContent className="dashboard-forms-field">
                                <Grid container spacing={2}>
                                  <Grid item xs={12}>
                                    <h3 className="add-form-sec-title">
                                      Other Information
                                    </h3>
                                  </Grid>
                                </Grid>
                                <Grid container>
                                  <Grid item sm={6}>
                                    <Field
                                      label="Upload Images"
                                      id="images"
                                      name="images"
                                      type="multi-upload-image"
                                      values={values.images}
                                      setFieldValue={setFieldValue}
                                      deleteIds={values.removeMedia}
                                      addMedia={values.addMedia}
                                      mediaName={MEDIA_NAME.BOATIMAGE}
                                      required
                                    />
                                    <ErrorMessage
                                      component="div"
                                      name="images"
                                      className="error-message err-msg-images text-center"
                                    />
                                  </Grid>
                                  <Grid item sm={6} className="overflow-hidden">
                                    <Field
                                      label="Boat Layout"
                                      id="layout"
                                      name="layout"
                                      type="multi-upload-image"
                                      values={values.layout}
                                      setFieldValue={setFieldValue}
                                      deleteIds={values.removeMedia}
                                      addMedia={values.addMedia}
                                      mediaName={MEDIA_NAME.BOATLAYOUT}
                                      required
                                    />
                                    <ErrorMessage
                                      component="div"
                                      name="layout"
                                      className="error-message err-msg-layout text-center"
                                    />
                                  </Grid>

                                  <Grid item xs={12}>
                                    <hr></hr>
                                  </Grid>
                                  <Grid item sm={12} className="mt-3 mb-3">
                                    <Field
                                      label="Insert one of the supported video links Youtube, Facebook, Daily Motion, Streamable, Google Videos, and Vimeo"
                                      name="video"
                                      value={values.video}
                                      type="text"
                                      className="form-control"
                                      onChangeText={e => setFieldValue('video', e.target.value)}
                                    />
                                    <ErrorMessage component="div" name="video" className="error-message err-msg-video" />
                                  </Grid>
                                  {!isKayakOrCanoe && sellerRole.aliasName !== userRoles.BOAT_MANUFACTURER && (
                                    <Grid item sm={12} className="mt-2 mb-3">
                                      <div className="sea--trial-form-checkbox">
                                        <Field
                                          label="I permit surveyor to conduct sea trial during survey"
                                          type="checkbox"
                                          value={values.seaTrial}
                                          onChangeText={value => setFieldValue('seaTrial', !values.seaTrial)}
                                          name="seaTrial"
                                        />
                                      </div>
                                    </Grid>
                                  )}
                                  <Grid item sm={12} className="mt-3 mb-3">
                                    <TagsInput
                                      value={values?.meta_tags}
                                      handleChange={value => {
                                        setFieldValue('meta_tags', value.join(','))
                                      }}
                                    />
                                  </Grid>
                                </Grid>
                              </CardContent>
                            </Card>
                          </Col>
                          {/* {currentUser?.role?.aliasName === userRoles.BOAT_MANUFACTURER && ( */}
                          <Col md={6}>
                            <div style={{ height: '100%', padding: '20px 0', display: 'flex', alignItems: 'flex-end' }}>
                              <AppDlBtnCard
                                logoUrl={getImgUrl(siteConfigurations?.adamseaConnectLogo)}
                                text={get(staticPages, 'data[0].pageContent', '')}
                              />
                            </div>
                          </Col>
                          {/* )} */}
                        </Row>

                        {agreementContent?.data && (
                          <div className="clearfix mt-3 checkbox--with--align-label">
                            <div className="custom-control custom-checkbox float-left mb-none">
                              <input
                                type="checkbox"
                                className="custom-control-input custom-control--agreement cursor-pointer"
                                id="agree"
                                checked={agree}
                                onClick={() => {
                                  this.onClickAgree()
                                }}
                              />
                              <label className="custom-control-label font-14 register-custom-control-label mb-0 cursor-pointer">
                                <span onClick={this.onClickAgree}>I have read and agree to</span>{' '}
                                <span onClick={this.termsHandler} className="darkBlue">
                                  {agreementContent.data.title}
                                </span>{' '}
                                <span onClick={this.onClickAgree}>
                                  and understand that this application is to add used boats with AdamSea.Com.
                                </span>
                                <CommonModal
                                  open={termsModal}
                                  close={this.termsHandler}
                                  title={agreementContent.data.title}
                                  className="agreement--service--modal--section"
                                >
                                  <div dangerouslySetInnerHTML={{ __html: agreementContent.data.pageContent }} />
                                </CommonModal>
                              </label>
                            </div>
                          </div>
                        )}
                        {isBoatCreateAndUpdateError && <ErrorComponent errors={errorMessage} />}
                        <div className="d-flex justify-content-center add--boat--form--btn--spacing">
                          <ButtonComponent
                            className="h-auto as--success--outline btn--padding btn--font mr-3"
                            disabled={isButtonLoader}
                            loader={isButtonLoader}
                            onClick={handleSubmit}
                          >
                            {!isUpdate ? 'Save' : 'Update'}
                          </ButtonComponent>

                          <ButtonComponent
                            className=" h-auto as--cancel--outline btn--padding btn--font"
                            onClick={() => cancelHandler(history)}
                          >
                            Cancel
                          </ButtonComponent>
                        </div>
                      </containerFluid>
                    </Form>
                  )}
                />
              </div>
            )}
          </div>
        </DashboardLayout>
      </Fragment>
    )
  }
}

const mapStateToProps = state => ({
  siteConfigurations: state.loginReducer.siteConfigurations,
  staticPages: state.pageInfoByTypeReducer.staticPages,
  success: state.boatReducer.success,
  boatLookUps: state.boatReducer.boatLookups,
  currentUser: state.loginReducer.currentUser,
  boat: state.boatReducer.boat,
  isBoatCreated: state.boatReducer.isBoatCreated,
  isBoatUpdated: state.boatReducer.isBoatUpdated,
  isBoatCreateAndUpdateError: state.boatReducer.isBoatCreateAndUpdateError,
  adminBoatTypes: state.boatReducer.adminBoatTypes,
  getBoatSuccess: state.boatReducer.getBoatSuccess,
  errorMessage: state.errorReducer.errorMessage,
  agreement: state.pageInfoByTypeReducer && state.pageInfoByTypeReducer.agreement,
})

const mapDispatchToProps = dispatch => ({
  getStaticPages: data => dispatch(getStaticPages(data)),
  clear_getStaticPages: () => dispatch(clear_getStaticPages()),
  getBoatLookUps: () => dispatch(getAllBoatLookUps()),
  saveBoat: data => dispatch(saveBoat(data)),
  updateBoat: data => dispatch(updateBoat(data)),
  getBoatById: data => dispatch(getBoatById(data)),
  getBoatTypeStart: data => dispatch(getBoatTypeStart(data)),
  clearErrorMessageShow: () => dispatch(clearErrorMessageShow()),
  clearAddBoatFlag: () => dispatch(clearAddBoatFlag()),
  clearUpdateBoatFlag: () => dispatch(clearUpdateBoatFlag()),
  getDocumentUrl: data => dispatch(getDocumentUrl(data)),
  getAgreement: data => dispatch(getAgreement(data)),
  clear_getAgreement: () => dispatch(clear_getAgreement()),
})

export default connect(mapStateToProps, mapDispatchToProps)(AddBoat)
