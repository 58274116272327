import React, { Component } from 'react'
import { connect } from 'react-redux'

import { getLocalInfo, getLocalStorageItem } from '../helpers/storageHelper'
import { getCurrentRedirection, checkRentUserType } from '../helpers/jsxHelper'
import { setDashboardUnauthorizedAccess, sendConfirmationLink } from '../redux/actions'
import { comLicenceAccessibleRole, userRoles, withoutDocumentRoutes } from '../util/enums/enums'
import { isEmpty } from 'lodash'

export default function (ComposedComponent) {
  class CheckRole extends Component {
    componentDidMount() {
      const isDocumentsVerified = this.checkDocumentStatus(this.props)
      if (isDocumentsVerified) {
        this.isRoleCheck(this.props)
      }
    }

    componentDidUpdate(nextProps) {
      const isDocumentsVerified = this.checkDocumentStatus(this.props)
      if (isDocumentsVerified) {
        this.isRoleCheck(this.props)
      }
    }

    checkDocumentStatus = props => {
      const {
        unauthorizedAccess,
        setDashboardUnauthorizedAccess,
        history: { location },
        currentUser,
      } = this.props
      const isArticleOrBoatShow = withoutDocumentRoutes.includes(location?.pathname)
      const userRole = getLocalInfo('role')
      const documentStatus = getLocalInfo('documentStatus')
      const provider = getLocalInfo('provider')

      const rentRole = userRole.aliasName === userRoles.RENT_AND_CHARTER
      const isRentOrganizer = checkRentUserType(userRole.aliasName, provider && provider.alias)
      const userForBothDocuments = comLicenceAccessibleRole.includes(userRole.aliasName)
      const isGovernmentIdVerified = documentStatus.governmentIdVerified
      const isCommercialLicenceVerified = documentStatus.commercialLicenceVerified

      const bothDocUploadMessage =
        'Please visit your profile page and upload the missing documents of government ID and commercial license'
      const docUploadMessage =
        'Please visit your profile page and upload the missing documents of Goverment ID or maybe Stripe account'
      const docVerificationMessage = 'We have received your documents, and they are currently under verification.'

      const docMessage =
        currentUser?.commercialLicence?.length && currentUser?.governmentId?.length
          ? docVerificationMessage
          : userForBothDocuments
          ? bothDocUploadMessage
          : docUploadMessage

      if (!documentStatus.emailVerified) {
        props.history && props.history.replace('/dashboard')
        return false
      } else if (!documentStatus.mobileVerified) {
        !unauthorizedAccess && setDashboardUnauthorizedAccess('Please verify your mobile number first')
        props.history && props.history.replace('/profile')
        return false
      // @imp: will uncomment this code to turn back restrictions
      // } else if (
      //   !isArticleOrBoatShow &&
      //   ((rentRole && isRentOrganizer && (!isGovernmentIdVerified || !isCommercialLicenceVerified)) ||
      //     !isGovernmentIdVerified ||
      //     (userForBothDocuments && !isCommercialLicenceVerified))
      // ) {
      //   !unauthorizedAccess && setDashboardUnauthorizedAccess(docMessage)
      //   props.history && props.history.replace('/dashboard')
      //   return false
      // } else if (!isArticleOrBoatShow && rentRole && !isRentOrganizer && !isGovernmentIdVerified) {
      //   !unauthorizedAccess && setDashboardUnauthorizedAccess(docMessage)
      //   props.history && props.history.replace('/dashboard')
      //   return false
      } /* else if (
        !isEmpty(currentUser) &&
        !currentUser.is_stripe_profile_completed &&
        ['/manage-boat-rents', '/manage-trip-details', '/manage-boats', '/add-boat'].includes(location.pathname)
      ) {
        setDashboardUnauthorizedAccess('Please visit your profile page and register your Stripe account')
        props.history && props.history.replace('/dashboard')
        return false
      } */ else {
        return true
      }
    }

    isRoleCheck = props => {
      const {
        history: { location },
      } = props
      const userRole = getLocalInfo('role')
      // @imp: will remove this true to turn back restrictions
      const userVerified = (true || getLocalStorageItem('isVerified'))
      const profileUpdated = getLocalStorageItem('isProfileCompleted')

      const isArticleOrBoatShow = withoutDocumentRoutes.includes(location?.pathname)
      const checkRole = getCurrentRedirection(
        location?.pathname,
        userRole.aliasName,
        userVerified,
        profileUpdated,
        isArticleOrBoatShow
      )

      if (!checkRole && !isArticleOrBoatShow) {
        props.history && props.history.goBack()
      }
      return
    }

    render() {
      const isAuth = getLocalStorageItem('isAuthenticated')
      const profileUpdated = getLocalStorageItem('isProfileCompleted')

      return <>{isAuth && profileUpdated && <ComposedComponent {...this.props} />}</>
    }
  }

  const mapStateToProps = state => ({
    unauthorizedAccess: state.dashboardReducer.unauthorizedAccess,
    currentUser: state.loginReducer.currentUser,
  })

  const mapDispatchToProps = dispatch => ({
    setDashboardUnauthorizedAccess: data => dispatch(setDashboardUnauthorizedAccess(data)),
    sendConfirmationLink: data => dispatch(sendConfirmationLink(data)),
  })

  return connect(mapStateToProps, mapDispatchToProps)(CheckRole)
}
