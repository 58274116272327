import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Col, Row, Container } from 'react-bootstrap'
import SaveAltIcon from '@material-ui/icons/SaveAlt'
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined'
import { Grid } from '@material-ui/core'

import PaymentOption from '../../../components/salesEngine/paymentOption'
import {
  paymentRequest,
  clearSalesEnginePaymentFlags,
  getDocumentLinks,
  getDocumentUrl,
  getPaymentReceipt,
  salesEngineAgents,
  getAuctionByBoatId,
} from '../../../redux/actions'
import { BoatPurchaseInformation, PaymentInformation, SellerInformation } from '../../../components'
import { checkAgreement, getAgreementContents } from '../../../redux/actions'
import { salesEngineAgreementType, paymentTypes } from '../../../util/enums/enums'
import { SuccessNotify } from '../../../helpers/notification'
import '../SalesEngine.scss'
import { paymentStatus } from '../SalesEngineHelper'
import { PaymentDetail } from '../../../components/salesEngine/PaymentDetail'
import { CommonModal } from '../../../components/modal/commonModal'
import { getAllPageInfoByType } from '../../../redux/actions/pageInfoByTypeAction'
import { boatPaymentTotalPrice } from '../../../helpers/jsxHelper'
import BoatInformation from '../../../components/salesEngine/BoatInformation'
import { getWithStripeFee } from '../../../util/utilFunctions'

class Payment extends Component {
  state = {
    isAgreed: false,
    agree: false,
    agreementModal: false,
  }

  static getDerivedStateFromProps(nextProps) {
    const { isAgreementChecked, clearSalesEnginePaymentFlags, salesEngine } = nextProps

    if (isAgreementChecked) {
      clearSalesEnginePaymentFlags()
      SuccessNotify('Agreement ' + (salesEngine?.buyerAgreement ? 'checked' : 'unchecked') + ' successfully!')
    }
    return null
  }

  componentDidMount() {
    const { getAgreementContents, salesEngine, getDocumentLinks, getAuctionByBoatId } = this.props
    getDocumentLinks({ id: salesEngine.id, type: 'Agreement' })

    getAgreementContents({
      id: salesEngine.id,
      type: salesEngineAgreementType.agreementContract,
    })
    salesEngine?.isAuctionSalesEngine && getAuctionByBoatId({ id: salesEngine.boat?.id, includeClosed: true })
  }

  checkAgreementHandler = () => {
    const { checkAgreement, salesEngine } = this.props
    const agreementData = { id: salesEngine.id, buyerId: salesEngine.buyer.id }
    salesEngine && !salesEngine.buyerAgreement && checkAgreement(agreementData)
  }

  termsHandler = () => {
    this.setState(prevState => ({
      agreementModal: !prevState.agreementModal,
    }))
  }

  renderBoatAndSellerInfo = () => {
    const { salesEngine, isSeller, history, isBuyer } = this.props

    return (
      <>
        <BoatInformation salesEngine={salesEngine} />
        <SellerInformation
          isBuyer={isBuyer}
          salesEngine={salesEngine}
          isSeller={isSeller}
          history={history}
          isPaymentDone={salesEngine.boatPayment}
        />
      </>
    )
  }

  render() {
    const {
      paymentRequest,
      agreementsContents,
      salesEngine,
      isSeller,
      isAgent,
      costEstimation,
      currentUser,
      history,
      getDocumentUrl,
      getAllPageInfoByType,
      getPaymentReceipt,
      checkAgreement,
      agreementLoading,
      paymentProcessing,
      isBuyer,
      boatAuctionRoom,
      payments,
    } = this.props

    const { paymentInformation } = salesEngine

    const depositPayment = boatAuctionRoom?.payment?.find(p => p.transactionType === 'bidDeposit')

    let priceData = {}
    priceData.quantity = salesEngine?.stockQuantity || 1
    priceData.unitBoatPrice = salesEngine?.negotiatedBoatPrice || salesEngine?.boat?.price
    priceData.totalBoatPrice = paymentInformation?.unitBoatPrice
    priceData.serviceFee = paymentInformation?.serviceFees
    priceData.depositPrice = depositPayment?.amount || 0
    priceData.depositFee = depositPayment?.stripeFee / 100 || 0
    priceData.subTotalPrice = priceData.totalBoatPrice + priceData.serviceFee - priceData.depositPrice + priceData.depositFee
    priceData.priceWithStripeFee = getWithStripeFee(+priceData.subTotalPrice)

    // let totalPrice = salesEngine?.paymentInformation?.totalPrice
    // if (boatAuctionRoom && boatAuctionRoom?.depositAmount) {
    //   totalPrice = totalPrice - boatAuctionRoom?.depositAmount
    // }

    let salesContent = this.props.salesEnginePages?.length ? this.props.salesEnginePages : [];
    salesContent = salesContent.find(c => c.titleSlug === 'agreement-of-purchase-and-sales-601')?.pageContent || '';

    return (
      <>
        {isBuyer && (
          <>
            {depositPayment && <PaymentDetail salesEngine={salesEngine} isDepositPayment paymentItem={depositPayment} />}

            {salesEngine.boat &&
              paymentStatus(salesEngine) &&
              payments?.length > 0 &&
              payments.map(item => (
                <PaymentDetail
                  salesEngine={salesEngine}
                  isBoatPaymentDetail
                  payment={boatAuctionRoom?.payment}
                  paymentItem={item}
                />
              ))}
          </>
        )}

        <div>
          <Container fluid className="pl-0 pr-0">
            <Grid container>
              {salesEngine.boat && !this.props.isPaymentDue && (
                <BoatPurchaseInformation
                  className={salesEngine?.boatPayment ? 'mb-4' : 'mb-0'}
                  salesEngine={salesEngine}
                  costEstimation={costEstimation}
                  depositPayment={depositPayment}
                  priceData={priceData}
                />
              )}

              {!salesEngine?.boatPayment && !this.props.isPaymentDue && (
                <Grid xs={12} item className="mt-3">
                  <PaymentOption
                    paymentTo={paymentTypes.boatPayment}
                    // price={boatPaymentTotalPrice(salesEngine)}
                    totalPayment={priceData.priceWithStripeFee}
                    paymentRequest={paymentRequest}
                    salesEngine={salesEngine}
                    priceData={priceData}
                    paymentDone={paymentStatus(salesEngine)}
                    currentUser={currentUser}
                    disable={salesEngine.buyerAgreement}
                    getAllPageInfoByType={getAllPageInfoByType}
                    getPaymentReceipt={getPaymentReceipt}
                    isSeller={isSeller}
                    isBuyer={isBuyer}
                    agreementsContents={salesContent}
                    getDocumentUrl={getDocumentUrl}
                    checkAgreement={checkAgreement}
                    agreementLoading={agreementLoading}
                    paymentProcessing={paymentProcessing}
                    auctionId={
                      boatAuctionRoom && (boatAuctionRoom?.id !== null || boatAuctionRoom?.id !== undefined)
                        ? boatAuctionRoom?.id
                        : null
                    }
                    isFromAuction={
                      boatAuctionRoom &&
                      (boatAuctionRoom?.depositAmount === null || boatAuctionRoom?.depositAmount === undefined)
                        ? false
                        : true
                    }
                  />
                </Grid>
              )}
              {salesEngine?.boatPayment && <PaymentInformation />}

              {/* <Grid item xs={12}>
                <div className="download--pay--step-btn">
                  <span
                    className="download--document--se--common cursor-pointer"
                    onClick={() => salesEngine?.agreementDocument && getDocumentUrl(salesEngine.agreementDocument.id)}
                  >
                    Download Agreement
                    <span className="download--document--icon">
                      <SaveAltIcon />
                    </span>
                  </span>
                </div>
              </Grid> */}
            </Grid>

            <Grid container spacing={2}>
              {this.renderBoatAndSellerInfo()}
            </Grid>
          </Container>
        </div>
      </>
    )
  }
}

const mapStateToProps = state => ({
  currentUser: state.loginReducer.currentUser,
  agreementsContents: state.salesEngineReducer.agreementsContents,
  isAgreementChecked: state.salesEngineReducer.isAgreementChecked,
  costEstimation: state.salesEngineReducer.costEstimation,
  agreementLoading: state.salesEngineReducer?.agreementLoading,
  paymentProcessing: state.salesEngineReducer?.paymentProcessing,
  boatAuctionRoom: state.boatReducer.boatAuctionRoom,
  payments: state.salesEngineReducer?.payments,
  salesEnginePages: state.pageInfoByTypeReducer?.salesEnginePages
})

const mapDispatchToProps = dispatch => ({
  paymentRequest: data => dispatch(paymentRequest(data)),
  getAgreementContents: data => dispatch(getAgreementContents(data)),
  checkAgreement: data => dispatch(checkAgreement(data)),
  clearSalesEnginePaymentFlags: data => dispatch(clearSalesEnginePaymentFlags(data)),
  getDocumentLinks: data => dispatch(getDocumentLinks(data)),
  getDocumentUrl: data => dispatch(getDocumentUrl(data)),
  getAllPageInfoByType: data => dispatch(getAllPageInfoByType(data)),
  getPaymentReceipt: data => getPaymentReceipt(data),
  getAuctionByBoatId: data => dispatch(getAuctionByBoatId(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Payment)
