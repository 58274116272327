import classNames from 'classnames';
import { uniqueId } from 'lodash';
import React, { useState } from 'react'
import { Tooltip } from 'react-tooltip'

/**
 * @param {{ component: React.ReactNode, className?: string } & import('react-tooltip').ITooltip} param0 
 * @returns 
 */
export const CommonTooltip = ({ content, component: Component, className = '', ...tooltipProps }) => {
  const [id] = useState(uniqueId('commonTooltip'));

  return (
    <>
      <Component id={id} />
      <Tooltip
        {...tooltipProps}
        id={id}
        className={classNames('common-tooltip', className)}
        place="bottom"
      >{content}</Tooltip>
    </>
  )
}
