import { useCallback, useState } from 'react'
import { enqueueSnackbar } from 'notistack'
import IconHeartSolid from '../../assets/icons/heart-solid'
import IconHeart from '../../assets/icons/heart'
import { likePost as qLikePost } from '../../lib/queries'
import { useAuth } from '../../contexts/auth-ctx'
import { useLoginModal } from '../../contexts/login-modal-ctx'
import { useSMDb } from '../../contexts/smdb-ctx'

export default function LikeBtn({ postId, ...props }) {
  const auth = useAuth()
  const loginModal = useLoginModal()
  const [smdbGet, , smdbMerge] = useSMDb()

  const [likeLoading, setLikeLoading] = useState(false)

  const likePost = useCallback(async () => {
    setLikeLoading(true)

    try {
      const res = await qLikePost(postId)
      smdbMerge(postId, res)
    } catch (er) {
      enqueueSnackbar(er)
    }

    setLikeLoading(false)
  }, [postId, smdbMerge])

  return (
    <button
      {...props}
      onClick={async e => {
        props.onClick?.(e)
        auth.isLoggedIn ? likePost(postId) : loginModal.show()
      }}
      disabled={likeLoading ?? props.disabled}
    >
      {props.children || (
        <>
          {smdbGet(postId, 'liked') ? <IconHeartSolid /> : <IconHeart />} {smdbGet(postId, 'likesCount')}
        </>
      )}
    </button>
  )
}
