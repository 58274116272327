import React, { Component } from 'react'
import { Col, Row, Spinner } from 'react-bootstrap'
import { Formik, ErrorMessage, Form } from 'formik'
import * as Yup from 'yup'
import SaveAltIcon from '@material-ui/icons/SaveAlt'
import { CardCvcElement, CardExpiryElement, CardNumberElement } from '@stripe/react-stripe-js'

import { selectPaymentOptions, footerLinks, paymentTypes } from '../../util/enums/enums'
import { Field } from '../ws/Field'
import ErrorFocus from '../ws/ErrorFocus'
import SingleSelect from '../helper/singleSelect'
import { CurrencyContextConsumer } from '../../CurrencyContext'
import { getConvertedPrice } from '../../helpers/currencyConverterHelper'
import { CommonSalesEnginTitle } from './CommonSalesEnginTitle'
import '../../containers/salesEngine/PaymentOption.scss'
import { stringValidation } from '../../helpers/yupHelper'
import { CommonModal } from '../modal/commonModal'
import { ButtonComponent } from '../form/Button'
import { PaymentInformation } from './PaymentInformation'
import { DownloadDocument } from './DownloadDocument'
import withStripe from '../../hoc/withStripe'
import { CARD_ELEMENT_OPTIONS } from '../../util/enums/stripeEnum'
import { requireMessage } from '../../helpers/string'
import { ErrorNotify } from '../../helpers/notification'
import { getWithStripeFee } from '../../util/utilFunctions'
import { get } from 'lodash'

class PaymentOption extends Component {
  state = {
    schema: {},
    agree: false,
    termsModal: false,
    agreementModal: false,
  }

  onClickAgree = () => {
    this.setState(prevState => ({
      agree: !prevState.agree,
    }))
  }

  agreementModalHandle = () => {
    this.setState(prevState => ({
      agreementModal: !prevState.agreementModal,
    }))
  }

  termsHandler = (title) => {
    const { getAllPageInfoByType } = this.props
    getAllPageInfoByType({ title, isLargeModal: true }, true)
    this.setState(prevState => ({
      termsModal: !prevState.termsModal,
    }))
  }

  checkAgreementHandler = checked => {
    const { checkAgreement, salesEngine } = this.props
    const agreementData = { id: salesEngine.id, buyerId: salesEngine.buyer.id, checked }
    checkAgreement(agreementData)
  }

  renderPaymentInfo = (setFieldValue, setFieldError, setFieldTouched) => {
    const { price, isSurveyorPaymentDetail, salesEngine, paymentTo, isSeller, totalPayment } = this.props

    const paymentTransferFee = isSurveyorPaymentDetail
      ? salesEngine?.paymentInformation?.surveyorPaymentTransferFee
      : salesEngine?.paymentInformation?.shipperPaymentTransferFee

    return (
      <>
        <Row className="m-0">
          <div className="payment--options--payment">
            <CurrencyContextConsumer>
              {({ currentCurrency }) => (
                <>
                  {price && paymentTo === paymentTypes.boatPayment && (
                    <p className="stepper-boat-info-final-price stepper--boat--final--price stepper--boat--final--price--spacing">
                      <span className="font--black-bold"> Total Amount:</span> {getConvertedPrice(price, currentCurrency)}
                    </p>
                  )}
                  {isSurveyorPaymentDetail && (
                    <>
                      <p className="stepper-boat-info-final-price stepper--boat--final--price stepper--boat--final--price--spacing">
                        <span className="font--black-bold"> Surveyor Cost (Per Feet) </span>
                        <span className="font-bold">{getConvertedPrice(isSurveyorPaymentDetail, currentCurrency)}</span>
                      </p>
                      <p className="stepper-boat-info-final-price stepper--boat--final--price stepper--boat--final--price--spacing">
                        <span className="font--black-bold">
                          Total Surveyor Cost (length of the boat * surveyor cost per feet):
                        </span>
                        <span className="font-bold">{getConvertedPrice(price, currentCurrency)}</span>
                      </p>
                    </>
                  )}
                  {paymentTo === paymentTypes.shipperPayment && (
                    <p className="stepper-boat-info-final-price stepper--boat--final--price stepper--boat--final--price--spacing">
                      <span className="font--black-bold"> Total Shipment:</span>
                      <span className="font-bold">{getConvertedPrice(price, currentCurrency)}</span>
                    </p>
                  )}
                  {paymentTo !== paymentTypes.boatPayment && (
                    <p className="stepper-boat-info-final-price stepper--boat--final--price stepper--boat--final--price--spacing">
                      <span className="font--black-bold">
                        Payment Transfer Fee
                        <div className="text-blue-mini">(If Card issued outside of Canada)</div>
                      </span>
                      <span className="font-bold">
                        {getConvertedPrice(totalPayment.feeNational, currentCurrency)}
                        <div className="text-blue-mini">
                          (Add {getConvertedPrice(totalPayment.feeInternational - totalPayment.feeNational, currentCurrency)})
                        </div>
                      </span>
                    </p>
                  )}
                  {totalPayment.totalNational && (
                    <p className="stepper-boat-info-final-price stepper--boat--final--price stepper--boat--final--price--spacing price-bigger">
                      <div className="font--black-bold" style={{ marginLeft: 'auto', marginRight: 50 }}>
                        Total Payment
                        <div className="text-blue-mini text-blue-mini-sm">(If Card issued outside of Canada)</div>
                      </div>
                      <div className="font-bold">
                        {getConvertedPrice(totalPayment.totalNational, currentCurrency)}
                        <div className="text-blue-mini text-blue-mini-sm">
                          (Add{' '}
                          {getConvertedPrice(totalPayment.totalInternational - totalPayment.totalNational, currentCurrency)})
                        </div>
                      </div>
                    </p>
                  )}
                </>
              )}
            </CurrencyContextConsumer>
          </div>
          <div className="payment--options--input">
            <div className="d-flex flex-column payment--option--field-with--input card--holder--input">
              <label className="mb-1 mr-2 font-weight-500 sales-engine-field-title">{'Card Number'}</label>
              <CardNumberElement
                options={{
                  showIcon: true,
                }}
                className="light-gray-bg form-control stripe--salesengine--payment--field"
                onBlur={value => this.handleChange(value, setFieldValue, setFieldError, setFieldTouched)}
                onChange={value => this.handleChange(value, setFieldValue, setFieldError, setFieldTouched)}
                {...CARD_ELEMENT_OPTIONS}
              />
              <ErrorMessage component="div" name="cardNumber" className="error-message" />
            </div>
            <div className="d-flex flex-column payment--option--field-with--input">
              <label className="mb-1 mr-2 font-weight-500 sales-engine-field-title">{'Expiration Date'}</label>
              <CardExpiryElement
                className="light-gray-bg form-control stripe--salesengine--payment--field"
                onBlur={value => this.handleChange(value, setFieldValue, setFieldError, setFieldTouched)}
                onChange={value => this.handleChange(value, setFieldValue, setFieldError, setFieldTouched)}
                {...CARD_ELEMENT_OPTIONS}
              />
              <ErrorMessage component="div" name="cardExpiry" className="error-message" />
            </div>
            <div className="d-flex flex-column payment--option--field-with--input">
              <label className="mb-1 mr-2 font-weight-500 sales-engine-field-title">{'CVC'}</label>
              <CardCvcElement
                className="light-gray-bg form-control cvc--payment--field stripe--salesengine--payment--field"
                onBlur={value => this.handleChange(value, setFieldValue, setFieldError, setFieldTouched)}
                onChange={value => this.handleChange(value, setFieldValue, setFieldError, setFieldTouched)}
                {...CARD_ELEMENT_OPTIONS}
              />
              <ErrorMessage component="div" name="cardCvc" className="error-message" />
            </div>
            <div className="d-flex flex-column payment--option--field-with--input card--holder--input">
              <label className="mb-1 mr-2 font-weight-500 sales-engine-field-title">{'Name on Card'}</label>
              <Field
                type="text"
                name="cardHolderName"
                onChangeText={e => setFieldValue('cardHolderName', e.target.value)}
                className="light-gray-bg stripe--salesengine--payment--field"
              />
              <ErrorMessage component="div" name="cardHolderName" className="error-message" />
            </div>
          </div>
        </Row>
      </>
    )
  }
  createMarkup = data => {
    return { __html: data }
  }

  handleChange = (value, setFieldValue, setFieldError, setFieldTouched) => {
    const { elementType, complete, empty, error } = value

    if (error) {
      setFieldError(elementType, error.message)
      setFieldTouched(elementType, true, false)
    } else if (complete) {
      setFieldError(elementType, '')
    }

    setFieldValue(elementType, !empty || '', false)
  }

  render() {
    const {
      paymentRequest,
      paymentTo,
      salesEngine,
      disable,
      inRent,
      currentUser,
      price,
      paymentDone,
      getPaymentReceipt,
      isSeller,
      agreementsContents,
      getDocumentUrl,
      totalPayment,
      agreementLoading,
      shipperDocument,
      stripe,
      paymentProcessing,
      isBuyer,
      isFromAuction,
      auctionId,
      priceData,
    } = this.props
    const { agreementModal } = this.state

    const isBoatPayment = paymentTo === paymentTypes.boatPayment
    const termRequiredValidation = !isBoatPayment
      ? {
          isAcceptTerm: Yup.string().required('Please accept Term of Service'),
        }
      : {}

    const isShipperPayment = paymentTo === paymentTypes.shipperPayment

    const isDownloadOptionsVisible = isShipperPayment ? salesEngine?.shipperPayment : false

    return (
      <>
        {!isDownloadOptionsVisible && (isBuyer || inRent) && (
          <Formik
            initialValues={{
              cardNumber: '',
              cardExpiry: '',
              cardCvc: '',
              cardHolderName: '',
              type: selectPaymentOptions[0],
              isAcceptTerm: '',
              checkIsAcceptTerm: (isBoatPayment && salesEngine?.buyerAgreement) || false,
            }}
            validationSchema={Yup.object().shape({
              type: Yup.string().required(requireMessage('Type')),
              cardNumber: stringValidation.required(requireMessage('card number')),
              cardExpiry: stringValidation.required(requireMessage('card expiry')),
              cardCvc: stringValidation.required(requireMessage('card cvc')),
              cardHolderName: stringValidation.required(requireMessage("Card holder's name")),
              checkIsAcceptTerm: Yup.boolean(),
              isAcceptTerm: Yup.string().when('checkIsAcceptTerm', {
                is: false,
                then: Yup.string().required('Please accept Term of Service').ensure(),
              }),
            })}
            onSubmit={async values => {
              if (!inRent) {
                values.type = paymentTo
                values.salesEngineId = salesEngine.id
              }
              const { isAcceptTerm, checkIsAcceptTerm, cardNumber, cardExpiry, cardCvc, ...newValue } = values

              const { stripe, elements } = this.props

              const { error, paymentMethod } = await stripe.createPaymentMethod({
                type: 'card',
                card: elements.getElement(CardNumberElement),
                billing_details: { name: newValue.cardHolderName },
              })

              if (error?.message) {
                ErrorNotify(error.message)
                return
              }

              if (!error) {
                let payData = {
                  ...newValue,
                  srcToken: paymentMethod.id,
                  amount:
                    get(paymentMethod, 'card.country', 'US') === 'CA'
                      ? totalPayment.totalNational
                      : totalPayment.totalInternational,
                }
                if (isFromAuction) {
                  payData.category_id = 'auction'
                  payData.sub_category_id = isBoatPayment ? 'boat_payment' : 'shipper_payment'
                  payData.auction_id = auctionId
                } else {
                  payData.category_id = 'sales_engine'
                  payData.sub_category_id =
                    paymentTo === paymentTypes.boatPayment
                      ? 'boat_payment'
                      : paymentTo === paymentTypes.shipperPayment
                      ? 'shipper_payment'
                      : paymentTo === paymentTypes.surveyorPayment
                      ? 'surveryor_payment'
                      : 'agent_payment'
                }

                if (isBoatPayment) {
                  payData.price = priceData.totalBoatPrice
                  payData.serviceFee = priceData.serviceFee
                }

                paymentRequest(payData)
              }
            }}
            render={({ values, setFieldValue, setFieldError, setFieldTouched, handleSubmit, errors }) => (
              <Form onSubmit={handleSubmit}>
                <ErrorFocus />
                <Row>
                  <Col xs={12}>
                    <div className="payment--details--main">
                      <CommonSalesEnginTitle title="Payment Details" />
                      <div className="payment--details--list">
                        <div className="d-flex  payment--option--details">
                          {/* <div className="pay--with">Pay With</div> */}
                          {/* <div className="pay--options">
                            <SingleSelect
                              selectedOption={values.type}
                              options={selectPaymentOptions}
                              placeholder={'Select Payment option'}
                              onChange={select => setFieldValue('type', select)}
                            />
                            <ErrorMessage component="div" name="type" className="error-message" />
                          </div> */}
                          <div className="payment--icon-card">
                            <img loading="lazy" src={require('../../assets/images/salesEngine/visa.png')} alt="Visa" />
                            <img loading="lazy" src={require('../../assets/images/salesEngine/american.png')} alt="American" />
                            <img loading="lazy" src={require('../../assets/images/salesEngine/mc.png')} alt="Mc" />
                            <img loading="lazy" src={require('../../assets/images/salesEngine/diner.png')} alt="Diner" height="30px" width="53px" />
                            <img loading="lazy" src={require('../../assets/images/salesEngine/jcb.png')} alt="Jcb" height="30px" width="53px" />
                            <img loading="lazy" src={require('../../assets/images/salesEngine/unionPay.png')} alt="Union Pay" />
                            <img
                              src={require('../../assets/images/salesEngine/discover.png')}
                              alt="Discover"
                              height="38px"
                              width="48px"
                            />
                          </div>
                          {paymentDone && (
                            <div>
                              <ButtonComponent
                                onClick={() =>
                                  getPaymentReceipt({ salesEngineId: salesEngine.id, paymentReceiptType: paymentTo })
                                }
                              >
                                Download Payment Receipt
                              </ButtonComponent>
                            </div>
                          )}
                        </div>
                        <div className="payment--details--content">
                          <div className="d-flex flex-column">
                            <div className="render--Payment--info">
                              {this.renderPaymentInfo(setFieldValue, setFieldError, setFieldTouched)}
                            </div>
                          </div>
                        </div>

                        <div className="d-flex  pay--confirmation">
                          {!isBoatPayment ? (
                            <div className="d-flex flex-column">
                              <div className="clearfix mt-3 checkbox--with--align-label">
                                <div className="custom-control custom-checkbox float-left mb-none">
                                  <input
                                    type="checkbox"
                                    className="custom-control-input custom-control--agreement cursor-pointer"
                                    id="agree"
                                    checked={values.checkIsAcceptTerm}
                                    onClick={() => {
                                      setFieldValue('checkIsAcceptTerm', !values.checkIsAcceptTerm)
                                      this.onClickAgree()
                                    }}
                                  />
                                  <label className="custom-control-label font-14 register-custom-control-label mb-0 d-flex cursor-pointer fix--align-checkbox">
                                    <span onClick={() => setFieldValue('isAcceptTerm', !values.isAcceptTerm)}>
                                      By continuing, you agree to AdamSea's{' '}
                                    </span>
                                    <a className="ml-1 light-sky-blue mr-1" onClick={() => {
                                      this.termsHandler(isShipperPayment ? 'agreement-for-shipper-payment-134' : 'agreement-for-surveyor-payment-534')
                                    }}>
                                      Conditions of use and purchase policy
                                    </a>
                                  </label>
                                </div>
                              </div>
                              <ErrorMessage component="div" name="isAcceptTerm" className="error-message" />
                            </div>
                          ) : (
                            <div className="clearfix mt-3">
                              <div className="custom-control custom-checkbox float-left mb-none">
                                <input
                                  type="checkbox"
                                  onClick={e => {
                                    const checked = e.target.checked
                                    setFieldValue('checkIsAcceptTerm', checked)
                                    this.checkAgreementHandler(checked)
                                  }}
                                  className="custom-control-input custom-control--agreement cursor-pointer"
                                  // id="agree"
                                  checked={isSeller ? salesEngine.sellerAgreement : salesEngine.buyerAgreement}
                                  disabled={paymentProcessing || agreementLoading}
                                />
                                <label className="custom-control-label font-14 register-custom-control-label mb-0 d-flex cursor-pointer fix--align-checkbox">
                                  I have read and agree to
                                  <a onClick={this.agreementModalHandle} className="ml-1 darkBlue mr-1">
                                    Agreement of purchase and Sales
                                  </a>
                                  <div>
                                    <CommonModal
                                      className="sales--engine-agreement-model"
                                      title="Agreement"
                                      open={agreementModal}
                                      close={this.agreementModalHandle}
                                    >
                                      <div
                                        className="agreement-detail"
                                        dangerouslySetInnerHTML={{
                                          __html: agreementsContents,
                                        }}
                                      />
                                      <div className="agreement-download-btn">
                                        <span className="stepper-submitted-report-info agreement-stepper-submitted-report-info">
                                          <span
                                            className="cursor-pointer"
                                            onClick={() =>
                                              salesEngine?.agreementDocument && getDocumentUrl(salesEngine.agreementDocument.id)
                                            }
                                          >
                                            Download Agreement <SaveAltIcon className="download-agreement section" />
                                          </span>
                                        </span>
                                      </div>
                                    </CommonModal>
                                  </div>
                                  and understand that this application is to buy boats with AdamSea.Com{' '}
                                </label>
                              </div>
                              <ErrorMessage component="div" name="isAcceptTerm" className="error-message" />
                            </div>
                          )}
                          <ButtonComponent
                            className="btn btn-outline-primary pay--confirmation--btn"
                            type="submit"
                            disabled={
                              !stripe ||
                              paymentProcessing ||
                              agreementLoading ||
                              (isBoatPayment ? salesEngine?.boatPayment : !disable)
                            }
                            loader={agreementLoading || paymentProcessing}
                          >
                            {'Confirm and Pay'}
                          </ButtonComponent>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Form>
            )}
          />
        )}
        {(isBuyer || isSeller) && shipperDocument?.id && (
          <DownloadDocument shipperDocument={shipperDocument} getDocumentUrl={getDocumentUrl} />
        )}

        {!isDownloadOptionsVisible && (isBuyer || inRent) && <PaymentInformation />}
      </>
    )
  }
}

PaymentOption.defaultProps = {
  paymentTo: '',
}

export default withStripe(PaymentOption)
