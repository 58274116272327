import React, { Component } from 'react'
import { connect } from 'react-redux'

import { searchBoatRent } from '../../../redux/actions'
import BoatListingsWithMap from '../../../components/gridComponents/BoatListingsWithMap'
import { urlParamsToObject } from '../../../helpers/routeHelper'
import { rentBoatTripForCard } from '../../../util/enums/enums'

class SearchRentBoats extends Component {
  state = {
    values: null,
  }

  componentDidMount() {
    const { location } = this.props
    const urlParams = new URLSearchParams(location.search)
    let values = urlParamsToObject(urlParams)
    values = { ...values, total: +values.total, page: values.page ? +values.page : 1 }
    this.setState({ values })
  }

  render() {
    const { values } = this.state
    const { searchedBoatRent, totalSearchedBoatRent, searchBoatRent, isPageLoader } = this.props

    return (
      <BoatListingsWithMap
        isPageLoader={isPageLoader}
        isRent
        boats={searchedBoatRent}
        boatsType={totalSearchedBoatRent ? `Find more ${rentBoatTripForCard[values && values.type]}` : 'No Boats Found'}
        boatsTypeCount={totalSearchedBoatRent || 0}
        action={searchBoatRent}
        value={values}
      />
    )
  }
}

const mapStateToProps = state => ({
  searchedBoatRent: state.rentReducer.searchedBoatRent,
  totalSearchedBoatRent: state.rentReducer.totalSearchedBoatRent,
  isPageLoader: state.rentReducer.isPageLoader,
})

const mapDispatchToProps = dispatch => ({
  searchBoatRent: data => dispatch(searchBoatRent(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(SearchRentBoats)
