import React from 'react'
import SaveAltIcon from '@material-ui/icons/SaveAlt'
import './Document.scss'
import { DownloadComponent } from './DownloadComponent'
import { documentName } from '../home'
import { CommonSalesEnginTitle } from './CommonSalesEnginTitle'

export const DownloadDocument = props => {
  const { shipperDocument, getDocumentUrl } = props
  return (
    <div className="download--document--section">
      <CommonSalesEnginTitle title="Download Document" />
      <div className="download--document--div">
        <div className="d-flex download--document">
          {documentName.map(item => {
            return shipperDocument[item.keyName]?.id ? (
              <DownloadComponent documentName={item.name} onCLick={() => getDocumentUrl(shipperDocument[item.keyName].id)} />
            ) : null
          })}
        </div>
      </div>
    </div>
  )
}
