import React, { Component, Fragment } from 'react'
import { Formik, ErrorMessage } from 'formik'
import { connect } from 'react-redux'
import * as Yup from 'yup'
import { Container, Card, Row, Col, Form, FormLabel } from 'react-bootstrap'
import { Button, Radio, FormControl, FormControlLabel, Select, MenuItem, Box, RadioGroup } from '@material-ui/core'
import 'react-dates/initialize'
import HelpIcon from '@material-ui/icons/Help'
import 'react-dates/lib/css/_datepicker.css'
import { lookupTypes } from '../../util/enums/enums'
import { DashboardLayout } from '../../components/layout/dashboardLayout'
import GoogleMap from '../../components/map/map'
import { Field, Loader } from '../../components'
import {
  createBoatRent,
  clearBoatRentFlag,
  getBoatRentLookUps,
  getAllBoatRentOfUser,
  getAllRentTypes,
  updateBoatRent,
  getRentInnerBoat,
  clearBoatRentData,
  getTypeWiseLookup,
} from '../../redux/actions/boatRentAction'
import { getImgUrl, renderOptions, renderSingleSelectOptions } from '../../util/utilFunctions'
import { randomAdId, renderSelectOptions, getIds, requireMessage } from '../../helpers/string'
import { cancelHandler } from '../../helpers/routeHelper'
import { renderMenuItems, getLatLngFromGeometric, getAddress, checkTripType } from '../../helpers/jsxHelper'
import { getListOfYears, latLng, AddressInputTypeEnum, helpTextEnum, MEDIA_NAME } from '../../util/enums/enums'
import MultiSelect from '../../components/helper/multiSelect'

import '../../styles/common.scss'
import './CreateRentBoat.scss'
import { SuccessNotify } from '../../helpers/notification'
import moment from 'moment'
import ErrorComponent from '../../components/error/errorComponent'
import ErrorFocus from '../../components/ws/ErrorFocus'
import SingleSelect from '../../components/helper/singleSelect'
import { ButtonComponent } from '../../components/form/Button'
import { getAddMedia } from '../../helpers/s3FileUpload'
import { stringValidation, urlValidation } from '../../helpers/yupHelper'
import TagsInput from '../dashboard/manageBoats/TagsInput'
import { clear_getAgreement, clear_getStaticPages, getAgreement, getStaticPages } from '../../redux/actions/pageInfoByTypeAction'
import { CommonModal } from '../../components/modal/commonModal'
import AppDlBtnCard from '../../components/appDlBtnCard'
import { get } from 'lodash'

class CreateRentBoat extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isSubmitting: false,
      checkLocationFlag: false,
      isRentPerHourTrip: !(props.location?.state?.tripType?.tripId?.length > 1),
      isCostOfDeposit: false,
      isEdit: false,
      isHours: false,
      tripInformation: {
        trip: '',
        tripType: '',
        captainName: '',
        address: [],
        tripAddress: [],
        gatheringPoint: [],
      },
      tripTypeName: [],
      boatInformation: {
        boatLength: '',
        bedroomAndCabins: '',
        maximumGuest: '',
        bathrooms: '',
        seatsOnBoat: '',
        model: '',
        manufacturer: '',
        yearOfManufacturer: '',
        engine: '',
      },
      deckEntertainment: {
        deckAndEntertainment: '',
        whatToBring: '',
        descriptionOfOurTrip: '',
        promotionOnSite: '',
        policy: '',
        youtubeLinkVideo: '',
        youtubeLinkVideo_1: '',
        youtubeLinkVideo_2: '',
        deposit: '',
        costOfDeposit: '',
        boatLayout: [],
        boatLength: '',
        images: [],
        addMedia: {
          boatLayout: [],
          images: [],
        },
        removeMedia: [],
      },
      openRangePicker: false,
      validTripTypes: [],
      latLng: {},
      tripLatLng: {},
      mediaUpdated: false,
      meta_tags: null,
      userRole: '',
      isAgreementModalOpen: false,
    }
  }

  componentWillUnmount() {
    const { clearBoatRentData } = this.props
    clearBoatRentData()
  }

  componentDidMount() {
    const {
      getBoatRentLookUps,
      clearBoatRentFlag,
      getAllRentTypes,
      getRentInnerBoat,
      getTypeWiseLookup,
      match: { params },
    } = this.props

    params.id && getRentInnerBoat({ id: params.id })
    getBoatRentLookUps()
    clearBoatRentFlag()
    getAllRentTypes({ isAdmin: true })
    getTypeWiseLookup(lookupTypes.TRIP)
    getTypeWiseLookup(lookupTypes.TRIP_TYPE)
    this.props.getStaticPages({ slug: "as-chat-956" });
  }

  componentDidUpdate() {
    if (this.props.staticPages?.success || this.props.staticPages?.failure) {
      this.props.clear_getStaticPages()
    }
  }

  checkLocation = () => {
    this.setState(prevState => ({
      checkLocationFlag: !prevState.checkLocationFlag,
    }))
  }

  static getDerivedStateFromProps(props, state) {
    const {
      history,
      createSuccess,
      createError,
      clearBoatRentFlag,
      updatedSuccess,
      currentUser,
      getAgreement,
      clear_getAgreement,
      agreement,
      match: { params },
    } = props

    const { isEdit } = state

    if (updatedSuccess) {
      setTimeout(() => SuccessNotify('The boat rental has been updated successfully.'), 100)
      history.push('/manage-boat-rents')
    }

    if (createSuccess) {
      setTimeout(() => SuccessNotify('The boat rental has been added successfully.'), 100)
      history.push('/manage-boat-rents')
    }

    if (createSuccess || createError || updatedSuccess) {
      clearBoatRentFlag()
      return { isSubmitting: false }
    }

    if (currentUser?.role?.aliasName && currentUser?.role?.aliasName !== state.userRole) {
      getAgreement({ role: currentUser?.role?.aliasName })

      return {
        ...state,
        userRole: currentUser?.role?.aliasName,
      }
    }

    if (agreement?.success || agreement?.failure) {
      clear_getAgreement()
      return state
    }

    if (params && params.id && !isEdit) {
      if (params.id) {
        return {
          boatId: params.id,
          isEdit: true,
        }
      }
    }

    return null
  }

  getTypeWiseValues = values => {
    const {
      captainName,
      boatLength,
      bedroomAndCabins,
      maximumGuest,
      bathrooms,
      seatsOnBoat,
      deckAndEntertainment,
      whatToBring,
      ...newData
    } = values
    return newData
  }

  renderRadioItems = (items, values) => {
    return items.map((item, index) => {
      return (
        <FormControlLabel
          key={item.id}
          label={item.alias}
          control={<Radio name={item.alias} value={item.id} disabled={values.verifiedFirstTime} />}
        />
      )
    })
  }

  renderOptions = options => {
    return options.map(item => {
      return <option key={item.lookUp.alias}>{item.lookUp.alias}</option>
    })
  }

  menuItems(values) {
    if (values) {
      return values.map(name => (
        <MenuItem value={name.lookUp.id} key={name.lookUp.id}>
          {name.lookUp.alias}
        </MenuItem>
      ))
    }
  }

  getSubmitValues = data => {
    const { adStatus, boatRentStatus, ...values } = data
    return values
  }

  findByTripType = tripType => {
    const { allBoatRentTypes } = this.props
    let isRentPerHour = false
    const selectedType = allBoatRentTypes.find(item => item.id === tripType.value)
    selectedType.tripId.map(item => {
      if (item.alias === 'Rent Per Hour') {
        isRentPerHour = true
      }
      return isRentPerHour
    })
    selectedType && this.setState({ availableTrip: selectedType.tripId, isRentPerHourTrip: isRentPerHour })
  }

  getYearListing = () => {
    const listOfYears = getListOfYears()

    return (
      listOfYears &&
      listOfYears.map(year => {
        return {
          label: year,
          value: year,
        }
      })
    )
  }
  editData = values => {
    const {
      location,
      tripLocation,
      latLng,
      tripLatLng,
      rating,
      user,
      __typename,
      pageVisits,
      likes,
      reviews,
      createdAt,
      startDate,
      ...newValues
    } = values
    return newValues
  }

  findTripTypeByTrip = trip => {
    const { tripTypeLookups } = this.props
    const validTripTypes = tripTypeLookups
      .filter(item => item.tripId?.some(t => t.id === trip))
      .map(i => ({ label: i.alias, value: i.id }))
    validTripTypes && this.setState({ validTripTypes: validTripTypes })
  }

  prepareEditRentValue = () => {
    const {
      editRentBoat,
      tripTypeLookups,
      match: { params },
    } = this.props
    const { mediaUpdated, tripInformation, boatInformation, deckEntertainment } = this.state

    delete editRentBoat.timezoneName

    if (editRentBoat && editRentBoat.id && params.id) {
      const {
        deckAndEntertainment,
        trip,
        tripType,
        deposit,
        address,
        yearOfManufacturer,
        maximumGuest,
        seatsOnBoat,
        bathrooms,
        bedroomAndCabins,
        costOfDeposit,
        meta_tags,
      } = editRentBoat

      if (trip && tripTypeLookups.length && !this.state.validTripTypes.length) {
        this.findTripTypeByTrip(trip.id)
      }

      if (!mediaUpdated) {
        const { commonAddress, tripAddress, gatheringPoint } = getAddress(address)

        this.setState({
          latLng: getLatLngFromGeometric(commonAddress.geometricLocation),
          tripLatLng: getLatLngFromGeometric(tripAddress.geometricLocation),
          gatheringPontLatLng: getLatLngFromGeometric(gatheringPoint.geometricLocation),
          mediaUpdated: true,
          isCostOfDeposit: deposit?.alias.toLowerCase() === 'required deposit',
        })

        return {
          ...editRentBoat,
          address: [commonAddress],
          tripAddress: [tripAddress],
          gatheringPoint: [gatheringPoint],
          deckAndEntertainment: renderSelectOptions(deckAndEntertainment, 'alias', 'id'),
          trip: trip.id,
          tripType: tripType ? { label: tripType.alias, value: tripType.id } : '',
          deposit: deposit ? { label: deposit.alias, value: deposit.id } : '',
          yearOfManufacturer: { label: yearOfManufacturer, value: yearOfManufacturer },
          maximumGuest: { label: maximumGuest, value: maximumGuest },
          seatsOnBoat: { label: seatsOnBoat, value: seatsOnBoat },
          bathrooms: { label: bathrooms, value: bathrooms },
          bedroomAndCabins: { label: bedroomAndCabins, value: bedroomAndCabins },
          addMedia: deckEntertainment.addMedia,
          removeMedia: [],
          costOfDeposit: costOfDeposit || '',
          agreement: false,
        }
      }
    } else {
      return {
        ...tripInformation,
        ...boatInformation,
        ...deckEntertainment,
        checkLocationFlag: false,
        meta_tags: null,
        agreement: false,
      }
    }
  }

  toggleAgreementModal = () => {
    this.setState(prevState => ({
      isAgreementModalOpen: !prevState.isAgreementModalOpen,
    }))
  }

  render() {
    const { isRentPerHourTrip, isEdit, tripLatLng, latLng, gatheringPontLatLng, validTripTypes } = this.state

    const {
      createBoatRent,
      editRentBoat,
      boatRentLookUps,
      history,
      updateBoatRent,
      tripLookups,
      tripTypeLookups,
      agreement: agreementContent,
    } = this.props

    let { allBoatRentTypes } = this.props

    allBoatRentTypes =
      allBoatRentTypes &&
      allBoatRentTypes.length > 0 &&
      allBoatRentTypes.map(item => {
        return {
          label: item.name,
          value: item.id,
          tripIds: item.tripId.map(trip => trip.id),
        }
      })

    const decksAndEntertainment =
      boatRentLookUps && renderSelectOptions(boatRentLookUps.decksAndEnvironment, 'alias', 'id', true)
    const tripsDeposit = boatRentLookUps && renderSelectOptions(boatRentLookUps.tripDeposit, 'alias', 'id', true)

    const privateAndSharedCommon = isRentPerHourTrip
      ? {
          boatLength: Yup.number().required('Boat Length field is required.').min(1, 'Length value should be positive number'),
          maximumGuest: Yup.string().required('Maximum Guest field is required.'),
        }
      : {
          boatLength: Yup.number().required('Boat Length field is required.').min(1, 'Length value should be positive number'),
          bedroomAndCabins: Yup.string().required('Bedroom & Cabins field is required.'),
          deckAndEntertainment: Yup.string().required('Decks & Entertainment field is required.'),
          maximumGuest: Yup.string().required('Maximum Guest field is required.'),
          seatsOnBoat: Yup.string().required('Seats on Boat field is required.'),
          whatToBring: stringValidation.required('What to Bring field is required.'),
        }

    const initValue = this.prepareEditRentValue()

    return (
      <Fragment>
        <DashboardLayout className="rent-create-layout">
          {isEdit && editRentBoat && !editRentBoat.hasOwnProperty('id') ? (
            <Loader isPageLoader />
          ) : (
            <Container fluid={true}>
              <Formik
                initialValues={{ ...initValue }}
                validationSchema={Yup.object().shape({
                  address: Yup.array(Yup.object({ city: Yup.string() }))
                    .compact(v => !v.city || !v.state || !v.country)
                    .required(requireMessage('Address'))
                    .min(1, requireMessage('Address'))
                    .ensure(),
                  captainName: isRentPerHourTrip ? undefined : Yup.string().required('Captain Name is required'),
                  youtubeLinkVideo: urlValidation.required('Youtube link is required.'),
                  checkLocationFlag: Yup.boolean(),
                  gatheringPoint: Yup.array(Yup.object({ city: Yup.string() }))
                    .compact(v => !v.city || !v.state || !v.country)
                    .when('checkLocationFlag', {
                      is: false,
                      then: Yup.array().min(1, requireMessage('Gathering Point')).ensure(),
                    }),
                  tripAddress: Yup.array(Yup.object({ city: Yup.string() }))
                    .compact(v => !v.city || !v.state || !v.country)
                    .required(requireMessage('Trip Address')),
                  // .when('checkLocationFlag', {
                  //   is: false,
                  //   then: Yup.array().min(1, requireMessage('Trip Address')).ensure(),
                  // }),
                  descriptionOfOurTrip: stringValidation.required('Description Of Our Trip field is required.'),
                  engine: isRentPerHourTrip ? null : stringValidation.required('Engine field is required.'),
                  images: Yup.array().min(5, 'Minimum 5 trip picture upload required.'),
                  model: stringValidation.required('Model field is required.'),
                  manufacturer: isRentPerHourTrip ? null : stringValidation.required('Manufacturer field is required.'),
                  policy: stringValidation.required('Policy field is required.'),
                  trip: Yup.string().required('Trip field is required.'),
                  tripType: Yup.string().required('Trip Type field is required.'),
                  yearOfManufacturer: isRentPerHourTrip
                    ? null
                    : Yup.string().required('Year of manufacturing field is required.'),
                  deposit: boatRentLookUps.tripDeposit ? Yup.string().required('Deposit field is required.') : null,
                  costOfDeposit: this.state.isCostOfDeposit
                    ? Yup.string().required('Required Deposit field is required.')
                    : null,
                  ...privateAndSharedCommon,
                  agreement: Yup.boolean().oneOf([true], 'An agreement is required.'),
                })}
                onSubmit={values => {
                  this.setState({ isSubmitting: true })

                  const { allBoatRentTypes } = this.props
                  if (values.checkLocationFlag) {
                    values.gatheringPoint = values.address
                  }

                  let addressToUpdate = []
                  if (values.address.length & values.tripAddress.length) {
                    const [address] = values.address
                    const [tripAddress] = values.tripAddress
                    const [gatheringPoint] = values.gatheringPoint

                    addressToUpdate = [
                      { ...address, type: AddressInputTypeEnum.COMMON_ADDRESS },
                      {
                        ...tripAddress,
                        type: AddressInputTypeEnum.TRIP_ADDRESS,
                      },
                      {
                        ...gatheringPoint,
                        type: AddressInputTypeEnum.GATHERING_POINT,
                      },
                    ]
                  }

                  const {
                    tripAddress,
                    gatheringPoint,
                    checkLocationFlag,
                    total,
                    endDate,
                    endTime,
                    price,
                    images,
                    boatLayout,
                    ...dataObject
                  } = values

                  const newValue = isRentPerHourTrip ? this.getTypeWiseValues(dataObject) : dataObject
                  const newSubmitValue = isEdit ? this.getSubmitValues(newValue) : newValue
                  const rentPerHourType = checkTripType(newSubmitValue.tripType.value, allBoatRentTypes)

                  let input = {
                    ...newSubmitValue,
                    deposit: values.deposit ? values.deposit.value : null,
                    costOfDeposit: isNaN(+values.costOfDeposit) ? null : +values.costOfDeposit,
                    boatLength: rentPerHourType ? null : values.boatLength,
                    bedroomAndCabins: rentPerHourType ? null : values.bedroomAndCabins.value,
                    checkLocationFlag,
                    deckAndEntertainment: rentPerHourType ? null : getIds(values.deckAndEntertainment),
                    maximumGuest: rentPerHourType ? null : values.maximumGuest.value,
                    seatsOnBoat: rentPerHourType ? null : values.seatsOnBoat.value,
                    bathrooms: rentPerHourType ? null : values.bathrooms.value,
                    whatToBring: rentPerHourType ? null : values.whatToBring,
                    trip: values.trip,
                    tripType: values.tripType ? values.tripType.value : null,
                    yearOfManufacturer: String(values.yearOfManufacturer.value),
                    address: addressToUpdate,
                    addMedia: getAddMedia(values.addMedia),
                  }

                  delete input.tripDetails
                  delete input.bookings
                  delete input.images
                  delete input.boatLayout
                  delete input.startTime
                  delete input.endTime
                  delete input.tripDuration
                  delete input.agreement

                  isEdit ? updateBoatRent(this.editData(input)) : createBoatRent(input)
                }}
                render={({ values, setFieldValue, handleSubmit, errors }) => (
                  <Form>
                    <ErrorFocus />
                    <div className="pb-3">
                      <div className="pl-2 pt-3 pb-3 map-div map-title-bg">
                        <Box fontSize={20} letterSpacing={1} fontWeight={600} className="map-title">
                          {`Add Rent Boat`}{' '}
                          {values?.adId && <span className="font-weight-400 font-14">Ad ID: {`${values.adId}`}</span>}
                        </Box>
                      </div>
                      <Row className="addRentBoat-field m-0">
                        <Col lg={6} className="pl-0" style={{ display: 'flex', flexDirection: 'column' }}>
                          <Row className="rent--card--spacing">
                            <Col>
                              <GoogleMap
                                className="map-rent"
                                fetch={result => setFieldValue('address', [result])}
                                height={35}
                                isFullGrid
                                width={100}
                                latLng={latLng}
                                isUpdate={isEdit}
                                id="destination-input"
                                placeHolder={'Location'}
                                columnName={'address'}
                                isError={errors.address}
                              ></GoogleMap>
                            </Col>
                          </Row>
                          <Row className="rent--card--spacing">
                            <Col>
                              <GoogleMap
                                className="map-rent"
                                fetch={result => setFieldValue('tripAddress', [result])}
                                id="pac-input"
                                height={35}
                                isFullGrid
                                latLng={tripLatLng}
                                isUpdate={isEdit}
                                width={100}
                                placeHolder={'Trip Destination'}
                                columnName={'tripAddress'}
                                isError={errors.tripAddress}
                              ></GoogleMap>
                            </Col>
                          </Row>
                          {!values.checkLocationFlag && (
                            <Row className="rent--card--spacing">
                              <Col>
                                <GoogleMap
                                  className="map-rent"
                                  fetch={result =>
                                    setFieldValue('gatheringPoint', [{ ...result, type: AddressInputTypeEnum.COMMON_ADDRESS }])
                                  }
                                  id="pac-input"
                                  height={35}
                                  isFullGrid
                                  latLng={gatheringPontLatLng}
                                  isUpdate={isEdit}
                                  width={100}
                                  placeHolder={'Gathering Point'}
                                  columnName={'gatheringPoint'}
                                  isError={errors.gatheringPoint}
                                ></GoogleMap>
                              </Col>
                            </Row>
                          )}
                          <Row>
                            <Col>
                              <div className="clearfix">
                                <div className="custom-control custom-checkbox  mb-none custom--radio--btn">
                                  <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    id="agree"
                                    checked={values.checkLocationFlag}
                                    onClick={() => setFieldValue('checkLocationFlag', !values.checkLocationFlag)}
                                  />
                                  <label
                                    className="custom-control-label font-14 custom--radio--btn"
                                    onClick={() => setFieldValue('checkLocationFlag', !values.checkLocationFlag)}
                                  >
                                    <span className="rent--arrangement">
                                      Check if trip location and gathering point are the same
                                    </span>
                                  </label>
                                </div>
                              </div>
                            </Col>
                          </Row>

                          <div style={{ flex: 1, margin: '20px 0', display: 'flex', alignItems: 'flex-end' }}>
                            <AppDlBtnCard
                              logoUrl={getImgUrl(this.props.siteConfigurations?.adamseaConnectLogo)}
                              text={get(this.props.staticPages, 'data[0].pageContent', '')}
                            />
                          </div>
                        </Col>
                        <Col lg={6} className="pl-0 pr-0">
                          <Card
                            className="
                            p-10 mt-0  rent--card--spacing rent--card--spacing--first"
                          >
                            <Row className="m-0">
                              <Col xs={12} className="pl-0 pr-0">
                                <div className="ml-0 card-title h5">Trip Information</div>
                              </Col>
                            </Row>

                            {!!tripLookups.length && (
                              <Row>
                                <Col>
                                  <div>
                                    <div className="trip-kind">
                                      <FormControl component="fieldset">
                                        <FormLabel component="legend" className="mb-0 addMarinaStorage-info required">
                                          Trip
                                        </FormLabel>

                                        <RadioGroup
                                          row
                                          aria-label="gender"
                                          className="radioButton"
                                          defaultValue={values.trip}
                                          onChange={e => {
                                            setFieldValue('trip', e.target.value)
                                            this.setState({ isRentPerHourTrip: e.target.name === 'Rent Per Hour' })
                                            this.findTripTypeByTrip(e.target.value)
                                          }}
                                        >
                                          {this.renderRadioItems(tripLookups, values)}
                                        </RadioGroup>
                                      </FormControl>
                                    </div>

                                    <ErrorMessage component="div" name="trip" className="error-message err-msg-trip" />
                                  </div>
                                </Col>
                              </Row>
                            )}

                            <Row className="m-0">
                              <Col xs={6} className="z-index-99999 pl-0">
                                <div className="mb-2">
                                  <SingleSelect
                                    iconTextClasses=""
                                    helpText={helpTextEnum.rent.tripType}
                                    label="Trip Type"
                                    required
                                    name="tripType"
                                    placeholder="Select Trip Type"
                                    selectedOption={values.tripType}
                                    options={validTripTypes}
                                    onChange={value => setFieldValue('tripType', value)}
                                  />

                                  <ErrorMessage component="div" name="tripType" className="error-message err-msg-tripType" />
                                </div>
                              </Col>

                              <Col xs={6} className="pl-0">
                                {isRentPerHourTrip || (
                                  <div className="mb-2">
                                    <Field
                                      label="Captain Name"
                                      id={'captainName'}
                                      required
                                      name={'captainName'}
                                      value={values.captainName}
                                      type="text"
                                      helpText="Trip requires boat owner or captain to accompany it."
                                      onChangeText={e => {
                                        setFieldValue('captainName', e.target.value)
                                      }}
                                    />
                                    <ErrorMessage
                                      component="div"
                                      name="captainName"
                                      className="error-message err-msg-captainName"
                                    />
                                  </div>
                                )}
                              </Col>
                              <Col></Col>
                            </Row>
                          </Card>

                          <Card className="p-10 rent--card--spacing">
                            <Row className="m-0">
                              <Col xs={12} className="pl-0 pr-0">
                                <div className="ml-0 card-title h5">Other Info</div>
                              </Col>
                            </Row>
                            <Row className="m-0">
                              <Col xs={6} className="pl-0">
                                <div className="mb-2">
                                  <Field
                                    label="Boat Model"
                                    id="model"
                                    name="model"
                                    required
                                    type="text"
                                    value={values.model}
                                    onChangeText={e => setFieldValue('model', e.target.value)}
                                  ></Field>

                                  <ErrorMessage component="div" name="model" className="error-message err-msg-model" />
                                </div>
                              </Col>
                              <Col xs={6} className="pr-0">
                                <div className="mb-2">
                                  <Field
                                    label="Manufacturer"
                                    id="manufacturer"
                                    name="manufacturer"
                                    required={!isRentPerHourTrip}
                                    type="text"
                                    value={values.manufacturer}
                                    onChangeText={e => setFieldValue('manufacturer', e.target.value)}
                                  ></Field>

                                  <ErrorMessage
                                    component="div"
                                    name="manufacturer"
                                    className="error-message err-msg-manufacturer"
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row className="m-0">
                              <Col xs={6} className="z-index-9999 pl-0">
                                <div className="mb-2">
                                  <SingleSelect
                                    label="Year of manufacturing"
                                    required={!isRentPerHourTrip}
                                    name="yearOfManufacturer"
                                    placeholder="Select year"
                                    selectedOption={values.yearOfManufacturer}
                                    options={this.getYearListing()}
                                    onChange={value => {
                                      setFieldValue('yearOfManufacturer', value)
                                    }}
                                  />
                                  <ErrorMessage
                                    component="div"
                                    name="yearOfManufacturer"
                                    className="error-message err-msg-yearOfManufacturer"
                                  />
                                </div>
                              </Col>
                              <Col xs={6} className="pr-0">
                                <div className="mb-2">
                                  <Field
                                    label="Engine"
                                    id="engine"
                                    name="engine"
                                    required={!isRentPerHourTrip}
                                    type="text"
                                    value={values.engine}
                                    onChangeText={e => setFieldValue('engine', e.target.value)}
                                  ></Field>

                                  <ErrorMessage component="div" name="engine" className="error-message err-msg-engine" />
                                </div>
                              </Col>
                            </Row>
                          </Card>

                          {
                            <Card className="p-10 rent--card--spacing">
                              <Row className="m-0">
                                <Col xs={12} className="pl-0 pr-0">
                                  <div className="ml-0 card-title h5">Boat Information</div>
                                </Col>
                              </Row>
                              <Row className="m-0">
                                <Col xs={6} className="pl-0">
                                  <div className="mb-2">
                                    <label className="required">Boat Length (ft)</label>
                                    <Field
                                      label=""
                                      id={'boatLength'}
                                      name={'boatLength'}
                                      value={values.boatLength}
                                      required
                                      numberClassName="height-boat-length-ft"
                                      type="number"
                                      onChangeText={e => {
                                        setFieldValue('boatLength', +e.target.value || '')
                                      }}
                                    />

                                    <ErrorMessage
                                      component="div"
                                      name="boatLength"
                                      className="error-message err-msg-boatLength"
                                    />
                                  </div>
                                </Col>

                                {isRentPerHourTrip || (
                                  <Col xs={6} className="z-index-999 pr-0">
                                    <div className="mb-2">
                                      <SingleSelect
                                        label="Bedrooms & Cabins"
                                        required
                                        name="bedroomAndCabins"
                                        placeholder="Select Bedrooms & Cabins"
                                        selectedOption={values.bedroomAndCabins}
                                        options={renderSingleSelectOptions(50)}
                                        onChange={value => {
                                          setFieldValue('bedroomAndCabins', value)
                                        }}
                                      />
                                      <ErrorMessage
                                        component="div"
                                        name="bedroomAndCabins"
                                        className="error-message err-msg-bedroomAndCabins"
                                      />
                                    </div>
                                  </Col>
                                )}

                                <Col xs={6} className="z-index-999 pl-0">
                                  <div className="mb-2">
                                    <SingleSelect
                                      label="Number of passenger capacity"
                                      required
                                      name="maximumGuest"
                                      placeholder="Select number of passenger capacity"
                                      selectedOption={values.maximumGuest}
                                      options={renderSingleSelectOptions(50)}
                                      onChange={value => {
                                        setFieldValue('maximumGuest', value)
                                      }}
                                    />
                                    <ErrorMessage
                                      component="div"
                                      name="maximumGuest"
                                      className="error-message err-msg-maximumGuest"
                                    />
                                  </div>
                                </Col>

                                {isRentPerHourTrip || (
                                  <>
                                    <Col xs={6} className="z-index-99 pr-0">
                                      <div className="mb-2">
                                        <SingleSelect
                                          label="Bathrooms"
                                          required
                                          name="bathrooms"
                                          placeholder="Select Bathrooms"
                                          selectedOption={values.bathrooms}
                                          options={renderSingleSelectOptions(50)}
                                          onChange={value => {
                                            setFieldValue('bathrooms', value)
                                          }}
                                        />
                                        <ErrorMessage
                                          component="div"
                                          name="bathrooms"
                                          className="error-message err-msg-bathrooms"
                                        />
                                      </div>
                                    </Col>

                                    <Col xs={6} className="z-index-99 pl-0">
                                      <div className="mb-2">
                                        <SingleSelect
                                          label="Number of seats on board"
                                          required
                                          name="seatsOnBoat"
                                          placeholder="Select number of seats on board"
                                          selectedOption={values.seatsOnBoat}
                                          options={renderSingleSelectOptions(50)}
                                          onChange={value => {
                                            setFieldValue('seatsOnBoat', value)
                                          }}
                                        />
                                        <ErrorMessage
                                          component="div"
                                          name="seatsOnBoat"
                                          className="error-message err-msg-seatsOnBoat"
                                        />
                                      </div>
                                    </Col>
                                  </>
                                )}
                              </Row>
                            </Card>
                          }

                          {
                            <Card className="p-10 rent--card--spacing">
                              <Row className="m-0">
                                <Col xs={12} className="pl-0 pr-0">
                                  <div className="ml-0 card-title h5">Deck & Entertainment</div>
                                </Col>
                              </Row>

                              <Row className="m-0">
                                <Col xs={12} className="pl-0 pr-0">
                                  <div className="mb-2 what-bring-input">
                                    <Field
                                      label="What To Bring"
                                      id={'whatToBring'}
                                      name={'whatToBring'}
                                      value={values.whatToBring}
                                      required
                                      type="textarea"
                                      onChangeText={e => setFieldValue('whatToBring', e.target.value)}
                                    />

                                    <ErrorMessage
                                      component="div"
                                      name="whatToBring"
                                      className="error-message err-msg-whatToBring"
                                    />
                                  </div>
                                </Col>

                                {isRentPerHourTrip || (
                                  <Col xs={12} className="pl-0 pr-0">
                                    <div className="mb-2">
                                      <div controlId="exampleForm.ControlSelect1">
                                        <label className="required">Deck & Entertainment</label>

                                        <FormControl className="select-container rent-decks-full-width">
                                          {boatRentLookUps && decksAndEntertainment && (
                                            <MultiSelect
                                              style={{
                                                backgroundColor: 'red',
                                                zIndex: 999,
                                                position: 'relative',
                                                overflow: 'visible',
                                              }}
                                              selectedOption={values.deckAndEntertainment}
                                              onChangeValue={item => {
                                                setFieldValue('deckAndEntertainment', [...item])
                                              }}
                                              options={decksAndEntertainment}
                                            />
                                          )}
                                        </FormControl>

                                        <ErrorMessage
                                          component="div"
                                          name="deckAndEntertainment"
                                          className="error-message err-msg-deckAndEntertainment"
                                        />
                                      </div>
                                    </div>
                                  </Col>
                                )}
                              </Row>
                            </Card>
                          }
                          <Card className="p-10 rent--card--spacing">
                            <Row className="m-0">
                              <Col xs={12} className="pl-0 pr-0">
                                <div className="ml-0 card-title h5">Rent Information</div>
                              </Col>
                            </Row>
                            <Row className="m-0">
                              <Col xs={6} className="pl-0">
                                {/* <div className="mb-2">
                                  <Field
                                    label="About US"
                                    id={'aboutUs'}
                                    name={'aboutUs'}
                                    value={values.aboutUs}
                                    required
                                    type="text"
                                    onChangeText={e => {
                                      setFieldValue('aboutUs', e.target.value)
                                    }}
                                  ></Field>

                                  <ErrorMessage component="div" name="aboutUs" className="error-message err-msg-aboutUs" />
                                </div> */}
                                <div className="mb-2">
                                  <Field
                                    label="Description of our Trip"
                                    id={'descriptionOfOurTrip'}
                                    name={'descriptionOfOurTrip'}
                                    required
                                    value={values.descriptionOfOurTrip}
                                    type="textarea"
                                    onChangeText={e => {
                                      setFieldValue('descriptionOfOurTrip', e.target.value)
                                    }}
                                  ></Field>

                                  <ErrorMessage
                                    component="div"
                                    name="descriptionOfOurTrip"
                                    className="error-message err-msg-descriptionOfOurTrip"
                                  />
                                </div>

                                <div className="mb-2">
                                  <Field
                                    label="Add your policy"
                                    id={'policy'}
                                    name={'policy'}
                                    value={values.policy}
                                    type="textarea"
                                    required
                                    onChangeText={e => setFieldValue('policy', e.target.value)}
                                  ></Field>

                                  <ErrorMessage component="div" name="policy" className="error-message err-msg-policy" />
                                </div>

                                <div className="mb-2">
                                  <Field
                                    label="Promotion on site"
                                    id="promotionOnSite"
                                    name="promotionOnSite"
                                    value={values.promotionOnSite}
                                    type="textarea"
                                    onChangeText={e => setFieldValue('promotionOnSite', e.target.value)}
                                  ></Field>
                                </div>

                                <div className="mb-2">
                                  <div className="font-14">
                                    Insert one of the supported video links Youtube, Facebook, Daily Motion, Streamable, Google Videos, and Vimeo
                                  </div>
                                  <Field
                                    label="Link 1"
                                    id={'youtubeLinkVideo'}
                                    name={'youtubeLinkVideo'}
                                    value={values.youtubeLinkVideo}
                                    type="text"
                                    required
                                    onChangeText={e => {
                                      setFieldValue('youtubeLinkVideo', e.target.value)
                                    }}
                                  ></Field>

                                  <ErrorMessage
                                    component="div"
                                    name="youtubeLinkVideo"
                                    className="error-message err-msg-youtubeLinkVideo"
                                  />
                                </div>

                                <div className="mb-2">
                                  <Field
                                    label="Link 2"
                                    id={'youtubeLinkVideo_1'}
                                    name={'youtubeLinkVideo_1'}
                                    value={values.youtubeLinkVideo_1}
                                    type="text"
                                    onChangeText={e => setFieldValue('youtubeLinkVideo_1', e.target.value)}
                                  ></Field>
                                </div>

                                <div className="mb-2">
                                  <Field
                                    label="Link 3"
                                    id={'youtubeLinkVideo_2'}
                                    name={'youtubeLinkVideo_2'}
                                    value={values.youtubeLinkVideo_2}
                                    type="text"
                                    onChangeText={e => setFieldValue('youtubeLinkVideo_2', e.target.value)}
                                  ></Field>
                                </div>

                                {boatRentLookUps && boatRentLookUps.tripDeposit && (
                                  <>
                                    <div className="mb-2">
                                      <div controlId="exampleForm.ControlSelect1" className="">
                                        <FormControl className="select-container minWidth-40 w-100 ">
                                          <SingleSelect
                                            label="Deposit"
                                            required
                                            name="deposit"
                                            placeholder="Select deposit"
                                            selectedOption={values.deposit}
                                            options={tripsDeposit}
                                            onChange={value => {
                                              setFieldValue('deposit', value)
                                              this.setState({
                                                isCostOfDeposit: value.label.toLowerCase() === 'required deposit',
                                              })
                                            }}
                                          />
                                        </FormControl>
                                        <ErrorMessage
                                          component="div"
                                          name="deposit"
                                          className="error-message err-msg-deposit"
                                        />
                                      </div>
                                    </div>

                                    {values.deposit?.label?.toLowerCase() === 'required deposit' && (
                                      <div className="mb-2">
                                        <Field
                                          label="Required Deposit"
                                          id="costOfDeposit"
                                          name="costOfDeposit"
                                          required
                                          value={values.costOfDeposit}
                                          type="text"
                                          onChangeText={e => setFieldValue('costOfDeposit', e.target.value)}
                                        ></Field>
                                        <ErrorMessage
                                          component="div"
                                          name="costOfDeposit"
                                          className="error-message err-msg-costOfDeposit"
                                        />
                                      </div>
                                    )}
                                  </>
                                )}
                                <div className="mb-2">
                                  <TagsInput
                                    value={values?.meta_tags}
                                    handleChange={value => {
                                      setFieldValue('meta_tags', value.join(','))
                                    }}
                                  />
                                </div>
                              </Col>

                              <Col xs={6} className="pr-0 overflow-hidden">
                                <div className="d-flex justify-content-center flex-column h-100">
                                  <div className="w-100 mb-10">
                                    <Field
                                      label="Upload Layout Image"
                                      id="boatLayout"
                                      name="boatLayout"
                                      type="multi-upload-image"
                                      setFieldValue={setFieldValue}
                                      values={values.boatLayout}
                                      deleteIds={values.removeMedia}
                                      addMedia={values.addMedia}
                                      mediaName={MEDIA_NAME.RENTBOATLAYOUT}
                                    />
                                  </div>
                                  <Field
                                    label="Upload Trip Picture"
                                    id="images"
                                    name="images"
                                    type="multi-upload-image"
                                    setFieldValue={setFieldValue}
                                    values={values.images}
                                    deleteIds={values.removeMedia}
                                    addMedia={values.addMedia}
                                    mediaName={MEDIA_NAME.RENTIMAGE}
                                    required
                                  />
                                  <ErrorMessage
                                    component="div"
                                    name="images"
                                    className="error-message text-center err-msg-images"
                                  />
                                </div>
                              </Col>
                            </Row>
                          </Card>
                        </Col>
                      </Row>

                      <div style={{ marginTop: '1em' }}>
                        {agreementContent?.data && (
                          <>
                            <div className="clearfix">
                              <div className="custom-control custom-checkbox  mb-none custom--radio--btn">
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  id="agreement"
                                  checked={values.agreement}
                                  onClick={() => setFieldValue('agreement', !values.agreement)}
                                />
                                <label
                                  className="custom-control-label font-14 custom--radio--btn"
                                  onClick={() => setFieldValue('agreement', !values.agreement)}
                                >
                                  I have read and agreed to{' '}
                                  <span
                                    className="darkBlue cursor-pointer"
                                    onClick={e => {
                                      e.stopPropagation()
                                      this.toggleAgreementModal()
                                    }}
                                  >
                                    {agreementContent.data.title}
                                  </span>{' '}
                                  and I understood that this application is used to add a rental with AdamSea.Com.
                                </label>
                              </div>
                            </div>

                            <CommonModal
                              open={this.state.isAgreementModalOpen}
                              close={this.toggleAgreementModal}
                              title={agreementContent.data.title}
                              className="agreement--service--modal--section"
                            >
                              <div dangerouslySetInnerHTML={{ __html: agreementContent.data.pageContent }} />
                            </CommonModal>
                          </>
                        )}
                        <ErrorMessage component="div" name="agreement" className="error-message err-msg-agreement" />
                      </div>

                      <div className="d-flex justify-content-center" style={{ marginTop: '1em' }}>
                        <ButtonComponent
                          disabled={this.state.isSubmitting}
                          variant="outline"
                          color="success"
                          className=" h-auto btn--padding btn--font mr-3"
                          onClick={handleSubmit}
                        >
                          Save
                        </ButtonComponent>

                        <ButtonComponent
                          variant="outline"
                          color="cancel"
                          className=" h-auto btn--padding btn--font"
                          onClick={() => cancelHandler(history)}
                        >
                          Cancel
                        </ButtonComponent>
                      </div>
                    </div>
                  </Form>
                )}
              ></Formik>
            </Container>
          )}
        </DashboardLayout>
      </Fragment>
    )
  }
}

const mapStateToProps = state => ({
  siteConfigurations: state.loginReducer.siteConfigurations,
  staticPages: state.pageInfoByTypeReducer.staticPages,
  success: state.boatRentReducer && state.boatRentReducer.success,
  createSuccess: state.boatRentReducer && state.boatRentReducer.createSuccess,
  createError: state.boatRentReducer && state.boatRentReducer.error,
  updatedSuccess: state.boatRentReducer && state.boatRentReducer.updatedSuccess,
  boatRentLookUps: state.boatRentReducer && state.boatRentReducer.boatRentLookUps,
  boatRentTypes: state.boatRentReducer.boatRentTypes,
  allBoatRentTypes: state.boatRentReducer.allBoatRentTypes,
  editRentBoat: state.boatRentReducer && state.boatRentReducer.boatRentInnerData,
  tripLookups: state.rentReducer?.tripLookups,
  tripTypeLookups: state.rentReducer?.tripTypeLookups,
  currentUser: state.loginReducer && state.loginReducer.currentUser,
  agreement: state.pageInfoByTypeReducer && state.pageInfoByTypeReducer.agreement,
})

const mapDispatchToProps = dispatch => ({
  getStaticPages: data => dispatch(getStaticPages(data)),
  clear_getStaticPages: () => dispatch(clear_getStaticPages()),
  createBoatRent: data => dispatch(createBoatRent(data)),
  clearBoatRentFlag: () => dispatch(clearBoatRentFlag()),
  getBoatRentLookUps: () => dispatch(getBoatRentLookUps()),
  getAllBoatRentOfUser: () => dispatch(getAllBoatRentOfUser()),
  getAllRentTypes: data => dispatch(getAllRentTypes(data)),
  updateBoatRent: data => dispatch(updateBoatRent(data)),
  getRentInnerBoat: data => dispatch(getRentInnerBoat(data)),
  clearBoatRentData: data => dispatch(clearBoatRentData(data)),
  getTypeWiseLookup: data => dispatch(getTypeWiseLookup(data)),
  getAgreement: data => dispatch(getAgreement(data)),
  clear_getAgreement: () => dispatch(clear_getAgreement()),
})

export default connect(mapStateToProps, mapDispatchToProps)(CreateRentBoat)
