import React, { PureComponent } from 'react'
import { Badge } from 'react-bootstrap'

import './SingleBoatInformation.scss'
import { UserInfoDashboard } from './UserInfoDashboard'
import GoogleMarker from '../map/marker'
import { getSingleBoatMarker, googleDirectionUrl } from '../../helpers/boatHelper'
import { SaleEngineLabelAndValue } from './SaleEngineLabelAndValue'
import { getSalesEngineCountDescription, salesEngineStatus } from '../../containers/salesEngine/SalesEngineHelper'
import { getAddress } from '../../helpers/jsxHelper'
import { ButtonComponent } from '../form/Button'
import { SurveyorPaymentDetailPopup } from './SurveyorPaymentDetailsPopup'
import { salesEngineIcon, timeEnum, wishlistModuleEnum } from '../../util/enums/enums'
import { CurrencyContextConsumer } from '../../CurrencyContext'
import { getConvertedPrice } from '../../helpers/currencyConverterHelper'
import { getId, priceFormat } from '../../util/utilFunctions'
import { salesEngineAgents } from '../../redux/actions'
import { formattedRequestDate } from '../../helpers/dateTimeHelper'
import { Countdown } from '../home/countdown'
import { CommonTooltip } from '../CommonTooltip'
import { FaDownload, FaQrcode } from 'react-icons/fa'
import { CommonModal } from '../modal/commonModal'
import { connect } from 'react-redux'
import { getLocalStorageItem } from '../../helpers/storageHelper'
import { getModulePdf } from '../../redux/actions/pdfAction'

class SalesEngineDashboardCard extends PureComponent {
  state = {
    paymentDetailsModal: false,
    block: false,
  }

  paymentDetailsModalHandler = () => {
    this.setState(prevState => ({ paymentDetailsModal: !prevState.paymentDetailsModal }))
  }

  totalShipmentData = price => {
    if (price) {
      const transferFee = (price * 2.5) / 100
      return transferFee + price
    }
    return false
  }

  completeTimer = () => {
    this.setState({ block: true })
  }

  timerFunctionDetails = () => {
    const {
      salesEngine: { surveyorPaymentDueTime },
    } = this.props
    if (surveyorPaymentDueTime) {
      return {
        title: 'Time left to upload Survey report :',
        time: surveyorPaymentDueTime,
        desc: getSalesEngineCountDescription('surveyor'),
      }
    }
    return false
  }

  render() {
    const { paymentDetailsModal, block } = this.state
    const {
      salesEngine,
      accept,
      decline,
      isShipper,
      openModal,
      startShipment,
      currentUserId,
      completeShipment,
      submitReport,
      getDocumentUrl,
      goToBoat,
      getModulePdf,
      surveyDocument,
      isDealers,
      isAgent,
      history,
      currentUser,
      branchId,
      index,
      page,
      location,
      acceptRequestByAgent,
      discardAgentProcess,
      isSurveyor,
      isLimitExceeed,
      isNotInRequest,
    } = this.props
    const countDownDetails = this.timerFunctionDetails()

    const {
      shipmentLocation,
      boat: { address },
      boat: boatLocation,
      isViewedByShipper,
      isViewedBySurveyor,
      isViewedByAgent,
    } = salesEngine
    const {
      commonAddress: { address1: boatAddress, address2: boatAddress2, geometricLocation = null },
    } = getAddress(address)
    const shipperHasAcceptedRequest = salesEngine?.shipperRequested
      ?.reduce((ids, item) => {
        return (ids = item.shipper && item.price ? [...ids, item.shipper.id] : ids)
      }, [])
      .includes(currentUserId)
    const showPayment = isShipper ? (shipperHasAcceptedRequest && salesEngine?.paymentInformation) : salesEngine?.surveyorAccepted
    const shipperProposal =
      isShipper &&
      salesEngine?.shipperRequested?.length &&
      salesEngine?.shipperRequested.find(item => item?.shipper?.id === currentUserId)
    const payment = salesEngine?.surveyor?.pricePerFt * salesEngine?.boat?.lengthInFt
    const showPaymentTag = isShipper ? salesEngine?.shipperPayment : salesEngine?.surveyorPayment
    const paymentTagPrice = isShipper ? shipperProposal?.price && this.totalShipmentData(shipperProposal?.price) : payment

    const urlParams = new URLSearchParams(location.search)

    const pageCount = urlParams.has('page') ? urlParams.get('page') : 1

    const requestedDate = isShipper
      ? shipmentLocation?.date //shipper request date
      : isSurveyor
      ? salesEngine?.surveyorRequestDetails //surveyor request date
      : isAgent
      ? salesEngine?.agentRequestDate //agent/dealer request date
      : ''

    const isAdViewed = isShipper
      ? isViewedByShipper
      : isSurveyor
      ? isViewedBySurveyor?.includes && isViewedBySurveyor?.includes(branchId)
      : isAgent
      ? isViewedByAgent
      : false

    return (
      <div className="select-single-boat-information">
        <SurveyorPaymentDetailPopup
          open={paymentDetailsModal}
          onClose={this.paymentDetailsModalHandler}
          salesEngine={salesEngine}
          isShipper={isShipper}
          currentUserId={currentUserId}
        />

        {((salesEngine && salesEngine.boat) || isDealers) && (
          <div className="top-boat-information">
            <div className="boat-information-left-width">
              <div className="boat-information-left-with-right">
                <div className="d-flex flex-column shipper--request--card--left--div">
                  {salesEngine.boat.images?.length > 0 && (
                    <div className="image-left-info">
                      {(!isLimitExceeed || isNotInRequest) && (
                        <span className="boat--info--index">{index + 1 + (pageCount * 10 - 10)}.</span>
                      )}
                      <img
                        src={salesEngine.boat.images[0]?.url}
                        alt={salesEngine.boat.boatName}
                        onClick={() => goToBoat({ ...salesEngine.boat, seller: salesEngine.seller })}
                      />
                    </div>
                  )}
                </div>
                <div className="boat-info-right d-flex flex-column justify-content-center">
                  <div className="ad--id">Ad Id {salesEngine.boat.adId}</div>
                  <div className="boat--name">{salesEngine.boat.boatName}</div>
                  {showPaymentTag && paymentTagPrice && !isAgent && (
                    <CurrencyContextConsumer>
                      {({ currentCurrency }) => (
                        <Badge variant="success" className="">
                          Payment received of amount {getConvertedPrice(paymentTagPrice, currentCurrency)}
                        </Badge>
                      )}
                    </CurrencyContextConsumer>
                  )}
                  {/* <div className="boat--distance">
                                    Boat distance from the Surveyor: &nbsp;<span>{salesEngine?.distance && `${priceFormat(salesEngine?.distance)} Km`}</span>
                                </div>
                                    {!isAgent &&
                                        <div>
                                            {salesEngine.surveyorAccepted && !salesEngine?.surveyorPayment &&
                                                <div className="buyer--payment--proceeding">
                                                    <span>
                                                        Buyer Proceeding with payment
                                                    </span>
                                                </div>
                                            }



                                            {!isAgent && salesEngine.surveyorAccepted && <ButtonComponent
                                                className="payment--detail--btn"
                                                onClick={this.paymentDetailsModalHandler}>Payment Details</ButtonComponent>}
                                        </div>
                                    }
                                    {!shipperHasAcceptedRequest && isShipper &&
                                        <div className="boat--action">
                                             <button
                                        className="btn btn-sales-engine-secondary btn-sales-engine-info"
                                        onClick={() => decline({ id: salesEngine.id, shipperId: currentUserId })}
                                    >
                                        Reject
                                     </button>
                                    <button
                                        className="btn btn-sales-engine-secondary"
                                        onClick={() =>
                                            openModal("proposalModal", salesEngine.id)
                                            // accept(salesEngine.id)
                                        }
                                    >
                                        Accept
                                    </button> */}
                  {!isAgent && showPayment && (
                    <div>
                      <ButtonComponent className="payment--detail--btn" onClick={this.paymentDetailsModalHandler}>
                        Payment Details
                      </ButtonComponent>
                    </div>
                  )}

                  {!salesEngine?.surveyorPayment && isSurveyor && showPayment && (
                    <div className="buyer--payment--proceed--tag">
                      <p className="color-black font-bold font-18">Wait until the buyer proceeds with payment.</p>
                    </div>
                  )}

                  {isShipper && (
                    <div>
                      <button
                        className="btn btn-sales-engine-secondary proposal--to--buyer"
                        onClick={() => {
                          shipperHasAcceptedRequest
                            ? openModal('proposalModal', salesEngine?.id)
                            : openModal('proposalModal', salesEngine?.id)
                        }}
                      >
                        {shipperHasAcceptedRequest ? 'Proposal sent to Buyer' : 'Send Proposal'}
                      </button>
                    </div>
                  )}
                  {/* </div>
                                        } */}
                  {/* Surveyor */}
                  {!isLimitExceeed &&
                    !isAgent &&
                    !isShipper &&
                    isSurveyor &&
                    !salesEngine.surveyorAccepted &&
                    salesEngine.requestedSurveyor?.includes(branchId) && (
                      <div className="boat--action d-flex">
                        <button
                          className="btn btn-sales-engine-secondary btn-sales-engine-info requst--reject--btn"
                          onClick={() => decline({ id: salesEngine?.id, surveyorId: branchId })}
                        >
                          {'Reject'}
                        </button>
                        <button
                          className="btn btn-sales-engine-secondary"
                          onClick={() => accept({ id: salesEngine?.id, surveyorId: branchId })}
                        >
                          {'Accept'}
                        </button>
                      </div>
                    )}
                  {isAgent && !salesEngine?.agent && salesEngine?.agentRequested && (
                    <div className="boat--action d-flex">
                      <button
                        className="btn btn-sales-engine-secondary btn-sales-engine-info requst--reject--btn"
                        onClick={() => discardAgentProcess({ id: salesEngine?.id })}
                      >
                        {'Reject'}
                      </button>
                      <button
                        className="btn btn-sales-engine-secondary"
                        onClick={() => acceptRequestByAgent({ id: salesEngine?.id, agentId: currentUserId })}
                      >
                        {'Accept'}
                      </button>
                    </div>
                  )}
                  {!!salesEngine?.stockQuantity && isShipper && (
                    <div className="request--boat--quantity">
                      <span className="font-bold">Boat Quantity {salesEngine?.stockQuantity}</span>
                    </div>
                  )}
                  {isShipper &&
                    salesEngine.shipperPayment &&
                    salesEngine.shipperRequested.map(item => item.shipper?.id).includes(currentUserId) &&
                    currentUserId !== salesEngine?.shipper?.id && (
                      <div className="mt-2">
                        <span className="Unverified">{'LOST DEAL'}</span>
                      </div>
                    )}
                </div>
              </div>
            </div>

            {/* {!isAgent &&  */}
            <div className="boat-information-right-width">
              {/* need for future use, temp commented */}
              {/* {isAgent &&
                            <div>
                                <div className="year-build-detail">
                                    <div className="label-boat-with-value">
                                        <SaleEngineLabelAndValue
                                            label="Company Name"
                                            value={salesEngine.boat.yearBuilt}
                                        />

                                    </div>
                                    <div className="label-boat-with-value agent--company--logo">
                                        <SaleEngineLabelAndValue
                                            label="Company Logo"
                                            value={<img loading="lazy" src={salesEngine.boat.images[0]?.url} alt={salesEngine.boat.boatName} />}
                                        />
                                    </div>
                                    <div className="label-boat-with-value">
                                        <SaleEngineLabelAndValue
                                            label="Website"
                                            value={salesEngine.boat.lengthInFt}
                                        />

                                    </div>
                                </div>
                            </div>
                        } */}
              <div className="year-build-detail">
                <div className="label-boat-with-value">
                  <SaleEngineLabelAndValue label="Year Build" value={salesEngine.boat.yearBuilt} />
                </div>
                {salesEngine?.boat?.boatParking && (
                  <div className="label-boat-with-value">
                    <SaleEngineLabelAndValue label="Boat Parking" value={salesEngine?.boat?.boatParking?.alias} />
                  </div>
                )}
                <div className="label-boat-with-value">
                  <SaleEngineLabelAndValue label="Boat Length" value={salesEngine.boat.lengthInFt} />
                </div>
                <div className="label-boat-with-value">
                  <SaleEngineLabelAndValue label="Trailer" value={salesEngine.boat.trailer} />
                </div>
              </div>

              <div className="status-type-detail">
                <div className="label-boat-with-value">
                  <SaleEngineLabelAndValue label="Boat Status" value={salesEngine?.boat?.boatStatus?.alias} />
                </div>
                <div className="label-boat-with-value">
                  <SaleEngineLabelAndValue label="Boat Type" value={salesEngine?.boat?.boatType?.name} />
                </div>
                <div className="label-boat-with-value">
                  <SaleEngineLabelAndValue label="Engine Manufacturer" value={salesEngine?.boat?.engineManufacturer} />
                </div>
                {!isAgent && (
                  <div className="label-boat-with-value">
                    {isShipper ? (
                      <>
                        {salesEngine.buySellProcess.includes(salesEngineStatus.shipmentStarted) &&
                          shipperHasAcceptedRequest && (
                            <>
                              <button
                                onClick={() => {
                                  history.push(`/shipment-document/${salesEngine?.id}`)
                                }}
                                className="btn btn-outline-primary saleseng-btn"
                              >
                                Upload Shipment Documents
                              </button>
                              <button
                                type="button"
                                className="w-100 btn btn-outline-dark mt-2 saleseng-btn qrcode-btn"
                                disabled={this.props.isPdfLoading}
                                onClick={() => {
                                  this.props.getModulePdf({
                                    id: getId(salesEngine),
                                    type: wishlistModuleEnum.SHIPPERSALESENGINEQR,
                                    currency: getLocalStorageItem('currentCurrency'),
                                  })
                                }}
                              >
                                <FaDownload />
                                &nbsp;QR Code
                              </button>
                            </>
                          )}
                        {getId(salesEngine?.seller) !== getId(currentUser) && (salesEngine.buySellProcess[salesEngine.buySellProcess.length - 1] ===
                          salesEngineStatus.shipperPaymentCompleted &&
                          shipperHasAcceptedRequest && (
                            <>
                              {/* <button
                                onClick={() => startShipment({ id: salesEngine?.id })}
                                className="btn btn-sales-engine-primary sales--engine--shipment--process--btn"
                              >
                                Start Shipment{' '}
                              </button> */}

                              <button
                                type="button"
                                className="w-100 btn btn-outline-dark saleseng-btn qrcode-btn"
                                onClick={() => this.setState({ isQRCodeModalOpen: true })}
                              >
                                <FaQrcode />
                                &nbsp;QR Code
                              </button>

                              <CommonModal
                                className="sales-engine-header-help-dialog dashboard--table--help--section"
                                open={this.state.isQRCodeModalOpen}
                                close={() => this.setState({ isQRCodeModalOpen: false })}
                                hideCloseIcon
                                title={
                                  <button
                                    type="button"
                                    className="w-100 btn btn-outline-dark mr-2 saleseng-btn qrcode-btn"
                                    disabled={this.props.isPdfLoading}
                                    onClick={() => {
                                      this.props.getModulePdf({
                                        id: getId(salesEngine),
                                        type: wishlistModuleEnum.SHIPPERSALESENGINEQR,
                                        currency: getLocalStorageItem('currentCurrency'),
                                      })
                                    }}
                                  >
                                    Download
                                  </button>
                                }
                              >
                                <div className="px-10 pb-4">
                                  <img loading="lazy" src={salesEngine?.qrCode} alt="qrcode" style={{ maxWidth: '50vw', maxHeight: '50vh' }} />
                                </div>

                                <ButtonComponent
                                  className="m-auto h-auto as--cancel--outline btn--padding btn--font "
                                  onClick={() => this.setState({ isQRCodeModalOpen: false })}
                                >
                                  Close
                                </ButtonComponent>
                              </CommonModal>
                            </>
                          ))}
                        {/* {salesEngine.buySellProcess[salesEngine.buySellProcess.length - 1] ===
                          salesEngineStatus.shipmentStarted && (
                          <button
                            onClick={() => completeShipment({ id: salesEngine?.id })}
                            className="btn btn-sales-engine-primary sales--engine--shipment--process--btn"
                          >
                            Complete Shipment
                          </button>
                        )} */}
                      </>
                    ) : (
                      <>
                        {!isAgent && salesEngine.surveyorAccepted && salesEngine.surveyorPayment && (
                          // salesEngine.surveyorPayment
                          //     ? <span onClick={() => getDocumentUrl(salesEngine?.surveyDocument?.id)} >Report Submitted</span>
                          //     : salesEngine.surveyorPayment &&
                          <>
                            {salesEngine.salesEngineStatus !== salesEngineStatus.surveyNotStarted ? (
                              <>
                                <button
                                  className="btn btn-outline-primary saleseng-btn"
                                  onClick={() => {
                                    submitReport({ id: salesEngine?.id, isReportUploaded: salesEngine.surveyorReport })
                                  }}
                                  disabled={isLimitExceeed || block || salesEngine?.boatPayment}
                                >
                                  {salesEngine?.surveySubmitted ? 'Update Uploaded Report' : 'Upload Survey Report'}
                                </button>
                                <button
                                  type="button"
                                  className="w-100 btn btn-outline-dark mt-2 saleseng-btn qrcode-btn"
                                  disabled={this.props.isPdfLoading}
                                  onClick={() => {
                                    this.props.getModulePdf({
                                      id: getId(salesEngine),
                                      type: wishlistModuleEnum.SURVEYORSALESENGINEQR,
                                      currency: getLocalStorageItem('currentCurrency'),
                                    })
                                  }}
                                >
                                  <FaDownload />
                                  &nbsp;QR Code
                                </button>
                              </>
                            ) : (
                              showPaymentTag &&
                              paymentTagPrice &&
                              !isAgent && (
                                <>
                                  <button
                                    type="button"
                                    className="w-100 btn btn-outline-dark saleseng-btn qrcode-btn"
                                    onClick={() => this.setState({ isQRCodeModalOpen: true })}
                                  >
                                    <FaQrcode />
                                    &nbsp;QR Code
                                  </button>

                                  <CommonModal
                                    className="sales-engine-header-help-dialog dashboard--table--help--section"
                                    open={this.state.isQRCodeModalOpen}
                                    close={() => this.setState({ isQRCodeModalOpen: false })}
                                    hideCloseIcon
                                    title={
                                      <button
                                        disabled={this.props.isPdfLoading}
                                        className="w-100 btn btn-outline-dark mr-2 saleseng-btn qrcode-btn"
                                        onClick={() =>
                                          this.props.getModulePdf({
                                            id: getId(salesEngine),
                                            type: wishlistModuleEnum.SURVEYORSALESENGINEQR,
                                            currency: getLocalStorageItem('currentCurrency'),
                                          })
                                        }
                                      >
                                        Download
                                      </button>
                                    }
                                  >
                                    <div className="px-10 pb-4">
                                      <img
                                        src={salesEngine?.qrCode}
                                        alt="qrcode"
                                        style={{ maxWidth: '50vw', maxHeight: '50vh' }}
                                      />
                                    </div>

                                    <ButtonComponent
                                      className="m-auto h-auto as--cancel--outline btn--padding btn--font "
                                      onClick={() => this.setState({ isQRCodeModalOpen: false })}
                                    >
                                      Close
                                    </ButtonComponent>
                                  </CommonModal>
                                </>
                              )
                            )}
                          </>
                        )}
                      </>
                    )}
                  </div>
                )}
                {!isAgent && (
                  <div className="download-surveyor-modal">
                    <div className="download-surveyor-modal--content">
                      {!isShipper && (
                        <ButtonComponent
                          color="surveyor"
                          className="no-hover"
                          onClick={() => getDocumentUrl(surveyDocument?.document?.id)}
                        >
                          <img loading="lazy" className="download-surveyor-modal--left-icon" alt="salesengine description" src={salesEngineIcon.description} />
                          <span className="download-surveyor-modal--text">Survey Report</span>
                          <div className="download-surveyor-modal--download">
                            <img loading="lazy" className="download-surveyor-modal--download-icon" alt="salesengine download" src={salesEngineIcon.download} />
                          </div>
                        </ButtonComponent>
                      )}
                      <ButtonComponent
                        color="surveyor"
                        className="no-hover"
                        disabled={this.props.isPdfLoading}
                        onClick={() => {
                          getModulePdf({
                            id: getId(salesEngine?.boat),
                            type: wishlistModuleEnum.OWNERSHIPCERT,
                            filenamePrefix: 'ownership-cert',
                            zipFilename: 'ownership-certs',
                          })
                        }}
                      >
                        <img loading="lazy" className="download-surveyor-modal--left-icon" alt="salesengine direction" src={salesEngineIcon.directions} />
                        <span className="download-surveyor-modal--text">Ownership Certficates</span>
                        <div className="download-surveyor-modal--download">
                          <img loading="lazy" className="download-surveyor-modal--download-icon" alt="salesengine download" src={salesEngineIcon.download} />
                        </div>
                      </ButtonComponent>
                    </div>
                  </div>
                )}
              </div>
              {!isLimitExceeed && salesEngine?.surveyorPaymentDueTime && isSurveyor && (
                <div className="d-flex mt-10 justify-content-end process-countdown">
                  <div className="payment-due-header">
                    {countDownDetails?.title}
                    <CommonTooltip component={ctProps => (
                      <p data-tooltip-id={ctProps.id} data-tooltip-content={countDownDetails.desc}>what is it ?</p>
                    )} />
                  </div>
                  <div className="d-flex">
                    <Countdown endTime={salesEngine?.surveyorPaymentDueTime} isDashboardCounter complete={this.completeTimer} />
                  </div>
                </div>
              )}
              {isLimitExceeed && (
                <div className="payment-due-header d-flex justify-content-start">
                  The transaction has been canceled because you missed the timeline to submit the survey report.
                </div>
              )}
              {/* {!isAgent ? (
                <div className="d-flex inner--bid--common--modal--section" style={{ marginTop: '5px', marginBottom: '5px' }}>
                  <span className="d-flex justify-content-center align-items-center inner--auction--room--time--left--title inner--bid--common--modal--time">
                    {'TIME LEFT'}
                  </span>
                  <div className="d-flex">
                    <Countdown endTime={moment().add(1, 'day')} isDashboardCounter />
                  </div>
                </div>
              ) : (
                ''
              )} */}
            </div>
            {/* } */}
            {currentUserId === salesEngine?.agent?.id && (
              <div className="view--sales--engine--process--div">
                <button
                  type="button"
                  onClick={() => history.push(`/sales-engine-process/${salesEngine?.id}`)}
                  className="view--sales--engine--process"
                >
                  {'View Process'}
                </button>
              </div>
            )}

            <div className="new--request--icon--div">
              {requestedDate && (
                <div className="surveyor--date">
                  <p>Request Date: {formattedRequestDate(requestedDate)}</p>
                </div>
              )}
              {!isAdViewed && (
                <div className="new--request--icon">
                  <img loading="lazy" src={require('../../assets/images/salesEngine/new1.svg')} alt="bell-icon" />
                </div>
              )}
            </div>
          </div>
        )}
        <div className="bottom-boat-information">
          {salesEngine?.buyer?.id && (
            <div className="boat-information-left-width">
              <UserInfoDashboard userInfo={salesEngine.buyer} isOnline={this.props.onlineUsers[getId(salesEngine.buyer)]} buyer />
            </div>
          )}
          <div className="boat-information-right-width">
            <>
              {salesEngine?.seller?.id && (
                <div className="boat--info--user--div">
                  {/* Seller Information */}
                  <UserInfoDashboard userInfo={salesEngine.seller} isOnline={this.props.onlineUsers[getId(salesEngine.seller)]} seller />
                </div>
              )}
            </>

            <div className="boat--info--map-with-address pl-2 pt-2">
              {isShipper ? (
                <div className="user--pickup--boat--address">
                  <div className="pick--from--field">
                    <div className="pick--from--label">Pickup from</div>
                    <div className="pick--from--value">
                      <a href={googleDirectionUrl(geometricLocation)} target="_blank">
                        {boatAddress || boatAddress2}
                      </a>
                    </div>
                  </div>
                  <div className="pick--from--field">
                    <div className="pick--from--label">Delivery to</div>
                    <div className="pick--from--value">
                      {shipmentLocation && (
                        <a href={googleDirectionUrl(shipmentLocation.geometricLocation)} target="_blank">
                          {shipmentLocation.address}
                        </a>
                      )}
                    </div>
                  </div>
                </div>
              ) : (
                <div className="boat--location--with--map">
                  {/* Surveyor */}
                  {boatLocation && (
                    <GoogleMarker
                      gotoDirectionText="Go to boat"
                      isInnerPage={false}
                      markers={getSingleBoatMarker(boatLocation, true)}
                      geometricLocation={geometricLocation}
                      isRedirectToMap
                    />
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  isPdfLoading: state.pdfReducer?.isLoading,
})

const mapDispatchToProps = dispatch => ({
  getModulePdf: data => dispatch(getModulePdf(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(SalesEngineDashboardCard)
