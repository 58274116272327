import React, { Component, Fragment } from 'react'
import { Grid, Box } from '@material-ui/core'
import { connect } from 'react-redux'

import {
  clearCityWiseBoats,
  getRentBoatTripCities,
  getBoatRentLookUps,
  getAllRentTypes,
  getRentBoatByTripAction,
  getRentBoatMostPopularTripAction,
  getRecommendedTrips,
  getModuleWiseBanners,
  getExperiences,
  notifyMe,
  getTypeWiseLookup,
  getAllTripTypes,
} from '../../redux/actions'
import { shareDetailsWithUsers } from '../../redux/actions/shareAction'
import { getCategoryWiseAdvertisements } from '../../redux/actions/advertisementAction'
import { getCategoryWiseVideos } from '../../redux/actions/VideoAction'
import { Layout } from '../../components/layout/layout'
import RentCard from '../../components/staticComponent/rentCard'
import { TripCarousels } from '../../components/carouselComponent/tripsCarousels'
import ExploreAdamSeaIndia from '../../components/carouselComponent/exploreAdamSeaIndia'
import { RentSharedTrips } from '../../components/gridComponents/rentSharedTrips'
import ReviewCards from '../../components/gridComponents/reviewCards'
import { RentBoatTripCitiesCard } from '../../components/staticComponent/rentBoatTripCitiesCard'
import ServicesMarinaStorage from '../../components/staticComponent/servicesMarinaStorage'
import CommonBanner from '../../components/mainBanner/commonBanner'
import RegisterCard from '../../components/staticComponent/registerCard'
import { VideoComponent } from '../../components/videoComponent/videoComponent'
import { VideoModel } from '../../components/videoComponent/videoModel'

import UserContext from '../../UserContext'
import {
  pagination,
  lookupTypes,
  moduleCategory,
  dimension,
  mediaCategory,
  advertisementCategory,
  showAll,
  responsiveCarousel,
  marginCarousel,
} from '../../util/enums/enums'
import { RentCardStyle, RentBannerImage, RentHomeCardBanner } from '../../components/styleComponent/styleComponent'
import { getRatio, getHeightRatio } from '../../helpers/ratio'
import { lowerHypenCase } from '../../helpers/string'

import './rent.scss'
import './rentResponsive.scss'
import { clear_getStaticPages, getStaticPages } from '../../redux/actions/pageInfoByTypeAction'
import { get } from 'lodash'

const recommendedTrip = {
  isBrowser: 3,
  isTablet: 3,
  isMobile: 1,
}

const ExploreAdamSeaRentCarousel = {
  isBrowser: 6,
  isTablet: 2,
  isMobile: 1,
}
const rentBoatCities = {
  isBrowser: 5,
  isTablet: 2,
  isMobile: 1,
}
const mostPopularTripItems = {
  isBrowser: 5,
  isTablet: 3,
  isMobile: 1,
}
class Rent extends Component {
  state = {
    setVideoFlag: false,
    videoUrl: '',
    videoThumbnail: '',
    isFlag: false,
    country: '',
    videoWidth: dimension.rentVideo.width,
    videoHeight: dimension.rentVideo.height,
    height: 75,
  }

  static contextType = UserContext

  static getDerivedStateFromProps(props, state) {
    const { tripTypes, getRentBoatByTripAction } = props
    const { isFlag, country } = state

    if (tripTypes && tripTypes.length && isFlag) {
      tripTypes.map(type => {
        return getRentBoatByTripAction({
          page: pagination.PAGE_COUNT,
          limit: pagination.PAGE_RECORD_LIMIT,
          country,
          trip: type.alias,
        })
      })

      return { isFlag: false }
    }
    return null
  }

  componentDidMount() {
    const {
      getRentBoatMostPopularTripAction,
      getRentBoatTripCities,
      getBoatRentLookUps,
      getAllRentTypes,
      getRecommendedTrips,
      getCategoryWiseVideos,
      getModuleWiseBanners,
      getExperiences,
      getCategoryWiseAdvertisements,
      getTypeWiseLookup,
      getAllTripTypes,
    } = this.props

    const { country } = this.context

    const mostPopularRequestData = {
      page: pagination.PAGE_COUNT,
      limit: pagination.PAGE_RECORD_LIMIT,
      country: country,
    }

    getRentBoatTripCities({ country })

    getRentBoatMostPopularTripAction(mostPopularRequestData)

    getRecommendedTrips({
      country,
      page: pagination.PAGE_COUNT,
      limit: pagination.PAGE_RECORD_LIMIT,
    })

    getBoatRentLookUps(lookupTypes.TRIP_TYPE)
    getAllTripTypes({ country })
    getExperiences('boat-rent')
    getCategoryWiseVideos({ type: moduleCategory.RENT })
    getModuleWiseBanners({
      type: mediaCategory.rent.type,
      fieldName: mediaCategory.rent.fieldName,
      isBanner: true,
    })

    getCategoryWiseAdvertisements(advertisementCategory.RENT)

    this.zoomWidth()
    this.getNewWidth()
    this.videoWidthZoom()

    this.setState({ isFlag: true, country })
    getTypeWiseLookup('Trip')
    getTypeWiseLookup('Trip Type')
    window.addEventListener('resize', this.zoomWidth)
    window.addEventListener('resize', this.getNewWidth)
    window.addEventListener('resize', this.videoWidthZoom)

    this.props.getStaticPages({ slug: 'rent-homepage-content-706' })
  }
  componentDidUpdate() {
    if (this.props.staticPages?.success || this.props.staticPages?.failure) {
      this.props.clear_getStaticPages()
    }
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.zoomWidth)
    window.removeEventListener('resize', this.getNewWidth)
    window.removeEventListener('resize', this.videoWidthZoom)
  }
  zoomWidth = () => {
    const selector = document.querySelector('.header-responsive')
    const height = selector.offsetHeight
    this.setState({ height })
  }
  videoWidthZoom = () => {
    const videoWidth = getRatio(dimension, 'rentVideo', '.section-heading')

    const videoHeight = getHeightRatio(dimension, 'rentVideo', '.section-heading')
    this.setState({ videoWidth, videoHeight })
  }
  getNewWidth = () => {
    const width = document.querySelector('body')
    const actualWidth = width && width.offsetWidth / 3.8
    this.setState({ width: actualWidth })
  }

  playVideo = (url, thumbnail) => {
    this.setState(prevState => ({
      setVideoFlag: !prevState.setVideoFlag,
      videoUrl: url,
      videoThumbnail: thumbnail,
    }))
  }
  closeVideo = () => {
    this.setState({ setVideoFlag: false })
  }

  getCityWiseBoatsHandler = value => {
    // const { country } = this.context
    value && window.open(`/rent-city-wise-boats/${value && value.city}/${value?.country}`, '_blank')
  }

  getCategoryWiseBoatsHandler = value => {
    const { country } = this.context
    value &&
      window.open(
        `/rent-category-wise-boats/${lowerHypenCase(value.alias)}/${value && value.id}/${lowerHypenCase(country)}`,
        '_blank'
      )
  }

  render() {
    const {
      categoryVideos,
      rentSharedTripsData,
      rentMostPopularTrips,
      recommendedTrips,
      privateTrips,
      recommendedTripsPerHour,
      tripLists,
      totalRecommendedTrips,
      advertisements,
      history,
      boatRentTypes,
      allBoatRentTypes,
      rentBoatTripCities,
      rentBanner,
      experience,
      notifyMe,
      notifyInput,
      rentSharedTripsTotal,
      rentMostPopularTripsTotal,
      allTripTypes,
      tripLookups,
      tripTypeLookups,
    } = this.props

    const { setVideoFlag, videoUrl, videoThumbnail, videoHeight, videoWidth, height, width } = this.state

    return (
      <Layout className="rent-layout rent-header-top-space">
        <RentHomeCardBanner height={height} className="rent-content">
          <div>
            <RentCardStyle className="rent-home rent--home--border rent-home-page-form" width={width}>
              <div className="rent-card-wrap">
                <RentCard tripList={tripLists} tripTypeData={tripTypeLookups} notifyMe={notifyMe} notifyInput={notifyInput} />
              </div>
            </RentCardStyle>
          </div>

          <RentBannerImage width={width}>
            <div className="adam-glo-video-hide">
              <div className="overflow-hidden rent-home-img">
                {rentBanner && rentBanner.length > 0 && <CommonBanner data={rentBanner} isRent />}
              </div>
            </div>
          </RentBannerImage>
        </RentHomeCardBanner>

        <div className="container100 Rent rent-image">
          <div container>
            {/* <Grid item xs={12}>
              <h1 className="r-h1 mb-0">
                <Box className="section-heading" fontSize={24} fontWeight={500} letterSpacing={0.5}>
                  Explore AdamSea
                </Box>
              </h1>
              <ServicesMarinaStorage type="rent" className="mb-20" />
            </Grid> */}

            {allTripTypes?.length > 0 && (
              <Grid className="rent-arrow cor-wrp-up" item xs={12}>
                <Box className="section-heading" fontSize={24} fontWeight={500} letterSpacing={0.5}>
                  Explore the fun trips and rentals in {this.context.country}
                </Box>
                <ExploreAdamSeaIndia
                  customWidthItem={6}
                  items={ExploreAdamSeaRentCarousel}
                  carouselData={allTripTypes}
                  getCategoryWiseBoats={value => this.getCategoryWiseBoatsHandler(value)}
                />
              </Grid>
            )}

            {rentBoatTripCities?.length > 0 && (
              <Grid item xs={12}>
                <Box className="section-heading" fontSize={24} fontWeight={500} letterSpacing={0.5}>
                  Trip destinations around the world
                </Box>

                <RentBoatTripCitiesCard
                  limit={5}
                  data={rentBoatTripCities}
                  items={rentBoatCities}
                  getCityWiseRentBoats={value => this.getCityWiseBoatsHandler(value)}
                />
              </Grid>
            )}

            {recommendedTrips?.length > 0 && (
             <Grid item xs={12} className='rent-inner-recommended-trips'>
                <Box className="section-heading" fontSize={24} fontWeight={500} letterSpacing={0.5}>
                  AdamRent Recommended Trips
                </Box>

                <TripCarousels
                  customWidthItem={3}
                  itemsLength={8}
                  totalLength={totalRecommendedTrips}
                  items={recommendedTrip}
                  showType={showAll.recommended}
                  showAllText="AdamRent Recommended Trips"
                  carouselData={recommendedTrips}
                  dimensionKey="recommended"
                  margin={marginCarousel.sharedTrip}
                  responsiveNumber={responsiveCarousel.recommendedTrip}
                />
              </Grid>
            )}

            {rentSharedTripsData?.length > 0 && (
              <Grid item xs={12} className="shared-trip">
                <Box className="section-heading " fontSize={24} fontWeight={500} letterSpacing={0.5}>
                  AdamRent shared trips with others
                </Box>
                <RentSharedTrips
                  isShared
                  isTopLabel={false}
                  itemsLength={8}
                  showAllText="AdamRent shared trips with others"
                  dimensionKey={showAll.sharedTrip}
                  className="grid--rent--private--trip cursor-pointer"
                  showType={showAll.sharedTrip}
                  rentSharedTrips={rentSharedTripsData}
                />
              </Grid>
            )}

            {rentMostPopularTrips?.length > 0 && (
              <Grid item xs={12} className='rent-inner-recommended-trips'>
                <Box className="section-heading" fontSize={24} fontWeight={500} letterSpacing={0.5}>
                  Most Popular Trips
                </Box>

                <TripCarousels
                  itemsLength={8}
                  totalLength={rentMostPopularTripsTotal}
                  items={mostPopularTripItems}
                  showType={showAll.mostPopular}
                  carouselData={rentMostPopularTrips}
                  showAllText="most popular trips"
                  dimensionKey="popularTrip"
                  gutter={15}
                  responsiveNumber={responsiveCarousel.rentMostPopular}
                />
              </Grid>
            )}

            {advertisements?.length > 0 && (
              <Grid item xs={12} className="registerCardCont adam-card-banner">
                <div >
                  <RegisterCard adData={advertisements[0]} isFlag />
                </div>
              </Grid>
            )}

            {privateTrips?.length > 0 && (
              <Grid item xs={12} className="private-trip private--grid--trip--new">
                <Box className="section-heading" fontSize={24} fontWeight={500} letterSpacing={0.5}>
                  Private Trips
                </Box>

                <RentSharedTrips
                  isPrivate
                  isTopLabel={false}
                  itemsLength={8}
                  showAllText="private trips"
                  dimensionKey="privateTrip"
                  className="grid--rent--private--trip cursor-pointer"
                  showType={showAll.privateTrip}
                  rentSharedTrips={privateTrips}
                />
              </Grid>
            )}

            {recommendedTripsPerHour?.length > 0 && (
              <Grid item xs={12} className="adamsea-rent-per-hour">
                <Box className="section-heading" fontSize={24} fontWeight={500} letterSpacing={0.5}>
                  AdamSea RENT Per Hour
                </Box>
                <RentSharedTrips
                  isRentPerHour
                  isTopLabel={false}
                  showAllText="AdamSea RENT per hour"
                  itemsLength={8}
                  dimensionKey="perHourTrip"
                  className="grid--rent--private--trip cursor-pointer"
                  showType={showAll.tripsPerHour}
                  rentSharedTrips={recommendedTripsPerHour}
                />
              </Grid>
            )}

            {advertisements?.length > 1 && (
              <Grid item xs={12} className="registerCardCont">
                <Grid container spacing={2}>
                  <Grid item sm={6}>
                    <div className="double-card">
                      <RegisterCard isSpace isDouble adData={advertisements[1]} isFlag />
                    </div>
                  </Grid>
                  {advertisements && advertisements.length > 2 && (
                    <Grid item sm={6}>
                      <div className="double-card">
                        <RegisterCard isSpace2 isDouble adData={advertisements[2]} isFlag />
                      </div>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            )}

            {experience?.length > 0 && (
              <Grid item xs={12}>
                <Box className="section-heading hide-component-homepage-card" fontSize={24} fontWeight={500} letterSpacing={0.5}>
                  Live the Experience with us
                </Box>
                <ReviewCards
                  isRent
                  xs={12}
                  sm={3}
                  experience={experience}
                  dimensionKey="experienceUs"
                  gutter={80}
                  responsiveNumber={responsiveCarousel.experienceUs}
                />
              </Grid>
            )}

            {categoryVideos?.length > 0 && (
              <div item xs={12} className="rent-video-section">
                <Box className="section-heading" fontSize={24} fontWeight={500} letterSpacing={0.5}></Box>

                <Grid item xs={12}>
                  <Grid container spacing={2} className='video-section-space-gap video-gapping-space-common'>
                    {categoryVideos &&
                      categoryVideos.map((video, index) => {
                        if (index < 3) {
                          return (
                            <Fragment key={video.id}>
                              <VideoComponent
                                setVideoUrlHandler={thum =>
                                  this.playVideo(
                                    video?.url?.[0]?.url,
                                    video.thumbnail?.length > 0 ? video?.thumbnail?.[0]?.url : thum
                                  )
                                }
                                video={video}
                                videoWidth={videoWidth}
                                videoHeight={videoHeight}
                              />
                            </Fragment>
                          )
                        }
                      })}
                  </Grid>
                  <VideoModel
                    videoFlag={setVideoFlag}
                    videoUrl={videoUrl}
                    thumbnail={videoThumbnail}
                    closeVideo={this.closeVideo}
                  />
                </Grid>
              </div>
            )}

            {get(this.props.staticPages, 'data[0].pageContent', '') && (
              <div style={{ marginTop: 30, padding: '16px 24px', borderRadius: 8, background: '#dff0f2' }}>
                <div className="d-flex">
                  <div style={{ flexShrink: 0 }}>
                    <img src={require('../../assets/images/RENT.svg')} alt="bell" height={28} style={{ height: 28 }} />
                  </div>
                  <div style={{ margin: 0, marginLeft: 20, fontSize: 16 }}>
                    {get(this.props.staticPages, 'data[0].pageContent', '')}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </Layout>
    )
  }
}

const mapStateToProps = state => ({
  videos: state.dashboardReducer.videos,
  categoryVideos: state.videoReducer && state.videoReducer.categoryVideos,
  rentSharedTripsData: state.rentReducer && state.rentReducer.rentSharedTrips,
  rentMostPopularTrips: state.rentReducer && state.rentReducer.rentMostPopularTrips,
  rentMostPopularTripsTotal: state.rentReducer && state.rentReducer.rentMostPopularTripsTotal,
  privateTrips: state.rentReducer.privateTrips,
  recommendedTripsPerHour: state.rentReducer.recommendedTripsPerHour,
  tripTypes: state.rentReducer.tripTypes,
  boatRentTypes: state.boatRentReducer.boatRentTypes,
  allBoatRentTypes: state.boatRentReducer.allBoatRentTypes,
  recommendedTrips: state.rentReducer.recommendedTrips,
  tripsPerHourTotal: state.rentReducer.tripsPerHourTotal,
  privateTripsTotal: state.rentReducer.privateTripsTotal,
  rentSharedTripsTotal: state.rentReducer.rentSharedTripsTotal,
  totalRecommendedTrips: state.rentReducer.totalRecommendedTrips,
  tripLists: state.boatRentReducer && state.boatRentReducer.boatRentLookUps && state.boatRentReducer.boatRentLookUps.trip,
  lookUpSuccess: state.boatRentReducer && state.boatRentReducer.lookUpSuccess,
  advertisements: state.dashboardReducer && state.dashboardReducer[mediaCategory.rent.adField],
  rentBoatTripCities: state.rentReducer.rentBoatTripCities,
  experience: state.dashboardReducer.experience,
  rentBanner: state.dashboardReducer && state.dashboardReducer[mediaCategory.rent.fieldName],
  notifyInput: state.dashboardReducer.notifyInput,
  allTripTypes: state.rentReducer.allTripTypes,
  tripLookups: state.rentReducer?.tripLookups,
  tripTypeLookups: state.rentReducer?.tripTypeLookups,
  staticPages: state.pageInfoByTypeReducer.staticPages,
})

const mapDispatchToProps = dispatch => ({
  getRentBoatByTripAction: data => dispatch(getRentBoatByTripAction(data)),
  getCategoryWiseVideos: data => dispatch(getCategoryWiseVideos(data)),
  getRentBoatMostPopularTripAction: data => dispatch(getRentBoatMostPopularTripAction(data)),
  getRentBoatTripCities: data => dispatch(getRentBoatTripCities(data)),
  clearCityWiseBoats: () => dispatch(clearCityWiseBoats()),
  getBoatRentLookUps: data => dispatch(getBoatRentLookUps(data)),
  getAllRentTypes: data => dispatch(getAllRentTypes(data)),
  getExperiences: data => dispatch(getExperiences(data)),
  getRecommendedTrips: data => dispatch(getRecommendedTrips(data)),
  getCategoryWiseAdvertisements: data => dispatch(getCategoryWiseAdvertisements(data)),
  getModuleWiseBanners: type => dispatch(getModuleWiseBanners(type)),
  notifyMe: data => dispatch(notifyMe(data)),
  shareDetailsWithUsers: data => dispatch(shareDetailsWithUsers(data)),
  getTypeWiseLookup: data => dispatch(getTypeWiseLookup(data)),
  getAllTripTypes: data => dispatch(getAllTripTypes(data)),
  getStaticPages: data => dispatch(getStaticPages(data)),
  clear_getStaticPages: () => dispatch(clear_getStaticPages()),
})

export default connect(mapStateToProps, mapDispatchToProps)(Rent)
