import React from 'react'
import * as Yup from 'yup'
import { Formik, ErrorMessage } from 'formik'

import { Field } from '../ws/Field'
import { CommonModal } from './commonModal'
import { ButtonComponent } from '../form/Button'
import { positiveNumberHelper, requireMessage } from '../../helpers/string'
import { maxQuantityValidation, minQuantityValidation } from '../../util/enums/enums'

export const QuantityModal = props => {
  const { open, handleCloseModal, createSalesEngine, boat, createSalesEngineLoading } = props

  const availableQuantity = boat?.stockQuantity || 0
  const minPurchaseQuantity = boat?.minPurchaseQuantity || 0

  return (
    <>
      <CommonModal open={open} close={handleCloseModal} className="verification--otp" title="Select Quantity">
        <Formik
          initialValues={{ values: '' }}
          validationSchema={Yup.object().shape({
            stockQuantity: Yup.number()
              .required(requireMessage('Quantity'))
              .positive(positiveNumberHelper('Quantity'))
              .min(minPurchaseQuantity, minQuantityValidation(availableQuantity, minPurchaseQuantity))
              .max(availableQuantity, maxQuantityValidation(availableQuantity)),
          })}
          onSubmit={values => {
            createSalesEngine({
              buySellProcess: 'myBoat',
              boat: boat.id,
              seller: boat.seller.id,
              stockQuantity: parseInt(values.stockQuantity),
            })
          }}
          render={({ setFieldValue, values, handleSubmit }) => (
            <>
              <div>Available Quantity : {availableQuantity}</div>
              <div>Minimum Purchase Quantity : {minPurchaseQuantity}</div>
              <Field
                type="number"
                name="stockQuantity"
                id="stockQuantity"
                placeholder="Enter Quantity"
                value={values.stockQuantity}
                onChangeText={e => setFieldValue('stockQuantity', e.target.value)}
              />
              <ErrorMessage component="div" className="error-message" name="stockQuantity" />
              <div className="d-flex justify-content-center profile-container1">
                <ButtonComponent
                  variant="outline"
                  color="success"
                  className="h-auto btn--padding btn--font mr-3"
                  disabled={createSalesEngineLoading}
                  onClick={() => handleSubmit()}
                >
                  {'Start Buying'}
                </ButtonComponent>

                <ButtonComponent
                  variant="outline"
                  color="cancel"
                  className=" h-auto as--cancel--outline btn--padding btn--font"
                  onClick={() => handleCloseModal()}
                >
                  {'Cancel'}
                </ButtonComponent>
              </div>
            </>
          )}
        ></Formik>
      </CommonModal>
    </>
  )
}
