import React, { useContext } from 'react'
import SharePopup from '../share/SharePopup'
import { getAddress, cityCountryNameFormatter, redirectToUserProfile } from '../../helpers/jsxHelper'
import { defaultProfileIcon } from '../../util/enums/enums'
import UserContext from '../../UserContext'
import ChatUserCreate from '../chats/ChatCreateUse'
import classNames from 'classnames'
import { getId } from '../../util/utilFunctions'
import { prettifyPhoneNumber } from '../../helpers/validationHelper'

export const UserInfoDashboard = React.memo(({ userInfo, seller, buyer, isOnline }) => {
  const [selectedIndex, setSelectedIndex] = React.useState(null)
  const { history } = useContext(UserContext)

  const singleHandleClick = () => {
    setTimeout(() => {
      setSelectedIndex(null)
    }, 50)
  }
  const handleClick = index => {
    setSelectedIndex(selectedIndex !== index ? index : null)
  }
  const { documentVerification, firstName, lastName, mobileNumber, id, address, image } = userInfo
  const {
    commonAddress: { city, country },
  } = getAddress(address)

  return (
    <>
      <div className="user--info--dashboard-main">
        <div className="user-information-left-main">
          <div style={{ marginBottom: 15 }} className="d-flex align-items-center">
            <div className="user-type-label adamsea-text-color font-16">{buyer ? 'Buyer' : 'Seller'}</div>
            {userInfo && userInfo?.isVerified && (
              <div style={{ marginLeft: 10 }} className="user-document-verification">
                <div className="d-flex align-items-center icon--with-img">
                  <img loading="lazy" src={require('../userProfile/image/vector.png')} className="stepper-user-profile-icon-div mr-2" alt="Profile" />
                  <span className="rentInner-userTextH4  font-16 dark-silver">Verified</span>
                </div>
              </div>
            )}
          </div>
          <div className="user---name">
            {firstName} {lastName}
          </div>
          <div className="user---city--country">{cityCountryNameFormatter(city, country)}</div>
          {!!mobileNumber && <div className="user--phone">
              <a><i className="fas fa-phone-alt"></i> {prettifyPhoneNumber(mobileNumber)}</a>
          </div>}
        </div>
        <div className="d-flex flex-column align-items-center">
          <div className={classNames("user--logo-boat--info", { 'disconnect': !isOnline })} onClick={() => redirectToUserProfile(userInfo, history)}>
            <img loading="lazy" className="rounded-circle width-100 h-100" src={image?.url || defaultProfileIcon} alt="profile icon" />
          </div>
          <div className="user--profile--social--icon">
            <ChatUserCreate id={id} image={image?.url}/>
            <img
              src={require('../userProfile/image/share.png')}
              className="user-profile-social-icon-div cursor-pointer"
              alt="Share"
              onClick={() => handleClick(id)}
            />
            <SharePopup
              handleClick={value => handleClick(value)}
              index={id}
              selectedIndex={selectedIndex}
              singleHandleClick={() => singleHandleClick()}
              isInner
              useOwnIcon
              moduleId={id}
            />
          </div>
        </div>
      </div>
    </>
  )
})
