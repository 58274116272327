import gql from 'graphql-tag'
import {
  SalesEngine,
  Surveyor,
  creditPayment,
  SalesEngineAgreement,
  shipMent,
  SalesEngineAssignShipper,
  TypeCrud,
  SurveyorReport,
  Boat,
  AdminUser,
  SurveyorUserForSalesEngine,
  ImageType,
  CreateSalesEngine,
  SalesEngineUser,
  Media,
  AddressType,
  GeometricLocation,
} from './schemaTypes/schemaTypes'

// SALES ENGINE PROCESS
export const singleSalesEngine = gql`
    query singleSalesEngine($id: String!) {
        singleSalesEngine(id: $id)
            ${CreateSalesEngine}
    }
`
export const sellerAddNegotiableBoatPrice = gql`
    mutation sellerAddNegotiableBoatPrice($input: SellerNegotiableBoatPriceInput!){
        sellerAddNegotiableBoatPrice(input: $input)
            ${CreateSalesEngine}

    }`

export const skipSurveyor = gql`
    mutation skipSurveyor($id: String!, $isSurveyorSkip: Boolean!){
        skipSurveyor(id: $id, isSurveyorSkip: $isSurveyorSkip)
            ${CreateSalesEngine}
    }`

export const createSalesEngine = gql`
    mutation createSalesEngine($input: SalesEngineInput!) {
        createSalesEngine(input: $input)
        ${CreateSalesEngine}
    }
`
export const requestSurveyor = gql`
    mutation salesEngineBuyerRequestSurveyor($id: String!, $surveyorId: String!) {
        salesEngineBuyerRequestSurveyor(id: $id, surveyorId: $surveyorId)
            ${CreateSalesEngine}
    }
`

export const declineSurveyor = gql`
    mutation buyerDeclineSurveyor($id: String!, $surveyorId: String!) {
        buyerDeclineSurveyor(id: $id, surveyorId: $surveyorId)
            ${CreateSalesEngine}
    }
`

export const paymentQuery = gql`
    mutation salesEnginePayment($input: PaymentInput!) {
        salesEnginePayment(input: $input) {
            salesEngine ${CreateSalesEngine}
            payment ${creditPayment}
        }
    }
`

export const getAgreementContents = gql`
    query salesEngineAgreementContent($id: String!, $type: salesEngineAgreementTypeArg!) {
        salesEngineAgreementContent(id: $id, type: $type)
            ${SalesEngineAgreement}
    }
`

export const generateAgreementAndSurvey = gql`
    query generateAgreementAndSurvey($id: String!, $type: String!) {
        generateAgreementAndSurvey(id: $id, type: $type)
            ${CreateSalesEngine}
    }
`

export const checkAgreement = gql`
    mutation salesEngineAgreementCheck($id: String!, $buyerId: String, $checked: Boolean) {
        salesEngineAgreementCheck(id: $id, buyerId: $buyerId, checked: $checked)
            ${CreateSalesEngine}
    }
`

export const salesEngineAssignShipperQuery = gql`
    mutation salesEngineAssignShipper($id: String!, $shipperId: String!, $removeShipper: Boolean) {
        salesEngineAssignShipper(id: $id, shipperId: $shipperId, removeShipper: $removeShipper)
            ${CreateSalesEngine}
    }
`

export const skipShipper = gql`
    mutation skipShipper($id: String!, $isShipperSkip: Boolean!) {
        skipShipper(id: $id, isShipperSkip: $isShipperSkip)
            ${CreateSalesEngine}
    }
`

export const salesEngineShipperList = gql`
    query salesEngineShipperList($salesEngineId: String!) {
        salesEngineShipperList(salesEngineId: $salesEngineId) {
            price
            proposalDocument ${ImageType}
            shipper ${AdminUser}
            declineSalesEngineRequest
            distance
        }
    }
`

export const requestAgent = gql`
    mutation requestAgent($agentId: String!, $id: String!) {
        requestAgent(agentId: $agentId, id: $id)
            ${CreateSalesEngine}
    }
`

export const assignAgent = gql`
    mutation assignAgent($agentId: String!, $id: String!) {
        assignAgent(agentId: $agentId, id: $id)
            ${CreateSalesEngine}
    }
`

export const addBoatShipmentLocation = gql`
    mutation addBoatShipmentLocation($input: ShipmentLocationInput!, $id: String!) {
        addBoatShipmentLocation(input: $input, id: $id)
            ${CreateSalesEngine}
    }
`

export const getCostEstimate = gql`
  {
    getCostEstimate
  }
`

// BUYER DASHBOARD
export const getSalesEngineByBuyer = gql`
    query getSalesEngineByBuyer($isBuyItNow: Boolean! = false, $isArchive: Boolean, $page: Int, $limit: Int, $sort: SortType, $sortKey: String, $searchTerm: String) {
        getSalesEngineByBuyer(page: $page, limit: $limit, isBuyItNow: $isBuyItNow, isArchive: $isArchive, sort: $sort, sortKey: $sortKey, searchTerm: $searchTerm) {
            items  {
                id
                salesEngineStatus
                boat {
                    boatType ${TypeCrud}
                    adId
                }
                seller {
                    id
                    firstName
                    lastName
                    role {
                        id
                        role
                        aliasName
                    }
                }
                createdAt
                buySellProcess
                isPaymentDue
                isArchive
                surveyorPayment
                boatPayment
                shipperPayment
                isAuctionSalesEngine
            }
            total
        }
    }
`

// SELLER DASHBOARD
export const getSalesEngineBySeller = gql`
    query getSalesEngineBySeller($page: Int, $limit: Int, $sort: SortType, $sortKey: String, $searchTerm: String) {
        getSalesEngineBySeller(page: $page, limit: $limit, sort: $sort, sortKey: $sortKey, searchTerm: $searchTerm) {
            items {
                    id
                    adId
                    boatName,
                    boatType ${TypeCrud}
                    createdAt
                    isViewedBySeller
            }
            total
        }
    }
`

// SURVEYOR DASHBOARD
export const getSalesEngineBySurveyor = gql`
    query getSalesEngineBySurveyor($branchId: String!, $page: Int, $limit: Int, $searchTerm: String) {
        getSalesEngineBySurveyor(branchId: $branchId, page: $page, limit: $limit, searchTerm: $searchTerm) {
            items {
                id
                isViewedByShipper
                isViewedBySurveyor
                createdAt
                surveyorPaymentDueTime
                surveyorExceedUploadLimit
                isViewedByAgent
                qrCode
                boat {
                    id
                    images ${ImageType}
                    boatName
                    adId
                    yearBuilt
                    lengthInFt
                    trailer
                    engineManufacturer
                    boatStatus {
                        alias
                    }
                    boatType {
                        name
                    }
                    boatParking {
                        alias
                    }
                    address ${AddressType}
                    ownershipAttachment ${Media}
                }
                boatPayment
                salesEngineStatus
                distance
                stockQuantity
                surveyorAccepted
                surveyorRequestDetails
                surveyorPayment
                surveyorReport
                surveyDocument ${ImageType}
                requestedSurveyor
                surveySubmitted
                shipper {
                    id
                    isVerified
                }
                shipperPayment
                isAuctionSalesEngine
                shipperRequested {
                    price
                    proposalDocument ${ImageType}
                }
                buySellProcess
                buyer {
                    id
                    address ${AddressType}
                    firstName
                    lastName
                    mobileNumber
                    image ${ImageType}
                    isVerified
                }
                seller {
                    id
                    address ${AddressType}
                    firstName
                    lastName
                    mobileNumber
                    image ${ImageType}
                    isVerified
                    role {
                        id
                        role
                        aliasName
                    }
                }
                surveyor {
                    pricePerFt
                }
                agent {
                    id
                    address ${AddressType}
                    firstName
                    lastName
                    image ${ImageType}
                    isVerified
                }
                agentRequestDate
            }
            total
        }
    }
`
export const salesEngineSurveyorAcceptBuyerRequest = gql`
    mutation salesEngineSurveyorAcceptBuyerRequest($id: String!, $surveyorId: String!) {
        salesEngineSurveyorAcceptBuyerRequest(id: $id, surveyorId: $surveyorId)
            ${CreateSalesEngine}
    }
`
export const salesEngineSurveyorDeclineBuyerRequest = gql`
    mutation salesEngineSurveyorDeclineBuyerRequest($id: String!, $surveyorId: String!) {
        salesEngineSurveyorDeclineBuyerRequest(id: $id, surveyorId: $surveyorId)
            ${CreateSalesEngine}
}
`

export const createSurveyorReport = gql`
mutation createSurveyorReport($input: SurveyorReportInput!) {
    createSurveyorReport(input: $input){
        id
        comment
        report ${ImageType}
        boatVerifications ${ImageType}
        otherDocument ${ImageType}
        video ${ImageType}
        images ${ImageType}
        surveyorId{
            id
            firstName
            lastName
        }
    }
}
`

export const getSurveyorReport = gql`
query getSurveyorReportBySalesEngine($surveyorId: String!, $salesEngineId: String!){
    getSurveyorReportBySalesEngine(surveyorId: $surveyorId, salesEngineId: $salesEngineId) {
        id
        comment
        report ${Media}
        boatVerifications ${Media}
        otherDocument ${Media}
        video${Media}
        images${ImageType}
        surveyorId{
            id
            firstName
            lastName
        }
    }
}
`

// SHIPPER DASHBOARD
export const getSalesEngineByShipper = gql`
    query getSalesEngineByShipper($page: Int, $limit: Int, $searchTerm: String) {
        getSalesEngineByShipper(page: $page, limit: $limit, searchTerm: $searchTerm) {
            items {
                id
                isViewedByShipper
                isViewedBySurveyor
                createdAt
                isSold
                isViewedByAgent
                qrCode
                boat {
                    id
                    images ${ImageType}
                    boatName
                    adId
                    address ${AddressType}
                    yearBuilt
                    lengthInFt
                    trailer
                    engineManufacturer
                    boatStatus {
                        alias
                    }
                    boatType {
                        name
                    }
                    boatParking {
                        alias
                    }
                }
                boatPayment
                paymentInformation {
                    unitBoatPrice
                    subTotalPrice
                    totalPrice
                    serviceFees
                    paymentTransferFee
                    surveyorSubtotalPrice
                    surveyorTotalPrice
                    shipperSubtotalPrice
                    shipperTotalPrice
                    surveyorPaymentTransferFee
                    shipperPaymentTransferFee
                }
                distance
                stockQuantity
                surveyorAccepted
                surveyorRequestDetails
                surveyorPayment
                surveyorReport
                surveyDocument ${ImageType}
                requestedSurveyor
                surveySubmitted
                shipper {
                    id
                    isVerified
                }
                shipperPayment
                isAuctionSalesEngine
                shipmentLocation {
                    date
                    city
                    country
                    address
                    state
                    geometricLocation ${GeometricLocation}
                }
                shipperRequested {
                    price
                    proposalDocument ${ImageType}
                    shipper {
                        id
                        isVerified
                        firstName
                        lastName
                    }
                }
                buySellProcess
                buyer {
                    id
                    address ${AddressType}
                    firstName
                    lastName
                    mobileNumber
                    image ${ImageType}
                    isVerified
                }
                seller {
                    id
                    address ${AddressType}
                    firstName
                    lastName
                    mobileNumber
                    image ${ImageType}
                    isVerified
                    role {
                        id
                        role
                        aliasName
                    }
                }
                surveyor {
                    pricePerFt
                }
                agent {
                    id
                    address ${AddressType}
                    firstName
                    lastName
                    image ${ImageType}
                    isVerified
                }
                agentRequestDate
            }
            total
        }
    }
`

export const shipperAcceptShipmentRequest = gql`
    mutation salesEngineShipperAcceptShipmentRequest($id: String!) {
        salesEngineShipperAcceptShipmentRequest(id: $id) {
            id
            shipperRequested {
                price
                proposalDocument ${ImageType}
                declineSalesEngineRequest
            }
        }
    }
`

export const shipperDeclineShipmentRequest = gql`
    mutation salesEngineShipperDeclineShipmentRequest($id: String!, $shipperId: String!) {
        salesEngineShipperDeclineShipmentRequest(id: $id, shipperId: $shipperId) {
            id
            shipperRequested {
                price
                proposalDocument ${ImageType}
                declineSalesEngineRequest
            }
        }
    }
`

export const salesEngineShipperSubmitPrice = gql`
    mutation salesEngineShipperSubmitPrice($input: ShipperSubmitPriceInput!) {
        salesEngineShipperSubmitPrice(input: $input)
            ${CreateSalesEngine}
    }
`

// AGENT DASHBOARD
export const getSalesEngineByAgent = gql`
  query getSalesEngineByAgent($limit: Int, $page: Int, $manufacturerId: String, $searchTerm: String) {
    getSalesEngineByAgent(limit: $limit, page: $page, manufacturerId: $manufacturerId, searchTerm: $searchTerm) {
      items {
        id
        isViewedByShipper
        isViewedBySurveyor
        createdAt
        isSold
        isViewedByAgent
        boat {
          id
          address ${AddressType}
          images ${ImageType}
          boatName
          adId
          yearBuilt
          lengthInFt
          trailer
          engineManufacturer
          boatStatus {
            alias
          }
          boatType {
            name
          }
          boatParking {
            alias
          }
        }
        boatPayment
        distance
        stockQuantity
        surveyorAccepted
        surveyorRequestDetails
        surveyorPayment
        surveyorReport
        surveyDocument ${ImageType}
        requestedSurveyor
        surveySubmitted
        agentRequested
        shipper {
            id
            isVerified
        }
        shipperPayment
        isAuctionSalesEngine
        shipperRequested {
            price
            proposalDocument ${ImageType}
        }
        buySellProcess
        buyer {
          id
          address ${AddressType}
          firstName
          lastName
          image ${ImageType}
          isVerified
        }
        seller {
          id
          address ${AddressType}
          firstName
          lastName
          image ${ImageType}
          isVerified
            role {
                id
                role
                aliasName
            }
        }
        surveyor {
          pricePerFt
        }
        agent {
          id
          address ${AddressType}
          firstName
          lastName
          image ${ImageType}
          isVerified
        }
        agentRequestDate
      }
      total
    }
  }
`

//start shipment
export const startShipment = gql`
    mutation shipmentStart($id: String!) {
        shipmentStart(id: $id)
        ${CreateSalesEngine}
    }
`

//complete shipment
export const shipmentComplete = gql`
    mutation shipmentComplete($id: String!) {
        shipmentComplete(id: $id)
        ${CreateSalesEngine}
    }
`

export const createShipperDocument = gql`
mutation createShipperDocument($input: ShipperDocumentInput!) {
    createShipperDocument(input: $input) {
        id
        shipper {
            id
            firstName
            lastName
        }
        salesEngine {
            id
        }
        billOfLoading ${ImageType}
        certificateOfOrigin ${ImageType}
        insuranceCertificate ${ImageType}
        receivedForShipment ${ImageType}
        invoiceOfShipmentPayment ${ImageType}
        otherDocument ${ImageType}
    }
}
`

export const updateShipperDocument = gql`
mutation updateShipperDocument($input: ShipperDocumentInput!) {
    updateShipperDocument(input: $input) {
        id
        shipper {
            id
            firstName
            lastName
        }
        salesEngine {
            id
        }
        billOfLoading ${ImageType}
        certificateOfOrigin ${ImageType}
        insuranceCertificate ${ImageType}
        receivedForShipment ${ImageType}
        invoiceOfShipmentPayment ${ImageType}
        otherDocument ${ImageType}
    }
}
`

export const discardAgent = gql`
mutation discardAgentProcess($id: String!, $isCancelled: Boolean) {
    discardAgentProcess(id: $id, isCancelled: $isCancelled)
    ${CreateSalesEngine}
}
`

export const salesEngineAgents = gql`
query getSalesEngineAgents($salesEngineId: String!) {
    getSalesEngineAgents(salesEngineId: $salesEngineId) {
        items ${SalesEngineUser}
    }
}
`

export const salesEngineStopProcess = gql`
  mutation salesEngineStopProcess($id: String!) {
    salesEngineStopProcess(id: $id) {
      id
    }
  }
`

export const getPaymentReceipt = gql`
  query getPaymentReceipt($input: PrintReceiptInput!) {
    getPaymentReceipt(input: $input)
  }
`

export const getAllSalesEngineStaticContent = gql`
  query getAllSalesEngineStaticContent {
    getAllSalesEngineStaticContent {
      id
      content
      contentFor
      stepsFor
    }
  }
`

export const getSurveyDocument = gql`
  {
    getSurveyData {
      id
      title
      description
      document {
        id
        url
      }
    }
  }
`

export const salesEngineByBoat = gql`
query getSalesEngineByBoat($boatId: String!, $page: Int, $limit: Int, $searchTerm: String) {
    getSalesEngineByBoat(boatId: $boatId, page: $page, limit: $limit, searchTerm: $searchTerm) {
        items {
            id
            buySellProcess
            isViewedBySeller
            buyer {
                role{
                    role
                }
                firstName
                lastName
            }
            boat {
                id
                adId
                boatName,
                boatType ${TypeCrud}
            }
            createdAt
        }
        total
    }
}
`

export const buyerReviewSurveyorReport = gql`
mutation buyerReviewSurveyorReport($id: String!) {
    buyerReviewSurveyorReport(id: $id)
    ${CreateSalesEngine}
}
`

export const updateSurveyorReport = gql`
  mutation updateSurveyorReport($input: SurveyorReportInput!) {
    updateSurveyorReport(input: $input) {
      id
    }
  }
`

export const stepChangeSchema = gql`
    mutation stepChangeHandler($input: StepChangeInput!) {
        stepChangeHandler(input: $input)
            ${CreateSalesEngine}
    }`

export const getDealersManufacturers = gql`
  query getDealersManufacturers($page: Int, $limit: Int, $searchTerm: String) {
    getDealersManufacturers(page: $page, limit: $limit, searchTerm: $searchTerm) {
      items {
        id
        firstName
        lastName
      }
      total
    }
  }
`

export const getShipperDocuments = gql`
query getShipperDocuments($id: String!) {
    getShipperDocuments(id: $id) {
        id
        billOfLoading ${Media}
        certificateOfOrigin ${Media}
        insuranceCertificate ${Media}
        receivedForShipment ${Media}
        invoiceOfShipmentPayment ${Media}
        otherDocument ${Media}
    }
}
`

export const updateStockQuantityQuery = gql`
    mutation updateSalesEngineQuantity($input: UpdateQuantityInput!) {
        updateSalesEngineQuantity(input: $input)
            ${CreateSalesEngine}
    }
`

export const salesEngineDashboardVisit = gql`
  mutation salesEngineDashboardVisit($branchId: String, $manufacturerId: String) {
    salesEngineDashboardVisit(branchId: $branchId, manufacturerId: $manufacturerId) {
      message
      statusCode
    }
  }
`
export const salesEngineTransections = gql`
  query getSalesEngineTransactions($salesEngineId: String!, $transactionType: Float!) {
    getSalesEngineTransactions(salesEngineId: $salesEngineId, transactionType: $transactionType) {
      id
      amount
      transactionType
      isRefunded
      isRefundable
      paymentId
    }
  }
`

export const getCommissionsQuery = gql`
    query getCommissions {
        getCommissions {
            Boat_Service_Fee
            Rent_Service_Fee
        }
    }
`
