import React, { PureComponent } from 'react'

import SalesEngineDashboardCard from './SalesEngineDashboardCard'
import { viewBoatHandler } from '../../helpers/boatHelper'
import { Loader } from '../loader/loader'
import { connect } from 'react-redux'
import { SET_ONLINE_USERS } from '../../redux/actionTypes'
import { getId, getOnlineUsers } from '../../util/utilFunctions'

class SalesEngineDashboardCardListingComp extends PureComponent {
  goToBoat = boat => {
    viewBoatHandler(boat)
  }

  checkSurveyorTimeLimit = salesEngine => {
    const { branchId = '' } = this.props

    const { surveyorExceedUploadLimit } = salesEngine
    return !!(surveyorExceedUploadLimit?.length && surveyorExceedUploadLimit.includes(branchId))
  }

  componentDidMount() {
    const ids = [];
    this.props.salesEngines?.forEach(s => ids.push(getId(s?.buyer)));
    this.props.salesEngines?.forEach(s => ids.push(getId(s?.seller)));
    getOnlineUsers([...new Set(ids)], this.props.setOnlineUsers);
  }

  render() {
    const {
      salesEngines,
      accept,
      decline,
      isShipper,
      openModal,
      startShipment,
      completeShipment,
      currentUserId,
      submitReport,
      getDocumentUrl,
      isLoading,
      getModulePdf,
      surveyDocument,
      className = '',
      dealers,
      isDealers = false,
      isAgent,
      history,
      branchId = '',
      location,
      acceptRequestByAgent,
      discardAgentProcess,
      isSurveyor,
      onlineUsers
    } = this.props

    if (isLoading) {
      return <Loader isPageLoader />
    }

    return (
      <>
        {salesEngines && salesEngines.length > 0 ? (
          salesEngines.map((salesEngine, index) => {
            const isLimitExceeed = isSurveyor && this.checkSurveyorTimeLimit(salesEngine)
            const isInRequest = isSurveyor ? salesEngine.requestedSurveyor?.includes(branchId) || salesEngine?.surveyor : true

            return (
              <div>
                {isInRequest && (
                  <div className={`select-boat-information-sales-engine ${className}`} key={salesEngine.id}>
                    <SalesEngineDashboardCard
                      index={index}
                      dealers={dealers}
                      isDealers
                      isAgent={isAgent}
                      salesEngine={salesEngine}
                      onlineUsers={onlineUsers}
                      isShipper={isShipper}
                      openModal={openModal}
                      accept={accept}
                      decline={decline}
                      completeShipment={completeShipment}
                      getDocumentUrl={getDocumentUrl}
                      startShipment={startShipment}
                      history={history}
                      currentUserId={currentUserId}
                      submitReport={submitReport}
                      goToBoat={this.goToBoat}
                      getModulePdf={getModulePdf}
                      surveyDocument={surveyDocument}
                      branchId={branchId}
                      location={location}
                      acceptRequestByAgent={acceptRequestByAgent}
                      discardAgentProcess={discardAgentProcess}
                      isSurveyor={isSurveyor}
                    />
                  </div>
                )}
                {isLimitExceeed && (
                  <div key={`${salesEngine.id}_limit_exceed`} className={`select-boat-information-sales-engine ${className}`}>
                    <SalesEngineDashboardCard
                      index={index}
                      dealers={dealers}
                      isDealers
                      isAgent={isAgent}
                      salesEngine={salesEngine}
                      onlineUsers={onlineUsers}
                      isShipper={isShipper}
                      openModal={openModal}
                      accept={accept}
                      decline={decline}
                      completeShipment={completeShipment}
                      getDocumentUrl={getDocumentUrl}
                      startShipment={startShipment}
                      history={history}
                      currentUserId={currentUserId}
                      submitReport={submitReport}
                      goToBoat={this.goToBoat}
                      getModulePdf={getModulePdf}
                      surveyDocument={surveyDocument}
                      branchId={branchId}
                      location={location}
                      acceptRequestByAgent={acceptRequestByAgent}
                      discardAgentProcess={discardAgentProcess}
                      isSurveyor={isSurveyor}
                      isLimitExceeed={isLimitExceeed}
                      isNotInRequest={!isInRequest && !salesEngine?.surveyor}
                    />
                  </div>
                )}
              </div>
            )
          })
        ) : (
          <div className="no--record-found">No record Found</div>
        )}
      </>
    )
  }
}

const mapStateToProps = state => ({ onlineUsers: state.chatReducer.onlineUsers })
const mapDispatchToProps = dispatch => ({ setOnlineUsers: users => dispatch({ type: SET_ONLINE_USERS, payload: users }) })

export const SalesEngineDashboardCardListing = connect(mapStateToProps, mapDispatchToProps)(SalesEngineDashboardCardListingComp)
