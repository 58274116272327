import React from 'react'

// dialog
import { Grid, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@material-ui/core'

import { Row, Col } from 'react-bootstrap'

import './EstimateCost.scss'
import { defaultImage } from '../../util/enums/enums'
import { CommonModal } from '../modal/commonModal'
import { ButtonComponent } from '../form/Button'

const EmailVerificationDialog = props => {
  const { isOpen, handleClose, sendEmail } = props

  return (
    <CommonModal open={isOpen} close={handleClose} title="Email Verification" className="email--verification--dialogue">
      <>
        <div className="email--verification--desc--div">
          <div>
            <p className="email--verification--desc">You need to verify your email address to activate your account. We've sent you an email with instructions on how to verify your email. Haven't received the verification email yet?</p>
          </div>
          {/* <div>
            <p className="email--verification--desc">An email with instructions to verify your email has been sent to you.</p>
          </div>
          <div>
            <p className="email--verification--desc">Haven't received verification email yet?</p>
          </div> */}
        </div>
        <div className="d-flex justify-content-between">
          <ButtonComponent className="email--verification--resend--btn h-auto as--view--outline" onClick={sendEmail}>
            RE-SEND EMAIL
          </ButtonComponent>

          <ButtonComponent className="email--verification--resend--btn h-auto as--cancel--outline" onClick={handleClose}>
            CANCEL
          </ButtonComponent>
        </div>
      </>
    </CommonModal>
  )
}

export default EmailVerificationDialog
