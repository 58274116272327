import Post from '../post'
import compStyles from '../../styles/components.module.scss'
import styles from './posts.module.scss'

export default function Posts({ items, loading }) {
  return (
    <div className={styles.postsWrp}>
      {items.map(data => (
        <>
          <Post key={data._id} data={data} />
          <div className={styles.postSep} />
        </>
      ))}

      {loading && (
        <div className={compStyles.spinnerWrp}>
          <span className={compStyles.spinner} />
        </div>
      )}
    </div>
  )
}
