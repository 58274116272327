import React, { Component } from 'react'
import { SnackbarContent } from '@material-ui/core'
import ErrorIcon from '@material-ui/icons/Error'

/**
 * type: alert-info, alert-danger, alert-warning
 */
class ErrorComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    const { errors, type, withoutComma } = this.props

    let errorsArray = typeof errors !== 'string' && errors && errors.length && errors[0].message.split(',')

    let singleError = typeof errors === 'string' ? errors : withoutComma && errors?.length ? errors[0].message : null

    return (
      <>
        {errors && (
          <>
            {typeof singleError === 'string' && (
              <p className={`${type} mt-2`}>
                <ErrorIcon /> {singleError}
              </p>
            )}
            {errorsArray && errorsArray.length && !singleError && (
              <div className={`alert ${type} f-14`} role="alert">
                {errorsArray.map((error, index) => (
                  <p className={`${type} mb-0`} key={index}>
                    <ErrorIcon /> {error}
                  </p>
                ))}
              </div>
            )}
          </>
        )}
      </>
    )
  }
}
export default ErrorComponent
ErrorComponent.defaultProps = {
  type: 'alert-danger',
  withoutComma: false,
}
