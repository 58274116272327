import { createContext, useCallback, useContext, useState } from 'react'
import _ from 'lodash'

const SMDbCtx = createContext()
export default SMDbCtx

export const SMDbProvider = ({ children }) => {
  const [db, setDb] = useState({})

  const get = useCallback((id, key = null) => (key ? _.get(db[id], key) : db[id]), [db])

  const set = useCallback((id, key, val) => {
    setDb(db => {
      db[id] = db[id] || {}
      _.set(db[id], key, val)
      return db
    })
  }, [])

  const merge = useCallback((id, val) => {
    setDb(db => {
      db[id] = db[id] || {}
      _.merge(db[id], db[id], val)
      return db
    })
  }, [])

  return <SMDbCtx.Provider value={[get, set, merge]}>{children}</SMDbCtx.Provider>
}

export const useSMDb = () => useContext(SMDbCtx)
