import React, { useState, useEffect, useContext } from 'react'
import { Map, GoogleApiWrapper, Polygon, Marker } from 'google-maps-react'
import { Grid, Typography, Card, CardContent } from '@material-ui/core'
import { ErrorMessage } from 'formik'
import axios from 'axios'
import Geosuggest from 'react-geosuggest'

import { getGeoLocation } from '../../helpers/geoLocation'
import withProps from '../../hoc/withProps'
import UserContext from '../../UserContext'
import { countryValidationMessage } from '../../util/enums/enums'
import '../../styles/common.scss'
import { getLocalStorageItem } from '../../helpers/storageHelper'
import { searchBy } from '../../helpers/currencyConverterHelper'
import { getAddress } from '../../helpers/jsxHelper'
import { envConfig } from '../../config'
import { googleDirectionUrl } from '../../helpers/boatHelper'

const GoogleMap = props => {
  const { currentUser } = useContext(UserContext)

  const { address } = currentUser

  const {
    commonAddress: { country: userCountry },
  } = getAddress(address)

  const [countryError, setCountryError] = useState(false)

  let markers = []

  const {
    id,
    isError,
    columnName,
    latLng,
    width,
    height,
    placeHolder,
    fetch,
    google,
    isUpdate,
    isCloseBtn,
    onClose,
    isDisplayLocationOnly,
    isShipperLocation,
    pathPoints,
    isMapControl,
    isRegister,
    crossCountryAllowed,
    countryEdit,
    shipmentMap,
    shipmentAddressLabel,
    isRedirectToMap,
    geometricLocation,
    gotoDirectionText,
  } = props

  const [mapStyle, setMapStyle] = useState({
    width: width ? `${width}%` : '50%',
    height: height ? `${height}vh` : '50vh',
  })

  const [mapInfo, setMapInfo] = useState()

  const [mapLatLng, setMapLatLng] = useState(latLng)

  const getLatLng = res => {
    setMapLatLng(res)
  }

  useEffect(() => {
    if (getLocalStorageItem('countryCode')) {
      var [{ geo }] = searchBy.search(getLocalStorageItem('countryCode'))
    }
    !isUpdate && (geo ? setMapLatLng({ lat: geo.latitude, lng: geo.longitude }) : getGeoLocation(getLatLng))

    isUpdate && fetchPlaces()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getInformation = places => {
    let regex = /^[a-zA-Z0-9$@$!%*?&#^-_. +-s]+$/

    const filteredPlaces = places.filter(item => {
      return new RegExp(regex).test(item.formatted_address)
    })

    const place = filteredPlaces[0]

    // let result = {
    //     address1: '',
    //     street: '',
    //     city: '',
    //     state: '',
    //     country: '',
    //     zip: '',
    // }
    let result = {}
    // result.placeName = place.name
    if (place && place.address_components && place.address_components.length) {
      result.address1 = place.formatted_address
      result.latitude =
        typeof place.geometry.location.lat === 'function' ? place.geometry.location.lat() : place.geometry.location.lat
      result.longitude =
        typeof place.geometry.location.lng === 'function' ? place.geometry.location.lng() : place.geometry.location.lng
      place.address_components.map(component => {
        const addressType = component.types[0]
        // const matchCountry = countryEdit && isUpdate?.length ? isUpdate[0].country !== component.long_name : getLocalStorageItem('countryCode') !== component.short_name
        // if (!crossCountryAllowed && addressType === 'country' && matchCountry) {
        //     setCountryError(true)
        //     result = { address1: "" }
        //     markers.forEach(function (marker) {
        //         marker.setMap(null)
        //     });
        //     return null
        // }
        switch (addressType) {
          case 'street_number':
            result.street = component.long_name
            break
          // case 'administrative_area_level_2':
          //     result.city = component.long_name
          //     break;
          case 'locality':
            result.city = component.long_name
            break
          case 'administrative_area_level_1':
            result.state = component.long_name
            break
          case 'country':
            result.country = component.long_name
            break
          case 'postal_code':
            result.zip = component.long_name
            break
          default:
            break
        }

        //fallback for grabbing city value if it's not coming from locality
        if (!result.city && addressType === 'administrative_area_level_2') {
          result.city = component.long_name
        }

        result.geometricLocation = {
          coordinates: [result.longitude, result.latitude],
        }
      })
    }
    const { latitude, longitude, ...filteredResult } = result
    fetch({ ...filteredResult })
    setMapInfo({ ...filteredResult })
  }

  const fetchPlaces = () => {
    if (isUpdate && latLng && latLng.lat && latLng.lng) {
      onMarkerDragEnd({ latLng: { lat: latLng.lat, lng: latLng.lng } })
    }
  }

  const onMarkerDragEnd = (coord, index) => {
    setCountryError(false)
    const { latLng } = coord
    const lat = typeof latLng.lat === 'function' ? latLng.lat() : latLng.lat
    const lng = typeof latLng.lng === 'function' ? latLng.lng() : latLng.lng
    setMapLatLng({ lat, lng })
    getPlace(lat, lng)
  }

  const getPlace = async (lat, lng) => {
    var latLng = parseFloat(lat) + ',' + parseFloat(lng)
    const { data } = await axios.get(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latLng}&key=${envConfig.MAP_API_KEY}&language=en`
    )
    if (data.status === 'OK') {
      getInformation(data.results)
    } else {
      console.error('not working')
    }
  }

  const handleSelectPlace = event => {
    setCountryError(false)
    if (event) {
      onMarkerDragEnd({ latLng: { lat: event.location.lat, lng: event.location.lng } })
    }
  }

  const mapInfoDisplay = () => (
    <>
      <Grid item xs={6} className="add-map-field--first">
        <Typography component="div" className="map-field add-map-field">
          <label className="map-label">Country:</label>
          <div className="map-info-with-search">{mapInfo.country}</div>
        </Typography>
      </Grid>
      <Grid item xs={6} className="add-map-field--first">
        <Typography component="div" className="map-field add-map-field">
          <label className="map-label">State:</label>
          <div className="map-info-with-search">{mapInfo.state}</div>
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography component="div" className="map-field add-map-field">
          <label className="map-label">City: </label>
          <div className="map-info-with-search">{mapInfo.city}</div>
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography component="div" className="map-field add-map-field">
          <label className="map-label">Postal Code:</label>
          <div className="map-info-with-search">{mapInfo.zip}</div>
        </Typography>
      </Grid>
      <Grid item xs={props.isFullGrid ? (isRegister ? 12 : 11) : 6}>
        <Typography component="div" className="map-field add-map-field">
          <label className="map-label full-map-address"> Full Address:</label>
          <div className="map-info-with-search">{mapInfo.address1}</div>
        </Typography>
      </Grid>
    </>
  )
  const shipmentMapInfoDisplay = () => (
    <>
      <Grid item xs={12} className="add-map-field--first map--input--section">
        <label className="map-label shipment--map--label">Country:</label>
        <input name="country" className="form-control" placeholder="Shipment Country" value={mapInfo?.country} />
      </Grid>
      <Grid item xs={12} className="map--input--section">
        <label className="map-label shipment--map--label">City:</label>
        <input name="city" className="form-control" placeholder="Shipment City" value={mapInfo?.city} />
      </Grid>
      <Grid item xs={12} className="map--input--section">
        <label className="map-label shipment--map--label">Postal Code:</label>
        <input name="postalCode" className="form-control" placeholder="Shipment Zip Code" value={mapInfo?.zip || ''} />
      </Grid>
    </>
  )
  const geoSuggestCommon = () => (
    <>
      <Geosuggest
        name={columnName}
        suggestItemClassName="autosuggest-places"
        onSuggestSelect={handleSelectPlace}
        placeholder={placeHolder || 'Search Box'}
        initialValue={(mapInfo && mapInfo.address1) || ''}
        // country={!crossCountryAllowed && getLocalStorageItem('countryCode') ? getLocalStorageItem('countryCode').toLowerCase() : null}
        types={['establishment', 'geocode']}
        // placeDetailFields={}
      />
    </>
  )
  const countryWithError = () => (
    <>
      {countryError && (
        <span className={'error-message err-msg-' + columnName + ' w-100'}>{countryValidationMessage(userUpdateCountry)}</span>
      )}
      {props && !countryError && columnName && isError && (
        <span className={'error-message w-100 err-msg-' + columnName + ' sign--up--map'}>{isError}</span>
      )}
    </>
  )
  const mainMapSection = () => (
    <>
      <div style={mapStyle}>
        <Map
          className={`${isRedirectToMap ? 'map--full--screen--icon' : ''}`}
          google={google}
          zoom={8}
          minZoom={3}
          style={mapStyle}
          center={mapLatLng}
          disableDefaultUI={isMapControl}
          zoomControl
          zoomControlOptions={{
            position: google.maps.ControlPosition.RIGHT_BOTTOM,
          }}
          streetViewControl
          streetViewControlOptions={{
            position: google.maps.ControlPosition.RIGHT_BOTTOM,
          }}
        >
          {!isShipperLocation ? (
            <Marker position={mapLatLng} draggable onDragend={(t, map, coord) => onMarkerDragEnd(coord)} />
          ) : (
            <Polygon
              paths={pathPoints}
              strokeColor="#0000FF"
              strokeOpacity={0.8}
              strokeWeight={2}
              fillColor="#0000FF"
              fillOpacity={0.35}
            />
          )}
        </Map>
      </div>
    </>
  )

  const userUpdateCountry = isUpdate?.length ? isUpdate[0].country : getLocalStorageItem('country')
  return (
    <>
      <div className={shipmentMap ? 'map-design shipment--map--design' : 'map-design'}>
        <Card className="map-card-div map-border map-div">
          {isCloseBtn && (
            <div className="d-flex justify-content-end pt-3 map-close-btn-position cursor-pointer" onClick={onClose}>
              <img loading="lazy" src={require('../../components/footer/close.png')} className="register-map-close-btn" alt="Close icon" />
            </div>
          )}
          {/* start */}
          {shipmentMap ? (
            <CardContent className="map-card-content shipment--map--section">
              <Grid container>
                <Grid item xs={4} className="map--left--section">
                  {!isDisplayLocationOnly && !isShipperLocation && (
                    <>
                      <Grid
                        item
                        xs={12}
                        classes={{
                          item: (props && !countryError && isError) || countryError ? 'no--margin' : '',
                        }}
                        className="map-search-place-div mb-0 signup--map--input"
                      >
                        {shipmentAddressLabel && (
                          <label className="map-label shipment--map--label">{shipmentAddressLabel}</label>
                        )}

                        {geoSuggestCommon()}
                      </Grid>

                      {countryWithError()}
                    </>
                  )}

                  {(mapInfo || shipmentMap) && !countryError && shipmentMapInfoDisplay()}
                </Grid>
                <Grid item xs={8} className="position-relative map-location-show map--right--section">
                  <div className="shipment--boat--title">
                    <span>Where would you like to ship your boat?</span>
                  </div>
                  <div className="position-relative right--section--main-map">
                    {isRedirectToMap && googleDirectionUrl(geometricLocation) && (
                      <div className="search--as--move--map-div-ui search--as--move--section">
                        <a
                          className="inner--map-direction shipment--inner--map-direction"
                          href={googleDirectionUrl(geometricLocation)}
                          target="_blank"
                        >
                          {gotoDirectionText}
                        </a>
                      </div>
                    )}

                    {mainMapSection()}
                  </div>
                  {props.buttonContent}
                </Grid>
              </Grid>
            </CardContent>
          ) : (
            <CardContent className="map-card-content">
              <Grid container>
                {!isDisplayLocationOnly && !isShipperLocation && (
                  <>
                    <Grid
                      item
                      xs={12}
                      classes={{
                        item: (props && !countryError && isError) || countryError ? 'no--margin' : '',
                      }}
                      className="map-search-place-div mb-0 signup--map--input"
                    >
                      {geoSuggestCommon()}
                    </Grid>
                    {countryWithError()}
                  </>
                )}

                {mapInfo && !countryError && mapInfoDisplay()}

                <Grid item xs={12} className="position-relative map-location-show">
                  {mainMapSection()}
                </Grid>
              </Grid>
            </CardContent>
          )}
          {/* end */}
        </Card>
      </div>
    </>
  )
}

export default GoogleApiWrapper(props => ({
  apiKey: envConfig.MAP_API_KEY,
  isFullGrid: false,
  language: 'en',
}))(withProps(GoogleMap))

GoogleMap.defaultProps = {
  isMapControl: true,
  isRegister: false,
  crossCountryAllowed: false,
  isRedirectToMap: false,
  gotoDirectionText: 'Go to direction',
  geometricLocation: null,
}
