export const cancelHandler = history => {
  history.goBack()
}

export const objectToQuerystring = obj => {
  return Object.keys(obj).reduce((str, key, i) => {
    let delimiter, val

    delimiter = i === 0 ? '?' : '&'

    key = encodeURIComponent(key)
    val = obj[key] && encodeURIComponent(obj[key])

    return [str, delimiter, key, '=', val].join('')
  }, '')
}

export const urlParamsToObject = urlParams => {
  let values = {}

  for (const [key, value] of urlParams.entries()) {
    values = { ...values, [key]: value }
  }

  return values
}

export const redirectRouteHandler = (route) => {
  window.location.href = route
}

export const queryStringToObject = (query = '') => {
  if (query) {
    const queryResult = decodeURIComponent(query).slice(1).split('&')

    return queryResult.reduce((accum, result) => {
      const [key, value] = result.split('=')

      return {
        ...accum,
        [key]: value,
      }
    }, {})
  }
  return
}

