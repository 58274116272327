import React from 'react'
import '../footer/footer.scss'
import { Grid } from '@material-ui/core'
// import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container'
import { Link } from 'react-router-dom'
import { Layout } from '../layout/layout'
import { careersJobImages } from '../../util/enums/enums'
import { NextIconCarrer } from '../styleComponent/styleComponent'

class FooterContent extends React.Component {
  render() {
    return (
      <>
        <Layout>
          <div className="contactFooter-bg dynamic-page-container">
            <Container maxWidth="lg" className="contact--container--section contact--header--container">
              <div className="h-100 contact-title footerContact-container">
                <div className="lines">
                  <h1 className="line1 mb-0" style={{ padding: '20px' }}>
                    Contact Us
                  </h1>
                </div>
              </div>
            </Container>

            <div className="footer--container--content--section">
              {/* <div className="inner"> */}
              <Container maxWidth="lg" className="contact--container--section">
                {/* <Grid container spacing={3}>

                                    <Grid item xs>
                                        <div className="contactDetail-box bg-gray pb-40 position-relative h-100 contact--section--main--info--div">
                                            <span className="contact-us-page-title font-weight-400">
                                               Feedback
                                             </span>
                                            <p className="text-justify font-14 contactDetail-style contact--detail--section--style">
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt  ut labore et
                                             </p>
                                            <p className="contact-link">
                                                <Link to="/">
                                                    <h5 className="contact--bottom--link--text">Feedback</h5>
                                                </Link>
                                            </p>
                                        </div>
                                    </Grid>

                                    <Grid item xs>
                                        <div className="contactDetail-box bg-gray pb-40 position-relative h-100 contact--section--main--info--div">
                                        <span className="contact-us-page-title font-weight-400">
                                                News and Multimedia
                                             </span>
                                            <p className="text-justify font-14 contactDetail-style contact--detail--section--style">
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
                                             </p>
                                            <p className="contact-link">
                                                <Link to="/">
                                                    <h5 className="contact--bottom--link--text">News</h5>
                                                </Link>
                                            </p>
                                        </div>
                                    </Grid>
                                    <Grid item xs>
                                        <div className="contactDetail-box bg-gray pb-40 position-relative h-100 contact--section--main--info--div">
                                             <span className="contact-us-page-title font-weight-400">
                                                Partner Relation
                                             </span>
                                            <p className="text-justify font-14 contactDetail-style contact--detail--section--style">
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
                                             </p>
                                            <p className="contact-link">
                                                <Link to="/">
                                                    <h5 className="contact--bottom--link--text">Relation</h5>
                                                </Link>
                                            </p>
                                        </div>
                                    </Grid>
                                </Grid> */}
                {/* <Grid container spacing={3}>
                  <Grid item xs>
                    <div className="contactDetail-box bg-gray pb-40 position-relative h-100 contact--section--main--info--div">
                      <span className="contact-us-page-title font-weight-400">Suggestions</span>
                      <p className="text-justify font-14 contactDetail-style contact--detail--section--style">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                        dolore magna aliqua. Ut enim ad minim veniam
                      </p>
                      <p className="contact-link">
                        <Link to="/contact-us-suggestions">
                          <h5 className="contact--bottom--link--text">Suggestions</h5>
                        </Link>
                      </p>
                    </div>
                  </Grid>
                  <Grid item xs>
                    <div className="contactDetail-box bg-gray pb-40 position-relative h-100 contact--section--main--info--div">
                      <span className="contact-us-page-title font-weight-400">Customer Service</span>
                      <p className="text-justify font-14 contactDetail-style contact--detail--section--style">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
                      </p>
                      <p className="contact-link">
                        <Link to="/contact-us-services">
                          <h5 className="contact--bottom--link--text">Service</h5>
                        </Link>
                      </p>
                    </div>
                  </Grid>
                  <Grid item xs>
                    <div className="contactDetail-box bg-gray pb-40 position-relative h-100 contact--section--main--info--div">
                      <span className="contact-us-page-title font-weight-400">Submit a complaint</span>
                      <p className="text-justify font-14 contactDetail-style contact--detail--section--style">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
                      </p>
                      <p className="contact-link">
                        <Link to="/contact-us-complaint">
                          <h5 className="contact--bottom--link--text">Complaint</h5>
                        </Link>
                      </p>
                    </div>
                  </Grid>
                </Grid> */}

                <div className="job-card-grid-wrapper">
                  <Grid container spacing={3} className="">
                    <Grid item sm={12} xs={12} lg={4} className="contactus-page-grid-first">
                      <Link to="/contact-us-suggestions" className="job-card-grid" style={{ width: '100%' }}>
                        <div className="job-grid-title-value">Suggestions</div>
                        <div className="job-grid-label-value">
                          <div className="job-grid-value">
                            Share your valuable ideas and suggestions to help us improve. We appreciate your feedback!
                          </div>
                        </div>
                        {/* <Link to="/contact-us-suggestions">
                          <NextIconCarrer className="job-next-icon left-icon-img" img={careersJobImages[0]} />
                        </Link> */}
                      </Link>
                    </Grid>

                    <Grid item sm={12} xs={12} lg={4} className="contactus-page-grid-first">
                      <Link to="/contact-us-services" className="job-card-grid" style={{ width: '100%' }}>
                        <div className="job-grid-title-value">Customer Service</div>
                        <div className="job-grid-label-value">
                          <div className="job-grid-value">
                            Need assistance or have a question? Our customer service team is here to provide you with timely support and solutions.
                          </div>
                        </div>
                        {/* <Link to="/contact-us-services">
                          <NextIconCarrer className="job-next-icon left-icon-img" img={careersJobImages[1]} />
                        </Link> */}
                      </Link>
                    </Grid>

                    <Grid item sm={12} xs={12} lg={4} className="contactus-page-grid-first">
                      <Link to="/contact-us-complaint" className="job-card-grid" style={{ width: '100%' }}>
                        <div className="job-grid-title-value">Submit a Complaint</div>
                        <div className="job-grid-label-value">
                          <div className="job-grid-value">
                            If you've encountered an issue, please let us know. Submit your complaint, and we'll work to resolve it promptly. Your feedback matters to us.
                          </div>
                        </div>
                        {/* <Link to="/contact-us-complaint">
                          <NextIconCarrer className="job-next-icon left-icon-img" img={careersJobImages[2]} />
                        </Link> */}
                      </Link>
                    </Grid>
                  </Grid>
                </div>
              </Container>
            </div>
          </div>
        </Layout>
      </>
    )
  }
}

export default FooterContent
