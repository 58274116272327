import { put, takeLatest, all } from 'redux-saga/effects'

import {
  GET_MODULE_PDF,
  GET_MODULE_PDF_SUCCESS,
  GET_MODULE_PDF_FAILURE,
  CLEAR_PDF_FLAG,
  ERROR_NOTIFICATION_MESSAGE,
} from '../actionTypes'
import { query } from './apiHelper'
import { getModulePdf } from '../../graphql/pdfSchema'
import { dynamicError } from '../../util/enums/notificationEnum'

function getModulePdfApi(input) {
  return query(getModulePdf, { input })
}

function* getModulePdfData(action) {
  const { filenamePrefix, zipFilename, ...payload } = action.payload

  try {
    const res = yield getModulePdfApi(payload)

    if (res.errors?.length) {
      yield put({ type: GET_MODULE_PDF_FAILURE, e: res.errors })
      yield put({ type: ERROR_NOTIFICATION_MESSAGE, payload: dynamicError(res.errors) })
    } else if (res.data) {
      yield put({
        type: GET_MODULE_PDF_SUCCESS,
        payload: { ...res, filenamePrefix, zipFilename },
      })
    }
  } catch (e) {
    yield put({ type: GET_MODULE_PDF_FAILURE, e })
  }
  yield put({ type: CLEAR_PDF_FLAG })
}

function* getModulePdfSaga() {
  yield takeLatest(GET_MODULE_PDF, getModulePdfData)
}

export default function* pdfSaga() {
  yield all([getModulePdfSaga()])
}
