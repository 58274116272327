import React, { Component } from 'react'
import { connect } from 'react-redux'

import { PaginationBar } from '../../components'
import { pagination } from '../../util/enums/enums'

import { urlParamsToObject } from '../../helpers/routeHelper'
import { searchYachtService } from '../../redux/actions'
import BoatListingsWithMap from '../../components/gridComponents/BoatListingsWithMap'
import { cityCountryNameFormatter } from '../../helpers/jsxHelper'

class SearchBoatServices extends Component {
  render() {
    const { searchedYachtServices, totalSearchedYachtServices, searchYachtService, isPageLoader, location } = this.props

    const urlParams = new URLSearchParams(location.search)
    const values = urlParamsToObject(urlParams)
    const { serviceAlias, ...otherValues } = values
    const place = cityCountryNameFormatter(values?.city, values?.country)
    const title = `${totalSearchedYachtServices || 0} ${serviceAlias || 'Boat Services'} available in ${place || 'our market'}`

    return (
      <BoatListingsWithMap
        isBoatService
        boatsTypeCount={totalSearchedYachtServices || 0}
        isPageLoader={isPageLoader}
        boatsType={title}
        viewService={id => this.viewService(id)}
        boats={searchedYachtServices}
        action={searchYachtService}
        value={otherValues}
      />
    )
  }
}

const mapStateToProps = state => ({
  searchedYachtServices: state.boatServiceReducer.searchedYachtServices,
  totalSearchedYachtServices: state.boatServiceReducer.totalSearchedYachtServices,
  isPageLoader: state.boatServiceReducer.isPageLoader,
})

const mapDispatchToProps = dispatch => ({
  searchYachtService: data => dispatch(searchYachtService(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(SearchBoatServices)
