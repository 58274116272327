import { createContext, useCallback, useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { merge } from 'lodash'
import { enqueueSnackbar } from 'notistack'
import { getId } from '../../../util/utilFunctions'
import { getUser as qGetUser } from '../lib/queries'
import { useTopLoader } from './top-loader-ctx'

const AuthCtx = createContext()
export default AuthCtx

export const AuthProvider = ({ children }) => {
  const authLoading = useSelector(state => state.loginReducer.isUserLoading)
  const currentUser = useSelector(state => state.loginReducer.currentUser)

  const topLoader = useTopLoader()

  const [user, setUser] = useState(null)

  useEffect(() => {
    if (authLoading) {
      topLoader.show()
    } else {
      topLoader.hide()
    }
  }, [authLoading])

  useEffect(() => {
    if (getId(currentUser)) {
      ;(async () => {
        topLoader.show()

        try {
          const res = await qGetUser(getId(currentUser))
          setUser(user => merge({}, user, res))
        } catch (err) {
          enqueueSnackbar(err)
        }

        topLoader.hide()
      })()
    } else {
      setUser(null)
    }
  }, [currentUser])

  return (
    <AuthCtx.Provider
      value={{
        isLoggedIn: !!getId(user),
        user,
      }}
    >
      {children}
    </AuthCtx.Provider>
  )
}

export const useAuth = () => useContext(AuthCtx)
