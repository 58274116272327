import React, { Component } from 'react'
import { Grid, CardContent, Card, CardActions, Box } from '@material-ui/core'
import 'react-dates/initialize'
import { DateRangePicker, SingleDatePicker } from 'react-dates'
import AddIcon from '@material-ui/icons/Add'
import RemoveIcon from '@material-ui/icons/Remove'
import 'react-dates/lib/css/_datepicker.css'

import CarousalFormBoatTitle from '../carouselComponent/carousalFormBoatTitle'
import { getSelectedTrip, getBoatPassengerText } from '../../helpers/jsxHelper'
import SearchComplete from '../map/SearchComplete'
import UserContext from '../../UserContext'
import { objectToQuerystring } from '../../helpers/routeHelper'
import { NotifyModal } from '../modal/notifyModal'
import { notifyMeEnums, rentBoatTripForCard } from '../../util/enums/enums'
import { renderSelectOptions } from '../../helpers/string'
import SingleSelect from '../helper/singleSelect'
import '../../assets/css/component/StaticComponent/rentCard.scss'
class RentCard extends Component {
  state = {
    clicks: 0,
    Show: true,
    startDate: '',
    endDate: '',
    focusedInput: '',
    boatRentType: '',
    boatType: '',
    place: '',
    // startTime: "",
    // endTime: "",
    focused: false,
    openDialog: false,
    selectedId: '', // TEMP SOLUTION
  }

  static contextType = UserContext

  IncrementItem = () => {
    this.setState(prevState => {
      if (prevState.clicks < 200) {
        return {
          clicks: prevState.clicks + 1,
        }
      } else {
        return this.state.clicks
      }
    })
  }

  DecreaseItem = () => {
    this.setState(prevState => {
      if (prevState.clicks > 0) {
        return {
          clicks: prevState.clicks - 1,
        }
      } else {
        return this.state.clicks
      }
    })
  }

  getCountText = (tripList, boatType) => {
    const selectedTrip = getSelectedTrip(boatType)
    return getBoatPassengerText(selectedTrip)
  }

  handleSearch = () => {
    const { startDate, endDate, place, boatRentType, boatType, clicks } = this.state
    const { history } = this.context
    const sDate = startDate && startDate.format('DD-MM-YYYY')
    const eDate = endDate && endDate.format('DD-MM-YYYY')

    // const sTime = startTime && moment(startTime).toISOString()
    // const eTime = endTime && moment(endTime).toISOString()

    const values = {
      country: place.country,
      city: place.city,
      tripType: boatRentType.value,
      type: boatType,
      total: clicks,
      startDate: sDate,
      endDate: eDate,
      // startTime: sTime,
      // endTime: eTime
    }
    const queryResult = objectToQuerystring(values)

    history.push(`/search-rent-boats${queryResult && queryResult}`, values)
  }

  modalHandler = e => {
    e && e.preventDefault()
    this.setState(preState => ({ openDialog: !preState.openDialog }))
  }

  notifyMeHandler = formValues => {
    const { notifyMe } = this.props
    const { startDate, endDate, place, boatRentType, boatType, clicks } = this.state
    const sDate = startDate && startDate.toDate().toISOString()
    const eDate = endDate && endDate.toDate().toISOString()

    const values = {
      country: place.country,
      city: place.city,
      tripType: boatRentType.value,
      type: boatType,
      total: clicks,
      startDate: sDate,
      endDate: eDate,
    }

    notifyMe({
      ...formValues,
      ...values,
      type: notifyMeEnums.BOAT_RENT,
    })

    this.modalHandler()
  }

  render() {
    const { clicks, startDate, endDate, focusedInput, boatType, boatRentType, focused, openDialog, selectedId } = this.state
    const { tripTypeData, tripList, notifyInput } = this.props

    const filteredTypes =
      tripTypeData && tripTypeData.length > 0
        ? tripTypeData.filter(item => {
            if (item.tripId) {
              return item.tripId.map(item => item.alias).includes(rentBoatTripForCard[boatType])
            }
            return false
          })
        : []

    return (
      <Card className="rent--card--box--new rent-card">
        <CardContent className="margin-padding-rent-card">
          <div className="rent--card--title">Start your trip</div>

          <CarousalFormBoatTitle
            data={tripList}
            fetchBoatType={(value, selectedId) => this.setState({ boatRentType: '', boatType: value, selectedId })}
          />
          <Grid container className="rent-form-container-div">
            <Grid item sm={12} className="pt-0">
              <div className="trip--passenger--count">
                <label className="trip--passenger--label mb-0">{this.getCountText(tripList, boatType)} </label>

                <div className="count--inc--dcs">
                  <button className="rent-passenger rent-passenger-height passenger--minus" onClick={this.DecreaseItem}>
                    <RemoveIcon />
                  </button>
                  <span className="count-passenger"> {clicks} </span>
                  <button className="rent-passenger rent-passenger-height passenger--plus" onClick={this.IncrementItem}>
                    <AddIcon />
                  </button>
                </div>
              </div>
            </Grid>

            <Grid item sm={12} className="trip--type--mb">
              {/* <label className="form-label rent-card-label-text color-black">Location</label> */}
              <SearchComplete
                placeHolder="Search country or city"
                className="form-control rent--card--input rent--card--input--loc rent-card-label-text"
                getPlaceName={place => {
                  this.setState({ place })
                }}
              />
            </Grid>

            <Grid item sm={12} className="trip--type--mb">
              {/* <label className="form-label rent-card-label-text color-black">Trip Type</label> */}

              {filteredTypes && filteredTypes.length > 0 && (
                <SingleSelect
                  styles={{
                    menuList: base => ({
                      ...base,

                      '::-webkit-scrollbar': {
                        width: '8px',
                        height: '0px',
                      },
                      '::-webkit-scrollbar-track': {},
                      '::-webkit-scrollbar-thumb': {
                        background: 'black',
                        borderRadius: '15px',
                      },
                    }),
                    control: (base, state) => ({
                      ...base,
                      height: 'calc(1.5em + .75rem + 2px) !important',
                    }),
                  }}
                  placeholder="What do you want to do?"
                  selectedOption={boatRentType}
                  options={renderSelectOptions(filteredTypes, 'alias', 'id')}
                  onChange={value => {
                    this.setState({ boatRentType: value })
                  }}
                />
              )}
            </Grid>

            <Grid item sm={12} className="manage--margin rent-search-date-picker">
              <DateRangePicker
                startDate={startDate ? startDate : ''}
                startDateId="your_unique_start_date_id"
                endDate={endDate ? endDate : ''}
                endDateId="your_unique_end_date_id"
                numberOfMonths={1}
                onDatesChange={({ startDate, endDate }) => this.setState({ startDate, endDate })}
                focusedInput={focusedInput}
                onFocusChange={focusedInput => (
                  setTimeout(() => document.querySelector('.DateRangePicker_picker')?.scrollIntoView(false), 500),
                  this.setState({ focusedInput })
                )}
              />
            </Grid>
          </Grid>
          <CardActions className="d-flex justify-content-center p-0">
            <span></span>
            {/* <a className="btn-link-info float-right" href="/#" onClick={this.modalHandler}>
              <u className="rent-notify-color rent-notify-btn">NOTIFY ME</u>
            </a> */}
            {/* <NotifyModal
              open={openDialog}
              value={notifyInput}
              closeModal={this.modalHandler}
              onSubmit={formValues => this.notifyMeHandler(formValues)}
            /> */}

            <button
              onClick={this.handleSearch}
              // type="button"
              className="btn search-glo-btn font-weight-600 rent-button-color rent-search-boat-btn rent-card-hovered-button-effect search-button-width"
              type="submit"
            >
              Search for a trip
              {/*<SearchIcon />*/}
            </button>
          </CardActions>
        </CardContent>
      </Card>
    )
  }
}

export default RentCard
