import React, { useEffect } from 'react'
import { Col, Row } from 'react-bootstrap'

import { TermWithIcon } from './TermWithIcon'
import { PageType, paymentPolicy } from '../../util/enums/enums'
import { useDispatch, useSelector } from 'react-redux'
import { getSalesEnginePages } from '../../redux/actions/pageInfoByTypeAction'

export const PaymentInformation = props => {
  const dispatch = useDispatch()
  const salesEnginePages = useSelector(state => state.pageInfoByTypeReducer?.salesEnginePages)

  useEffect(() => {
    dispatch(getSalesEnginePages())
  }, [])

  const getPageDescription = (pageType, pages) =>
    pages
      .map(page => {
        if (page.titleSlug === pageType) {
          return page.pageContent
        }
      })
      .filter(Boolean)

  return (
    <div>
      <Row className='rating-info-boat-dashboard'>
        <Col xs={4}>
          <TermWithIcon
            title={PageType.paymentPolicy}
            descriptions={getPageDescription('privacy-policy-734', salesEnginePages ? salesEnginePages : [])}
            icon={<i class="fas fa-money-check-alt"></i>}
            url="#"
            isDescHTML
            popup
            modalClassName="w-90"
          />
        </Col>
        <Col xs={4}>
          <TermWithIcon
            title="Cancellation Policy"
            descriptions={getPageDescription('cancellation-policy-623', salesEnginePages ? salesEnginePages : [])}
            icon={<i className="fas fa-ban"></i>}
            isDescHTML
            popup
            modalClassName="w-90"
          />
        </Col>
        <Col xs={4}>
          <TermWithIcon
            title="Delivery Policy"
            descriptions={getPageDescription('delivery-policy-320', salesEnginePages ? salesEnginePages : [])}
            icon={<i class="fas fa-truck"></i>}
            url="#"
            isDescHTML
            popup
            modalClassName="w-90"
          />
        </Col>
      </Row>
    </div>
  )
}
