import React from 'react'
import { ManufacturerStyle } from '../styleComponent/styleComponent'
import '../../assets/css/component/moreServicesWeDo.scss'
export const MarketServiceDetail = React.memo(({ value, width, height, getService, className, gridClassName, margin }) => {
  return (
    <div key={value.id} style={{ width: width, marginRight: margin }} className={`${gridClassName} ${className}`}>
      <div>
        <ManufacturerStyle
          height={height}
          className="market-type-image boat-service-img-height"
          img={encodeURI(value.icon?.url)}
          onClick={() => getService(value)}
        />
      </div>
      <div className="boat-name image--boat--name">{value.name || value.alias}</div>
    </div>
  )
})
MarketServiceDetail.defaultProps = {
  className: '',
  gridClassName: '',
}
