import React, { Component } from 'react'
import { connect } from 'react-redux'

import Chip from '@material-ui/core/Chip'
import { getSingleSalesEngine } from '../../../redux/actions'
import { DashboardLayout } from '../../../components'
import { AgentInfoCard } from '../../../components/salesEngine/AgentInfoCard'
import { CommonSalesEnginTitle } from '../../../components/salesEngine/CommonSalesEnginTitle'
import { CurrencyContextConsumer } from '../../../CurrencyContext'
import { getConvertedPrice } from '../../../helpers/currencyConverterHelper'

import { Grid } from '@material-ui/core'
import { cityCountryNameFormatter } from '../../../helpers/jsxHelper'
import { defaultProfileIcon } from '../../../util/enums/enums'
import SharePopup from '../../../components/share/SharePopup'
import { SaleEngineLabelAndValue } from '../../../components/salesEngine/SaleEngineLabelAndValue'
import BoatInformation from '../../../components/salesEngine/BoatInformation'
import ChatUserCreate from '../../../components/chats/ChatCreateUse'
import classNames from 'classnames'
import { getId, getOnlineUsers } from '../../../util/utilFunctions'
import { SET_ONLINE_USERS } from '../../../redux/actionTypes'

class SalesEngineShipperDetail extends Component {
  state = {
    isSalesEngineId: true,
    hrWidthCalc: 0,
    divWidthCalc: 0,
    that: this,
    sharePopUpOpen: false,
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const {
      match: { params },
      getSingleSalesEngine,
    } = nextProps
    const { isSalesEngineId } = prevState

    if (params && params.hasOwnProperty('id') && params.id && isSalesEngineId) {
      getSingleSalesEngine({ id: params.id })
      return {
        isSalesEngineId: false,
      }
    }
    return null
  }
  componentDidMount() {
    this.stepperWidth()
    this.stepperDivWidth()

    if (getId(this.props.salesEngine?.buyer)) {
      getOnlineUsers([getId(this.props.salesEngine?.buyer)], this.props.setOnlineUsers);
    }
  }

  componentDidUpdate(prevProps) {
    if (getId(this.props.salesEngine?.buyer) !== getId(prevProps.salesEngine?.buyer)) {
      getOnlineUsers([getId(this.props.salesEngine?.buyer)], this.props.setOnlineUsers);
    }
  }

  stepperWidth = () => {
    const stepperWidth = document.querySelector('.sales-engine-stepper')
    const stepperActualWidth =
      stepperWidth && stepperWidth.offsetWidth / (document.querySelectorAll('.stepper-info-rounded-circle').length - 1)
    stepperActualWidth && this.setState({ hrWidthCalc: stepperActualWidth + 5 })
  }

  stepperDivWidth = () => {
    const stepperDivWidth = document.querySelector('.sales-engine-stepper')
    const stepperDivActualWidth =
      stepperDivWidth && stepperDivWidth.offsetWidth / document.querySelectorAll('.stepper-info-rounded-circle').length
    stepperDivActualWidth && this.setState({ divWidthCalc: stepperDivActualWidth + 5 })
  }

  render() {
    const { salesEngine, history, onlineUsers } = this.props
    const { boat, shipmentLocation, buyer } = salesEngine
    // const { commonAddress: { city, country } } = salesEngine  && salesEngine.id && boat && getAddress(boat.address)
    // const { commonAddress: { city:shipperCity, country:shipperCountry, address1:shipperAddress1, address2:shipperAddress2 } } = shipper && getAddress(shipper.address)
    // const { address1: boatAddress, address2: boatAddress2 } = boat && boat.address && boat.address.length > 0 && boat.address[0]
    const { hrWidthCalc, sharePopUpOpen, divWidthCalc } = this.state

    return (
      <DashboardLayout className="sales-engin-layout-design ">
        <div className="shipper-detail--single--sales-engine">
          {/* Step  */}

          <div className="stepper-info-main-section shipment--info--main--section ">
            <div className="near-step-title">Boat Shipment</div>
            <div className="d-flex width-100 pb-4 pt-4 sales-engine-stepper justify-content-between">
              <div className="position-relative stepper-main-div">
                <div className="stepper-main-section">
                  <div className="d-flex justify-content-center align-items-start flex-direction-column width-100">
                    <div className="stepper-info-rounded-circle stepper-number-div stepper-number-div-new cursor-pointer bg-white stepper-selected-div-text stepper-line-color">
                      <span className="d-flex justify-content-center align-items-center h-100 font-14 stepper-div-text">
                        My Boat
                      </span>
                    </div>
                  </div>
                  <hr
                    style={{ width: `${hrWidthCalc}px` }}
                    className={`stepper-line-div profile-hr stepper-line-selected-color`}
                  />
                </div>
              </div>
              <div className="position-relative stepper-main-div">
                <div className="stepper-main-section">
                  <div className=" d-flex justify-content-center align-items-start flex-direction-column width-100">
                    <div className="stepper-info-rounded-circle stepper-number-div stepper-number-div-new cursor-pointer bg-white stepper-selected-div-text stepper-line-color">
                      <span className="d-flex justify-content-center align-items-center h-100 font-14 stepper-div-text">
                        Shipment
                      </span>
                    </div>
                  </div>
                  <hr
                    style={{ width: `${hrWidthCalc}px` }}
                    className={`stepper-line-div profile-hr stepper-line-selected-color`}
                  />
                </div>
              </div>
            </div>
          </div>
          {/* Step  */}
          <div className="shipment--title-with--action">
            <CommonSalesEnginTitle
              title="Boat Shipment Detail"
              className="shipment--title-with-sub-label"
              otherInformation={`<div className="shipment--title-with-sub-value">Enter Detail regarding the Boat Shipment.</div>`}
            />
            <button className="btn btn-outline-primary shipment--btn--action">
              Submit <span>{`>>`}</span>
            </button>
          </div>

          {/* upload Address Payment Detail  */}
          <div className="upload--address--payment-with--title">
            <CommonSalesEnginTitle title="Upload Documents" />
            <CommonSalesEnginTitle title="Address" />
            <CommonSalesEnginTitle title="Payment Details" />
          </div>
          <div className="upload--address--payment">
            {/* Upload Documents */}

            <div className="shipment--upload--div shipment--33">
              <div className="shipment--upload--main">
                <div className="input--upload--shipment">
                  <div>Select Document</div>
                  <button className="btn">Upload</button>
                </div>
                <div className="shipment--uploaded--contain">
                  <div className="shipment--uploaded--doc--label">Uploaded Documents</div>
                  <div className="shipment--uploaded--doc">
                    <Chip classes={{ root: 'label--root' }} label="Bill of Lading" variant="outlined" />
                    <Chip classes={{ root: 'label--root' }} label="Certificate Of Origin" variant="outlined" />
                    <Chip classes={{ root: 'label--root' }} label="Insurance Certificate" variant="outlined" />
                    <Chip classes={{ root: 'label--root' }} label="Received for Shipment" variant="outlined" />
                    <Chip classes={{ root: 'label--root' }} label="Shipment Other Document" variant="outlined" />
                    <Chip classes={{ root: 'label--root' }} label="Invoice of Shipment Payment" variant="outlined" />
                  </div>
                </div>
              </div>
            </div>

            {/* Address */}

            <div className="shipment--address--div shipment--33">
              <div className="shipment--address--from">
                <div className="shipment--label-from">Pick From</div>

                <SaleEngineLabelAndValue
                  className="shipment--address--full"
                  valueClass="shipment--address--full--value"
                  label="Address"
                  value={boat && boat.address && boat.address.length > 0 && boat.address[0].address1}
                />
                <div className="shipment--address--city--country">
                  <SaleEngineLabelAndValue
                    className="shipment--address--city"
                    label="City"
                    value={boat && boat.address && boat.address.length > 0 && boat.address[0].city}
                  />
                  <SaleEngineLabelAndValue
                    className="shipment--address--city"
                    label="Country"
                    value={boat && boat.address && boat.address.length > 0 && boat.address[0].country}
                  />
                </div>
              </div>
              <div className="shipment--address--from shipment--address--to">
                <div className="shipment--label-from">Delivery To</div>

                <SaleEngineLabelAndValue
                  className="shipment--address--full"
                  valueClass="shipment--address--full--value"
                  label="Address"
                  value={shipmentLocation && shipmentLocation.address}
                />
                <div className="shipment--address--city--country">
                  <SaleEngineLabelAndValue
                    className="shipment--address--city"
                    label="City"
                    value={shipmentLocation && shipmentLocation.city}
                  />
                  <SaleEngineLabelAndValue
                    className="shipment--address--city"
                    label="Country"
                    value={shipmentLocation && shipmentLocation.country}
                  />
                </div>
              </div>
            </div>
            {/* Payment Detail */}
            <div className="shipment--payment--div shipment--33">
              <div className="shipment--payment--success--detail">
                <SaleEngineLabelAndValue label="Status" value="Success" valueClass="success-payment" />
                <SaleEngineLabelAndValue label="Transaction ID" value="000000000" />
                <SaleEngineLabelAndValue label="Transaction by" value="User Name" />
                <SaleEngineLabelAndValue label="Transaction Date" value="12 March 2020" />
                <SaleEngineLabelAndValue label="Transaction Type" value="Bank Transfer" />
                <SaleEngineLabelAndValue
                  label="Total Amount"
                  value={
                    <CurrencyContextConsumer>
                      {({ currentCurrency }) => getConvertedPrice(250, currentCurrency)}
                    </CurrencyContextConsumer>
                  }
                />
              </div>
            </div>
          </div>
          {/* upload Address Payment Detail  */}

          {salesEngine.agent && (
            <AgentInfoCard
              history={history}
              agent={salesEngine.agent}
              className={'assign-single-list shipment--assign-single-list no-animation'}
              isSingle={false}
              salesEngine={salesEngine}
            />
          )}
          <Grid container spacing={2}>
            {salesEngine && salesEngine.boat && <BoatInformation salesEngine={salesEngine} />}

            <Grid item xs={4}>
              {buyer && (
                <div className="buyer--detail--in--shipment">
                  <div className="buyer--detail--in--shipment--left">
                    <div className="buyer--detail--in--name">
                      {buyer.firstName} {buyer.lastName}
                    </div>
                    <div className="buyer--detail--in--city-country">
                      {cityCountryNameFormatter(
                        buyer.address && buyer.address.length > 0 && buyer.address[0].city,
                        buyer.address && buyer.address.length > 0 && buyer.address[0].country
                      )}
                    </div>
                    <div className="buyer--detail--in--mb-no">{buyer.mobileNumber}</div>

                    {buyer.documentVerification && (
                      <div className="mb-2 d-flex align-items-center">
                        <img
                          src={require('../../../components/userProfile/image/vector.png')}
                          className="stepper-user-profile-icon-div mr-2"
                          alt="Verification icon"
                        />
                        <span className="rentInner-userTextH4  font-16 dark-silver">Government ID</span>
                      </div>
                    )}
                    {buyer.documentVerification && (
                      <div className="mb-2 d-flex align-items-center">
                        <img
                          src={require('../../../components/userProfile/image/vector.png')}
                          className="stepper-user-profile-icon-div mr-2"
                          alt="Verification icon"
                        />
                        <span className="rentInner-userTextH4  font-16 dark-silver">Email address</span>
                      </div>
                    )}
                    {buyer.documentVerification && (
                      <div className="mb-2 d-flex align-items-center">
                        <img
                          src={require('../../../components/userProfile/image/vector.png')}
                          className="stepper-user-profile-icon-div mr-2"
                          alt="Verification icon"
                        />
                        <span className="rentInner-userTextH4  font-16 dark-silver">Phone number</span>
                      </div>
                    )}
                  </div>
                  <div className="buyer--detail--in--shipment--right">
                    <div className="stepper-userImg rounded-circle user-profile-online-section">
                      <img
                        className="rounded-circle width-100 h-100"
                        src={buyer.image?.url || defaultProfileIcon}
                        alt="profile icon"
                      />
                      <div className="stepper-user-online">
                        <div className={classNames("online-div-user", {
                          'disconnect': !onlineUsers[getId(buyer)],
                        })}></div>
                      </div>
                    </div>
                    <div className="d-flex justify-content-evenly mt-3">
                      <ChatUserCreate id={buyer?.id} image={buyer.image?.url}/>
                      <img
                        src={require('../../../components/userProfile/image/share.png')}
                        className="user-profile-social-icon-div cursor-pointer"
                        alt="Share"
                        onClick={() => this.setState({ sharePopUpOpen: true })}
                      />
                      {sharePopUpOpen && (
                        <SharePopup handleClick={() => this.setState({ sharePopUpOpen: false })} useOwnIcon={true} />
                      )}
                    </div>
                  </div>
                </div>
              )}
            </Grid>
          </Grid>
        </div>
      </DashboardLayout>
    )
  }
}
const mapStateToProps = state => ({
  salesEngine: state.salesEngineReducer.salesEngine,
  onlineUsers: state.chatReducer.onlineUsers,
})

const mapDispatchToProps = dispatch => ({
  getSingleSalesEngine: data => dispatch(getSingleSalesEngine(data)),
  setOnlineUsers: users => dispatch({ type: SET_ONLINE_USERS, payload: users })
})

export default connect(mapStateToProps, mapDispatchToProps)(SalesEngineShipperDetail)
