import React, { Component } from 'react'
import { connect } from 'react-redux'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { Grid, Box } from '@material-ui/core'
import { Link } from 'react-router-dom'
import Carousel, { Modal, ModalGateway } from 'react-images'
import Truncate from 'react-truncate'
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline'
import '../../styles/responsive.scss'

import {
  boatInformation,
  mechanicalSystem,
  electricalSystem,
  boatDimensions,
  icons,
  iconImages,
  defaultImage,
  likeModuleEnum,
  wishlistModuleEnum,
  shareModule,
  textCustomerReview,
  userRoles,
  boatType,
  boatVerifyMessage,
  textSeaTrial,
  skeletonType,
  noSurveyorMessage,
  noShipperMessage,
  noStockAvailable,
  marketTypes,
  radioEnum,
  boatDisabled,
  soldOut,
  boatEngine,
  isKayakCanoeType,
  isYachtType,
} from '../../util/enums/enums'
import { displayDefaultValue, formatCash, snakeCase, thousandFormatter } from '../../helpers/string'
import { Layout, Loader, Field } from '../../components'
import ReadMore from '../../components/helper/truncate'
import {
  getBoatById,
  createSalesEngine,
  clearCreateSalesEngineFlag,
  createAuctionBid,
  getBoatByCities,
  clearBoatByCity,
  pageVisit,
  getBoatCostEstimation,
  mailBoatCostEstimation,
  addLikes,
  clearEstimatedMail,
  getBoatPdf,
  clearPdfFlag,
  getAuctionByBoatId,
  clearErrorMessageShow,
  getBoatConvertedCostEstimation,
  clearAdminLoginFlag,
  checkForQuantity,
  clearCheckForQuantity,
  addDepositForBid,
  getServiceTags,
} from '../../redux/actions'
import GoogleMarker from '../../components/map/marker'
import {
  getSingleBoatMarker,
  isBuyerEligible,
  convertValueFromFtToMeter,
  getGridCountForMap,
  getGridCountForMapElement,
  viewServiceBoatHandler,
} from '../../helpers/boatHelper'
import { SuccessNotify, InfoNotify } from '../../helpers/notification'
import { BoatInnerGallery, HomPageVideoStyle } from '../../components/styleComponent/styleComponent'
import { addFeedback, clearFeedbackFlag, getReviewByModuleId } from '../../redux/actions/ReviewAction'

import './BoatInner.scss'
import DialogCard from '../../components/dialog/DialogCard'
import { prepareGalleryData, reviewType, percentageFormate, getImgUrl, getId } from '../../util/utilFunctions'
import {
  getAvgRating,
  shareWithUsersInner,
  getBoatTypeName,
  shareWithUsers,
  checkModuleVerification,
  verificationRedirection,
  getAddress,
  checkBrokerAccessibility,
  cityCountryNameFormatter,
} from '../../helpers/jsxHelper'
import { VideoModel } from '../../components/videoComponent/videoModel'
import { getLocalStorageItem, setLocalStorageItem } from '../../helpers/storageHelper'
import { popUpMessage } from '../../helpers/confirmationPopup'
import { BoatGrid } from '../../components/gridComponents/boatGrid'
import { getConvertedPrice } from '../../helpers/currencyConverterHelper'
import { currencyEnum } from '../../util/enums/currencyEnums'
import { ShareAndWishlist } from '../../components/share/ShareAndWishlist'
import { shareDetailsWithUsers } from '../../redux/actions/shareAction'
import { CommonTooltip } from '../../components/CommonTooltip'
import RatingComponent from '../../components/rating/Rating'
import ReviewRating from '../../components/reviewRating/ReviewRating'
import SmallCard from '../../components/boat/smallCard'
import { UserCardWithFeedback } from '../../components/user/UserCardWithFeedback'
import { formattedDate, checkDateIsAfterCurrent, checkDateIsBeforeCurrent } from '../../helpers/dateTimeHelper'
import ImageGalleryComponent from '../../components/imageHelper/imageGalleryComponent'
import { LikeComponent } from '../../components/like/LikeComponent'
import { ButtonComponent } from '../../components/form/Button'
import LoginModal from '../../components/modal/loginModal'
import { AuctionBox } from '../../components/auctionRoom/AuctionBox'
import { getModulePdf } from '../../redux/actions/pdfAction'
import { SkeletonLoader } from '../../components/loader/SkeletonLoader'
import { redirectRouteHandler } from '../../helpers/routeHelper'
import { QuantityModal } from '../../components/modal/quantityModal'
import { getUserReportByModule } from '../../redux/actions/reportAction'
import { CurrencyContextConsumer } from '../../CurrencyContext'
import InnerRating from '../../components/reviewRating/InnerRating'
import HelmetMetaData from '../../components/helmetComponent/HelmetMeta'
import CopyAdId from '../../components/CopyAdId'
import { get, isEmpty } from 'lodash'
import { FaImages } from 'react-icons/fa'
import { FeedBackForm } from '../../components/feedback/FeedBackForm'
import UserCardSale from '../../components/user/UserCardSale'

class BoatInner extends Component {
  state = {
    boatId: '',
    review: {
      rating: 0,
      reviews: '',
    },
    estimateCostFlag: false,
    boatByCityList: [],
    shipperModalFlag: false,

    isGallery: false,
    isLayoutGallery: false,

    relatedVideoPopUp: false,
    newThumbnail: '',
    isMailEnabled: false,
    openCurrencyPopup: false,
    selectedCurrency: getLocalStorageItem('currentCurrency'),
    isLoading: false,

    isBidNow: false,
    openDepositModal: false,
    loginModal: false,

    imageIndex: null,
    isValueInFt: true,
    isCheckVerify: true,
    stockModal: false,
    isVarificationFlag: true,

    underVerification: false,
    verificationStatus: {},
    salesEngineView: false,
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const {
      match: { params },
      history,
      createSuccess,
      salesEngine,
      createError,
      clearCreateSalesEngineFlag,
      isReview,
      getReviewByModuleId,
      isMailCost,
      clearEstimatedMail,
      isMailCostFail,
      errors,
      boat,
      isVarification,
      errorMessage,
      getBoatSuccess,
      getBoatError,
      getBoatById,
      clearAdminLoginFlag,
      adminLoginAsUserSuccess,
      location,
      isCheckedQuantity,
      clearCheckForQuantity,
      createSalesEngine,
      currentUser,
      getServiceTags,
    } = nextProps

    const { boatId, isCheckVerify, isVarificationFlag } = prevState

    if (getBoatSuccess) {
      getServiceTags({ boatId })
      getReviewByModuleId({ userModuleId: boatId, moduleId: boat?.seller?.id })
    }

    if (getBoatError) {
      if (boat?.seller?.id !== currentUser?.id && getBoatError) {
        return {
          underVerification: true,
          verificationStatus: { message: boatDisabled },
        }
      } else if (getBoatError) {
        const underVerification = checkModuleVerification(marketTypes.BOAT, errorMessage)
        if (underVerification) {
          return {
            underVerification: true,
            verificationStatus: underVerification,
          }
        }
      }

      let isComingFromSalesEngine

      const urlParams = location?.search && new URLSearchParams(location.search)
      if (urlParams) {
        isComingFromSalesEngine = urlParams?.has('isComingFromSalesEngine') && urlParams.get('isComingFromSalesEngine')
      }
      if (getBoatError && ((isComingFromSalesEngine && isComingFromSalesEngine === 'true') || errorMessage?.length)) {
        return {
          underVerification: true,
          verificationStatus: { message: errorMessage[0].message },
        }
      } else {
        InfoNotify(boatVerifyMessage)
        return {
          isCheckVerify: false,
        }
      }
    }

    // if (isVarification && isVarificationFlag) {
    //   InfoNotify(boatVerifyMessage)
    //   /** For Notification boat Under varifiaction  */
    //   setTimeout(() => {
    //     history && history.push(`/`);
    //   }, 3000);
    //   return {
    //     isVarificationFlag: false
    //   }
    // }
    if (isReview && boat?.id) {
      boatId && getReviewByModuleId({ userModuleId: boatId, moduleId: boat?.seller?.id })
    }
    if (isCheckVerify && boat.id && errorMessage?.length) {
      const urlParams = location?.search && new URLSearchParams(location.search)
      const isComingFromSalesEngine =
        urlParams instanceof URLSearchParams &&
        urlParams.has('isComingFromSalesEngine') &&
        urlParams.get('isComingFromSalesEngine')
      if (isComingFromSalesEngine === 'true' || errorMessage?.length) {
        return {
          underVerification: true,
          verificationStatus: { message: errorMessage[0].message },
        }
      } else {
        InfoNotify(boatVerifyMessage)
        return {
          isCheckVerify: false,
        }
      }
    }
    if (isMailCost) {
      SuccessNotify('Mail sent successfully')
      clearEstimatedMail()
      return {
        isLoading: false,
        estimateCostFlag: false,
        isMailEnabled: false,
      }
    }

    if (isMailCostFail) {
      clearEstimatedMail()
      return {
        isLoading: false,
      }
    }

    if (isCheckedQuantity) {
      const userId = getLocalStorageItem('userId')

      const buyerIds = boat?.salesEngine?.map(item => item?.buyer?.id) || []

      clearCheckForQuantity()

      if (buyerIds.includes(userId)) {
        createSalesEngine({
          buySellProcess: 'myBoat',
          boat: boat.id,
          seller: boat.seller.id,
          stockQuantity: 0,
        })
      } else {
        return {
          stockModal: true,
        }
      }
    }

    if (createSuccess && salesEngine && salesEngine.id) {
      clearCreateSalesEngineFlag()
      if (salesEngine.salesEngineInProcessWithPayment) {
        return {
          stockModal: true,
        }
      } else {
        history && history.push(`/sales-engine-process/${salesEngine.id}`)
      }
    } else if (createError && errors) {
      clearCreateSalesEngineFlag()
      typeof errors === 'string' && popUpMessage(errors, 'Purchase and Permissions')
    } else if (params && params.hasOwnProperty('id') && params.id) {
      return {
        boatId: params.id,
      }
    }

    return null
  }

  createThumbnail = video => {
    if (video !== undefined) {
      var regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/
      var result = video?.match(regExp)
      if (result === null || result === undefined) {
        this.setState({ newThumbnail: defaultImage })
      } else {
        var youtube_video_id = result.pop()
        if (youtube_video_id?.length == 11) {
          var video_thumbnail = `//img.youtube.com/vi/${youtube_video_id}/0.jpg`
          this.setState({ newThumbnail: video_thumbnail })
        }
      }
    }
  }

  shareBoatWithUsers = data => {
    const { shareDetailsWithUsers } = this.props
    const { boatId } = this.state
    data.id = boatId
    const object = shareWithUsers(data, shareModule.boat)

    shareDetailsWithUsers(object)
  }

  async componentDidMount() {
    const { boatId } = this.state
    const {
      getBoatById,
      pageVisit,
      getBoatByCities,
      getBoatCostEstimation,
      getReviewByModuleId,
      getAuctionByBoatId,
      location,
      adminLoginDetected,
    } = this.props

    boatId && (await getBoatById({ id: boatId }))

    !adminLoginDetected &&
      boatId &&
      pageVisit({ module: boatId, type: likeModuleEnum.BOAT, sessionId: getLocalStorageItem('sessionId') })

    boatId && getBoatCostEstimation(boatId)
    boatId && (await getBoatByCities({ id: boatId }))
    boatId && getAuctionByBoatId({ id: boatId })
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      boatByCityLoad,
      boatByCitySuccess,
      boatByCityError,
      boatByCityData,
      clearBoatByCity,
      createBidSuccess,
      getAuctionByBoatId,
      isDepositSuccess,
      boat,
    } = this.props

    const { boatId, newThumbnail } = prevState

    if (prevProps.boatByCityLoad && !boatByCityLoad && boatByCitySuccess) {
      this.setState({ boatByCityList: boatByCityData })
      clearBoatByCity()
    }

    if (prevProps.boatByCityLoad && !boatByCityLoad && boatByCityError) {
      typeof boatByCityError === 'string' && popUpMessage(boatByCityError)
      clearBoatByCity()
    }

    if (createBidSuccess && createBidSuccess !== prevProps.createBidSuccess) {
      SuccessNotify('Your bid added successfully')
      boatId && getAuctionByBoatId({ id: boatId })
      this.setState({ isBidNow: false })
    }

    if (isDepositSuccess && isDepositSuccess !== prevProps.isDepositSuccess) {
      boatId && getAuctionByBoatId({ id: boatId })
      this.depositModalHandler()
    }
    if (boat && boat.video !== '' && newThumbnail === '') {
      this.createThumbnail(boat.video)
    }
  }

  setEstimateCost = data => {
    this.setState({
      estimateCostFlag: data,
    })
  }

  getConvertedPriceEstimate = async () => {
    const { boatId } = this.state
    const { getBoatConvertedCostEstimation } = this.props
    await getBoatConvertedCostEstimation({
      boatId,
      currency: getLocalStorageItem('currentCurrency'),
    })
  }

  handlerLoginModal = () => this.setState(prevState => ({ loginModal: !prevState.loginModal }))
  stockModalHandler = () => this.setState(prevState => ({ stockModal: !prevState.stockModal }))

  purchaseBoatHandler = async () => {
    const { boat, currentUser, createSalesEngine, history, boatAuctionRoom, checkForQuantity } = this.props
    const userId = getLocalStorageItem('userId')

    if (!userId) {
      this.handlerLoginModal()
    } else if (boat && boat.isAuctionRoomCreated && boatAuctionRoom && boatAuctionRoom.id) {
      this.bidHandler()
    } else if (isBuyerEligible(currentUser, history) && boat.hasOwnProperty('id') && boat.seller) {
      if (boat?.stockQuantity === 1 || boat.seller?.role?.aliasName === userRoles.BOAT_OWNER) {
        await createSalesEngine({
          buySellProcess: 'myBoat',
          boat: boat.id,
          seller: boat.seller.id,
          stockQuantity: boat?.stockQuantity,
        })
      } else {
        await checkForQuantity({ boatId: boat.id })
      }
    }
  }

  onClickBoatShipper = () => {
    this.setState(prevState => ({
      shipperModalFlag: !prevState.shipperModalFlag,
    }))
  }

  bidHandler = () => {
    const { clearErrorMessageShow, currentUser, history } = this.props
    const userId = getLocalStorageItem('userId')

    if (!userId) {
      this.handlerLoginModal()
      return
    }

    if (isBuyerEligible(currentUser, history)) {
      clearErrorMessageShow()
      this.setState(prevState => ({
        isBidNow: !prevState.isBidNow,
      }))
    }
  }

  depositModalHandler = () => {
    const { clearErrorMessageShow, currentUser, history } = this.props
    const userId = getLocalStorageItem('userId')

    if (!userId) {
      this.handlerLoginModal()
      return
    }

    if (isBuyerEligible(currentUser, history)) {
      clearErrorMessageShow()
      this.setState(prevState => ({
        openDepositModal: !prevState.openDepositModal,
      }))
    }
  }

  openLightbox = (index = null) => {
    this.setState({ isGallery: true, imageIndex: index })
  }
  closeLightbox = () => {
    this.setState({ isGallery: false, imageIndex: null })
  }

  openNewLightbox = (index = null) => {
    this.setState({ isLayoutGallery: true, imageIndex: index })
  }
  closeNewLightbox = () => {
    this.setState({ isLayoutGallery: false, imageIndex: null })
  }

  loadingMail = () => {
    this.setState({ isLoading: true })
  }
  relatedVideoHandler = () => {
    this.setState(prevState => ({
      relatedVideoPopUp: !prevState.relatedVideoPopUp,
    }))
  }

  currencyPopupHandler = () => {
    this.setState(prevState => ({
      openCurrencyPopup: !prevState.openCurrencyPopup,
    }))
  }

  renderBuyNowSection = () => {
    const { openCurrencyPopup, selectedCurrency } = this.state
    const { boat, currentUser, boatAuctionRoom, createSalesEngineLoading, countryDetails } = this.props
    const { price, seller, isAuctionRoomCreated, stockQuantity, inStock } = boat

    return (
      <div className="d-flex buy-right-button h-100  pl-0 pt-3 pb-3 pr-0 inner--boat--header--div inner-price-dive-boat">
        <figcaption>
          {/* <Link
            className=" pl-0 font-10 f-500 text-right f-12 add-feedback-text boat--inner--currency--converter"
            to="#"
            data-toggle="modal"
            data-target="#currency-modal"
            onClick={this.currencyPopupHandler}
          >
            USE CURRENCY CONVERTER
          </Link>
          {openCurrencyPopup &&
            <>
              <Field
                type="select"
                value={selectedCurrency}
                onChangeText={e => this.setState({ selectedCurrency: e.target.value })}
                options={currencyEnum.map(item => { return { id: item.label, value: item.name } })}
                labelClass="d-flex mb-2"
              />
            </>
          } */}
          <div className="price-wrap boat-inner-price-top-space">
            <CurrencyContextConsumer>
              {({ currentCurrency }) => (
                <span className="price-new font-weight-500">{getConvertedPrice(price, currentCurrency)}</span>
              )}
            </CurrencyContextConsumer>
          </div>
        </figcaption>
        {/* {!boat ? (<> */}
        <div>
          {!boat?.isSalesEngineCreated &&
            get(boat, 'boatType.id', null) !== '5ede27b7744ef04518957b7f' &&
            stockQuantity > 0 &&
            seller &&
            seller.id &&
            currentUser.id !== seller.id &&
            !(isAuctionRoomCreated && boatAuctionRoom?.id) &&
            boat?.boatType?.name !== boatType.superYacht &&
            boat?.boatType?.name !== boatType.yacht &&
            (!isEmpty(countryDetails) && !countryDetails.salesEngineStatus ? (
              <ButtonComponent
                className="as--success--outline buy--it--now h-auto"
                onClick={this.purchaseBoatHandler}
                disabled={createSalesEngineLoading}
              >
                Buy It Now
              </ButtonComponent>
            ) : (
              <>
                {/* <div
                  data-tooltip-id="app-common-tooltip"
                  data-tooltip-content="Buying online will be released soon. Sellers can keep listing their boats"
                  data-tooltip-place="top"
                >
                  <ButtonComponent className="as--success--outline buy--it--now h-auto">Buy It Now</ButtonComponent>
                </div> */}
              </>
            ))}
        </div>
        {/* </>) : ""} */}
      </div>
    )
  }

  renderBoatInfo = () => {
    const { boat } = this.props
    const jetSkiType = boat?.boatType?.name === boatType.jetSki
    const kayakCaoneType = isKayakCanoeType(boat)
    const {
      boatName,
      boatParking,
      trailer,
      yearBuilt,
      manufacturedBy,
      hullMaterial,
      hullColor,
      usedHours,
      seller,
      seats,
      kayakType,
      kind,
      fuelType,
      inStock,
      stockQuantity,
      weightCapacity,
    } = boat
    const {
      TITLE,
      BOAT_TYPE,
      BOAT_NAME,
      BOAT_PARKING,
      TRAILER,
      YEARS_OF_BUILT,
      MANUFACTURED_BY,
      HULL_MATERIAL,
      HULL_COLOR,
      HOURS_USED,
      SEATS,
      FUEL_TYPE,
      KAYAK_TYPE,
      KIND,
      IN_STOCK,
      STOCK_QUANTITY,
      WEIGHT_CAPACITY,
    } = boatInformation

    return (
      <div className="inner-page-info-div">
        <Grid container>
          <Grid item md={12}>
            <div className="width-100 pr-3 boat-inner-info-title d-flex align-items-center inner--boat--systems-title">
              <div className="inner--title--icons">
                <img
                  loading="lazy"
                  src={require('../../assets/images/boatInner/information.svg')}
                  alt="Info"
                  className="d-flex h-100"
                />
              </div>

              <span className="inner--main--title"> {TITLE} </span>
            </div>

            <Grid container className="mb-2">
              <Grid item xs={6} md={4}>
                <p className="boat-inner-info-desc boat--inner--info--div--desc mb-0 color-black">
                  {displayDefaultValue(boatName)}
                </p>
                <h6 className="title-sm mb-20 title-description inner--info--title ">{BOAT_NAME}</h6>
              </Grid>
              {boatParking && (
                <Grid item xs={6} md={4}>
                  <p className="boat-inner-info-desc boat--inner--info--div--desc mb-0 color-black">
                    {displayDefaultValue(boatParking.alias)}
                  </p>
                  <h6 className="title-sm mb-20 title-description inner--info--title ">{BOAT_PARKING}</h6>
                </Grid>
              )}
              {seats && (
                <Grid item xs={6} md={4}>
                  <p className="boat-inner-info-desc boat--inner--info--div--desc mb-0 mb-0 color-black">
                    {displayDefaultValue(seats)}
                  </p>
                  <h6 className="title-sm mb-20 title-description inner--info--title ">{SEATS}</h6>
                </Grid>
              )}
              {/* {inStock && <Grid item md={4}>
                <p className="boat-inner-info-desc boat--inner--info--div--desc mb-0 mb-0">
                  {displayDefaultValue(inStock)}
                </p>
                <h6 className="title-sm mb-20 title-description inner--info--title ">
                  {IN_STOCK}
                </h6>
              </Grid>} */}
              {usedHours && (
                <Grid item xs={6} md={4}>
                  <p className="boat-inner-info-desc boat--inner--info--div--desc mb-0 mb-0 color-black">
                    {displayDefaultValue(usedHours)}
                  </p>
                  <h6 className="title-sm mb-20 title-description inner--info--title ">{HOURS_USED}</h6>
                </Grid>
              )}
              {kayakCaoneType && (
                <Grid item xs={6} md={4}>
                  <p className="boat-inner-info-desc boat--inner--info--div--desc mb-0 color-black">
                    {displayDefaultValue(manufacturedBy ? manufacturedBy.alias : seller?.companyName)}
                  </p>
                  <h6 className="title-sm mb-20 title-description inner--info--title ">{MANUFACTURED_BY}</h6>
                </Grid>
              )}
            </Grid>

            <Grid container className=" mb-2">
              {!kayakCaoneType && (
                <Grid item xs={6} md={4}>
                  <p className="boat-inner-info-desc boat--inner--info--div--desc mb-0 color-black">
                    {displayDefaultValue(manufacturedBy ? manufacturedBy.alias : seller?.companyName)}
                  </p>
                  <h6 className="title-sm mb-20 title-description inner--info--title ">{MANUFACTURED_BY}</h6>
                </Grid>
              )}
              <Grid item xs={6} md={4}>
                {hullMaterial && (
                  <p className="boat-inner-info-desc boat--inner--info--div--desc mb-0 color-black">
                    {displayDefaultValue(hullMaterial.alias)}
                  </p>
                )}
                <h6 className="title-sm mb-20 title-description inner--info--title ">{HULL_MATERIAL}</h6>
              </Grid>
              <Grid item xs={6} md={4}>
                <p className="boat-inner-info-desc boat--inner--info--div--desc mb-0 mb-0 color-black">
                  {displayDefaultValue(trailer)}
                </p>
                <h6 className="title-sm mb-20 title-description inner--info--title ">{TRAILER}</h6>
              </Grid>

              <Grid item xs={6} md={4}>
                <p className="boat-inner-info-desc boat--inner--info--div--desc mb-0 mb-0 color-black">
                  {displayDefaultValue(hullColor.alias)}
                </p>
                <h6 className="title-sm mb-20 title-description inner--info--title ">{HULL_COLOR}</h6>
              </Grid>
              {weightCapacity && (
                <Grid item xs={6} md={4}>
                  <p className="boat-inner-info-desc boat--inner--info--div--desc mb-0 mb-0 color-black">
                    {`${displayDefaultValue(weightCapacity)} kg`}
                  </p>
                  <h6 className="title-sm mb-20 title-description inner--info--title ">{WEIGHT_CAPACITY}</h6>
                </Grid>
              )}
              {jetSkiType && fuelType?.alias && (
                <Grid item xs={6} md={4}>
                  <p className="boat-inner-info-desc boat--inner--info--div--desc mb-0 mb-0 color-black">
                    {displayDefaultValue(fuelType.alias)}
                  </p>
                  <h6 className="title-sm mb-20 title-description inner--info--title ">{FUEL_TYPE}</h6>
                </Grid>
              )}
              {kind?.alias && !kayakCaoneType && (
                <Grid item xs={6} md={4}>
                  <p className="boat-inner-info-desc boat--inner--info--div--desc mb-0 mb-0 color-black">
                    {displayDefaultValue(kind.alias)}
                  </p>
                  <h6 className="title-sm mb-20 title-description inner--info--title ">{KIND}</h6>
                </Grid>
              )}
              {kayakType?.alias && !kayakCaoneType && (
                <Grid item xs={6} md={4}>
                  <p className="boat-inner-info-desc boat--inner--info--div--desc mb-0 mb-0 color-black">
                    {displayDefaultValue(kayakType.alias)}
                  </p>
                  <h6 className="title-sm mb-20 title-description inner--info--title ">{KAYAK_TYPE}</h6>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </div>
    )
  }

  renderBoatLayout = () => {
    const { boat } = this.props

    return (
      <Grid item md={12}>
        {boat.layout && boat.layout.length > 0 && (
          <div>
            <Grid item md={12} className="p-0 align-items-end justify-content-between">
              <div className="d-flex align-items-center inner--boat--systems-title">
                <div className="inner--title--icons">
                  <img
                    loading="lazy"
                    src={require('../../assets/images/boatInner/Boat layout.svg')}
                    alt="Layout"
                    className="d-flex h-100"
                  />
                </div>
                <span className="inner--main--title">Boat Layout</span>
              </div>

              {boat.layout?.length > 0 && (
                <div className="boat-layout-gallery">
                  <div className="boat-layout-gallery-col">
                    <div className="boat-layout-gallery-row" onClick={() => this.openNewLightbox(0)}>
                      <img src={getImgUrl(boat.layout[0])} alt="" />
                    </div>
                    {boat.layout.length > 3 && (
                      <div className="boat-layout-gallery-row" onClick={() => this.openNewLightbox(3)}>
                        <img src={getImgUrl(boat.layout[3])} alt="" />
                      </div>
                    )}
                  </div>

                  {boat.layout.length > 1 && (
                    <div className="boat-layout-gallery-col">
                      <div className="boat-layout-gallery-row" onClick={() => this.openNewLightbox(1)}>
                        <img src={getImgUrl(boat.layout[1])} alt="" />
                      </div>
                      {boat.layout.length > 2 && (
                        <div className="boat-layout-gallery-row" onClick={() => this.openNewLightbox(2)}>
                          <img src={getImgUrl(boat.layout[2])} alt="" />
                        </div>
                      )}
                    </div>
                  )}

                  {boat.layout.length > 4 && (
                    <div className="boat-layout-gallery-see-more" onClick={() => this.openNewLightbox(4)}>
                      <FaImages />
                    </div>
                  )}
                </div>
              )}

              {/* <figure
                className="card-product m-0 boat-inner-layout-carousel boat--inner--layout--carousel inner--boat--dimension--div"
                target="_blank"
              >
                <div data-ride="carousel">
                  <div className="video-container">
                    <div className="carousel-item active boatInner-boatLayout">
                      <div className="boat-box">
                        <div className="boat-box-shadow ">
                          <div className="boat-img-box boat--inner--gallery--div">
                            <ImageGalleryComponent onClick={this.openNewLightbox} isInnerPage images={boat.layout} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </figure> */}
            </Grid>
          </div>
        )}
      </Grid>
    )
  }

  renderAboutBoat = () => {
    const { boat } = this.props

    return (
      <Grid item sm={12} className="inner--boat--info">
        <div className="width-100 pr-3 padding-inner-about">
          <p className="a-inner-fix mb-4 inner--boat--about color-black">
            <ReadMore className="text-justify">{boat && displayDefaultValue(boat.description)}</ReadMore>
          </p>
        </div>
      </Grid>
    )
  }

  renderMechanicalSystem = () => {
    const { boat } = this.props
    const {
      waterMarker,
      bowThruster,
      steeringSystem,
      stabilizerSystem,
      fuelType,
      oilWaterSeparator,
      noOfEngines,
      engineManufacturer,
      modelYear,
      fuelCapacity,
      engineModel,
      engineHp,
      freshWater,
      holdingCapacity,
      engineDrives,
      engineStroke,
    } = boat
    const {
      TITLE,
      WATER_MAKER,
      BOW_THRUSTER,
      STEERING_SYSTEM,
      STABILIZER_SYSTEM,
      FUEL_TYPE,
      OIL_WATER_SEPARATOR,
      NO_OF_ENGINE,
      ENGINE_MANUFACTURER,
      YEAR_MODEL,
      FUEL_CAPACITY,
      ENGINE_MODEL,
      ENGINE_HP,
      FRESH_WATER,
      HOLDING_CAPACITY,
      ENGINE_DRIVES,
      ENGINE_STROKE,
    } = mechanicalSystem

    return (
      <div className="inner-page-info-div">
        <Grid item md={12}>
          <div className="d-flex align-items-center inner--boat--systems-title">
            <div className="inner--title--icons">
              <img
                loading="lazy"
                src={require('../../assets/images/boatInner/Mechanical System.svg')}
                alt="Mechanical"
                className="d-flex h-100"
              />
            </div>
            <span className="inner--main--title">{TITLE}</span>
          </div>
          <Grid container>
            {waterMarker && (
              <Grid item xs={6}>
                <p className="boat-inner-info-desc boat--inner--info--div--desc mb-0">{displayDefaultValue(waterMarker)}</p>
                <h6 className="title-sm mb-20 title-description inner--info--title ">{WATER_MAKER}</h6>
              </Grid>
            )}
            {bowThruster && (
              <Grid item xs={6}>
                <p className="boat-inner-info-desc boat--inner--info--div--desc mb-0">{displayDefaultValue(bowThruster)}</p>
                <h6 className="title-sm mb-20 title-description inner--info--title ">{BOW_THRUSTER}</h6>
              </Grid>
            )}

            {steeringSystem && (
              <Grid item xs={6}>
                <p className="boat-inner-info-desc boat--inner--info--div--desc mb-0">{displayDefaultValue(steeringSystem)}</p>
                <h6 className="title-sm mb-20 title-description inner--info--title ">{STEERING_SYSTEM}</h6>
              </Grid>
            )}
            {stabilizerSystem && (
              <Grid item xs={6}>
                <p className="boat-inner-info-desc boat--inner--info--div--desc mb-0">
                  {displayDefaultValue(stabilizerSystem)}
                </p>
                <h6 className="title-sm mb-20 title-description inner--info--title ">{STABILIZER_SYSTEM}</h6>
              </Grid>
            )}

            {fuelType && fuelType.alias && (
              <Grid item xs={6}>
                <p className="boat-inner-info-desc boat--inner--info--div--desc mb-0">{displayDefaultValue(fuelType.alias)}</p>
                <h6 className="title-sm mb-20 title-description inner--info--title ">{FUEL_TYPE}</h6>
              </Grid>
            )}
            {noOfEngines && (
              <Grid item xs={6}>
                <p className="boat-inner-info-desc boat--inner--info--div--desc mb-0">{displayDefaultValue(noOfEngines)}</p>
                <h6 className="title-sm mb-20 title-description inner--info--title ">{NO_OF_ENGINE}</h6>
              </Grid>
            )}
            {oilWaterSeparator && (
              <Grid item xs={6}>
                <p className="boat-inner-info-desc boat--inner--info--div--desc mb-0">
                  {displayDefaultValue(oilWaterSeparator)}
                </p>
                <h6 className="title-sm mb-20 title-description inner--info--title ">{OIL_WATER_SEPARATOR}</h6>
              </Grid>
            )}
            {engineManufacturer && (
              <Grid item xs={6}>
                <p className="boat-inner-info-desc boat--inner--info--div--desc mb-0">
                  {displayDefaultValue(engineManufacturer)}
                </p>
                <h6 className="title-sm mb-20 title-description inner--info--title ">{ENGINE_MANUFACTURER}</h6>
              </Grid>
            )}
            {modelYear && (
              <Grid item xs={6}>
                <p className="boat-inner-info-desc boat--inner--info--div--desc mb-0">{displayDefaultValue(modelYear)}</p>
                <h6 className="title-sm mb-20 title-description inner--info--title ">{YEAR_MODEL}</h6>
              </Grid>
            )}
            {fuelCapacity && (
              <Grid item xs={6}>
                <p className="boat-inner-info-desc boat--inner--info--div--desc mb-0">{displayDefaultValue(fuelCapacity)}</p>
                <h6 className="title-sm mb-20 title-description inner--info--title ">{FUEL_CAPACITY}</h6>
              </Grid>
            )}
            {engineModel && (
              <Grid item xs={6}>
                <p className="boat-inner-info-desc boat--inner--info--div--desc mb-0">{displayDefaultValue(engineModel)}</p>
                <h6 className="title-sm mb-20 title-description inner--info--title ">{ENGINE_MODEL}</h6>
              </Grid>
            )}
            {engineHp && (
              <Grid item xs={6}>
                <p className="boat-inner-info-desc boat--inner--info--div--desc mb-0">{displayDefaultValue(engineHp)}</p>
                <h6 className="title-sm mb-20 title-description inner--info--title ">{ENGINE_HP}</h6>
              </Grid>
            )}
            {freshWater && (
              <Grid item xs={6}>
                <p className="boat-inner-info-desc boat--inner--info--div--desc mb-0">{displayDefaultValue(freshWater)}</p>
                <h6 className="title-sm mb-20 title-description inner--info--title ">{FRESH_WATER}</h6>
              </Grid>
            )}
            {holdingCapacity && (
              <Grid item xs={6}>
                <p className="boat-inner-info-desc boat--inner--info--div--desc mb-0">{displayDefaultValue(holdingCapacity)}</p>
                <h6 className="title-sm mb-20 title-description inner--info--title ">{HOLDING_CAPACITY}</h6>
              </Grid>
            )}
            {engineDrives && engineDrives.alias && (
              <Grid item xs={6}>
                <p className="boat-inner-info-desc boat--inner--info--div--desc mb-0">
                  {displayDefaultValue(engineDrives.alias)}
                </p>
                <h6 className="title-sm mb-20 title-description inner--info--title ">{ENGINE_DRIVES}</h6>
              </Grid>
            )}
            {engineStroke && engineStroke.alias && (
              <Grid item xs={6}>
                <p className="boat-inner-info-desc boat--inner--info--div--desc mb-0">
                  {displayDefaultValue(engineStroke.alias)}
                </p>
                <h6 className="title-sm mb-20 title-description inner--info--title ">{ENGINE_STROKE}</h6>
              </Grid>
            )}
          </Grid>
        </Grid>
      </div>
    )
  }

  renderElectricalSystem = () => {
    const { boat } = this.props
    const { output, generators, emergencyGenerator, batteryType, batteriesCount } = boat
    const { TITLE, OUTPUT, GENERATORS, EMERGENCY_GENERATORS, TYPES_OF_BATTERIES, NO_OF_BATTERIES, CONTENT } = electricalSystem
    return (
      <div className="inner-page-info-div">
        <Grid item md={12}>
          <div className="d-flex align-items-center inner--boat--systems-title">
            <div className="inner--title--icons">
              <img
                loading="lazy"
                src={require('../../assets/images/boatInner/Electrical system.svg')}
                alt="Electrical"
                className="d-flex h-100"
              />
            </div>
            <span className="inner--main--title">{TITLE}</span>
          </div>

          <Grid container>
            {output && (
              <Grid item xs={6}>
                <p className="boat-inner-info-desc boat--inner--info--div--desc mb-0 boat--inner--electrical--output">
                  {displayDefaultValue(output)}
                </p>
                <h6 className="title-sm mb-20 title-description inner--info--title ">{OUTPUT}</h6>
              </Grid>
            )}
            {batteriesCount && (
              <Grid item xs={6}>
                <p className="boat-inner-info-desc boat--inner--info--div--desc mb-0">{displayDefaultValue(batteriesCount)}</p>
                <h6 className="title-sm mb-20 title-description inner--info--title ">{NO_OF_BATTERIES}</h6>
              </Grid>
            )}

            {generators && (
              <Grid item xs={6}>
                <p className="boat-inner-info-desc boat--inner--info--div--desc mb-0">{displayDefaultValue(generators)}</p>
                <h6 className="title-sm mb-20 title-description inner--info--title ">{GENERATORS}</h6>
              </Grid>
            )}
            {emergencyGenerator && (
              <Grid item xs={6}>
                <p className="boat-inner-info-desc boat--inner--info--div--desc mb-0">
                  <h6 className="title-sm mb-20 title-description inner--info--title ">{EMERGENCY_GENERATORS}</h6>
                  {displayDefaultValue(emergencyGenerator)}
                </p>
              </Grid>
            )}

            {batteryType && (
              <Grid item xs={6}>
                <p className="boat-inner-info-desc boat--inner--info--div--desc mb-0">{displayDefaultValue(batteryType)}</p>
                <h6 className="title-sm mb-20 title-description inner--info--title ">{TYPES_OF_BATTERIES}</h6>
              </Grid>
            )}
          </Grid>
        </Grid>
      </div>
    )
  }

  convertFtToMeter = () => this.setState(prevState => ({ isValueInFt: !prevState.isValueInFt }))

  renderBoatEngine = () => {
    const { boat } = this.props

    const { ENGINE_MANUFACTURER, YEAR_MODEL, ENGINE_MODEL } = mechanicalSystem

    const { engineManufacturer, modelYear, engineModel } = boat

    const { TITLE } = boatEngine

    return (
      <>
        <div className="inner-page-info-div">
          <Grid item md={12}>
            <div className="d-flex align-items-center inner--boat--systems-title">
              <div className="inner--title--icons">
                <img
                  loading="lazy"
                  src={require('../../assets/images/boatInner/dimension.png')}
                  alt="Dimension"
                  className="d-flex h-100"
                />
              </div>
              <span className="inner--main--title">{TITLE}</span>
            </div>

            <Grid container className="pt-2 inner--boat--dimension--div">
              {engineManufacturer && (
                <Grid item xs={6} md={3}>
                  <p className="boat-inner-info-desc boat--inner--info--div--desc mb-0">
                    {displayDefaultValue(engineManufacturer)}
                  </p>
                  <h6 className="title-sm mb-20 title-description inner--info--title ">{ENGINE_MANUFACTURER}</h6>
                </Grid>
              )}
              {engineModel && (
                <Grid item xs={6} md={3}>
                  <p className="boat-inner-info-desc boat--inner--info--div--desc mb-0">{displayDefaultValue(engineModel)}</p>
                  <h6 className="title-sm mb-20 title-description inner--info--title ">{ENGINE_MODEL}</h6>
                </Grid>
              )}
              {modelYear && (
                <Grid item xs={6} md={3}>
                  <p className="boat-inner-info-desc boat--inner--info--div--desc mb-0">{displayDefaultValue(modelYear)}</p>
                  <h6 className="title-sm mb-20 title-description inner--info--title ">{YEAR_MODEL}</h6>
                </Grid>
              )}
            </Grid>
          </Grid>
        </div>
      </>
    )
  }

  renderDimensions = () => {
    const { boat } = this.props
    const { isValueInFt } = this.state
    const kayakCaoneType = isKayakCanoeType(boat)

    const {
      decks,
      draft,
      heightInFt,
      displacement,
      lengthInFt,
      widthInFt,
      weightInKg,
      mainSaloon,
      mainSaloonConvertible,
      beam,
      numberOfHeads,
      crewHeadsCount,
      crewCabinCount,
      crewBerthCount,
    } = boat
    const {
      TITLE,
      DECK_NUMBER,
      HEIGHT,
      LENGTH,
      WIDTH,
      WEIGHT,
      BEAM,
      DRAFT,
      DISPLACEMENT,
      MAIN_SALOON,
      MAIN_SALOON_CONVERTIBLE,
      NO_OF_HEADS,
      NO_OF_CREW_CABIN,
      NO_OF_CREW_BIRTHS,
      NO_OF_CREW_HEADS,
    } = boatDimensions

    return (
      <>
        <div className="inner-page-info-div">
          <Grid item md={12}>
            <div className="d-flex align-items-center inner--boat--systems-title">
              <div className="inner--title--icons">
                <img
                  loading="lazy"
                  src={require('../../assets/images/boatInner/Dimentions.svg')}
                  alt="Dimension"
                  className="d-flex h-100"
                />
              </div>
              <span className="inner--main--title">{TITLE}</span>
              <span className="ml-4 cursor-pointer convert--ft-to-m" onClick={this.convertFtToMeter}>
                {`Converter ${isValueInFt ? '(ft to m)' : '(m to ft)'}`}
              </span>
            </div>

            <Grid container className="pt-2 inner--boat--dimension--div">
              {heightInFt && (
                <Grid item xs={6} md={3}>
                  <p className="boat-inner-info-desc boat--inner--info--div--desc mb-0">
                    {displayDefaultValue(convertValueFromFtToMeter(heightInFt, isValueInFt))}
                  </p>
                  <h6 className="title-sm mb-20 title-description inner--info--title ">{HEIGHT}</h6>
                </Grid>
              )}
              {lengthInFt && (
                <Grid item xs={6} md={3}>
                  <p className="boat-inner-info-desc boat--inner--info--div--desc mb-0">
                    {displayDefaultValue(convertValueFromFtToMeter(lengthInFt, isValueInFt))}
                  </p>
                  <h6 className="title-sm mb-20 title-description inner--info--title ">{LENGTH}</h6>
                </Grid>
              )}
              {weightInKg && (
                <Grid item xs={6} md={3}>
                  <p className="boat-inner-info-desc boat--inner--info--div--desc mb-0">{displayDefaultValue(weightInKg)} kg</p>
                  <h6 className="title-sm mb-20 title-description inner--info--title ">{WEIGHT}</h6>
                </Grid>
              )}

              {widthInFt && (
                <Grid item xs={6} md={3}>
                  <p className="boat-inner-info-desc boat--inner--info--div--desc mb-0">
                    {displayDefaultValue(convertValueFromFtToMeter(widthInFt, isValueInFt))}
                  </p>
                  <h6 className="title-sm mb-20 title-description inner--info--title ">{WIDTH}</h6>
                </Grid>
              )}
              {!kayakCaoneType && (
                <>
                  {decks && (
                    <Grid item xs={6} md={3}>
                      <p className="boat-inner-info-desc boat--inner--info--div--desc mb-0">{displayDefaultValue(decks)}</p>
                      <h6 className="title-sm mb-20 title-description inner--info--title ">{DECK_NUMBER}</h6>
                    </Grid>
                  )}

                  {mainSaloonConvertible && (
                    <Grid item xs={6} md={3}>
                      <p className="boat-inner-info-desc boat--inner--info--div--desc mb-0">
                        {displayDefaultValue(mainSaloonConvertible.alias)}
                      </p>
                      <h6 className="title-sm mb-20 title-description inner--info--title ">{MAIN_SALOON_CONVERTIBLE}</h6>
                    </Grid>
                  )}

                  {beam && (
                    <Grid item xs={6} md={3}>
                      <p className="boat-inner-info-desc boat--inner--info--div--desc mb-0">
                        {displayDefaultValue(convertValueFromFtToMeter(beam, isValueInFt))}
                      </p>
                      <h6 className="title-sm mb-20 title-description inner--info--title ">{BEAM}</h6>
                    </Grid>
                  )}

                  {draft && (
                    <Grid item xs={6} md={3}>
                      <p className="boat-inner-info-desc boat--inner--info--div--desc mb-0">
                        {displayDefaultValue(convertValueFromFtToMeter(draft, isValueInFt))}
                      </p>
                      <h6 className="title-sm mb-20 title-description inner--info--title ">{DRAFT}</h6>
                    </Grid>
                  )}

                  {displacement && (
                    <Grid item xs={6} md={3}>
                      <p className="boat-inner-info-desc boat--inner--info--div--desc mb-0">
                        {displayDefaultValue(displacement)}
                      </p>
                      <h6 className="title-sm mb-20 title-description inner--info--title ">{DISPLACEMENT}</h6>
                    </Grid>
                  )}

                  {mainSaloon && (
                    <Grid item xs={6} md={3}>
                      {mainSaloon && (
                        <p className="boat-inner-info-desc boat--inner--info--div--desc mb-0">
                          {displayDefaultValue(mainSaloon.alias)}
                        </p>
                      )}
                      <h6 className="title-sm mb-20 title-description inner--info--title ">{MAIN_SALOON}</h6>
                    </Grid>
                  )}

                  {numberOfHeads > 0 && (
                    <Grid item xs={6} md={3}>
                      <p className="boat-inner-info-desc boat--inner--info--div--desc mb-0">
                        {displayDefaultValue(numberOfHeads)}
                      </p>
                      <h6 className="title-sm mb-20 title-description inner--info--title ">{NO_OF_HEADS}</h6>
                    </Grid>
                  )}

                  {crewCabinCount > 0 && (
                    <Grid item xs={6} md={3}>
                      <p className="boat-inner-info-desc boat--inner--info--div--desc mb-0">
                        {displayDefaultValue(crewCabinCount)}
                      </p>
                      <h6 className="title-sm mb-20 title-description inner--info--title ">{NO_OF_CREW_CABIN}</h6>
                    </Grid>
                  )}

                  {crewBerthCount > 0 && (
                    <Grid item xs={6} md={3}>
                      <p className="boat-inner-info-desc boat--inner--info--div--desc mb-0 mb-0">
                        {displayDefaultValue(crewBerthCount)}
                      </p>
                      <h6 className="title-sm mb-20 title-description inner--info--title ">{NO_OF_CREW_BIRTHS}</h6>
                    </Grid>
                  )}

                  {crewHeadsCount > 0 && (
                    <Grid item xs={6} md={3}>
                      <p className="boat-inner-info-desc boat--inner--info--div--desc mb-0 mb-0">
                        {displayDefaultValue(crewHeadsCount)}
                      </p>
                      <h6 className="title-sm mb-20 title-description inner--info--title ">{NO_OF_CREW_HEADS}</h6>
                    </Grid>
                  )}
                </>
              )}
            </Grid>
          </Grid>
        </div>
      </>
    )
  }

  renderAmenities = () => {
    const { boat } = this.props
    const { amenities } = boat

    return (
      <Grid container className="boat-amenities">
        <Grid item md={12}>
          <div className="d-flex align-items-center inner--boat--systems-title">
            <div className="inner--title--icons">
              <img
                loading="lazy"
                src={require('../../assets/images/boatInner/Amenities.svg')}
                alt="Amenties"
                className="d-flex h-100"
              />
            </div>
            <span className="inner--main--title">Amenities</span>
          </div>

          {amenities && amenities.length > 0 && (
            <div className="col-md-12 pt-2 inner--boat--amenities--div pl-0">
              <Grid container>
                <div className="boat-feature-items">
                  {amenities &&
                    amenities.length &&
                    amenities.map((item, index) => {
                      return (
                        <div className="boat-feature-item">
                          <SmallCard
                            index={index}
                            renderIcon={item && icons[snakeCase(item.alias.replace(/[{()}]/g, ''))]}
                            elseDynamicRenderImage={item.icon === null ? null : encodeURI(item.icon?.largeUrl)}
                            elseRenderImage={iconImages[snakeCase(item.alias)]}
                            title={item.alias}
                            height="20px"
                          />
                        </div>
                      )
                    })}
                </div>
              </Grid>
            </div>
          )}
        </Grid>
      </Grid>
    )
  }

  renderAccessories = () => {
    const { boat } = this.props
    const { accessories } = boat

    return (
      <Grid container>
        <Grid item md={12}>
          <div className="d-flex align-items-center inner--boat--systems-title">
            <div className="inner--title--icons">
              <img
                loading="lazy"
                src={require('../../assets/images/boatInner/Accessories.svg')}
                alt="Accessories"
                className="d-flex h-100"
              />
            </div>
            <span className="inner--main--title">Accessories</span>
          </div>

          {accessories && accessories.length > 0 && (
            <div className="col-md-12 pt-2 inner--boat--amenities--div pl-0">
              <Grid container>
                <div className="boat-feature-items">
                  {accessories &&
                    accessories.length &&
                    accessories.map((item, index) => {
                      return (
                        <div className="boat-feature-item">
                          <SmallCard
                            index={index}
                            renderIcon={item && icons[snakeCase(item.alias)]}
                            elseDynamicRenderImage={item.icon === null ? null : encodeURI(item.icon?.largeUrl)}
                            elseRenderImage={iconImages[snakeCase(item.alias)]}
                            title={item.alias}
                            height="20px"
                          />
                        </div>
                      )
                    })}
                </div>
              </Grid>
            </div>
          )}
        </Grid>
      </Grid>
    )
  }

  renderNavigationEquipments = () => {
    const { boat } = this.props
    const { navigationEquipments } = boat

    return (
      <Grid container>
        <Grid item sm={12}>
          <div className="d-flex align-items-center inner--boat--systems-title">
            <div className="inner--title--icons">
              <img
                loading="lazy"
                src={require('../../assets/images/boatInner/Navigation equipment.svg')}
                alt="Navigation"
                className="d-flex h-100"
              />
            </div>
            <span className="inner--main--title">Navigation Equipment</span>
          </div>

          {navigationEquipments && navigationEquipments.length > 0 && (
            <div className="col-md-12 pt-2 inner--boat--amenities--div pl-0">
              <Grid container>
                <div className="boat-feature-items">
                  {navigationEquipments &&
                    navigationEquipments.length &&
                    navigationEquipments.map((item, index) => {
                      return (
                        <div className="boat-feature-item">
                          <SmallCard
                            index={index}
                            renderIcon={(item && icons[snakeCase(item.alias.replace(/[{()}]/g, ''))]) || 'adam-ship-radar'}
                            elseDynamicRenderImage={item.icon === null ? null : encodeURI(item.icon?.largeUrl)}
                            elseRenderImage={iconImages[snakeCase(item.alias.replace(/[{()}]/g, ''))]}
                            title={item.alias}
                            height="20px"
                          />
                        </div>
                      )
                    })}
                </div>
              </Grid>
            </div>
          )}
        </Grid>
      </Grid>
    )
  }

  renderCommercial1 = () => {
    const { boat } = this.props
    const kayakCaoneType = isKayakCanoeType(boat)
    const boatUsageMaintanance =
      boat.usage || (boat.lastMaintenance && !kayakCaoneType) || (boat.accidentDescribe && !kayakCaoneType)
    return (
      <>
        {(boatUsageMaintanance || boat.internationalWarranty) && (
          <Grid item sm={4} className="inner--boat--location--system pt-0 pb-0 boat--inner--related--videos--right--div">
            {boat.usage && (
              <div className="boat-inner-boat-info mb-4">
                <span className="mb-2 col-12 f-500 pl-0 inner--main--title">Boat was used for</span>
                <p className="boat-inner-info-desc pl-0 col-12 boat--inner--info--div--desc mb-0 color-black">
                  <ReadMore className="text-justify">{displayDefaultValue(boat.usage)}</ReadMore>
                </p>
              </div>
            )}

            {boat.lastMaintenance && !kayakCaoneType && (
              <div className="boat-inner-boat-info mb-4">
                <span className=" mt-4 mb-2 col-12 f-500 pl-0 inner--main--title">Last time Maintenance was</span>
                <p className="boat-inner-info-desc pl-0 col-12 boat--inner--info--div--desc mb-0 color-black">
                  <ReadMore className="text-justify ">{displayDefaultValue(formattedDate(boat.lastMaintenance))}</ReadMore>
                </p>
              </div>
            )}

            {boat.accidentDescribe && !kayakCaoneType && (
              <div className="boat-inner-boat-info mb-4">
                <span className="mb-2 col-12 f-500 pl-0 inner--main--title">Boat Damage Description</span>
                <p className="boat-inner-info-desc pl-0 col-12 boat--inner--info--div--desc mb-0 color-black">
                  <ReadMore className="text-justify">{displayDefaultValue(boat.accidentDescribe)}</ReadMore>
                </p>
              </div>
            )}

            {boat.internationalWarranty && (
              <div className="boat-inner-boat-info">
                <span className="mb-2 col-12 f-500 pl-0 inner--main--title">
                  The manufacturer provides {boat.internationalWarrantyValidity}{' '}
                  {+boat.internationalWarrantyValidity < 2 ? 'month' : 'months'} international warranty
                </span>
                <p className="boat-inner-info-desc pl-0 col-12 boat--inner--info--div--desc mb-0 color-black">
                  <ReadMore className="text-justify">{displayDefaultValue(boat.internationalWarrantyTerms)}</ReadMore>
                </p>
              </div>
            )}
          </Grid>
        )}
      </>
    )
  }

  renderCommercial2 = () => {
    const { boat } = this.props
    const kayakCaoneType = isKayakCanoeType(boat)
    const boatHistoryReview =
      (boat.accidentHistory && !kayakCaoneType) || (boat?.repairHistory && !kayakCaoneType) || boat?.boatReview
    return (
      <>
        {boatHistoryReview && (
          <Grid
            item
            sm={4}
            className={`${
              !this.renderCommercial1().boatUsageMaintanance ? 'boat--inner--commerciall-right--div' : ''
            } inner--boat--location--system pt-0 pb-0`}
          >
            <div className="boat-inner-boat-info">
              {boat.accidentHistory && !kayakCaoneType && (
                <>
                  <span className="mb-2 padding-zero-mob col-12 f-500 inner--main--title">Any Accident History</span>
                  <p className="boat-inner-info-desc col-12 boat--inner--info--div--desc mb-0 color-black">
                    <ReadMore className="text-justify ">{displayDefaultValue(boat.accidentHistory)}</ReadMore>
                  </p>
                </>
              )}

              {boat?.repairHistory && !kayakCaoneType && (
                <>
                  <span className=" mt-4 mb-2 col-12 padding-zero-mob f-500 inner--main--title">Repair History if any</span>
                  <p className="boat-inner-info-desc col-12 boat--inner--info--div--desc mb-0 color-black">
                    <ReadMore className="text-justify ">{displayDefaultValue(boat.repairHistory)}</ReadMore>
                  </p>
                </>
              )}
            </div>
            {boat?.boatReview && (
              <div className="boat-inner-boat-info mt-5">
                <span className="mb-2 col-12 f-500 padding-zero-mob inner--main--title">Why To Buy This Boat</span>
                <p className="boat-inner-info-desc col-12 boat--inner--info--div--desc mb-0 color-black">
                  <ReadMore className="text-justify ">{displayDefaultValue(boat.boatReview)}</ReadMore>
                </p>
              </div>
            )}
          </Grid>
        )}
      </>
    )
  }

  renderRatingAndReview = () => {
    const { boat, currentUser, reviews } = this.props
    if (boat?.seller) {
      return <InnerRating module={boat} currentUser={currentUser} isAddReview user={boat?.seller} />
    }
  }

  getBoatPDf = () => {
    const { boat, getBoatPdf } = this.props
    getBoatPdf({ id: boat.id })
  }

  mailFlagHandler = () => {
    this.setState(prevState => ({
      isMailEnabled: !prevState.isMailEnabled,
    }))
  }

  redirectHandler = () => {
    const { history } = this.props
    verificationRedirection('/', history)
  }

  render() {
    const {
      boat,
      currentUser,
      createAuctionBid,
      boatShipperList,
      boatCostEstimation,
      mailBoatCostEstimation,
      addFeedback,
      reviews,
      addLikes,
      isError,
      errorMessage,
      clearErrorMessageShow,
      boatAuctionRoom,
      getPdfSuccess,
      pdf,
      getModulePdf,
      createSalesEngine,
      isPdfLoading,
      getUserReportByModule,
      addDepositForBid,
      createSalesEngineLoading,
      countryDetails,
      // isFacebookShare,
    } = this.props

    const boatCountry = boat?.address?.length && boat?.address[0]?.country
    const jetSkiType = boat?.boatType?.name === boatType.jetSki
    const kayakCaoneType = isKayakCanoeType(boat)

    const {
      estimateCostFlag,
      isGallery,
      isLayoutGallery,
      boatByCityList,
      relatedVideoPopUp,
      isMailEnabled,
      boatId,
      isLoading,
      isBidNow,
      loginModal,
      loading,
      imageIndex,
      stockModal,
      underVerification,
      verificationStatus,
      openDepositModal,
      newThumbnail,
    } = this.state

    const sellerRole = boat?.seller?.role?.aliasName

    const {
      commonAddress: { geometricLocation = null, city, country },
    } = getAddress(boat?.address)

    const { boatName, description, images } = boat

    const boatTitleName = displayDefaultValue(boatName)
    return (
      <>
        <Layout className="boat-inner-responsive not--scroll-auto boat-inner-top-scroll-space adam-top-spa-res">
          <LoginModal open={loginModal} close={this.handlerLoginModal} />
          <QuantityModal
            open={stockModal}
            handleCloseModal={this.stockModalHandler}
            createSalesEngine={createSalesEngine}
            boat={boat}
            createSalesEngineLoading={createSalesEngineLoading}
          />
          {underVerification && popUpMessage(verificationStatus?.message, 'Alert', true, null, null, this.redirectHandler)}
          {(boat && !boat.hasOwnProperty('id')) || loading ? (
            <Grid container className="container-fluid">
              <SkeletonLoader type={skeletonType.innerPage} />
            </Grid>
          ) : (
            <>
              {/* adid section start */}
              <Grid container className="container-fluid clr-fluid justify-center boat-inner-sticky sticky-on-top">
                <Grid container className="w-85vw buy-inner p-0 inner--boat--header--section">
                  <Grid item className="d-flex pr-0 pt-3 pb-3 pl-0 inner--boat--header--div" style={{ flex: 1 }}>
                    <div className="mr-auto  align-self-center">
                      <h1 className="d-flex align-items-center mb-0">
                        <span className="boat-inner-name mr-1 boat--inner--header--year">{boat.yearBuilt}</span>
                        <span className="boat-inner-name ml-1 boat--inner--header--name">
                          {getBoatTypeName(boat)}
                          {!kayakCaoneType && ` | ${boat.lengthInFt} ft `}
                        </span>
                        <span className="badge badge-light color-black ml-2 header-small-font mb-0">
                          {boat.boatStatus && boat.boatStatus.alias && boat.boatStatus.alias}
                        </span>
                      </h1>
                      {/* <div className="d-flex align-items-center inner-review mt-0">
                        <RatingComponent rating={boat?.rating} className="rating-clr" />
                        <h6 className="mr-2 mb-0 rating-avg header--rating--count--reviews boat--inner--rating--count--reviews">
                          {boat?.rating == null ? 0 : boat?.rating.toFixed(1)}
                        </h6>
                        <span className="rating-avg gray-light pl-1 header--rating--count--reviews boat--inner--rating--count--reviews">
                          {boat?.rating_count && formatCash(boat?.rating_count)} {textCustomerReview}
                        </span>
                      </div> */}
                      <div className="d-flex">
                        {boat?.seaTrial && <span className="sea--trial-text mr-2">{textSeaTrial}</span>}
                        {!boat?.isSalesEngineCreated &&
                          sellerRole !== userRoles.BOAT_OWNER &&
                          checkBrokerAccessibility(boat, sellerRole) &&
                          (isYachtType(boat?.boatType?.name) && !boat?.stockQuantity ? null : (
                            <span className="sea--trial-text">
                              {boat?.stockQuantity > 0 ? (
                                <span className="stock--quantity-custom-align-ment">
                                  <span className="font--count-available">
                                    {boat?.stockQuantity > 0 ? boat?.stockQuantity : 0}
                                  </span>{' '}
                                  {getBoatTypeName(boat)} available in stock{' '}
                                </span>
                              ) : boat?.boatStatus?.alias === boatType.used ? (
                                soldOut
                              ) : (
                                noStockAvailable
                              )}
                            </span>
                          ))}
                        {!boat?.isSalesEngineCreated && sellerRole === userRoles.BOAT_OWNER && boat?.stockQuantity <= 0 && (
                          <span className="sea--trial-text">{soldOut}</span>
                        )}
                      </div>
                    </div>
                  </Grid>

                  <div className="wishlist--section">
                    {/* {boat?.isSalesEngineCreated && <div> // INFO: it will be updated with below condition
                      <span className="sea--trial-text under--sales--engine--label">Under Sales Process</span>
                    </div>} */}

                    {boat?.isSalesEngineCreated && boat?.salesEngineStatus && (
                      <div className="d-flex baot-inner-rating-section-center">
                        <span className="sea--trial-text under--sales--engine--label">{boat?.salesEngineStatus}</span>
                      </div>
                    )}

                    {boat?.auctionRoomWinner === null &&
                      boat?.isAuctionRoomCreated &&
                      !boat.auctionApproved &&
                      !boat.auctionDeclined && (
                        <div className="d-flex justify-content-center">
                          <span className="sea--trial-text under--sales--engine--label">
                            Boat is now in auction for admin approval
                          </span>
                        </div>
                      )}
                  </div>
                  <Grid item className="boatinner-buy-cont" style={{ flex: 1 }}>
                    {boat?.isAuctionRoomCreated === false &&
                      boat?.auctionApproved === false &&
                      boat &&
                      this.renderBuyNowSection(boat.seller)}
                  </Grid>
                </Grid>
              </Grid>
              {/* adid section end */}

              {/* auction bid section start */}
              {boatAuctionRoom && boatAuctionRoom.id && (
                <AuctionBox
                  boatAuctionRoom={boatAuctionRoom}
                  boat={boat}
                  isBidNow={isBidNow}
                  bidHandler={this.bidHandler}
                  createAuctionBid={createAuctionBid}
                  isError={isError}
                  errorMessage={errorMessage}
                  clearErrorMessageShow={clearErrorMessageShow}
                  addDepositForBid={addDepositForBid}
                  depositModalHandler={this.depositModalHandler}
                  openDepositModal={openDepositModal}
                  showBitNowButton={!isEmpty(countryDetails) && !countryDetails.auctionStatus}
                />
              )}
              {/* auction bid section end */}

              {/* banner start */}
              <Grid container className="w-85vw mx-auto h-600 position-relative inner--home--banner--like--div">
                {/* <div className="inner-banner-views-div inner-banner-views-div-2 inner-baner-view-mob mr-2 d-flex font13 gray-dark img-show-text boat--inner--banner--likes">
                  <div className="banner-count-text inner--banner--count">{thousandFormatter(boat.pageVisits.length)} VIEWS</div>
                </div>
                <div className="position-absolute inner-banner-top-social-div inner--banner--top--div">
                  <div className="inner-banner-views-div d-flex font13 gray-dark img-show-text boat--inner--banner--likes">
                    <div className="d-flex justify-content-center align-items-center mr-2  inner--banner--icons-div mr-2">
                      <CommonTooltip component={ctProps => (
                        <div data-tooltip-id={ctProps.id} data-tooltip-content="Photos" className="inner--banner--icons d-flex">
                          <img loading="lazy" src={require('../../assets/images/rentInner/image-count.png')} className="h-100" alt="Photos" />
                        </div>
                      )} />
                    </div>
                    <div className="banner-count-text inner--banner--count">{boat.images.length}</div>
                  </div>
                </div> */}
                <Grid item xs={6} className="hidden border-radius-padding-img boat-inner-first-img">
                  <div className="boatInner-banner-section cursor-pointer">
                    <BoatInnerGallery
                      onClick={() => this.openLightbox(0)}
                      img={
                        boat.images && boat.images.length > 0
                          ? encodeURI(boat.images[0]?.largeUrl)
                          : require('../../assets/images/marinaStorageInner/Light-Grey-Background.jpg')
                      }
                    >
                      <div className="hover--effect"></div>
                    </BoatInnerGallery>
                  </div>
                </Grid>

                <Grid item xs={6} className="h-100">
                  <Grid container className="h-50">
                    <Grid item xs={6} className="four-inner-bg h-100">
                      <div className="boatInner-banner-section cursor-pointer">
                        <BoatInnerGallery
                          onClick={() => this.openLightbox(1)}
                          img={
                            boat.images && boat.images.length > 1
                              ? encodeURI(boat.images[1]?.largeUrl)
                              : require('../../assets/images/marinaStorageInner/Light-Grey-Background.jpg')
                          }
                        >
                          <div className="hover--effect"></div>
                        </BoatInnerGallery>
                      </div>
                    </Grid>
                    <Grid item xs={6} className="four-inner-bg h-100 boatInner-last-upper-img">
                      <div className="boatInner-banner-section cursor-pointer">
                        <BoatInnerGallery
                          onClick={() => this.openLightbox(2)}
                          img={
                            boat.images && boat.images.length > 2
                              ? encodeURI(boat.images[2]?.largeUrl)
                              : require('../../assets/images/marinaStorageInner/Light-Grey-Background.jpg')
                          }
                        >
                          <div className="hover--effect"></div>
                        </BoatInnerGallery>
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container className="h-50">
                    <Grid item xs={6} className="four-inner-bg h-100 dddd">
                      <div className="boatInner-banner-section cursor-pointer">
                        <BoatInnerGallery
                          onClick={() => this.openLightbox(3)}
                          img={
                            boat.images && boat.images.length > 3
                              ? encodeURI(boat.images[3]?.largeUrl)
                              : require('../../assets/images/marinaStorageInner/Light-Grey-Background.jpg')
                          }
                        >
                          <div className="hover--effect"></div>
                        </BoatInnerGallery>
                      </div>
                    </Grid>
                    <Grid item xs={6} className="four-inner-bg h-100 boatInner-last-lower-img">
                      <div className="boatInner-banner-section cursor-pointer">
                        <BoatInnerGallery
                          onClick={() => this.openLightbox(4)}
                          img={
                            boat.images && boat.images.length > 4
                              ? encodeURI(boat.images[4]?.largeUrl)
                              : require('../../assets/images/marinaStorageInner/Light-Grey-Background.jpg')
                          }
                        >
                          <div className="hover--effect"></div>
                        </BoatInnerGallery>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>

                <div className="inner--action--btns">
                  <ShareAndWishlist
                    style={{ marginTop: '11px' }}
                    moduleId={boat.id}
                    noTopSpace
                    moduleType={wishlistModuleEnum.BOAT}
                    ownerId={boat?.seller?.id}
                    shareWithUsers={data => this.shareBoatWithUsers(data)}
                    getUserReportByModule={getUserReportByModule}
                    getPdfSuccess={getPdfSuccess}
                    pdf={pdf}
                    getModulePdf={getModulePdf}
                    isInner
                    isClickble={boat.adStatus}
                    hideFlag
                    hidePDF
                  />
                </div>
              </Grid>
              {/* banner end */}

              <div className="w-85vw mx-auto banner-b-btns-wrp">
                <LikeComponent
                  likes={boat.likes}
                  moduleId={boat.id}
                  likeModule={likeModuleEnum.BOAT}
                  addLikes={addLikes}
                  noAbsolute
                />
                <div className="banner-b-btn">{thousandFormatter(boat.pageVisits?.length || 0)} VIEWS</div>
                <CommonTooltip
                  component={ctProps => (
                    <div data-tooltip-id={ctProps.id} data-tooltip-content="Photos" className="banner-b-btn">
                      <i className="fas fa-images"></i>
                      {boat?.images?.length}
                    </div>
                  )}
                />
              </div>

              <Grid container className="container-fluid clr-fluid w-85vw p-0">
                <Grid container className="inner--about--boat p-sec">
                  <Grid item xs={12} className="d-flex flex-column">
                    <Grid container className="inner--boat--shipping--div boat--inner--desc" style={{ gap: 40 }}>
                      <Grid item xs={12} md={5}>
                        {boat?.description && <span className="inner--main--title">About The Boat</span>}

                        {boat?.description && this.renderAboutBoat()}

                        {boat && this.renderBoatInfo()}
                      </Grid>
                      <Grid item xs={12} sm={4} className="boat--inner--user--card mx-auto">
                        {boat?.seller?.id && <UserCardSale seller={boat.seller} boat={boat} user={currentUser} />}
                      </Grid>
                      <Grid item sm={12} className="boat--inner--shipping--payment">
                        <Grid container>
                          <Grid item xs={12} md={4}>
                            <div className="boat--inner--shipping--title">
                              <div
                                onClick={() =>
                                  boat?.totalShippers > 0 && redirectRouteHandler(`/boat-shipper/${boat.id}/${boatCountry}`)
                                }
                                className="inner--main--title inner-btn-title underline--on--hover"
                              >
                                <div>
                                  <img loading="lazy" src={require('./image/shipper-icon.svg')} alt="Shipper icon" />{' '}
                                  <span style={{ fontWeight: 'bold', fontSize: '1.5em' }}>{boat?.totalShippers}</span>
                                </div>
                                <div>Boat Shippers</div>
                              </div>
                            </div>
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <div className="boat--inner--shipping--title">
                              <div
                                onClick={() =>
                                  boat?.totalSurveyors > 0 && redirectRouteHandler(`/boat-surveyor/${boat.id}/${boatCountry}`)
                                }
                                className="inner--main--title inner-btn-title underline--on--hover"
                              >
                                <div>
                                  <img loading="lazy" src={require('./image/surveyor-icon.svg')} alt="Shipper" />{' '}
                                  <span style={{ fontWeight: 'bold', fontSize: '1.5em' }}>{boat?.totalSurveyors}</span>
                                </div>
                                <div>Surveyors in {boatCountry}</div>
                              </div>
                            </div>
                          </Grid>

                          {boat?.boatType?.name !== boatType.superYacht && boat?.boatType?.name !== boatType.yacht && (
                            <Grid item xs={12} md={4}>
                              <div className="boat--inner--shipping--title">
                                <div
                                  onClick={() => {
                                    this.getConvertedPriceEstimate()
                                    boatCostEstimation && boatCostEstimation.boatPrice && this.setEstimateCost(true)
                                  }}
                                  className="inner--main--title inner-btn-title underline--on--hover h-100"
                                >
                                  <div>
                                    <img src={require('./image/estimate-icon.svg')} alt="Payment" /> Estimate Cost | Financing
                                  </div>
                                </div>
                              </div>

                              <DialogCard
                                showServiceProf
                                isPdfLoading={isPdfLoading}
                                isOpen={estimateCostFlag}
                                boatCostEstimation={boatCostEstimation}
                                mailFlagHandler={this.mailFlagHandler}
                                isMailEnabled={isMailEnabled}
                                loadingMail={this.loadingMail}
                                sendMail={mailBoatCostEstimation}
                                boatId={boatId}
                                boat={boat}
                                isLoading={isLoading}
                                onClose={isChat => {
                                  ;(isChat ? getId(currentUser) : true) && this.setEstimateCost(!estimateCostFlag)
                                }}
                              />
                            </Grid>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  {!!this.props.getServiceTags_data?.length && (
                    <Grid item xs={12}>
                      <div className="boat-service-tags">
                        {document.querySelector('.boat-service-tags-wrp')?.scrollWidth >
                          document.querySelector('.boat-service-tags-wrp')?.clientWidth && (
                          <button
                            type="button"
                            className="boat-service-tags-btn boat-service-tags-prev-btn"
                            onClick={() => {
                              const width = document.querySelector('.boat-service-tags-cont').clientWidth
                              document.querySelector('.boat-service-tags-cont').scrollBy(-width / 2, 0)
                            }}
                          >
                            <i className="fas fa-angle-left"></i>
                          </button>
                        )}
                        <div className="boat-service-tags-cont">
                          <div className="boat-service-tags-wrp">
                            {this.props.getServiceTags_data.map(tag => (
                              <div className="boat-service-tag" onClick={() => viewServiceBoatHandler(tag)}>
                                <div className="boat-service-tag-img">
                                  <img src={get(tag, 'user.companyLogo.url', '')} alt={get(tag, 'user.companyName', '')} />
                                </div>
                                <div className="boat-service-tag-txt">
                                  <div className="boat-service-tag-title">{get(tag, 'user.companyName', '')}</div>
                                  <div className="boat-service-tag-subtitle">
                                    {get(tag, 'taggedServiceProvide[0].name', '')}
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                        {document.querySelector('.boat-service-tags-wrp')?.scrollWidth >
                          document.querySelector('.boat-service-tags-wrp')?.clientWidth && (
                          <button
                            type="button"
                            className="boat-service-tags-btn boat-service-tags-next-btn"
                            onClick={() => {
                              const width = document.querySelector('.boat-service-tags-cont').clientWidth
                              document.querySelector('.boat-service-tags-cont').scrollBy(width / 2, 0)
                            }}
                          >
                            <i className="fas fa-angle-right"></i>
                          </button>
                        )}
                      </div>
                    </Grid>
                  )}
                </Grid>

                <hr />

                <Grid container className="width-100 p-sec boat-inner-map-full-width boat--inner--desc">
                  <Grid
                    item
                    sm={
                      kayakCaoneType
                        ? 12
                        : getGridCountForMapElement(boat?.mechanicalSystemLength, boat?.electricalSystemLength)
                    }
                    className={`${
                      getGridCountForMap(boat?.mechanicalSystemLength, boat?.electricalSystemLength) === 12 || kayakCaoneType
                        ? 'pr-boat-inner-0'
                        : ''
                    } boat--inner--location--system inner-boat-graph-first-cild pt-0 pb-0`}
                  >
                    <div className="">
                      <div className="d-flex align-items-center inner--boat--systems-title flex-wrap">
                        <div className="inner--title--icons">
                          <img
                            loading="lazy"
                            src={require('../../assets/images/boatInner/Location.svg')}
                            alt="Location"
                            className="d-flex h-100"
                          />
                        </div>
                        <span className="inner--main--title boat--inner--location--title">Boat Location</span>
                        <span className="boat-inner-info-desc boat--inner--info--div--desc mb-0 ml-2">
                          {cityCountryNameFormatter(city, country)}
                        </span>
                      </div>
                    </div>
                    <div className="boat--inner--map--div">
                      {boat && (
                        <GoogleMarker
                          isInnerPage={false}
                          markers={getSingleBoatMarker(boat)}
                          geometricLocation={geometricLocation}
                          isRedirectToMap
                        />
                      )}
                    </div>
                  </Grid>
                  {boat?.mechanicalSystemLength > 0 && (
                    <Grid
                      item
                      sm={getGridCountForMap(boat?.mechanicalSystemLength, boat?.electricalSystemLength)}
                      className="inner--boat--location--system inner-boat-second-child pt-0 pb-0 xyy"
                    >
                      {!kayakCaoneType && this.renderMechanicalSystem()}
                    </Grid>
                  )}
                  {boat?.electricalSystemLength > 0 && (
                    <Grid
                      item
                      sm={getGridCountForMap(boat?.mechanicalSystemLength, boat?.electricalSystemLength)}
                      className="inner--boat--location--system inner-boat-second-child pt-0 pb-0"
                    >
                      {!jetSkiType && !kayakCaoneType && this.renderElectricalSystem()}
                    </Grid>
                  )}
                </Grid>

                <hr />

                <Grid container className="width-100 p-sec boat--inner--desc boat--inner--desc--div ">
                  <Grid item sm={8} className="boat--inner--location--system boat-inner-padding-right-zero pt-0 pb-0">
                    <Grid item sm={12} className="boat--inner--location--system boat-inner-padding-right-zero pt-0 pb-0">
                      {boat && this.renderDimensions()}
                    </Grid>

                    <Grid item sm={12} className="boat--inner--location--system pt-0 pb-0">
                      {boat && kayakCaoneType && this.renderBoatEngine()}
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    sm={4}
                    className="inner--boat--location--system pt-0 pb-0 pr-0 boat--inner--location--system--section"
                  >
                    {boat && this.renderBoatLayout()}
                  </Grid>
                </Grid>
                {/* <hr className="mt-4 hr--margin" /> */}

                {!jetSkiType && !kayakCaoneType && (
                  <>
                    <Grid container className="width-100 inner-facilities-div-boxes-parent p-sec boat--inner--facilities">
                      <Grid item sm={4} className="inner-facilities-div-boxes">
                        <div className="boat-inner-facilities">
                          <div>{boat?.amenities?.length ? this.renderAmenities() : ''}</div>
                        </div>
                      </Grid>
                      <Grid item sm={4} className="inner-facilities-div-boxes">
                        <div className="boat-inner-facilities">
                          <div>{boat?.accessories?.length ? this.renderAccessories() : ''}</div>
                        </div>
                      </Grid>
                      <Grid item sm={4} className="inner-facilities-div-boxes">
                        <div className="boat-inner-facilities">
                          <div>{boat?.navigationEquipments?.length ? this.renderNavigationEquipments() : ''}</div>
                        </div>
                      </Grid>
                    </Grid>
                  </>
                )}

                <Grid container className="p-sec inner--boat--related--videos--div">
                  {boat && boat.video && (
                    <Grid item sm={12} className="pb-0">
                      <div className="d-flex align-items-center inner--boat--systems-title boat--inner--related--video">
                        <div className="inner--title--icons">
                          <img
                            src={require('../../assets/images/boatInner/Related Videos.svg')}
                            alt="Related Videos"
                            className="d-flex h-100"
                          />
                        </div>
                        <span className="inner--main--title">Related Videos</span>
                      </div>
                    </Grid>
                  )}

                  {boat && boat.video && (
                    <Grid
                      item
                      sm={4}
                      className="boat--inner--location--system pb-0 pt-0 boat--inner--related--videos--left--div"
                    >
                      {boat.video && (
                        <>
                          <HomPageVideoStyle
                            img={newThumbnail}
                            className="boat-inner-related-video"
                            onClick={this.relatedVideoHandler}
                          >
                            <PlayCircleOutlineIcon className="playVideo-icon w-auto cursor-pointer" />
                          </HomPageVideoStyle>
                          <VideoModel
                            videoFlag={relatedVideoPopUp}
                            videoUrl={boat.video}
                            thumbnail={newThumbnail}
                            closeVideo={this.relatedVideoHandler}
                          />
                        </>
                      )}
                    </Grid>
                  )}
                  {boat && this.renderCommercial1()}
                  {boat && this.renderCommercial2()}

                  {boat?.seller?.id && (
                    <div className="inner--add--feedback w-100 d-flex flex-column align-items-end">
                      {boat?.seller?.id !== currentUser.id && (
                        <div
                          className={`text-right f-14 mb-0 cursor-pointer add-feedback-mt add--feedback color hover-scale-1_1`}
                          style={{ padding: 15 }}
                          onClick={() => {
                            currentUser?.id
                              ? this.setState(prev => ({ feedbackModal: !prev.feedbackModal }))
                              : this.setState(prev => ({ loginModal: !prev.loginModal }))
                          }}
                        >
                          <img
                            loading="lazy"
                            src={require(`../../assets/images/boatInner/boatinner-like.png`)}
                            className="inner-feedback-img mr-2"
                            alt="Feedback"
                          />
                          <span className="add-feedback-text add--feedback--text">Send us Feedback</span>
                        </div>
                      )}

                      <LoginModal
                        open={this.state.loginModal}
                        close={() => this.setState(prev => ({ loginModal: !prev.loginModal }))}
                      />
                      <FeedBackForm
                        open={this.state.feedbackModal}
                        closeModal={() => this.setState(prev => ({ feedbackModal: !prev.feedbackModal }))}
                        submitValues={addFeedback}
                      />

                      <div class="font-weight-500 inner--adid--section">
                        <span className="inner-adid font-weight-400 color-black">
                          <CopyAdId adId={boat.adId} />
                        </span>

                        <ShareAndWishlist
                          style={{ marginTop: '11px' }}
                          moduleId={boat.id}
                          noTopSpace
                          moduleType={wishlistModuleEnum.BOAT}
                          ownerId={boat?.seller?.id}
                          shareWithUsers={data => this.shareBoatWithUsers(data)}
                          getUserReportByModule={getUserReportByModule}
                          getPdfSuccess={getPdfSuccess}
                          pdf={pdf}
                          getModulePdf={getModulePdf}
                          isInner
                          isClickble={boat.adStatus}
                          hideShare
                          hideSave
                        />
                      </div>
                    </div>
                  )}
                </Grid>
                {/* {!kayakCaoneType && <hr />} */}
              </Grid>
              {/* <Grid container className="w-85vw m-auto position-relative inner--home--banner--like--div inner--review--div">
                {boat && this.renderRatingAndReview()}
              </Grid> */}
              <div className="container-fluid clr-fluid w-85vw p-0 home-layout-page">
                {boatByCityList && boatByCityList.length > 0 && (
                  <div className="container-fluid container-zero-padding-space home-page">
                    <Grid item xs={12} className="p-sec">
                      <Box className="section-heading mt-0" fontSize={24} fontWeight={500} letterSpacing={0.5}>
                        {`Explore More ${boat?.boatType?.name} Option`}
                      </Box>
                      <div className="boat--inner--similar--boat">
                        <BoatGrid
                          isTopLabel
                          route
                          itemsLength={8}
                          showType=""
                          isCustomWidth
                          isBottomRating
                          boatGrid={boatByCityList}
                          isUserProfile
                          isBoatInner
                          shareWithUsers={data => this.shareBoatWithUsers(data)}
                          className="user--profile--boats--grid boat-inner-grid-layout-full-width"
                          showAllText="similar boats"
                        />
                      </div>
                    </Grid>
                  </div>
                )}
              </div>
            </>
          )}

          {boat && boat.images && boat.images.length > 0 && isGallery && (
            <ModalGateway>
              <Modal onClose={this.closeLightbox}>
                <Carousel views={prepareGalleryData(boat.images, imageIndex)} />
              </Modal>
            </ModalGateway>
          )}
          {boat.layout && boat.layout.length > 0 && isLayoutGallery && (
            <ModalGateway>
              <Modal onClose={this.closeNewLightbox}>
                <Carousel views={prepareGalleryData(boat.layout, imageIndex)} />
              </Modal>
            </ModalGateway>
          )}
        </Layout>
      </>
    )
  }
}

const mapStateToProps = state => ({
  boat: state.boatReducer.boat || {},
  getBoatSuccess: state.boatReducer?.getBoatSuccess,
  currentUser: state.loginReducer && state.loginReducer.currentUser,
  createSuccess: state.salesEngineReducer.createSuccess,
  salesEngine: state.salesEngineReducer.salesEngine,
  errors: state.salesEngineReducer.errors,
  createBidSuccess: state.boatReducer.createBidSuccess,
  createError: state.salesEngineReducer.createError,
  reviews: state.reviewReducer && state.reviewReducer.reviews,
  isReview: state.reviewReducer && state.reviewReducer.isReview,
  boatByCityLoad: state.boatReducer && state.boatReducer.boatByCityLoad,
  boatByCitySuccess: state.boatReducer && state.boatReducer.boatByCitySuccess,
  boatByCityError: state.boatReducer && state.boatReducer.boatByCityError,
  boatByCityData: state.boatReducer && state.boatReducer.boatByCityData,
  boatCostEstimation: state.boatReducer && state.boatReducer.boatCostEstimation,
  isMailCost: state.boatReducer && state.boatReducer.isMailCost,
  boatPDF: state.boatReducer && state.boatReducer.boatPDF,
  boatAuctionRoom: state.boatReducer.boatAuctionRoom,
  isError: state.boatReducer.isError,
  errorMessage: state.errorReducer.errorMessage,
  getPdfSuccess: state.pdfReducer && state.pdfReducer.getPdfSuccess,
  // loading: state.pdfReducer && state.pdfReducer.isLoading,
  pdf: state.pdfReducer && state.pdfReducer.pdf,
  surveyorLength: state.boatReducer?.surveyorLength,
  boatShipperList: state.boatReducer?.yachtShipperLength,
  isPdfLoading: state.boatReducer?.isPdfLoading,
  isVarification: state.boatReducer?.isVarification,
  getBoatError: state.boatReducer?.getBoatError,
  adminLoginAsUserSuccess: state.loginReducer.adminLoginAsUserSuccess,
  adminLoginDetected: state.loginReducer?.adminLoginDetected,
  isCheckedQuantity: state.boatReducer?.isCheckedQuantity,
  isDepositSuccess: state.boatReducer?.isDepositSuccess,
  createSalesEngineLoading: state?.salesEngineReducer?.createSalesEngineLoading,
  countryDetails: state.dashboardReducer.countryDetails,
  getServiceTags_loading: state.boatServiceReducer.getServiceTags_loading,
  getServiceTags_success: state.boatServiceReducer.getServiceTags_success,
  getServiceTags_failure: state.boatServiceReducer.getServiceTags_failure,
  getServiceTags_data: state.boatServiceReducer.getServiceTags_data,
})

const mapDispatchToProps = dispatch => ({
  getBoatById: data => dispatch(getBoatById(data)),
  getBoatConvertedCostEstimation: data => dispatch(getBoatConvertedCostEstimation(data)),
  createAuctionBid: data => dispatch(createAuctionBid(data)),
  pageVisit: data => dispatch(pageVisit(data)),
  createSalesEngine: data => dispatch(createSalesEngine(data)),
  clearCreateSalesEngineFlag: () => dispatch(clearCreateSalesEngineFlag()),
  clearEstimatedMail: () => dispatch(clearEstimatedMail()),
  getBoatByCities: data => dispatch(getBoatByCities(data)),
  clearBoatByCity: () => dispatch(clearBoatByCity()),
  getBoatCostEstimation: data => dispatch(getBoatCostEstimation(data)),
  mailBoatCostEstimation: data => dispatch(mailBoatCostEstimation(data)),
  addLikes: data => dispatch(addLikes(data)),
  addFeedback: data => dispatch(addFeedback(data)),
  clearFeedbackFlag: () => dispatch(clearFeedbackFlag()),
  shareDetailsWithUsers: data => dispatch(shareDetailsWithUsers(data)),
  getReviewByModuleId: data => dispatch(getReviewByModuleId(data)),
  getAuctionByBoatId: data => dispatch(getAuctionByBoatId(data)),
  clearErrorMessageShow: () => dispatch(clearErrorMessageShow()),
  getModulePdf: data => dispatch(getModulePdf(data)),
  clearAdminLoginFlag: () => dispatch(clearAdminLoginFlag()),
  getUserReportByModule: data => dispatch(getUserReportByModule(data)),
  checkForQuantity: data => dispatch(checkForQuantity(data)),
  clearCheckForQuantity: data => dispatch(clearCheckForQuantity(data)),
  addDepositForBid: data => dispatch(addDepositForBid(data)),
  getServiceTags: data => dispatch(getServiceTags(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(BoatInner)
