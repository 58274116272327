import React, { useEffect, useState } from 'react'
import '../footer/footer.scss'
import { Grid } from '@material-ui/core'
import Container from '@material-ui/core/Container'
import { ErrorMessage, Form, Formik } from 'formik'
import * as Yup from 'yup'
import { connect } from 'react-redux'
import { Layout } from '../layout/layout'
import SingleSelect from '../helper/singleSelect'
import { renderSelectOptions, requireMessage, trimmedValue } from '../../helpers/string'
import { stringValidation } from '../../helpers/yupHelper'
import { ButtonComponent } from '../form/Button'
import { Field } from '../ws/Field'
import { getUserRoles } from '../../redux/actions'
import { createService } from '../../redux/actions/userFaqAction'
import { SuccessNotify } from '../../helpers/notification'

const ContactUsServices = props => {
  const [initValues, setInitValues] = useState({
    role: '',
    fullName: '',
    email: '',
    mobileNumber: '',
    helpText: '',
  })
  const { userRoles, history, currentUser, createService } = props

  useEffect(() => {
    const { getUserRoles, currentUser } = props
    Object.keys(currentUser).length > 0 &&
      setInitValues({
        ...initValues,
        role: { label: currentUser?.role.role, value: currentUser?.role.id },
        fullName: `${currentUser?.firstName} ${currentUser?.lastName}`,
        email: currentUser?.email,
        mobileNumber: currentUser?.mobileNumber,
      })
    getUserRoles()
  }, [currentUser])

  return (
    <>
      <Layout>
        <div className="contactFooter-bg contact-us-top-space-header">
          <div className="footer--container--content--section">
            {/* <div className="inner"> */}
            <Container maxWidth="lg" className="contact--container--section">
              <h1 className="r-h1 mt-50 mb-0" style={{ textTransform: 'none' }}>Contact us</h1>
              {/* <p className='mb-4 pt-4'>Help us help you better. Use the form below to send us any of your suggestions or to report any problems you experienced on the AdamSea market. We'd love to hear from you.</p>
                            <p style={{ color: 'red' }} className='mb-1'>*REQUIRED FIELD</p>
                            <hr className='mt-0' /> */}
              <Container style={{ padding: 0 }}>
                <Formik
                  initialValues={{ ...initValues }}
                  onSubmit={(values, { resetForm }) => {
                    let data = {
                      userRole: values.role.value,
                      fullName: values.fullName,
                      email: values.email,
                      mobileNumber: values.mobileNumber,
                      description: values.helpText,
                    }
                    createService(data)
                    setTimeout(() => SuccessNotify('Form successfully submitted'), 100)
                    resetForm()
                    history.goBack()
                  }}
                  validationSchema={Yup.object().shape({
                    role: Yup.string().required(requireMessage('Role')),
                    fullName: stringValidation.required(requireMessage('Full Name')),
                    email: Yup.string().required(requireMessage('Email')),
                    helpText: Yup.string().required(requireMessage('Help Text')),
                  })}
                  render={({ setFieldValue, values, handleSubmit, errors }) => (
                    <Form className="form-left-side-space">
                      {/* <ErrorFocus /> */}
                      <Grid container className="formvalue-width">
                        <Grid item sm={12} className="grid-flex-method-topic  bottom-space-contactus-page">
                          <div className="boatShow-field grid-flex-method align-items-center">
                            <label className="footer-form-label">
                              <span className="required">*</span> I Am A(An)
                            </label>
                            <div className="footer-form-select-width">
                              <SingleSelect
                                selectedOption={values.role}
                                options={renderSelectOptions(
                                  userRoles.filter(el => el.type !== 'administration'),
                                  'role',
                                  'id'
                                )}
                                placeholder={'Select Role'}
                                onChange={select => {
                                  setFieldValue('role', select)
                                }}
                                styles={{
                                  singleValue: (provided, state) => {
                                    const fontSize = '14px'
                                    return { ...provided, fontSize, color: '#495057' }
                                  },
                                  placeholder: (provided, state) => {
                                    const fontSize = '14px'
                                    return { ...provided, fontSize, color: '#495057' }
                                  },
                                }}
                              />
                              <ErrorMessage component="div" name="role" className="error-message" />
                            </div>
                          </div>
                        </Grid>

                        <Grid item sm={12} className="grid-flex-method  bottom-space-contactus-page align-items-center">
                          <label className="footer-form-label">
                            <span className="required">*</span> Full Name
                          </label>
                          <div style={{ flex: '1' }} className="input-field-space contactus-page-form">
                            <Field
                              name="fullName"
                              value={values.fullName}
                              type="text"
                              className="form-control"
                              onChangeText={e => setFieldValue('fullName', e.target.value)}
                              styles={{ flex: '1' }}
                              required
                            />
                            <ErrorMessage
                              className="invalid-feedback ant-typography-danger d-block"
                              name={'fullName'}
                              component="span"
                            />
                          </div>
                        </Grid>

                        <Grid item sm={12} className="grid-flex-method  bottom-space-contactus-page align-items-center">
                          <label className="footer-form-label">
                            <span className="required">*</span> Email Address
                          </label>
                          <div style={{ flex: '1' }} className="input-field-space contactus-page-form">
                            <Field
                              name="email"
                              value={values.email}
                              type="text"
                              className="form-control"
                              onChangeText={e => setFieldValue('email', e.target.value)}
                              required
                            />
                            <ErrorMessage
                              className="invalid-feedback ant-typography-danger d-block"
                              name={'email'}
                              component="span"
                            />
                          </div>
                        </Grid>


                        <Grid item sm={12} className="grid-flex-method  bottom-space-contactus-page align-items-center">
                          <label className="footer-form-label">
                            Mobile Number
                          </label>
                          <div style={{ flex: '1' }} className="input-field-space contactus-page-form">
                            <Field
                              name="mobileNumber"
                              value={values.mobileNumber}
                              type="text"
                              className="form-control"
                              onChangeText={e => setFieldValue('mobileNumber', e.target.value)}
                              required
                            />
                            <ErrorMessage
                              className="invalid-feedback ant-typography-danger d-block"
                              name={'mobileNumber'}
                              component="span"
                            />
                          </div>
                        </Grid>

                        <Grid item sm={12} className="grid-flex-method bottom-space-contactus-page ">
                          <label className="footer-form-label">
                            <span className="required">*</span> How Can We Help You
                          </label>
                          <div style={{ flex: '1' }} className="contactus-page-form-suggestion input-field-space">
                            <Field
                              id={'helpText'}
                              name={'helpText'}
                              type="textarea"
                              className="contactus-page-form "
                              rows={4}
                              value={values.helpText}
                              onChangeText={e => {
                                setFieldValue('helpText', e.target.value)
                              }}
                              onBlur={e => setFieldValue('helpText', trimmedValue(e.target.value))}
                              required
                            />
                            <ErrorMessage
                              className="invalid-feedback ant-typography-danger d-block"
                              name={'helpText'}
                              component="span"
                            />
                          </div>
                        </Grid>

                        <Grid item sm={12} className="apply-btn-mt bottom-space-contactus-page">
                          <div className="d-flex" style={{ float: 'right' }}>
                            <ButtonComponent
                              className=" h-auto submit-btn-contactus btn--padding btn--font btn-outline-info"
                              onClick={handleSubmit}
                            >
                              {'Submit'}
                            </ButtonComponent>
                            <ButtonComponent
                              className=" h-auto as--success--outline btn--padding btn--font"
                              onClick={() => history.goBack()}
                            >
                              {'Cancel'}
                            </ButtonComponent>
                          </div>
                        </Grid>
                      </Grid>
                    </Form>
                  )}
                  enableReinitialize={true}
                />
              </Container>
              <div className="mt-2">
                <p>
                  AdamSea filters emails for security reasons.By clicking on " Submit ", you consent to this action in
                  accordance with our Terms of Use & Privacy Policy.
                </p>
              </div>
            </Container>
          </div>
        </div>
      </Layout>
    </>
  )
}

const mapStateToProps = state => ({
  userRoles: state.loginReducer?.roles,
  currentUser: state.loginReducer?.currentUser,
})

const mapDispatchToProps = dispatch => ({
  getUserRoles: () => dispatch(getUserRoles()),
  createService: data => dispatch(createService(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ContactUsServices)
