import {
  USER_REGISTER,
  USER_LOGIN,
  CLEAR_AUTH_FLAG,
  USER_LOGOUT,
  USER_UPDATE,
  GET_USER_ROLES,
  USER_EMAIL_VERIFY,
  GET_USER_BY_ID,
  SET_CURRENT_LOCATION,
  FORGOT_PASSWORD_MAIL,
  RESET_PASSWORD,
  FORGOT_PASSWORD,
  CLEAR_PASSWORD_FLAG,
  GET_ALL_PAGE_INFO_BY_TYPE,
  CHANGE_USER_ACCOUNT_STATUS,
  GET_USER_LOCATION,
  GET_USER_PAYMENT_INFO,
  ADD_USER_PAYMENT_INFO,
  UPDATE_EMAIL_SUCCESS,
  CLEAR_USER_PAYMENT_INFO_FLAG,
  ADD_REQUEST_FOR_AGREEMENT_ID,
  GET_USER_BY_AGREEMENT_ID,
  REMOVE_AGREEMENT_ID_DATA,
  UPDATE_USER_DETAILS_BY_AGREEMENT_ID,
  CLEAR_REMOVE_AGREEMENT_ID_FLAG,
  ASSIGN_AGREEMENT_ID,
  ADMIN_LOGIN_AS_USER,
  CLEAR_ADMIN_LOGIN_FLAG,
  GET_DOCUMENT_URL,
  GET_ITEMS_BY_USER,
  GET_SITE_CONFIGURATIONS,
  CLEAR_AGREEMENT_CREATE_MESSAGE,
  CLEAR_USER_REGISTER_FLAG,
  GET_DEALERS_REQUESTS,
  GET_MANAGE_PASSWORD_CONTENT,
  CLEAR_MANUFACTURER_FLAG,
  SUBSCRIBE_USER,
  GET_TRANSACTION_BY_USER,
  FETCH_ALL_CHANNELS,
  ADD_REMOVE_FRIEND,
  GET_USER_STRIPE_DETAILS,
  UPDATE_USER_STRIPE_PROFILE_COMPLETED,
  CLEAR_USER_STRIPE_LINK,
  GET_TRANSACTION_BY_USER_PAYOUTS,
  CLEAR__SUBSCRIBE_USER,
  SET_COOKIE_POLICY_STATUS,
  TOGGLE_COOKIE_POLICY_MODAL,
  LOGIN_WITH_PAYPAL,
  LOGIN_WITH_PAYPAL_CLEAR,
} from '../actionTypes'

export const register = data => ({
  type: USER_REGISTER,
  payload: data,
})

export const clearRegisterFlag = () => ({
  type: CLEAR_USER_REGISTER_FLAG,
})

export const login = data => ({
  type: USER_LOGIN,
  payload: data,
})

export const adminLoginAsUser = data => ({
  type: ADMIN_LOGIN_AS_USER,
  payload: data,
})

export const clearAdminLoginFlag = () => ({
  type: CLEAR_ADMIN_LOGIN_FLAG,
})

export const logout = () => ({
  type: USER_LOGOUT,
})

export const forgetPasswordMail = data => ({
  type: FORGOT_PASSWORD_MAIL,
  payload: data,
})

export const resetPassword = data => ({
  type: RESET_PASSWORD,
  payload: data,
})

export const clearAuthorizationFlag = () => ({
  type: CLEAR_AUTH_FLAG,
})

export const updateUser = data => ({
  type: USER_UPDATE,
  payload: data,
})

export const handleStripeAccount = data => ({
  type: GET_USER_STRIPE_DETAILS,
})

export const clearStripeAccountLink = data => ({
  type: CLEAR_USER_STRIPE_LINK,
})

export const updateStripeProfileCompleted = data => ({
  type: UPDATE_USER_STRIPE_PROFILE_COMPLETED,
  payload: data
})

export const getUserRoles = () => ({
  type: GET_USER_ROLES,
})

export const getAllPageInformationByType = data => ({
  type: GET_ALL_PAGE_INFO_BY_TYPE,
  payload: data,
})

export const activateUserDetail = data => ({
  type: USER_EMAIL_VERIFY,
  payload: data,
})

export const getUserById = (data, userProfile = false) => ({
  type: GET_USER_BY_ID,
  payload: data,
  userProfile,
})

export const setCurrentLocation = data => ({
  type: SET_CURRENT_LOCATION,
  payload: data,
})

export const forgotPassword = data => ({
  type: FORGOT_PASSWORD,
  payload: data,
})

export const clearForgotPassword = () => ({
  type: CLEAR_PASSWORD_FLAG,
})

export const clearUserPaymentInfoFlag = () => ({
  type: CLEAR_USER_PAYMENT_INFO_FLAG,
})

export const changeUserStatus = data => ({
  type: CHANGE_USER_ACCOUNT_STATUS,
  payload: data,
})

export const getUserLocation = data => ({
  type: GET_USER_LOCATION,
  payload: data,
})

export const getUserPaymentInfo = data => ({
  type: GET_USER_PAYMENT_INFO,
  payload: data,
})

export const addUserPaymentInfo = data => ({
  type: ADD_USER_PAYMENT_INFO,
  payload: data,
})

export const updateEmailSuccess = data => ({
  type: UPDATE_EMAIL_SUCCESS,
  payload: data,
})

export const requestAgreementId = data => ({
  type: ADD_REQUEST_FOR_AGREEMENT_ID,
  payload: data,
})
export const getUserByAgreementId = data => ({
  type: GET_USER_BY_AGREEMENT_ID,
  payload: data,
})

export const clearAgreementIdData = () => ({
  type: REMOVE_AGREEMENT_ID_DATA,
})

export const clearAgreementIdDataFlag = () => ({
  type: CLEAR_REMOVE_AGREEMENT_ID_FLAG,
})

export const clearAgreementCreateSuccessMessage = () => ({
  type: CLEAR_AGREEMENT_CREATE_MESSAGE,
})

export const updateUserDetailByAgreementId = data => ({
  type: UPDATE_USER_DETAILS_BY_AGREEMENT_ID,
  payload: data,
})

export const assignAgreement = data => ({
  type: ASSIGN_AGREEMENT_ID,
  payload: data,
})

export const getDocumentUrl = data => ({
  type: GET_DOCUMENT_URL,
  payload: data,
})
export const getItemsByUser = data => ({
  type: GET_ITEMS_BY_USER,
  payload: data,
})
export const getSiteConfigurations = () => ({
  type: GET_SITE_CONFIGURATIONS,
})

export const getDealersRequests = () => ({
  type: GET_DEALERS_REQUESTS,
})

export const getManagePassWordContent = () => ({
  type: GET_MANAGE_PASSWORD_CONTENT,
})

export const clearManufacturerFlag = () => ({
  type: CLEAR_MANUFACTURER_FLAG,
})

export const subscribeToUser = (data, fromArticleInner = false) => ({
  type: SUBSCRIBE_USER,
  payload: data,
})

export const clear_subscribeToUser = () => ({
  type: CLEAR__SUBSCRIBE_USER,
})

export const addOrRemoveFriend = data => ({
  type: ADD_REMOVE_FRIEND,
  payload: data,
})

export const getTransactionByUser = data => ({
  type: GET_TRANSACTION_BY_USER,
  payload: data,
})

export const getTransactionByUserPayouts = data => ({
  type: GET_TRANSACTION_BY_USER_PAYOUTS,
  payload: data,
})

export const fetchUserChannels = data => ({
  type: FETCH_ALL_CHANNELS,
  payload: data,
})

export const toggleCookiePolicyModal = data => ({
  type: TOGGLE_COOKIE_POLICY_MODAL,
  payload: data,
})

export const setCookiePolicyStatusAction = data => ({
  type: SET_COOKIE_POLICY_STATUS,
  payload: data,
})

export const loginWithPaypalAction = payload => ({
  type: LOGIN_WITH_PAYPAL,
  payload
})

export const loginWithPaypalClearAction = () => ({
  type: LOGIN_WITH_PAYPAL_CLEAR,
})