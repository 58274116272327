import { get } from 'lodash'
import {
  googleDirectionUrl,
  viewArticles,
  viewBoatHandler,
  viewMarinaDetails,
  viewRentBoatHandler,
  viewServiceBoatHandler,
} from '../../helpers/boatHelper'
import { redirectToUserProfile } from '../../helpers/jsxHelper'
import { getId } from '../../util/utilFunctions'

const NotificationTemplates = {
  SALESENGINE__CREATE: (notification, history, currentUser) => (
    <>
      <span
        className="notify-link"
        onClick={() => history.push('/sales-engine-process/' + get(notification, 'module.SalesEngine._id', ''))}
      >
        Sales Engine
      </span>{' '}
      has been created for the{' '}
      <span className="notify-link" onClick={() => viewBoatHandler(get(notification, 'module.SalesEngine.boat', ''))}>
        {get(notification, 'module.SalesEngine.boat.boatType.name', '')}
      </span>{' '}
      by buyer{' '}
      <span className="notify-link" onClick={() => redirectToUserProfile(get(notification, 'sender', ''), history)}>
        {get(notification, 'sender.firstName', '')} {get(notification, 'sender.lastName', '')}
      </span>
      .
    </>
  ),

  SALESENGINE__ADDED_DISCOUNTED_PRICE: (notification, history, currentUser) =>
    get(notification, 'module.SalesEngine.buyer._id', '') === getId(currentUser) ? (
      <>
        You have received a{' '}
        <span
          className="notify-link"
          onClick={() => history.push('/sales-engine-process/' + get(notification, 'module.SalesEngine._id', ''))}
        >
          new price
        </span>{' '}
        for the{' '}
        <span className="notify-link" onClick={() => viewBoatHandler(get(notification, 'module.SalesEngine.boat', ''))}>
          {get(notification, 'module.SalesEngine.boat.boatType.name', '')}
        </span>{' '}
        you are buying.
      </>
    ) : (
      <>
        Seller{' '}
        <span className="notify-link" onClick={() => redirectToUserProfile(get(notification, 'sender', ''), history)}>
          {get(notification, 'sender.firstName', '')} {get(notification, 'sender.lastName', '')}
        </span>{' '}
        has sent a new price for{' '}
        <span
          className="notify-link"
          onClick={() => history.push('/sales-engine-process/' + get(notification, 'module.SalesEngine._id', ''))}
        >
          Sales Engine
        </span>
        .
      </>
    ),

  SALESENGINE__AGENT_REQUEST: (notification, history, currentUser) => (
    <>
      You have a{' '}
      <span className="notify-link" onClick={() => history.push('/my-requests')}>
        new request
      </span>{' '}
      from{' '}
      <span className="notify-link" onClick={() => redirectToUserProfile(get(notification, 'sender', ''), history)}>
        {get(notification, 'sender.firstName', '')} {get(notification, 'sender.lastName', '')}
      </span>
      .
    </>
  ),

  SALESENGINE__AGENT_REJECTED_REQUEST: (notification, history, currentUser) => (
    <>
      Agent/Dealer{' '}
      <span className="notify-link" onClick={() => redirectToUserProfile(get(notification, 'sender', ''), history)}>
        {get(notification, 'sender.firstName', '')} {get(notification, 'sender.lastName', '')}
      </span>{' '}
      didn't accept your request. Please{' '}
      <span
        className="notify-link"
        onClick={() => history.push('/sales-engine-process/' + get(notification, 'module.SalesEngine._id', ''))}
      >
        select
      </span>{' '}
      another agent/dealer.
    </>
  ),

  SALESENGINE__AGENT_ACCEPTED_REQUEST: (notification, history, currentUser) =>
    get(notification, 'module.SalesEngine.seller._id', '') === getId(currentUser) ? (
      <>
        Agent/Dealer{' '}
        <span className="notify-link" onClick={() => redirectToUserProfile(get(notification, 'sender', ''), history)}>
          {get(notification, 'sender.firstName', '')} {get(notification, 'sender.lastName', '')}
        </span>{' '}
        has been assigned to this{' '}
        <span
          className="notify-link"
          onClick={() => history.push('/sales-engine-process/' + get(notification, 'module.SalesEngine._id', ''))}
        >
          Sales Engine
        </span>
        .
      </>
    ) : get(notification, 'module.SalesEngine.buyer._id', '') === getId(currentUser) ? (
      <>
        Agent/Dealer{' '}
        <span className="notify-link" onClick={() => redirectToUserProfile(get(notification, 'sender', ''), history)}>
          {get(notification, 'sender.firstName', '')} {get(notification, 'sender.lastName', '')}
        </span>{' '}
        accepted your request.
      </>
    ) : (
      <>
        Agent/Dealer{' '}
        <span className="notify-link" onClick={() => redirectToUserProfile(get(notification, 'sender', ''), history)}>
          {get(notification, 'sender.firstName', '')} {get(notification, 'sender.lastName', '')}
        </span>{' '}
        has been assigned to the boat{' '}
        <span className="notify-link" onClick={() => viewBoatHandler(get(notification, 'module.SalesEngine.boat', ''))}>
          {get(notification, 'module.SalesEngine.boat.adId', '')}
        </span>
        .
      </>
    ),

  SALESENGINE__AGENT_CANCELLED: (notification, history, currentUser) =>
    get(notification, 'data.agentId', '') === getId(currentUser) ? (
      <>
        Buyer{' '}
        <span className="notify-link" onClick={() => redirectToUserProfile(get(notification, 'sender', ''), history)}>
          {get(notification, 'sender.firstName', '')} {get(notification, 'sender.lastName', '')}
        </span>{' '}
        has cancelled your assignment on his Sales Engine for the{' '}
        <span className="notify-link" onClick={() => viewBoatHandler(get(notification, 'module.SalesEngine.boat', ''))}>
          {get(notification, 'module.SalesEngine.boat.adId', '')}
        </span>
        .
      </>
    ) : (
      <>
        Buyer{' '}
        <span className="notify-link" onClick={() => redirectToUserProfile(get(notification, 'sender', ''), history)}>
          {get(notification, 'sender.firstName', '')} {get(notification, 'sender.lastName', '')}
        </span>{' '}
        cancelled his agent/dealer{' '}
        <span
          className="notify-link"
          onClick={() => redirectToUserProfile(get(notification, 'module.SalesEngine.agent', ''), history)}
        >
          {get(notification, 'module.SalesEngine.agent.firstName', '')}{' '}
          {get(notification, 'module.SalesEngine.agent.lastName', '')}
        </span>
        .
      </>
    ),

  SALESENGINE__SURVEYOR_REQUEST: (notification, history, currentUser) => (
    <>
      You received a{' '}
      <span className="notify-link" onClick={() => history.push('/manage-branches')}>
        request
      </span>{' '}
      from{' '}
      <span className="notify-link" onClick={() => redirectToUserProfile(get(notification, 'sender', ''), history)}>
        {get(notification, 'sender.firstName', '')} {get(notification, 'sender.lastName', '')}
      </span>{' '}
      to do a survey for boat{' '}
      <span className="notify-link" onClick={() => viewBoatHandler(get(notification, 'module.SalesEngine.boat', ''))}>
        {get(notification, 'module.SalesEngine.boat.boatType.name', '')}
      </span>
      .
    </>
  ),

  SALESENGINE__SURVEYOR_REJECTED_REQUEST: (notification, history, currentUser) => (
    <>
      Surveyor rejected your request. Choose another or skip the surveyor for{' '}
      <span className="notify-link" onClick={() => viewBoatHandler(get(notification, 'module.SalesEngine.boat', ''))}>
        {get(notification, 'module.SalesEngine.boat.adId', '')}
      </span>
      .
    </>
  ),

  SALESENGINE__SURVEYOR_ACCEPTED_REQUEST: (notification, history, currentUser) =>
    get(notification, 'module.SalesEngine.seller._id', '') === getId(currentUser) ? (
      <>
        Surveyor{' '}
        <span className="notify-link" onClick={() => redirectToUserProfile(get(notification, 'sender', ''), history)}>
          {get(notification, 'sender.firstName', '')} {get(notification, 'sender.lastName', '')}
        </span>{' '}
        was assigned to the{' '}
        <span
          className="notify-link"
          onClick={() => history.push('/sales-engine-process/' + get(notification, 'module.SalesEngine._id', ''))}
        >
          Sales Engine
        </span>
        .
      </>
    ) : get(notification, 'module.SalesEngine.buyer._id', '') === getId(currentUser) ? (
      <>
        Surveyor{' '}
        <span className="notify-link" onClick={() => redirectToUserProfile(get(notification, 'sender', ''), history)}>
          {get(notification, 'sender.firstName', '')} {get(notification, 'sender.lastName', '')}
        </span>{' '}
        accepted your request.{' '}
        <span
          className="notify-link"
          onClick={() => history.push('/sales-engine-process/' + get(notification, 'module.SalesEngine._id', ''))}
        >
          Proceed
        </span>{' '}
        with the payment.
      </>
    ) : (
      <>
        Surveyor{' '}
        <span className="notify-link" onClick={() => redirectToUserProfile(get(notification, 'sender', ''), history)}>
          {get(notification, 'sender.firstName', '')} {get(notification, 'sender.lastName', '')}
        </span>{' '}
        was assigned to the{' '}
        <span
          className="notify-link"
          onClick={() => history.push('/sales-engine-process/' + get(notification, 'module.SalesEngine._id', ''))}
        >
          Sales Engine
        </span>
        .
      </>
    ),

  SALESENGINE__SURVEYOR_PAYMENT: (notification, history, currentUser) => (
    <>
      Surveyor{' '}
      <span
        className="notify-link"
        onClick={() => {
          if (get(notification, 'module.SalesEngine.surveyor.user._id', '') === getId(currentUser)) {
            if (get(notification, 'module.SalesEngine.surveyor._id', '')) {
              history.push(`/my-requests?branchId=${get(notification, 'module.SalesEngine.surveyor._id', '')}`)
            } else {
              history.push(`/manage-branches`)
            }
          } else {
            history.push('/sales-engine-process/' + get(notification, 'module.SalesEngine._id', ''))
          }
        }}
      >
        payment
      </span>{' '}
      has been completed.
    </>
  ),

  SALESENGINE__SURVEYOR_CREATED_REPORT: (notification, history, currentUser) => (
    <>
      Surveyor{' '}
      <span className="notify-link" onClick={() => redirectToUserProfile(get(notification, 'sender', ''), history)}>
        {get(notification, 'sender.firstName', '')} {get(notification, 'sender.lastName', '')}
      </span>{' '}
      uploaded a report for the{' '}
      <span
        className="notify-link"
        onClick={() => history.push('/sales-engine-process/' + get(notification, 'module.SalesEngine._id', ''))}
      >
        Sales Engine
      </span>
      .
    </>
  ),

  SALESENGINE__SURVEYOR_UPDATED_REPORT: (notification, history, currentUser) => (
    <>
      Surveyor{' '}
      <span className="notify-link" onClick={() => redirectToUserProfile(get(notification, 'sender', ''), history)}>
        {get(notification, 'sender.firstName', '')} {get(notification, 'sender.lastName', '')}
      </span>
      's report has been updated for the{' '}
      <span
        className="notify-link"
        onClick={() => history.push('/sales-engine-process/' + get(notification, 'module.SalesEngine._id', ''))}
      >
        Sales Engine
      </span>
      .
    </>
  ),

  SALESENGINE__BOAT_PAYMENT: (notification, history, currentUser) => (
    <>
      Buyer{' '}
      <span className="notify-link" onClick={() => redirectToUserProfile(get(notification, 'sender', ''), history)}>
        {get(notification, 'sender.firstName', '')} {get(notification, 'sender.lastName', '')}
      </span>{' '}
      completed the boat payment for the{' '}
      <span
        className="notify-link"
        onClick={() => history.push('/sales-engine-process/' + get(notification, 'module.SalesEngine._id', ''))}
      >
        Sales Engine
      </span>
      .
    </>
  ),

  SALESENGINE__BOAT_PICKUP: (notification, history, currentUser) =>
    get(notification, 'module.SalesEngine.seller._id', '') === getId(currentUser) ? (
      <>
        Buyer{' '}
        <span className="notify-link" onClick={() => redirectToUserProfile(get(notification, 'sender', ''), history)}>
          {get(notification, 'sender.firstName', '')} {get(notification, 'sender.lastName', '')}
        </span>{' '}
        will pick up the boat from{' '}
        <span
          className="notify-link"
          onClick={() => googleDirectionUrl(get(notification, 'module.SalesEngine.shipmentLocation.geometricLocation', ''))}
        >
          {get(notification, 'module.SalesEngine.shipmentLocation.address', '')}
        </span>{' '}
        for the{' '}
        <span
          className="notify-link"
          onClick={() => history.push('/sales-engine-process/' + get(notification, 'module.SalesEngine._id', ''))}
        >
          Sales Engine
        </span>
        .
      </>
    ) : (
      <>
        Buyer{' '}
        <span className="notify-link" onClick={() => redirectToUserProfile(get(notification, 'sender', ''), history)}>
          {get(notification, 'sender.firstName', '')} {get(notification, 'sender.lastName', '')}
        </span>{' '}
        will pick up the boat for the{' '}
        <span
          className="notify-link"
          onClick={() => history.push('/sales-engine-process/' + get(notification, 'module.SalesEngine._id', ''))}
        >
          Sales Engine
        </span>
        .
      </>
    ),

  SALESENGINE__SHIP_BOAT: (notification, history, currentUser) =>
    get(notification, 'module.SalesEngine.seller._id', '') !== getId(currentUser) &&
    get(notification, 'module.SalesEngine.agent._id', '') !== getId(currentUser) ? (
      <>
        You have a{' '}
        <span className="notify-link" onClick={() => history.push('/my-requests')}>
          new request
        </span>{' '}
        from{' '}
        <span className="notify-link" onClick={() => redirectToUserProfile(get(notification, 'sender', ''), history)}>
          {get(notification, 'sender.firstName', '')} {get(notification, 'sender.lastName', '')}
        </span>
        .
      </>
    ) : (
      <>
        Buyer{' '}
        <span className="notify-link" onClick={() => redirectToUserProfile(get(notification, 'sender', ''), history)}>
          {get(notification, 'module.SalesEngine.buyer.firstName', '')}{' '}
          {get(notification, 'module.SalesEngine.buyer.lastName', '')}
        </span>{' '}
        choose to ship the boat for the{' '}
        <span
          className="notify-link"
          onClick={() => history.push('/sales-engine-process/' + get(notification, 'module.SalesEngine._id', ''))}
        >
          Sales Engine
        </span>
        .
      </>
    ),

  SALESENGINE__SHIP_ADDR_UPDATE: (notification, history, currentUser) =>
    get(notification, 'module.SalesEngine.shipper._id', '') === getId(currentUser) ? (
      <>
        Buyer{' '}
        <span className="notify-link" onClick={() => redirectToUserProfile(get(notification, 'sender', ''), history)}>
          {get(notification, 'sender.firstName', '')} {get(notification, 'sender.lastName', '')}
        </span>{' '}
        updated{' '}
        <span className="notify-link" onClick={() => history.push('/my-requests')}>
          shipping location
        </span>
        .
      </>
    ) : (
      <>
        Buyer{' '}
        <span className="notify-link" onClick={() => redirectToUserProfile(get(notification, 'sender', ''), history)}>
          {get(notification, 'sender.firstName', '')} {get(notification, 'sender.lastName', '')}
        </span>{' '}
        updated shipping location for the{' '}
        <span
          className="notify-link"
          onClick={() => history.push('/sales-engine-process/' + get(notification, 'module.SalesEngine._id', ''))}
        >
          Sales Engine
        </span>
        .
      </>
    ),

  SALESENGINE__SHIPPER_PROPOSAL: (notification, history, currentUser) =>
    get(notification, 'module.SalesEngine.buyer._id', '') === getId(currentUser) ? (
      <>
        You received new proposal from shipper{' '}
        <span className="notify-link" onClick={() => redirectToUserProfile(get(notification, 'sender', ''), history)}>
          {get(notification, 'sender.firstName', '')} {get(notification, 'sender.lastName', '')}
        </span>{' '}
        for the{' '}
        <span
          className="notify-link"
          onClick={() => history.push('/sales-engine-process/' + get(notification, 'module.SalesEngine._id', ''))}
        >
          Sales Engine
        </span>
        .
      </>
    ) : (
      <>
        Buyer{' '}
        <span
          className="notify-link"
          onClick={() => redirectToUserProfile(get(notification, 'module.SalesEngine.buyer._id', ''), history)}
        >
          {get(notification, 'module.SalesEngine.buyer.firstName', '')}{' '}
          {get(notification, 'module.SalesEngine.buyer.lastName', '')}
        </span>{' '}
        has received a proposal for the boat{' '}
        <span className="notify-link" onClick={() => viewBoatHandler(get(notification, 'module.SalesEngine.boat', ''))}>
          {get(notification, 'module.SalesEngine.boat.adId', '')}
        </span>
        .
      </>
    ),

  SALESENGINE__SHIPPER_PAYMENT: (notification, history, currentUser) =>
    get(notification, 'module.SalesEngine.buyer._id', '') === getId(currentUser) ? (
      <>
        Shipping payment has been completed for the{' '}
        <span
          className="notify-link"
          onClick={() => history.push('/sales-engine-process/' + get(notification, 'module.SalesEngine._id', ''))}
        >
          Sales Engine
        </span>
        .
      </>
    ) : (
      <>
        Shipping payment has been completed by{' '}
        <span className="notify-link" onClick={() => redirectToUserProfile(get(notification, 'sender', ''), history)}>
          {get(notification, 'sender.firstName', '')} {get(notification, 'sender.lastName', '')}
        </span>{' '}
        for the{' '}
        <span className="notify-link" onClick={() => viewBoatHandler(get(notification, 'module.SalesEngine.boat', ''))}>
          {get(notification, 'module.SalesEngine.boat.adId', '')}
        </span>
        .
      </>
    ),

  SALESENGINE__SHIPPER_CREATED_DOCS: (notification, history, currentUser) => (
    <>
      Shipper{' '}
      <span className="notify-link" onClick={() => redirectToUserProfile(get(notification, 'sender', ''), history)}>
        {get(notification, 'sender.firstName', '')} {get(notification, 'sender.lastName', '')}
      </span>{' '}
      uploaded document for the{' '}
      <span
        className="notify-link"
        onClick={() => history.push('/sales-engine-process/' + get(notification, 'module.SalesEngine._id', ''))}
      >
        Sales Engine
      </span>
      .
    </>
  ),

  SALESENGINE__BOAT_RECEIVED: (notification, history, currentUser) =>
    get(notification, 'module.SalesEngine.buyer._id') ? (
      <>
        Boat{' '}
        <span className="notify-link" onClick={() => viewBoatHandler(get(notification, 'module.SalesEngine.boat', ''))}>
          {get(notification, 'module.SalesEngine.boat.adId', '')}
        </span>{' '}
        was delivered to you.
      </>
    ) : (
      <>
        Buyer received the boat{' '}
        <span className="notify-link" onClick={() => viewBoatHandler(get(notification, 'module.SalesEngine.boat', ''))}>
          {get(notification, 'module.SalesEngine.boat.adId', '')}
        </span>
        .
      </>
    ),

  ARTICLE__SUBSCRIBE: (notification, history, currentUser) => (
    <>
      You have new subscriber -{' '}
      <span className="notify-link" onClick={() => redirectToUserProfile(get(notification, 'sender', ''), history)}>
        {get(notification, 'sender.firstName', '')} {get(notification, 'sender.lastName', '')}
      </span>
      .
    </>
  ),

  ARTICLE__CREATE: (notification, history, currentUser) => (
    <>
      <span className="notify-link" onClick={() => redirectToUserProfile(get(notification, 'sender', ''), history)}>
        {get(notification, 'sender.firstName', '')} {get(notification, 'sender.lastName', '')}
      </span>{' '}
      from your subscription added{' '}
      <span
        className="notify-link"
        onClick={() => viewArticles(get(notification, 'module.Article._id', ''), get(notification, 'module.Article.title', ''))}
      >
        new article
      </span>
      .
    </>
  ),

  RENT__CREATE_BOOKING: (notification, history, currentUser) => (
    <>
      <span className="notify-link" onClick={() => viewRentBoatHandler(get(notification, 'module.RentBookingType.boat', ''))}>
        {get(notification, 'module.RentBookingType.boat.tripType.alias', '')}
      </span>{' '}
      was booked by{' '}
      <span className="notify-link" onClick={() => redirectToUserProfile(get(notification, 'sender', ''), history)}>
        {get(notification, 'sender.firstName', '')} {get(notification, 'sender.lastName', '')}
      </span>
      .
    </>
  ),

  RENT__CANCEL_BOOKING: (notification, history, currentUser) => (
    <>
      <span className="notify-link" onClick={() => viewRentBoatHandler(get(notification, 'module.RentBookingType.boat', ''))}>
        {get(notification, 'module.RentBookingType.boat.tripType.alias', '')}
      </span>{' '}
      booked by{' '}
      <span className="notify-link" onClick={() => redirectToUserProfile(get(notification, 'sender', ''), history)}>
        {get(notification, 'sender.firstName', '')} {get(notification, 'sender.lastName', '')}
      </span>{' '}
      has been cancelled.
    </>
  ),

  AUCTION__CREATE: (notification, history, currentUser) => (
    <>
      The{' '}
      <span
        className="notify-link"
        onClick={() => viewBoatHandler(get(notification, 'module.AuctionBid.auctionRoom.boat', ''))}
      >
        auction
      </span>{' '}
      for the boat you added has started.
    </>
  ),

  AUCTION__WINNER: (notification, history, currentUser) => (
    <>
      You won the{' '}
      <span
        className="notify-link"
        onClick={() => viewBoatHandler(get(notification, 'module.AuctionBid.auctionRoom.boat', ''))}
      >
        auction
      </span>
      . Please complete the{' '}
      <span
        className="notify-link"
        onClick={() => history.push('/sales-engine-process/' + get(notification, 'data.salesEngineId', ''))}
      >
        boat payment
      </span>{' '}
      within {get(notification, 'data.totalTimeToPay', '3 days')}.
    </>
  ),

  AUCTION__SELLER: (notification, history, currentUser) => (
    <>
      The{' '}
      <span
        className="notify-link"
        onClick={() => viewBoatHandler(get(notification, 'module.AuctionBid.auctionRoom.boat', ''))}
      >
        auction
      </span>{' '}
      is closed. The winner needs to complete the{' '}
      <span
        className="notify-link"
        onClick={() => history.push('/sales-engine-process/' + get(notification, 'data.salesEngineId', ''))}
      >
        boat payment
      </span>
      .
    </>
  ),

  AUCTION__LOST: (notification, history, currentUser) => (
    <>
      The{' '}
      <span
        className="notify-link"
        onClick={() => viewBoatHandler(get(notification, 'module.AuctionBid.auctionRoom.boat', ''))}
      >
        auction
      </span>{' '}
      is closed. You lost the bid for the boat{' '}
      <span
        className="notify-link"
        onClick={() => viewBoatHandler(get(notification, 'module.AuctionBid.auctionRoom.boat', ''))}
      >
        {get(notification, 'module.AuctionBid.auctionRoom.boat.adId', '')}
      </span>
      .
    </>
  ),

  SHARE__USER: (notification, history, currentUser) => (
    <>
      <span className="notify-link" onClick={() => redirectToUserProfile(get(notification, 'sender', ''), history)}>
        {get(notification, 'sender.firstName', '')} {get(notification, 'sender.lastName', '')}
      </span>{' '}
      shared{' '}
      <span className="notify-link" onClick={() => redirectToUserProfile(get(notification, 'module.Users', ''), history)}>
        {get(notification, 'module.Users.firstName', '')} {get(notification, 'module.Users.lastName', '')}
      </span>
      's <strong>profile</strong> with you.
    </>
  ),

  SHARE__ARTICLE: (notification, history, currentUser) => (
    <>
      <span className="notify-link" onClick={() => redirectToUserProfile(get(notification, 'sender', ''), history)}>
        {get(notification, 'sender.firstName', '')} {get(notification, 'sender.lastName', '')}
      </span>{' '}
      shared an{' '}
      <span
        className="notify-link"
        onClick={() => viewArticles(get(notification, 'module.Article._id', ''), get(notification, 'module.Article.title', ''))}
      >
        article
      </span>{' '}
      with you.
    </>
  ),

  SHARE__BOAT: (notification, history, currentUser) => (
    <>
      <span className="notify-link" onClick={() => redirectToUserProfile(get(notification, 'sender', ''), history)}>
        {get(notification, 'sender.firstName', '')} {get(notification, 'sender.lastName', '')}
      </span>{' '}
      shared a{' '}
      <span className="notify-link" onClick={() => viewBoatHandler(get(notification, 'module.Boat', ''))}>
        {get(notification, 'module.Boat.boatType.name', '')}
      </span>{' '}
      boat with you.
    </>
  ),

  SHARE__RENT_BOAT: (notification, history, currentUser) => (
    <>
      <span className="notify-link" onClick={() => redirectToUserProfile(get(notification, 'sender', ''), history)}>
        {get(notification, 'sender.firstName', '')} {get(notification, 'sender.lastName', '')}
      </span>{' '}
      shared the{' '}
      <span className="notify-link" onClick={() => viewRentBoatHandler(get(notification, 'module.BoatRent', ''))}>
        {get(notification, 'module.BoatRent.tripType.alias', '')}
      </span>{' '}
      with you.
    </>
  ),

  SHARE__BOAT_SHOW: (notification, history, currentUser) => (
    <>
      <span className="notify-link" onClick={() => redirectToUserProfile(get(notification, 'sender', ''), history)}>
        {get(notification, 'sender.firstName', '')} {get(notification, 'sender.lastName', '')}
      </span>{' '}
      shared <strong>Boat Show</strong> {get(notification, 'module.BoatShow.title', '')} with you.
    </>
  ),

  SHARE__MARINA: (notification, history, currentUser) => (
    <>
      <span className="notify-link" onClick={() => redirectToUserProfile(get(notification, 'sender', ''), history)}>
        {get(notification, 'sender.firstName', '')} {get(notification, 'sender.lastName', '')}
      </span>{' '}
      shared <strong>Marina</strong>{' '}
      <span className="notify-link" onClick={() => viewMarinaDetails(get(notification, 'module.Marina', ''))}>
        {get(notification, 'module.Marina.name', '')}
      </span>{' '}
      with you.
    </>
  ),

  SHARE__YACHT: (notification, history, currentUser) => (
    <>
      <span className="notify-link" onClick={() => redirectToUserProfile(get(notification, 'sender', ''), history)}>
        {get(notification, 'sender.firstName', '')} {get(notification, 'sender.lastName', '')}
      </span>{' '}
      shared <strong>Yacht</strong>{' '}
      <span className="notify-link" onClick={() => viewServiceBoatHandler(get(notification, 'module.Yacht', ''))}>
        {get(notification, 'module.Yacht.user.companyName', 'Service')}
      </span>{' '}
      with you.
    </>
  ),
}

export default NotificationTemplates

export const NOTIFICATION_CATG = {
  SALESENGINE: 'Sales Engine',
  ARTICLE: 'Article',
  RENT: 'Rent',
  AUCTION: 'Auction',
  USER: 'User',
  BOAT: 'Boat',
  MARINA: 'Marina and Storage',
  YACHT: 'Service and Maintenance',
}
