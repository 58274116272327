import React, { useState, useContext, useEffect, useRef } from 'react'
import { List, ListItem, IconButton } from '@material-ui/core'
import './header.scss'
import MenuIcon from '@material-ui/icons/Menu'
import RegisterUser from '../popUp/RegisterUser'
import { Link } from 'react-router-dom'
import MenuItem from '@material-ui/core/MenuItem'
import { getLocalInfo, getLocalStorageItem } from '../../helpers/storageHelper'
import { CommonTooltip } from '../CommonTooltip'
import { UserConsumer } from '../../UserContext'
import { redirectToUserProfile } from '../../helpers/jsxHelper'
import { IoIosArrowDown } from 'react-icons/io'
import UserContext from '../../UserContext'
import { connect } from 'react-redux'

import { SuccessNotify } from '../../helpers/notification'
import { defaultProfileIcon, requiredProfileUpdate, salesEngineAccessibleTypes } from '../../util/enums/enums'
import moment from 'moment'
import DashboardSidebar from '../../containers/dashboard/dashboardSidebar'

// currency

import { Formik, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import ErrorFocus from '../ws/ErrorFocus'
import { setLocalStorageItem } from '../../helpers/storageHelper'
import { currencyEnum } from '../../util/enums/currencyEnums'
import { getCurrentCurrency } from '../../helpers/currencyConverterHelper'
import { CommonModal } from '../modal/commonModal'
import SingleSelect from '../helper/singleSelect'
import { displayDefaultNumericValue, renderSelectOptions } from '../../helpers/string'
import { ButtonComponent } from '../form/Button'
import { MdLocationOn } from 'react-icons/md'

function MobileMenu(props) {
  const { dashboardnavbar } = props
  const { currentUser, history, logout, match, setCurrentCurrency, country } = useContext(UserContext)
  const isLoggedIn = Object.keys(currentUser).length > 0
  const { siteConfigurations } = props

  const dashboardSetLogo = () => {
    const { role } = currentUser
    if (role) {
      switch (role.aliasName) {
        case 'rent-and-charter':
          return {
            src: require('../../assets/images/logo/home.png'),
          }

        case 'marina-and-storage':
          return {
            src: require('../../assets/images/logo/home.png'),
          }

        case 'service-and-maintenance':
          return {
            src: require('../../assets/images/logo/home.png'),
          }

        default:
          return {
            src: require('../../assets/images/logo/home.png'),
          }
      }
    }
    return {
      src: require('../../assets/images/logo/home.png'),
    }
  }

  const setLogo = () => {
    if (match) {
      const { path } = match
      switch (path) {
        /** Rent Path */
        case '/rent':
        case '/rent-category-wise-boats/:tripType/:tripTypeId/:country':
        case '/rent-city-wise-boats/:city/:country':
        case '/show-all-rent/:type':
        case '/rent-inner/:id/:name':
        case '/search-rent-boats':
          return {
            key: siteConfigurations?.rentLogo?.url,
            url: '/rent',
          }

        /** Boat Service Path */
        case '/boat-service':
        case '/boat-service-inner/:id/:name':
        case '/category-wise-boat-service':
        case '/show-all-boat-service-gallery':
        case '/category-wise-boat-service/:typeId/:typeName':
        case '/search-boat-services':
          return {
            key: siteConfigurations?.yachtServiceLogo?.url,
            url: '/boat-service',
          }

        /** Marina Storage Path */
        case '/marina-storage':
        case '/marina-storage-view':
        case '/marina-storage-service-view/:serviceId':
        case '/marina-storage-inner/:id/:name':
        case '/search-marina-storage':
        case '/show-all-marina-and-storage/:type':
          return {
            key: siteConfigurations?.marinaAndStorageLogo?.url,
            url: '/marina-storage',
          }

        case '/boat-show':
        case '/show-all-boat-show':
        case '/search-boat-show':
          return {
            key: siteConfigurations?.boatShowLogo?.url,
            url: '/boat-show',
          }

        case '/':
        case '/dashboard':
        case '/profile':
        case '/manage-wishlist':
        case '/my-bookings':
        case '/manage-marina-storage':
        case '/manage-articles':
        case '/manage-boat-shows':
        case '/manage-payment':
        case '/manage-payouts':
        case '/manage-rent-payouts':
        case '/change-password':
          return {
            key: dashboardSetLogo().src,
            url: '/',
          }

        case '/boat-media':
          return {
            key: siteConfigurations?.boatMediaLogo?.url,
            url: '/boat-media',
          }

        default:
          return {
            key: require('../../assets/images/logo/home.png'),
            url: '/',
          }
      }
    } else {
      return {
        key: require('../../assets/images/logo/home.png'),
        url: '/',
      }
    }
  }
  const userRole = getLocalInfo('role')

  function isShowPopUp(isOpen) {
    setIsOpen(isOpen)
  }
  const [isOpen, setIsOpen] = React.useState(false)
  const [profileOpen, setProfileOpen] = React.useState(false)

  const [currencyDialog, setCurrencyDialog] = useState(false)

  const currencyModalHandler = e => {
    e && e.preventDefault()
    setCurrencyDialog(!currencyDialog)
  }

  // currency

  const [initValue] = useState({
    selectedCurrency: '',
  })
  const ref = useRef()
  useEffect(() => {
    const checkIfClickedOutside = e => {
      if (isOpen && ref.current && !ref.current.contains(e.target)) {
        setIsOpen(false)
      }
    }

    document.addEventListener('mousedown', checkIfClickedOutside)
    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside)
    }
  }, [isOpen])

  return (
    <div ref={ref}>
      <div
        onOutsideClick={() => {
          isShowPopUp(false)
        }}
      ></div>

      <div className="mobile__menu-color header-fixed-mobile-view d-sm-icon-none mobile-menu-styles">
        <div>
          <button className="btn" onClick={() => setIsOpen(prev => !prev)}>
            <MenuIcon className="ham-icon-logo" />
          </button>
          <Link ref={ref} to={setLogo().url}>
            <img alt="Adam Sea Logo" src={setLogo().key} className="mobile-menu-logo" />
          </Link>
        </div>
        <div className="d-flex align-items-center">
          <RegisterUser siteConfigurations={siteConfigurations} setSticky={() => {}} isMobileMenu />
          {!isLoggedIn && <div>
            {+getLocalStorageItem('totalWishlists') > 0 && (
              <div className=" header-btn heart-1-li header-cart-icon cursor-pointer ml-2">
                <Link
                  to="/manage-wishlist"
                  onClick={e => +getLocalStorageItem('totalWishlists') === 0 && e.preventDefault()}
                  className={`${props.headerClasses.textStyle} d-flex position-relative`}
                >
                  <i className="adam-heart-1"></i>
                  <span className="cart-no">
                    {displayDefaultNumericValue(getLocalStorageItem('totalWishlists'))}
                  </span>
                </Link>
              </div>
            )}
          </div>}
        </div>
      </div>

      {/* <IconButton
        aria-label="show more"
        aria-haspopup="true"
        color="inherit"
        ref={ref}
        className=""
      >
        <span className="header-icon-logo" onClick={() => setIsOpen(prev => !prev)}>
          <MenuIcon className="ham-icon-logo" />
        </span>
        <span ref={ref}>
          <Link className="Adam-image-logo-mobile-view" ref={ref} to={setLogo().url}>
          </Link>
        </span>
        <RegisterUser siteConfigurations={siteConfigurations} setSticky={() => {}} />
      </IconButton> */}

      {isOpen && (
        <div className="mobile-login-dropdown">
          <List>
            {dashboardnavbar ? (
              <DashboardSidebar />
            ) : (
              <>
                {setLogo().url !== '/' && <ListItem>
                  <Link className="mob-text-view" to="/">
                    Buy & Sell
                  </Link>
                </ListItem>}
                {setLogo().url !== '/rent' && <ListItem>
                  <Link className="mob-text-view" to="/rent">
                    Charter & Rent
                  </Link>
                </ListItem>}
                {setLogo().url !== '/boat-service' && <ListItem>
                  <Link className="mob-text-view" to="/boat-service">
                    Boat Service
                  </Link>
                </ListItem>}
                {setLogo().url !== '/marina-storage' && <ListItem>
                  <Link className="mob-text-view" to="/marina-storage">
                    Marina & Storage
                  </Link>
                </ListItem>}
                {setLogo().url !== '/boat-show' && <ListItem>
                  <Link className="mob-text-view" to="/boat-show">
                    Boat Show
                  </Link>
                </ListItem>}
                {setLogo().url !== '/boat-media' && <ListItem>
                  <Link className="mob-text-view" to="/boat-media">
                    Boat Media
                  </Link>
                </ListItem>}
              </>
            )}
          </List>
          {!isLoggedIn && (
            <React.Fragment>
              {/* <ListItem className="register-component">
                <RegisterUser siteConfigurations={siteConfigurations} setSticky={() => {}} />
              </ListItem> */}
              {/* Show location when not logged in */}
              <div className="location-container-not-logged-in d-flex header-location">
                <div className="header-location-img location-img-icon-div d-flex mt-1">
                    <img height='20px' style={{display:'block'}} className="location-icon-svg" src={require('../../assets/images/location/location-icon.png')} alt='Geo Location Icon'/>
                </div>
                <div>
                  <div className="d-flex flex-column flex-column-tab-ist-mobile-view">
                    <span className="text-black inner-parent-span ml-1 header-location-country-mobile gray-dark">
                      Your Location <span style={{ fontWeight: 'bold' }}>{country}</span>
                    </span>
                  </div>
                </div>
              </div>
            </React.Fragment>
          )}
          <CommonModal
            open={currencyDialog}
            close={currencyModalHandler}
            className="currency-convert-modal"
            title={`Current Currency: ${getCurrentCurrency()}`}
            overflowVisible
            overflowAuto={false}
          >
            <Formik
              initialValues={initValue}
              onSubmit={values => {
                const selectedValue = values.selectedCurrency ? values.selectedCurrency.value : ''
                setCurrentCurrency(selectedValue)
                setLocalStorageItem('currentCurrency', selectedValue)
                currencyModalHandler()
              }}
              validationSchema={Yup.object().shape({
                selectedCurrency: Yup.string().required('Please select currency first.'),
              })}
              render={({ values, setFieldValue, handleSubmit }) => (
                <Form className="mobile--currency--changer">
                  <ErrorFocus />
                  <div className="d-flex flex-column w-100 shipment-proposal-desc-div currency--converter--section">
                    <SingleSelect
                      label="Selected Currency"
                      name="selectedCurrency"
                      placeholder="Select Currency"
                      selectedOption={values.selectedCurrency}
                      options={renderSelectOptions(currencyEnum, 'name', 'label')}
                      onChange={value => setFieldValue('selectedCurrency', value)}
                      required
                    />
                    <ErrorMessage component="div" name="selectedCurrency" className="error-message" />

                    <div className="d-flex justify-content-center">
                      <ButtonComponent
                        className=" h-auto as--success--outline btn--padding btn--font currency--converter--btn"
                        onClick={handleSubmit}
                      >
                        Convert
                      </ButtonComponent>
                    </div>
                  </div>
                  <div className="convert--currency--tip">
                    <span className="currency--tip-label">Tip:</span>
                    <span className="currency--tip-tip">
                      All kind of transactions in AdamSea market will be only US $ Currency
                    </span>
                  </div>
                </Form>
              )}
            />
          </CommonModal>

          {currentUser && currentUser.id && (
            <div className="d-flex header-location">
              <div className="header-location-img location-img-icon-div d-flex mt-1">
                <img height='20px' style={{display:'block'}} className="location-icon-svg" src={require('../../assets/images/location/location-icon.png')} alt='Geo Location Icon'/>
              </div>
              <div>
                <div className="d-flex flex-column flex-column-tab-ist-mobile-view">
                  <span className="text-black inner-parent-span ml-1 header-location-country-mobile gray-dark">{country}</span>
                  <span className="header-user-time text-black  header-location-time-mobile dark-silver">
                    {moment(new Date()).format('D MMM ddd hh:mm A')}
                  </span>
                </div>
              </div>
            </div>
          )}
          {/* Profile  */}
          {isOpen && isLoggedIn && (
            <div className=" mobile-dropdown-profile-header header-btn mr-3 cursor-pointer">
              <div style={{ display: 'inline-block', padding: 5, border: '1px solid #0003', borderRadius: 999 }}>
                <UserConsumer>
                  {({ currentUser }) => (
                    <div className="d-flex align-items-center">
                      <div
                        className="mr-2 header-user-image-div cursor-pointer"
                        onClick={() => redirectToUserProfile(currentUser, history)}
                      >
                        <CommonTooltip component={ctProps => (
                          <img
                            data-tooltip-id={ctProps.id}
                            data-tooltip-content="Visit My Profile"
                            src={getLocalStorageItem('userImage') ? getLocalStorageItem('userImage') : defaultProfileIcon}
                            alt="Profile-icon"
                            className="h-100 width-100"
                          />
                        )} />
                      </div>
                      <div className="ml-1 d-flex flex-column mr-2">
                        <span className="header-user-name gray-dark">{getLocalStorageItem('firstName')}</span>
                      </div>
                      <div className="mobile-menu-down-arrow">
                        <IoIosArrowDown onClick={() => setProfileOpen(prev => !prev)} />
                      </div>
                    </div>
                  )}
                </UserConsumer>
              </div>
            </div>
          )}
          <div className="mobile-menu-popup">
            {isLoggedIn && profileOpen && (
              <UserConsumer>
                {({ currentUser }) => (
                  <div className="p-4">
                    {isLoggedIn && (
                      <div className="d-flex align-items-center justify-content-between">
                        <div className=" d-flex flex-wrap align-items-center">
                          <span className="font-weight-500 mr-2 small-screen-login-user-title header-dropdown-user-name-mobile">
                            {currentUser && currentUser.firstName}
                          </span>
                          <span className="login-user-div-text small-screen-login-user-title header-dropdown-user-role-mobile">
                            ({currentUser && currentUser.role && currentUser.role.role.toLowerCase()})
                          </span>
                        </div>
                        <div className=" d-flex flex-wrap">
                          <span className=" font-weight-500 login-user-div-text small-screen-login-user-title header-dropdown-user-role mr-1">
                            Since
                          </span>
                          <span className=" font-weight-500 login-user-div-text small-screen-login-user-title header-dropdown-user-role">
                            {moment(currentUser && currentUser.createdAt).format('DD-MM-YYYY')}
                          </span>
                        </div>
                      </div>
                    )}
                    <hr />
                    <div className="mt-3 mb-3 d-flex flex-column mobile-menu-gap-flex">
                      <span className="login-user-div-text font-weight-500 small-screen-login-user-info">My Account</span>
                      <div className="pl-2 d-flex flex-column mt-1">
                        <Link to="/dashboard" className="header-dropdown-link hover-text-only">
                          <span className="login-user-div-text login-user-div-text-link small-screen-login-user-info">
                            Dashboard
                          </span>
                        </Link>
                        {currentUser && currentUser.role && salesEngineAccessibleTypes.includes(currentUser.role.type) && (
                          <span
                            className="header-dropdown-link hover-text-only"
                            onClick={() => {
                              currentUser.documentVerification && currentUser.documentVerification.emailVerified
                                ? history.push('/sales-engines')
                                : SuccessNotify(requiredProfileUpdate)
                            }}
                          >
                            <span className="login-user-div-text login-user-div-text-link small-screen-login-user-info cursor-pointer">
                              My Store
                            </span>
                          </span>
                        )}
                        <Link to="/change-password" className="header-dropdown-link hover-text-only">
                          <span className="login-user-div-text login-user-div-text-link small-screen-login-user-info">
                            Change Password
                          </span>
                        </Link>

                        <Link to="/logout" className="header-dropdown-link hover-text-only logout-dashboard-mobile-responsive">
                          <span
                            className="login-user-div-text login-user-div-text-link cursor-pointer header-logout small-screen-login-user-info"
                            onClick={logout}
                          >
                            Logout
                          </span>
                        </Link>
                      </div>
                    </div>
                  </div>
                )}
              </UserConsumer>
            )}
          </div>
          <div className="mobile-menu-help">
            <MenuItem className="menu__help font-12 MobileMenu-hover header-btn ">
              <Link to="/help" className="paddingRight-1 ">
                {/* <IconButton className="help-button header-icons">
                  <i className="adam-help"></i>
                </IconButton> */}
                <span className="adam-text-font link-hover-text">Do you need help</span>
              </Link>
            </MenuItem>
          </div>
          {/* End User Profile  */}
          {/* <div className="mobile-menu-live-support">
            <MenuItem className="menu__help font-12 HeaderMenu-hover-mobile header-btn">
              <Link to="/" className="Live-support">
                <IconButton className="mobile-menu-help-button header-icons">
                  <i className="adam-chat-4"></i>
                </IconButton>
                <span className="adam-text-font link-hover-text">Live Support</span>
              </Link>
            </MenuItem>
          </div> */}

          <div className="mobile-menu-currency">
            <MenuItem className="menu__help font-12 currency-bg HeaderMenu-hover-mobile header-btn">
              <Link to="/" className="mobile-menu-currency">
                <div
                  className="btn btn-xs btn-light btn-outline-lights dropdown-toggle text-decoration-none header-small-font currencyBox mobile-menu-currency-button"
                  to="#"
                  data-toggle="modal"
                  data-target="#currency-modal"
                  onClick={currencyModalHandler}
                >
                  Currency {getLocalStorageItem('currentCurrency')}
                </div>
              </Link>
            </MenuItem>
          </div>
        </div>
      )}
    </div>
  )
}
const mapStateToProps = state => ({
  siteConfigurations: state.loginReducer.siteConfigurations,
})
export default connect(mapStateToProps, null)(MobileMenu)
