import { useState } from 'react'
import OutsideClickHandler from 'react-outside-click-handler'
import { enqueueSnackbar } from 'notistack'
import { uploadFile } from '../../../../helpers/s3FileUpload'
import { MEDIA_TYPE } from '../../../../util/enums/enums'
import IconClose from '../../assets/icons/close'
import IconGallery from '../../assets/icons/gallery'
import { PostMediaType } from '../../lib/enums'
import { createPost as qCreatePost } from '../../lib/queries'
import { useCreatePostModal } from '../../contexts/create-post-modal-ctx'
import EmojiBtn from '../common/emoji-btn'
import compStyles from '../../styles/components.module.scss'
import styles from './create-post-modal.module.scss'

export default function CreatePostModal({ zIndex }) {
  const createPostModal = useCreatePostModal()

  const [content, setContent] = useState('')
  const [contentErr, setContentErr] = useState('')
  const [uploading, setUploading] = useState(false)
  const [media, setMedia] = useState([])
  const [createLoading, setCreateLoading] = useState(false)

  const selectMedia = () => {
    const el = document.createElement('input')
    el.type = 'file'
    el.multiple = true
    el.accept = 'image/*,video/*'
    el.click()

    el.addEventListener('change', async e => {
      if (e.target.files?.length) {
        setUploading(true)

        try {
          const uploaded = await Promise.all(
            [...e.target.files].map(async file => {
              if (file.type.startsWith('image/')) {
                const [uploaded] = await uploadFile([file], null, MEDIA_TYPE.IMAGE, false, { maxImageSize: 0 })
                return { ...uploaded, type: 'Image' }
              } else if (file.type.startsWith('video/')) {
                const [uploaded] = await uploadFile([file], null, MEDIA_TYPE.VIDEO, false, { maxImageSize: 0 })
                return { ...uploaded, type: 'Video' }
              }
            })
          )

          setMedia(media => [...media, ...uploaded])
        } catch (err) {
          console.log(err)
          enqueueSnackbar("Couldn't upload media")
        }

        setUploading(false)
      }
    })
  }

  const removeMedia = key => {
    setMedia(m => {
      const i = m.findIndex(_m => _m.key === key)
      m.splice(i, 1)
      return [...m]
    })
  }

  const createPost = async ({ content, media }) => {
    if (!content) {
      setContentErr('Please describe your thought!')
      return
    } else {
      setContentErr('')
    }

    setCreateLoading(true)

    try {
      await qCreatePost({
        content,
        media: media.map(m => ({ type: m.type, key: m.key })),
      })
      enqueueSnackbar('Posted successfully')
      createPostModal.onCreate?.()
      createPostModal.hide()
    } catch (err) {
      enqueueSnackbar("Couldn't create post")
    }

    setCreateLoading(false)
  }

  if (!createPostModal.isOpen) return null

  return (
    <div className={compStyles.overlay} style={{ zIndex }}>
      <OutsideClickHandler display="contents" onOutsideClick={createPostModal.hide}>
        <div className={styles.modal}>
          <div className={styles.head}>
            <h2 className={styles.title}>Create Post</h2>
            <button className={styles.closeBtn} onClick={createPostModal.hide}>
              <IconClose className={styles.icon} />
            </button>
          </div>

          <div className={styles.body}>
            <textarea
              placeholder="What's on your mind?"
              className={styles.contentInp}
              value={content}
              onChange={e => setContent(e.target.value)}
              disabled={createLoading}
            />
            {contentErr && <p className={styles.contentInpErr}>{contentErr}</p>}

            <div className={styles.mediaWrp}>
              {media.map(m => (
                <div key={m.key} className={styles.media}>
                  {m.type === PostMediaType.Image ? <img src={m.url} alt={m.key} /> : <video controls src={m.url} />}
                  <button type="button" className={styles.closeBtn} onClick={() => removeMedia(m.key)}>
                    <IconClose className={styles.icon} />
                  </button>
                </div>
              ))}
            </div>
          </div>

          <div className={styles.footer}>
            <button type="button" className={styles.galleryBtn} onClick={selectMedia} disabled={uploading}>
              {uploading ? <span className={compStyles.spinner} /> : <IconGallery />}
            </button>
            <EmojiBtn className={styles.emojiBtn} addEmoji={emoji => setContent(c => c + emoji)} />

            <button
              type="button"
              className={styles.postBtn}
              onClick={() => createPost({ content, media })}
              disabled={createLoading}
            >
              {createLoading && <span className={compStyles.spinner} />} Post
            </button>
          </div>
        </div>
      </OutsideClickHandler>
    </div>
  )
}
