import { createContext, useContext, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { v4 as uuid } from 'uuid'
import { socket } from '../../../socket'
import { getId } from '../../../util/utilFunctions'
import { socketEvent } from '../../../util/enums/socketEnums'
import { chatType } from '../../../util/chat'
import { getIsChatShow, getIsGalleryOpen, getSelectUser } from '../../../redux/selector/chat'
import { chatToggle, createChatById } from '../../../redux/actions/chatAction'

const ChatModalCtx = createContext()
export default ChatModalCtx

export const ChatModalProvider = ({ children }) => {
  const dispatch = useDispatch()

  const isChatShow = useSelector(getIsChatShow)
  const isGalleryOpen = useSelector(getIsGalleryOpen)
  const selectUser = useSelector(getSelectUser)

  return (
    <ChatModalCtx.Provider
      value={{
        isOpen: !!isChatShow,

        createChat: user => {
          if (selectUser?.chatId) {
            socket.emit(socketEvent.LeaveChannel, selectUser?.chatId)
          }

          dispatch(
            createChatById({
              members: [getId(user)],
              imageUrl: user.image?.url ?? '',
              description: '',
              channelUrl: uuid(),
              chatType: chatType.normalchat,
            })
          )

          dispatch(chatToggle(true))
        },

        show: () => {
          if (selectUser?.chatId) {
            socket.emit(socketEvent.LeaveChannel, selectUser?.chatId)
          }

          dispatch(chatToggle(true))
        },

        hide: () => {
          if (!isGalleryOpen) {
            socket.emit(socketEvent.LeaveChannel, selectUser?.chatId)
            dispatch(chatToggle(false))
          }
        },
      }}
    >
      {children}
    </ChatModalCtx.Provider>
  )
}

export const useChatModal = () => useContext(ChatModalCtx)
