import React, { useState } from 'react'
import { FormControlLabel, Checkbox, Grid } from '@material-ui/core'
import { AgentInfoCard } from './AgentInfoCard'
import { CarouselWrapper } from '../carouselComponent/carouselWrapper'
import { assignAgentCheckBox } from '../../containers/salesEngine/SalesEngineHelper'
import { HelpIconComponent } from '../helper/helpIconComponent'
import { agentHelpText } from '../../util/enums/enums'

export const AgentSection = props => {
  const { salesEngine, assignAgentSuccess, discardAgentProcess, agentType, isBuyer, history } = props

  const [isShow, setShow] = useState(salesEngine?.agentRequested || salesEngine?.agent)
  const [isChange, setIsChange] = useState(false)

  const handleChange = () => {
    setIsChange(!isChange)
  }

  const salesEngineCarousel = {
    isBrowser: 2,
    isTablet: 2,
    isMobile: 2,
  }

  const unSelectedAgent = props.agents?.filter(
    item => item.id !== salesEngine?.agentRequested && item.id !== salesEngine?.agent?.id
  )
  const selectedAgent = props.agents?.filter(
    item => item.id === salesEngine?.agentRequested || item.id === salesEngine?.agent?.id
  )

  const finalAgent = [...selectedAgent, ...unSelectedAgent]

  return (
    <Grid container>
      <Grid item xs={12}>
        {!isShow && salesEngine && (
          <div className={isChange ? 'mb-0 stepper-asign-agent-div assign-single-selected' : 'stepper-asign-agent-div'}>
            {props.agents && props.agents.length > 0 ? (
              <div className="d-flex align-items-center agent--align-center">
                <FormControlLabel
                  control={
                    <Checkbox
                      defaultChecked={isShow}
                      classes={{ root: 'assign-agent-checkbox', checked: 'assign-agent-checked' }}
                      onChange={() => {
                        handleChange()
                        setShow(!isShow)
                        isShow && discardAgentProcess({ id: salesEngine.id })
                      }}
                    />
                  }
                />
                <span className={`help--icon--position--lb-tool d-flex align-center`}>
                  Assign {agentType} to help you to buy this boat
                  <HelpIconComponent helpText={agentHelpText} containerClass="single-select--help" />
                </span>
              </div>
            ) : (
              <FormControlLabel control={<></>} label={`Not available ${agentType} for this boat`} />
            )}
          </div>
        )}

        {isShow && !isChange && (
          <AgentInfoCard
            history={history}
            agent={salesEngine.agent}
            className={isChange && 'assign-single-list'}
            isAgentSingle
            isSingle={false}
            salesEngine={salesEngine}
            isChanged={isChange}
            handleChange={handleChange}
            isBuyer={isBuyer}
          />
        )}

        {isShow && (!salesEngine?.agent || isChange) && props.agents && props.agents.length > 0 && (
          <div className="sales--engin-assign-agent">
            <CarouselWrapper items={salesEngineCarousel} top={30}>
              {finalAgent.map(item => {
                return (
                  <AgentInfoCard
                    history={history}
                    isAgentCarousel
                    agent={item}
                    setAgent={props.setAgent}
                    salesEngine={salesEngine}
                    handleReSet={handleChange}
                    inListing
                    discardAgentProcess={discardAgentProcess}
                  />
                )
              })}
            </CarouselWrapper>
          </div>
        )}
      </Grid>
    </Grid>
  )
}
