import React, { Component, useEffect } from 'react'
import { comLicenceAccessibleRole, userRoles } from '../../util/enums/enums'
import { checkRentUserType } from '../../helpers/jsxHelper'
import { ButtonComponent } from '../form/Button'
import { connect } from 'react-redux'
import { chooseForEstimatedCost, isChosenForEstimatedCost } from '../../redux/actions'
import { getLocalStorageItem } from '../../helpers/storageHelper'

const UserDocumentInfoComp = props => {
  const { id, documentVerification, firstName, role, provider } = props

  const isAdmin = getLocalStorageItem('adminLoginDetected')

  useEffect(() => {
    if (props.isChosenForEstimatedCost && id) {
      props.isChosenForEstimatedCost({ userId: id });
    }
  }, [id]);

  useEffect(() => {
    if (id && props.chooseForEstimatedCost_success) {
      props.isChosenForEstimatedCost({ userId: id });
    }
  }, [id, props.chooseForEstimatedCost_success])

  return (
    <div className="mt-4 user--profile--has--provided">
      <h5 className="mb-15 text-capitalize font-16 user--has--provided">{firstName} has provided</h5>

      <div className="mb-15 d-flex align-items-center doc--verification--icon--text">
        <img
          src={
            documentVerification?.governmentIdVerified
              ? require('../../components/userProfile/image/vector.png')
              : require('../../components/userProfile/image/cross-icon.svg')
          }
          className="icon-25 mr-2 filter-black"
          alt="Verification icon"
        />
        <h5 className="font-weight-300 rentInner-userTextH4 mb-0 user-profile-desc font-16 color-black">Identity</h5>
      </div>

      <div className="mb-15 d-flex align-items-center doc--verification--icon--text">
        <img
          src={
            documentVerification?.emailVerified
              ? require('../../components/userProfile/image/vector.png')
              : require('../../components/userProfile/image/cross-icon.svg')
          }
          className="icon-25 mr-2 filter-black"
          alt="Verification icon"
        />
        <h5 className="font-weight-300 rentInner-userTextH4 mb-0 user-profile-desc font-16 color-black">Email</h5>
      </div>

      <div className="mb-15 d-flex align-items-center doc--verification--icon--text">
        <img
          src={
            documentVerification?.mobileVerified
              ? require('../../components/userProfile/image/vector.png')
              : require('../../components/userProfile/image/cross-icon.svg')
          }
          className="icon-25 mr-2 filter-black"
          alt="Verification icon"
        />
        <h5 className="font-weight-300 rentInner-userTextH4 mb-0 user-profile-desc font-16 color-black">Mobile Number</h5>
      </div>

      {role?.aliasName &&
        (comLicenceAccessibleRole.includes(role.aliasName) ||
          checkRentUserType(role?.aliasName, provider && provider.alias)) && (
          <div className="mb-15 d-flex align-items-center doc--verification--icon--text">
            <img
              src={
                documentVerification.commercialLicenceVerified
                  ? require('../../components/userProfile/image/vector.png')
                  : require('../../components/userProfile/image/cross-icon.svg')
              }
              className="icon-25 mr-2 filter-black"
              alt="Verification icon"
            />
            <h5 className="font-weight-300 rentInner-userTextH4 mb-0 user-profile-desc font-16 color-black">
              {role.aliasName === userRoles.SURVEYOR ? 'Certified' : 'Licence'}
            </h5>
          </div>
        )}

        {isAdmin && (role?.aliasName === userRoles.SERVICE_AND_MAINTENANCE) && (
          <div className="mt-20 mb-15">
            <button
              type="button"
              className={props.isChosenForEstimatedCost_data ? 'btn btn-danger' : 'btn btn-warning'}
              onClick={() => props.chooseForEstimatedCost({ userId: id, chosen: !props.isChosenForEstimatedCost_data })}
              disabled={props.chooseForEstimatedCost_loading || props.isChosenForEstimatedCost_loading}
            >
              {props.isChosenForEstimatedCost_data ? 'Remove from Estimated Cost' : 'Choose for Estimated Cost'}
            </button>
          </div>
        )}
    </div>
  )
}

export const UserDocumentInfo = connect(state => ({
  chooseForEstimatedCost_loading: state.boatServiceReducer.chooseForEstimatedCost_loading,
  chooseForEstimatedCost_success: state.boatServiceReducer.chooseForEstimatedCost_success,
  isChosenForEstimatedCost_loading: state.boatServiceReducer.isChosenForEstimatedCost_loading,
  isChosenForEstimatedCost_data: state.boatServiceReducer.isChosenForEstimatedCost_data,
}), dispatch => ({
  chooseForEstimatedCost: data => dispatch(chooseForEstimatedCost(data)),
  isChosenForEstimatedCost: data => dispatch(isChosenForEstimatedCost(data)),
}))(UserDocumentInfoComp)
