import React, { useState, useEffect, useContext, useRef } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import OutsideClickHandler from 'react-outside-click-handler'
import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import MenuItem from '@material-ui/core/MenuItem'
import Menu from '@material-ui/core/Menu'
import { Link } from 'react-router-dom'
import Slide from '@material-ui/core/Slide'
import PropTypes from 'prop-types'
import useScrollTrigger from '@material-ui/core/useScrollTrigger'
import moment from 'moment'
import { InputBase, Grid } from '@material-ui/core'
import { IoIosArrowDown, IoIosSearch } from 'react-icons/io'
import { MdLocationOn } from 'react-icons/md'

import RegisterUser from '../popUp/RegisterUser'
import { UserConsumer } from '../../UserContext'
import UserContext from '../../UserContext'
import SearchDropdown from './searchDropdown'
import {
  bellIcon,
  salesEngineAccessibleTypes,
  defaultProfileIcon,
  requiredProfileUpdate,
  pagination,
  chatIcon,
} from '../../util/enums/enums'
import { getLocalInfo, getLocalStorageItem, setLocalStorageItem } from '../../helpers/storageHelper'

import './header.scss'
import '../home/icon.scss'
import { CurrencyConverterModal } from '../modal/currencyConverterModal'
import { displayDefaultNumericValue } from '../../helpers/string'
import { CommonTooltip } from '../CommonTooltip'
import {
  globalSearch,
  getRecentSearch,
  changeUserStatus,
  getCountryDetails,
  toggleCookiePolicyModal,
} from '../../redux/actions'
import { SuccessNotify } from '../../helpers/notification'
import { redirectToUserProfile } from '../../helpers/jsxHelper'
import {
  chatToggle,
  deleteMessageChatByIDAction,
  getChatListsByCurrentUser,
  getChatMe,
  setAddRemoveUser,
  setDeliveredMessage,
  setLastActiveTime,
  setOnlineOfflineUser,
  setUnreadCount,
  setUserList,
} from '../../redux/actions/chatAction'
import { getChatsME, getChatsList, getIsChatShow, getSelectUser } from '../../redux/selector/chat'
import { ButtonComponent } from '../form/Button'
import { CommonModal } from '../modal/commonModal'
import notificationsSlice from '../../redux/slices/notificationsSlice'
import { get } from 'lodash'
import { socket } from '../../socket'
import { socketEvent } from '../../util/enums/socketEnums'
import NotificationSound from '../../components/chats/notification-sound.mp3'
import { FaSearch } from 'react-icons/fa'
import MobileMenu from './MobileMenu'
import GetApp from './GetApp'

const useStyles = makeStyles(theme => ({
  grow: {
    flexGrow: 1,
  },
  logoDesktop: {
    display: 'block',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  desktopMenu: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  desktopSearch: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block',
    },
  },
  title: {
    [theme.breakpoints.up('sm')]: {
      display: 'block',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      zIndex: 1,
    },
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    marginRight: theme.spacing(1.8),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: 'auto',
    },
    border: '1px solid #0d0799',
    color: '#0d0799',
    background: 'white',
    fontWeight: '500',
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 200,
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  displayFlex: {
    display: 'flex',
    padding: '1rem .5rem .5rem 0',
    borderBottom: 'solid 3px transparent',
  },
  displayFlexCenter: {
    display: 'flex',
    padding: '1rem .5rem .5rem .5rem',
    borderBottom: 'solid 3px transparent',
    justifyContent: 'center',
  },
  alignRight: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
  },
  headerPadding: {
    padding: '6px 15px 0',
    display: 'flex',
    minHeight: 'unset',
  },
  textStyle: {
    color: '#484848',
    textDecoration: 'none',
    fontSize: '0.8125rem',
  },
  textStyleBlack: {
    color: '#000',
    textDecoration: 'none',
  },
  textFieldPadding: {
    padding: '4px 16px',
  },
  overflowUnset: {
    overflow: 'unset',
  },
  textPrimary: {
    color: '#0d0799',
  },
  width100: {
    width: '100%',
  },
  root: {
    padding: '2px 4px',
    display: 'flex',
    width: '600px',
    border: '1px solid #EBEBEB !important',
    borderRadius: '4px !important',
    boxShadow: '0 2px 4px rgba(0,0,0,0.1) !important',
    justifyContent: 'center',
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
}))

function HideOnScroll(props) {
  const { children, window } = props
  const trigger = useScrollTrigger({ target: window ? window() : undefined })
  return (
    <Slide direction="down" in={!trigger}>
      {children}
    </Slide>
  )
}

HideOnScroll.propTypes = {
  children: PropTypes.element.isRequired,
  window: PropTypes.func,
}

const Header = props => {
  const {
    globalSearch,
    getRecentSearch,
    changeUserStatus,
    siteConfigurations,
    readNotifications,
    readNotifications_clear,
    notifications_read,
  } = props
  const { country, match, logout, currentUser, getAllWishlists, history } = useContext(UserContext)

  const isChatShow = useSelector(getIsChatShow)

  const currentChatUser = useSelector(getChatsME)
  const chatsList = useSelector(getChatsList)
  const selectUser = useSelector(getSelectUser)
  const chatCount = chatsList?.reduce((a, b) => a + b?.unreadCounts, 0)

  const isMainMenuHidden = ['/user-profile', '/boat-inner', '/rent-inner', '/marina-storage-inner', '/boat-service-inner', '/article', '/review-booking']
    .some(s => String(history.location.pathname).startsWith(s));

  const dispatch = useDispatch()
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = React.useState(null)

  const [indexHeader, setIndexHeader] = React.useState('z-header-fix')
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null)
  const isMenuOpen = Boolean(anchorEl)
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl)
  const isAuth = getLocalStorageItem('isAuthenticated')

  const [isVerificationModalOpen, setIsVerificationModalOpen] = useState(false)

  const audioPlayer = useRef(null)

  function playAudio() {
    const notification = getLocalStorageItem('isNotification') === 'on'
    if (notification) {
      audioPlayer.current.play()
    }
  }

  useEffect(() => {
    if (!getLocalStorageItem('isNotification')) setLocalStorageItem('isNotification', 'on')
  }, [])

  function handleMobileMenuClose() {
    setMobileMoreAnchorEl(null)
  }
  function handleMenuClose() {
    setAnchorEl(null)
    handleMobileMenuClose()
  }
  function handleMobileMenuOpen(event) {
    setMobileMoreAnchorEl(event.currentTarget)
  }
  const menuId = 'primary-search-account-menu'
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    ></Menu>
  )
  const mobileMenuId = 'primary-search-account-menu-mobile'
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <Link to="/" className={classes.textStyle}>
          Register
        </Link>
      </MenuItem>
      <MenuItem>
        <Link to="/" className={classes.textStyle}>
          Sign Up
        </Link>
      </MenuItem>
      <MenuItem>
        <Link to="/" className={classes.textStyle}>
          Help
        </Link>
      </MenuItem>
      <MenuItem>
        <Link to="/rent" className={classes.textStyle}>
          Charter & Rent
        </Link>
      </MenuItem>
      <MenuItem>
        <Link to="/boat-service" className={classes.textStyle}>
          Boat Service
        </Link>
      </MenuItem>
      <MenuItem>
        <Link to="/marina-storage" className={classes.textStyle}>
          Marina & Storage
        </Link>
      </MenuItem>
      <MenuItem>
        <Link to="/boat-show" className={classes.textStyle}>
          Boat Show
        </Link>
      </MenuItem>
    </Menu>
  )

  const [open, isOpen] = useState(false)

  function isShowPopUp(isLoginOpen) {
    setIsLoginOpen(isLoginOpen)
  }
  function setSticky(value) {
    value ? setIndexHeader('z-header-fix-sticky') : setIndexHeader('z-header-fix')
  }
  const [isLoginOpen, setIsLoginOpen] = React.useState(false)
  const [prevChatCount, setPrevChatCount] = useState(0)

  useEffect(() => {
    if (chatCount > prevChatCount) {
      playAudio()
    }
    setPrevChatCount(chatCount)
  }, [chatCount])

  useEffect(() => {
    getAllWishlists()
  }, [getAllWishlists])

  useEffect(() => {
    country && props.getCountryDetails(country)
  }, [country])

  useEffect(() => {
    if (currentUser?.id) {
      return () => {
        socket?.off('connect')
        socket?.off('disconnect')
        socket?.off('pong')
      }
    }
  }, [currentChatUser])

  useEffect(() => {
    if (currentChatUser?.id) {
      const hasListner = socket?.hasListeners(socketEvent.LiveChatCounts)
      if (!hasListner) {
        socket.on(socketEvent.LiveChatCounts, userUpdate => {
          dispatch(setOnlineOfflineUser(userUpdate))
        })
      }

      const hasAddRemoveListner = socket.hasListeners(socketEvent.AddOrRemoveUser)

      if (!hasAddRemoveListner) {
        socket.on(socketEvent.AddOrRemoveUser, userUpdate => {
          dispatch(setAddRemoveUser(userUpdate))
        })
      }
      const hasDeliverMessageListner = socket.hasListeners(socketEvent.DeliverMessage)
      if (!hasDeliverMessageListner) {
        socket.on(socketEvent.DeliverMessage, data => {
          if (data.msgIds.length > 0) {
            dispatch(setDeliveredMessage(data))
          }
        })
      }

      const hasDeleteMessageListner = socket.hasListeners(socketEvent.DeleteMessage)

      if (!hasDeleteMessageListner) {
        socket.on(socketEvent.DeleteMessage, UserUpdate => {
          dispatch(deleteMessageChatByIDAction(JSON.parse(UserUpdate).msgIds))
        })
      }
      const LastActiveHasListner = socket.hasListeners(socketEvent.LastActive)

      if (!LastActiveHasListner) {
        socket.on(socketEvent.OfflineUserCounts, userUpdate => {
          dispatch(setLastActiveTime(userUpdate))
        })
      }

      const hasUserChatListner = socket.hasListeners(socketEvent.UserChats)

      if (!hasUserChatListner) {
        socket.on(socketEvent.UserChats, data => {
          dispatch(setUserList(data))
          dispatch(setUnreadCount(data))
        })
      }
      dispatch(getChatListsByCurrentUser({ limit: 1000, searchTerm: '' }))

      return () => {
        socket.off(socketEvent.LiveChatCounts)
        socket.off(socketEvent.UserChats)
      }
    }
  }, [currentChatUser])

  useEffect(() => {
    if (currentChatUser?.id) return
    if (currentUser?.id) {
      dispatch(getChatMe())
      !notifications_read.loading && readNotifications({ cache: { page: 1, notifications: [] }, input: { page: 1 } })
    }
  }, [currentUser])

  useEffect(() => {}, [])

  useEffect(() => {
    if (notifications_read.success || notifications_read.failure) {
      readNotifications_clear()
    }
  }, [notifications_read])

  const dashboardSetLogo = () => {
    const { role } = currentUser

    if (role) {
      switch (role.aliasName) {
        case 'rent-and-charter':
          return {
            src: require('../../assets/images/logo/home.png'),
            className: 'logo-fix',
            url: '/',
          }

        case 'marina-and-storage':
          return {
            src: require('../../assets/images/logo/home.png'),
            className: 'logo-fix',
            url: '/',
          }

        case 'service-and-maintenance':
          return {
            src: require('../../assets/images/logo/home.png'),
            className: 'logo-fix',
            url: '/',
          }

        default:
          return {
            src: require('../../assets/images/logo/home.png'),
            className: 'logo-fix',
            url: '/',
          }
      }
    }
    return {
      src: require('../../assets/images/logo/home.png'),
      className: 'logo-fix',
    }
  }

  const setLogo = () => {
    if (match) {
      const { path } = match

      switch (path) {
        /** Rent Path */
        case '/rent':
        case '/rent-category-wise-boats/:tripType/:tripTypeId/:country':
        case '/rent-city-wise-boats/:city/:country':
        case '/show-all-rent/:type':
        case '/rent-inner/:id/:name':
        case '/search-rent-boats':
          return {
            key: siteConfigurations?.rentLogo?.url,
            className: 'logo-fix',
            url: '/rent',
          }

        /** Boat Service Path */
        case '/boat-service':
        case '/boat-service-inner/:id/:name':
        case '/category-wise-boat-service':
        case '/show-all-boat-service-gallery':
        case '/category-wise-boat-service/:typeId/:typeName':
        case '/search-boat-services':
          return {
            key: siteConfigurations?.yachtServiceLogo?.url,
            className: 'logo-fix',
            url: '/boat-service',
          }

        /** Marina Storage Path */
        case '/marina-storage':
        case '/marina-storage-view':
        case '/marina-storage-service-view/:serviceId':
        case '/marina-storage-inner/:id/:name':
        case '/search-marina-storage':
        case '/show-all-marina-and-storage/:type':
          return {
            key: siteConfigurations?.marinaAndStorageLogo?.url,
            className: 'logo-fix',
            url: '/marina-storage',
          }

        case '/boat-show':
        case '/show-all-boat-show':
        case '/search-boat-show':
          return {
            key: siteConfigurations?.boatShowLogo?.url,
            className: 'logo-fix',
            url: '/boat-show',
          }

        case '/dashboard':
        case '/profile':
        case '/manage-wishlist':
        case '/my-bookings':
        case '/manage-marina-storage':
        case '/manage-articles':
        case '/manage-boat-shows':
        case '/manage-payment':
        case '/manage-payouts':
        case '/manage-rent-payouts':
        case '/change-password':
          return {
            key: dashboardSetLogo().src,
            className: `${dashboardSetLogo().className} dashboard-type-logo`,
            url: '/',
          }

        case '/boat-media':
          return {
            key: siteConfigurations?.boatMediaLogo?.url,
            className: 'logo-fix',
            url: '/boat-media',
          }

        default:
          return {
            key: require('../../assets/images/logo/home.png'),
            className: 'logo-fix',
            url: '/',
          }
      }
    } else {
      return {
        key: require('../../assets/images/logo/home.png'),
        className: 'logo-fix',
        url: '/',
      }
    }
  }

  const [currencyDialog, setCurrencyDialog] = useState(false)

  const currencyModalHandler = e => {
    e && e.preventDefault()
    setCurrencyDialog(!currencyDialog)
  }

  //------------------------Search related methods------------------------//

  const [query, setQuery] = useState('')
  const [queryError, setQueryError] = useState('')
  const [queryChanged, setQueryChanged] = useState(false)

  const searchHandler = async () => {
    const payload = {
      query: query,
      page: pagination.PAGE_COUNT,
      limit: pagination.HEADER_SEARCH_BOX_LIMIT,
    }
    setQueryError('')

    if (query && query.length >= 3) {
      isOpen(true)
      await globalSearch(payload)
      getRecentSearch()
    }
  }

  useEffect(() => {
    if (history.location.pathname == '/search') {
      isOpen(false)
    }
    const timer = setTimeout(() => {
      const payload = {
        query: query,
        page: pagination.PAGE_COUNT,
        limit: pagination.HEADER_SEARCH_BOX_LIMIT,
      }

      setQueryError('')
      if (query && query.length >= 3) {
        isOpen(true)
        globalSearch(payload)
        getRecentSearch()
      } else if (query.length === 0) {
        isOpen(false)
      }
      if (history.location.pathname == '/search' && !queryChanged) {
        let arr = history.location.search.split('=')
        setQuery(decodeURIComponent(arr[arr.length - 1]))
        isOpen(false)
      }
    }, 500)
    return () => clearTimeout(timer)
  }, [query, globalSearch, getRecentSearch])

  const openSearchedResults = () => {
    if (history.location.pathname == '/search') {
      isOpen(false)
      return false
    }
    query && query.length >= 3 && isOpen(true)
  }

  //------------------------Search related methods------------------------//

  const renderSearchBar = () => (
    <Grid item sm={6} className="justify-center header-search-main-div mt-10" onMouseLeave={() => isOpen(false)}>
      <audio ref={audioPlayer} src={NotificationSound} />
      <div className="h-100">
        <OutsideClickHandler onOutsideClick={() => isOpen(false)}>
          <div className="width-search h-100">
            <div className="main-home-search h-100">
              <span className="pl-10" style={{ color: '#777' }}>
                <IoIosSearch style={{ width: 16, height: 16 }} />
              </span>
              <InputBase
                placeholder="Search anything"
                inputProps={{ 'aria-label': 'search google maps' }}
                className="search-input search-mobile"
                value={query}
                onChange={e => {
                  setQuery(e.target.value)
                  setQueryChanged(true)
                }}
                onClick={openSearchedResults}
              />
              <div className="d-flex">
                <img
                  src={require('../../assets/images/AdamSea-Gray.png')}
                  alt="AdamSea Logo Gray"
                  style={{ height: 14, maxWidth: 'max-content', marginRight: 10 }}
                />
              </div>
            </div>
            <span className="error-message error-search-message position-absolute">{!open && queryError}</span>
          </div>

          {!(history.location.pathname == '/search') && open && (
            <SearchDropdown query={query} setRecentQuery={query => setQuery(query)} history={history} />
          )}
        </OutsideClickHandler>

        <div className="header-not-0">
          <CommonTooltip
            className="common-tooltip-guide"
            key={history.location.state?.showFirstTimeGuide}
            isOpen={history.location.state?.showFirstTimeGuide === 7}
            clickable
            closeOnScroll={false}
            closeOnResize={false}
            content={
              <div id="showFirstTimeGuide-content-7">
                <div style={{ textAlign: 'center' }}>
                  Search for anything using keywords: Users, Trips, Boats, Services, Articles, Marine, and more.
                </div>
                <div style={{ textAlign: 'center' }}>
                  <button
                    className="btn common-tooltip-button"
                    onClick={() => {
                      history.replace({ ...history.location, state: { showFirstTimeGuide: 0 } })
                    }}
                  >
                    Got it
                  </button>
                </div>
              </div>
            }
            component={ctProps => <div data-tooltip-id={ctProps.id} data-tooltip-place="bottom"></div>}
          />
        </div>
      </div>
    </Grid>
  )

  const userRole = getLocalInfo('role')
  return (
    <>
      <div className={`${classes.grow}  d-sm-menu-none ${props.customClassName} ${indexHeader} z-top-header`}>
        <AppBar
          className={`header__bg header-responsive adam-glo-header  ${
            currentUser && currentUser.id ? 'login-user' : 'logout-user'
          } header-bg-index`}
        >
          <Toolbar className={`${classes.headerPadding} pt-0 flex-column`}>
            <Grid container className="mt-10 icons-here">
              <Grid item sm={3} className="header-logo-grid-mobile">
                <div className={`${classes.title} header-logo`}>
                  <Link to={setLogo().url}>
                    <img
                      loading="lazy"
                      src={setLogo().key}
                      alt="Logo"
                      className={`${classes.logoMobile} ${setLogo().className}`}
                    />
                  </Link>
                  <Link to={setLogo().url} className="desktop-logo-size">
                    <img loading="lazy" src={setLogo()} alt="Logo" className={classes.logoDesktop} />
                  </Link>
                </div>
              </Grid>

              {renderSearchBar()}

              <Grid item sm={3}>
                {!isAuth && (
                  <div className={`${classes.desktopMenu} desktopMenu-pt-0 float-right`}>
                    {!isMainMenuHidden && (
                      <MenuItem className="menu__help font-12 header-btn menuitem-button-no-hover" style={{ overflow: 'visible' }}>
                        <GetApp />
                      </MenuItem>
                    )}
                    <MenuItem className="menu__help font-12 header-btn menuitem-button-no-hover">
                      <Link to="/help" className={`${classes.textStyleBlack} paddingRight-1 _light-btn`}>
                        <span className="link-hover-text">Help Center</span>
                      </Link>
                    </MenuItem>
                    <MenuItem className="menu__help font-12 currency-bg header-btn menuitem-button-no-hover">
                      <Link to="/" className={`${classes.textStyleBlack} _light-btn`}>
                        <div to="#" data-toggle="modal" data-target="#currency-modal" onClick={currencyModalHandler}>
                          {getLocalStorageItem('currentCurrency')}
                        </div>
                      </Link>
                    </MenuItem>
                    <CurrencyConverterModal open={currencyDialog} closeModal={currencyModalHandler} />
                  </div>
                )}

                <div className="mange-right size-color d-flex align-items-center justify-content-end">
                  {isAuth && (
                    <>
                      {isAuth && (
                        <div className="menu__persons HeaderMenu-hover header-btn mr-3 cursor-pointer">
                          <div
                            style={{ padding: 5, border: '1px solid #0003', borderRadius: 9999 }}
                            onClick={() => (isLoginOpen ? setIsLoginOpen(false) : isShowPopUp(true))}
                          >
                            <UserConsumer>
                              {({ currentUser }) => (
                                <div className="d-flex align-items-center">
                                  <div
                                    className="mr-2 header-user-image-div cursor-pointer"
                                    onClick={() => redirectToUserProfile(currentUser, history)}
                                  >
                                    <CommonTooltip
                                      component={ctProps => (
                                        <img
                                          data-tooltip-id={ctProps.id}
                                          data-tooltip-content="Visit My Profile"
                                          src={
                                            getLocalStorageItem('userImage')
                                              ? getLocalStorageItem('userImage')
                                              : defaultProfileIcon
                                          }
                                          alt="Profile-icon"
                                          className="h-100 width-100"
                                        />
                                      )}
                                    />
                                  </div>
                                  <div className="ml-1 d-flex flex-column mr-2">
                                    <span className="header-user-name color-black font-weight-500">
                                      {getLocalStorageItem('firstName')}
                                    </span>
                                  </div>
                                  <div style={{ transform: isLoginOpen ? 'rotate(180deg)' : undefined }}>
                                    <IoIosArrowDown />
                                  </div>
                                </div>
                              )}
                            </UserConsumer>
                          </div>
                        </div>
                      )}

                      {(+getLocalStorageItem('totalWishlists') > 0 || isAuth) && (
                        <div className="icns-pos-mobile">
                          <div
                            className="d-flex align-items-center"
                            style={{ padding: '12px 16px 12px 4px', borderRadius: 999, border: '1px solid #0003' }}
                          >
                            {+getLocalStorageItem('totalWishlists') > 0 && (
                              <CommonTooltip
                                component={ctProps => (
                                  <div
                                    className=" header-btn heart-1-li header-cart-icon cursor-pointer ml-2"
                                    data-tooltip-id={ctProps.id}
                                    data-tooltip-content="Wishlist"
                                  >
                                    <Link
                                      to="/manage-wishlist"
                                      onClick={e => +getLocalStorageItem('totalWishlists') === 0 && e.preventDefault()}
                                      className={`${classes.textStyle} d-flex position-relative`}
                                    >
                                      <i className="adam-heart-1"></i>
                                      <span className="cart-no">
                                        {displayDefaultNumericValue(getLocalStorageItem('totalWishlists'))}
                                      </span>
                                    </Link>
                                  </div>
                                )}
                              />
                            )}

                            {isAuth && (
                              <>
                                {history.location.state?.showFirstTimeGuide === 5 ? (
                                  <CommonTooltip
                                    className="common-tooltip-guide"
                                    key={history.location.state?.showFirstTimeGuide}
                                    isOpen
                                    clickable
                                    closeOnScroll={false}
                                    closeOnResize={false}
                                    content={
                                      <div id="showFirstTimeGuide-content-5">
                                        <div style={{ textAlign: 'center' }}>
                                          Send and receive instant messages and find other users.
                                        </div>
                                        <div style={{ textAlign: 'center' }}>
                                          <button
                                            className="btn common-tooltip-button"
                                            onClick={() => {
                                              history.replace({ ...history.location, state: { showFirstTimeGuide: 6 } })
                                              setTimeout(() => {
                                                document
                                                  .getElementById(`showFirstTimeGuide-content-6`)
                                                  ?.scrollIntoView({ block: 'center' })
                                              }, 200)
                                            }}
                                          >
                                            Got it
                                          </button>
                                        </div>
                                      </div>
                                    }
                                    component={ctProps => (
                                      <div
                                        onClick={() => {
                                          if (
                                            !currentUser.documentVerification?.emailVerified ||
                                            !currentUser.documentVerification?.mobileVerified
                                          ) {
                                            setIsVerificationModalOpen(true)
                                          } else if (props.cookiePolicyStatus !== 1) {
                                            props.toggleCookiePolicyModal(true)
                                          } else {
                                            if (selectUser?.chatId) {
                                              socket.emit(socketEvent.LeaveChannel, selectUser?.chatId)
                                            }
                                            dispatch(chatToggle(!isChatShow))
                                          }
                                        }}
                                        className=" header-btn heart-1-li header-cart-icon cursor-pointer ml-3 position-relative"
                                        data-tooltip-id={ctProps.id}
                                        data-tooltip-place="left"
                                      >
                                        <>
                                          {/* <Link to="/chat" className={`${classes.textStyle} d-flex`}> */}
                                          <img loading="lazy" src={chatIcon} className="width-height-icon-bell" alt="icon" />
                                          {chatCount > 0 && <span className="cart-no">{chatCount}</span>}
                                          {/* </Link> */}
                                        </>
                                      </div>
                                    )}
                                  />
                                ) : (
                                  <CommonTooltip
                                    component={ctProps => (
                                      <div
                                        onClick={() => {
                                          if (
                                            !currentUser.documentVerification?.emailVerified ||
                                            !currentUser.documentVerification?.mobileVerified
                                          ) {
                                            setIsVerificationModalOpen(true)
                                          } else if (props.cookiePolicyStatus !== 1) {
                                            props.toggleCookiePolicyModal(true)
                                          } else {
                                            if (selectUser?.chatId) {
                                              socket.emit(socketEvent.LeaveChannel, selectUser?.chatId)
                                            }
                                            dispatch(chatToggle(!isChatShow))
                                          }
                                        }}
                                        className=" header-btn heart-1-li header-cart-icon cursor-pointer ml-3 position-relative"
                                        data-tooltip-id={ctProps.id}
                                        data-tooltip-content="Chat"
                                      >
                                        <>
                                          {/* <Link to="/chat" className={`${classes.textStyle} d-flex`}> */}
                                          <img loading="lazy" src={chatIcon} className="width-height-icon-bell" alt="icon" />
                                          {chatCount > 0 && <span className="cart-no">{chatCount}</span>}
                                          {/* </Link> */}
                                        </>
                                      </div>
                                    )}
                                  />
                                )}
                              </>
                            )}
                            {isAuth && (
                              <>
                                {history.location.state?.showFirstTimeGuide === 6 ? (
                                  <CommonTooltip
                                    className="common-tooltip-guide"
                                    key={history.location.state?.showFirstTimeGuide}
                                    isOpen
                                    clickable
                                    closeOnScroll={false}
                                    closeOnResize={false}
                                    content={
                                      <div id="showFirstTimeGuide-content-6">
                                        <div style={{ textAlign: 'center' }}>View all notifications here.</div>
                                        <div style={{ textAlign: 'center' }}>
                                          <button
                                            className="btn common-tooltip-button"
                                            onClick={() => {
                                              history.replace({ ...history.location, state: { showFirstTimeGuide: 7 } })
                                              setTimeout(() => {
                                                document
                                                  .getElementById(`showFirstTimeGuide-content-7`)
                                                  ?.scrollIntoView({ block: 'center' })
                                              }, 200)
                                            }}
                                          >
                                            Got it
                                          </button>
                                        </div>
                                      </div>
                                    }
                                    component={ctProps => (
                                      <div
                                        className=" header-btn heart-1-li header-cart-icon cursor-pointer ml-3"
                                        data-tooltip-id={ctProps.id}
                                        data-tooltip-place="left"
                                      >
                                        <Link to="/notification" className={`${classes.textStyle} d-flex position-relative`}>
                                          <img loading="lazy" src={bellIcon} className="width-height-icon-bell" alt="icon" />
                                          {get(notifications_read, 'data.result.unread', 0) ? (
                                            <span className="cart-no">{get(notifications_read, 'data.result.unread', 0)}</span>
                                          ) : null}
                                        </Link>
                                      </div>
                                    )}
                                  />
                                ) : (
                                  <CommonTooltip
                                    component={ctProps => (
                                      <div
                                        className=" header-btn heart-1-li header-cart-icon cursor-pointer ml-3"
                                        data-tooltip-id={ctProps.id}
                                        data-tooltip-content="Notification"
                                      >
                                        <Link to="/notification" className={`${classes.textStyle} d-flex position-relative`}>
                                          <img loading="lazy" src={bellIcon} className="width-height-icon-bell" alt="icon" />
                                          {get(notifications_read, 'data.result.unread', 0) ? (
                                            <span className="cart-no">{get(notifications_read, 'data.result.unread', 0)}</span>
                                          ) : null}
                                        </Link>
                                      </div>
                                    )}
                                  />
                                )}
                              </>
                            )}
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </div>
              </Grid>
            </Grid>

            <Grid container className="menu-marginTop mobile-menu-location">
              <Grid
                container
                className={`d-flex manage-header` && (!isMainMenuHidden ? ' mb-2' : '')}
              >
                <Grid item sm={3}></Grid>

                <Grid item sm={6} className="d-flex justify-content-center">
                  {!isMainMenuHidden && (
                    <nav className="market-links">
                      <Link to="/">
                        <img src={require('../../assets/images/boat_13553904.svg')} alt="buy" /> Buy
                      </Link>
                      <Link to="/rent">
                        <img src={require('../../assets/images/summer_13554163.svg')} alt="rent" /> Rent
                      </Link>
                      <Link to="/boat-service">
                        <img src={require('../../assets/images/boat_13553980.svg')} alt="service" /> Service
                      </Link>
                      <Link to="/marina-storage">
                        <img src={require('../../assets/images/boat_13554204.svg')} alt="marina" /> Marina
                      </Link>
                      <Link to="/boat-show">
                        <img src={require('../../assets/images/arrows_13553916.svg')} alt="boat show" /> Boat Show
                      </Link>
                      <Link to="/boat-media">
                        <img src={require('../../assets/images/camera_13553889.svg')} alt="media" /> Media
                      </Link>
                    </nav>
                  )}
                </Grid>

                <Grid item sm={3} className="d-flex justify-content-end">
                  <div className="mange-right size-color d-flex align-items-center">
                    {!isAuth && (
                      <div className="d-flex align-items-center">
                        <div
                          style={{ marginRight: '16px' }}
                          className={`${classes.overflowUnset} header-btn dropdownButtonWidth appbarRegisterButton HeaderMenu-hover p-0`}
                        >
                          <div className="position-relative">
                            <RegisterUser
                              setSticky={value => setSticky(value)}
                              siteConfigurations={siteConfigurations}
                              country={country}
                            />
                          </div>
                        </div>

                        <div className="HeaderMenu-hover">
                          {+getLocalStorageItem('totalWishlists') > 0 && (
                            <CommonTooltip
                              component={ctProps => (
                                <div
                                  className=" header-btn heart-1-li header-cart-icon cursor-pointer ml-2"
                                  data-tooltip-id={ctProps.id}
                                  data-tooltip-content="Wishlist"
                                >
                                  <Link
                                    to="/manage-wishlist"
                                    onClick={e => +getLocalStorageItem('totalWishlists') === 0 && e.preventDefault()}
                                    className={`${classes.textStyle} d-flex position-relative`}
                                  >
                                    <i className="adam-heart-1"></i>
                                    <span className="cart-no">
                                      {displayDefaultNumericValue(getLocalStorageItem('totalWishlists'))}
                                    </span>
                                  </Link>
                                </div>
                              )}
                            />
                          )}
                        </div>
                      </div>
                    )}

                    {isAuth && !isMainMenuHidden && <div className="HeaderMenu-hover"><GetApp /></div>}
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
        {renderMobileMenu}
        {renderMenu}
      </div>
      <OutsideClickHandler
        onOutsideClick={() => {
          isShowPopUp(false)
        }}
      >
        {isLoginOpen && (
          <UserConsumer>
            {({ currentUser }) => (
              <div
                className="login-user-div p-4"
                onMouseLeave={e => {
                  isShowPopUp(false)
                }}
              >
                {/* <h6>Your Market Title</h6> */}
                {isAuth && (
                  <>
                    {userRole && userRole.role && (
                      <span className="header-user-role header--user--role--div">{userRole.role.toLowerCase()}</span>
                    )}
                    <div>
                      <div className="mt-2 d-flex flex-wrap">
                        <span className=" font-weight-500 login-user-div-text small-screen-login-user-title header-dropdown-user-role mr-1">
                          Since
                        </span>
                        <span className=" font-weight-500 login-user-div-text small-screen-login-user-title header-dropdown-user-role">
                          {moment(currentUser && currentUser.createdAt).format('DD-MM-YYYY')}
                        </span>
                      </div>
                    </div>
                  </>
                )}
                <hr className="my-2" />
                <div className="mb-0 d-flex flex-column">
                  {/* <span className="login-user-div-text font-weight-500 small-screen-login-user-info">My Account</span> */}
                  <div className="d-flex flex-column">
                    <Link to="/dashboard" className="header-dropdown-link hover-text-only text-decoration-none">
                      <span className="login-user-div-text login-user-div-text-link small-screen-login-user-info">
                        <img
                          src={require('../../containers/dashboard/image/Dashboard.svg')}
                          alt="My Store"
                          className="login-user-div-text-link-img"
                        />{' '}
                        Dashboard
                      </span>
                    </Link>
                    {currentUser && currentUser.role && salesEngineAccessibleTypes.includes(currentUser.role.type) && (
                      <span
                        className="header-dropdown-link hover-text-only"
                        onClick={() => {
                          currentUser.documentVerification && currentUser.documentVerification.emailVerified
                            ? history.push('/sales-engines')
                            : SuccessNotify(requiredProfileUpdate)
                        }}
                      >
                        <span className="login-user-div-text login-user-div-text-link small-screen-login-user-info cursor-pointer">
                          <img
                            src={require('../../containers/dashboard/image/My Store (2).svg')}
                            alt="My Store"
                            className="login-user-div-text-link-img"
                          />{' '}
                          My Store
                        </span>
                      </span>
                    )}
                    <Link to="/change-password" className="header-dropdown-link hover-text-only text-decoration-none">
                      <span className="login-user-div-text login-user-div-text-link small-screen-login-user-info">
                        <img
                          src={require('../../containers/dashboard/image/Change Password.svg')}
                          alt="My Store"
                          className="login-user-div-text-link-img"
                        />{' '}
                        Change Password
                      </span>
                    </Link>
                    <Link to="/logout" className="header-dropdown-link hover-text-only text-decoration-none">
                      <span
                        className="login-user-div-text login-user-div-text-link cursor-pointer small-screen-login-user-info"
                        onClick={logout}
                      >
                        <img
                          src={require('../../containers/dashboard/image/Log-out.svg')}
                          alt="My Store"
                          className="login-user-div-text-link-img"
                        />{' '}
                        Logout
                      </span>
                    </Link>
                  </div>
                </div>
                {/* <hr />
                {currentUser && currentUser.id && (
                  <>
                    <div className="mt-2 header-dropdown-link">
                      <span className="wrap-text login-user-notification-text small-screen-login-user-info">
                        This will manage your account status, if you turn off your account, your posted Ads will not be display
                        in your market and your profile will be hidden to be seen by your contact.
                      </span>
                    </div>
                    <div className="login-user-div-switch mt-4 d-flex align-items-center">
                      <div className="switchtoggle">
                        <input
                          type="checkbox"
                          checked={currentUser.accountActivated}
                          id="switch"
                          onClick={() =>
                            changeUserStatus({
                              id: currentUser.id,
                              column: 'accountActivated',
                              value: currentUser.accountActivated,
                            })
                          }
                        />
                        <label for="switch">Toggle</label>
                      </div>
                      <div className="pb-1">
                        <Link to="#" className="header-dropdown-link">
                          <span className="login-user-div-text login-user-div-text-link small-screen-login-user-info ml-2">
                            Turn {currentUser.accountActivated ? 'Off' : 'On'} My Account
                          </span>
                        </Link>
                      </div>
                    </div>
                  </>
                )} */}
              </div>
            )}
          </UserConsumer>
        )}
      </OutsideClickHandler>

      <CommonModal
        open={isVerificationModalOpen}
        close={() => setIsVerificationModalOpen(false)}
        title="Email and mobile verification"
        className="email--verification--dialogue"
        hideCloseIcon
      >
        <div className="email--verification--desc--div">
          <div>
            <p className="email--verification--desc">
              You need to verify your email address and mobile number before using AS CHATS.
            </p>
          </div>
        </div>
        <div className="d-flex justify-content-between">
          <ButtonComponent
            className="email--verification--resend--btn h-auto as--view--outline"
            onClick={() => {
              setIsVerificationModalOpen(false)
              history.push('/profile')
            }}
          >
            GO TO PROFILE
          </ButtonComponent>

          <ButtonComponent
            className="email--verification--resend--btn h-auto as--cancel--outline"
            onClick={() => setIsVerificationModalOpen(false)}
          >
            CANCEL
          </ButtonComponent>
        </div>
      </CommonModal>

      <MobileMenu headerClasses={classes} dashboardnavbar={props.mobileMenu_dashboardnavbar} />
    </>
  )
}

const mapStateToProps = state => ({
  siteConfigurations: state.loginReducer.siteConfigurations,
  notifications_read: state.notificationsReducer.read,
  cookiePolicyStatus: state.loginReducer.cookiePolicyStatus,
})

const mapDispatchToProps = dispatch => ({
  globalSearch: data => dispatch(globalSearch(data)),
  getRecentSearch: () => dispatch(getRecentSearch()),
  changeUserStatus: data => dispatch(changeUserStatus(data)),
  getCountryDetails: data => dispatch(getCountryDetails(data)),
  readNotifications: data => dispatch(notificationsSlice.actions.read.main(data)),
  readNotifications_clear: data => dispatch(notificationsSlice.actions.read.clear(data)),
  toggleCookiePolicyModal: data => dispatch(toggleCookiePolicyModal(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Header)
