import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import MailOutlineIcon from '@material-ui/icons/MailOutline'

import { Modal, Backdrop, Fade, Button, Link } from '@material-ui/core'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { clearErrorMessageShow } from '../../redux/actions'
import ErrorComponent from '../error/errorComponent'

import '../dashboard/dashboard.scss'
import { toast } from 'react-toastify'

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: '999999 !important',
  },

  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}))

const ConfirmationEmail = props => {
  const classes = useStyles()

  const { open, email, close, isError, errorMessage, clearErrorMessageShow, isMailSent } = props

  useEffect(() => {
    clearErrorMessageShow()
  }, [clearErrorMessageShow])

  useEffect(() => {
    isMailSent &&
      (close(),
      toast.success('Mail Sent Successfully', {
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: true,
      }))
  }, [isMailSent])

  const sendEmailLinkHandler = async values => {
    const { sendLink } = props
    await sendLink(values)
  }

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={`${classes.modal} confirm-mail-model `}
        open={open}
        onClose={close}
        classes={{
          root: 'z-index-bg confirm-mail-model-root',
        }}
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={`${classes.paper} confirm-mail-model-content`}>
            <h2 id="transition-modal-title" className="confirm--mail--main--title">
              Confirm Your Mail{' '}
              <span className="close-model-mail cursor-pointer" onClick={close}>
                <CloseIcon />
              </span>
            </h2>
            <div className="model-content-custom">
              <p>We'll send an email to the address below. Tap the link in the email to confirm it's you.</p>
              <div className="mail-icon-confirmation">
                <span className="amil-icon-span  amil--icon--span">
                  <MailOutlineIcon />
                </span>
              </div>
              <Formik
                initialValues={{ email }}
                validationSchema={Yup.object().shape({
                  email: Yup.string().email('Email is invalid').required('Email is required'),
                })}
                onSubmit={values => {
                  clearErrorMessageShow()
                  sendEmailLinkHandler(values)
                }}
                render={({ setFieldValue, errors }) => (
                  <Form>
                    <div className="w-100 confirmation--email--popup">
                      <input
                        name="email"
                        className="form-control"
                        defaultValue={email}
                        onChange={e => setFieldValue('email', e.target.value)}
                      />
                      {errors && errors.email && <span className="invalid-feedback-error">{errors.email}</span>}
                    </div>
                    <Button
                      variant="contained"
                      type="submit"
                      color="secondary"
                      className="send-confirmation"
                      disabled={isMailSent}
                    >
                      {'Send Confirmation Link'}
                    </Button>
                  </Form>
                )}
              />
              {isError && <ErrorComponent errors={errorMessage} />}
              <Link onClick={close} className="cursor-pointer confirmation-a-tag do--it--later">
                I'll do this later
              </Link>
            </div>
          </div>
        </Fade>
      </Modal>
    </>
  )
}
const mapStateToProps = state => ({
  isError: state.dashboardReducer.isError,
  errorMessage: state.errorReducer.errorMessage,
  isMailSent: state.dashboardReducer.isMailSent,
})
const mapDispatchToProps = dispatch => ({
  clearErrorMessageShow: () => dispatch(clearErrorMessageShow()),
})
export default connect(mapStateToProps, mapDispatchToProps)(ConfirmationEmail)
