import React from 'react'
import { Grid } from '@material-ui/core'

import '../../containers/salesEngine/process/SalesEngineStepper.scss'
import { defaultProfileIcon } from '../../util/enums/enums'
import { getAddress, cityCountryNameFormatter, redirectToUserProfile, mileToKilometers } from '../../helpers/jsxHelper'
import { ButtonComponent } from '../form/Button'
import '../../containers/salesEngine/UserInformationCard.scss'
import { ShareWithChatAndUser } from '../share/ShareWithChatAndUser'
import { getConvertedPrice } from '../../helpers/currencyConverterHelper'
import { CurrencyContextConsumer } from '../../CurrencyContext'
import InnerRatingModal from '../reviewRating/InnerRatingModal'
import { nameFormatter } from '../../helpers/string'
import { prettifyPhoneNumber } from '../../helpers/validationHelper'

export const UserInformationCard = props => {
  const {
    userInfo,
    surveyorInfo,
    declineSurveyor,
    salesEngine,
    className,
    isSurveyorWithBranch,
    isBuyer,
    history,
    isSurveyor,
    isShipper,
    handleChange,
    shipperPaymentData,
    getDocumentUrl,
    proposalDocument,
    enableReview,
    isAgent,
    shipperDocumentUploaded,
    isSurveyReviewEnable,
    payment,
  } = props

  const isSurveyorWithSubBranch = isSurveyor && !surveyorInfo.isMainBranch

  const { commonAddress } = getAddress(isSurveyorWithSubBranch ? surveyorInfo?.address : userInfo?.address)
  const price = isShipper ? shipperPaymentData?.price : isSurveyor && isSurveyorWithBranch && surveyorInfo?.pricePerFt
  const distance = isShipper ? shipperPaymentData?.distance : isSurveyor && isSurveyorWithBranch && surveyorInfo.distance

  return (
    <Grid item xs={12} className={`${className} surveyor--listings--content--grid`}>
      <div className="surveyor--listings--content w-100">
        <Grid container className="surveyor--listings--row m-0">
          <Grid item xs={4}>
            <div className="agent-info-list-with-image xyz">
              <div
                className="stepper-agent-img-div sales--engine--agent--img"
                onClick={() => redirectToUserProfile(userInfo, history)}
              >
                <img
                  src={isSurveyorWithSubBranch ? surveyorInfo?.image?.url : userInfo.image?.url || defaultProfileIcon}
                  className="h-100 width-100"
                  alt="Profile"
                />
              </div>
              <div className="agent-info-list">
                <div className="agent-info-name">
                  {isSurveyor ? surveyorInfo.contactName : nameFormatter([userInfo.firstName, userInfo.lastName])}
                </div>
                <div className="agent-info-name surveyor--city--country">
                  {cityCountryNameFormatter(commonAddress.city, commonAddress.country)}
                </div>
                <div className="d-flex verified--surveyor">
                  <img
                    src={require('../userProfile/image/verified.png')}
                    className="stepper-user-profile-icon-div mr-2"
                    alt="Verified"
                  />
                  <span className="rentInner-userTextH4  font-16 dark-silver">Verified</span>
                </div>
                <div className="agent-info-icon d-flex">
                  <ShareWithChatAndUser userId={userInfo.id} imageUrl={userInfo.image?.url}/>
                </div>
                {isSurveyor && <span className="header-user-role dark-silver header--user--role--div">SURVEYOR</span>}
                {isShipper && <span className="header-user-role dark-silver header--user--role--div">SHIPPER</span>}
              </div>
            </div>
          </Grid>

          <Grid item xs={4}>
            {(userInfo.documentVerification || (isShipper && shipperPaymentData)) && (
              <div>
                {price && (
                  <CurrencyContextConsumer>
                    {({ currentCurrency }) => (
                      <span className="surveyor--value">
                        {isShipper ? 'Cost of the Shipper' : 'Survey cost per feet'}{' '}
                        <span className="surveyor--value--blue">{getConvertedPrice(price, currentCurrency)}</span>
                      </span>
                    )}
                  </CurrencyContextConsumer>
                )}
                {distance && (
                  <span className="surveyor--value">
                    Distance to the boat{' '}
                    <span className="surveyor--value--blue">
                      {isShipper ? distance : mileToKilometers(distance)}{' '}
                      {isSurveyor && (+surveyorInfo.distance || 0).toLocaleString('en-US') && ' km'}
                    </span>
                  </span>
                )}
                <div className="provided">{userInfo.firstName} has provided</div>
                <div className="surveyor--verified-doc">
                  <div className="surveyor--verified-doc--w100">
                    {userInfo.documentVerification.emailVerified && (
                      <div className="survey--icon--with-verification">
                        <img
                          src={require('../userProfile/image/vector.png')}
                          className="stepper-user-profile-icon-div mr-2"
                          alt="Profile"
                        />
                        <span className="rentInner-userTextH4  font-16 dark-silver">Email</span>
                      </div>
                    )}

                    {userInfo.documentVerification.governmentIdVerified && (
                      <div className="survey--icon--with-verification">
                        <img
                          src={require('../userProfile/image/vector.png')}
                          className="stepper-user-profile-icon-div mr-2"
                          alt="Profile"
                        />
                        <span className="rentInner-userTextH4  font-16 dark-silver">Identity</span>
                      </div>
                    )}
                  </div>
                  <div className="surveyor--verified-doc--w100">
                    {userInfo.documentVerification.mobileVerified && (
                      <div className="survey--icon--with-verification">
                        <img
                          src={require('../userProfile/image/vector.png')}
                          className="stepper-user-profile-icon-div mr-2"
                          alt="Profile"
                        />
                        <span className="rentInner-userTextH4  font-16 dark-silver">Mobile Number</span>
                      </div>
                    )}

                    {userInfo.documentVerification.commercialLicenceVerified && (
                      <div className="survey--icon--with-verification">
                        <img
                          src={require('../userProfile/image/vector.png')}
                          className="stepper-user-profile-icon-div mr-2"
                          alt="Profile"
                        />
                        <span className="rentInner-userTextH4  font-16 dark-silver">Licence</span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </Grid>
          <Grid item xs={4}>
            <div className="surveyor--company--logo--btn">
              <div className="logo-with-select">
                {userInfo?.companyLogo?.url && (
                  <div className="logo-box m-auto">
                    <img loading="lazy" src={userInfo.companyLogo.url} height={50} width={50} alt="logo" />
                  </div>
                )}

                {userInfo.companyName && (
                  <div className="company-name dark-silver font-16 text-center mb-2">{userInfo.companyName}</div>
                )}
                {isShipper && enableReview && !isAgent && !shipperDocumentUploaded && (
                  <div className="user--info--rating--div">
                    <InnerRatingModal module={userInfo} userModule={salesEngine?.boat} />
                  </div>
                )}
              </div>
              {!salesEngine.surveyorPayment && isSurveyor && (
                <div className="surveyor--company--btn--cancel">
                  <ButtonComponent
                    color="danger"
                    onClick={() =>
                      declineSurveyor({
                        id: salesEngine.id,
                        surveyorId: salesEngine.surveyor && salesEngine.surveyor.id,
                      })
                    }
                  >
                    Cancel
                  </ButtonComponent>
                </div>
              )}
              <div className="d-flex flex-column">
                {isSurveyor &&
                  salesEngine.surveyorPayment &&
                  isBuyer &&
                  isSurveyReviewEnable &&
                  !salesEngine.surveyorReviewAdded && (
                    <div>
                      <div className="mb-3">
                        <InnerRatingModal module={userInfo} userModule={salesEngine?.boat} userRole={userInfo?.role} />
                      </div>
                      {!!surveyorInfo.mobileNumber && <div className="user--phone">
                        <a><i className="fas fa-phone-alt"></i> {prettifyPhoneNumber(surveyorInfo.mobileNumber)}</a>
                      </div>}
                    </div>
                  )}
                {isShipper && isBuyer && (
                  <div className="mb-2">
                    <div className="logo-with-select">
                      <button
                        className="selected-btn btn  btn-sales-engine-secondary logo-with-select-btn"
                        onClick={() => {
                          handleChange({ id: salesEngine.id })
                        }}
                      >
                        Change My Shipper
                      </button>
                    </div>
                  </div>
                )}
                {salesEngine.shipperPayment && isShipper && !isAgent && (
                  <>
                    {!shipperDocumentUploaded && (
                      <div className="download--proposal--btn--div mb-2">
                        <ButtonComponent
                          className="download--proposal--btn"
                          onClick={() => getDocumentUrl(proposalDocument?.id)}
                        >
                          Download Proposal
                        </ButtonComponent>
                      </div>
                    )}
                    <div className="shipment--cost--div">
                      <CurrencyContextConsumer>
                        {({ currentCurrency }) => (
                          <p className="shipment--cost">Total Shipment Cost: {getConvertedPrice(payment?.amount)}</p>
                        )}
                      </CurrencyContextConsumer>
                    </div>
                  </>
                )}
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </Grid>
  )
}

UserInformationCard.defaultProps = {
  className: '',
  isSurveyorWithBranch: false,
}
