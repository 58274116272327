import { Container, Row, Col, Image } from 'react-bootstrap'
import { Layout } from '../../components'
import './surveyorInfo.scss'
import { data } from './data'
import { footerScreenImages, icons } from '../../util/enums/enums'
import { ButtonComponent } from '../../components/form/Button'
import _styles from '../../styles/info-screens.module.scss'
import classNames from 'classnames'
import { Link } from 'react-router-dom'

export default function SurveyorInfo() {
  return (
    <Layout>
      <div className="container100">
        <Container className="d-flex justify-content-center align-items-center flex-column text-center py-5">
          <h1 className="r-h1">Surveyor Services</h1>
        </Container>

        <Container className="d-flex justify-content-center align-items-center flex-column text-center py-5">
          <span className="surveyor--detail">
            As a purchase surveyor, you play a crucial role in the boat buying process by assessing the condition and value of a
            boat before it is purchased. At Adamsea, we make it easy for purchase surveyors to offer their services to buyers
            and sellers on our platform.
          </span>
          <div className="blue--hr my-5"></div>
        </Container>
        <span className="surveyor-title pl-4">Become a Purchase Surveyor with AdamSea</span>
        <Row className="my-4">
          {data?.map(({ title, text }) => (
            <Col lg={4} sm={12}>
              <div style={{ minHeight: '365px' }} className="surveyor--card">
                <b className="surveyor-subtitle my-1">{title}</b>
                <p className="surveyor-text text-dark">{text}</p>
              </div>
            </Col>
          ))}
        </Row>
        <Row className="my-5">
          <Col className="text-center" lg={6} sm={12}>
            <img loading="lazy" width={303} src={footerScreenImages.surveyor_why_so_wait} alt="Icon" />
          </Col>
          <Col lg={6} sm={12}>
            <span className="surveyor-subtitle">So why wait?</span>
            <p className="surveyor-text">
              Sign up to be a purchase surveyor with Adamsea today and start offering your services to buyers and sellers around
              the world. With our global network and secure payment processing, you can expand your business and reach new
              customers with ease. Plus, with our convenient chat feature, you can communicate with buyers and sellers directly
              on the platform, making it easier than ever to offer your services and grow your business.
            </p>
          </Col>
        </Row>
      </div>

      <div className={classNames(_styles.secPadding)}>
        <div className="container100">
          <h2 className={_styles.fz_h}>How purchase surveyors work in AdamSea</h2>
          <p className={_styles.fz_p + ' mt-20'}>
            <Link to="/register/surveyor" className="btn btn-primary rounded-full">Sign up and generate your agreement ID</Link>
          </p>

          <ol className={"mt-30 " + _styles.fz_ul_lg}>
            <li>
                <div>Receive a request to do a purchase survey from the buyer.</div>
                <div className="mt-20">
                    <Image src={require('../../assets/images/info-screens/surveyor-how-1.png')} alt="Receive a request to do a purchase survey from the buyer" fluid />
                </div>
            </li>
            <li>
                <div>Reject or accept the request.</div>
                <div className="mt-20">
                    <Image src={require('../../assets/images/info-screens/surveyor-how-2.png')} alt="Reject or accept the request" fluid />
                </div>
            </li>
            <li>
                <div>Wait until the buyer proceeds with payment.</div>
                <div className="mt-20">
                    <Image src={require('../../assets/images/info-screens/surveyor-how-3.png')} alt="Wait until the buyer proceeds with payment" fluid />
                </div>
            </li>
            <li>
                <div>Submit the Survey report on time.</div>
                <div className="mt-20">
                    <Image src={require('../../assets/images/info-screens/surveyor-how-4.png')} alt="Submit the Survey report on time" fluid />
                </div>
            </li>
            <li>
                <div>Upload the Survey report.</div>
                <div className="mt-20">
                    <Image src={require('../../assets/images/info-screens/surveyor-how-5.png')} alt="Upload the Survey report" fluid />
                </div>
            </li>
            <li>
                <div>Receive the payout from AdamSea.</div>
            </li>
          </ol>
        </div>
      </div>
    </Layout>
  )
}
