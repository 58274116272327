import * as Yup from 'yup'
import { positiveNumberHelper, requireMessage } from './string'

export const stringValidation = Yup.string().matches(/^[a-zA-Z0-9$@$!%*(){}<>?&#^-_.,+-`'":;|(\s)]+$/, {
  excludeEmptyString: true,
  message: 'Use english letters only.',
})

export const addressObjValidation = Yup.object().shape({
  city: Yup.string().required(requireMessage('City')),
})

export const urlValidation = Yup.string().nullable().matches(
  /((http:\/\/(.*youtube\.com\/watch.*|.*\.youtube\.com\/v\/.*|youtu\.be\/.*|.*\.youtube\.com\/user\/.*|.*\.youtube\.com\/.*#.*\/.*|m\.youtube\.com\/watch.*|m\.youtube\.com\/index.*|.*\.youtube\.com\/profile.*|.*\.youtube\.com\/view_play_list.*|.*\.youtube\.com\/playlist.*|www\.youtube\.com\/embed\/.*|youtube\.com\/gif.*|www\.youtube\.com\/gif.*|www\.youtube\.com\/attribution_link.*|youtube\.com\/attribution_link.*|youtube\.ca\/.*|youtube\.jp\/.*|youtube\.com\.br\/.*|youtube\.co\.uk\/.*|youtube\.nl\/.*|youtube\.pl\/.*|youtube\.es\/.*|youtube\.ie\/.*|it\.youtube\.com\/.*|youtube\.fr\/.*|.*\.dailymotion\.com\/video\/.*|.*\.dailymotion\.com\/.*\/video\/.*|video\.google\.com\/videoplay\?.*|streamable\.com\/.*|www\.vimeo\.com\/groups\/.*\/videos\/.*|www\.vimeo\.com\/.*|vimeo\.com\/groups\/.*\/videos\/.*|vimeo\.com\/.*|vimeo\.com\/m\/#\/.*|player\.vimeo\.com\/.*|www\.facebook\.com\/photo\.php.*|www\.facebook\.com\/video\.php.*|www\.facebook\.com\/.*\/posts\/.*|fb\.me\/.*|www\.facebook\.com\/.*\/photos\/.*|www\.facebook\.com\/.*\/videos\/.*|fb\.com|www\.facebook\.com\/watch\/.*))|(https:\/\/(.*youtube\.com\/watch.*|.*\.youtube\.com\/v\/.*|youtu\.be\/.*|.*\.youtube\.com\/playlist.*|www\.youtube\.com\/embed\/.*|youtube\.com\/gif.*|www\.youtube\.com\/gif.*|www\.youtube\.com\/attribution_link.*|youtube\.com\/attribution_link.*|youtube\.ca\/.*|youtube\.jp\/.*|youtube\.com\.br\/.*|youtube\.co\.uk\/.*|youtube\.nl\/.*|youtube\.pl\/.*|youtube\.es\/.*|youtube\.ie\/.*|it\.youtube\.com\/.*|youtube\.fr\/.*|streamable\.com\/.*|www\.vimeo\.com\/.*|vimeo\.com\/.*|player\.vimeo\.com\/.*|www\.facebook\.com\/photo\.php.*|www\.facebook\.com\/video\.php.*|www\.facebook\.com\/.*\/posts\/.*|fb\.me\/.*|www\.facebook\.com\/.*\/photos\/.*|www\.facebook\.com\/.*\/videos\/.*|www\.facebook\.com\/events\/.*|www\.facebook\.com\/watch\/.*)))/gi,
  {
    excludeEmptyString: true,
    message: 'Please enter valid url. Only Youtube, Facebook, Daily Motion, Streamable, Google Videos and Vimeo are supported.',
  }
)

export const numberValidation = (name = '') => Yup.number().positive(positiveNumberHelper(name))

export const nameValidation = (field = '') => Yup.string().required(requireMessage(field)).matches(/^[a-zA-Z]+$/, 'Only alphabets are allowed')

export const alphabetsOnly = e => {
  if (/[^A-Za-z-/\s]+$/.test(e.key)) {
    e.preventDefault()
  }
}

export const alphabetsNumberOnly = e => {
  if (/[^A-Za-z0-9-/\s]+$/.test(e.key)) {
    e.preventDefault()
  }
}
