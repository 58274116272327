import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Formik, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'

import {
  getSingleSalesEngine,
  getSalesEngineByBoat,
  stopSalesEngineProcess,
  getSalesEngineStaticContent,
  salesEngineAgents,
  getCostEstimate,
  addSurveyOption,
  getShipperDocument,
  addShipperDocuments,
  updateShipperDocument,
  clearShipperDocument,
  salesEnginPaymentInfo,
} from '../../../redux/actions/salesEngineAction'
import { DashboardLayout, SellerInformation, Field, Loader } from '../../../components'
import '../SalesEngine.scss'

import { CommonSalesEnginTitle } from '../../../components/salesEngine/CommonSalesEnginTitle'
import { Button, Grid } from '@material-ui/core'
import { Col, Row } from 'react-bootstrap'
import { AgentInfoCard } from '../../../components/salesEngine/AgentInfoCard'
import { salesEngineStatusCheck, salesEngineSteps } from '../SalesEngineHelper'
import { SuccessNotify } from '../../../helpers/notification'
import { getDocumentUrl } from '../../../redux/actions'
import { MEDIA_NAME } from '../../../util/enums/enums'
import { requireMessage } from '../../../helpers/string'
import ErrorFocus from '../../../components/ws/ErrorFocus'
import { ButtonComponent } from '../../../components/form/Button'
import { getAddMedia } from '../../../helpers/s3FileUpload'
import { getAddress } from '../../../helpers/jsxHelper'
import { formattedDate } from '../../../helpers/dateTimeHelper'
import BoatInformation from '../../../components/salesEngine/BoatInformation'
import { CurrencyContextConsumer } from '../../../CurrencyContext'
import { getConvertedPrice } from '../../../helpers/currencyConverterHelper'

class ShipmentDocument extends Component {
  constructor(props) {
    super(props)

    this.state = {
      shipmentDocument: {
        billOfLoading: '',
        certificateOfOrigin: '',
        insuranceCertificate: '',
        receivedForShipment: '',
        invoiceOfShipmentPayment: '',
        otherDocument: '',
        addMedia: {
          billOfLoading: [],
          certificateOfOrigin: [],
          insuranceCertificate: [],
          receivedForShipment: [],
          invoiceOfShipmentPayment: [],
          otherDocument: [],
        },
        removeMedia: [],
      },
      activeStep: 1, // Current step.
      stepName: '', // Current Step Name.
      totalStep: 0, // Total number of steps for sales engine process/

      sellerUser: false,
      salesEngineStepsState: salesEngineSteps(),

      stepperWidth: 0,
      hrWidthCalc: 0,
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const {
      shipperDocumentSuccess,
      clearShipperDocument,
      getSingleSalesEngine,
      match: { params },
      getShipperDocument,
    } = nextProps

    if (shipperDocumentSuccess) {
      SuccessNotify('Document uploaded successfully.')
      clearShipperDocument()
      if (params?.id) {
        getSingleSalesEngine({ id: params.id })
        getShipperDocument({ id: params.id })
      }
    }

    return null
  }

  componentDidMount() {
    const {
      getSingleSalesEngine,
      match: { params },
      getShipperDocument,
      salesEnginPaymentInfo,
    } = this.props

    if (params?.id) {
      getSingleSalesEngine({ id: params.id })
      getShipperDocument({ id: params.id })
      salesEnginPaymentInfo({
        salesEngineId: params.id,
        transactionType: 3,
      })
    }
  }

  submitDocumentHandler = values => {
    const {
      currentUser,
      addShipperDocuments,
      match: { params },
      updateShipperDocument,
    } = this.props
    let submitValues = values
    submitValues.shipper = currentUser.id
    submitValues.salesEngine = params.id

    if (values?.id) {
      updateShipperDocument(submitValues)
    } else {
      addShipperDocuments(submitValues)
    }
  }

  render() {
    const {
      salesEngine,
      history,
      shipperDocument,
      getDocumentUrl,
      submitDocument,
      shipperDocumentLoading,
      currentUser,
      payments,
    } = this.props
    const { shipmentDocument } = this.state
    const { paymentInformation } = salesEngine || {}
    const {
      commonAddress: { address1: boatAddress, address2: boatAddress2, geometricLocation = null, city, country },
    } = getAddress(salesEngine?.boat?.address)

    const payment = payments && payments[0]

    return (
      <>
        <DashboardLayout className="sales-engin-layout-design ">
          {shipperDocumentLoading ? (
            <Loader isPageLoader />
          ) : (
            <div className="shipment--document-main-section">
              {/* <div className="d-flex justify-content-between align-items-center mb-5">
                <div>
                  <CommonSalesEnginTitle title="Boat Shipment Details" isReport />
                  <p className="shipment--document-sub-title mb-0">Enter Details regarding the Boat Shipment.</p>
                </div>
              </div> */}
              <div>
                <Row>
                  <Col xs={4} className="d-flex flex-column">
                    <CommonSalesEnginTitle title="Upload Documents" className="mb-2" isReport />
                    <div className="shipment-document-upload-section shipment--document--info--section">
                      <div className="shipment--uploaded--document--title--div">
                        <span className="shipment--uploaded--document--title">Uploaded Documents</span>
                      </div>
                      <Formik
                        initialValues={
                          shipperDocument?.id
                            ? {
                                ...shipperDocument,
                                addMedia: {
                                  billOfLoading: [],
                                  certificateOfOrigin: [],
                                  insuranceCertificate: [],
                                  receivedForShipment: [],
                                  invoiceOfShipmentPayment: [],
                                  otherDocument: [],
                                },
                                removeMedia: [],
                              }
                            : shipmentDocument
                        }
                        onSubmit={values => {
                          const {
                            billOfLoading,
                            certificateOfOrigin,
                            insuranceCertificate,
                            receivedForShipment,
                            invoiceOfShipmentPayment,
                            otherDocument,
                            __typename,
                            ...submitValues
                          } = values
                          submitValues.addMedia = getAddMedia(submitValues.addMedia)
                          this.submitDocumentHandler(submitValues)
                        }}
                        validationSchema={Yup.object().shape({
                          billOfLoading: Yup.string().required(requireMessage('Bill of lading')),
                          certificateOfOrigin: Yup.string().required(requireMessage('Upload certificate of origin')),
                          insuranceCertificate: Yup.string().required(requireMessage('Upload insurance certificate')),
                          receivedForShipment: Yup.string().required(requireMessage('Upload received for shipment')),
                          invoiceOfShipmentPayment: Yup.string().required(requireMessage('Upload invoice of shipment payment')),
                        })}
                        render={({ values, setFieldValue, handleSubmit }) => (
                          <Form className="shipment-image-popup-width shipment--document--section">
                            <ErrorFocus />
                            <div className="shipment--document--list--div">
                              <Field
                                label="Bill Of Lading"
                                name="billOfLoading"
                                type="shipper-document-tag"
                                value={values.billOfLoading}
                                addMedia={values.addMedia}
                                deleteIds={values.removeMedia}
                                className="form-control"
                                onChangeText={setFieldValue}
                                mediaName={MEDIA_NAME.BILLOFLOADING}
                                getDocumentUrl={getDocumentUrl}
                                required
                              />
                              <ErrorMessage component="div" name="billOfLoading" className="error-message" />
                            </div>
                            <div className="shipment--document--list--div">
                              <Field
                                label="Upload Certificate Of Origin"
                                name="certificateOfOrigin"
                                type="shipper-document-tag"
                                value={values.certificateOfOrigin}
                                addMedia={values.addMedia}
                                deleteIds={values.removeMedia}
                                className="form-control"
                                mediaName={MEDIA_NAME.CERTIFICATEOFORIGIN}
                                onChangeText={setFieldValue}
                                getDocumentUrl={getDocumentUrl}
                                required
                              />
                              <ErrorMessage component="div" name="certificateOfOrigin" className="error-message" />
                            </div>
                            <div className="shipment--document--list--div">
                              <Field
                                label="Upload Insurance Certificate"
                                name="insuranceCertificate"
                                type="shipper-document-tag"
                                value={values.insuranceCertificate}
                                mediaName={MEDIA_NAME.INSURANCECERTIFICATE}
                                addMedia={values.addMedia}
                                deleteIds={values.removeMedia}
                                className="form-control"
                                onChangeText={setFieldValue}
                                getDocumentUrl={getDocumentUrl}
                                required
                              />
                              <ErrorMessage component="div" name="insuranceCertificate" className="error-message" />
                            </div>
                            <div className="shipment--document--list--div">
                              <Field
                                label="Upload Received For Shipment"
                                name="receivedForShipment"
                                type="shipper-document-tag"
                                value={values.receivedForShipment}
                                mediaName={MEDIA_NAME.RECEIVEDFROMSHIPMENT}
                                addMedia={values.addMedia}
                                deleteIds={values.removeMedia}
                                className="form-control"
                                getDocumentUrl={getDocumentUrl}
                                onChangeText={setFieldValue}
                                required
                              />
                              <ErrorMessage component="div" name="receivedForShipment" className="error-message" />
                            </div>
                            <div className="shipment--document--list--div">
                              <Field
                                label="Upload Invoice Of Shipment Payment"
                                name="invoiceOfShipmentPayment"
                                type="shipper-document-tag"
                                addMedia={values.addMedia}
                                deleteIds={values.removeMedia}
                                mediaName={MEDIA_NAME.INVOICEOFSHIPMENTPAYMENT}
                                value={values.invoiceOfShipmentPayment}
                                className="form-control"
                                onChangeText={setFieldValue}
                                getDocumentUrl={getDocumentUrl}
                                required
                              />
                              <ErrorMessage component="div" name="invoiceOfShipmentPayment" className="error-message" />
                            </div>
                            <div className="shipment--document--list--div">
                              <Field
                                label="Upload Other Document If Any"
                                name="otherDocument"
                                type="shipper-document-tag"
                                value={values.otherDocument}
                                className="form-control"
                                mediaName={MEDIA_NAME.SHIPMENTDOCUMENTOTHERDOCUMENT}
                                getDocumentUrl={getDocumentUrl}
                                addMedia={values.addMedia}
                                deleteIds={values.removeMedia}
                                onChangeText={setFieldValue}
                              />
                            </div>
                            <div className="d-flex justify-content-center w-100 shipper--btn mt-4">
                              <ButtonComponent type="submit" className="buy--it--now">
                                {shipperDocument?.id ? 'Update Documents' : 'Send to Buyer'}
                              </ButtonComponent>
                            </div>
                          </Form>
                        )}
                      />

                      {/* // TODO design changes on above form */}
                      {/*
                                        <div className="d-flex flex-wrap">
                                            <span className="shipment--document--list" onClick={() => getDocumentUrl(shipperDocument?.billOfLoading?.id)}>Bill of Lading</span>
                                            <span className="shipment--document--list" onClick={() => getDocumentUrl(shipperDocument?.certificateOfOrigin?.id)}>Certificate of Origin</span>
                                            <span className="shipment--document--list" onClick={() => getDocumentUrl(shipperDocument?.insuranceCertificate?.id)}>Insurance Certificate</span>
                                            <span className="shipment--document--list" onClick={() => getDocumentUrl(shipperDocument?.receivedForShipment?.id)}>Received for Shipment</span>
                                            <span className="shipment--document--list" onClick={() => getDocumentUrl(shipperDocument?.otherDocument?.id)}>Shipment Other Document</span>
                                            <span className="shipment--document--list" onClick={() => getDocumentUrl(shipperDocument?.invoiceOfShipmentPayment?.id)}>Invoice of shipment payment </span>
                                        </div> */}
                    </div>
                  </Col>
                  <Col xs={4} className="d-flex flex-column">
                    <CommonSalesEnginTitle title="Address" className="mb-2" isReport />
                    <div className="shipment-document-address-section shipment--document--info--section">
                      <div className="shipment--address--from--title">
                        <span className="adamsea-text-color">Pickup From</span>
                      </div>
                      <div>
                        <div>
                          <div>
                            <span className="shipment--from--to--title--text">Address</span>
                          </div>
                          <div>
                            <span className="shipment--from--to--info--text">{boatAddress || boatAddress2}</span>
                          </div>
                        </div>
                        <div className="mt-3 mb-0">
                          <Row>
                            <Col xs={6}>
                              <div>
                                <div>
                                  <span className="shipment--from--to--title--text">City</span>
                                </div>
                                <div>
                                  <span className="shipment--from--to--info--text">{city}</span>
                                </div>
                              </div>
                            </Col>
                            <Col xs={6}>
                              <div>
                                <div>
                                  <span className="shipment--from--to--title--text">Country</span>
                                </div>
                                <div>
                                  <span className="shipment--from--to--info--text">{country}</span>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </div>
                    <div className="shipment-document-address-section shipment--document--info--section mt-4">
                      <div className="shipment--address--from--title">
                        <span className="adamsea-text-color shipment--document--font">Delivery To</span>
                      </div>
                      <div>
                        <div>
                          <div>
                            <span className="shipment--from--to--title--text shipment--document--font">Address</span>
                          </div>
                          <div>
                            <span className="shipment--from--to--info--text shipment--document--font">
                              {salesEngine?.shipmentLocation?.address}
                            </span>
                          </div>
                        </div>
                        <div className="mt-3 mb-0">
                          <Row>
                            <Col xs={6}>
                              <div>
                                <div>
                                  <span className="shipment--from--to--title--text shipment--document--font">City</span>
                                </div>
                                <div>
                                  <span className="shipment--from--to--info--text shipment--document--font">
                                    {salesEngine?.shipmentLocation?.city}
                                  </span>
                                </div>
                              </div>
                            </Col>
                            <Col xs={6}>
                              <div>
                                <div>
                                  <span className="shipment--from--to--title--text shipment--document--font">Country</span>
                                </div>
                                <div>
                                  <span className="shipment--from--to--info--text shipment--document--font">
                                    {salesEngine?.shipmentLocation?.country}
                                  </span>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col xs={4} className="d-flex flex-column">
                    <CommonSalesEnginTitle title="Payment Details" className="mb-2" isReport />
                    <div className="shipment-document-payment-detail-section shipment--document--info--section d-flex justify-content-center">
                      <div>
                        <div className="mb-3">
                          <div>
                            <span className="shipment--from--to--title--text shipment--document--font">Payment Status</span>
                          </div>
                          <div>
                            <span className="shipment--from--to--info--text shipment--document--succeeded--text shipment--document--font">
                              Succeeded
                            </span>
                          </div>
                        </div>
                        {/* <div className="mb-3">
                          <div>
                            <span className="shipment--from--to--title--text shipment--document--font">Transaction ID</span>
                          </div>
                          <div>
                            <span className="shipment--from--to--info--text shipment--document--font">{payment?.paymentId}</span>
                          </div>
                        </div> */}
                        <div className="mb-3">
                          <div>
                            <span className="shipment--from--to--title--text shipment--document--font">Transaction by</span>
                          </div>
                          <div>
                            <span className="shipment--from--to--info--text shipment--document--font">
                              {salesEngine?.buyer?.firstName} {salesEngine?.buyer?.lastName}
                            </span>
                          </div>
                        </div>
                        <div className="mb-3">
                          <div>
                            <span className="shipment--from--to--title--text shipment--document--font">Transaction Date</span>
                          </div>
                          <div>
                            <span className="shipment--from--to--info--text shipment--document--font">
                              {formattedDate(salesEngine?.shipmentPaymentDate)}
                            </span>
                          </div>
                        </div>
                        <div className="mb-3">
                          <div>
                            <span className="shipment--from--to--title--text shipment--document--font">Transaction Type</span>
                          </div>
                          <div>
                            <span className="shipment--from--to--info--text shipment--document--font">Bank Transfer</span>
                          </div>
                        </div>
                        <div>
                          <div>
                            <span className="shipment--from--to--title--text shipment--document--font">Total Amount</span>
                          </div>
                          <div>
                            <CurrencyContextConsumer>
                              {({ currentCurrency }) => (
                                <span className="shipment--from--to--info--text shipment--document--amount shipment--document--font">
                                  {getConvertedPrice(payment?.amount, currentCurrency)}
                                </span>
                              )}
                            </CurrencyContextConsumer>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>

              <div className="shipment--agent--info--section">
                {salesEngine && (
                  <AgentInfoCard
                    agent={salesEngine.agent}
                    className={'assign-single-list'}
                    isSingle={false}
                    history={history}
                    salesEngine={salesEngine}
                    isShipper
                  />
                )}
              </div>
              <div className="d-flex">
                <Row>
                  {salesEngine?.id && <BoatInformation xs={8} salesEngine={salesEngine} />}
                  {salesEngine?.id && <SellerInformation xs={4} salesEngine={salesEngine} isBuyer={true} history={history} />}
                </Row>
              </div>
            </div>
          )}
        </DashboardLayout>
      </>
    )
  }
}

const mapStateToProps = state => ({
  salesEngine: state.salesEngineReducer?.salesEngine,
  getSalesEngineSuccess: state.salesEngineReducer?.getSalesEngineSuccess,
  isLoading: state.salesEngineReducer?.isLoading,
  currentUser: state.loginReducer?.currentUser,
  shipperDocument: state?.salesEngineReducer?.shipperDocument,
  shipperDocumentLoading: state?.salesEngineReducer?.shipperDocumentLoading,
  shipperDocumentSuccess: state.salesEngineReducer.shipperDocumentSuccess,
  payments: state.salesEngineReducer?.payments,
})

const mapDispatchToProps = dispatch => ({
  getSingleSalesEngine: data => dispatch(getSingleSalesEngine(data)),
  getShipperDocument: data => dispatch(getShipperDocument(data)),
  getDocumentUrl: data => dispatch(getDocumentUrl(data)),
  addShipperDocuments: data => dispatch(addShipperDocuments(data)),
  updateShipperDocument: data => dispatch(updateShipperDocument(data)),
  clearShipperDocument: () => dispatch(clearShipperDocument()),
  salesEnginPaymentInfo: data => dispatch(salesEnginPaymentInfo(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ShipmentDocument)
