import React from 'react'
import { defaultProfileIcon } from '../../util/enums/enums'
import { getConvertedPrice } from '../../helpers/currencyConverterHelper'
import { CurrencyContextConsumer } from '../../CurrencyContext'
import { getAddress, cityCountryNameFormatter, redirectToUserProfile } from '../../helpers/jsxHelper'

export const ShipperRequestList = props => {
  const { userInfo, price, salesEngine, shipperAcceptRequest, proposalDocument, distance, getDocumentUrl, history } = props

  const { city, country } = salesEngine?.shipmentLocation
  return (
    <div className={`shipper--request--list`}>
      <div className="shipper--request--image">
        <img
          className="rounded-circle"
          src={userInfo.image?.url || defaultProfileIcon}
          alt="profile icon"
          onClick={() => redirectToUserProfile(userInfo, history)}
        />
      </div>
      <div className="shipper--list--name">
        {userInfo.firstName} {userInfo.lastName}
      </div>
      <div className="shipper--list--name--company-name">{userInfo.companyName}</div>
      <div className="shipper--list--name--city">{cityCountryNameFormatter(city, country)}</div>

      {userInfo && (
        <div className="shipper-list-company-cost text-center">
          <CurrencyContextConsumer>
            {({ currentCurrency }) => (
              <>
                <span className="text-nowrap" style={{ fontSize: '1.1em' }}>
                  Shipment Cost
                </span>{' '}
                <span className="text-nowrap" style={{ fontSize: '1.1em' }}>
                  {price && getConvertedPrice(price, currentCurrency)}
                </span>
              </>
            )}
          </CurrencyContextConsumer>
        </div>
      )}
      <div className="shipper--cost--with--distance text-center d-flex flex-column">
        <div className="mt-1 from--to--city--info">
          From:{' '}
          <span>
            {salesEngine && salesEngine.boat && salesEngine.boat.address.length > 0 && salesEngine.boat.address[0].city},{' '}
            {salesEngine && salesEngine.boat && salesEngine.boat.address.length > 0 && salesEngine.boat.address[0].country}
          </span>
        </div>
        <div className="mt-1 from--to--city--info">
          To:{' '}
          <span>
            {city}, {country}
          </span>
        </div>
      </div>
      {distance && (
        <div className="shipper--distance--value text-center">
          Shipper to boat distance:{' '}
          <span className="distance--km">
            {String(distance)
              .replace(/[^0-9]/g, '')
              .toLocaleString('en-US') + 'km'}
          </span>
        </div>
      )}
      <div className="shipper--list--cost-btn">
        {proposalDocument && (
          <button
            type="button"
            className={`w-auto btn btn-sales-engine-secondary mb-3 proposal--btn`}
            onClick={() => getDocumentUrl(proposalDocument?.id)}
          >
            {'Download Proposal'}
            <img
              src={require('../../assets/images/Arrow/download-arrow.svg')}
              alt="download icon"
              className="main--download--icon"
              height="15px"
            />
            <img
              src={require('../../assets/images/Arrow/white-arrow.svg')}
              alt="download icon"
              className="hover--download--icon"
              height="15px"
            />
          </button>
        )}
        <button
          type="button"
          className={`w-auto btn btn-sales-engine-secondary m-auto select--btn`}
          onClick={() => shipperAcceptRequest({ shipperId: userInfo.id, id: salesEngine.id })}
        >
          {'Select'}
        </button>
      </div>
    </div>
  )
}
