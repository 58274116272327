import React, { Component, Fragment } from 'react'
import { Grid, Box } from '@material-ui/core'
import { connect } from 'react-redux'

import {
  getTypeWiseLookup,
  GetRecentlyAddedMarinaAndStorage,
  getMostViewedMarinaStorage,
  searchMarinaStorage,
  getMoreServices,
  getTopRatedMarinaAndStorage,
  getExperiences,
  notifyMe,
} from '../../redux/actions'
import { shareDetailsWithUsers } from '../../redux/actions/shareAction'
import { getCategoryWiseAdvertisements } from '../../redux/actions/advertisementAction'
import { getModuleWiseBanners } from '../../redux/actions'
import { getCategoryWiseVideos } from '../../redux/actions/VideoAction'

import { Layout } from '../../components/layout/layout'
import RegisterCard from '../../components/staticComponent/registerCard'
import ReviewCards from '../../components/gridComponents/reviewCards'
import { RecommendedMarinaStorages } from '../../components/gridComponents/recommendedMarinaStorages'
import {
  pagination,
  moduleCategory,
  dimension,
  mediaCategory,
  advertisementCategory,
  showAllMarina,
  shareModule,
  responsiveCarousel,
} from '../../util/enums/enums'
import { MarinaStorageSearchCard } from '../../components/marinaStorage/marinaStorageSearchCard'
import ServicesMarinaStorage from '../../components/staticComponent/servicesMarinaStorage'
import MarinaMoreServices from './marinaMoreServices'
import TopRatedMarina from '../../components/gridComponents/topRatedMarina'
import { VideoModel } from '../../components/videoComponent/videoModel'
import { VideoComponent } from '../../components/videoComponent/videoComponent'

import { getHeightRatio, getRatio } from '../../helpers/ratio'
import { objectToQuerystring } from '../../helpers/routeHelper'
import { nameFormatter } from '../../helpers/string'
import { getLocalStorageItem } from '../../helpers/storageHelper'

import './marinaStorage.scss'
import '../../styles/marinaResponsive.scss'
import { clear_getStaticPages, getStaticPages } from '../../redux/actions/pageInfoByTypeAction'
import { get } from 'lodash'

class MarinaStorage extends Component {
  state = {
    setVideoFlag: false,
    videoUrl: '',
    videoThumbnail: '',
    videoWidth: dimension.marinaStorageVideo.width,
    videoHeight: dimension.marinaStorageVideo.height,

    queryResult: '',
  }

  searchMarinaStorageHandler = values => {
    const { searchMarinaStorage } = this.props

    this.setState({ queryResult: objectToQuerystring(values) })
    const { serviceProviderAlias, providerAlias, ...submitValue } = values
    searchMarinaStorage(submitValue)
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { isMarinaStorageSearched, history } = nextProps
    const { queryResult } = prevState

    if (isMarinaStorageSearched) {
      history.push(`/search-marina-storage${queryResult && queryResult}`)
    }
    return null
  }
  videoWidthZoom = () => {
    const videoWidth = getRatio(dimension, 'marinaStorageVideo', '.section-heading')

    const videoHeight = getHeightRatio(dimension, 'marinaStorageVideo', '.section-heading')
    this.setState({ videoWidth, videoHeight })
  }
  async componentDidMount() {
    const {
      getTypeWiseLookup,
      GetRecentlyAddedMarinaAndStorage,
      getCategoryWiseVideos,
      getMostViewedMarinaStorage,
      getMoreServices,
      getTopRatedMarinaAndStorage,
      getModuleWiseBanners,
      getCategoryWiseAdvertisements,
      getExperiences,
    } = this.props

    getCategoryWiseVideos({ type: moduleCategory.MARINA_STORAGE, metatype: 'video' })
    this.videoWidthZoom()
    window.addEventListener('resize', this.videoWidthZoom)

    await getTypeWiseLookup('You are An')
    const data = {
      page: pagination.PAGE_COUNT,
      limit: pagination.MARKET_PAGE_LIMIT,
    }

    await GetRecentlyAddedMarinaAndStorage(data)
    await getMostViewedMarinaStorage(data)
    getExperiences('marina')
    getTopRatedMarinaAndStorage(data)
    getCategoryWiseAdvertisements(advertisementCategory.MARINA_STORAGE)
    getMoreServices({ isAdmin: false })
    getModuleWiseBanners({
      type: mediaCategory.marinaStorage.type,
      fieldName: mediaCategory.marinaStorage.fieldName,
      isBanner: true,
    })

    this.props.getStaticPages({ slug: 'marina-homepage-bottom-474' })
  }
  componentDidUpdate() {
    if (this.props.staticPages?.success || this.props.staticPages?.failure) {
      this.props.clear_getStaticPages()
    }
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.videoWidthZoom)
  }
  playVideo = (url, thumbnail) => {
    this.setState(prevState => ({
      setVideoFlag: !prevState.setVideoFlag,
      videoUrl: url,
      videoThumbnail: thumbnail,
    }))
  }

  viewMarinaStorage = alias => {
    const { history } = this.props
    history.push('/marina-storage-view', {
      alias: alias,
    })
  }

  closeVideo = () => {
    this.setState({ setVideoFlag: false })
  }

  render() {
    const { country } = this.context
    const {
      recentMarinaStorage,
      categoryVideos,
      mostViewedMarinaStorage,
      marinaTypeWiseLookUps,
      advertisements,
      moreMarinaService,
      topRatedMarinaStorage,
      topRatedMarinaTotal,
      history,
      marinaStorageBanner,
      mostViewedMarinaStorageTotal,
      experience,
      notifyMe,
      notifyInput,
      totalRecentMarinaCount,
    } = this.props
    const { setVideoFlag, videoUrl, videoWidth, videoHeight, videoThumbnail } = this.state

    return (
      <Fragment>
        <Layout isHeader="marina-storage-header-logo" className="marina-storage-layout">
          {marinaStorageBanner?.length > 0 && (
            <MarinaStorageSearchCard
              marinaBanner={marinaStorageBanner}
              types={marinaTypeWiseLookUps}
              services={moreMarinaService}
              search={this.searchMarinaStorageHandler}
              notifyMe={notifyMe}
              notifyInput={notifyInput}
            />
          )}

          <div className="container100 marinaStorageMainDiv">
            <Grid container>
              {/* <Grid item xs={12}>
                <h1 className="r-h1 mb-0">
                  <Box className="section-heading" fontSize={24} fontWeight={500} letterSpacing={0.5}>
                    Explore AdamSea
                  </Box>
                </h1>
                <ServicesMarinaStorage type="marinaStorage" className="mb-20" />
              </Grid> */}

              {moreMarinaService && moreMarinaService.length > 0 && (
                <Grid item xs={12}>
                  <Box className="section-heading" fontSize={24} fontWeight={500} letterSpacing={0.5}>
                    More services
                  </Box>
                  <MarinaMoreServices moreServices={moreMarinaService} limit={18} />
                </Grid>
              )}
              {advertisements && advertisements.length > 0 && (
                <Grid item xs={12} className="registerCardCont">
                  <Grid container>
                    <Grid item xs={12} className='adam-card-banner'>
                      <div>
                        <RegisterCard  adData={advertisements[0]} history={history} />
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              )}
              {recentMarinaStorage && recentMarinaStorage.length > 0 && (
                <Grid item xs={12} className="recommended-marina-div section-grid">
                  <Box className="section-heading" fontSize={24} fontWeight={500} letterSpacing={0.5}>
                    {`Marinas and Storages recommendations in ${nameFormatter([getLocalStorageItem('country')])}`}
                  </Box>
                  <RecommendedMarinaStorages
                    data={recentMarinaStorage}
                    itemsLength={8}
                    total={totalRecentMarinaCount}
                    dimensionKey="marinaMostPopular"
                    url="/show-all-marina-and-storage/recommended"
                    showAllText={`Marinas and Storages recommendations in ${nameFormatter([getLocalStorageItem('country')])}`}
                  />
                </Grid>
              )}

              {mostViewedMarinaStorage && mostViewedMarinaStorage.length > 0 && (
                <Grid item xs={12}>
                  <Box className="section-heading" fontSize={24} fontWeight={500} letterSpacing={0.5}>
                    Most popular marina facility
                  </Box>
                  <>
                    <RecommendedMarinaStorages
                      data={mostViewedMarinaStorage}
                      itemsLength={8}
                      total={mostViewedMarinaStorageTotal}
                      dimensionKey="marinaMostPopular"
                      url={`/show-all-marina-and-storage/${showAllMarina.mostPopular}`}
                      showAllText="most popular marina facility"
                    />
                  </>
                </Grid>
              )}

              {advertisements && advertisements.length > 1 && (
                <Grid item xs={12} className="registerCardCont">
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <div className="double-card">
                        <RegisterCard isSpace isDouble adData={advertisements[1]} />
                      </div>
                    </Grid>
                    {advertisements && advertisements.length > 2 && (
                      <Grid item xs={6}>
                        <div className="double-card">
                          <RegisterCard isSpace2 isDouble adData={advertisements[2]} />
                        </div>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              )}

              {topRatedMarinaTotal > 0 && (
                <Grid item xs={12}>
                  <Box className="section-heading" fontSize={24} fontWeight={500} letterSpacing={0.5}>
                    Top rated marinas and storages
                  </Box>
                  <TopRatedMarina
                    topRatedMarina={topRatedMarinaStorage}
                    iconColor="iconColor-marina"
                    showType={showAllMarina.topRated}
                    total={topRatedMarinaTotal}
                    limit={10}
                    country={country}
                    showAllText="top rated marina & storages"
                  />
                </Grid>
              )}

              {/* remove below commented code after discuss with @miraj [NOTE] }
              {/* <Grid item xs={12}>
                <Box
                  className="section-heading"
                  fontSize={24}
                  fontWeight={500}
                  letterSpacing={0.5}
                >
                  Reviews
                </Box>
                <ReviewCards
                  xs={12}
                  sm={6}
                  reviewCard={marinaReviews}
                  iconColor="iconColor-marina"
                />
              </Grid> */}
              {experience && experience.length > 0 && (
                <Grid item xs={12}>
                  <Box className="section-heading" fontSize={24} fontWeight={500} letterSpacing={0.5}>
                    Live the Experience with us
                  </Box>
                  <ReviewCards
                    xs={12}
                    sm={3}
                    experience={experience}
                    dimensionKey="experienceUs"
                    gutter={80}
                    responsiveNumber={responsiveCarousel.experienceUs}
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <Box className="section-heading hide-card-homepage" fontSize={24} fontWeight={500} letterSpacing={0.5}></Box>

                <Grid item xs={12}>
                  <Grid container spacing={2} className="video-component-grid-layout-marina-side video-gapping-space-common">
                    {categoryVideos &&
                      categoryVideos.length > 0 &&
                      categoryVideos.map((video, index) => {
                        if (index < 3) {
                          return (
                            <Fragment key={video.id}>
                              <VideoComponent
                                setVideoUrlHandler={(thum) => this.playVideo( video?.url?.[0]?.url, video.thumbnail?.length > 0 ? video?.thumbnail?.[0]?.url : thum )}
                                video={video}
                                videoWidth={videoWidth}
                                videoHeight={videoHeight}
                              />
                            </Fragment>
                          )
                        }
                      })}
                  </Grid>
                  <VideoModel
                    videoFlag={setVideoFlag}
                    videoUrl={videoUrl}
                    thumbnail={videoThumbnail}
                    closeVideo={this.closeVideo}
                  />
                </Grid>
              </Grid>
            </Grid>

            {get(this.props.staticPages, 'data[0].pageContent', '') && (
              <div style={{ marginTop: 30, padding: '16px 24px', borderRadius: 8, background: '#dff0f2' }}>
                <div className="d-flex">
                  <div style={{ flexShrink: 0 }}>
                    <img src={require('../../assets/images/MARINA.svg')} alt="bell" height={28} style={{ height: 28 }} />
                  </div>
                  <div style={{ margin: 0, marginLeft: 20, fontSize: 16 }}>
                    {get(this.props.staticPages, 'data[0].pageContent', '')}
                  </div>
                </div>
              </div>
            )}
          </div>
        </Layout>
      </Fragment>
    )
  }
}

// export default ;

const mapStateToProps = state => ({
  videos: state.dashboardReducer.videos,
  typeWiseLookup: state.marinaAndStorageReducer && state.marinaAndStorageReducer.marinaTypeWiseLookUps,
  user: state.loginReducer && state.loginReducer.currentUser,
  categoryVideos: state.videoReducer && state.videoReducer.categoryVideos,
  recentMarinaStorage: state.marinaAndStorageReducer && state.marinaAndStorageReducer.recentMarinaStorage,
  mostViewedMarinaStorage: state.marinaAndStorageReducer && state.marinaAndStorageReducer.mostViewedMarinaStorage,
  marinaTypeWiseLookUps: state.marinaAndStorageReducer.marinaTypeWiseLookUps,
  isMarinaStorageSearched: state.marinaAndStorageReducer.isMarinaStorageSearched,
  advertisements: state.dashboardReducer[mediaCategory.marinaStorage.adField],
  moreMarinaService: state.marinaAndStorageReducer && state.marinaAndStorageReducer.moreMarinaService,
  topRatedMarinaStorage: state.marinaAndStorageReducer && state.marinaAndStorageReducer.topRatedMarinaStorage,
  topRatedMarinaTotal: state.marinaAndStorageReducer && state.marinaAndStorageReducer.topRatedMarinaTotal,
  marinaStorageBanner: state.dashboardReducer && state.dashboardReducer[mediaCategory.marinaStorage.fieldName],
  mostViewedMarinaStorageTotal: state.marinaAndStorageReducer && state.marinaAndStorageReducer.mostViewedMarinaStorageTotal,
  experience: state.dashboardReducer.experience,
  notifyInput: state.dashboardReducer.notifyInput,
  totalRecentMarinaCount: state.marinaAndStorageReducer.totalRecentMarinaCount,
  staticPages: state.pageInfoByTypeReducer.staticPages,
})

const mapDispatchToProps = dispatch => ({
  getMoreServices: data => dispatch(getMoreServices(data)),
  getTypeWiseLookup: data => dispatch(getTypeWiseLookup(data)),
  GetRecentlyAddedMarinaAndStorage: data => dispatch(GetRecentlyAddedMarinaAndStorage(data)),
  getCategoryWiseVideos: data => dispatch(getCategoryWiseVideos(data)),
  getMostViewedMarinaStorage: data => dispatch(getMostViewedMarinaStorage(data)),
  searchMarinaStorage: data => dispatch(searchMarinaStorage(data)),
  getTopRatedMarinaAndStorage: data => dispatch(getTopRatedMarinaAndStorage(data)),
  getModuleWiseBanners: type => dispatch(getModuleWiseBanners(type)),
  getExperiences: data => dispatch(getExperiences(data)),
  notifyMe: data => dispatch(notifyMe(data)),
  shareDetailsWithUsers: data => dispatch(shareDetailsWithUsers(data)),
  getCategoryWiseAdvertisements: data => dispatch(getCategoryWiseAdvertisements(data)),
  getStaticPages: data => dispatch(getStaticPages(data)),
  clear_getStaticPages: () => dispatch(clear_getStaticPages()),
})

export default connect(mapStateToProps, mapDispatchToProps)(MarinaStorage)
