import { readableString, nameFormatter, lowerHypenCase, getSlug } from './string'
import { popUpMessage } from './confirmationPopup'
import { ftToMeterConversionValue, boatType } from '../util/enums/enums'
import { redirectRouteHandler } from './routeHelper'
import { getBoatTypeName } from './jsxHelper'
import { getId } from '../util/utilFunctions'

export const getSingleBoatMarker = (boat, isSalesEngineCard = false, isShipmentPickupCard = false) => {
  if (boat && boat.boatName && boat.address && boat.address.length) {
    const {
      id,
      rating,
      price,
      yearBuilt,
      lengthInFt,
      address: [{ country, city, geometricLocation, address1 }],
    } = boat
    const boatTypeAlias = getBoatTypeName(boat)
    const kayakCaoneType = boat?.boatType?.name === boatType.kayak
    const length = !kayakCaoneType && ` / ${lengthInFt} Ft`
    if (geometricLocation) {
      const marker = {
        id,
        image: boat?.images?.length > 0 && boat.images[0].url,
        country: country,
        city: city,
        rating: !isShipmentPickupCard ? rating : null,
        price: !isSalesEngineCard && !isShipmentPickupCard ? price : null,
        title: !isSalesEngineCard ? `${yearBuilt} ${boatTypeAlias} ${length ? length : ''}` : null,
        icon: {
          url: require('../assets/images/marker/map-home.svg'),
        },
        position: {
          lat: geometricLocation.coordinates[1],
          lng: geometricLocation.coordinates[0],
        },
      }
      return [marker]
    }
  }
}

export const getRentMarker = boat => {
  if (boat && boat.model && boat.address && boat.address.length) {
    const {
      id,
      rating,
      price,
      boatName,
      address: [{ country, city, geometricLocation }],
    } = boat

    if (geometricLocation) {
      const marker = {
        id,
        image: boat?.images?.length > 0 && boat.images[0].url,
        country: country,
        city: city,
        rating: rating,
        price: price,
        title: boatName,
        icon: {
          url: require('../assets/images/marker/map-rent.svg'),
        },
        position: {
          lat: geometricLocation.coordinates[1],
          lng: geometricLocation.coordinates[0],
        },
      }

      return [marker]
    }
  }
}

export const getSingleMarinaMarker = marina => {
  if (marina && marina.address && marina.address.length) {
    const {
      id,
      address: [{ country, city, geometricLocation }],
      rating,
      FOS,
    } = marina

    if (geometricLocation) {
      const marker = {
        id,
        image: marina?.images?.length > 0 && marina.images[0].url,
        country: country,
        city: city,
        rating: rating,
        title: FOS,
        icon: {
          url: require('../assets/images/marker/map-marina.svg'),
        },
        position: {
          lat: geometricLocation.coordinates[1],
          lng: geometricLocation.coordinates[0],
        },
      }
      return [marker]
    }
  }
}

export const getSingleServiceMarker = service => {
  if (service && service.address && service.address.length) {
    const {
      id,
      address: [{ country, city, geometricLocation }],
      rating,
      FOS,
    } = service

    if (geometricLocation) {
      const marker = {
        id,
        image: service?.images?.length > 0 && service.images[0].url,
        country: country,
        city: city,
        rating: rating,
        title: FOS,
        icon: {
          url: require('../assets/images/marker/map-service.svg'),
        },
        position: {
          lat: geometricLocation.coordinates[1],
          lng: geometricLocation.coordinates[0],
        },
      }
      return [marker]
    }
  }
}

export const getGatheringPointMarker = (address, companyName = '') => {
  const { country, city, geometricLocation } = address

  if (geometricLocation) {
    const marker = {
      title: companyName,
      image: null,
      country: country,
      city: city,
      icon: {
        url: require('../assets/images/marker/latest_place_icon.png'),
      },
      position: {
        lat: geometricLocation.coordinates[1],
        lng: geometricLocation.coordinates[0],
      },
    }
    return [marker]
  }
}

export const getSurveyorMarkersWithOffice = (branches, officeAddress, companyName = '') => {
  let branchesMarker, officeMarker
  if (branches && branches.length) {
    branchesMarker = branches.map(branch => {
      const {
        id,
        pricePerFt,
        contactName,
        position,
        branchName,
        address: [{ country, city, geometricLocation }],
      } = branch

      if (branch.address && branch.address.length && geometricLocation) {
        return {
          id,
          contactName: contactName,
          city: city,
          title: branchName,
          country: country,
          post: position?.alias,
          pricePerFt,
          icon: {
            url: require('../assets/images/marker/map-home.svg'),
          },
          position: {
            lat: geometricLocation.coordinates[1],
            lng: geometricLocation.coordinates[0],
          },
        }
      }
    })
  }
  if (officeAddress && officeAddress.address1) {
    const { country, city, geometricLocation } = officeAddress

    if (geometricLocation) {
      officeMarker = {
        title: companyName,
        city: city,
        country: country,
        icon: {
          url: require('../assets/images/marker/map-marina.svg'),
        },
        position: {
          lat: geometricLocation.coordinates[1],
          lng: geometricLocation.coordinates[0],
        },
      }
    }
  }

  return branchesMarker ? [...branchesMarker, officeMarker] : [officeMarker]
}

export const getBoatMarkers = boats => {
  if (boats && boats.length) {
    return boats.map(boat => {
      const {
        id,
        images,
        rating,
        price,
        yearBuilt,
        lengthInFt,
        address: [{ country, city, geometricLocation }],
      } = boat
      const boatTypeAlias = getBoatTypeName(boat)
      const kayakCaoneType = boat?.boatType?.name === boatType.kayak
      const length = !kayakCaoneType && ` / ${lengthInFt} Ft`

      if (boat.address && boat.address.length && boat.boatName && geometricLocation) {
        return {
          id,
          image: images && images.length > 0 && images[0]?.url,
          country: country,
          city: city,
          rating: rating,
          price: price,
          title: `${yearBuilt} ${boatTypeAlias} ${length ? length : ''} `,
          icon: {
            url: require('../assets/images/marker/map-home.svg'),
          },
          position: {
            lat: geometricLocation.coordinates[1],
            lng: geometricLocation.coordinates[0],
          },
          redirectUrl: viewBoatHandler(boat, true),
        }
      }
    })
  }
}

export const getRentShowMarkers = boats => {
  if (boats && boats.length) {
    return boats.map(boat => {
      const {
        id,
        images,
        rating,
        price,
        model,
        address: [{ country, city, geometricLocation }],
      } = boat

      if (geometricLocation && model) {
        return {
          id,
          image: images && images.length > 0 && images[0]?.url,
          country: country,
          city: city,
          rating: rating,
          price: price,
          title: model,
          icon: {
            url: require('../assets/images/marker/map-rent.svg'),
          },
          position: {
            lat: geometricLocation.coordinates[1],
            lng: geometricLocation.coordinates[0],
          },
          redirectUrl: viewRentBoatHandler(boat, true),
        }
      }
    })
  } else {
    return []
  }
}

export const getBoatShowMarker = boatShows => {
  if (boatShows && boatShows.length) {
    return boatShows.map(boatShow => {
      const {
        id,
        boatshowImages,
        title,
        address: [{ country, city, geometricLocation }],
      } = boatShow

      if (geometricLocation) {
        return {
          id,
          image: boatshowImages[0]?.url,
          country: country,
          city: city,
          title: title,
          icon: {
            url: require('../assets/images/marker/map-boatshow.svg'),
          },
          position: {
            lat: geometricLocation.coordinates[1],
            lng: geometricLocation.coordinates[0],
          },
        }
      }
    })
  }
}

export const getBoatServiceMarkers = boats => {
  if (boats && boats.length) {
    return boats.map(boat => {
      const {
        id,
        images,
        rating,
        service,
        serviceProvide,
        user,
        address: [{ country, city, geometricLocation }],
      } = boat

      if (geometricLocation) {
        return {
          id,
          image: images && images.length > 0 && images[0]?.url,
          country: country,
          city: city,
          rating: rating,
          title: serviceProvide?.length > 0 && serviceProvide[0].name,
          companyName: user?.companyName,
          description: service,
          icon: {
            url: require('../assets/images/marker/map-service.svg'),
          },
          position: {
            lat: geometricLocation.coordinates[1],
            lng: geometricLocation.coordinates[0],
          },
          redirectUrl: viewServiceBoatHandler(boat, true),
        }
      }
    })
  }
}

export const getMarinaStorageMarker = marinas => {
  if (marinas && marinas.length) {
    return marinas.map(marina => {
      const {
        id,
        images,
        provider,
        address: [{ country, city, geometricLocation }],
        rating,
        name,
      } = marina
      if (geometricLocation) {
        return {
          id,
          image: images && images.length > 0 && images[0]?.url,
          country: country,
          city: city,
          rating: rating,
          facilityName: name,
          icon: {
            url: require('../assets/images/marker/map-marina.svg'),
          },
          provider: provider,
          position: {
            lat: geometricLocation.coordinates[1],
            lng: geometricLocation.coordinates[0],
          },
          redirectUrl: viewMarinaDetails(marina, true),
        }
      }
    })
  }
}

export const getShipmentMarker = shipment => {
  if (shipment && shipment.address && shipment.address.length) {
    const {
      id,
      address: [{ country, city, geometricLocation }],
      rating,
      FOS,
    } = shipment

    if (geometricLocation) {
      const marker = {
        id,
        image: shipment?.images?.length > 0 && shipment.images[0].url,
        country: country,
        city: city,
        rating: rating,
        title: FOS,
        icon: {
          url: require('../assets/images/marker/map-marina.svg'),
        },
        position: {
          lat: geometricLocation.coordinates[1],
          lng: geometricLocation.coordinates[0],
        },
      }
      return [marker]
    }
  }
}

export const viewBoatHandler = (boat, getUrl = false, isComingFromSalesEngine = false, withOrigin = false) => {
  if (boat && boat.seller) {
    const { id, _original, yearBuilt, lengthInFt, boatType, manufacturedBy, engineHp, seller, address, _id } = boat
    const city = address?.find(a => a.type === 'COMMON_ADDRESS' || a.type === 'JET_SKI_ADDRESS')?.city;

    const manufacturedByAlias = manufacturedBy ? manufacturedBy.alias : seller?.companyName
    const userId = _id || id || _original.id
    const { role } = seller
    const seoName = nameFormatter([yearBuilt, boatType?.name, manufacturedByAlias, lengthInFt ? lengthInFt + ' ft' : '', city], ' ')

    if (userId) {
      const url = `${withOrigin ? location.origin : ''}/boat-inner/${role.aliasName}/${userId}/${readableString(seoName)}`

      if (getUrl) {
        return url
      } else {
        redirectRouteHandler(url)
      }
    }
  }
}

export const viewRentBoatHandler = (boat, getUrl = false, withOrigin = false) => {
  if (boat) {
    const { id, _id, boatLength, model, _original, name, trip, tripType, address } = boat
    const city = address?.find(a => a.type === 'COMMON_ADDRESS')?.city;
    const userId = id || _id || _original.id
    const slug = [boatLength ? `${boatLength} ft` : '', model, 'boat', trip?.alias, tripType?.alias, city].filter(Boolean).join('-')
    if (userId) {
      const url = `${withOrigin ? location.origin : ''}/rent-inner/${userId}/${getSlug(slug)}`

      if (getUrl) {
        return url
      } else {
        redirectRouteHandler(url)
      }
    }
  }
}

export const viewServiceBoatHandler = (value, getUrl = false, withOrigin = false) => {
  if (value) {
    const {
      serviceProvide,
      user: { companyName },
      address
    } = value
    const city = address?.find(a => a.type === 'COMMON_ADDRESS')?.city;

    const [first, ...rest] = serviceProvide
    const last = rest.pop()

    const seoName = nameFormatter([companyName, 'service',  first?.name, last?.name, city], '-')

    if (getId(value)) {
      const url = `${withOrigin ? location.origin : ''}/boat-service-inner/${getId(value)}/${lowerHypenCase(seoName)}`

      if (getUrl) {
        return url
      } else {
        redirectRouteHandler(url)
      }
    }
  }
}

export const viewMarinaDetails = (value, getUrl = false, withOrigin = false) => {
  if (value) {
    const {
      name,
      user: { companyName },
      provider: { alias },
      serviceProvide,
      address,
    } = value
    const city = address?.find(a => a.type === 'COMMON_ADDRESS')?.city;

    const [first, ...rest] = serviceProvide
    const last = rest.pop()

    const seoName = nameFormatter([name, alias, first?.name, city], '-')

    if (getId(value)) {
      const url = `${withOrigin ? location.origin : ''}/marina-storage-inner/${getId(value)}/${lowerHypenCase(seoName)}`

      if (getUrl) {
        return url
      } else {
        redirectRouteHandler(url)
      }
    }
  }
}

export const isBuyerEligible = (buyer, history) => {
  let isVerified = false

  if (buyer && buyer.id && buyer.role && buyer.role.aliasName) {
    isVerified = buyer.accountActivated

    !isVerified &&
      popUpMessage('You are either not verified or your account not activated', 'Alert', true, 'Go to My Profile', history)
  } else {
    history && history.push('/login', { callbackLink: history.location && history.location.pathname })
  }

  return isVerified
}

export const convertValueFromFtToMeter = (value, isValueInFt) => {
  return +value ? (isValueInFt ? `${value.toFixed(2)} ft` : `${(value * ftToMeterConversionValue).toFixed(2)} m`) : ''
}

export const viewArticles = (id, title = '', getUrl = false, withOrigin = false) => {
  const url = `${withOrigin ? location.origin : ''}/article${title ? '/' + title.trim().toLowerCase().replace(/[^a-z]+/g, '-') : ''}/${id}`
  if (getUrl) return url
  redirectRouteHandler(url)
}

export const profileRedirection = history => {
  history && history.push('/profile')
}

export const googleDirectionUrl = (geometricLocation = null) => {
  if (geometricLocation && geometricLocation?.coordinates?.length) {
    const latitude = geometricLocation?.coordinates[1]
    const longitude = geometricLocation?.coordinates[0]
    return `https://www.google.com/maps/dir/?api=1&origin=&destination=${latitude},${longitude}&language=en`
  } else {
    return ''
  }

  return
}

export const getGridCountForMap = (mechanicalSystemLength = 0, electricalSystemLength = 0) => {
  return !mechanicalSystemLength && !electricalSystemLength ? 12 : 4
}
export const getGridCountForMapElement = (mechanicalSystemLength = 0, electricalSystemLength = 0) => {
  return !mechanicalSystemLength && !electricalSystemLength
    ? 12
    : (!mechanicalSystemLength && electricalSystemLength) || (mechanicalSystemLength && !electricalSystemLength)
    ? 8
    : 4
}
