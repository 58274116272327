import React, { Component } from 'react'
import { connect } from 'react-redux'

import { ProfileComponent, Loader } from '../../components'
import {
  updateUser,
  updateStripeProfileCompleted,
  getTypeWiseLookup,
  clearErrorMessageShow,
  getDocumentUrl,
  clearDashboardUnauthorizedAccessFlag,
  getAllPaymentTypes,
  handleStripeAccount,
  clearStripeAccountLink,
  emailVerificationCheck,
  isVisitFirstTimeForEmail,
  closeEmailVerificationModal,
  sendConfirmationLink,
} from '../../redux/actions'
import { SuccessNotify, ErrorNotify } from '../../helpers/notification'

import './profile.scss'
import { lookupTypes, latLng, activationType } from '../../util/enums/enums'
import { DashboardLayout } from '../../components/layout/dashboardLayout'
import { getIds, renderSelectOptions } from '../../helpers/string'
import { popUpMessage } from '../../helpers/confirmationPopup'
import { getAddress } from '../../helpers/jsxHelper'
import { CommonModal } from '../../components/modal/commonModal'
import { ButtonComponent } from '../../components/form/Button'
import { isValidMobileNumber } from '../../helpers/validationHelper'
import { getAddMedia } from '../../helpers/s3FileUpload'
import { getLocalInfo } from '../../helpers/storageHelper'
import EmailVerificationDialog from '../../components/dialog/EmailVerificationDialog'
import ConfirmationEmail from '../../components/modal/confirmationEmail'

class Profile extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showStripeSuccessPopup: false,
    }
  }
  componentDidMount() {
    const { getTypeWiseLookup, getAllPaymentTypes } = this.props

    getAllPaymentTypes()
    getTypeWiseLookup(lookupTypes.CONTACT_PREFERENCE)
    getTypeWiseLookup(lookupTypes.PROFESSION)
    getTypeWiseLookup(lookupTypes.DOCUMENT_TYPES)
  }

  componentDidUpdate(prevProps) {
    const { emailVerificationCheck, emailVerificationPopup, clearDashboardUnauthorizedAccessFlag } = prevProps
    const { unauthorizedAccess, unauthorizedAccessMessage } = this.props

    if (unauthorizedAccess) {
      clearDashboardUnauthorizedAccessFlag()
      SuccessNotify(unauthorizedAccessMessage)
    }

    const documentVerification = getLocalInfo('documentStatus')

    documentVerification && !emailVerificationPopup && emailVerificationCheck(documentVerification?.emailVerified)
  }

  static getDerivedStateFromProps(nextProps) {
    const { isUpdate, isUpdateError, errorMessage, clearErrorMessageShow, stripProfileLink } = nextProps
    if (stripProfileLink) {
      window && window.open(`${stripProfileLink}`, '_blank')
      nextProps.clearStripeAccountLink()
    }
    if (isUpdate) {
      setTimeout(() => SuccessNotify('Profile updated'), 100)
      nextProps.history.push('/dashboard')
    } else if (isUpdateError && errorMessage) {
      ErrorNotify(errorMessage[0].message)
      clearErrorMessageShow()
    }
    return null
  }

  // componentWillUnmount() {
  //   const { isVisitFirstTimeForEmail } = this.props
  //   isVisitFirstTimeForEmail()
  // }

  cancelHandler = () => {
    const { history } = this.props
    history.push('/dashboard')
  }

  closeEmailModal = () => {
    const { closeEmailVerificationModal } = this.props
    closeEmailVerificationModal()
  }

  sendEmail = () => {
    this.closeEmailModal()
    this.modalHandler()
  }

  modalHandler = () => {
    this.setState(preState => ({ openDialog: !preState.openDialog }))
  }

  render() {
    const {
      currentUser,
      updateUser,
      preferenceTypes,
      professionTypes,
      documentTypes,
      countryCode,
      message,
      getDocumentUrl,
      paymentTypes,
      handleStripeAccount,
      clearStripeAccountLink,
      updateStripeProfileCompleted,
      history,
      emailVerificationPopup, 
      isVisitFirstTime
    } = this.props

    const {
      id,
      firstName,
      lastName,
      address,
      mobileNumber,
      email,
      companyName,
      companyWebsite,
      preference,
      title,
      language,
      companyLogo,
      image,
      commercialLicence,
      governmentId,
      provider,
      shortDescription,
      profession,
      paymentMethodTypes,
      pricePerFt,
      is_stripe_profile_completed,
    } = currentUser

    const { commonAddress, officeAddress } = getAddress(address)

    const initValue = {
      id: id,
      firstName: firstName,
      lastName: lastName || '',
      shortDescription: shortDescription || '',
      profession: profession ? { label: profession.alias, value: profession.id } : '',
      provider: (provider && provider.alias) || '',
      geometricLocation:
        address && address.length
          ? address[0].geometricLocation && address[0].geometricLocation.coordinates
            ? address[0].geometricLocation.coordinates
            : []
          : [],
      city: (commonAddress && commonAddress.city) || '',
      state: (commonAddress && commonAddress.state) || '',
      country: commonAddress && commonAddress.country,
      address1: (commonAddress && commonAddress.address1) || '',
      address2: (commonAddress && commonAddress.address2) || '',
      street: (commonAddress && commonAddress.street) || '',
      zip: (commonAddress && commonAddress.zip) || '',

      mobileNumber: mobileNumber || '',
      email: email,
      companyName: companyName || '',
      companyWebsite: companyWebsite || '',
      companyLogo: companyLogo?.url ? companyLogo : '',
      title: title || '',
      language: language && language.length ? language.map(item => ({ label: item, value: item })) : [],
      preference: renderSelectOptions(preference, 'alias', 'id') || [],
      image: image?.url ? image : '',
      officeAddress: officeAddress.address1 ? [officeAddress] : [],
      governmentId: governmentId || [],
      commercialLicence: commercialLicence || [],
      addMedia: {
        image: [],
        companyLogo: [],
        governmentId: [],
        commercialLicence: [],
      },
      removeMedia: [],
      paymentMethodTypes: paymentMethodTypes?.length
        ? paymentMethodTypes.map(item => ({ label: item.name, value: item.id }))
        : [],
      pricePerFt: pricePerFt || '',
      is_stripe_profile_completed: currentUser.is_stripe_profile_completed,
    }

    const helpModalHandler = () => {
      this.setState({ showStripeSuccessPopup: false })
      clearStripeAccountLink()
      setTimeout(() => SuccessNotify('Profile updated'), 100)
      history.push('/dashboard')
    }

    const profileStripe = history.location.state
    let userId = currentUser?.id
    if (userId && profileStripe?.stripe === 'return') {
      // this.setState({ showStripeSuccessPopup: true })
      updateStripeProfileCompleted(userId)
      history.replace({ ...history.location, state: null });
    }

    return (
      <DashboardLayout className="profile-dashboard-layout">
        {this.state.showStripeSuccessPopup && (
          <CommonModal
            className="sales-engine-header-help-dialog dashboard--table--help--section"
            open={this.state.showStripeSuccessPopup}
            close={helpModalHandler}
            // title="Help"
          >
            <div className="text-center">
              {/* <img loading="lazy" src="../../components/userProfile/image/vector.png" /> */}
              <p className="mb-3 dashboard--table--help--desc">Stripe profile updated successfully.</p>
            </div>
            <ButtonComponent className="m-auto h-auto as--cancel--outline btn--padding btn--font " onClick={helpModalHandler}>
              Close
            </ButtonComponent>
          </CommonModal>
        )}
        <ConfirmationEmail
          open={this.state.openDialog}
          close={this.modalHandler}
          email={email}
          sendLink={data => this.props.sendConfirmationLink({ userName: data.email, activationType: activationType.EMAIL })}
        />
        <EmailVerificationDialog
          isOpen={emailVerificationPopup && isVisitFirstTime}
          handleClose={this.closeEmailModal}
          sendEmail={this.sendEmail}
        />
        {currentUser && currentUser.role && currentUser.role.aliasName ? (
          <ProfileComponent
            key={currentUser.is_stripe_profile_completed}
            initValue={initValue}
            updateUser={updateUser}
            handleStripeAccount={handleStripeAccount}
            // handleStripeAccount={() => this.setState({showStripeSuccessPopup: true})}
            paymentTypes={paymentTypes}
            cancelHandler={this.cancelHandler}
            latLng={latLng}
            userType={currentUser.role}
            documentVerification={currentUser.documentVerification}
            preferenceTypes={preferenceTypes}
            professionTypes={professionTypes}
            documentTypes={documentTypes}
            countryCode={countryCode}
            message={message}
            getDocumentUrl={getDocumentUrl}
          />
        ) : (
          <Loader isSmall isPageLoader />
        )}
      </DashboardLayout>
    )
  }
}

const mapStateToProps = state => ({
  currentUser: state.loginReducer.currentUser,
  stripProfileLink: state.loginReducer.stripProfileLink,
  isUpdate: state.loginReducer.isUpdate,
  isUpdateError: state.loginReducer.isUpdateError,
  preferenceTypes: state.loginReducer.preferenceTypes,
  professionTypes: state.loginReducer.professionTypes,
  documentTypes: state.loginReducer.documentTypes,
  countryCode: state.loginReducer.countryCode,
  errorMessage: state.errorReducer.errorMessage,
  unauthorizedAccess: state.dashboardReducer.unauthorizedAccess,
  unauthorizedAccessMessage: state.dashboardReducer.unauthorizedAccessMessage,
  paymentTypes: state.dashboardReducer?.paymentTypes,
  emailVerificationPopup: state.dashboardReducer.emailVerificationPopup,
  isVisitFirstTime: state.dashboardReducer.isVisitFirstTime,
})

const mapDispatchToProps = dispatch => ({
  updateUser: data => dispatch(updateUser(data)),
  updateStripeProfileCompleted: data => dispatch(updateStripeProfileCompleted(data)),
  handleStripeAccount: () => dispatch(handleStripeAccount()),
  getTypeWiseLookup: data => dispatch(getTypeWiseLookup(data)),
  clearErrorMessageShow: () => dispatch(clearErrorMessageShow()),
  getDocumentUrl: data => dispatch(getDocumentUrl(data)),
  clearDashboardUnauthorizedAccessFlag: () => dispatch(clearDashboardUnauthorizedAccessFlag()),
  getAllPaymentTypes: () => dispatch(getAllPaymentTypes()),
  clearStripeAccountLink: () => dispatch(clearStripeAccountLink()),
  closeEmailVerificationModal: () => dispatch(closeEmailVerificationModal()),
  emailVerificationCheck: data => dispatch(emailVerificationCheck(data)),
  isVisitFirstTimeForEmail: data => dispatch(isVisitFirstTimeForEmail(data)),
  sendConfirmationLink: data => dispatch(sendConfirmationLink(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Profile)
