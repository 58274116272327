import React from 'react'
import { Grid } from '@material-ui/core'
import { getConvertedPrice } from '../../helpers/currencyConverterHelper'
import { CurrencyContextConsumer } from '../../CurrencyContext'
import '../../containers/salesEngine/BoatPurchaseInformation.scss'

export const BoatPurchaseInformation = props => {
  const { salesEngine, className, depositPayment, priceData } = props

  return (
    <>
      <CurrencyContextConsumer>
        {({ currentCurrency }) => (
          <Grid item xs={12}>
            <div className={`${className} boat-information-table-sales-engine`}>
              <div className="table-responsive">
                <table>
                  <thead>
                    <tr>
                      <th>Item</th>
                      <th>Quantity</th>
                      <th>Description</th>
                      <th>Unit Price</th>
                      <th></th>
                      <th>Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        {salesEngine?.boat?.isAuctionRoomCreated
                          ? 'Boat Price'
                          : salesEngine.negotiatedBoatPrice
                          ? 'Counter Price'
                          : 'Original Boat Price'}
                      </td>
                      <td>{priceData.quantity}</td>
                      <td>Item Cost</td>
                      <td>{getConvertedPrice(priceData.unitBoatPrice, currentCurrency)}</td>
                      <td></td>
                      <td>{getConvertedPrice(priceData.totalBoatPrice, currentCurrency)}</td>
                    </tr>
                    <tr>
                      <td>Service Fees</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>{getConvertedPrice(priceData.serviceFee, currentCurrency)}</td>
                    </tr>
                    {depositPayment && (
                      <tr>
                        <td>
                          Deposited Amount
                          <br />
                          Payment Transfer Fee for Deposit
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>
                          {getConvertedPrice(priceData.depositPrice, currentCurrency)}
                          <br />
                          {getConvertedPrice(priceData.depositFee, currentCurrency)}
                        </td>
                      </tr>
                    )}
                    <tr>
                      <td>Sub Total</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>{getConvertedPrice(priceData.subTotalPrice, currentCurrency)}</td>
                    </tr>
                    <tr>
                      <td>
                        Payment Transfer Fees <div className="text-blue-mini">(If Card issued outside of Canada)</div>
                      </td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>
                        {getConvertedPrice(priceData.priceWithStripeFee.feeNational, currentCurrency)}
                        <div className="text-blue-mini">
                          (Add{' '}
                          {getConvertedPrice(
                            priceData.priceWithStripeFee.feeInternational - priceData.priceWithStripeFee.feeNational,
                            currentCurrency
                          )}
                          )
                        </div>
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr style={{ fontSize: '1.25em' }}>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>
                        <div>Total</div>
                      </td>
                      <td>
                        <div>
                          {getConvertedPrice(priceData.priceWithStripeFee.totalNational, currentCurrency)}
                          <div className="text-blue-mini">
                            (Add{' '}
                            {getConvertedPrice(
                              priceData.priceWithStripeFee.totalInternational - priceData.priceWithStripeFee.totalNational,
                              currentCurrency
                            )}
                            )
                          </div>
                        </div>
                      </td>
                    </tr>
                    {/* <tr>
                      <td>Counter Price</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td> {getConvertedPrice(salesEngine.negotiatedBoatPrice, currentCurrency)}</td>
                    </tr> */}
                  </tfoot>
                </table>
              </div>
            </div>
          </Grid>
        )}
      </CurrencyContextConsumer>
    </>
  )
}
BoatPurchaseInformation.defaultProps = {
  className: '',
}
