import React, { Component } from 'react'
import { CardContent, Card, Select } from '@material-ui/core'
import { Formik, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'

import '../../containers/boatServices/boatServices.scss'
import '../../styles/common.scss'
import SearchComplete from '../map/SearchComplete'
import { renderYachtServicesMenuItems } from '../../helpers/jsxHelper'
import { BoatServiceBannerFormStyle } from '../styleComponent/styleComponent'
import { NotifyModal } from '../modal/notifyModal'
import { likeModuleEnum, notifyMeEnums } from '../../util/enums/enums'
import SingleSelect from '../helper/singleSelect'
import { renderSelectOptions, requireMessage } from '../../helpers/string'

class BoatServiceCard extends Component {
  state = {
    openDialog: false,
    isClass: false,
  }

  setPlaceHandler = ({ country, city }, setFieldValue) => {
    setFieldValue('country', country)
    setFieldValue('city', city)
  }

  getNewWidth = () => {
    const width = document.querySelector('body')
    const actualWidth = width && width.offsetWidth / 4
    return actualWidth
  }

  modalHandler = e => {
    e && e.preventDefault()
    this.setState(preState => ({ openDialog: !preState.openDialog }))
  }

  notifyMeHandler = (formValues, values) => {
    const { notifyMe } = this.props
    notifyMe({
      ...formValues,
      ...values,
      type: notifyMeEnums.YACHT_SERVICE,
      service: values.service ? values.service.value : '',
    })

    this.modalHandler()
  }
  /** Temporary */
  openDropDownHandle = () => {
    this.interval = setInterval(() => {
      const className = document.querySelector('.drop---item')
      className && this.stop(className)
    }, 1000)
  }
  stop(className) {
    className.parentElement.parentElement.parentElement.classList.add('single--dropDown')
    clearInterval(this.interval)
  }
  /** Temporary */
  render() {
    const { openDialog } = this.state
    const { iconBg, services, search, notifyInput } = this.props

    const width = this.getNewWidth()

    return (
      <Formik
        initialValues={{
          country: '',
          city: '',
          service: '',
        }}
        validationSchema={Yup.object().shape({
          country: Yup.string().required(requireMessage('Location')),
        })}
        onSubmit={values => {
          search({
            ...values,
            service: values.service ? values.service.value : '',
            serviceAlias: values.service ? values.service.label : '',
          })
        }}
        render={({ touched, errors, values, setFieldValue, handleSubmit }) => (
          <Form className="bg-transparent-form service-home-search-card" onSubmit={e => e.preventDefault()}>
            <BoatServiceBannerFormStyle bgWidth={width} className="adam-banner-form">
              <div className="boat-service-banner-form-div">
                <Card
                  style={{ backgroundColor: 'transparent', border: '0', boxShadow: 'none' }}
                  className="boat-service-banner-form-card card p-0 br-10 overflow--unset"
                >
                  <CardContent className="p-4 ">
                    <div className="boat-service-banner-form-inner-size mt-2">
                      <div className="boat-service-banner-form-info border--radius--20">
                        <div style={{ position: 'relative' }}>
                          <SearchComplete
                            style={{ backgroundColor: '#0073', backdropFilter: 'blur(4px)', color: 'white' }}
                            requiredFieldLabel={
                              errors.country && touched.country ? (
                                <ErrorMessage component="div" name="country" className="color-white"></ErrorMessage>
                              ) : (
                                <span style={{ color: 'white' }}>Search country or city</span>
                              )
                            }
                            className="form-control font-14 marina--filter--location border--radius--20 opacity--1"
                            name="country"
                            getPlaceName={place => this.setPlaceHandler(place, setFieldValue)}
                          />
                          <i
                            style={{
                              position: 'absolute',
                              top: '50%',
                              right: 10,
                              transform: 'translateY(-50%)',
                              color: 'white',
                              fontSize: 13,
                            }}
                            className="adam-search-2"
                          ></i>
                        </div>
                      </div>
                      <div className="boat-service-banner-form-info mt-3 mb-0">
                        <SingleSelect
                          name="service"
                          styles={{
                            control: (base, state) => ({
                              ...base,
                              borderRadius: '20px',
                              backgroundColor: '#061e9c19',
                              backdropFilter: 'blur(2px)',
                              boxShadow: '#63636333 0px 2px 8px 0px',
                              color: 'white',
                            }),
                            menuList: base => ({
                              ...base,
                              '::-webkit-scrollbar': {
                                width: '9px',
                                height: '0px',
                              },
                              '::-webkit-scrollbar-track': {},
                              '::-webkit-scrollbar-thumb': {
                                background: '#2276d6',
                                borderRadius: '15px',
                              },
                            }),
                          }}
                          style={{ fontSize: '14px' }}
                          placeholder="Service"
                          selectedOption={values.service}
                          options={renderSelectOptions(services, 'name', 'id')}
                          onChange={value => {
                            setFieldValue('service', value)
                          }}
                        />
                        <ErrorMessage component="div" name="service" className="error-message"></ErrorMessage>
                      </div>

                      <div className="mt-3 mb-0 d-flex justify-content-between">
                        <span></span>
                        {/* <a className="btn btn-link-info btn-notify-link pl-0 flex-none" href="/#" onClick={this.modalHandler}>
                          <u className="font-weight-500 boat-service-banner-notify-button light-green boatservice-notify">
                            NOTIFY ME
                          </u>
                        </a> */}
                        {/* <NotifyModal
                          open={openDialog}
                          value={notifyInput}
                          closeModal={this.modalHandler}
                          onSubmit={formValues => this.notifyMeHandler(formValues, values)}
                        /> */}

                        <button
                          type="button"
                          className={`btn btn-danger font-weight-600 boat--service--card--btn box--shadow--1 border--radius--20 boatService-banner-btn boat-service-banner-submit-button search-button-width ${iconBg}`}
                          onClick={handleSubmit}
                        >
                          Search Now
                        </button>
                      </div>
                    </div>
                  </CardContent>
                </Card>
              </div>
            </BoatServiceBannerFormStyle>
          </Form>
        )}
      />
    )
  }
}

export default BoatServiceCard
