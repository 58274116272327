import React, { Component } from 'react'
import { connect } from 'react-redux'

import { clearSearchedResults, searchBoat } from '../../redux/actions'
import { resultMessage } from '../../util/enums/enums'
import BoatListingsWithMap from '../../components/gridComponents/BoatListingsWithMap'
import { urlParamsToObject } from '../../helpers/routeHelper'
import UserContext from '../../UserContext'

class SearchBoats extends Component {
  static contextType = UserContext

  constructor(props, context) {
    super(props, context)
  }
  state = {
    showMap: false,
    values: null,
    boatAlias: null,
  }

  static getDerivedStateFromProps(nextProps, nextState) {
    const { location } = nextProps

    const urlParams = new URLSearchParams(location.search)
    let values = urlParamsToObject(urlParams)
    const { boatAlias, ...otherValues } = values
    values = {
      ...otherValues,
      minLength: +values.minLength,
      maxLength: +values.maxLength,
      minPrice: +values.minPrice,
      maxPrice: +values.maxPrice,
      minYearBuild: +values.minYearBuild,
      maxYearBuild: +values.maxYearBuild,
      page: values.page ? +values.page : 1,
    }
    return {
      values,
      boatAlias,
    }
  }

  componentWillUnmount() {
    const { clearSearchedResults } = this.props
    clearSearchedResults()
  }

  render() {
    const { searchedBoats, searchedBoatsCount, searchBoat, isPageLoader } = this.props
    const { showMap, values, boatAlias } = this.state
    const { country } = this.context

    return (
      <BoatListingsWithMap
        isPageLoader={isPageLoader}
        boatsType={`${searchedBoatsCount || 0} ${boatAlias || 'Boats for Sale'} ${
          values?.city && values?.country ? `in ${values.city},${values.country}` : ''
        }`}
        boatsTypeCount={searchedBoatsCount || 0}
        showMap={showMap}
        toggleMapHandler={this.toggleMapHandler}
        boats={searchedBoats}
        message={resultMessage.search}
        action={searchBoat}
        value={values}
      />
    )
  }
}

const mapStateToProps = state => ({
  searchedBoats: state.boatReducer.searchedBoats,
  searchedBoatsCount: state.boatReducer.searchedBoatsCount,
  isPageLoader: state.boatReducer.isPageLoader,
})

const mapDispatchToProps = dispatch => ({
  clearSearchedResults: () => dispatch(clearSearchedResults()),
  searchBoat: data => dispatch(searchBoat(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(SearchBoats)
