import React, { Component, Fragment } from 'react'
import { Formik, ErrorMessage } from 'formik'
import { connect } from 'react-redux'
import * as Yup from 'yup'
import { Button, Card, Row, Col, Form } from 'react-bootstrap'

import { Field, Loader } from '../../../components'
import { createArticle, clearArticleFlag, getSingleArticle, updateArticle } from '../../../redux/actions/articlesAction'
import { requireMessage, requiredWords, validateWords, trimmedValue, maximumWordCount } from '../../../helpers/string'
import { DashboardLayout } from '../../../components/layout/dashboardLayout'
import ErrorComponent from '../../../components/error/errorComponent'

import './article.scss'
import ErrorFocus from '../../../components/ws/ErrorFocus'
import { SuccessNotify } from '../../../helpers/notification'
import { popUpMessage } from '../../../helpers/confirmationPopup'
import { EditorFormats, EditorModules, MEDIA_NAME } from '../../../util/enums/enums'
import { ButtonComponent } from '../../../components/form/Button'
import { getAddMedia } from '../../../helpers/s3FileUpload'
import { stringValidation } from '../../../helpers/yupHelper'
import { envConfig } from '../../../config'
import TagsInput from '../manageBoats/TagsInput'

const ReactQuill = envConfig.BUILD_TARGET !== 'server' ? require('react-quill') : ''

class CreateArticle extends Component {
  constructor(props) {
    super(props)
    this.state = {
      editId: props.location && props.location.state && props.location.state.editId,
      article: {
        title: '',
        description: '',
        shortDescription: '',
        files: '',
        addMedia: {
          files: [],
        },
        isButtonLoader: false,
        removeMedia: [],
        meta_tags: null
      },
    }
  }

  componentDidMount() {
    const { clearArticleFlag, getSingleArticle } = this.props
    const { editId } = this.state
    clearArticleFlag()

    if (editId) {
      getSingleArticle({ id: editId })
    }
  }

  static getDerivedStateFromProps(props, state) {
    const { clearArticleFlag, history, createSuccess, createError, getOneSuccess, article, updateSuccess, updateError } = props

    if (getOneSuccess) {
      clearArticleFlag()
      return {
        article: {
          ...article,
          files: article.files[0],
          addMedia: {
            files: [],
          },
          removeMedia: [],
        },
      }
    }

    if (createSuccess || updateSuccess) {
      history.push('/manage-articles')
      return {
        isButtonLoader: false,
      }
    } else if (createError || updateError) {
      clearArticleFlag()
      return {
        isButtonLoader: false,
      }
    }
  }

  cancelHandler = () => {
    const { history, clearArticleFlag } = this.props
    clearArticleFlag()
    history.push('/manage-articles')
  }

  render() {
    const { createArticle, createError, errorMessage, isLoading, updateArticle } = this.props
    const { article, editId, isButtonLoader } = this.state

    return (
      <Fragment>
        <DashboardLayout>
          {isLoading ? (
            <Loader isPageLoader />
          ) : (
            <div className="article-container pt-0 adam-dash-manage-art-add">
              <Formik
                initialValues={{ ...article }}
                onSubmit={values => {
                  // console.log(values);

                  this.setState({ isButtonLoader: true })
                  const { files, isButtonLoader, ...updatedValues } = values
                  const input = {
                    ...updatedValues,
                    addMedia: getAddMedia(updatedValues.addMedia),
                  }
                  if (editId) {
                    const {
                      user,
                      userTotalArticles,
                      articleApproved,
                      articleViewCount,
                      createdAt,
                      files,
                      status,
                      titleSlug,
                      updatedAt,
                      __typename,
                      likes,
                      pageVisits,
                      ...newValues
                    } = input
                    updateArticle(newValues)
                  } else {
                    createArticle(input)
                  }
                }}
                validationSchema={Yup.object().shape({
                  title: stringValidation.required('Title field is required.'),
                  shortDescription: stringValidation.required('Short description field is required.'),
                  description: stringValidation
                    .required(requireMessage('Article content'))
                    .ensure()
                    .test('match', requiredWords('Article content'), val => validateWords(val)),
                  files: Yup.string().required('Article image field is required.'),
                })}
                render={({ values, setFieldValue, handleSubmit }) => (
                  <Form>
                    <ErrorFocus />
                    <Card className="p-3">
                      <Card.Title className="card-title d-flex justify-content-left mb-3">Add Article</Card.Title>
                      <Row className="pb-5">
                        <Col xs={6}>
                          <div>
                            <Field
                              label="Title"
                              id="title"
                              name="title"
                              type="text"
                              required
                              value={values.title}
                              onChangeText={e => {
                                // console.log(e.target.value);
                                // console.log(values);
                                setFieldValue('title', e.target.value)
                              }}
                              onBlur={e => setFieldValue('title', trimmedValue(e.target.value))}
                            />
                            <ErrorMessage component="div" name="title" className="error-message" />
                          </div>
                          <div className='mt-2'>
                            <label>Short description <span className='required'>*</span></label>
                            <div style={{flex: '1'}}>
                                <Field
                                    id={'shortDescription'}
                                    name={'shortDescription'}
                                    type="textarea"
                                    rows={4}
                                    value={values.shortDescription}
                                    onChangeText={e => {
                                        setFieldValue('shortDescription', e.target.value)
                                    }}
                                    onBlur={e => setFieldValue('shortDescription', trimmedValue(e.target.value))}
                                    required
                                />
                                <ErrorMessage className="invalid-feedback ant-typography-danger d-block" name={'shortDescription'} component="span" />
                            </div>
                          </div>
                          <br></br>
                          <div className="d-flex flex-wrap justify-content-center">
                            <div className="addBoatShow-imgUploader">
                              <Field
                                label="Article Image"
                                name="files"
                                id="files"
                                type="single-image"
                                onChangeText={setFieldValue}
                                value={values.files}
                                deleteIds={values.removeMedia}
                                addMedia={values.addMedia}
                                mediaName={MEDIA_NAME.ARTICLEFILE}
                                bigSize
                                isNotCircle
                                required
                                maxImageSize={0}
                              />
                            </div>
                            <ErrorMessage
                              component="div"
                              name="files"
                              className="error-message w-100 justify-content-center d-flex"
                            />
                          </div>
                        </Col>
                        <Col xs={6}>
                          <div className="w-100">
                            <label className=" required">Article Content</label>
                            {ReactQuill && (
                              <ReactQuill
                                name="description"
                                className="editor-height"
                                theme={'snow'}
                                formats={EditorFormats}
                                modules={EditorModules}
                                value={values.description}
                                onChange={values => {
                                  // console.log(values);
                                  setFieldValue('description', values)
                                }}
                                onBlur={(previousRange, source, editor) => setFieldValue('description', values.description)}
                                // onBlur={(previousRange, source, editor) => setFieldValue("description", trimmedValue(editor.getText()))}
                              />
                            )}
                            <ErrorMessage component="div" name="description" className="error-message" />
                          </div>
                          <div className='w-100'>
                            <TagsInput 
                                value={values?.meta_tags}
                                handleChange={(value) => {
                                  setFieldValue('meta_tags', value.join(','))
                                }}
                              />
                          </div>
                        </Col>
                      </Row>
                      {createError && (
                        <Row>
                          <Col xs={12}>
                            <ErrorComponent errors={errorMessage} />
                          </Col>
                        </Row>
                      )}
                      <hr></hr>
                      <Row>
                        <Col xs={12}>
                          <div className="d-flex justify-content-center">
                            <ButtonComponent
                              variant="outline"
                              color="success"
                              disabled={isButtonLoader}
                              loader={isButtonLoader}
                              className=" h-auto btn--padding btn--font mr-3"
                              onClick={handleSubmit}
                            >
                              {'Post my article'}
                            </ButtonComponent>

                            <ButtonComponent
                              variant="outline"
                              color="cancel"
                              className=" h-auto btn--padding btn--font"
                              onClick={this.cancelHandler}
                            >
                              {' '}
                              {'Cancel'}
                            </ButtonComponent>
                          </div>
                        </Col>
                      </Row>
                    </Card>
                  </Form>
                )}
              ></Formik>
            </div>
          )}
        </DashboardLayout>
      </Fragment>
    )
  }
}

const mapStateToProps = state => ({
  createSuccess: state.articleReducer && state.articleReducer.createSuccess,
  createError: state.articleReducer && state.articleReducer.createError,
  user: state.loginReducer && state.loginReducer.currentUser,
  errorMessage: state.errorReducer.errorMessage,
  getOneSuccess: state.articleReducer && state.articleReducer.getOneSuccess,
  isLoading: state.articleReducer && state.articleReducer.isLoading,
  article: state.articleReducer && state.articleReducer.article,
  updateSuccess: state.articleReducer && state.articleReducer.updateSuccess,
  updateError: state.articleReducer && state.articleReducer.updateError,
})

const mapDispatchToProps = dispatch => ({
  createArticle: data => dispatch(createArticle(data)),
  clearArticleFlag: () => dispatch(clearArticleFlag()),
  getSingleArticle: data => dispatch(getSingleArticle(data)),
  updateArticle: data => dispatch(updateArticle(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(CreateArticle)
