import { createContext, useContext, useState } from 'react'

const TopLoaderCtx = createContext()
export default TopLoaderCtx

export const TopLoaderProvider = ({ children }) => {
  const [isActive, setIsActive] = useState(false)

  const show = () => setIsActive(true)
  const hide = () => setIsActive(false)

  return (
    <TopLoaderCtx.Provider
      value={{
        isActive,
        show,
        hide,
      }}
    >
      {children}
    </TopLoaderCtx.Provider>
  )
}

export const useTopLoader = () => useContext(TopLoaderCtx)
