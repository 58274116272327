import React, { Component } from 'react'
import OutsideClickHandler from 'react-outside-click-handler'

import 'react-responsive-carousel/lib/styles/carousel.min.css'

import { Grid, Dialog, DialogTitle, DialogContent, DialogContentText } from '@material-ui/core'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'

import { getAllPageInfoByType, clearPageInfoByTypeFlag, clearModelSizeFlags } from '../../redux/actions/pageInfoByTypeAction'
import { footerLinks, getModuleType, userRoles } from '../../util/enums/enums'
import { displayDefaultNumericValue, displayDefaultValue, textTruncate } from '../../helpers/string'
import { addToWishlist } from '../../helpers/methodHelper'
import { removeWishlist, getAllWishlists, toggleCookiePolicyModal, setCookiePolicyStatusAction } from '../../redux/actions'
import { viewBoatHandler, viewMarinaDetails, viewRentBoatHandler, viewServiceBoatHandler } from '../../helpers/boatHelper'
import { getLocalStorageItem } from '../../helpers/storageHelper'
import { CommonTooltip } from '../CommonTooltip'
import UserContext from '../../UserContext'
import { redirectRouteHandler } from '../../helpers/routeHelper'

import './footer.scss'
import '../../assets/css/component/wishListFooter.scss'
import '../../components/home/home.scss'
import { CommonModal } from '../modal/commonModal'
import classNames from 'classnames'
import { CurrencyConverterModal } from '../modal/currencyConverterModal'

class Footer extends Component {
  state = {
    privacyModal: false,
    showWishlist: false,
    commonModelOpen: false,
    wishlistCount: displayDefaultNumericValue(getLocalStorageItem('totalWishlists')),
    isLargePopup: false,
  }

  static getDerivedStateFromProps(props, prevState) {
    const { success, clearPageInfoByTypeFlag, totalWishlists, isModelSuccess } = props

    if (isModelSuccess) {
      clearPageInfoByTypeFlag()
      return {
        privacyModal: true,
      }
    }

    if (success && !isModelSuccess) {
      clearPageInfoByTypeFlag()
      return {
        commonModelOpen: true,
      }
    }

    if (prevState.wishlistCount !== totalWishlists) {
      return {
        wishlistCount: displayDefaultNumericValue(getLocalStorageItem('totalWishlists')),
      }
    }

    return null
  }

  // componentDidMount() {
  //   if (!this.props.cookiePolicyStatus) {
  //     this.props.toggleCookiePolicyModal(true);
  //   }
  // }

  static contextType = UserContext

  createMarkup = data => {
    return { __html: data }
  }

  privacyPolicyHandler = () => {
    this.setState(prevState => ({
      privacyModal: !prevState.privacyModal,
    }))
  }

  handleCommonModel = () => {
    const { clearModelSizeFlags } = this.props
    this.setState(prevState => ({ commonModelOpen: !prevState.commonModelOpen }))
    clearModelSizeFlags()
  }

  renderTermsOfServiceHandler = () => {
    const { pageInfoByType, isLargeModal } = this.props
    const { commonModelOpen } = this.state
    return (
      <CommonModal
        className={isLargeModal ? `large-footer-modal` : `footer-common-modal `}
        title={pageInfoByType.title}
        open={commonModelOpen}
        close={this.handleCommonModel}
      >
        <div
          className="small-screen-footer-link-desc"
          dangerouslySetInnerHTML={this.createMarkup(pageInfoByType.pageContent)}
        />{' '}
        <div className="col-12 text-center ">
          <div className="sub-footer text-muted footer-rights-section small-screen-font-12">
            © {new Date().getFullYear()} AdamSea.com All Rights Reserved.
          </div>
        </div>
      </CommonModal>
    )
  }

  getPageByTitle = async (getPageByTitle, isLarge = false) => {
    const { getAllPageInfoByType } = this.props
    await getAllPageInfoByType({ title: getPageByTitle, isLargeModal: isLarge })
  }

  toggleShowWishlist = () => {
    const { getAllWishlists } = this.props
    getAllWishlists()
    this.setState(prevState => ({ showWishlist: !prevState.showWishlist }))
  }

  getDescription = item => {
    const data = item.moduleId
    let response

    switch (item.moduleType) {
      case 'Boat':
        response = data.description
        break
      case 'BoatRent':
        response = data.descriptionOfOurTrip
        break
      case 'Marina':
        response = data.serviceDescription
        break
      case 'Yacht':
        response = data.service
        break
      case 'BoatShow':
        response = data.showDescription
        break

      default:
        response = ''
        break
    }

    return textTruncate(response, 50)
  }

  viewPageHandler = item => {
    switch (item.moduleType) {
      case 'Boat':
        viewBoatHandler(item.moduleId)
        break
      case 'BoatRent':
        viewRentBoatHandler(item.moduleId)
        break
      case 'Marina':
        viewMarinaDetails(item.moduleId)
        break
      case 'Yacht':
        viewServiceBoatHandler(item.moduleId)
        break

      default:
        break
    }
  }

  render() {
    const { privacyModal, showWishlist, wishlistCount, currencyDialog } = this.state
    const { pageInfoByType, allWishlists, removeWishlist, currentUser, siteConfigurations } = this.props
    const { role } = currentUser

    return (
      <>
        <div className={classNames('bg-gray main-footer', { 'mt-30': !this.props.noFooterMargin })}>
          <div className="m-auto pt-4 pb-4 container ">
            <div className="custom-parent-div-footer">
              <div className="pl-3 pr-3 " style={{ flex: 1 }}>
                <div className="footer-title mt-4 gray-dark footer-small-screen-font">Boats for sale</div>

                <ul className="footer-nav">
                  <li className="footer-nav-item small-screen-footer-nav-links">
                    <Link
                      to="/show-all-boats/recently-added-boats"
                      className="footer-link dark-silver footer-small-screen-font link-hover-text"
                    >
                      Recently added
                    </Link>
                  </li>
                  <li className="footer-nav-item small-screen-footer-nav-links">
                    <Link
                      to="/show-all/featured-boats"
                      className="footer-link dark-silver footer-small-screen-font link-hover-text"
                    >
                      Featured boats
                    </Link>
                  </li>
                  <li className="footer-nav-item small-screen-footer-nav-links">
                    <Link
                      to="/show-all/best-deal-boats"
                      className="footer-link dark-silver footer-small-screen-font link-hover-text"
                    >
                      Must buy
                    </Link>
                  </li>
                  <li className="footer-nav-item small-screen-footer-nav-links">
                    <Link
                      to="/show-all-footer-link/most-viewed-boats"
                      className="footer-link dark-silver footer-small-screen-font link-hover-text"
                    >
                      Most viewed
                    </Link>
                  </li>
                  <li className="footer-nav-item small-screen-footer-nav-links">
                    <Link
                      to="/show-all-auction-rooms"
                      className="footer-link dark-silver footer-small-screen-font link-hover-text"
                    >
                      Auction
                    </Link>
                  </li>
                  <li className="footer-nav-item small-screen-footer-nav-links">
                    <Link
                      to="/show-all/must-buy-boats"
                      className="footer-link dark-silver footer-small-screen-font link-hover-text"
                    >
                      Small boats for sale
                    </Link>
                  </li>
                </ul>
              </div>

              <div className="pl-3 pr-3 " style={{ flex: 1 }}>
                <div className="footer-title mt-4 gray-dark footer-small-screen-font">Partnership</div>

                <ul className="footer-nav">
                  <li className="footer-nav-item small-screen-footer-nav-links">
                    <Link to="/be-a-partner" className="footer-link dark-silver footer-small-screen-font link-hover-text">
                      Become a partner
                    </Link>
                  </li>
                  <li className="footer-nav-item small-screen-footer-nav-links">
                    <Link to="/shipper-info" className="footer-link dark-silver footer-small-screen-font link-hover-text">
                      Shipper
                    </Link>
                  </li>
                  {/* <li className="footer-nav-item small-screen-footer-nav-links">
             <Link to="#" className="footer-link dark-silver footer-small-screen-font link-hover-text">
               Escrow
             </Link>
           </li> */}
                  <li className="footer-nav-item small-screen-footer-nav-links">
                    <Link
                      to="/detail/manufacturer"
                      className="footer-link dark-silver footer-small-screen-font link-hover-text"
                    >
                      Manufacturer
                    </Link>
                  </li>
                  <li className="footer-nav-item small-screen-footer-nav-links">
                    <Link to="/detail/surveyor" className="footer-link dark-silver footer-small-screen-font link-hover-text">
                      Surveyor
                    </Link>
                  </li>
                  {/* <li className="footer-nav-item small-screen-footer-nav-links">
             <Link to="#" className="footer-link dark-silver footer-small-screen-font link-hover-text">
               All Boats List
             </Link>
           </li> */}
                  {/* <li className="footer-nav-item small-screen-footer-nav-links">
             <Link to="#" className="footer-link dark-silver footer-small-screen-font link-hover-text">
               Top 20 Used Boats
             </Link>
           </li> */}
                </ul>
              </div>

              <div className="pl-3 pr-3 " style={{ flex: 1 }}>
                <div className="footer-title mt-4 gray-dark footer-small-screen-font">Boat shows</div>

                <ul className="footer-nav sub-nav">
                  <li className="footer-nav-item small-screen-footer-nav-links">
                    <Link to="/boat-show" className="footer-link dark-silver footer-small-screen-font link-hover-text">
                      List of boats shows
                    </Link>
                  </li>
                  {role?.aliasName !== userRoles.MEMBER && (
                    <li className="footer-nav-item small-screen-footer-nav-links">
                      <Link to="/add-boat-show" className="footer-link dark-silver footer-small-screen-font link-hover-text">
                        Add your boat show
                      </Link>
                    </li>
                  )}
                </ul>

                <div className="footer-title mt-4 gray-dark footer-small-screen-font">Tips and Resources</div>

                <ul className="footer-nav sub-nav">
                  <li className="footer-nav-item small-screen-footer-nav-links">
                    <Link to="/survey-your-boat" className="footer-link dark-silver footer-small-screen-font link-hover-text">
                      Survey your boat
                    </Link>
                  </li>
                  <li className="footer-nav-item small-screen-footer-nav-links">
                    <Link to="/fraud-protection" className="footer-link dark-silver footer-small-screen-font link-hover-text">
                      Fraud Protection
                    </Link>
                  </li>
                  <li className="footer-nav-item small-screen-footer-nav-links">
                    <Link to="/buying-process" className="footer-link dark-silver footer-small-screen-font link-hover-text">
                      Buying process
                    </Link>
                  </li>
                </ul>
              </div>

              <div className="pl-3 pr-3 " style={{ flex: 1 }}>
                <div className="footer-title mt-4 gray-dark footer-small-screen-font">AdamSea.com</div>

                <ul className="footer-nav sub-nav">
                  <li className="footer-nav-item small-screen-footer-nav-links">
                    <Link to="/market" className="footer-link dark-silver footer-small-screen-font link-hover-text">
                      Our Market
                    </Link>
                  </li>
                  <li className="footer-nav-item small-screen-footer-nav-links">
                    <Link to="/privacy-policy" className="footer-link dark-silver footer-small-screen-font link-hover-text">
                      Policy
                    </Link>
                  </li>
                  {/* <li className="footer-nav-item small-screen-footer-nav-links">
             <Link to="#" className="footer-link dark-silver footer-small-screen-font link-hover-text">
               Leadership
             </Link>
           </li> */}
                  {/* <li className="footer-nav-item small-screen-footer-nav-links">
             <Link to="#" className="footer-link dark-silver footer-small-screen-font link-hover-text">
               What We Do
             </Link>
           </li> */}
                  <li className="footer-nav-item small-screen-footer-nav-links">
                    <Link to="/rent-and-charter" className="footer-link dark-silver footer-small-screen-font link-hover-text">
                      Rent and charter
                    </Link>
                  </li>
                  <li className="footer-nav-item small-screen-footer-nav-links">
                    <Link
                      to="/integrity-and-compliance"
                      className="footer-link dark-silver footer-small-screen-font link-hover-text"
                    >
                      Integrity and compliance
                    </Link>
                  </li>
                  {/* <li className="footer-nav-item small-screen-footer-nav-links">
             <Link to="/boat-media" className="footer-link dark-silver footer-small-screen-font link-hover-text">
               AdamSea Connect
             </Link>
           </li> */}
                  <li className="footer-nav-item small-screen-footer-nav-links">
                    <Link to="/about-us" className="footer-link dark-silver footer-small-screen-font link-hover-text">
                      About Us
                    </Link>
                  </li>
                  <li className="footer-nav-item small-screen-footer-nav-links">
                    <Link to="/adamsea-community" className="footer-link dark-silver footer-small-screen-font link-hover-text">
                      AdamSea community
                    </Link>
                  </li>
                  <li className="footer-nav-item small-screen-footer-nav-links">
                    <Link to="/contact-us" className="footer-link dark-silver footer-small-screen-font link-hover-text">
                      Contact Us
                    </Link>
                  </li>
                  <li className="footer-nav-item small-screen-footer-nav-links">
                    <Link to="/careers" className="footer-link dark-silver footer-small-screen-font link-hover-text">
                      Careers
                    </Link>
                  </li>
                </ul>
              </div>

              <div className="pl-3 pr-3 " style={{ gridColumn: 'span 2' }}>
                <div className="footer-title mt-4 gray-dark footer-small-screen-font">Sell your boat</div>

                <ul className="footer-nav">
                  <li className="footer-nav-item small-screen-footer-nav-links">
                    <Link to="/detail/boat-owner" className="footer-link dark-silver footer-small-screen-font link-hover-text">
                      Boat owners
                    </Link>
                  </li>
                  <li className="footer-nav-item small-screen-footer-nav-links">
                    <Link
                      to="/detail/broker-and-dealer"
                      className="footer-link dark-silver footer-small-screen-font link-hover-text"
                    >
                      Broker and Dealer
                    </Link>
                  </li>
                  <li className="footer-nav-item small-screen-footer-nav-links">
                    <Link
                      to="/detail/builder-and-manufacturer"
                      className="footer-link dark-silver footer-small-screen-font link-hover-text"
                    >
                      Builders & Manufacturers
                    </Link>
                  </li>
                  <li className="footer-nav-item small-screen-footer-nav-links">
                    <Link to="/selling-process" className="footer-link dark-silver footer-small-screen-font link-hover-text">
                      Selling process
                    </Link>
                  </li>
                </ul>

                <div className="footer-title mt-4 color-black footer-small-screen-font as--chat--title">AS CHATS</div>

                <div className="d-flex">
                  <div
                    className="footer-app-icon cursor-pointer"
                    onClick={() => window.open('https://apps.apple.com/ca/app/adamsea/id6446269602', '_blank')}
                  >
                    <img loading="lazy" src={require('./app-store.png')} alt="App store" />
                  </div>

                  <div
                    className="footer-app-icon ml-2 cursor-pointer"
                    onClick={() => window.open('https://play.google.com/store/apps/details?id=com.adamsea.chat', '_blank')}
                  >
                    <img loading="lazy" src={require('./play-store.png')} alt="Play store" />
                  </div>
                </div>

                <p className="mt-2">Download the app and stay connected</p>
              </div>
            </div>

            {currentUser?.id && <div style={{ display: 'flex', gap: 12 }}>
              <Link to="/user-guide" className="paddingRight-1 _light-btn">
                <span className="link-hover-text">Help Center</span>
              </Link>
              <Link to="/" className="_light-btn">
                <div
                  to="#"
                  data-toggle="modal"
                  data-target="#currency-modal"
                  onClick={e => {
                    e.preventDefault()
                    this.setState({ currencyDialog: true })
                  }}
                >
                  Currency {getLocalStorageItem('currentCurrency')}
                </div>
              </Link>
            </div>}

            <CurrencyConverterModal
              open={currencyDialog}
              closeModal={() => {
                this.setState({ currencyDialog: false })
              }}
            />
          </div>
        </div>
        <Grid container className="bg-navy-blue">
          <Grid item sm={12}>
            <div className="container-md">
              <div className="container footer-small-screen">
                <div className="row pt-5 pb-3 responsive--padding">
                  <div className="col-12 text-center logo-footer-section small-screen-footer-logo-div">
                    <img
                      loading="lazy"
                      src={require('../../assets/images/boatInner/logo-white.png')}
                      alt="adamsea"
                      height="25"
                    />
                  </div>
                  <div className="col-12 footer-social-icon ml-auto flex-row">
                    <ul className="social-network footer-small-screen-social-icons">
                      <li onClick={() => redirectRouteHandler(siteConfigurations.facebookLink)}>
                        <a>
                          <i className="adam-facebook small-screen-font-15 footer-icon-effect"></i>
                        </a>
                      </li>
                      <li onClick={() => redirectRouteHandler(siteConfigurations.twitterLink)}>
                        <a>
                          <svg className="small-screen-font-15 footer-icon-effect" viewBox="12 12.769 24 22.462">
                            <path d=" M 12.058 12.769 L 21.325 25.158 L 12 35.231 L 14.099 35.231 L 22.263 26.412 L 28.858 35.231 L 36 35.231 L 26.212 22.145 L 34.892 12.769 L 32.793 12.769 L 25.275 20.891 L 19.2 12.769 L 12.058 12.769 Z  M 15.145 14.314 L 18.426 14.314 L 32.913 33.686 L 29.632 33.686 L 15.145 14.314 Z " />
                          </svg>
                          {/* <i className="adam-twitter-fill"></i> */}
                        </a>
                      </li>
                      {/* <li>
                        <a>
                          <i className="adam-google-plus small-screen-font-15 footer-icon-effect"></i>
                        </a>
                      </li> */}
                      <li onClick={() => redirectRouteHandler(siteConfigurations.linkedInLink)}>
                        <a>
                          <i className="adam-linkedin small-screen-font-15 footer-icon-effect"></i>
                        </a>
                      </li>
                      <li onClick={() => redirectRouteHandler(siteConfigurations.pinterestLink)}>
                        <a>
                          <i className="adam-pinterest-logo small-screen-font-15 footer-icon-effect"></i>
                        </a>
                      </li>
                      <li onClick={() => redirectRouteHandler(siteConfigurations.googlePlusLink)}>
                        <a>
                          <i className="adam-blogspot-fill small-screen-font-15 footer-icon-effect"></i>
                        </a>
                      </li>
                      <li onClick={() => redirectRouteHandler(siteConfigurations.instaLink)}>
                        <a>
                          <i className="adam-instagram small-screen-font-15 footer-icon-effect"></i>
                        </a>
                      </li>
                      <li onClick={() => redirectRouteHandler(siteConfigurations.youtubeLink)}>
                        <a>
                          <svg className="small-screen-font-15 footer-icon-effect" viewBox="0 0 576 512">
                            <path d="M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205-142.739 81.201z" />
                          </svg>
                        </a>
                      </li>
                    </ul>
                  </div>

                  <div className="col-12 text-center">
                    <ul className="footer-nav">
                      <li className="footer-nav-item mb-0">
                        <ul className="list-inline footer-service-link footer-small-screen-link-list footer-small-screen-padding-bottom">
                          <li className="list-inline-item cursor-pointer footer-small-screen-font">
                            <Link to="/info/service-agreement" className="footer-text-effect">
                              Service Agreement
                            </Link>
                          </li>
                          <li className="list-inline-item cursor-pointer footer-small-screen-font">
                            <Link to="/info/dispute-rules" className="footer-text-effect">
                              Dispute Rules
                            </Link>
                          </li>
                          <li className="list-inline-item cursor-pointer footer-small-screen-font">
                            <Link to="/info/policy" className="footer-text-effect">
                              Privacy Policy
                            </Link>
                          </li>
                          <li className="list-inline-item cursor-pointer footer-small-screen-font">
                            <Link to="/info/product-listing-policy" className="footer-text-effect">
                              Product Listing Policy
                            </Link>
                          </li>
                          <li className="list-inline-item cursor-pointer footer-small-screen-font">
                            <Link to="/info/terms-of-use" className="footer-text-effect">
                              Terms Of Use
                            </Link>
                          </li>
                          <li className="list-inline-item cursor-pointer footer-small-screen-font">
                            <Link to="/info/cookie-policy" className="footer-text-effect">
                              Cookie Policy
                            </Link>
                          </li>
                          <li className="list-inline-item cursor-pointer footer-small-screen-font">
                            <Link to="/sitemap" className="footer-text-effect">
                              Sitemap
                            </Link>
                          </li>
                        </ul>
                      </li>
                    </ul>
                    {this.renderTermsOfServiceHandler()}
                    {/* dialog start */}

                    {this.props.isCookiePolicyModalOpen && (
                      <div
                        style={{
                          position: 'fixed',
                          bottom: 50,
                          left: 50,
                          width: 500,
                          maxWidth: innerWidth - 100,
                          zIndex: 1000,
                          background: '#fff',
                          borderRadius: 8,
                          boxShadow: '0 0 30px -9px #0007',
                          padding: 24,
                        }}
                        open={this.props.isCookiePolicyModalOpen}
                        close={() => this.props.toggleCookiePolicyModal(false)}
                      >
                        <div className="mb-3 d-flex justify-content-between align-items-center">
                          <div className="d-flex justify-content-between align-items-center" style={{ flex: 1, gap: 16 }}>
                            <img src={require('../../assets/images/cookies_1547866.svg')} alt="bell" height={28} />
                            <p style={{ flex: 1, margin: 0, textAlign: 'left', color: 'black', fontSize: 20 }}>
                              This site uses cookies
                            </p>
                          </div>

                          <button className="btn p-0" onClick={() => this.props.toggleCookiePolicyModal(false)}>
                            <img src={require('../../assets/images/boatInner/close.svg')} alt="Close icon" height={28} />
                          </button>
                        </div>

                        <p style={{ flex: 1, textAlign: 'left', color: 'black' }}>
                          We use cookies to ensure a smooth sailing chat experience on AdamSea. They help maintain your chat
                          sessions, remember your preferences, and improve our services. You have the right to accept or decline
                          cookies. Most web browsers automatically accept cookies, but you can usually modify your browser
                          settings to decline cookies if you prefer. However, disabling cookies may prevent you from taking full
                          advantage of the chat feature on our site. Learn more about our{' '}
                          <Link to="/info/cookie-policy">Cookie Policy</Link>.
                        </p>

                        {this.props.cookiePolicyStatus !== 1 && (
                          <div className="d-flex flex-wrap justify-content-end">
                            <button
                              className="ml-4 cookie-modal-btn cookie-modal-btn-reject"
                              onClick={() => {
                                this.props.setCookiePolicyStatusAction(-1)
                                this.props.toggleCookiePolicyModal(false)
                              }}
                            >
                              Reject Cookies
                            </button>
                            <button
                              className="ml-4 cookie-modal-btn cookie-modal-btn-accept"
                              onClick={() => {
                                this.props.setCookiePolicyStatusAction(1)
                                this.props.toggleCookiePolicyModal(false)
                              }}
                            >
                              Allow Cookies
                            </button>
                          </div>
                        )}
                      </div>
                    )}

                    <Dialog
                      open={privacyModal}
                      classes={{ root: 'privacy-modal-root' }}
                      onClose={this.privacyPolicyHandler}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                    >
                      <DialogTitle id="alert-dialog-title" className="small-screen-footer-link-title">
                        <div className="d-flex justify-content-between align-items-center">
                          {pageInfoByType.title}
                          <div onClick={() => this.privacyPolicyHandler()}>
                            <img
                              loading="lazy"
                              src={require('./close.png')}
                              alt="Close"
                              className="footer-close-btn cursor-pointer"
                            />
                          </div>
                        </div>
                      </DialogTitle>
                      <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                          <div
                            className="small-screen-footer-link-desc"
                            dangerouslySetInnerHTML={this.createMarkup(pageInfoByType.pageContent)}
                          />
                        </DialogContentText>
                      </DialogContent>
                    </Dialog>
                    {/* dialog end */}
                  </div>

                  <div className="col-12 text-center ">
                    <div className="sub-footer text-muted footer-rights-section small-screen-font-12">
                      © {new Date().getFullYear()} AdamSea.com All Rights Reserved.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>

        {/* {showWishlist ? (
          <OutsideClickHandler
            display="contents"
            onOutsideClick={() => this.setState({ showWishlist: false })}
          >
            <div className="wish-list-fixed">
              <label className="wish-list-fixed-label" onClick={this.toggleShowWishlist} >Wishlist ({wishlistCount})</label>
              {allWishlists && allWishlists.all && allWishlists.all.length > 0 ?
                (<>
                  {allWishlists.all.length > 4 && <Link to="/manage-wishlist" className="wish-list-fixed-show-all">See All</Link>}
                  <ul className="wish-list-fixed-ul">
                    {allWishlists.all.slice(0, 4).map(item =>
                      item && item.moduleId &&
                      <li className="wish-list-fixed-li" key={item.id}>
                        <div className="wish-list-fixed-image">
                          <img
                            src={item.moduleId.images ? item.moduleId.images[0] : item.moduleId.boatshowImages}
                            alt="wishlist-item icon"
                            onClick={() => this.viewPageHandler(item)}
                          />
                            <img
                            data-tooltip-id="common-tooltip" data-tooltip-content="Remove"
                            className="remove-wish-list-icon"
                            onClick={() => addToWishlist(removeWishlist, item.moduleId.id, getModuleType(item.moduleType))} src={require('../../assets/images/close-wishlist-icon.png')} alt="adamsea" />
                        </div>
                        <div className="wish-list-fixed-description">
                          {textTruncate(displayDefaultValue(this.getDescription(item)),30)}
                        </div>
                      </li>
                    )}
                  </ul>
                </>
                ) :this.toggleShowWishlist()}
            </div>
          </OutsideClickHandler>) : (<>
            {allWishlists && allWishlists.all && allWishlists.all.length > 0 &&
            <div className="hide-wishlist-tag" onClick={this.toggleShowWishlist}>Wishlist ({wishlistCount})</div>}
          </>)
        } */}
      </>
    )
  }
}

const mapStateToProps = state => ({
  pageInfoByType: state.pageInfoByTypeReducer && state.pageInfoByTypeReducer.pageInfoByType,
  success: state.pageInfoByTypeReducer && state.pageInfoByTypeReducer.success,
  isModelSuccess: state.pageInfoByTypeReducer && state.pageInfoByTypeReducer.isModelSuccess,
  isLargeModal: state.pageInfoByTypeReducer && state.pageInfoByTypeReducer.isLargeModal,
  allWishlists: state.dashboardReducer.allWishlists,
  removeWishlistSuccess: state.dashboardReducer.removeWishlistSuccess,
  totalWishlists: state.dashboardReducer.totalWishlists,
  currentUser: state.loginReducer.currentUser,
  siteConfigurations: state.loginReducer.siteConfigurations,
  isCookiePolicyModalOpen: state.loginReducer.isCookiePolicyModalOpen,
  cookiePolicyStatus: state.loginReducer.cookiePolicyStatus,
})

const mapDispatchToProps = dispatch => ({
  getAllPageInfoByType: data => dispatch(getAllPageInfoByType(data)),
  clearPageInfoByTypeFlag: data => dispatch(clearPageInfoByTypeFlag()),
  getAllWishlists: data => dispatch(getAllWishlists(data)),
  removeWishlist: data => dispatch(removeWishlist(data)),
  clearModelSizeFlags: () => dispatch(clearModelSizeFlags()),
  toggleCookiePolicyModal: data => dispatch(toggleCookiePolicyModal(data)),
  setCookiePolicyStatusAction: data => dispatch(setCookiePolicyStatusAction(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Footer)
