import React, { useContext, useEffect, useState } from 'react'
import { Grid } from '@material-ui/core'
import { connect } from 'react-redux'
import RemoveIcon from '@material-ui/icons/Remove'
import AddIcon from '@material-ui/icons/Add'
import { Field, Layout, Loader } from '../../components'
import './ReviewBooking.scss'
import {
  getRentInnerBoat,
  clearBoatRentFlag,
  getPaymentReceipt,
  createRentBooking,
  clearCreateRentBooking,
  getCommissions,
  getCommissions_clear,
} from '../../redux/actions'
import { Row, Col } from 'react-bootstrap'
import moment from 'moment'
import { getAddress, redirectToUserProfile } from '../../helpers/jsxHelper'
import { getConvertedPrice } from '../../helpers/currencyConverterHelper'
import { getId, isHourly, isPrivate, isShared } from '../../util/utilFunctions'
import { FaCaretLeft, FaCaretRight } from 'react-icons/fa'
import { defaultProfileIcon, footerLinks, paymentPolicy, rentBoatTripForCard, RentBookingStatus, userRoles } from '../../util/enums/enums'
import { TermWithIcon } from '../../components/salesEngine/TermWithIcon'
import { isEmpty } from 'lodash'
import Payment from './Payment'
import { toast } from 'react-toastify'
import { CurrencyContextConsumer } from '../../CurrencyContext'
import { clearRentPagesFlag, getRentPages } from '../../redux/actions/pageInfoByTypeAction'
import { Link } from 'react-router-dom'
import UserContext from '../../UserContext'
import classNames from 'classnames'
import { ShareWithChatAndUser } from '../../components/share/ShareWithChatAndUser'
import UserCardSale from '../../components/user/UserCardSale'
import ChatUserCreate from '../../components/chats/ChatCreateUse'
import Register from '../register/register'

const pricePerLabel = {
  [rentBoatTripForCard.private]: 'Trip',
  [rentBoatTripForCard.shared]: 'Passenger',
  [rentBoatTripForCard.rentPerHour]: 'Boat',
}

const ReviewBooking = ({
  getRentPages,
  clearRentPagesFlag,
  getRentInnerBoat,
  createRentBooking,
  currentUser,
  rentPagesSuccess,
  rentPagesFailure,
  rentPages,
  isLoading,
  boatRentInnerData,
  clearCreateRentBooking,
  createRentBookingLoading,
  createRentBookingFailure,
  createRentBookingSuccess,
  getCommissions,
  getCommissions_clear,
  commissions,
  match: { params },
  history,
  isAuthorized,
  countryDetails,
}) => {
  const [content, setContent] = useState({})
  const [tz, setTz] = useState('')
  const [utcOffset, setUtcOffset] = useState(0)
  const [tripAlias, setTripAlias] = useState('')
  const [tripTypeAlias, setTripTypeAlias] = useState('')
  const [boatUserName, setBoatUserName] = useState('')
  const [tripDetails, setTripDetails] = useState([])
  const [dateVal, setDateVal] = useState('')
  const [selectedDate, setSelectedDate] = useState('')
  const [selectedSlots, setSelectedSlots] = useState([])
  const [selectedSlot, setSelectedSlot] = useState('')
  const [counter, setCounter] = useState(null)
  const [count, setCount] = useState(1)
  const [countTimer, setCountTimer] = useState(null)
  const [baseAmount, setBaseAmount] = useState(0)
  const [rentServiceFee, setRentServiceFee] = useState(0)
  const [serviceFee, setServiceFee] = useState(0)
  const [stripeFee, setStripeFee] = useState(0)
  const [stripeFee2, setStripeFee2] = useState(0)
  const [totalAmount, setTotalAmount] = useState(0)
  const [totalAmount2, setTotalAmount2] = useState(0)

  const userCtx = useContext(UserContext)

  useEffect(() => {
    getRentPages()
    getCommissions()
    getRentInnerBoat({ id: params.id, forBooking: true })
  }, [])

  useEffect(() => {
    dateVal || (tz && setDateVal(moment().tz(tz)))
  }, [tz])

  useEffect(() => {
    if (rentPagesSuccess && rentPages) {
      const pages = {}
      rentPages.forEach(p => (pages[p.titleSlug] = p))
      setContent(pages)
    }

    if (rentPagesSuccess || rentPagesFailure) clearRentPagesFlag()
  }, [rentPagesSuccess])

  useEffect(() => {
    if (commissions?.success && commissions.data) {
      setRentServiceFee(commissions.data?.Rent_Service_Fee)
    }

    if (commissions?.success || commissions?.failure) getCommissions_clear()
  }, [commissions])

  useEffect(() => {
    if (isEmpty(boatRentInnerData)) return

    const _tz = boatRentInnerData.timezoneName
    setTz(_tz)

    const _utcOffset = moment().tz(_tz).utcOffset()
    setUtcOffset(_utcOffset)

    setTripAlias(boatRentInnerData.trip.alias)
    setTripTypeAlias(boatRentInnerData.tripType.alias)

    setBoatUserName(
      boatRentInnerData?.user && [boatRentInnerData.user.firstName, boatRentInnerData.user.lastName].filter(Boolean).join(' ')
    )

    const _tripDetails = (boatRentInnerData.tripDetails || [])
      .map(({ __typename, id, ...s }) => ({
        ...s,
        _id: id || s._id,
        startDate: moment(s.startDate).tz(_tz).subtract(_utcOffset, 'minutes'),
        startTime: moment(s.startTime).tz(_tz).subtract(_utcOffset, 'minutes'),
        endDate: moment(s.endDate).tz(_tz).subtract(_utcOffset, 'minutes'),
        endTime: moment(s.endTime).tz(_tz).subtract(_utcOffset, 'minutes'),
      }))
      .filter(slot => !slot.expired && slot.available && moment().tz(_tz).isBefore(slot.startDate))
      .sort((s1, s2) => s1.startDate.valueOf() - s2.startDate.valueOf())

    console.log(_tripDetails)

    setTripDetails(_tripDetails)

    const initSlotDate = _tripDetails.find(s => s.price === boatRentInnerData.price)?.startDate
    initSlotDate && setSelectedDate(initSlotDate)
    initSlotDate && setDateVal(initSlotDate)
  }, [boatRentInnerData])

  useEffect(() => {
    selectedDate && tripDetails.length && checkAvailability()
  }, [selectedDate, tripDetails])

  useEffect(() => {
    setBaseAmount(selectedSlot ? selectedSlot.price : 0)
    window.scrollTo(0, 0)
  }, [selectedSlot])

  useEffect(() => {
    const _serviceFee = baseAmount * (rentServiceFee / 100)

    const pGoal = baseAmount + _serviceFee
    const fFixed = 0.3
    const fPerc = 0.029
    const fPerc2 = fPerc + 0.006

    const _totalAmount = (pGoal + fFixed) / (1 - fPerc)
    const _totalAmount2 = (pGoal + fFixed) / (1 - fPerc2)

    const _stripeFee = _totalAmount - (baseAmount + _serviceFee)
    const _stripeFee2 = _totalAmount2 - (baseAmount + _serviceFee)

    setServiceFee(_serviceFee)
    setStripeFee(_stripeFee)
    setStripeFee2(_stripeFee2)
    setTotalAmount(_totalAmount)
    setTotalAmount2(_totalAmount2)
  }, [baseAmount, rentServiceFee])

  useEffect(() => {
    if (!counter) {
      clearInterval(countTimer)
      setCountTimer(-1)
      return
    }

    setCount(count => counter({ count, selectedSlot }))

    setCountTimer(
      setTimeout(() => {
        setCount(count => counter({ count, selectedSlot }))

        setCountTimer(
          setInterval(() => {
            setCount(count => counter({ count, selectedSlot }))
          })
        )
      }, 500)
    )
  }, [counter])

  useEffect(() => {
    isPrivate(tripAlias) || setBaseAmount(selectedSlot ? selectedSlot.price * count : 0)
  }, [count])

  useEffect(() => {
    if (createRentBookingSuccess) toast.success('Booking created successfully', { autoClose: 5000 })
    else if (createRentBookingFailure) toast.error('Booking creation failed', { autoClose: 5000 })

    clearCreateRentBooking()

    if (createRentBookingSuccess) history.push('/my-bookings')
  }, [createRentBookingSuccess, createRentBookingFailure])

  const checkAvailability = () => {
    setDateVal(selectedDate)
    setSelectedSlots(tripDetails.filter(s => s.startDate.isSame(selectedDate, 'day')))
  }

  const onPaymentSuccess = paymentMethodId => {
    createRentBooking({
      customer: currentUser.id,
      boat: boatRentInnerData.id,
      slot: selectedSlot._id,
      units: count,
      paymentMethodId,
      bookedAt: moment().toDate(),
    })
  }

  return (
    <>
      <Layout>
        {isLoading ? (
          <Loader isPageLoader />
        ) : (
          <>
            <div className="rent-booking-design">
              <div className="p-3 d-flex justify-content-center align-items-center header-top-margin" style={{ position: 'sticky', zIndex: 99, background: '#fff' }}>
                <button type="button" className={classNames('rent-booking-tab-btn', !selectedSlot && 'rent-booking-tab-btn-active')} onClick={() => setSelectedSlot('')}>Check Availability</button>
                <div style={{ width: 300, height: 1, background: '#0007' }}></div>
                <button type="button" className={classNames('rent-booking-tab-btn', selectedSlot && 'rent-booking-tab-btn-active')} disabled>Confirm & Pay</button>
              </div>

              <div className='p-4'>
                <Grid container spacing={3}>
                  <Grid item md={8}>
                    <Grid container>
                      <Grid container>
                        <Grid item sm={12}>
                          {!selectedSlot ? (
                            <>
                              <div className="d-sm-flex border-0">
                                <div>
                                  <div
                                    className="rb-boat-img"
                                    onClick={() => history.push(`/rent-inner/${boatRentInnerData.id}/${boatRentInnerData.model}`)}
                                  >
                                    <img
                                      src={boatRentInnerData.images?.length && boatRentInnerData.images[0].url}
                                      className="h-100 width-100"
                                      alt="Boat"
                                    />
                                  </div>
                                </div>

                                <div className="width-100 px-4 section-stepper-info-boat-div">
                                  <div className="d-flex justify-content-between">
                                    <div className="d-flex flex-column">
                                      {/* <span className="rb-boat-id">Ad ID: {boatRentInnerData.adId}</span> */}
                                      <span className="rb-boat-name">{boatRentInnerData.model}</span>

                                      <CurrencyContextConsumer>
                                        {({ currentCurrency }) =>
                                          boatRentInnerData.price && (
                                            <span>
                                              <span className="rb-boat-price">
                                                Price {getConvertedPrice(boatRentInnerData.price, currentCurrency)}
                                              </span>{' '}
                                              {isPrivate(boatRentInnerData.trip.alias)
                                                ? 'Trip'
                                                : isShared(boatRentInnerData.trip.alias)
                                                ? 'Passenger'
                                                : 'Boat'}
                                              <span className="mx-2"></span>
                                              <span className="rb-trip-alias">{boatRentInnerData.trip?.alias}</span>
                                            </span>
                                          )
                                        }
                                      </CurrencyContextConsumer>
                                    </div>
                                  </div>

                                  <div>
                                    <Row className="mt-3">
                                      <Col xs={6}>
                                        <div className="d-flex flex-column">
                                          <span className="stepper-div-text">Trip Type</span>
                                          <span className="stepper-div-text">
                                            <span style={{ fontSize: '0.8em', fontWeight: 400 }}>{boatRentInnerData.tripType?.alias}</span>
                                          </span>
                                        </div>
                                      </Col>
                                      <Col xs={6}>
                                        <div className="d-flex flex-column">
                                          <span className="stepper-div-text">Captain Name</span>
                                          <span className="stepper-div-text">
                                            <span style={{ fontSize: '0.8em', fontWeight: 400 }}>{(boatRentInnerData.captainName?.trim && boatRentInnerData.captainName.trim()) ||
                                              'No Captain'}</span>
                                          </span>
                                        </div>
                                      </Col>
                                    </Row>

                                    <Row className="mt-3">
                                      <Col xs={12}>
                                        <div className="d-flex flex-column">
                                          <span className="stepper-div-text">Trip Destination</span>
                                          <span className="stepper-div-text">
                                            <span style={{ fontSize: '0.8em', fontWeight: 400 }}>{getAddress(boatRentInnerData.address)?.tripAddress?.address1?.replace(
                                              /(^[A-Z0-9]+\+[A-Z0-9]+\,?\s?)?/,
                                              ''
                                            )}</span>
                                          </span>
                                        </div>
                                      </Col>
                                    </Row>
                                  </div>
                                </div>
                              </div>

                              <div className="mt-5">
                                <Row>
                                  <Col xs={6}>
                                    <TermWithIcon
                                      title="Payment Policy"
                                      descriptions={content && content[footerLinks.rent.paymentPolicy]?.pageContent}
                                      icon={<img src={require('./dollars_9684392.svg')} height={28} />}
                                      isDescHTML
                                      popup
                                      modalClassName="max-w-768"
                                    />
                                  </Col>
                                  <Col xs={6}>
                                    <TermWithIcon
                                      title="Cancellation Policy"
                                      descriptions={content && content[footerLinks.rent.cancellationPolicy]?.pageContent}
                                      icon={<img src={require('./shield_9684424.svg')} height={28} />}
                                      isDescHTML
                                      popup
                                      modalClassName="max-w-768"
                                    />
                                  </Col>
                                </Row>
                              </div>
                            </>
                          ) : (
                            <>
                              <Grid container>
                                <Grid item xs={12} lg={9}>
                                  <h2 className="mt-5 review-pay-head">Cancellation Policies</h2>
                                  <p
                                    dangerouslySetInnerHTML={{
                                      __html: content && content[footerLinks.rent.reviewAndPay]?.pageContent,
                                    }}
                                  />

                                  <h2 className="mt-5 review-pay-head">Bring Your Ticket</h2>
                                  <p
                                    dangerouslySetInnerHTML={{
                                      __html: content && content[footerLinks.rent.passengerInfo]?.pageContent,
                                    }}
                                  />
                                </Grid>
                              </Grid>

                              <div className="mt-5" style={{ padding: '32px 24px', borderRadius: 8, background: '#dff0f2' }}>
                                <h3 className="review-pay-head">Trip Information</h3>
                                <p>{boatRentInnerData.descriptionOfOurTrip}</p>

                                <div className="mt-4 d-flex justify-content-between align-items-center" style={{ gap: 30 }}>
                                  <div className="book-user-card">
                                    <div className="book-user-card-col">
                                      <div className="book-user-card-img-wrp">
                                        <img src={boatRentInnerData.user.image?.url || defaultProfileIcon} alt="User Img" />
                                        {boatRentInnerData.user?.isVerified && (
                                          <div className="book-user-card-verif">
                                            <img src={require('../../assets/images/verif.svg')} alt="verified user" />
                                          </div>
                                        )}
                                      </div>
                                      <div className="book-user-card-name">{boatRentInnerData.user.firstName}</div>
                                    </div>
                                    <div className="book-user-card-col">
                                      <ChatUserCreate bookRentCard id={boatRentInnerData.user.id} image={boatRentInnerData.user.image?.url} />
                                    </div>
                                  </div>
                                  <div style={{ flex: 1 }}>
                                    <TermWithIcon
                                      title={`${boatRentInnerData.user.firstName} ${boatRentInnerData.user.lastName} Policy`}
                                      descriptions={boatRentInnerData.policy}
                                      // icon={<i className="fas fa-money-check-alt"></i>}
                                      popup
                                    />
                                  </div>
                                </div>
                              </div>

                              <Grid container>
                                <Grid item xs={12} lg={9}>
                                  {boatRentInnerData.whatToBring && (
                                    <Row className="mt-5">
                                      <Col sm={12}>
                                        <h3 className="review-pay-head">What {boatRentInnerData.user.firstName} wants you to bring</h3>
                                        <p>{boatRentInnerData.whatToBring}</p>
                                      </Col>
                                    </Row>
                                  )}

                                  <Row className="mt-5">
                                    <Col sm={12}>
                                      <h3 className="review-pay-head">Trip Deposit</h3>
                                      <p>
                                        {boatRentInnerData.deposit.alias}{' '}
                                        {!!boatRentInnerData.costOfDeposit && getConvertedPrice(boatRentInnerData.costOfDeposit)}
                                      </p>
                                      <p className="mt-2">
                                        <strong>IMPORTANT NOTE:</strong> The trip owner mandates keeping a deposit during the trip, which is collected and returned by the trip owner. AdamSea does not collect or keep any deposit on behalf of the guest. We advise asking for proof of payment, whether it is done by cash or card.
                                      </p>
                                    </Col>
                                  </Row>
                                </Grid>
                              </Grid>

                              <Row className="mt-5">
                                <Col xs={12}>
                                  {getId(currentUser) && !isAuthorized ? (
                                    !isEmpty(countryDetails) && !countryDetails.bookNowStatus ? (
                                      <div style={{ padding: '20px 26px', borderRadius: 20, background: '#ece5db' }}>
                                        <Row>
                                          <Col xs={12} md={6}>
                                            <h3 className="block-heading text-black mb-1">Pay for the Trip</h3>
                                          </Col>
                                          <Col xs={12} md={6}>
                                          <h3 className="block-heading mb-1">
                                            {getConvertedPrice(totalAmount)}{' '}
                                            <div className="text-blue-mini">(Add {getConvertedPrice(totalAmount2 - totalAmount)})</div>
                                          </h3>
                                          </Col>
                                        </Row>
                                        <Payment
                                          precheckData={{ boatId: boatRentInnerData.id, slotId: selectedSlot._id, units: count }}
                                          agreementContent={content && content[footerLinks.rent.agreement]?.pageContent}
                                          onSuccess={onPaymentSuccess}
                                        />
                                      </div>
                                     ) : (
                                      <h2 className="font-16" style={{ padding: '16px 24px', borderRadius: 8, background: '#dff0f2' }}>Booking is currently unavailable in Canada. Please stay tuned for future updates</h2>
                                     )
                                  ) : (
                                    <div style={{ padding: '20px 26px', borderRadius: 20, border: '1px solid #0003' }}>
                                      <h3 className="block-heading text-black mb-1">Sign Up to book this trip</h3>
                                      <Register isInline registerType={userRoles.MEMBER} history={history} />
                                    </div>
                                  )}
                                </Col>
                              </Row>
                            </>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item md={4}>
                    <Grid container>
                      <div className="check-avail-box">
                        {selectedSlot ? (
                          <>
                            <div className="d-flex" style={{ gap: 10 }}>
                              <Link
                                to={`/rent-inner/${boatRentInnerData.id}/${boatRentInnerData.model}`}
                                className="ms-3 d-flex boat-img-cont"
                              >
                                <img
                                  src={boatRentInnerData.images?.length && boatRentInnerData.images[0]?.url}
                                  alt={boatRentInnerData.model}
                                />
                              </Link>
                              <div className="d-flex flex-column align-items-start">
                                <span className="mt-2 trip-label">{tripAlias}</span>
                                <span className="mt-2 trip-label">{tripTypeAlias}</span>
                              </div>
                            </div>

                            <div className="mt-3 py-3" style={{ borderTop: '1px solid #ccc' }}>
                              {selectedSlot.startDate.isSame(selectedSlot.endDate, 'day') ? (
                                <p>
                                  <span className="h6">{selectedSlot.startDate.format('dddd, MMM DD')}</span>
                                  <br />
                                  {selectedSlot.startTime.format('hh:mm A')} - {selectedSlot.endTime.format('hh:mm A')}
                                </p>
                              ) : (
                                <p className="d-flex align-items-center">
                                  <span>
                                    <span className="h6">{selectedSlot.startDate.format('dddd, MMM DD')}</span>
                                    <br />
                                    {selectedSlot.startTime.format('hh:mm A')}
                                  </span>
                                  <span className="mx-3">-</span>
                                  <span>
                                    <span className="h6">{selectedSlot.endDate.format('dddd, MMM DD')}</span>
                                    <br />
                                    {selectedSlot.endTime.format('hh:mm A')}
                                  </span>
                                </p>
                              )}
                            </div>

                            <div className="mt-2 d-flex flex-wrap flex-column align-items-center">
                              <h2 className="mt-4 review-pay-head">
                                Number of {isHourly(tripAlias) ? 'Boats' : 'Passengers'}
                              </h2>
                              
                              <div className="d-flex align-items-center">
                                <button
                                  className="mx-4 btn-o-black rounded-full"
                                  onMouseDown={() =>
                                    setCounter(
                                      () =>
                                        ({ count }) =>
                                          count > 1 ? count - 1 : 1
                                    )
                                  }
                                  onMouseUp={() => setCounter(null)}
                                >
                                  <RemoveIcon style={{ fontSize: '1em' }} />
                                </button>

                                <span
                                  className="text-black h5 m-0 d-flex justify-content-center"
                                  style={{ minWidth: '25px' }}
                                >
                                  {count}
                                </span>

                                <button
                                  className="mx-4 btn-o-black rounded-full"
                                  onMouseDown={() => {
                                    setCounter(() => ({ count, selectedSlot }) => {
                                      if (isPrivate(tripAlias)) {
                                        return count < boatRentInnerData.maximumGuest ? count + 1 : boatRentInnerData.maximumGuest
                                      } else {
                                        return count < selectedSlot.available ? count + 1 : selectedSlot.available
                                      }
                                    })
                                  }}
                                  onMouseUp={() => setCounter(null)}
                                >
                                  <AddIcon style={{ fontSize: '1em' }} />
                                </button>
                              </div>

                              <span className="ms-4 badge badge-pill badge-ghost my-3" style={{ fontSize: '.9em' }}>
                                {isPrivate(tripAlias)
                                  ? `Our trip capacity is ${boatRentInnerData.maximumGuest} Guests can join`
                                  : (isShared(tripAlias)
                                      ? `${selectedSlot.available} Passengers`
                                      : `${selectedSlot.available} Boats`) + ' available for booking'}
                              </span>
                            </div>

                            <div className="mt-3 py-3" style={{ borderTop: '1px solid #ccc' }}>
                              <div className="d-flex justify-content-between">
                                <p>
                                  <span className="h6">
                                    {getConvertedPrice(selectedSlot.price)} {pricePerLabel[tripAlias]}
                                  </span>
                                  {!isPrivate(tripAlias) && (
                                    <>
                                      <br />
                                      &times; {count} {isHourly(tripAlias) ? boatRentInnerData.tripType?.alias + 's' : 'Persons'}
                                    </>
                                  )}
                                </p>

                                <p className="h6">{getConvertedPrice(baseAmount)}</p>
                              </div>

                              <div className="mt-3 mb-0 d-flex justify-content-between">
                                <p className="mb-1">Service Fees</p>
                                <p className="mb-1">{getConvertedPrice(serviceFee)}</p>
                              </div>
                              <div className="mt-3 d-flex justify-content-between"></div>
                              <div className="d-flex justify-content-between">
                                <p className="mb-1">
                                  Payment Transfer Fees <div className="text-blue-mini">(If Card issued outside of Canada)</div>
                                </p>
                                <p className="mb-1">
                                  {getConvertedPrice(stripeFee)}{' '}
                                  <div className="text-blue-mini">(Add {getConvertedPrice(stripeFee2 - stripeFee)})</div>
                                </p>
                              </div>
                            </div>

                            <div className="mt-3 py-3" style={{ borderTop: '1px solid #ccc' }}>
                              <div className="d-flex justify-content-between">
                                <h3 className="block-heading mb-1">Total</h3>
                                <h3 className="block-heading mb-1">
                                  {getConvertedPrice(totalAmount)}{' '}
                                  <div className="text-blue-mini">(Add {getConvertedPrice(totalAmount2 - totalAmount)})</div>
                                </h3>
                              </div>

                              <p className="mt-3">The Value-Added Tax (VAT) has already been included in the total price.</p>
                            </div>
                          </>
                        ) : (
                          <>
                            <h3 className="block-heading text-black mb-1">Check Availability</h3>
                            {/* <p className="text-secondary">Select date and find availability for renting the boat.</p> */}
                            <div className="mt-3" style={{ fontSize: '1.2em' }}>
                              {console.log(tripDetails.map(slot => slot.startDate.startOf('day')))}
                              <Field
                                label="Date"
                                type="select-date"
                                isTimeFormatFalse
                                timezoneName={tz}
                                placeholder="Date"
                                value={selectedDate}
                                className="mb-0"
                                isUpdate
                                required
                                onChange={setSelectedDate}
                                highlightDates={tripDetails.map(slot => slot.startDate.startOf('day'))}
                                isValidDate={cur => !moment(cur).isBefore(moment(), 'days')}
                              />
                            </div>
                            {/* <div className="mt-3 d-flex justify-content-end">
                              <button
                                className="w-100 btn btn-sm btn-rounded inner-header-button light-sky-blue rent-inner-card-hovered-button-effect"
                                onClick={() => setSelectedDate(dateVal)}
                                style={{ fontSize: '1.2em' }}
                              >
                                Check Availability
                              </button>
                            </div> */}
                            <div className="mt-4 d-flex justify-content-between">
                              <button
                                className="btn btn-sm arrow-btn"
                                onClick={() => setSelectedDate(selectedDate.clone().subtract(1, 'day'))}
                              >
                                <FaCaretLeft />
                              </button>
                              <span className="stepper-div-text">{selectedDate && selectedDate.format('MMM DD, YYYY')}</span>
                              <button
                                className="btn btn-sm arrow-btn"
                                onClick={() => setSelectedDate(selectedDate.clone().add(1, 'day'))}
                              >
                                <FaCaretRight />
                              </button>
                            </div>
                            <div className="mt-4">
                              {selectedSlots.length ? (
                                selectedSlots.map(s => (
                                  <div className="slot-choose-box" key={s._id}>
                                    <div className="slot-choose-dets">
                                      <p className="slot-price">{s.startTime.format('hh:mm A')}</p>
                                      <p className="slot-price">
                                        {getConvertedPrice(s.price)} {pricePerLabel[tripAlias]}
                                      </p>
                                    </div>
                                    <button
                                      className="btn btn-rounded inner-header-button light-sky-blue rent-inner-card-hovered-button-effect"
                                      onClick={() => setSelectedSlot(s)}
                                      style={{ padding: '14px 16px', borderRadius: 999, fontSize: 18 }}
                                    >
                                      Book
                                    </button>
                                  </div>
                                ))
                              ) : (
                                <p className="text-center">No trips available on this day.</p>
                              )}
                            </div>
                          </>
                        )}
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </div>
          </>
        )}
      </Layout>

      {createRentBookingLoading && <Loader isPageLoader />}
    </>
  )
}

const mapStateToProps = state => ({
  isAuthorized: state.loginReducer.isAuthorized,
  currentUser: state.loginReducer && state.loginReducer.currentUser,
  rentPages: state.pageInfoByTypeReducer?.rentPages,
  rentPagesSuccess: state.pageInfoByTypeReducer?.rentPagesSuccess,
  rentPagesFailure: state.pageInfoByTypeReducer?.rentPagesFailure,
  isLoading: state.boatRentReducer && state.boatRentReducer.isLoading,
  editRentBoatSuccess: state.boatRentReducer && state.boatRentReducer.editRentBoatSuccess,
  boatRentInnerData: state.boatRentReducer && state.boatRentReducer.boatRentInnerData,
  createRentBookingLoading: state.rentReducer.createRentBookingLoading,
  createRentBookingFailure: state.rentReducer.createRentBookingFailure,
  createRentBookingSuccess: state.rentReducer.createRentBookingSuccess,
  createRentBookingData: state.rentReducer.createRentBookingData,
  commissions: state.salesEngineReducer.commissions,
  countryDetails: state.dashboardReducer.countryDetails,
})

const mapDispatchToProps = dispatch => ({
  getRentPages: () => dispatch(getRentPages()),
  clearRentPagesFlag: () => dispatch(clearRentPagesFlag()),
  getRentInnerBoat: data => dispatch(getRentInnerBoat(data)),
  clearBoatRentFlag: () => dispatch(clearBoatRentFlag()),
  createRentBooking: data => dispatch(createRentBooking(data)),
  clearCreateRentBooking: () => dispatch(clearCreateRentBooking()),
  getPaymentReceipt: data => dispatch(getPaymentReceipt(data)),
  getCommissions: data => dispatch(getCommissions(data)),
  getCommissions_clear: data => dispatch(getCommissions_clear(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ReviewBooking)
