import { GET_MODULE_PDF, GET_MODULE_PDF_FAILURE, GET_MODULE_PDF_SUCCESS } from '../actionTypes'
import { redirectRouteHandler } from '../../helpers/routeHelper'
import { downloadSurveyImagesZipFile } from '../../util/utilFunctions'

const InitialState = {
  getPdfSuccess: false,
  getPdfError: false,
  pdf: '',
  isLoading: false,
}

export const pdfReducer = (state = InitialState, action) => {
  switch (action.type) {
    case GET_MODULE_PDF:
      return {
        ...state,
        pdf: '',
        isLoading: true,
      }
    case GET_MODULE_PDF_SUCCESS:
      if (action.payload.data.getModulePdf?.length > 1) {
        downloadSurveyImagesZipFile(
          action.payload.data.getModulePdf.map(url => ({ url })),
          {
            filenamePrefix: action.payload.filenamePrefix,
            zipFilename: action.payload.zipFilename,
          }
        )
      } else if (action.payload.data.getModulePdf?.length) {
        redirectRouteHandler(action.payload.data.getModulePdf)
      }

      return {
        ...state,
        getPdfSuccess: true,
        isLoading: false,
        pdf: action.payload.data.getModulePdf,
      }
    case GET_MODULE_PDF_FAILURE:
      return {
        ...state,
        getPdfError: true,
        isLoading: false,
        pdf: '',
      }

    default:
      return state
  }
}
