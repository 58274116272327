import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Formik, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import { Link } from '@material-ui/core'

import { CommonModal } from './commonModal'
import { requireMessage, phoneRegExp, validationMessages } from '../../helpers/string'
import { Field } from '../ws/Field'
import { clearErrorMessageShow, sendConfirmationLink, activateUserDetail, getUserById } from '../../redux/actions'
import ErrorComponent from '../error/errorComponent'
import { activationType } from '../../util/enums/enums'
import { ConfirmMobileOTP } from './confirmMobileOTP'
import { ButtonComponent } from '../form/Button'

import './confirmationMobile.scss'
import { getLocalStorageItem } from '../../helpers/storageHelper'
import { isValidMobileNumber } from '../../helpers/validationHelper'

const ConfirmMobileNumber = props => {
  const {
    open,
    close,
    isCountryUS,
    setIsUSCountry,
    value,
    isError,
    isErrorOTP,
    errorMessage,
    isMobileVerifySuccess,
    sendConfirmationLink,
    isOTPSentToMobile,
    activateUserDetail,
    getUserById,
    clearErrorMessageShow,
    message,
  } = props

  const [mobileNumberSentForOTP, setMobileNumberSentForOTP] = useState(value)

  // FOR OTP
  const [OTPModal, setOTPModal] = useState(false)
  const OTPModalHandler = () => setOTPModal(!OTPModal)

  useEffect(() => {
    if (isOTPSentToMobile) {
      setOTPModal(true)
      open && close()
    } else if (isMobileVerifySuccess) {
      getUserById(getLocalStorageItem('userId'))
      setOTPModal(false)
    }
  }, [isOTPSentToMobile, open, close, isMobileVerifySuccess, getUserById])

  const resendOTPHandler = () => {
    sendConfirmationLink({ userName: mobileNumberSentForOTP, activationType: activationType.MOBILE, isCountryUS })
    isErrorOTP && clearErrorMessageShow()
  }

  return (
    <>
      {OTPModal && <ConfirmMobileOTP
        open={OTPModal}
        message={message}
        close={OTPModalHandler}
        mobileNumberSentForOTP={mobileNumberSentForOTP}
        activateUserDetail={value => activateUserDetail({ token: value, activationType: activationType.MOBILE })}
        resendOTP={resendOTPHandler}
        isError={isErrorOTP}
        errorMessage={errorMessage}
        isMobileVerifySuccess={isMobileVerifySuccess}
      />}

      <CommonModal open={open} close={close} className="verify--mobile" title="Verify your mobile number" hideCloseIcon>
        <Formik
          initialValues={{ mobileNumber: value }}
          validationSchema={Yup.object().shape({
            mobileNumber: Yup.string()
              .test('match', validationMessages.validMobileNumber, val => isValidMobileNumber(val))
              .required(requireMessage('Mobile Number'))
              .ensure(),
          })}
          render={({ setFieldValue, values }) => (
            <Form>
              <div className="w-100 confirmation--email--popup">
                <Field
                  label="Mobile Number"
                  type="mobile-number"
                  id="mobileNumber"
                  name="mobileNumber"
                  className="form-control"
                  value={values.mobileNumber}
                  setIsUSCountry={setIsUSCountry}
                  onChangeText={value => setFieldValue('mobileNumber', value)}
                  required
                  noFormControl
                />
                <ErrorMessage component="div" name="mobileNumber" className="invalid-feedback-error" />
              </div>

              <div className="d-flex justify-content-between align-items-center flex-wrap">
                {!isCountryUS && <ButtonComponent
                  type="button"
                  onClick={() => {
                    clearErrorMessageShow()

                    const updatedMobileNumber = isValidMobileNumber(values.mobileNumber, false)

                    setMobileNumberSentForOTP(updatedMobileNumber)
                    sendConfirmationLink({ userName: updatedMobileNumber, activationType: activationType.MOBILE })
                  }}
                  capitalize={false}
                  className="h-auto as--success--outline btn--padding btn--font send--otp--btn rounded-full"
                >
                  Send OTP over SMS
                </ButtonComponent>}
                <ButtonComponent
                  type="button"
                  onClick={() => {
                    clearErrorMessageShow()

                    const updatedMobileNumber = isValidMobileNumber(values.mobileNumber, false)

                    setMobileNumberSentForOTP(updatedMobileNumber)
                    sendConfirmationLink({ userName: updatedMobileNumber, activationType: activationType.MOBILE, viaCall: true })
                  }}
                  capitalize={false}
                  className="h-auto as--success--outline btn--padding btn--font send--otp--btn rounded-full"
                >
                  Send OTP over Call
                </ButtonComponent>
                <Link onClick={close} className="cursor-pointer confirmation-a-tag do--it--later">
                  {`I'll do this later.`}
                </Link>
              </div>

              {isError && <ErrorComponent errors={errorMessage} />}
            </Form>
          )}
        />
      </CommonModal>
    </>
  )
}

const mapStateToProps = state => ({
  isOTPSentToMobile: state.dashboardReducer.isOTPSentToMobile,
  isMobileVerifySuccess: state.loginReducer.isMobileVerifySuccess,
  isMobileVerifyError: state.loginReducer.isMobileVerifyError,
  isError: state.dashboardReducer.isError,
  isErrorOTP: state.loginReducer.isError,
  errorMessage: state.errorReducer.errorMessage,
  message: state.dashboardReducer.message,
})

const mapDispatchToProps = dispatch => ({
  sendConfirmationLink: data => dispatch(sendConfirmationLink(data)),
  activateUserDetail: data => dispatch(activateUserDetail(data)),
  clearErrorMessageShow: () => dispatch(clearErrorMessageShow()),
  getUserById: data => dispatch(getUserById(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmMobileNumber)
