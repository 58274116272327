import { useState } from 'react'
import { Picker } from 'emoji-mart'
import OutsideClickHandler from 'react-outside-click-handler'
import IconEmoji from '../../assets/icons/emoji'
import styles from './emoji-btn.module.scss'

export default function EmojiBtn({ className, addEmoji }) {
  const [emojiOpen, setEmojiOpen] = useState(false)

  const handleSelect = (e, addEmoji) => {
    let emoji = e.native

    if (!emoji) {
      const sym = e.unified.split('-')
      const codesArray = sym.map(el => '0x' + el)
      emoji = String.fromCodePoint(...codesArray)
    }

    addEmoji(emoji)
  }

  return (
    <OutsideClickHandler onOutsideClick={() => setEmojiOpen(false)}>
      <div className={styles.emojiWrp}>
        <button className={className} onClick={() => setEmojiOpen(open => !open)}>
          <IconEmoji />
        </button>
        {emojiOpen && (
          <div className={styles.emojiPickerWrp}>
            <Picker onSelect={e => handleSelect(e, addEmoji)} />
          </div>
        )}
      </div>
    </OutsideClickHandler>
  )
}
