import { createContext, useCallback, useContext, useEffect, useState } from 'react'

const VisibleCtx = createContext()
export default VisibleCtx

export const VisibleProvider = ({ children }) => {
  const [visibleId, setVisibleId] = useState(null)
  const [visibleIds, setVisibleIds] = useState(new Set())

  const handleVisible = useCallback(id => {
    setVisibleIds(prev => new Set(prev).add(id))
  }, [])

  const handleNotVisible = useCallback(id => {
    setVisibleIds(prev => {
      const newSet = new Set(prev)
      newSet.delete(id)
      return newSet
    })
  }, [])

  useEffect(() => {
    if (visibleIds.size === 0) {
      setVisibleId(null)
    } else {
      setVisibleId(visibleIds.values().next().value)
    }
  }, [visibleIds])

  return <VisibleCtx.Provider value={[visibleId, handleVisible, handleNotVisible]}>{children}</VisibleCtx.Provider>
}

export const useVisible = () => useContext(VisibleCtx)
